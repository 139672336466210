import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ChangeUnitConfirmClose extends Component {
  onClickConfirm = (isCloseModalConfirmed) => {
    const { confirmCloseModal } = this.props;

    confirmCloseModal(isCloseModalConfirmed);
  };

  render() {
    return (
      <div className="change-unit-confirm-close">
        <div className="top-text">You Have Unsaved Changes</div>
        <div className="bottom-text">Leave Without Saving Changes?</div>

        <div className="confirm-buttons">
          <button
            className="btn-left btn btn-white btn-danger"
            onClick={() => this.onClickConfirm(true)}
          >
            Leave Without Saving
          </button>
          <button
            className="btn-right btn btn-primary"
            onClick={() => this.onClickConfirm(false)}
          >
            Don&apos;t Leave
          </button>
        </div>
      </div>
    );
  }
}

ChangeUnitConfirmClose.propTypes = {
  confirmCloseModal: PropTypes.func.isRequired,
};

import React from 'react';
import { OcrLabel } from '@v2/types/UploadsTypes';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import { Badge, Tooltip } from '@notch-ordering/ui-components';
import { t } from '@v2/i18n';

export interface UploadOcrLabelBadgeProps {
    ocrLabel: OcrLabel,
}

const getBadgeText = (ocrLabel: OcrLabel): string => (
    (ocrLabel === OcrLabel.CREDIT_INV_PACKING_SLIP || ocrLabel === OcrLabel.DUPLICATE_BILL)
        ? t('Uploads.Labels.cannotParse')
        : t('Uploads.Labels.reuploadNeeded')
);

const getBadgeTooltipText = (ocrLabel: OcrLabel): string => {
    switch (ocrLabel) {
        case OcrLabel.BLURRY:
            return t('Uploads.LabelTooltips.blurry');
        case OcrLabel.DOES_NOT_MATCH_NOTCH_ORDER:
            return t('Uploads.LabelTooltips.doesNotMatchNotchOrder');
        case OcrLabel.INCORRECT_SUPPLIER:
            return t('Uploads.LabelTooltips.incorectSupplier');
        case OcrLabel.MISSING_PAGES:
            return t('Uploads.LabelTooltips.missingPages');
        case OcrLabel.CREDIT_INV_PACKING_SLIP:
            return t('Uploads.LabelTooltips.creditInvPackingSlip');
        case OcrLabel.DUPLICATE_BILL:
            return t('Uploads.LabelTooltips.duplicateBill');
        default:
            return '';
    }
};

export const UploadOcrLabelBadge : React.FC<UploadOcrLabelBadgeProps> = ({ ocrLabel }) => {
    const [showTooltip, setShowTooltip] = React.useState(false);

    const renderBadge = (): React.ReactElement => <div
        onMouseEnter={(): void => setShowTooltip(true)}
        onMouseLeave={(): void => setShowTooltip(false)}>
        <Badge className="inline-block" variant="RED">
            <InfoOutlineIcon className="h-4 mr-1"/>
            {getBadgeText(ocrLabel)}
        </Badge>
    </div>;

    return <Tooltip
        show={showTooltip}
        placement="top"
        trigger={renderBadge()}>
        <>{getBadgeTooltipText(ocrLabel)}</>
    </Tooltip>;
};

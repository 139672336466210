import React from 'react';
import { Banner,
    EBannerType,
    NONE_SELECTED,
    Select,
    Separator,
    Tooltip,
    Typography } from '@notch-ordering/ui-components';
import BackIcon from '@icons/back-icon.svg';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import InfoIcon from '@icons/info-icon.svg';
import { FieldRowProps,
    getDuplicatedColumnsSelected,
    getErrorMessages,
    hasRequiredFields,
    IColumnMap,
    MAP_FIELDS } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStepConstants';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import ColumnNamesTooltipImage from '@v2/assets/images/column-names-tooltip.png';
import { useBulkUploadStore } from '@v2/stores/BulkUploadStore';
import { cleanColumnCharacters } from '@v2/components/BulkUpload/BulkUploadModal/BulkUploadModalConstants';

const SelectFieldRow:React.FC<FieldRowProps> = ({ label, value, handleChange, selectedCol = '', optional, formSubmitted, duplicatedColumnsSelected = [] }) => {
    const [selectedColumn, setSelectedColumn] = React.useState<string>(selectedCol);
    const isRequiredField = !optional && (!selectedColumn || selectedColumn === NONE_SELECTED);
    const isDuplicatedFieldSelected = duplicatedColumnsSelected.includes(selectedColumn);
    const isInvalid = formSubmitted && (isRequiredField || isDuplicatedFieldSelected);
    const { csvColumns } = useBulkUploadStore();
    const onChange = (e: React.ChangeEvent<HTMLSelectElement>):void => {
        setSelectedColumn(e.target.value);
        handleChange({
            [value]: e.target.value === NONE_SELECTED ? '' : e.target.value,
        });
    };

    return (
        <div className="flex gap-4 py-7 relative items-center">
            <div className="w-6/12">
                <Typography as="div" weight="font-medium">{label}{optional && <Typography className="text-gray-600" as="span">&nbsp;({tCommon('optional')})</Typography>}</Typography>
            </div>
            <div>
                <BackIcon className="text-gray-400 -rotate-180 w-4 h-4"/>
            </div>
            <div className="w-7/12">
                <Select required={!optional}
                    isInvalid={isInvalid}
                    value={selectedColumn}
                    placeholder="Select"
                    onChange={onChange}
                    options={csvColumns.map((column) => ({
                        label: column,
                        value: column,
                    }))}/>
            </div>
        </div>
    );
};

export interface BulkMapFieldsStepProps {
    columns: string[],
    missingColumns: string[],
    formSubmitted?: boolean,

}

export const BulkMapFieldsStep:React.FC<BulkMapFieldsStepProps> = ({ missingColumns, formSubmitted }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'BulkUpload' });
    const [contentNameTooltip, setContentNameTooltip] = React.useState<boolean>(false);
    const { columnMapping, setColumnMapping } = useBulkUploadStore();
    const [headerColumnNameTooltip, setHeaderColumnNameTooltip] = React.useState<boolean>(false);
    const duplicatedColumnsSelected = getDuplicatedColumnsSelected(columnMapping);
    const hasDuplicates = duplicatedColumnsSelected.length > 0;
    const hasRequired = hasRequiredFields(columnMapping);
    const invalidFields = !hasRequired || hasDuplicates;
    const handleChange = (option: IColumnMap):void => {
        setColumnMapping({
            ...columnMapping,
            ...option,
        });
    };

    return (
        <div>
            {formSubmitted && invalidFields
                && <div className="mx-4 lg:mx-10">
                    <Banner alertType={EBannerType.ERROR}
                        title={<Typography weight="font-semibold" as="div">
                            {getErrorMessages(columnMapping, hasRequired, hasDuplicates).title}
                        </Typography>}
                        body={<Typography as="div" className="text-gray-600">
                            {getErrorMessages(columnMapping, hasRequired, hasDuplicates).message}
                        </Typography>}
                        icon={<InfoIcon className="w-5 h-5 text-red-300"/>}
                        isDismissable={false}/></div>}
            <div className="mx-4 lg:mx-10">
                <div className="flex gap-4 py-7 relative items-center">
                    <div className="w-6/12">
                        <Typography as="div" weight="font-medium" className="flex items-center gap-2">
                            { t('contentName')}
                            <Tooltip show={contentNameTooltip}
                                showArrow={false}
                                tooltipClassName="w-56"
                                placement="top"
                                trigger={ <div onMouseLeave={():void => setContentNameTooltip(false)} onMouseEnter={():void => setContentNameTooltip(true)}>
                                    {contentNameTooltip ? <InfoIcon className="w-4 h-4 mt-1.5 text-gray-600"/> : <InfoOutlineIcon className="w-4 h-4 mt-1.5 text-gray-600"/>}
                                </div>}>
                                <Typography>{t('theNameOfTheContentTooltip')}</Typography>
                            </Tooltip>
                        </Typography>
                    </div>
                    <div className="h4 w-4" />
                    <div className="w-7/12">

                        <Typography as="div" weight="font-medium" className="flex items-center gap-2">
                            { t('csvHeaderName')}
                            <Tooltip
                                show={headerColumnNameTooltip}
                                showArrow={false}
                                tooltipClassName="w-48"
                                placement="bottom"
                                trigger={<div onMouseLeave={():void => setHeaderColumnNameTooltip(false)} onMouseEnter={():void => setHeaderColumnNameTooltip(true)}>
                                    {headerColumnNameTooltip ? <InfoIcon className="w-4 h-4 mt-1.5 text-gray-600"/> : <InfoOutlineIcon className="w-4 h-4 mt-1.5 text-gray-600"/>}
                                </div>}>
                                <>
                                    <img src={ColumnNamesTooltipImage} className="mb-2" alt="Column Names Tooltip" />
                                    <Typography>{t('selectColumnHeadersTooltip')}</Typography>
                                </>
                            </Tooltip>

                        </Typography>

                    </div>
                </div>
                <Separator />
            </div>

            {MAP_FIELDS.map(({ optional, alias, label, value }, idx) => (
                <React.Fragment key={value}>
                    <div className="mx-4 lg:mx-10">
                        <SelectFieldRow
                            formSubmitted={formSubmitted}
                            handleChange={handleChange}
                            optional={optional}
                            selectedCol={missingColumns.includes(cleanColumnCharacters(alias)) ? '' : alias}
                            label={label}
                            value={value}
                            duplicatedColumnsSelected={duplicatedColumnsSelected}/>
                        {idx !== MAP_FIELDS.length - 1 && <Separator />}
                    </div>

                </React.Fragment>
            ))}
        </div>
    );
};

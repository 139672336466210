/* SVG Icons currenty used in the application for now.
 *  Design Team is working on a consolidated Icon Set, once that happens, we can delete this file and use the new Icon Library.
 * By Importing from this file we have a single source of truth for current icons, which means refactoring will be easier in the future
 * since we will see all the places that import from this file and be able to update the icons appropriately
 */
import React from 'react';

export const BackArrow = () => (
  <svg viewBox="0 0 11 9">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-285.000000, -108.000000)" fillRule="nonzero">
        <g transform="translate(285.000000, 103.000000)">
          <path
            d="M5.11683737,14.1718018 C5.10056028,14.1705551 5.09153701,14.1696913 5.08252779,14.1686739 C5.05406282,14.1654625 5.02565055,14.1606984 4.99751437,14.1543914 C4.98668046,14.1519687 4.97549638,14.1492122 4.96436475,14.14621 C4.93977732,14.1395686 4.91574011,14.1318387 4.89204209,14.1229392 C4.87435549,14.116299 4.85679168,14.1089771 4.83945568,14.1009988 C4.82299403,14.093437 4.80624777,14.0850161 4.78975428,14.0759669 C4.77490707,14.0677985 4.76106962,14.0596298 4.74743704,14.0510067 C4.72841161,14.0389967 4.70920672,14.0256644 4.69048988,14.0113908 C4.68840184,14.0097977 4.68648516,14.0083213 4.68457371,14.0068351 L4.60959892,13.9406295 L1.2330987,10.5641293 C0.922300434,10.2533311 0.922300434,9.74942751 1.2330987,9.43862925 C1.5199894,9.15173854 1.97141952,9.12967003 2.28362398,9.3724237 L2.35859877,9.43862925 L4.376,11.455349 L4.37650022,4.96819769 C4.37650022,4.52866257 4.73281383,4.17234895 5.17234895,4.17234895 C5.58048871,4.17234895 5.91687114,4.47957855 5.96284343,4.87538491 L5.96819769,4.96819769 L5.968,11.455349 L7.98609913,9.43862925 C8.2968974,9.12783098 8.80080094,9.12783098 9.11159921,9.43862925 C9.39848991,9.72551995 9.42055843,10.1769501 9.17780476,10.4891545 L9.11159921,10.5641293 L5.73509899,13.9406295 L5.73509899,13.9406295 C5.66815782,14.0075707 5.5922584,14.0600938 5.51125967,14.0981987 C5.38715413,14.1565893 5.25103932,14.1811285 5.11683737,14.1718018 Z"
            transform="translate(5.172349, 9.172349) rotate(-270.000000) translate(-5.172349, -9.172349) "
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export const LeftArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.075 34.075"
    xmlSpace="preserve"
  >
    <path d="M24.57 34.075a1.964 1.964 0 0 1-1.396-.577L8.11 18.432a1.972 1.972 0 0 1 0-2.79L23.174.578a1.973 1.973 0 1 1 2.791 2.79l-13.67 13.669 13.67 13.669a1.974 1.974 0 0 1-1.395 3.369z" />
  </svg>
);

export const RightArrow = () => (
  <svg viewBox="0 0 185.343 185.343" xmlSpace="preserve">
    <path d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z" />
  </svg>
);

export const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <g
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m1.375 12.624 11.25-11.25M12.625 12.624 1.375 1.374" />
    </g>
  </svg>
);

export const Trash = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path d="M 24 4 C 20.491685 4 17.570396 6.6214322 17.080078 10 L 10.238281 10 A 1.50015 1.50015 0 0 0 9.9804688 9.9785156 A 1.50015 1.50015 0 0 0 9.7578125 10 L 6.5 10 A 1.50015 1.50015 0 1 0 6.5 13 L 8.6386719 13 L 11.15625 39.029297 C 11.427329 41.835926 13.811782 44 16.630859 44 L 31.367188 44 C 34.186411 44 36.570826 41.836168 36.841797 39.029297 L 39.361328 13 L 41.5 13 A 1.50015 1.50015 0 1 0 41.5 10 L 38.244141 10 A 1.50015 1.50015 0 0 0 37.763672 10 L 30.919922 10 C 30.429604 6.6214322 27.508315 4 24 4 z M 24 7 C 25.879156 7 27.420767 8.2681608 27.861328 10 L 20.138672 10 C 20.579233 8.2681608 22.120844 7 24 7 z M 11.650391 13 L 36.347656 13 L 33.855469 38.740234 C 33.730439 40.035363 32.667963 41 31.367188 41 L 16.630859 41 C 15.331937 41 14.267499 40.033606 14.142578 38.740234 L 11.650391 13 z M 20.476562 17.978516 A 1.50015 1.50015 0 0 0 19 19.5 L 19 34.5 A 1.50015 1.50015 0 1 0 22 34.5 L 22 19.5 A 1.50015 1.50015 0 0 0 20.476562 17.978516 z M 27.476562 17.978516 A 1.50015 1.50015 0 0 0 26 19.5 L 26 34.5 A 1.50015 1.50015 0 1 0 29 34.5 L 29 19.5 A 1.50015 1.50015 0 0 0 27.476562 17.978516 z" />
  </svg>
);

export const DefaultImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z" />
  </svg>
);

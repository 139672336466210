import { toastr } from 'react-redux-toastr';

import * as DataAPI from '../../../api/DataAPI';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const resetPassword = ({
  email,
  password1,
  password2,
  passwordResetCode,
}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_PASSWORD_REQUEST });

      const response = await DataAPI.resetPassword({
        email,
        password1,
        password2,
        passwordResetCode,
      });

      if (response && response.data) {
        dispatch({ type: RESET_PASSWORD_SUCCESS });

        return response.data;
      } else {
        dispatch({ type: RESET_PASSWORD_FAILED });
      }
    } catch (error) {
      dispatch({ type: RESET_PASSWORD_FAILED });

      let errorMessage = '';
      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`${errorMessage}`);
    }
  };
};

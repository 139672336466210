import React, { useState, useEffect } from 'react';
import { Banner, Button, EBannerType, Loading, Separator, Typography, toast } from '@notch-ordering/ui-components';
import { addNewItemtoCatalog, checkForDuplicateInvoices,
    createCatalogProduct,
    createNewProductLineItems,
    sanitizeSKU,
    useOcrInvoiceStore,
    getOfflineInvoiceData,
    updateInvoice,
    getAccountingConfiguration,
    EApPlatform,
    EErpPlatform,
    LineItemCategory,
    LineItemCategoryEnum,
    EConfigurationType } from '@notch-ordering/shared-logic';
import { Tabs, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { EPaths, ESettingsPaths } from '@v2/constants/EPaths';
import InfoIcon from '@icons/info-icon.svg';
import { ScanOverviewDuplicatedInvoiceModal } from '@v2/components/ScanReview/ScanOverview/ScanOverviewDuplicatedInvoiceModal';
import { ScanOverviewOneInvoiceExistsForThisOrderModal } from '@v2/components/ScanReview/ScanOverview/ScanOverviewOneInvoiceExistsForThisOrderModal';
import { ScanOverviewMoreThanOneInvoiceExistsForThisOrderModal } from '@v2/components/ScanReview/ScanOverview/ScanOverviewMoreThanOneInvoiceExistsForThisOrderModal';
import { logError } from '@v2/utils/logError';

import { useGetBillsQuery } from '@v2/Pages/Invoices/BillsQueries.hook';
import { useQuery } from '@tanstack/react-query';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { getInitialSyncComplete } from '@v2/network/BushwhackAPI';
import { integrationNameKeys } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import { tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';

import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { IProduct } from '@notch-ordering/shared-logic/dist/src/types/IProduct';
import { Link } from 'react-router';
import useAccount from '@v2/hooks/useAccount.hook';
import { AccountData } from '@v2/types/AccountData';
import { getLineItemCategory, getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';
import { listInvoices } from '@v2/network/GreevilsGreedApi';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { SyncConfirmationModal } from '../SyncConfirmationModal';

type Props = {
    accountingSynced: boolean,
    imsSynced: boolean,
};

/**
 * A component that displays the bottom navigation of the Scan Review page, including "Save and Exit" and "Approve and Sync" buttons.
 *
 * @returns JSX.Element
 */
export const ScanOverviewBottomNavigation: React.FC<Props> = ({ accountingSynced, imsSynced }) => {
    const { getNewLineItems, draftInvoice, formErrors, invoices, invoiceTopLevelDataErrors, updateLineItemPrimaryData, updateLinkedCatalogData } = useOcrInvoiceStore();
    const { onSaveOcrInvoice, router, isSavingOcrInvoice, selectedTab, setIsSavingOcrInvoice, hasImsIntegration } = useOcrInvoiceContext();
    const isSaveButtonDisabled = isSavingOcrInvoice;
    const { buyer } = useBuyerHook();
    const [syncIMS, setSyncIMS] = useState<boolean>(false);
    const [syncAccounting, setSyncAccounting] = useState<boolean>(false);
    const [isLoadingSync, setIsLoadingSync] = useState<boolean>(false);
    const [isLoadingNext, setIsLoadingNext] = useState<boolean>(false);
    const { firstName, lastName }: AccountData = useAccount();
    const [showSyncConfirmationModal, setShowSyncConfirmationModal] = useState(false);
    const [showDuplicatedInvoiceModal, setShowDuplicatedInvoiceModal] = useState(false);
    const [showOneInvoiceExistsForThisOrderModal, setOneInvoiceExistsForThisOrderModal] = useState(false);
    const [invoicesWithSameOrder, setInvoicesWithSameOrder] = useState(null);
    const [showMoreThanOneInvoiceExistsForThisOrderModal, setMoreThanOneInvoiceExistsForThisOrderModal] = useState(false);
    const shouldNavigateToNextScanAfterSyncRef = React.useRef(false);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const accountingIntegration = useQuery(['FETCH_ACCOUNTING_INTEGRATIONS', buyer.urlsafe], () => getAccountingConfiguration(buyer.urlsafe), { enabled: !!buyer.urlsafe, cacheTime: 0, retry: 1 });
    const accountingPlatform = accountingIntegration?.data?.data?.platform;
    const accessToken = accountingIntegration?.data?.data?.accessToken;
    const integrationType = accountingPlatform ? t(integrationNameKeys(accountingPlatform)) : '';
    const isPlatformEnabled = !!buyer.urlsafe && !!accessToken && !!accountingPlatform;
    const isInitialIntegrationSyncComplete = useQuery(['FETCH_ACCOUNTING_INTEGRATION_INITIAL_SYNC', buyer.urlsafe], () => getInitialSyncComplete(buyer.urlsafe, accountingPlatform, accessToken), { enabled: isPlatformEnabled, cacheTime: 0, retry: 1 });
    const isIntegrationSetupComplete = accountingIntegration?.data?.data ? accountingIntegration.data.data.taxCode?.id : true;

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategoryToValidate = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);
    const hasFormErrors = formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount > 0;
    const hasInvoiceTopLevelDataErrors = invoiceTopLevelDataErrors?.totalCount > 0;
    const hasAccountingVendorID = !!draftInvoice?.topLevelData?.accountingVendorID || getAccountingConfigurationType(draftInvoice.accountingConfiguration) === EApPlatform.BIGCHIP; // Big chip doesn't need vendor ID set
    const hasAccountingMissingFields = draftInvoice?.isQBOEnabled && !hasAccountingVendorID;
    const hasBasicInfoMissingFields = !draftInvoice?.topLevelData?.externalReferenceID;
    const buyerUrlsafeKey = draftInvoice?.invoiceData?.buyerUrlsafeKey || '';
    const orderUrlsafeKey = draftInvoice?.invoiceData?.orderUrlsafeKey || '';
    const accountName = `${firstName} ${lastName}`;
    const isBigChip = draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip;

    const isOrderDataLoading = invoicesWithSameOrder === null && orderUrlsafeKey !== '';
    const isApproveAndSyncDisabled = hasFormErrors || isSaveButtonDisabled || hasAccountingMissingFields || hasBasicInfoMissingFields || isOrderDataLoading || hasInvoiceTopLevelDataErrors;

    const invoicesWithOrder = useGetBillsQuery({ buyerUrlsafeKey, filters: { orderUrlsafeKey }, pagination: { page: 1, limit: 2 } });

    const lineItemCategory: LineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, false, hasImsIntegration);

    const invoiceIndex = invoices?.results.findIndex((inv) => inv.id === draftInvoice.invoiceID);
    const currentInvoicePosition = invoiceIndex + 1;
    const totalInvoices = invoices?.total ?? 0;

    const { data: invoiceResponse } = useQuery(
        ['SYNCED_GREEVILS_BILL', draftInvoice?.topLevelData?.invoiceNumber],
        () => listInvoices(1, buyer.urlsafe, { search: [draftInvoice?.topLevelData?.invoiceNumber], supplierUrlsafeKey: draftInvoice?.invoiceData?.supplierUrlsafeKey }),
        {
            enabled: !!draftInvoice?.topLevelData?.invoiceNumber,
            refetchOnWindowFocus: false,
        }
    );

    const invoiceData = invoiceResponse?.results[0];
    const isAtLeastPartiallySynced = !!draftInvoice?.rawInvoiceData?.imsSyncAt || !!draftInvoice?.rawInvoiceData?.accountingSyncAt;
    const navigateNextScan = (): void => {
        const targetInvoice = invoices?.results[invoiceIndex + 1];
        if (targetInvoice) {
            router.push(EPaths.SCANS_REVIEW.replace(':invoiceKey', targetInvoice.id));
        }
    };

    let approveAndSyncText;
    switch (selectedTab) {
        case Tabs.IMS:
            // They have more than 1 integration they are in the IMS tab
            approveAndSyncText = `Sync bill to ${t(`${integrationNameKeys(EErpPlatform.OPTIMUMCONTROL)}`)}`;
            break;
        case Tabs.ACCOUNTING:
            // They have more than 1 integration and they are in the Accounting tab
            if (draftInvoice?.accountingConfiguration?.type !== EConfigurationType.BigChip) {
                approveAndSyncText = `Sync bill to ${t(`${integrationNameKeys(draftInvoice?.accountingConfiguration?.data?.platform)}`)}`;
            } else {
                approveAndSyncText = 'Approve bill';
            }
            break;
        case Tabs.NO_TABS:
        default:
            // They have 1 or 0 integrations, so check
            if (draftInvoice?.accountingConfiguration?.isEnabled && draftInvoice?.accountingConfiguration?.type !== EConfigurationType.BigChip) {
                approveAndSyncText = `Sync bill to ${t(`${integrationNameKeys(draftInvoice?.accountingConfiguration?.data?.platform)}`)}`;
            } else if (hasImsIntegration) {
                approveAndSyncText = `Sync bill to ${t(`${integrationNameKeys(EErpPlatform.OPTIMUMCONTROL)}`)}`;
            } else {
                approveAndSyncText = 'Approve bill';
            }
            break;
    }

    useEffect(() => {
        if (invoicesWithOrder?.data?.results && orderUrlsafeKey !== '') {
            setInvoicesWithSameOrder(invoicesWithOrder.data.results);
        }
    }, [invoicesWithOrder?.data?.results, orderUrlsafeKey]);

    const onClickSaveAndExit = (): void => {
        onSaveOcrInvoice({
            onSuccess: () => {
                router.push(EPaths.UPLOADS);
            },
            ocrState: draftInvoice?.invoiceData?.ocrState,
            verifyOnSave: false,
            syncAccountingOnSave: false,
            syncIMSOnSave: false,
            hasAccountingIntegration: accountingIntegration?.data?.isEnabled,
            hasIMSIntegration: !!hasImsIntegration,
            accountingSyncName: null,
            imsSyncName: null,
            lineItemCategory,
            triggeredBy: '[fe-buyer] ScanOverviewBottomNavigation -> onClickSaveAndExit'
        });
    };

    /**
     * @returns false if an error occurred, otherwise true
     */
    const addNewLineItems = async (): Promise<boolean> => {
        const lineItemCategoryForCatalog = lineItemCategory === LineItemCategoryEnum.ChartOfAccountDetails ? LineItemCategoryEnum.LineItems : lineItemCategory;
        const newLineItems = getNewLineItems(lineItemCategoryForCatalog);
        if (!newLineItems?.length) return true;

        const productLineItems = createNewProductLineItems(newLineItems);
        const promises:Promise<IProduct>[] = productLineItems.map((curLineItem) => addNewItemtoCatalog({ catalogID: draftInvoice?.catalogID,
            product: createCatalogProduct({
                product: curLineItem,
                catalogPriceListID: draftInvoice?.catalogPriceListID,
                catalogID: draftInvoice?.catalogID,
            }),
            priceListID: draftInvoice?.catalogPriceListID,
            productSku: sanitizeSKU(curLineItem.sku), }));

        try {
            await Promise.all(promises).then((lineItems) => {
                lineItems.forEach((lineItem, index) => {
                    const currentLineItem = newLineItems[index];
                    if (lineItem?.packages?.length > 0) {
                        updateLinkedCatalogData(currentLineItem.reactKey, lineItem?.packages[0], lineItem.taxCode, lineItemCategoryForCatalog);
                        const packageID = lineItem?.packages[0]?.id;
                        const sku = lineItem?.packages[0]?.sku;
                        updateLineItemPrimaryData(currentLineItem.reactKey, 'packageID', packageID, lineItemCategoryForCatalog);
                        if (sku) {
                            updateLineItemPrimaryData(currentLineItem.reactKey, 'sku', sku, lineItemCategoryForCatalog);
                        }
                    }
                });
            });
        } catch (err) {
            logError('Unable to add new item to catalog: ', err, false);
            return false;
        }

        return true;
    };

    // A new offline invoice (bill) will be created when
    // ocrState is updated to verified on the ocr invoice
    const saveToNewInvoiceAndSync = async (): Promise<void> => {
        onSaveOcrInvoice({
            ocrState: 'verified',
            onSuccess: () => {
                setIsSavingOcrInvoice(false);
                setIsLoadingNext(false);
                setIsLoadingSync(false);
                if (shouldNavigateToNextScanAfterSyncRef.current && currentInvoicePosition < totalInvoices) {
                    navigateNextScan();
                } else if (accountingSynced || imsSynced || selectedTab === Tabs.NO_TABS || (draftInvoice?.invoiceData?.ocrState === 'verified' && isBigChip)) {
                    router.push(EPaths.UPLOADS);
                } else {
                    window.location.reload();
                }
            },
            onError: () => {
                setIsSavingOcrInvoice(false);
                setIsLoadingNext(false);
                setIsLoadingSync(false);
            },
            verifyOnSave: true,
            syncAccountingOnSave: syncAccounting,
            syncIMSOnSave: syncIMS,
            hasAccountingIntegration: accountingIntegration?.data?.isEnabled,
            hasIMSIntegration: !!hasImsIntegration,
            ...(syncAccounting && { accountingSyncName: accountName }),
            ...(syncIMS && { imsSyncName: accountName }),
            lineItemCategory,
            triggeredBy: '[fe-buyer] ScanOverviewBottomNavigation -> saveToNewInvoiceAndSync'
        });
    };

    /*
        The getOfflineInvoiceData function gets the info off the draft invoice in the
        invoice store and formats it to be sent to greevils api (bill/offline invoice).
        Then the only related bill (invoicesWithSameOrder[0]) is updated and the ocr
        invoice is set to archived if the update is successful.
     */
    const saveToExistingInvoiceAndSync = async (): Promise<void> => {
        const accountingSyncName = syncAccounting ? accountName : null;
        const imsSyncName = syncIMS ? accountName : null;
        const invoiceDataForUpdate = getOfflineInvoiceData(syncAccounting, syncIMS, accountingSyncName, imsSyncName, lineItemCategory);
        const existingInvoice = invoicesWithSameOrder[0];

        // If invoice has not already been approved for Big Chip, then approve it if
        // user has an active Big Chip integration and this is not a sync.
        if (existingInvoice.isApprovedForBigChip !== true) {
            invoiceDataForUpdate.isApprovedForBigChip = !syncIMS && draftInvoice.accountingConfiguration.type === EConfigurationType.BigChip;
        } else {
            invoiceDataForUpdate.isApprovedForBigChip = existingInvoice.isApprovedForBigChip;
        }

        try {
            await updateInvoice(existingInvoice.id, invoiceDataForUpdate);
            onSaveOcrInvoice({
                ocrState: 'archived',
                onSuccess: () => {
                    router.push(EPaths.UPLOADS);
                },
                verifyOnSave: true,
                syncAccountingOnSave: syncAccounting,
                syncIMSOnSave: syncIMS,
                hasAccountingIntegration: accountingIntegration?.data?.isEnabled,
                hasIMSIntegration: !!hasImsIntegration,
                ...(syncAccounting && { accountingSyncName: accountName }),
                ...(syncIMS && { imsSyncName: accountName }),
                lineItemCategory,
                triggeredBy: '[fe-buyer] ScanOverviewBottomNavigation -> saveToExistingInvoiceAndSync'
            });
        } catch (error) {
            logError('Unable to update bill: ', error, false);
            toast.show({
                message: t('unableToUpdateBill'),
                showClose: false
            });
            setIsSavingOcrInvoice(false);
        }
    };

    /*
        This function decides if modals should be shown for user choices during saving
        IF there are:
            no duplicates,
            no associated orders,
            or no other invoices associated with the order connected to this this draft invoice
        THEN set the ocr invoice as verified and create a new offline invoice (bill)
        OTHERWISE show the appropriate modal
    */
    const onClickApproveAndSync = async (): Promise<void> => {
        setIsSavingOcrInvoice(true);

        await addNewLineItems();
        // This commented out code below blocks saving if the line item can't be added to the catalog, which
        // might be too harsh so disabling for now.
        /*
        const isAddedNewLineItems = await addNewLineItems();
        if (!isAddedNewLineItems) {
            toast.show({
                message: 'Unable to add new item to catalog',
                showClose: false
            });
            setIsSavingOcrInvoice(false);
            setIsLoadingNext(false);
            setIsLoadingSync(false);
            return;
        }
        */

        const hasDuplicates = await checkForDuplicateInvoices();
        const readyForSave = !hasDuplicates && (invoicesWithSameOrder === null || invoicesWithSameOrder.length === 0);

        if (readyForSave) {
            await saveToNewInvoiceAndSync();
        } else if (hasDuplicates) {
            setShowDuplicatedInvoiceModal(true);
        } else if (invoicesWithSameOrder.length === 1) {
            setOneInvoiceExistsForThisOrderModal(true);
        } else if (invoicesWithSameOrder.length > 1) {
            setMoreThanOneInvoiceExistsForThisOrderModal(true);
        }
    };

    return <><div className="sticky bottom-0 bg-white mt-auto">
        <Separator variant="small"/>
        { (!isInitialIntegrationSyncComplete || !isIntegrationSetupComplete)
            && <div className="w-full mt-6 px-8">
                <Link to={`${ESettingsPaths.INTEGRATIONS}`}>
                    <Banner alertType={EBannerType.WARNING}
                        body={<Typography as="div" className="text-gray-600">
                            {!isInitialIntegrationSyncComplete ? t('integrationNotSynced', { integrationType }) : t('integrationNotSetup', { integrationType })}
                        </Typography>}
                        icon={<InfoIcon className="w-4 h-4 text-orange-300"/>}
                        isDismissable={false}/>
                </Link>
            </div>
        }
        <div className="flex w-full justify-between py-6 px-8 ">
            <Button disabled={isSaveButtonDisabled}
                onClick={onClickSaveAndExit}
                variant="TERTIARY_FILLED"
                size="SMALL">
                {isSavingOcrInvoice ? <Loading isDark /> : 'Save and Exit'}
            </Button>
            <Button
                disabled={isApproveAndSyncDisabled}
                onClick={(): void => {
                    if (selectedTab === Tabs.IMS || (selectedTab === Tabs.NO_TABS && hasImsIntegration)) {
                        sendTrackingEvent({ ...TrackingEvents.imsSyncButtonClicked, label: TrackingLabel.OCR });
                        setSyncIMS(true);
                        setSyncAccounting(false);
                        setShowSyncConfirmationModal(true);
                    } else if ((selectedTab === Tabs.ACCOUNTING) || (selectedTab === Tabs.NO_TABS && draftInvoice?.accountingConfiguration?.isEnabled)) {
                        sendTrackingEvent({ ...TrackingEvents.accountingSyncButtonClicked, label: TrackingLabel.OCR });
                        setSyncAccounting(!isBigChip);
                        setSyncIMS(false);
                        setShowSyncConfirmationModal(true);
                    } else {
                        setSyncIMS(false);
                        setSyncAccounting(false);
                        onClickApproveAndSync();
                    }
                }}
                variant="SECONDARY"
                size="SMALL">
                {isSavingOcrInvoice ? <Loading isDark /> : approveAndSyncText}
            </Button>

        </div>
        <ScanOverviewDuplicatedInvoiceModal invoiceNumber={draftInvoice?.topLevelData?.externalReferenceID}
            close={():void => {
                setShowDuplicatedInvoiceModal(false);
                setIsSavingOcrInvoice(false);
            }}
            isOpen={showDuplicatedInvoiceModal}
            onConfirm={ ():void => {
                setShowDuplicatedInvoiceModal(false);
                saveToNewInvoiceAndSync();
            }}/>
        <ScanOverviewMoreThanOneInvoiceExistsForThisOrderModal orderNumber={draftInvoice?.invoiceData?.orderNumber}
            close={():void => {
                setMoreThanOneInvoiceExistsForThisOrderModal(false);
                setIsSavingOcrInvoice(false);
            }}
            isOpen={showMoreThanOneInvoiceExistsForThisOrderModal}
            onCreateNew={ ():void => {
                setMoreThanOneInvoiceExistsForThisOrderModal(false);
                saveToNewInvoiceAndSync();
            }}/>
        <ScanOverviewOneInvoiceExistsForThisOrderModal orderNumber={draftInvoice?.invoiceData?.orderNumber}
            existingInvoiceNumber={invoicesWithSameOrder?.[0]?.invoiceNumber ?? ''}
            close={():void => {
                setOneInvoiceExistsForThisOrderModal(false);
                setIsSavingOcrInvoice(false);
            }}
            isOpen={showOneInvoiceExistsForThisOrderModal}
            onReplace={ ():void => {
                setOneInvoiceExistsForThisOrderModal(false);
                saveToExistingInvoiceAndSync();
            }}
            onCreateNew={ ():void => {
                setOneInvoiceExistsForThisOrderModal(false);
                saveToNewInvoiceAndSync();
            }}/>
    </div>
    { showSyncConfirmationModal && <SyncConfirmationModal isOpen={showSyncConfirmationModal}
        close={(): void => { setShowSyncConfirmationModal(false); }}
        syncOnClick={(shouldNavigateNext: boolean = false): void => {
            shouldNavigateToNextScanAfterSyncRef.current = shouldNavigateNext;
            onClickApproveAndSync();
        }}
        setIsLoadingNext={(): void => {
            setIsLoadingNext(true);
        }}
        setIsLoadingSync={(): void => {
            setIsLoadingSync(true);
        }}
        isLoadingSync = {isLoadingSync}
        isLoadingNext = {isLoadingNext}
        invoiceData={invoiceData}
        isAtLeastPartiallySynced={isAtLeastPartiallySynced}
        draftInvoice={draftInvoice}
        disabled={isApproveAndSyncDisabled}/>
    }
    </>;
};

import React from 'react';
import { Button, Modal, Typography } from '@notch-ordering/ui-components';
import ImgCelebrate from '@v2/assets/images/onboarding-celebrate.svg';
import { DoubleSyncOnboardingSteps, useDoubleSyncOnboardingStore } from '@v2/stores/DoubleSyncOnboardingStore';
import { t } from '@v2/i18n';

export interface ScanDoubleSyncOnboardingModalProps {
    isOpen: boolean,
    onClose: () => void,
}

export const ScanDoubleSyncOnboardingModal : React.FC<ScanDoubleSyncOnboardingModalProps> = ({ isOpen, onClose }) => {
    const { setCurrentStep } = useDoubleSyncOnboardingStore();
    return <Modal
        isOpen={isOpen}
        desktopModalWidth="lg:w-[512px] lg:h-[392px]"
        headerPadding="p-0 hidden"
        close={onClose}>
        <div className="pt-8 px-8 pb-2">
            <div className="text-center">
                <ImgCelebrate className="mx-auto w-40 pt-6"/>
            </div>
            <div className="text-center space-y-2 mt-8">
                <Typography className="font-bold m-0" variant="2XL">
                    {t('ScanReview.dualSyncOnboarding.discoverTheLatestChanges')}
                </Typography>
                <Typography className="text-gray-600 m-0">{t('ScanReview.dualSyncOnboarding.weVeMovedAFewThings')}</Typography></div>
            <div className="text-center mt-10">
                <Button variant="SECONDARY"
                    size="SMALL"
                    onClick={():void => {
                        setCurrentStep(DoubleSyncOnboardingSteps.ChartOfAccounts);
                    }}>{t('ScanReview.dualSyncOnboarding.showMeAround')}</Button>
            </div>
        </div>
    </Modal>;
};

import { SupplierData } from '@v2/network/CoreAPI';
import { useSelector } from 'react-redux';
import { RootState } from '..';

const useGetSupplier = (): {
    getSupplierByID: (id) => SupplierData,
    getSupplierByUrlsafeKey: (urlsafeKey) => SupplierData,
} => {
    const { vendors } = useSelector((store: RootState) => store.vendorsReducer);

    /**
     * Searches the redux store for a supplier with a matching uuid
     *
     * @param id uuid of the supplier
     */
    function getSupplierByID(id: string): SupplierData {
        return vendors.find((vendor) => vendor.id === id);
    }

    /**
     * Searches the redux store for a supplier with a matching uuid
     *
     * @param id uuid of the supplier
     */
    function getSupplierByUrlsafeKey(urlsafeKey: string): SupplierData {
        return vendors.find((vendor) => vendor.urlsafe === urlsafeKey);
    }

    return { getSupplierByID, getSupplierByUrlsafeKey };
};

export default useGetSupplier;

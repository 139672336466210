import { OrderData } from '@v2/types/OrderData';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadOrdersForDeliveryToday } from '@/actions/orderActions';
import { RootState } from '..';

const useCompletedOrdersToday = (): Array<OrderData> => {
    const { completedOrdersToday } = useSelector((store: RootState) => store.ordersReducer);
    const dispatch = useDispatch();

    const getTodaysOrders = useCallback(async () => {
        await dispatch(loadOrdersForDeliveryToday());
    }, [dispatch]);

    useEffect(() => {
        getTodaysOrders();
    }, []);

    return completedOrdersToday;
};

export default useCompletedOrdersToday;

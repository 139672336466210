import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';

import validate from '../../../../redux-form/LocationsForm/validate';
import renderField from '../../../../redux-form/renderField';
import renderFieldDropDown from '../../../../redux-form/renderFieldDropDown';
import renderFieldTextArea from '../../../../redux-form/renderFieldTextArea';
import renderFieldGoogleAutoComplete from '../../../../redux-form/renderFieldGoogleAutoComplete.js';
import { logException } from '../../../../domains/shared/logger';

class LocationsForm extends Component {
  componentDidMount() {
    const { initialValues, onClickCancel } = this.props;

    if (_.isEmpty(initialValues)) {
      // When a billing address for a buyer cannot be found, an error to the user is thrown and
      // the Intercom chat is shown to encourage to contact support through chat to supply their
      // billing information, and the modal is cancelled
      window.Intercom('show');
      onClickCancel();

      toastr.error(
        'Error: A billing address has not been supplied. Please contact support.'
      );
      console.error('An Error occured with LocationsForm');
      logException(new Error('This buyer does not have a billingAddress'));
    }
  }

  // Prevent enter key from submitting form.
  // This might happen with GoogleAutoComplete
  onKeyPress = (event) => {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  };

  // Adds the value given by GoogleAutoComplete as a state for address
  autoFillAddress = (place, fieldName) => {
    // fieldName is shipping address
    // We need to adjust the last part of the field per field

    // Handles parsing the postal code/zip out of GoogleAutoComplete object
    // and storing in the postal code field
    if (
      place &&
      place.address_components &&
      place.address_components.length > 0
    ) {
      const { changeFieldValue } = this.props;
      let address,
        postalCode,
        city,
        state,
        country,
        streetNumber = '';

      place.address_components.forEach((component) => {
        if (component) {
          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          } else if (component.types.includes('route')) {
            // If streetNumber was not found for this address,
            // Just print out the route (i.e street)
            address = streetNumber
              ? `${streetNumber} ${component.long_name}`
              : `${component.long_name}`;
          } else if (component.types.includes('postal_code')) {
            postalCode = component.long_name;
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('country')) {
            country = component.long_name;
          }
        }
      });

      changeFieldValue(fieldName, address);

      const postalCodeField = fieldName.replace('Address', 'PostalCode');
      changeFieldValue(postalCodeField, postalCode);

      const cityField = fieldName.replace('Address', 'City');
      changeFieldValue(cityField, city);

      const stateField = fieldName.replace('Address', 'State');
      changeFieldValue(stateField, state);

      const countryField = fieldName.replace('Address', 'Country');
      changeFieldValue(countryField, country);
    }
  };

  render() {
    const {
      handleSubmit,
      invalid,
      pristine,
      submitting,
      onClickCancel,
      initialValues,
    } = this.props;
    const countryOptions = ['Canada', 'United States'];

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="shippingName"
          className="form-control input-lg"
          component={renderField}
          label="Shipping Name"
          type="text"
        />
        <div className="split-inputs" onKeyPress={this.onKeyPress}>
          <div className="left-lg-input">
            <Field
              name="shippingAddress"
              id="shippingAddress"
              label="Shipping Address"
              type="text"
              className="form-control input-lg"
              autoCompleteType={['geocode']}
              autoFillAddress={this.autoFillAddress}
              component={renderFieldGoogleAutoComplete}
            />
          </div>
          <div className="right-sm-input">
            <Field
              name="shippingUnitNumber"
              className="form-control input-lg"
              component={renderField}
              label="Unit Number"
              type="text"
            />
          </div>
        </div>
        <div className="split-inputs">
          <div className="left-input">
            <Field
              name="shippingPostalCode"
              className="form-control input-lg"
              component={renderField}
              label="Zip/Postal Code"
              type="text"
            />
          </div>
          <div className="right-input">
            <Field
              name="shippingCity"
              className="form-control input-lg"
              component={renderField}
              label="City"
              type="text"
            />
          </div>
        </div>

        <div className="split-inputs">
          <div className="left-input">
            <Field
              name="shippingState"
              className="form-control input-lg"
              component={renderField}
              label="Province"
              type="text"
            />
          </div>
          <div className="right-input" id="countryField">
            <Field
              name="shippingCountry"
              className="form-control input-lg"
              component={renderFieldDropDown}
              label="Country"
              placeholder="-- Please Choose a Country --"
              options={countryOptions}
              defaultValue={
                initialValues.billingCountry ? initialValues.billingCountry : ''
              }
              type="dropdown"
            />
          </div>
        </div>

        <Field
          name="driveInstructions"
          id="driveInstructions"
          className="form-control input-lg"
          hintText="* Where should the driver deliver? Front door/Side door/ Loading Dock etc.&#10;* Lockboxes, doorbells, codes etc?&#10;* Parking instructions?&#10;* Who's receiving the order? What's their phone number?&#10;* Anything else to help us deliver correctly"
          component={renderFieldTextArea}
          label="Delivery Instructions"
          type="text"
        />

        <div className="footer-accounts">
          <button
            type="button"
            className="btn btn-white btn-lg previous pull-left"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg pull-right"
            disabled={invalid || submitting}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

LocationsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
};

export default connect(
  (state) => {
    const { buyer } = state.buyerReducer;
    const { locationsModalIndex } = state.settingsReducer;
    const billingAddress = !_.isEmpty(buyer.billingAddress)
      ? buyer.billingAddress
      : {};

    if (
      buyer.shippingAddresses.length > 0 &&
      buyer.shippingAddresses[locationsModalIndex]
    ) {
      const shippingAddress = buyer.shippingAddresses[locationsModalIndex];

      return {
        initialValues: {
          billingLocationId: billingAddress.id,
          billingName: billingAddress.name,
          billingAddress: billingAddress.line1,
          billingUnitNumber: billingAddress.line2 || '',
          billingPostalCode: billingAddress.zip,
          billingCity: billingAddress.city,
          billingState: billingAddress.state,
          billingCountry: billingAddress.country,

          shippingLocationId: shippingAddress.id || '',
          shippingName: shippingAddress.name || '',
          shippingAddress: shippingAddress.line1 || '',
          shippingUnitNumber: shippingAddress.line2 || '',
          shippingPostalCode: shippingAddress.zip || '',
          shippingCity: shippingAddress.city || '',
          shippingState: shippingAddress.state || '',
          shippingCountry: shippingAddress.country || '',
          driveInstructions: shippingAddress.driveInstructions || '',
        },
      };
    } else {
      if (!_.isEmpty(billingAddress)) {
        return {
          initialValues: {
            billingLocationId: billingAddress.id,
            billingName: billingAddress.name,
            billingAddress: billingAddress.line1,
            billingUnitNumber: billingAddress.line2,
            billingPostalCode: billingAddress.zip,
            billingCity: billingAddress.city,
            billingState: billingAddress.state,
            billingCountry: billingAddress.country,
          },
        };
      } else {
        return {
          initialValues: {},
        };
      }
    }
  },
  (dispatch) => {
    return {
      // This will be passed as a property to the presentational component
      changeFieldValue: (field, value) => {
        dispatch(change('LocationsForm', field, value));
      },
    };
  }
)(
  reduxForm({
    form: 'LocationsForm',
    destroyOnUnmount: true, // preserve form data
    validate,
  })(LocationsForm)
);

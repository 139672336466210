import React, { useMemo, useState } from 'react';
import { debounce } from 'lodash';

import { SearchInput } from '@v2/components/Shared/SearchInput/SearchInput';
import { Typography } from '@notch-ordering/ui-components';
import { EApPlatform, EErpPlatform } from '@notch-ordering/shared-logic';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import { useLegacySuppliers } from '@v2/hooks/useLegacySuppliers.hook';
import CheckIcon from '@icons/check-icon.svg';
import { FilterButton } from '@v2/components/Shared/FiltersButton/FilterButton';
import { useUploadsStore } from '@v2/stores/UploadsStore';
import { UploadsFilterBadges } from '@v2/components/Uploads/UploadFilters/UploadsFilterBadges';
import { tUploadsFilters } from '@v2/Pages/Uploads/UploadsConstants';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { SyncStatusFilter } from './SyncStatusFilter';

const DEBOUNCE_SEARCH_QUERY_DELAY = 100;

export type UploadFiltersProps = {
    onChangeFilters?: () => void,
    erpPlatform?: EErpPlatform,
    accountingPlatform?: EApPlatform,
    shouldShowSyncStatusFilter: boolean,
};

export const UploadsFilters : React.FC<UploadFiltersProps> = ({
    onChangeFilters,
    erpPlatform,
    accountingPlatform,
    shouldShowSyncStatusFilter
}) => {
    const { updateSearchParams, searchParams, resetPagination } = useUploadsStore();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices.Filters' });
    const { legacySuppliers = [] } = useLegacySuppliers();

    const filteredVendorsOptions = useMemo<PopoverItem[]>(() => legacySuppliers.sort((a, b) => a?.name?.localeCompare(b?.name)).map((vendor) => ({
        label: <Typography className="mb-0 flex gap-2 relative items-center">
            <span className="pr-5">{vendor.name}</span>
            {searchParams?.supplierUrlsafeKey?.includes(vendor.urlsafe)
                && <div className="w-4 ml-auto absolute right-0 flex justify-center items-center"><CheckIcon className="w-4 h-4 ml-auto"/></div>}
        </Typography>,
        onClick: (e):void => {
            // this will prevent the popover to close when clicking on the item
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            const supplierUrlsafeKeys = searchParams?.supplierUrlsafeKey ?? [];
            const supplierUrlsafeKey = supplierUrlsafeKeys.includes(vendor.urlsafe)
                ? supplierUrlsafeKeys.filter((vendorID) => vendorID !== vendor.urlsafe)
                : [...supplierUrlsafeKeys, vendor.urlsafe];
            updateSearchParams({ supplierUrlsafeKey });
            sendTrackingEvent({ ...TrackingEvents.supplierFilterNameSelected, label: TrackingLabel.UPLOADS });
            resetPagination();

            if (onChangeFilters) {
                onChangeFilters();
            }
        }
    })), [searchParams?.supplierUrlsafeKey, legacySuppliers]);

    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        if (query === '') {
            updateSearchParams({ externalReferenceID: undefined });
            return;
        }
        updateSearchParams({ externalReferenceID: query });
        resetPagination();
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);

    const handleQueryChange = (query: string = ''):void => {
        setSearchQuery(query);
        debounceSearchQuery(query);
        if (onChangeFilters) {
            onChangeFilters();
        }
    };

    const handleOnFocus = ():void => {
        sendTrackingEvent({ ...TrackingEvents.searchFocus, label: TrackingLabel.UPLOADS });
    };

    return <div className="flex flex-col gap-4">
        <div className="w-full flex flex-wrap gap-x-2">
            <SearchInput onChange={handleQueryChange}
                onFocus={handleOnFocus}
                value={searchQuery}
                inputProps={{
                    placeholder: tUploadsFilters('searchByBillNumber'),
                }
                } />
            <div className={shouldShowSyncStatusFilter ? 'w-full flex gap-x-2 mt-3 flex-wrap' : ''}>
                <FilterButton label={t('supplier')}
                    items={filteredVendorsOptions}
                    size={'SMALL'}
                    className="max-h-80 overflow-auto"
                    placement="bottom-start"/>
                <SyncStatusFilter
                    onChangeFilters={onChangeFilters}
                    erpPlatform={erpPlatform}
                    accountingPlatform={accountingPlatform}
                    shouldShowSyncStatusFilter={shouldShowSyncStatusFilter}/>
            </div>
        </div>
        <UploadsFilterBadges/>
    </div>;
};

import AddIcon from '@icons/add-icon.svg';
import { SupplierInitials } from '@v2/components/Shared/SupplierCard/SupplierInitials';
import { Button, Separator, Skeleton } from '@notch-ordering/ui-components';
import React from 'react';

export const DiscoveredSupplierSkeleton: React.FC = () => (<div>
    <div className="h-24 flex items-center justify-between">
        <div className="flex items-center gap-x-5 flex-auto">
            <SupplierInitials name="" />
            <Skeleton size="medium" />
        </div>
        <Button variant="TERTIARY_OUTLINED"
            size="ICON_MEDIUM"
            className="flex items-center justify-center"
            disabled>
            <div className="h-4 w-4"><AddIcon/></div>
        </Button>
    </div>
    <Separator />
</div>);

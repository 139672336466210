import React, { useState } from 'react';
import { Button, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import UploadsEmptyImage from '@images/uploads-empty.svg';
import AddCreditCardModal from '@v2/components/Shared/AddCreditCard/AddCreditCardModal';
import useBuyerHook from '@v2/hooks/useBuyer.hook';

export interface UploadsEmptyProps {
    onClickUploadBill: () => void,
}

export const UploadsEmptyState : React.FC<UploadsEmptyProps> = ({ onClickUploadBill }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Uploads.EmptyState' });
    const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
    const { buyer } = useBuyerHook();
    const buyerHasPaymentMethodConfigured = !!buyer.stripeCustomerID;

    return <>
        <div className="flex flex-col justify-center items-center lg:h-full h-screen p-4 lg:p-0 -mt-14 lg:mt-0">
            <UploadsEmptyImage className="w-40 mb-10" />
            <Typography as="div"
                className="text-center"
                variant="XL"
                desktopSize="text-5"
                weight="font-semibold">{t('addNewBills')}</Typography>
            <Typography as="div" className="text-gray-600 text-center mt-2 mb-16 max-w-[356px]">{t('uploadImages')}</Typography>
            <Button variant="TERTIARY_FILLED"
                onClick={(): void => {
                    if (buyerHasPaymentMethodConfigured) {
                        onClickUploadBill();
                    } else {
                        setIsCreditCardModalOpen(true);
                    }
                }}>
                {t('uploadABill')}
            </Button>
        </div>
        <AddCreditCardModal setIsOpen={setIsCreditCardModalOpen}
            isOpen={isCreditCardModalOpen}
            onSuccess={onClickUploadBill} />
    </>;
};

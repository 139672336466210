import { Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { IColumnMap,
    MAP_FIELD_KEYS,
    MAP_FIELDS } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStepConstants';
import { parse, ParseResult, unparse } from 'papaparse';

export const getModalHeader = ({ headline, subHeadline }: { headline: string, subHeadline: string }):React.ReactNode => <> <Typography as="div"
    variant="XL"
    desktopSize="lg:text-4"
    weight="font-semibold"
    className="mb-2">{ headline}</Typography>
<Typography as="div" className="text-gray-600">{subHeadline}</Typography></>;

export const cleanColumnCharacters = (column: string = ''): string => column.replace(/[\n\r]/g, '').toLowerCase();
export const CSV_DEFAULT_COLUMN_NAMES = MAP_FIELDS.map((field) => cleanColumnCharacters(field.alias));
export const getMissingColumns = (columns: string[]): string[] => CSV_DEFAULT_COLUMN_NAMES.filter((column) => !columns.includes(column));

export const setDefaultColumnMap = (columns: string[]): IColumnMap => {
    const columnMap: IColumnMap = {};
    columns.forEach((column) => {
        const field = MAP_FIELDS.find((f) => cleanColumnCharacters(f.alias) === cleanColumnCharacters(column));
        if (field) {
            columnMap[field.value] = column;
        }
    });
    return columnMap;
};

export enum EModalSteps {
    UploadFile = 'UploadFile',
    MapColumns = 'MapColumns',
}

/**
 *
 * Parse csv using paparse
 *
 * @param file
 */
export const parseCSV = (file: File): Promise<ParseResult<unknown>> => new Promise((resolve, reject) => {
    parse(file, {
        header: true,
        skipEmptyLines: true,
        complete(results: ParseResult<unknown>) {
            resolve(results);
        },
        error(error) {
            reject(error);
        },
    });
});

type ProcessCSVColumns = {
    hasMissedColumns: boolean,
    defaultColumnMapping: IColumnMap,
    missedColumns: string[],
    cleanColumns: string[],
    validColumns: string[],
};

/**
 * Process csv columns to determine if the current file has missing columns and set default column map
 *
 * @param columns
 */
export const processCSVColumns = (columns: string[]):ProcessCSVColumns => {
    const validColumns = columns.filter(Boolean);
    const cleanColumns = validColumns.map(cleanColumnCharacters);
    const missedColumns = getMissingColumns(cleanColumns);
    const hasMissedColumns = missedColumns?.length > 0;
    const defaultColumnMapping = setDefaultColumnMap(validColumns);
    return { hasMissedColumns, defaultColumnMapping, missedColumns, cleanColumns, validColumns };
};

type TProcessCSVData = {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    result: ParseResult<any>,
    validCategories: string[],
    validUnits: string[],
    columnMap: IColumnMap,
};

// function to ignore all casing and white spaces
const ignoreCaseAndWhiteSpace = (str: string = ''): string => str.replace(/\s/g, '').toLowerCase();

// this function checks in a list of fields string if field exists and returns the field or passed value
const checkValidField = (field: string, fieldsAccepted: string[]): string => {
    const fieldLowercase = ignoreCaseAndWhiteSpace(field);
    const foundField = fieldsAccepted.find((f) => ignoreCaseAndWhiteSpace(f) === fieldLowercase);
    return foundField ?? field;
};

export const NON_TAXABLE_TAX_CODE = '99999';

const TAX_CODE = {
    FullyTaxed: 'Fully-taxed',
    Exempted: 'Tax-exempted',
};

const TAX_CODES = [TAX_CODE.FullyTaxed, TAX_CODE.Exempted];

const AVAILABILITY = {
    IN_STOCK: 'In stock',
    OUT_OF_STOCK: 'Out of stock',
    DISABLED: 'Disabled'
};

const AVAILABILITIES = [AVAILABILITY.IN_STOCK, AVAILABILITY.OUT_OF_STOCK, AVAILABILITY.DISABLED];

/**
 *
 * This function checks the csv category, unit, taxCode and availability columns and fix casing and whitespaces to use proper values
 *
 * @param result
 * @param validCategories
 * @param validUnits
 * @param columnMap
 */
export const processCSVData = ({ result, validCategories, validUnits, columnMap }: TProcessCSVData):File => {
    const processedData = result.data.map((row) => {
        const category = columnMap[MAP_FIELD_KEYS.productCategory] ? checkValidField(row[columnMap[MAP_FIELD_KEYS.productCategory]], validCategories) : '';
        const unit = columnMap[MAP_FIELD_KEYS.packageUnit] ? checkValidField(row[columnMap[MAP_FIELD_KEYS.packageUnit]], validUnits) : '';
        const taxCode = columnMap[MAP_FIELD_KEYS.productTaxCode] ? checkValidField(row[columnMap[MAP_FIELD_KEYS.productTaxCode]], TAX_CODES) : '';
        const taxCodeRowValue = taxCode === TAX_CODE.Exempted ? NON_TAXABLE_TAX_CODE : taxCode;
        const availability = columnMap[MAP_FIELD_KEYS.packageAvailability] ? checkValidField(row[columnMap[MAP_FIELD_KEYS.packageAvailability]], AVAILABILITIES) : '';
        const availabilityRowValue = parseInt(availability) === 1 || availability === AVAILABILITY.IN_STOCK ? 1 : 0;
        return { ...row,
            [columnMap[MAP_FIELD_KEYS.productCategory]]: category,
            [columnMap[MAP_FIELD_KEYS.packageUnit]]: unit,
            [columnMap[MAP_FIELD_KEYS.productTaxCode]]: taxCodeRowValue,
            [columnMap[MAP_FIELD_KEYS.packageAvailability]]: availabilityRowValue };
    });
    const csv = unparse(processedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    return new File([blob], 'processedCSVData.csv', { type: 'text/csv' });
};

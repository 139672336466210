import React, { useContext, useState } from 'react';
import { Modal, Typography, Button, Separator, onMobileSetLarge } from '@notch-ordering/ui-components';
import { OrderGuideListItem } from '@v2/components/Market/OrderGuideListItem/OrderGuideListItem';
import FileIcon from '@icons/file-icon.svg';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import { OrderGuidesList } from '@v2/components/Market/OrderGuideList/OrderGuideList';
import { TFetchProductsType } from '@v2/network/SecretShopAPI';
import FavoriteEmptyIcon from '@icons/favorite-empty-icon.svg';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

export type OrderGuide = {
    id: string,
    label: string,

};

export interface OrderGuidesListModalProps {
    onClose?: () => void,
    hideCreateOrderGuide?: boolean,
    hideCollections?: boolean,
    isSelectMode?: boolean,
    title?: React.ReactNode,

}

export enum OrderGuidesCollectionType {
    Saved = 'Saved',
    Full = 'Full'
}

export const SwitchOrderGuidesModal : React.FC<OrderGuidesListModalProps> = ({
    onClose = (): void => null,
    hideCreateOrderGuide = false,
    hideCollections = false,
    title = '',

}) => {
    const { modals, setModalVisibility, filters, setFilters, fetchTypeSelected, setFetchTypeSelected, setOrderGuideIDSelected } = useContext(MarketContext);
    const [orderGuideSelected, setOrderGuideSelected] = useState<string>('');

    const closeModal = ():void => {
        setModalVisibility('switchOrderGuide', false);
        onClose();
    };

    const handleSelection = (type: TFetchProductsType): void => {
        setFetchTypeSelected(type);
        setOrderGuideSelected('');
        setFilters({
            ...filters,
            orderGuideID: '',
        });
        closeModal();
    };

    const handleOrderGuideSelection = (orderGuide):void => {
        setOrderGuideSelected(orderGuide);
        setFetchTypeSelected(TFetchProductsType.ORDER_GUIDE);
        setFilters({
            ...filters,
            orderGuideID: orderGuide
        });
        closeModal();
        sendTrackingEvent(TrackingEvents.customOrderGuideClicked);
    };

    return (
        <Modal isOpen={modals.switchOrderGuide}
            close={closeModal}
            onClose={closeModal}
            modalSize={onMobileSetLarge('SMALL')}
            title={title}
            hideHeader>
            <div className="w-full h-full flex flex-col gap-3 overflow-auto">
                <div className="flex gap-6 flex-col">
                    {!hideCollections && (
                        <>
                            <Typography as="div" weight="font-medium" variant="XL" className="px-4 lg:px-5 mt-[60px] lg:mt-5 mb-0 py-0 flex justify-between w-full">
                        Collections
                            </Typography>
                            <OrderGuideListItem
                                id={OrderGuidesCollectionType.Saved}
                                label={'All saved products'}
                                isSelected={fetchTypeSelected === TFetchProductsType.SAVED}
                                icon={<FavoriteEmptyIcon/>}
                                onClick={():void => {
                                    handleSelection(TFetchProductsType.SAVED);
                                    sendTrackingEvent(TrackingEvents.allSavedProductsClicked);
                                }}/>
                            <Separator/>
                            <OrderGuideListItem
                                id={OrderGuidesCollectionType.Full}
                                label={'View full catalog'}
                                icon={<FileIcon />}
                                isSelected={fetchTypeSelected === TFetchProductsType.SEARCH}
                                onClick={():void => {
                                    handleSelection(TFetchProductsType.SEARCH);
                                    sendTrackingEvent(TrackingEvents.viewFullCatalogClicked);
                                }}/>
                            <Separator variant="large"/>
                            <Typography as="div" weight="font-medium" variant="LG-2" className="px-4 lg:px-10 mb-0 py-0 flex justify-between w-full">
                        Order guides
                                {!hideCreateOrderGuide && <Button onClick={
                                    (): void => {
                                        setModalVisibility('addEditOrderGuide', true);
                                        setOrderGuideIDSelected(null);
                                    }
                                }
                                variant="LINK"
                                size="NO_BACKGROUND">
                                    <span className="text-3 lg:text-2">Create</span></Button>}
                            </Typography>
                        </>
                    )}
                    <OrderGuidesList orderGuidesSelected={[orderGuideSelected]}
                        onSelectOrderGuide={handleOrderGuideSelection} />
                </div>

            </div>
        </Modal>
    );
};

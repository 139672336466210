import React from 'react';
import { Button, Separator, Typography } from '@notch-ordering/ui-components';
import CloseIcon from '@icons/close-icon.svg';

export interface ScanReviewItemProps {
    icon: React.ReactNode,
    title: React.ReactNode,
    description?: React.ReactNode,
    buttonText?: string,
    onClick: (e?: React.MouseEvent) => void,
    separator?: boolean,
    showClose?: boolean,
}

export const ScanOverviewItem : React.FC<ScanReviewItemProps> = ({ icon, title, description, onClick, separator, buttonText, showClose }) => <>
    <div className="px-8 py-6 flex gap-5 items-center">
        {icon}
        <div className="flex gap-1 flex-col">
            <Typography className="text-gray-700 m-0" weight="font-medium" >{title}</Typography>
            {description && <Typography className="text-gray-600 m-0" weight="font-regular">{description}</Typography>}
        </div>
        {!!buttonText && <Button variant="TERTIARY_FILLED" size="SMALL" className="ml-auto whitespace-nowrap" onClick={onClick}>{buttonText}</Button>}
        {showClose && <Button onClick={onClick} size="SMALL" variant="SUBDUED_ICON" className="ml-auto p-0 "><CloseIcon className="w-4 h-4 text-gray-500 ml-auto block " /></Button>}
    </div>
    {separator && <Separator variant="small"/>}
</>;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';
import * as Intercom from '../../third-party/Intercom';

export default class BuyerSuspendedModal extends Component {
  onClickSupport = () => {
    Intercom.open();
  };

  render() {
    const { onCloseModal } = this.props;

    return (
      <div className="buyer-suspended-modal">
        <div className="modal-container">
          {/* Modal header */}
          <div className="modal-header">
            <button onClick={onCloseModal} className="btn pull-right btn-link">
              <i className="fa fa-times"></i>
            </button>
            <div className="modal-header-text warning">
              Checkout Not Permitted
            </div>
          </div>

          {/* Modal body */}
          <div className="modal-body">
            <div className="text-block">
              Sorry, your account cannot checkout at this time
            </div>
            <div className="text-block">
              please contact
              <Link to={null} onClick={this.onClickSupport}>
                {' '}
                customer support{' '}
              </Link>
              for more details.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BuyerSuspendedModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
};

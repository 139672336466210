import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import GoogleAutoComplete from '../components/general/GoogleAddressAutoComplete.jsx';

export default class renderFieldGoogleAutoComplete extends Component {
  // Triggers when autoComplete is selected
  onAutoComplete = (place, fieldName = '') => {
    const { input, autoCompleteType, autoFillAddress } = this.props;

    if (autoCompleteType[0] === 'geocode' && fieldName) {
      autoFillAddress(place, fieldName);
    } else {
      input.onBlur(place);
    }
  };

  render() {
    const {
      className,
      input,
      meta: { active, touched, error },
    } = this.props;

    return (
      <div>
        <GoogleAutoComplete
          {...this.props}
          value={input.value}
          className={classNames('form-control', className)}
          onAutoComplete={this.onAutoComplete}
        />
      </div>
    );
  }
}

renderFieldGoogleAutoComplete.propTypes = {
  input: PropTypes.object.isRequired,
  autoCompleteType: PropTypes.array.isRequired,

  autoFillAddress: PropTypes.func,
};

import { Transition } from '@headlessui/react';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { tCommon } from '@v2/i18n';
import { useSetupStore } from '@v2/stores/SetupStore';
import cx from 'classnames';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Typography, Separator } from '@notch-ordering/ui-components';
import { BUTTON_VARIANTS } from '@notch-ordering/ui-components/dist/components/Button/ButtonConstants';

type Props = {
    children?: React.ReactNode,
    header?: React.ReactNode,
    mobileMaxHeight?: number,
    back?: () => void,
    next?: () => void,
    onExpand?: (boolean) => void,
    onScrolledToBottom?: () => void,
    nextButtonDisabled?: boolean,
    nextButtonLabel?: string,
    nextButtonVariant? : keyof typeof BUTTON_VARIANTS,
    totalSteps?: number,
    currentStep?: number,
    footerMessage?: string,
    isBusy?: boolean,
    alignTop?: boolean,
    drawerEnabled?: boolean,
};

/**
 * Used to contain a list of buttons or other interactable elements.
 * Appears as a right-side panel on desktop and a bottom sliding drawer on mobile.
 *
 * Based on: https://tailwindui.com/components/application-ui/overlays/modals#component-47a5888a08838ad98779d50878d359b3
 */
export const ActionsPanel: React.FC<Props> = ({
    children,
    header,
    mobileMaxHeight = 220,
    back,
    next,
    onExpand,
    onScrolledToBottom,
    nextButtonDisabled = true,
    nextButtonLabel = tCommon('Buttons.next'),
    nextButtonVariant = 'SECONDARY',
    alignTop = false,
    drawerEnabled = true,
    totalSteps,
    currentStep,
    footerMessage,
    isBusy
}) => {
    const [currentMaxHeight, setCurrentMaxHeight] = useState(mobileMaxHeight);
    const [isExpanded, setIsExpanded] = useState(false);
    const safeRef = useRef<HTMLDivElement>(null);
    const isMobileWidth = useIsMobileWidth();
    const progressWidth: number = Math.floor((currentStep / totalSteps) * 100);
    const { initialDrawerHeight } = useSetupStore();

    let lastScroll = 0;
    const scrollBottomBuffer = 10;

    useEffect(() => {
        setCurrentMaxHeight(window.innerHeight - initialDrawerHeight - 100);
    }, [initialDrawerHeight]);

    function scrollHandler(event: React.UIEvent<HTMLDivElement>): void {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        const scrollChange = scrollTop - lastScroll;
        // The maximum the mobile drawer can expand to with padding for the header
        const fullyExpandedHeight = Math.min(scrollHeight, window.innerHeight - 208);

        // Scrolling up pulls the drawer up
        if (!isExpanded && isMobileWidth) {
            setCurrentMaxHeight(Math.min(fullyExpandedHeight, currentMaxHeight + scrollChange));
            event.currentTarget.scrollTo({ top: 0 });

            if (currentMaxHeight === fullyExpandedHeight) {
                setIsExpanded(true);
                if (onExpand) {
                    onExpand(true);
                }
            }
        } else if (scrollTop + clientHeight >= scrollHeight - scrollBottomBuffer) {
            if (onScrolledToBottom) {
                onScrolledToBottom();
            }
        }

        lastScroll = scrollTop;
    }

    function getPanel(): JSX.Element {
        return (
            <div className={cx(
                'bg-white lg:shadow-none flex flex-col justify-between lg:w-1/2 w-full overflow-hidden lg:rounded-none lg:relative lg:bottom-auto lg:pb-0',
                {
                    'relative rounded-none shadow-none flex-grow': !drawerEnabled,
                    'fixed shadow-z4 shadow-gray-700 rounded-2xl pb-4 -bottom-4': isMobileWidth && drawerEnabled
                }
            )}>
                {/* Hack to be able to get iPhone notch height in code */}
                <div hidden className="mt-safe" ref={safeRef} />

                {header && <>
                    <div className="h-fit flex-none" hidden={isMobileWidth}>{header}</div>
                    <Separator hidden={isMobileWidth && !drawerEnabled}/>
                </>
                }
                <div
                    onScroll={scrollHandler}
                    className={cx(
                        'flex flex-col items-center px-4 lg:px-20 w-full overflow-y-auto overflow-x-hidden',
                        {
                            alignTop,
                            'h-full': !alignTop
                        }
                    )}
                    style={{ maxHeight: isMobileWidth && drawerEnabled ? `${currentMaxHeight}px` : 'none' }}>
                    {/* Pull tab */}
                    <div hidden={!isMobileWidth || !drawerEnabled} className="w-full h-4 flex-shrink-0 sticky top-0 bg-white flex flex-col">
                        <div className="w-8 h-1 m-auto bg-gray-300 rounded-full"/>
                    </div>
                    <div className="w-full m-auto pt-4">
                        {children}
                    </div>
                </div>
                <div className="h-24">
                    <div
                        className="h-0.5 bg-gray-700 lg:bg-teal-300"
                        hidden={progressWidth === 0}
                        style={{ width: `${progressWidth}%` }} />
                    <div className="w-full h-px bg-gray-200" />
                    <div className="flex items-center w-full pt-6 pb-8 px-4 lg:p-6 lg:pb-8 justify-between">
                        {(!isMobileWidth && !!back)
                                    && <Button variant="TERTIARY_FILLED"
                                        size="LARGE"
                                        onClick={back}>
                                        <span className="text-3">
                                            {tCommon('Buttons.back')}
                                        </span>
                                    </Button>
                        }
                        <Typography
                            as="div"
                            weight="font-medium"
                            className="ml-4"
                            hidden={!footerMessage}>
                            <span className="lg:text-3 text-2">{footerMessage}</span>
                        </Typography>
                        <Button
                            variant={nextButtonVariant}
                            size="LARGE"
                            disabled={nextButtonDisabled || isBusy}
                            onClick={next}
                            loading={isBusy}
                            className={cx('lg:w-fit', {
                                'w-full': !footerMessage,
                                'w-24': !!footerMessage
                            })}>
                            <span className="text-3">
                                {nextButtonLabel}
                            </span>
                        </Button>
                    </div>

                </div>
            </div>
        );
    }

    return (
        <>
            {isMobileWidth && drawerEnabled
                ? <Transition
                    as={Fragment}
                    appear
                    show={true}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="translate-y-full">
                    { getPanel() }
                </Transition>
                : getPanel()
            }
        </>
    );
};

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUploadsStore } from '@v2/stores/UploadsStore';

import { FileOpener } from '@capacitor-community/file-opener';
import { isPlatform } from '@ionic/react';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { blobToBase64, getPdfFromUrl, PDF_CONTENT_TYPE } from '@v2/utils/PDF';
import { AttachmentThumbnail } from '@v2/Pages/Orders/AttachmentThumbnail';
import { showInvoiceImagesModal } from '@/actions/orderInvoicesActions';
import { EIonicPlatforms } from '@/constants/Mobile';

export interface UploadAssetProps {
    urls: string[],
    className?: string,
    invoiceNumber?: string,
    supplierName?: string,
    children?: React.ReactNode,
}

const handleOnError = ({ currentTarget }):void => {
    currentTarget.onerror = null;
    // hiding the image if for some reason it fails to load
    currentTarget.style.display = 'none';
};

export const UploadAsset : React.FC<UploadAssetProps> = ({ urls = [], className, invoiceNumber = '', supplierName = '', children }) => {
    const { updateModals } = useUploadsStore();
    const url = urls.length > 0 ? urls[0] : '';
    const [pdfData, setPdfData] = useState<string>();
    const [pdfBytesArray, setPdfBytesArray] = useState<Uint8Array>();

    const isPdf = url.toLowerCase().includes('.pdf');
    const isNativeDevice = isPlatform(EIonicPlatforms.CAPACITOR);
    const dispatch = useDispatch();

    // Fetches the PDF data from the provided URL and updates the state with the necessary information for rendering in modal
    useEffect(() => {
        if (isPdf) {
            getPdfFromUrl(url)
                .then(({
                    pdfBytes,
                    pdfUrlObject
                }) => {
                    setPdfData(`${pdfUrlObject}#toolbar=0&navpanes=0&scrollbar=0`);
                    setPdfBytesArray(pdfBytes);
                }).catch((e) => {
                    console.info(e, 'Error while loading pdf');
                });
        }
    }, []);

    const handlePdfClick = async (e: React.MouseEvent<HTMLDivElement>): Promise<void> => {
        try {
            e.stopPropagation();
            if (!pdfData) {
                return;
            }
            if (isNativeDevice) {
                const fileName = url.split('/')
                    .pop();
                const data = await blobToBase64(new Blob([pdfBytesArray], { type: PDF_CONTENT_TYPE })) as string;
                const file = await Filesystem.writeFile({
                    data,
                    path: fileName,
                    directory: Directory.Documents
                });
                await FileOpener.open({
                    filePath: file.uri,
                    contentType: PDF_CONTENT_TYPE
                });
                // when the file viewer is closed then it will delete the file from the system
                await Filesystem.deleteFile({
                    path: file.uri,
                    directory: Directory.Documents
                });
            } else {
                updateModals({
                    pdfModal: {
                        pdfData,
                        invoiceNumber,
                        supplierName,
                        isPdfModalOpen: true
                    }
                });
            }
        } catch (ex) {
            console.info(ex, 'Error while clicking on pdf');
        }
    };

    const handleImageClick = (e): void => {
        e.stopPropagation();
        const images = urls.map((u) => ({
            url: u,
            key: u,
            alt: u
        }));
        dispatch(
            showInvoiceImagesModal(true, {
                invoiceId: '',
                invoiceNumber: invoiceNumber ?? '',
                images,
                vendorName: supplierName,
            })
        );
    };

    if (isPdf) {
        return <div role="button" onClick={handlePdfClick} className="cursor-pointer w-full">
            {children || <AttachmentThumbnail
                pdfHeight={'h-10'}
                pdfWidth={'w-10'}
                imageSize={40}
                smallThumbnail={true}
                url={url} />}
        </div>;
    }
    return <div role="button" onClick={handleImageClick} className="cursor-pointer w-full">
        {children || (url.includes('lh3.googleusercontent.com/') ? <img onError={handleOnError} className={className} src={`${url}=s40`} key={url}/> : <img onError={handleOnError} className={className} src={`${url}`} key={`${url}`}/>)}
    </div>;
};

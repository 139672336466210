import { NONE_SELECTED } from '@notch-ordering/ui-components';
import React from 'react';
import { t } from '@v2/i18n';

const I18N_BULK_UPLOAD_PREFIX = 'BulkUpload';
const I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX = 'BulkUpload.columnLabels';

export interface IColumnMap {
    [key: string]: string,
}

export interface FieldRowProps {
    label: string,
    value: string,
    selectedCol?: string,
    optional?: boolean,
    handleChange?: (value: IColumnMap) => void,
    formSubmitted?: boolean,
    duplicatedColumnsSelected?: string[],
}

export interface IFieldColumns {
    label: string,
    value: string,
    alias?: string,
    optional?: boolean,
}

export const MAP_FIELD_KEYS = {
    packageSku: 'productColumn_packageSku',
    productName: 'productColumn_productName',
    productDescription: 'productColumn_productDescription',
    productCategory: 'productColumn_productCategory',
    packageAvailability: 'productColumn_packageAvailability',
    packageUnit: 'productColumn_packageUnit',
    packageDescription: 'productColumn_packageDescription',
    packageQuantity: 'productColumn_packageQuantity',
    defaultPrice: 'productColumn_defaultPrice',
    productTaxCode: 'productColumn_productTaxCode',
};

export const MAP_FIELDS: IFieldColumns[] = [
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.productName`),
        value: MAP_FIELD_KEYS.productName,
        alias: 'Product Name',
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.productDescription`),
        optional: true,
        value: MAP_FIELD_KEYS.productDescription,
        alias: 'Product Description',
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.category`),
        value: MAP_FIELD_KEYS.productCategory,
        alias: 'Category',
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.sizeDescription`),
        optional: true,
        value: MAP_FIELD_KEYS.packageDescription,
        alias: 'Package Description'
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.quantity`),
        value: MAP_FIELD_KEYS.packageQuantity,
        alias: 'Package Quantity'
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.unitOfMeasure`),
        value: MAP_FIELD_KEYS.packageUnit,
        alias: 'Package Unit'
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.unitPrice`),
        value: MAP_FIELD_KEYS.defaultPrice,
        alias: 'Default Price'
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.sku`),
        value: MAP_FIELD_KEYS.packageSku,
        alias: 'Package SKU'
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.availability`),
        value: MAP_FIELD_KEYS.packageAvailability,
        alias: 'Availability'
    },
    {
        label: t(`${I18N_BULK_UPLOAD_COLUMN_LABELS_PREFIX}.taxCode`),
        optional: true,
        value: MAP_FIELD_KEYS.productTaxCode,
        alias: 'Tax Code'
    },
];

// ALL the required field definitions
export const REQUIRED_FIELDS = [
    MAP_FIELD_KEYS.packageSku,
    MAP_FIELD_KEYS.productName,
    MAP_FIELD_KEYS.productCategory,
    MAP_FIELD_KEYS.packageAvailability,
    MAP_FIELD_KEYS.packageUnit,
    MAP_FIELD_KEYS.packageQuantity,
    MAP_FIELD_KEYS.defaultPrice,
];
/**
 *
 * validates if all required fields are mapped
 *
 * @param columnMap
 */
export const hasRequiredFields = (columnMap: IColumnMap):boolean => REQUIRED_FIELDS.every((field) => columnMap[field] && columnMap[field] !== NONE_SELECTED);

export const getDuplicatedColumnsSelected = (columnMap: IColumnMap): string[] => {
    const values = Object.values(columnMap);
    return values.filter((value, index) => values.indexOf(value) !== index && value !== NONE_SELECTED);
};

export const getErrorMessages = (columnMap: IColumnMap, hasRequired = false, hasDuplicates = false): { title:string, message: React.ReactNode } => {
    if (!hasRequired && !hasDuplicates) {
        return {
            title: t(`${I18N_BULK_UPLOAD_PREFIX}.youLeftSomeRequiredFields`),
            message: t(`${I18N_BULK_UPLOAD_PREFIX}.allTheFieldsInRedMustBeFilled`),
        };
    }
    if (hasDuplicates && hasRequired) {
        return {
            title: t(`${I18N_BULK_UPLOAD_PREFIX}.columnSelectionTwice`),
            message: t(`${I18N_BULK_UPLOAD_PREFIX}.columnSelectionTwiceTooltip`),
        };
    }

    if (!hasRequired && hasDuplicates) {
        return {
            title: t(`${I18N_BULK_UPLOAD_PREFIX}.errorsNeedToBeResolved`, { count: 2 }),
            message: <ul className="m-0 pl-4"><li>{t(`${I18N_BULK_UPLOAD_PREFIX}.allTheFieldsInRedMustBeFilled`)}</li><li>{t(`${I18N_BULK_UPLOAD_PREFIX}.columnSelectionTwiceTooltip`)}</li> </ul>
        };
    }
    return {
        title: '',
        message: '',
    };
};

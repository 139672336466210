/* eslint-disable @typescript-eslint/no-shadow */
import AddIcon from '@icons/add-icon.svg';
import { Button, Typography, Input, Modal, NONE_SELECTED, Select, SelectOption, toast, onMobileSetLarge } from '@notch-ordering/ui-components';
import { ActionsPanel, SetupInfo } from '@v2/components/Setup/Setup';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import { tCommon, tNamespace } from '@v2/i18n';
import { createProduct,
    deleteProduct,
    getCategories,
    getSupplierProducts,
    getUnits,
    ProductData,
    updateProduct,
    updateProductPackage,
    updateProductPackagePrice } from '@v2/network/SecretShopAPI';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'react-router';
import { useSetupStore } from '@v2/stores/SetupStore';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import { CreatedItem } from './components/CreatedItem';

type Props = {
    prop: string,
    router: Router,
};

type ProductFormData = {
    id?: string,
    name?: string,
    unit?: string,
    packageDescription?: string,
    price?: number,
    sku?: string,
    category?: string,
};

type FormErrors = {
    name?: boolean,
    unit?: boolean,
    sku?: boolean,
    network?: boolean,
    category?: boolean,
};

const DEFAULT_UNIT = 'each';

export const AddItemsCreate: React.FC<Props> = ({ router }) => {
    const { catalogID, supplier, setSetupActions, setProductsCreated } = useSetupStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Catalog.AddItems.Create' });
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';
    const [productData, setProductData] = useState<ProductFormData>({
        unit: DEFAULT_UNIT,
    });
    const [currentProduct, setCurrentProduct] = useState<ProductData>();
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [addMore, setAddMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [units, setUnits] = useState<SelectOption[]>([]);
    const [categories, setCategories] = useState<SelectOption[]>([]);
    const [products, setProducts] = useState([]);
    const isEditing = !!productData.id;
    const maxProductsToDisplay = 1000;
    const [isActionsExpanded, setIsActionsExpanded] = useState(false);

    function resetForm(): void {
        setProductData({
            name: '',
            packageDescription: '',
            unit: DEFAULT_UNIT,
            price: 0,
            category: '',
            sku: '',
            id: ''
        });
        setCurrentProduct(undefined);
        setFormErrors({});
    }

    useEffect(() => {
        setIsLoading(true);
        const categoriesPromise = getCategories();
        const unitsPromise = getUnits();
        const productsPromise = getSupplierProducts(catalogID, maxProductsToDisplay);
        Promise.all([categoriesPromise, unitsPromise, productsPromise])
            .then(([{ categories }, { units }, { results }]) => {
                setCategories(categories.map((category) => ({ value: category, label: category })));
                setUnits(units.map((unit:string) => ({ value: unit, label: unit })));
                setProducts(results);
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        setSetupActions({ didCreateItems: products.length > 0 });
        setProductsCreated(products.length);
    }, [products]);

    function exit(): void {
        router.push(EPaths.HOME);
    }

    function back(): void {
        router.goBack();
    }

    function closeModal(): void {
        resetForm();
        setIsCreateModalOpen(false);
    }

    function onCreateProductSuccess(data): void {
        resetForm();
        if (!addMore) {
            closeModal();
        }

        setIsLoading(false);
        setProducts([...products, data]);
    }

    function onCreateProductError(data): void {
        if (data.error.includes('already exists')) {
            setFormErrors({
                sku: true
            });
        } else {
            setFormErrors({
                network: true
            });
        }

        setIsLoading(false);
    }

    function refreshProducts(): void {
        setIsLoading(true);

        resetForm();
        closeModal();
        getSupplierProducts(catalogID, maxProductsToDisplay)
            .then(({ results }) => {
                setProducts(results);
                setIsLoading(false);
            });
    }

    function onUpdateProductError(): void {
        setFormErrors({ ...formErrors, network: true });
        setIsLoading(false);
    }

    function sendCreateProduct(): void {
        const data: ProductData = {
            id: productData.id,
            name: productData.name,
            category: productData.category,
            packages: [
                {
                    unit: productData.unit,
                    sku: productData.sku,
                    description: productData.packageDescription,
                    prices: [{
                        pricePerUnit: !productData.price || productData.price <= 0 ? 0.01 : productData.price
                    }],
                    quantity: 1
                }
            ]
        };
        if (isEditing) {
            const [pack] = currentProduct.packages;
            const [price] = pack.prices;
            const updateProductPromise = updateProduct({
                data,
                supplierCatalogId: catalogID
            });
            const updateProductPackagePromise = updateProductPackage({
                productID: productData.id,
                pack: { ...pack, unit: productData.unit, description: productData.packageDescription },
                supplierCatalogID: catalogID
            });
            const updateProductPackagePricePromise = updateProductPackagePrice({
                productID: productData.id,
                packageID: pack.id,
                priceID: price.id,
                pricePerUnit: productData.price,
                supplierCatalogID: catalogID
            });
            Promise.all([
                updateProductPromise,
                updateProductPackagePromise,
                updateProductPackagePricePromise
            ]).then(refreshProducts).catch(onUpdateProductError);
        } else {
            createProduct(data, catalogID)
                .then(onCreateProductSuccess)
                .catch((error) => onCreateProductError(error));
        }
    }

    function onSubmit(e): void {
        e.preventDefault();
        const errors = { ...formErrors };

        errors.name = !productData.name || productData.name.length === 0;
        errors.sku = !productData.sku || productData.sku.length === 0;
        errors.unit = !productData.unit || productData.unit.length === 0 || productData.unit === NONE_SELECTED;
        errors.category = !productData.category || productData.category.length === 0 || productData.category === NONE_SELECTED;

        setFormErrors(errors);

        if (!(errors.name || errors.sku || errors.unit || errors.category)) {
            setIsLoading(true);
            sendCreateProduct();
        }
    }

    function mapProductFields(fields:ProductData):ProductFormData {
        const { name, category, id } = fields;
        const [pack] = fields.packages;
        const [price] = pack.prices;
        const { unit, sku, description } = pack;
        return {
            id,
            name,
            packageDescription: description,
            sku,
            unit,
            price: price.pricePerUnit,
            category,

        };
    }
    function editProduct(product: ProductData): void {
        setProductData(mapProductFields(product));
        setCurrentProduct(product);
        setIsCreateModalOpen(true);
    }

    function sendDeleteProduct(id: string): void {
        setProducts([]);
        setIsLoading(true);
        deleteProduct(id, catalogID)
            .then(refreshProducts)
            .catch(() => {
                refreshProducts();
                toast.show({
                    message: `Error: ${t('deleteError')}`,
                    showClose: true
                });
            });
    }
    return (
        <>
            <SetupInfo
                heading={supplier.name}
                title={t('createItemsManually')}
                shortTitle={t('createItems')}
                showShortTitle={isActionsExpanded}
                close={exit}
                back={back}/>
            <ActionsPanel
                back={back}
                nextButtonDisabled={products.length === 0}
                isBusy={isLoading}
                next={(): void => { router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS}`); } }
                nextButtonLabel={tCommon('Buttons.done')}
                totalSteps={1}
                currentStep={0}
                onExpand={(isExpanded: boolean): void => {
                    setIsActionsExpanded(isExpanded);
                }}>
                {products.length === 0
                    ? (<Button
                        variant="SECONDARY"
                        size="LARGE"
                        onClick={(): void => { setIsCreateModalOpen(true); }}
                        className="flex items-center justify-between w-full lg:w-fit m-auto"
                        disabled={isLoading}>
                        <div className="w-4 h-4 mr-2 pointer-events-none"><AddIcon/></div>
                        <span className="text-3 w-full">
                            {t('createNewItem')}
                        </span>
                    </Button>)
                    : (<div className="flex flex-col lg:mt-10">
                        {products.map((product: ProductData) => (
                            <CreatedItem
                                key={product.id}
                                product={product}
                                editItem={editProduct}
                                deleteItem={(): void => sendDeleteProduct(product.id)}/>
                        ))}
                        <div className="flex justify-start items-center w-full pb-6 cursor-pointer text-teal-300"
                            onClick={(): void => {
                                if (isEditing) {
                                    resetForm();
                                }
                                setIsCreateModalOpen(true);
                            }}>
                            <div className="w-4 h-4 mr-4 pointer-events-none"><AddIcon/></div>
                            <Typography
                                as="span"
                                variant="LG-2"
                                weight="font-medium">
                                {t('createAnotherItem')}
                            </Typography>
                        </div>
                    </div>)
                }
            </ActionsPanel>
            <Modal
                title={isEditing ? t('editItem') : t('newItem')}
                modalSize={onMobileSetLarge('MEDIUM', true)}
                titleSeparatorMobile
                description={t('provideDetails')}
                isOpen={isCreateModalOpen}
                close={closeModal}>
                <form onSubmit={onSubmit}>
                    <div className="mt-4 mb-6 mx-4 gap-y-4 lg:grid lg:grid-cols-2 lg:gap-x-4 lg:gap-y-6 lg:mx-8 lg:items-start">
                        <Input
                            className="mt-0 lg:m-0"
                            label={tCommon('Labels.productName')}
                            type="TEXT"
                            value={productData.name || ''}
                            onChange={(e): void => setProductData({ ...productData, name: e.target.value })}
                            isInvalid={formErrors.name}
                            invalidMessage={t('invalidName')}
                            required
                            disabled={isLoading}/>
                        <Select
                            className="mt-6 lg:m-0"
                            label={tCommon('Labels.category')}
                            value={productData.category || ''}
                            placeholder={t('selectCategory')}
                            onChange={(e): void => setProductData({ ...productData, category: e.target.value })}
                            options={categories}
                            id="category"
                            disabled={isLoading}
                            isInvalid={formErrors.category}
                            invalidMessage={t('invalidCategory')}
                            required/>
                        <Input
                            className="mt-6 mb-0 lg:m-0"
                            value={productData.sku || ''}
                            onChange={(e): void => {
                                setProductData({ ...productData, sku: e.target.value });
                                setFormErrors({ ...formErrors, sku: false });
                            }}
                            label={tCommon('Labels.skuProductCode')}
                            type="TEXT"
                            isInvalid={formErrors.sku}
                            invalidMessage={(!productData.sku || productData.sku.length === 0) ? t('invalidSKU') : t('existingSKU') }
                            required
                            disabled={isLoading || !!productData.id}/>
                        <Input
                            className="mt-6 lg:m-0"
                            label={tCommon('Labels.packageDescription')}
                            value={productData.packageDescription || ''}
                            onChange={(e): void => setProductData({ ...productData, packageDescription: e.target.value })}
                            type="TEXT"
                            disabled={isLoading}/>
                        <Select
                            className="mt-6 lg:m-0"
                            label={tCommon('Labels.unitOfMeasure')}
                            placeholder={t('selectUnit')}
                            options={units}
                            id="unit"
                            value={productData.unit || ''}
                            isInvalid={formErrors.unit}
                            invalidMessage={t('invalidUnit')}
                            onChange={(e): void => { setProductData({ ...productData, unit: e.target.value }); }}
                            required
                            disabled={isLoading}/>
                        <Input
                            className="mt-6 lg:m-0"
                            label={`${tCommon('Labels.pricePer')} ${productData.unit !== 'none' ? productData.unit : ''}`}
                            type="CURRENCY"
                            value={productData.price || ''}
                            onChange={(e): void => { setProductData({ ...productData, price: parseFloat(e.target.value) }); }}
                            placeholder="0.00"
                            disabled={isLoading}/>

                    </div>
                    <div className="w-full">
                        <div className="w-full h-px bg-gray-300" />
                        {formErrors.network
                                && <Typography as="div"
                                    className="text-red-300 mt-2 text-center w-full">
                                    {tCommon('somethingWentWrong')} {tCommon('tryAgain')}
                                </Typography>}
                        <div className="flex items-center flex-col w-full px-4 lg:p-0 lg:flex-row lg:justify-between lg:mt-6 lg:mx-8 m-0 lg:w-auto">
                            {!isMobileWidth
                                    && <Button
                                        variant="TERTIARY_FILLED"
                                        size="MEDIUM"
                                        as="div"
                                        className="w-full lg:w-fit mb-12 lg:m-0 cursor-pointer"
                                        onClick={closeModal}
                                        disabled={isLoading}>
                                        <Typography as="span" weight="font-medium">
                                            {tCommon('Buttons.cancel')}
                                        </Typography>
                                    </Button>
                            }
                            <div className="w-full lg:w-auto lg:flex lg:flex-row-reverse">
                                <Button
                                    type="submit"
                                    variant="SECONDARY"
                                    size={buttonSize}
                                    className="w-full lg:w-fit mt-6 mb-4 lg:m-0 lg:ml-2"
                                    onClick={(): void => { setAddMore(false); }}
                                    disabled={isLoading}>
                                    <Typography as="span" weight="font-medium">
                                        {isEditing ? tCommon('Buttons.save') : tCommon('Buttons.create')}
                                    </Typography>
                                </Button>
                                <Button
                                    hidden={isEditing}
                                    type="submit"
                                    variant="TERTIARY_FILLED"
                                    size={buttonSize}
                                    className="w-full lg:w-fit mb-6 lg:m-0"
                                    onClick={(): void => { setAddMore(true); }}
                                    disabled={isLoading}>
                                    <Typography as="span" weight="font-medium">
                                        {tCommon('Buttons.createAddMore')}
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

export const labels = {
  description:
    'Enter the product details as how you received it on your previous invoice with the supplier. It helps them identify the correct item that you need.',
  title: 'Add a custom product with',
  productName: 'Product Name (required)',
  externalRef: 'Product SKU',
  packaging: 'Pack Size',
  price: 'Price per unit',
  add: 'ADD',
};

export const notAllowedCharsInPrice = ['e', 'E', '+', '-'];

import { Typography } from '@notch-ordering/ui-components';
import React, { useMemo } from 'react';
import cx from 'classnames';
import { getSupplierInitials } from './SupplierCardConstants';

type Props = {
    name: string,
    className?: string,
};

export const SupplierInitials: React.FC<Props> = ({ name, className }) => {
    const initials = useMemo(() => (name ? getSupplierInitials(name) : ''), [name]);

    return (
        <div className={cx('flex items-center justify-center bg-gray-200 h-12 w-12 rounded-full', className)}>
            <Typography as="span"
                variant="LG-2"
                className="text-gray-600">
                {initials}
            </Typography>
        </div>);
};

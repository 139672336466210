import React, { useEffect } from 'react';
import Modal from '../../shared/Modal/Modal.jsx';
import PropTypes from 'prop-types';
import QuantityChanger from '../../general/QuantityChanger.jsx';
import Button from '@/deprecated/fe-shared-components/button/Button';
import { labels, notAllowedCharsInPrice } from './constants.jsx';
import Utils from "@/utils";

export const initialState = {
  name: '',
  externalRef: '',
  packaging: '',
  price: null,
  quantity: 1,
};

const AddCustomItem = ({
  isOpen,
  onClose,
  onAccept,
  supplierName,
  isLoading,
}) => {
  const [formState, setFormState] = React.useState(initialState);
  const updateFormState = (fields) =>
    setFormState((prevState) => ({ ...prevState, ...fields }));

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    updateFormState({ [name]: value });
  };

  const getFormData = () =>
    Object.fromEntries(
      Object.entries(formState).filter(([_, v]) => v !== '' && v !== null)
    );
  const onSubmit = (e) => {
    e.preventDefault();
    const isValidForm = formState.name && formState.quantity;

    if (isValidForm) {
      const formData = getFormData();
      onAccept({
        ...formData,
        ...(formData.price && { price: +formData.price }),
      });
    }
  };

  const updateQuantity = (_event, quantity, type) => {
    if (type === Utils.QUANTITY_TYPES.INCREASE) {
      updateFormState({ quantity: quantity + 1 });
    }
    if (type === Utils.QUANTITY_TYPES.DECREASE) {
      updateFormState({
        quantity:
          quantity > initialState.quantity
            ? quantity - 1
            : initialState.quantity,
      });
    }
    if (type === Utils.QUANTITY_TYPES.CUSTOM) {
      updateFormState({
        quantity:
          quantity > initialState.quantity ? quantity : initialState.quantity,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      setFormState({
        ...initialState,
      });
    }
  }, [isOpen]);
  return (
    <Modal
      className={'add-custom-item-modal'}
      title={`${labels.title} ${supplierName}`}
      desc={labels.description}
      onClose={onClose}
      acceptButtonText="ADD"
      isOpen={isOpen}
    >
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="product-name" className="visually-hidden">
            {labels.productName}
          </label>
          <input
            autoFocus={true}
            id="product-name"
            type="text"
            className="form-control"
            placeholder={labels.productName}
            name="name"
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="product-sku" className="visually-hidden">
            {labels.externalRef}
          </label>
          <input
            id={'product-sku'}
            type="text"
            className="form-control"
            name="externalRef"
            placeholder={labels.externalRef}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="product-description" className="visually-hidden">
            {labels.packaging}
          </label>
          <input
            id={'product-description'}
            type="text"
            className="form-control"
            name="packaging"
            placeholder={labels.packaging}
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="product-price" className="visually-hidden">
            {labels.price}
          </label>
          <input
            id={'product-price'}
            type="number"
            onKeyDown={(evt) =>
              notAllowedCharsInPrice.includes(evt.key) && evt.preventDefault()
            }
            onBlur={(e) => {
              if (e.target.value) {
                updateFormState({
                  price: parseFloat(e.target.value).toFixed(2),
                });
              }
            }}
            name="price"
            step=".01"
            className="form-control"
            placeholder={labels.price}
            onChange={onChange}
            value={formState.price}
          />
        </div>
        <QuantityChanger
          className={'quantity-changer-modal'}
          changeQuantity={updateQuantity}
          quantity={formState.quantity}
        />
        <Button
          type="submit"
          color={'primary'}
          className="CustomModal__action-button add-custom-item-modal__action-button"
          disabled={!formState.name}
        >
          {isLoading ? (
            <i className="spin-spinner fa fa-circle-o-notch" />
          ) : (
            labels.add
          )}
        </Button>
      </form>
    </Modal>
  );
};
AddCustomItem.defaultProps = {
  onClose: () => 'noop',
  onAccept: () => 'noop',
  isOpen: false,
  isLoading: false,
};

AddCustomItem.propTypes = {
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  isOpen: PropTypes.bool,
  supplierName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default AddCustomItem;

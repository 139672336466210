import React, { useEffect } from 'react';
import { ScanOverview } from '@v2/components/ScanReview/ScanOverview/ScanOverview';
import { ScanLineItems } from '@v2/components/ScanReview/ScanLineItems/ScanLineItems';
import { ScanBasicDetails } from '@v2/components/ScanReview/ScanBasicDetails/ScanBasicDetails';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { ScanChartOfAccountDetails } from '@v2/components/ScanReview/ScanChartOfAccountDetails/ScanChartOfAccountDetails';

export type ScanReviewViewProps = {
    className?: string,

};

export const ScanReviewView: React.FC = () => {
    const { currentView } = useOcrInvoiceContext();
    const { validateTopLevelData } = useOcrInvoiceStore();

    // validate top level data on view change
    useEffect(() => {
        validateTopLevelData();
    }, [currentView]);

    switch (currentView) {
        case ScanReviewViews.Overview:
            return <ScanOverview />;
        case ScanReviewViews.LineItems:
            return <ScanLineItems />;
        case ScanReviewViews.BasicDetails:
            return <ScanBasicDetails />;
        case ScanReviewViews.ChartOfAccountDetails:
            return <ScanChartOfAccountDetails />;
        default:
            return null;
    }
};

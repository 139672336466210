import React from 'react';
import { tNamespace } from '@v2/i18n';
import { Tabs } from '@notch-ordering/ui-components';
import i18n from 'i18next';

const tUploadTabs = i18n.getFixedT(null, tNamespace, 'Uploads.Tabs');
export const UPLOAD_TABS = {
    IN_REVIEW: tUploadTabs('inReview'),
    APPROVED: tUploadTabs('verified'),
};

export const UPLOADS_TABS_LIST = Object.values(UPLOAD_TABS);
export const UPLOADS_TABS_DEFAULT = UPLOAD_TABS.IN_REVIEW;
export type UploadTabsProps = {
    onChange?: (value: string) => void,
    selectedTab: string,
};
export const UploadsTabs : React.FC<UploadTabsProps> = ({ onChange, selectedTab }) => <Tabs tabs={UPLOADS_TABS_LIST}
    value={selectedTab}
    onChange={onChange}/>;

import CheckIcon from '@icons/check-icon.svg';
import { Button, Typography, Input, Modal, toast, onMobileSetLarge } from '@notch-ordering/ui-components';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { tCommon, tNamespace } from '@v2/i18n';
import { createSupplier, CreateSupplierData, SupplierData } from '@v2/network/CoreAPI';
import { isInvalidEmail, isInvalidPhoneNumber, isInvalidString } from '@v2/utils/FormValidation';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { logException } from '@/domains/shared/logger';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import { loadCartOrders } from '@/actions/orderActions';

type Props = {
    isOpen: boolean,
    close: () => void,
    onCreateSupplier: (createdSuppler: SupplierData) => void,
};

type FormErrors = {
    network?: boolean,
    name?: boolean,
    email?: boolean,
    phone?: boolean,
};

/**
 * Modal that appears on the Home page when creating a new supplier.
 */
export const AddSupplierModal: React.FC<Props> = ({
    isOpen = false,
    onCreateSupplier,
    close
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.AddSupplierModal' });
    const { buyer } = useBuyerHook();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';
    const [formData, setFormData] = useState<CreateSupplierData>({
        name: '',
        email: '',
        phone: ''
    });
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const { completeTask } = useOnboardingStore();

    function onSubmit(e): void {
        e.preventDefault();
        setIsLoading(true);
        const errors = { ...formErrors };

        errors.network = false;
        errors.name = isInvalidString(formData.name);
        errors.email = isInvalidString(formData.email) || isInvalidEmail(formData.email);
        errors.phone = !isInvalidString(formData.phone) && isInvalidPhoneNumber(formData.phone);

        setFormErrors(errors);

        if (!(errors.name || errors.email || errors.phone)) {
            createSupplier(buyer.urlsafe, formData)
                .then((supplier) => {
                    close();
                    onCreateSupplier(supplier);
                    setIsLoading(false);
                    dispatch(loadCartOrders(true));
                    toast.show({
                        message: <>{t('createdSupplier')} <span className="font-semibold">{supplier.name}</span></>,
                        icon: <CheckIcon />,
                        showClose: false
                    });

                    completeTask(buyer.urlsafe, EOnboardingTasks.ADD_SUPPLIER);
                    sendTrackingEvent(TrackingEvents.createSupplierClicked);
                })
                .catch((error) => {
                    setIsLoading(false);
                    logException(error);
                    setFormErrors({ network: true });
                });
        } else {
            setIsLoading(false);
        }
    }

    return <Modal
        title={t('createNewSupplier')}
        description={t('provideDetails')}
        modalSize={onMobileSetLarge('MEDIUM')}
        isOpen={isOpen}
        close={close}>
        <form onSubmit={onSubmit} noValidate>
            <div className="flex flex-col lg:block my-6 mx-4 gap-y-6 lg:mx-8 lg:mb-8 lg:items-start">
                <Input
                    className="mt-0 lg:m-0"
                    label={tCommon('Labels.name')}
                    type="TEXT"
                    value={formData.name}
                    onChange={(e): void => setFormData({ ...formData, name: e.target.value })}
                    isInvalid={formErrors.name}
                    invalidMessage={t('invalidName')}
                    disabled={isLoading}
                    required />
                <div className="flex flex-col lg:flex-row lg:justify-between gap-y-6 lg:gap-y-0 lg:gap-x-4 lg:mt-6">
                    <Input
                        className="mt-0 lg:m-0 lg:w-72"
                        label={tCommon('Labels.emailAddress')}
                        type="EMAIL"
                        value={formData.email}
                        isInvalid={formErrors.email}
                        invalidMessage={t('invalidEmail')}
                        onChange={(e): void => setFormData({ ...formData, email: e.target.value })}
                        disabled={isLoading}
                        required />
                    <Input
                        className="mt-0 lg:m-0 lg:w-52"
                        label={tCommon('Labels.phoneNumber')}
                        type="TEL"
                        value={formData.phone}
                        isInvalid={formErrors.phone}
                        invalidMessage={t('invalidPhoneNumber')}
                        disabled={isLoading}
                        onChange={(e): void => setFormData({ ...formData, phone: e.target.value })} />
                </div>
            </div>
            <div className="w-full">
                <div className="w-full h-px bg-gray-300" />
                {formErrors.network
                    && <Typography as="div" className="text-red-300 mt-2 text-center w-full">
                        {tCommon('somethingWentWrong')} {tCommon('tryAgain')}
                    </Typography>}
                <div className="flex items-center flex-col w-full px-4 lg:p-0 lg:flex-row lg:justify-end lg:mt-6 lg:mx-10 m-0 lg:w-auto lg:gap-x-2">
                    {!isMobileWidth
                        && <Button
                            variant="TERTIARY_FILLED"
                            size="MEDIUM"
                            className="w-full lg:w-fit mb-12 lg:m-0"
                            onClick={close}
                            disabled={isLoading}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.cancel')}
                            </Typography>
                        </Button>
                    }
                    <div className="w-full lg:w-auto lg:flex lg:flex-row-reverse">
                        <Button
                            type="submit"
                            variant="SECONDARY"
                            size={buttonSize}
                            className="w-full lg:w-fit mt-6 mb-4 lg:m-0 lg:ml-2"
                            disabled={isLoading}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.create')}
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    </Modal>;
};

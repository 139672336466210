import { Button, Separator, Typography } from '@notch-ordering/ui-components';
import { HomeFooter } from '@v2/components/Home/HomeFooter';
import { tCommon, tNamespace } from '@v2/i18n';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AddCreditCardModal from '@v2/components/Shared/AddCreditCard/AddCreditCardModal';
import { Router } from 'react-router';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { OnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import HomeHeaderEmpty from './HomeHeaderEmpty';

type Props = {
    router: Router,
};

/**
 * Home page when the buyer has no supplier catalogs available.
 */
const HomeEmpty: React.FC<Props> = ({ router }) => {
    const { buyer } = useBuyerHook();
    const buyerHasPaymentMethodConfigured = !!buyer.stripeCustomerID;
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.Empty' });
    const [openAddCreditCardModal, setOpenAddCreditCardModal] = React.useState(false);
    const { areAllComplete } = useOnboardingStore();
    const allTasksComplete = areAllComplete(buyer.urlsafe);
    let onCCSuccess: () => void = null;

    useEffect(() => {
        if (!buyerHasPaymentMethodConfigured) {
            onCCSuccess = null;
            setOpenAddCreditCardModal(true);
        }
    }, [buyer]);

    const handleAddSuppliersClick = (): void => {
        sendTrackingEvent(TrackingEvents.emptyHomeAddSupplierClicked);

        if (buyerHasPaymentMethodConfigured) {
            router.push(`${EPaths.SETUP}/${ESetupPaths.SUPPLIERS}`);
        } else {
            onCCSuccess = (): void => {
                router.push(`${EPaths.SETUP}/${ESetupPaths.SUPPLIERS}`);
            };
            setOpenAddCreditCardModal(true);
        }
    };

    return (
        <>
            <div className="h-full flex flex-col justify-between">
                <div>
                    <HomeHeaderEmpty />
                    {!allTasksComplete && <OnboardingTasks router={router} />}

                    <Separator className="mb-10"/>
                    <div className="flex flex-row justify-between gap-x-2 items-start">
                        <div className="flex flex-col">
                            <Typography
                                variant="XL"
                                weight="font-semibold"
                                as="h3"
                                className="lg:ml-10 ml-4 mb-0 text-gray-700">
                                {t('yourSuppliers')}
                            </Typography>
                            <Typography
                                as="div"
                                className="lg:ml-10 ml-4 mb-10 text-gray-600 mt-2">
                                {t('noSuppliersYet')}
                            </Typography>
                        </div>
                        <Button
                            variant="TERTIARY_FILLED"
                            size="SMALL"
                            className="lg:mr-10 mr-4 mt-2 shrink-0"
                            onClick={handleAddSuppliersClick}>
                            {tCommon('Buttons.addSuppliers')}
                        </Button>

                    </div>
                </div>
                <div className="lg:pb-0 pb-safe">
                    <HomeFooter className="mb-[62px]" />
                </div>
            </div>
            <AddCreditCardModal setIsOpen={setOpenAddCreditCardModal}
                isOpen={openAddCreditCardModal}
                onSuccess={onCCSuccess} />
        </>
    );
};

export default HomeEmpty;

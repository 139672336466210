import { createContext } from 'react';

/**
 * A Chat channel list context
 *
 * This context provides ability to consuem all of the channels data easily by using useContext
 */
export const ChatChannelsListContext = createContext(null);

/**
 * A Chat Selected context
 *
 * This context provides ability to consuem all of the selected channel data easily by using useContext
 */
export const ChatSelectedContext = createContext(null);

import { toast } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import { ERoleName, OrgRoleData } from '@v2/types/OrgData';
import useBuyerHook from './useBuyer.hook';
import useOrgHook from './useOrg.hook';

const useRolesHook = (): { roles: OrgRoleData[], hasRole: (roleNames: ERoleName[], showToast?: boolean) => boolean } => {
    const { buyer } = useBuyerHook();
    const { org } = useOrgHook();
    const currentOrg = org?.find((o) => o.buyerKey === buyer.urlsafe);
    const roles: OrgRoleData[] = currentOrg ? currentOrg.roles : [];

    /**
     * @param roleNames List of role names to search for
     * @returns true is any of the roles match
     */
    function hasRole(roleNames: ERoleName[], showToast = true): boolean {
        if (roles.find((role) => roleNames.includes(role.name))) {
            return true;
        }

        if (showToast) {
            toast.show({
                message: tCommon('Toasts.error.onlyRoleCanPerform', { roles: roleNames.join(', ') }),
            });
        }
        return false;
    }

    return {
        roles,
        hasRole
    };
};

export default useRolesHook;

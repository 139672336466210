import BackIcon from '@icons/back-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import ExportIcon from '@icons/export-icon.svg';
import NestedArrowIcon from '@icons/nested-arrow-icon.svg';
import NoCatalogImg from '@images/no-catalog.svg';
import { ActionBar, Badge, Button, Checkbox, DataGridCheckbox, GridColDef, GridRenderCellParams, GridSelectionModel, GRID_CHECKBOX_SELECTION_COL_DEF, Loading, NotchDataGrid, Popover, Skeleton, toast, Tooltip, Typography, useGridApiContext } from '@notch-ordering/ui-components';
import { InfiniteData } from '@tanstack/query-core';
import { BulkUploadModal } from '@v2/components/BulkUpload/BulkUploadModal/BulkUploadModal';
import { FiltersBadge } from '@v2/components/Shared/FiltersBadge/FiltersBadge';
import { FilterButton } from '@v2/components/Shared/FiltersButton/FilterButton';
import { EPaths } from '@v2/constants/EPaths';
import { TrackingEvents } from '@v2/constants/Tracking';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { tCommon, tNamespace, tNavigation } from '@v2/i18n';
import { deleteProductPackage, deleteProducts, exportCSV, TFetchProductsType } from '@v2/network/SecretShopAPI';
import { IProduct, IProductResponse } from '@v2/network/types';
import { useGetSupplierCatalogs } from '@v2/Pages/Catalog/SupplierCatalogQueries.hook';
import { FILTERS_INITIAL_STATE, IActiveFilters } from '@v2/Pages/Market/MarketConstants';
import { useFetchMarketProductsQuery, useGetProductCategories } from '@v2/Pages/Market/MarketQueries.hook';
import { useBulkUploadStore } from '@v2/stores/BulkUploadStore';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { debounce } from 'lodash';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'react-router';
import { useTitle } from '@v2/hooks/useTitle.hook';
import SearchPanel from '@v2/components/Shared/Search/SearchPanel';
import Utils from '@/utils';
import { CatalogManagementQuery, commonColumn, CatalogManagementRowData, SortingOptions, SortOption } from './CatalogManagementConstants';
import { ProductRowMoreButton } from './components/ProductRowMoreButton';
import { ProductDetailsModal } from './components/ProductDetailsModal';
import { ConfirmDeletionModal } from './components/ConfirmDeletionModal';
import { OrderGuidesModal } from './components/OrderGuidesModal';

export interface CatalogManagementProps {
    router: Router,
    //  this property is added to the component by react-router
    params: {
        supplierUrlsafeKey: string,
    },
}

export const formatGridRowProducts = (products: IProduct[]): CatalogManagementRowData[] => products.reduce((acc, product) => {
    const hasMultiplePackages = product.packages.length > 1;
    // if product has multiple packages, we need to create a extra row for the parent product

    if (hasMultiplePackages) {
        acc.push({ ...product, isProduct: true, productID: product.id });
    }

    return acc.concat(product.packages.map((pack) => ({
        id: pack.id,
        productID: product.id,
        isProduct: !hasMultiplePackages,
        name: product?.name,
        category: product?.category,
        supplierCatalogID: product?.supplierCatalogID,
        size: `${pack?.quantity} ${pack?.unit} ${pack?.description ? `- ${pack?.description}` : ''}`,
        pricePerUnit: Utils.formatAsCurrency(pack?.pricePerUnit),
        sku: pack.sku,
        availability: pack.availability,
    })));
}, []).flat();
const DEFAULT_PAGE_SIZE = 50;
const DEBOUNCE_SEARCH_QUERY_DELAY = 500;

export const CatalogManagement: React.FC<CatalogManagementProps> = ({ router, params }) => {
    const { setModalBulkUploadOpen, setCurrentSupplier, currentSupplier } = useBulkUploadStore();
    const [isProductDetailsOpen, setIsProductDetailsOpen] = useState(false);
    const [isEditingProduct, setIsEditingProduct] = useState(false);
    const { buyer } = useBuyerHook();
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [selectedRows, setSelectedRows] = useState<CatalogManagementRowData[]>([]);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [formattedRows, setFormattedRows] = useState<CatalogManagementRowData[]>([]);
    const [selectedProductIDs, setSelectedProductIDs] = useState<string[]>([]);
    const [productPackageIds] = useState<Map<string, string[]>>(new Map<string, string[]>());
    const [isOrderGuideModalOpen, setIsOrderGuideModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const { t } = useTranslation(tNamespace, { keyPrefix: 'BulkUpload' });
    useTitle(tNavigation('Catalog'));
    const { upload: showUpload }: CatalogManagementQuery = router.location.query;

    const [page, setPage] = React.useState(0);
    const [isActionBarOpen, setIsActionBarOpen] = useState<boolean>(false);

    const { data: supplierCatalogs, isLoading: loadingSupplierCatalogs } = useGetSupplierCatalogs({
        supplierUrlsafeKey: params.supplierUrlsafeKey,
        limit: 1
    });

    const supplier = supplierCatalogs?.results[0];
    const [filters, setFilters] = useState<IActiveFilters>({ ...FILTERS_INITIAL_STATE, supplierCatalogs: [supplier?.id] });
    const [currentSortOption, setCurrentSortOption] = useState<SortOption>();
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [categoryFilters, setCategoryFilters] = useState<string[]>([]);
    const [inStockOnly, setInStockOnly] = useState<boolean>(false);
    const [showCategoryTooltip, setShowCategoryTooltip] = useState<boolean>(false);
    const { data: categoriesData } = useGetProductCategories();

    useEffect(() => {
        let currentCategories: string[] = [];
        categoryFilters.forEach((filter) => {
            currentCategories = [...currentCategories, filter];
        });

        const currentFilters: IActiveFilters = { inStockOnly, categories: currentCategories, sort: currentSortOption?.sortMode, direction: currentSortOption?.sortDirection, name: searchQuery };
        setFilters({ supplierCatalogs: [supplier?.id], ...currentFilters });
        setPage(0); // reset the pagination
    }, [categoryFilters, currentSortOption, searchQuery, inStockOnly]);

    const { data: prods, isLoading: isLoadingProducts, status, fetchNextPage, refetch: refetchData } = useFetchMarketProductsQuery({ buyerUrlsafeKey: buyer.urlsafe,
        params: {
            ...filters,
            supplierCatalogs: [supplier?.id],
            limit: DEFAULT_PAGE_SIZE,
        },
        type: TFetchProductsType.SEARCH,
        queryOptions: {
            onSuccess: (data: InfiniteData<IProductResponse>) => {
                setFormattedRows(formatGridRowProducts(data.pages[page]?.results ?? []).flat());
                const results = data.pages[page]?.results;
                results.forEach((product) => {
                    const childPackageIds = product.packages.map((pack) => pack.id);
                    productPackageIds.set(product.id, childPackageIds);
                });
            },
            enabled: !!supplier?.id,
        } });

    const hasLoadedProducts = status === 'success';

    const pages = prods?.pages || [];
    const hasResults = pages?.length && pages[0].total > 0;
    const onClickUploadCatalog = (): void => {
        setModalBulkUploadOpen(true);
        sendTrackingEvent(TrackingEvents.uploadCatalogClicked);
    };

    function closeActionBar(): void {
        setIsActionBarOpen(false);
    }

    function clearSelected(): void {
        setSelectionModel([]);
    }

    function handleSelectionModelChange(selected): void {
        const didRemoveRow = selected.length < selectionModel.length;

        if (didRemoveRow) {
            const removedId = selectionModel.find((r) => !selected.includes(r));
            const packageRows = productPackageIds.get(removedId as string);
            if (packageRows?.length > 0) {
                setSelectionModel(selected.filter((r: string) => !packageRows.includes(r)));
            } else {
                setSelectionModel(selected);
            }
        } else {
            const lastId = selected[selected.length - 1];
            const packageRows = productPackageIds.get(lastId as string);
            if (packageRows?.length > 0) {
                setSelectionModel([...selected, ...packageRows.filter((r) => !selected.includes(r))]);
            } else {
                setSelectionModel(selected);
            }
        }
    }

    const CATALOG_TABLE_COLUMNS: GridColDef[] = [
        {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            width: 40,
            minWidth: 40,
            headerAlign: 'center',
            align: 'left',
            type: 'boolean',
            renderCell: ({ row, value }):React.ReactNode => {
                const apiRef = useGridApiContext();

                return (
                    <div className="ml-3">
                        { !row.isProduct && <NestedArrowIcon className="w-4 h-4 ml-2 mr-3 text-gray-400" /> }
                        <DataGridCheckbox
                            className="ml-3"
                            checked={value}
                            value={value}
                            onChange={(): void => {
                                apiRef.current.selectRow(row.id, !value);
                            }}/>
                    </div>);
            },
            colSpan: ({ row }):number => {
                if (!row.isProduct) {
                    return 2;
                }
                return undefined;
            },
        },
        {
            ...commonColumn,
            field: 'name',
            flex: 1,
            minWidth: 150,
            headerName: 'Product',
            type: 'string'
        },
        {
            ...commonColumn,
            field: 'size',
            flex: 0.5,
            minWidth: 80,
            headerName: 'Size',
            type: 'string',
            renderCell: ({ row }): ReactNode => (row.size ? <span className="truncate">{row.size}</span> : <span className="text-gray-400">-</span>)
        },
        {
            ...commonColumn,
            field: 'sku',
            flex: 0.5,
            minWidth: 80,
            headerName: 'SKU',
            type: 'string',
            renderCell: ({ row }): ReactNode => (row.sku ? <span className="truncate">{row.sku}</span> : <span className="text-gray-400">-</span>)
        },
        {
            ...commonColumn,
            field: 'pricePerUnit',
            flex: 0.25,
            minWidth: 80,
            headerName: 'Price',
            type: 'number',
            renderCell: ({ row }): ReactNode => (row.pricePerUnit ? <span className="truncate">{row.pricePerUnit}</span> : <span className="text-gray-400">-</span>)
        },
        {
            ...commonColumn,
            field: 'category',
            flex: 0.4,
            minWidth: 100,
            headerName: 'Category',
            type: 'string',
            renderCell: ({ row }): ReactNode => (row.category ? <span className="truncate">{row.category}</span> : <span className="text-gray-400">-</span>)
        },
        {
            ...commonColumn,
            field: 'availability',
            flex: 0.1,
            minWidth: 115,
            headerName: 'Availability',
            type: 'string',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            renderCell: (gridParams: GridRenderCellParams<any, any, any>): JSX.Element => {
                if (gridParams.row.availability === 'IN_STOCK') {
                    return (<Badge variant={'GREEN'} size={'MEDIUM'} >In stock</Badge>);
                } if (gridParams.row.availability === 'OUT_OF_STOCK') {
                    return (<Badge variant={'GRAY'} size={'MEDIUM'} >Out of stock </Badge>);
                } if (gridParams.row.availability === 'DISABLED') {
                    return (<Badge variant={'ORANGE'} size={'MEDIUM'}>Disabled</Badge>);
                } return null;
            },
        },
        {
            ...commonColumn,
            field: 'more',
            cellClassName: 'overflow-visible', // This must be set for the popover to be visible
            flex: 0.1,
            minWidth: 52,
            headerName: '',
            renderCell: ({ row }): JSX.Element => (<ProductRowMoreButton row={row}
                buyerUrlsafe={buyer.urlsafe}
                onDelete={(): void => {
                    clearSelected();
                    refetchData();
                }}
                openProductDetailsModal={(): void => {
                    setIsEditingProduct(true);
                    setSelectedRows([row]);
                    setIsProductDetailsOpen(true);
                    closeActionBar();
                }} />)
        },
    ];

    useEffect(() => {
        if (supplier?.id) {
            setCurrentSupplier(supplier);
        }
    }, [supplier]);

    useEffect(() => {
        const selectedArray = selectionModel.map((rowId): CatalogManagementRowData => formattedRows.find((r) => r.id === rowId));
        setSelectedRows(selectedArray);
        const productIDs = [];
        selectedArray.forEach((row) => {
            if (!productIDs.includes(row.productID)) {
                productIDs.push(row.productID);
            }
        });
        setSelectedProductIDs(productIDs);
        const isBarOpen = selectedArray.length > 0;
        setIsActionBarOpen(isBarOpen);

        if (!isBarOpen) return;

        // True when everything selected is a package of the same parent
        let allSameProduct = false;
        const { productID } = selectedArray[0];
        for (let i = 1; i < selectedArray.length; i++) {
            const row = selectedArray[i];
            if (row.productID === productID) {
                allSameProduct = true;
            } else {
                allSameProduct = false;
                break;
            }
        }
        setIsEditable(selectedArray.length === 1 || allSameProduct);
    }, [selectionModel]);

    useEffect(() => {
        if (showUpload) {
            setModalBulkUploadOpen(true);
        }
    }, [showUpload]);

    const hasProducts = formattedRows?.length > 0;

    function goToCatalog(): void {
        router.push(EPaths.CATALOG);
    }
    const debounceSearchQuery = useMemo(() => debounce((query: string) => {
        setPage(0);
        setSearchQuery(query.trim());
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);

    const handleDownloadCSV = async (): Promise<void> => {
        const csvBlob = await exportCSV({
            supplierCatalogID: supplier?.id,
            buyerUrlsafeKey: buyer?.urlsafe
        });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(csvBlob);
        a.download = `${supplier.supplierName} products.csv`;
        a.click();
        sendTrackingEvent(TrackingEvents.exportCatalogClicked);
    };

    const packageLabel = selectedRows[0]?.isProduct ? `${selectedRows[0]?.name}` : `${selectedRows[0]?.name} - ${selectedRows[0]?.size}`;
    const productLabel = selectedProductIDs.length > 1 ? `${selectedProductIDs.length} products` : packageLabel;
    const productLabelForDelete = selectedProductIDs.length > 1 ? `${productLabel}?` : `"${productLabel}"?`;

    function removeCategoryFilter(filter): void {
        setCategoryFilters(categoryFilters.filter((f) => (f !== filter)));
    }

    async function handleDeleteRows(): Promise<void> {
        const productsToDelete = [];
        let packagesToDelete = [];
        selectedRows.forEach((row) => {
            if (row.isProduct) {
                productsToDelete.push(row.productID);
            } else {
                packagesToDelete.push({ id: row.id, name: row.name, sku: row.sku, parent: row.productID });
            }
        });
        packagesToDelete = packagesToDelete.filter((pack) => !productsToDelete.includes(pack.parent));
        packagesToDelete.forEach((pack) => {
            try {
                deleteProductPackage({ pack, productID: pack.parent, supplierCatalogID: supplier?.id });
            } catch (e) {
                toast.show({
                    message: `An error occurred while deleting ${pack.name}`,
                });
            }
        });
        if (productsToDelete.length > 0) {
            try {
                await deleteProducts(productsToDelete, supplier?.id);
            } catch (e) {
                toast.show({
                    message: `An error occurred while deleting ${productLabel}`,
                });
            }
            toast.show({
                message: `Successfully deleted ${productLabel}`,
            });
        }
        setIsDeleteModalOpen(false);
        clearSelected();
        refetchData();
    }

    return (
        <div className="w-full">
            <div className="w-full flex flex-col pt-4 px-10 gap-4 justify-end">
                <div className="flex flex-row w-full mt-12">
                    <Button variant="TEXT"
                        size="NO_BACKGROUND"
                        className="flex flex-row items-center justify-center pl-0"
                        onClick={goToCatalog}>
                        <div className="text-gray-600 pr-3 flex-shrink-0"><BackIcon className="mt-1 w-4 h-4" /></div>
                        <Typography as="span">{tCommon('Buttons.back')}</Typography>
                    </Button>
                    <div className="flex flex-row w-full justify-end gap-x-3">
                        <Button
                            onClick={handleDownloadCSV}
                            variant="TERTIARY_FILLED"
                            size="MEDIUM"
                            className="flex items-center justify-center gap-2">
                            <ExportIcon className={'w-4 h-4 max-w-none'} />
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.export')}
                            </Typography>
                        </Button>
                        <Popover className="w-44"
                            button={
                                <Button
                                    stopPropagation={false}
                                    variant="SECONDARY"
                                    size="MEDIUM"
                                    className="flex items-center shrink-0 justify-center gap-2"
                                    onClick={(): void => {
                                        sendTrackingEvent(TrackingEvents.addProductsClicked);
                                    }}>
                                    <Typography as="span" weight="font-medium">
                                        {t('addProducts')}
                                    </Typography>
                                    <ChevronDownIcon className="h-3 w-3" />
                                </Button>}
                            items={[
                                { label: t('uploadACatalog'),
                                    onClick: onClickUploadCatalog },
                                { label: t('createNewProduct'),
                                    onClick: (): void => {
                                        setIsEditingProduct(false);
                                        setIsProductDetailsOpen(true);
                                        sendTrackingEvent(TrackingEvents.newItemClicked);
                                    } }]} />
                    </div>
                </div>
                <Typography variant="5XL" as="div" className="mb-6 mt-5" weight="font-medium">
                    {loadingSupplierCatalogs ? <Skeleton size="medium" className="h-10" /> : supplier?.supplierName}
                </Typography>
                <div className="w-full flex gap-2 flex-wrap">
                    <SearchPanel
                        onChange={debounceSearchQuery}
                        placeholder={t('searchProducts')}
                        showFilterBtn={false}
                        className="flex-grow" />
                    <FilterButton label={t('category')}
                        className="w-56"
                        closePanelOnClick={false}
                        items={categoriesData?.categories.map((category) => ({
                            label: <div className="flex flex-row justify-between items-center">
                                <Checkbox label={category}
                                    value={category}
                                    checked={categoryFilters.includes(category)}
                                    onChange= {(): void => {}}/>
                            </div>,
                            onClick: (): void => {
                                const isSelected = categoryFilters.includes(category);
                                if (isSelected) {
                                    removeCategoryFilter(category);
                                } else {
                                    setCategoryFilters([...categoryFilters, category]);
                                }
                            }
                        }))} />
                    <FilterButton label={t('availability')}
                        items={[{
                            label: <div className="flex flex-row justify-between items-center">{tCommon('inStock')} {inStockOnly && <CheckIcon className="h-4 w-4"/>}</div>,
                            onClick: (): void => {
                                setInStockOnly(!inStockOnly);
                            }
                        },
                        {
                            label: <div className="flex flex-row justify-between items-center">{tCommon('showAll')} {!inStockOnly && <CheckIcon className="h-4 w-4"/>}</div>,
                            onClick: (): void => {
                                setInStockOnly(!inStockOnly);
                            }
                        }]} />
                    <FilterButton label={t('sort')}
                        items={SortingOptions.map((option) => ({
                            label: <div className="flex flex-row justify-between items-center">
                                <span>{option.label}</span>
                                {(currentSortOption?.sortMode === option.sortMode && currentSortOption?.sortDirection === option.sortDirection) && <CheckIcon className="h-4 w-4"/>}
                            </div>,
                            onClick: (): void => {
                                setCurrentSortOption({
                                    sortMode: option.sortMode,
                                    sortDirection: option.sortDirection,
                                    label: option.label
                                });
                            },
                        }))}
                        className="overflow-auto" />
                </div>
                <div className="w-full flex gap-2 flex-wrap">
                    {categoryFilters.length > 0
                    && <Tooltip
                        show={showCategoryTooltip && categoryFilters.length > 1}
                        className="flex"
                        tooltipClassName={'w-auto'}
                        showArrow={false}
                        onShow ={(): void => { setShowCategoryTooltip(true); }}
                        onHide ={(): void => { setShowCategoryTooltip(false); }}
                        placement="bottom"
                        trigger={<FiltersBadge
                            clearFilter={(): void => setCategoryFilters([])}>
                            <span className="text-gray-650">{t('categoryIs', { count: categoryFilters.length })}</span>&nbsp;
                            { categoryFilters.length > 1
                                ? <>{t('categories')}</>
                                : <>{categoryFilters}</>}
                        </FiltersBadge>}>
                        {categoryFilters.join(', ')}
                    </Tooltip>
                    }
                    {inStockOnly && <FiltersBadge
                        clearFilter={(): void => setInStockOnly(false)}>
                        <span className="text-gray-650">{t('availabilityIs')}</span> {tCommon('inStock')}
                    </FiltersBadge>}
                    {currentSortOption && <FiltersBadge
                        clearFilter={(): void => setCurrentSortOption(undefined)}>
                        <span className="text-gray-650">{t('sortingBy')}</span> {currentSortOption.label}
                    </FiltersBadge>}
                </div>
                {isLoadingProducts && <Loading className="mt-0" isDark={true}/>}
                {hasProducts && <NotchDataGrid
                    autoHeight
                    checkboxSelection
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu={true}
                    pagination
                    pageSize={DEFAULT_PAGE_SIZE}
                    paginationMode="server"
                    rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                    rowCount={pages[page]?.total}
                    getRowId = {(row: IProduct): string => row.id}
                    page={page}
                    loading={isLoadingProducts}
                    onPageChange={(newPage): void => {
                        setPage(newPage);
                        fetchNextPage();
                    }}
                    onSelectionModelChange={handleSelectionModelChange}
                    selectionModel={selectionModel}
                    rows={formattedRows}
                    sortingMode="server"
                    columns={CATALOG_TABLE_COLUMNS}
                    sx={{
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: '#F9F9F9'
                        }
                    }}
                    hideFooterSelectedRowCount/>}
                {!hasResults && hasLoadedProducts && <div className="flex flex-col items-center justify-center mt-20">
                    <NoCatalogImg className="w-40 h-40" />
                    <Typography variant="LG-2" className="mt-6 mb-2" weight="font-semibold">
                        {t('noProductsSupplier')}
                    </Typography>
                    <Typography className="text-gray-600" weight="font-semibold">
                        {t('addProductsCatalog')}
                    </Typography> </div>}
            </div>
            <div className="flex justify-center items-center">
                <ActionBar
                    text={`${tCommon('itemsCount', { count: selectedProductIDs.length })} ${tCommon('selected')}`}
                    isOpen={isActionBarOpen}
                    close={closeActionBar}
                    selection={selectionModel}
                    onClose={(): void => { clearSelected(); }}
                    buttons={[
                        {
                            variant: 'TERTIARY_DARK',
                            onClick: (): void => {
                                setIsOrderGuideModalOpen(true);
                            },
                            text: tCommon('Buttons.addToOrderGuide'),
                        },
                        {
                            variant: 'TERTIARY_DARK',
                            hidden: !isEditable,
                            onClick: (): void => {
                                setIsEditingProduct(true);
                                setIsProductDetailsOpen(true);
                                closeActionBar();
                            },
                            text: tCommon('Buttons.edit'),
                        },
                        {
                            variant: 'DESTRUCTIVE',
                            onClick: (): void => {
                                setIsDeleteModalOpen(true);
                            },
                            text: tCommon('Buttons.delete'),
                        },
                    ]}/>
            </div>
            {!!currentSupplier?.id && <BulkUploadModal />}
            {isProductDetailsOpen && <ProductDetailsModal
                isOpen={isProductDetailsOpen}
                close={(): void => {
                    setIsProductDetailsOpen(false);
                    refetchData();
                }}
                isEditing={isEditingProduct}
                productID={isEditingProduct ? selectedRows[0]?.productID : ''}
                catalogID={supplier?.id}/>}
            {/* Add to Order Guides modal */}
            <OrderGuidesModal isOpen={isOrderGuideModalOpen}
                productLabel={selectedProductIDs.length > 1 ? `${selectedProductIDs.length} products` : selectedRows[0]?.name}
                buyerUrlsafe={buyer.urlsafe}
                selectedProductIDs={selectedProductIDs}
                closeFunction={(): void => setIsOrderGuideModalOpen(false)} />
            {/* Delete Product(s) confirmation modal */}
            <ConfirmDeletionModal isOpen={isDeleteModalOpen}
                productLabel={productLabelForDelete}
                deleteFunction={handleDeleteRows}
                closeFunction={(): void => { setIsDeleteModalOpen(false); }}
                count={selectedProductIDs.length} />
        </div>
    );
};

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { featureFlagBoolean } from '../feature-flag';
import { UPDATE_FEATURE } from '../../../constants/ActionTypes';

const useFeatures = () => {
  const { feature } = useSelector((store) => store);
  const dispatch = useDispatch();

  const getFeatures = useCallback(
    async (firebaseProp = '', state = '') => {
      const value = await featureFlagBoolean(firebaseProp);
      dispatch({ type: UPDATE_FEATURE, value, state });
    },
    [dispatch]
  );

  const getAppFeatures = useCallback(() => {
    if (!('isChatEnabled' in feature)) {
      getFeatures('chat_enabled', 'isChatEnabled');
    }
    if (!('isOrderChat' in feature)) {
      getFeatures('chat_order_specific_enabled', 'isOrderChat');
    }
    if (!('chatAllowAllMarketVendors' in feature)) {
      getFeatures('chat_allow_all_market_vendors', 'chatAllowAllMarketVendors');
    }
    if (!('isReceivingTool' in feature)) {
      getFeatures('receiving_tool_enabled', 'isReceivingTool');
    }
    if (!('buyerMarkAsPaid' in feature)) {
      getFeatures('buyer_mark_as_paid', 'buyerMarkAsPaid');
    }
    if (!('buyerOrderDetails' in feature)) {
      getFeatures('buyer_order_details', 'buyerOrderDetails');
    }
    if (!('isBushwhackEnabled' in feature)) {
      getFeatures('buyer_bushwhack_enabled', 'isBushwhackEnabled');
    }
  }, []);

  return {
    feature,
    getAppFeatures,
  };
};

export default useFeatures;

import React from 'react';
import { Modal } from '@notch-ordering/ui-components';
import { UPLOAD_PDF_MODAL_INITIAL_STATE, useUploadsStore } from '@v2/stores/UploadsStore';

export const UploadPDFModal : React.FC = () => {
    const { modals: { pdfModal }, updateModals } = useUploadsStore();
    const { isPdfModalOpen, pdfData, supplierName, invoiceNumber } = pdfModal;
    return <Modal
        title={`${invoiceNumber} - ${supplierName}`}
        isOpen={isPdfModalOpen}
        close={(): void => {
            // reset the state
            updateModals({ pdfModal: UPLOAD_PDF_MODAL_INITIAL_STATE });
        }
        }>
        <div className="mx-4 lg:mx-10">
            <object
                className="w-full h-[65vh] lg:h-[600px]"
                data={pdfData}/>
        </div>
    </Modal>;
};

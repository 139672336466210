import React, { useContext } from 'react';
import useFeatures from '../../shared/hooks/useFeatures';
// import { sortChannelMembers } from '../helpers/chatClientHelper';

import { ChatSelectedContext } from '../context';
import { setChatSelected } from '../actions/chatSelectedActions';
import { useDispatch } from 'react-redux';

const ChatSelectedHeader = () => {
  const {
    feature: { isOrderChat },
  } = useFeatures();
  const context = useContext(ChatSelectedContext);
  const dispatch = useDispatch();
  const {
    vendorName = '',
    orderNumber = '',
    // channelMembers = {},
  } = context.messageAttributes;

  // const selectedChannelMembers =
  //   Object.entries(channelMembers).sort(sortChannelMembers);
  const backToMessageList = () => {
    dispatch(setChatSelected(null, true));
  };
  return (
    <div className="header-container">
      <div className="header">
        <div className="vendor-name">
          <button onClick={backToMessageList}>
            <i className="fa fa-angle-left"></i>

            {vendorName}
          </button>
        </div>
        {!context.showChatDetails && orderNumber && isOrderChat && (
          <div
            className="order-number"
            onClick={() => {
              context.setShowChatDetails(!context.showChatDetails);
            }}
          >
            Order #{orderNumber}
            <span className="btn-xs glyphicon glyphicon-chevron-left" />
          </div>
        )}
      </div>
      {/* {!isOrderChat && (
        <div className="members">
          {selectedChannelMembers.map(([, member], index) => {
            if (index === selectedChannelMembers.length - 1) return member;
            return `${member}, `;
          })}
        </div>
      )} */}
    </div>
  );
};

export default ChatSelectedHeader;

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { searchProductCatalog, clearSearchResults } from '../actions/searchAction';

const useSearch = () => {
  const {
    receiving: { search },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const searchProducts = useCallback(
    ({ searchTerm, buyerUrlsafeKey, supplierUrlsafeKey }) => {
      dispatch(searchProductCatalog({ searchTerm, buyerUrlsafeKey, supplierUrlsafeKey }));
    },
    [dispatch]
  );

  const clearSearch = useCallback(() => dispatch(clearSearchResults()));

  return {
    search,
    searchProducts,
    clearSearch,
  };
};

export default useSearch;

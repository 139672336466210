import React from 'react';
import { Badge, Typography, twMergeCustom } from '@notch-ordering/ui-components';
import VisaIcon from '@icons/visa-icon.svg';
import MastercardIcon from '@icons/mastercard-icon.svg';
import AmexIcon from '@icons/amex-icon.svg';
import PadIcon from '@icons/pad-icon.svg';
import { BadgeProps } from '@notch-ordering/ui-components/src/components/Badge/BadgeConstants';
import cx from 'classnames';

export interface PaymentMethodBadgeProps {
    brand: string,
    autoCollection?: boolean,
    className?: string,
    badgeProps?: Omit<BadgeProps, 'children'>,
}

export const enum PaymentBrand {
    americanExpress = 'american express',
    amex = 'amex',
    mastercard = 'mastercard',
    visa = 'visa',
}

export const getCCIcon = (brand: string): JSX.Element => {
    const brandLower = brand.toLowerCase().trim();
    switch (brandLower) {
        case PaymentBrand.americanExpress:
        case PaymentBrand.amex:
            return <AmexIcon className={'h-5'}/>;
        case PaymentBrand.mastercard:
            return <MastercardIcon className={'h-5'}/>;
        case PaymentBrand.visa:
            return <VisaIcon className={'h-5'}/>;
        default:
            return <PadIcon className={'h-5'}/>;
    }
};

const PaymentMethodBadge : React.FC<PaymentMethodBadgeProps> = ({
    brand,
    autoCollection,
    className,
    badgeProps,
}) => (
    <Typography className={twMergeCustom(
        cx('truncate flex gap-2 items-center', className)
    )}>
        <span>{getCCIcon(brand)}</span>
        {autoCollection
        && <Badge variant="PURPLE" size="SMALL" {...badgeProps}>Autopay</Badge>
        }
    </Typography>
);

export default PaymentMethodBadge;

import React from 'react';
import { Skeleton } from '@notch-ordering/ui-components';

export const ProductCardPlaceholder: React.FC = () => (
    <div className={'w-full flex gap-3'}>
        <div className={'lg:w-3/4 w-3/5  flex flex-col lg:flex-row sm:flex-1'}>
            <div className="lg:w-3/4  w-full flex flex-col gap-2 mb-2">
                <Skeleton className="w-full md:w-3/4" size={'medium'}/>
                <Skeleton size={'medium'}/>
            </div>
            <div
                className=
                    "lg:w-1/4 w-full flex items-center gap-2 lg:items-start lg:flex-col">
                <Skeleton size={'small'}/>
                <span className={'text-[8px] lg:hidden'}>•</span>
                <Skeleton size={'small'}/>
            </div>
        </div>
        <div
            className={'w-2/5 lg:w-1/4 gap-2 flex items-center ml-auto justify-end'}>
            <Skeleton size={'small'}/>
            <Skeleton size={'small'}/>
        </div>
    </div>
);
export default ProductCardPlaceholder;

import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Data } from 'react-csv/components/CommonPropTypes';
import { Router, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button, Container, Separator, toast, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { CatalogImportHeader } from '@v2/components/CatalogImport/CatalogImportHeader/CatalogImportHeader';
import { CatalogImportProductSection,
    ESupplierCatalogImportProductSectionType } from '@v2/components/CatalogImport/CatalogImportProductSection/CatalogImportProductSection';
import { useBulkUploadStore } from '@v2/stores/BulkUploadStore';
import { getInvalidCsvData } from '@v2/Pages/Setup/Catalog/Import/CatalogImportConstants';
import { useMutation } from '@tanstack/react-query';
import { bulkImportProductsCommit } from '@v2/network/SecretShopAPI';
import { EPaths } from '@v2/constants/EPaths';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import useBuyerHook from '@v2/hooks/useBuyer.hook';

export interface SupplierCatalogImportProps {
    router?: Router,
}
export const CatalogImport: React.FC<SupplierCatalogImportProps> = withRouter(({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'SupplierCatalogImport' });
    const { completeTask } = useOnboardingStore();
    const { buyer } = useBuyerHook();
    const {
        validateImportInfo,
        csvColumns,
        columnMapping,
        bulkImportRequestPayload,
        cleanBulkUploadStore,
        currentSupplier,
    } = useBulkUploadStore();
    const addedProducts = validateImportInfo?.addedProducts ?? [];
    const updatedProducts = validateImportInfo?.updatedProducts ?? [];
    const invalidProducts = validateImportInfo?.invalidProducts ?? [];
    const hasAddedProducts = addedProducts.length > 0;
    const hasUpdatedProducts = updatedProducts.length > 0;
    const hasInvalidProducts = invalidProducts.length > 0;
    const invalidProductsList = hasInvalidProducts ? invalidProducts.map((invalid) => ({
        ...invalid.product,
        errors: invalid.errors
    })) : [];
    const csvData: Data = getInvalidCsvData(invalidProducts, columnMapping);
    const totalProducts = addedProducts.length + updatedProducts.length;
    const commitCatalogMutation = useMutation(bulkImportProductsCommit);

    const handleConfirm = ():void => {
        commitCatalogMutation.mutate(bulkImportRequestPayload, {
            onSuccess: () => {
                router.push(EPaths.CATALOG_MANAGEMENT.replace(':supplierUrlsafeKey', currentSupplier?.supplierUrlsafeKey));
                toast.show({
                    message: t('savedToCatalogSuccessfully', {
                        supplierName: currentSupplier?.supplierName,
                    }),
                    showClose: false
                });
                completeTask(buyer.urlsafe, EOnboardingTasks.ADD_BILL);
                cleanBulkUploadStore();
            },
            onError: () => {
                toast.show({
                    message: t('errorSavingToCatalog', { supplierName: currentSupplier?.supplierName ?? '' }),
                    showClose: false
                });
            }
        });
        sendTrackingEvent(TrackingEvents.catalogBulkUploadConfirm);
    };

    useEffect(() => {
        if (!validateImportInfo || !csvColumns || !columnMapping || !bulkImportRequestPayload || !bulkImportRequestPayload.data) {
            router?.push(EPaths.HOME);
        }
    }, []);

    useEffect(() => {
        window.Intercom('update', {
            hide_default_launcher: true
        });

        return (() => {
            window.Intercom('update', {
                hide_default_launcher: false
            });
        });
    }, []);

    return <>
        <CatalogImportHeader />
        <Separator/>
        {hasAddedProducts && <CatalogImportProductSection products={addedProducts}
            title={t('newProducts')}
            subtitle={t('nProducts', { count: addedProducts.length })}
            type={ESupplierCatalogImportProductSectionType.SUCCESS}/>}
        {hasUpdatedProducts
            && <CatalogImportProductSection products={updatedProducts}
                title={t('updatedProducts')}
                subtitle={t('nProductsUpdated', { count: updatedProducts.length })}
                type={ESupplierCatalogImportProductSectionType.SUCCESS}/>}
        {hasInvalidProducts && <CatalogImportProductSection products={invalidProductsList}
            hideSeparator
            title={t('invalidProducts')}
            subtitle={<div
                className="flex justify-between gap2">
                <div
                    className="w-7/12">{t('invalidProductsErrors')}</div>
                <CSVLink data={csvData}
                    headers={csvColumns}
                    filename={`${currentSupplier?.supplierName ?? ''}_invalid_products.csv`}>
                    <Button variant="TERTIARY_FILLED" size="MEDIUM">{t('downloadInvalidCsv')}</Button>
                </CSVLink>

            </div>}
            type={ESupplierCatalogImportProductSectionType.ERROR}/>}
        <Container fluid className="border-y border-gray-300 sticky bottom-0 bg-white" >
            <Container className="my-1" >
                <div className="flex justify-between gap-4 my-8">
                    <Typography>
                        <span className="font-semibold">{totalProducts}</span> {t('productsImported')}
                    </Typography>
                    <div className="flex gap-3">
                        <Button variant="TERTIARY_FILLED"
                            size="MEDIUM"
                            onClick={():void => {
                                cleanBulkUploadStore();
                                window.history.back();
                            }}>{tCommon('Buttons.cancel')}</Button>
                        <Button variant="SECONDARY"
                            size="MEDIUM"
                            loading={commitCatalogMutation.isLoading}
                            onClick={handleConfirm}>{t('saveAndAddToCatalog')}</Button>
                    </div>
                </div>
            </Container>
        </Container>

    </>;
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { changeRoute } from '../../actions/routerActions';
import { getBuyer } from '../../selectors/buyerSelectors';
import withUseIsMobileWidth from '../../domains/shared/hooks/withUseIsMobileWidth';

import CheckPermission from '../general/CheckPermission.jsx';
import {EPaths} from "@v2/constants/EPaths";

class NoOrdersCheckout extends Component {
  render() {
    const {
      isPendingOrders,
      displayText,
      buyer,
      location,
      dispatch
    } = this.props;

    const goToOrderDeskPage = () => (
      dispatch(
        changeRoute({
          pathname: EPaths.HOME,
          query: {
            bid: buyer.id,
            ...location.query,
          }
        })
      )
    );

    return (
      <div className="col-md-8 col-sm-offset-2 checkout-no-orders">
        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
        {isPendingOrders ? (
          <div>
            <div className="no-orders-title">{displayText}</div>
            <ul>
              <li>
                <CheckPermission roles={['Owner', 'Manager']}>
                  <Link to="/invoices/">
                    View Invoices and Payments
                    <span className="checkout-arrow-right-green"></span>
                  </Link>
                </CheckPermission>
              </li>
              <li>
                <Link to={EPaths.HOME}>
                  Go to Order Desk
                  <span className="checkout-arrow-right-green"></span>
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div>
            <div className="no-orders-title">
              You have no more items in this order.
            </div>

            <button className="btn btn-primary" onClick={goToOrderDeskPage}>
              Return to order desk
            </button>
          </div>
        )}
      </div>
    );
  }
}

NoOrdersCheckout.propTypes = {
  isPendingOrders: PropTypes.bool.isRequired,
  displayText: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.object
  }).isRequired,
  buyer: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  buyer: getBuyer(state)
});

export default compose(connect(mapStateToProps), withRouter, withUseIsMobileWidth)(NoOrdersCheckout);

import React, { useContext, useState } from 'react';
import { Modal, Typography, Button, Separator, toast, onMobileSetLarge } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import { OrderGuidesList } from '@v2/components/Market/OrderGuideList/OrderGuideList';
import { useMutation } from '@tanstack/react-query';
import { addProductToOrderGuide } from '@v2/network/SecretShopAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { OrderGuideListCreateItem } from '@v2/components/Market/AddProductToOrderGuidesModal/OrderGuideListCreateItem';
import CheckIcon from '@icons/check-icon.svg';
import { useGetOrderGuides } from '@v2/Pages/Market/MarketQueries.hook';
import { OrderGuide } from '@v2/network/types';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';

export interface OrderGuidesAddProductModalProps {
    onClose?: () => void,
    title?: React.ReactNode,
}

const getOrderGuideById = (orderGuides: OrderGuide[], id: string): OrderGuide | undefined => orderGuides.find((orderGuide) => orderGuide.id === id);

export const AddProductToOrderGuidesModal : React.FC<OrderGuidesAddProductModalProps> = ({ onClose = (): void => null, }) => {
    const { modals, setModalVisibility, setActiveProduct, activeProduct = { name: '' } } = useContext(MarketContext);
    const [orderGuidesSelected, setOrderGuidesSelected] = useState<string[]>([]);
    const closeModal = ():void => {
        setOrderGuidesSelected([]);
        setModalVisibility('addProductToOrderGuide', false);
        setActiveProduct(undefined);
        onClose();
    };
    const { buyer } = useBuyerHook();
    const { data } = useGetOrderGuides(buyer.urlsafe);
    const orderGuides = data?.orderGuides || [];
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';

    const addProductToOrderGuideMutation = useMutation(addProductToOrderGuide, {
        onSuccess: (_response, { orderGuideID }) => {
            toast.show({
                message: `Product ${activeProduct.name} added to ${getOrderGuideById(orderGuides, orderGuideID).name} order guide`,
                icon: <CheckIcon />,
                showClose: false
            });
        },
        onError: (error, { orderGuideID }) => {
            toast.show({
                message: `Failed to add product ${activeProduct.name} to ${getOrderGuideById(orderGuides, orderGuideID).name} order guide`,
            });
        }
    });

    const handleOrderGuideSelected = (id: string): void => {
        if (orderGuidesSelected.includes(id)) {
            setOrderGuidesSelected(orderGuidesSelected.filter((item) => item !== id));
        } else {
            setOrderGuidesSelected([...orderGuidesSelected, id]);
        }
    };
    const isButtonDisabled = orderGuidesSelected.length === 0;

    const handleAddToOrderGuides = (): void => {
        orderGuidesSelected.forEach((orderGuideID) => {
            addProductToOrderGuideMutation.mutate({
                buyerUrlsafeKey: buyer.urlsafe,
                orderGuideID,
                productID: activeProduct.id,
            });
        });
        closeModal();
    };

    return (
        <Modal isOpen={modals.addProductToOrderGuide}
            close={closeModal}
            modalSize={onMobileSetLarge('SMALL')}
            onClose={closeModal}
            title={<>
                {isMobileWidth && <Typography className="text-gray-600 mb-3" as="div">
                    {activeProduct?.name}
                </Typography>}
                <Typography variant="XL" className="m-0" weight="font-semibold">
                    Choose where to save this product
                </Typography>
            </>}
            description={activeProduct?.name}>
            <div className="w-full h-full flex flex-col gap-3 mt-4">
                <div className="flex gap-6 flex-col">
                    <OrderGuidesList isSelectMode orderGuidesSelected={orderGuidesSelected} onSelectOrderGuide={handleOrderGuideSelected} />
                    <OrderGuideListCreateItem onClick={():void => setModalVisibility('addEditOrderGuide', true)}/>
                </div>
                <Separator variant="small" />
                <div className={'px-4 py-5 mt-auto sticky bottom-0'}>
                    <div className="flex items-center justify-between flex-col gap-3">
                        <Button variant="SECONDARY"
                            size={buttonSize}
                            className={'w-full'}
                            onClick={handleAddToOrderGuides}
                            disabled={isButtonDisabled}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.save')}
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>

        </Modal>
    );
};

import CloseIcon from '@icons/close-icon.svg';
import ArrowsBidrectionalIcon from '@icons/arrows-bidirectional-icon.svg';
import ArrowsTopLeftIcon from '@icons/arrows-top-left-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import CircleIcon from '@icons/circle-icon.svg';
import PendingIcon from '@icons/pending-icon.svg';
import HelpIcon from '@icons/help-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { Badge } from '@notch-ordering/ui-components';
import { useInfiniteQuery } from '@tanstack/react-query';
import { EInvoiceStatus, EStripeStatus } from '@v2/constants/EInvoiceStatus';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { tNamespace } from '@v2/i18n';
import { ExternalInvoicesResponse, fetchExternalInvoices, StripeInvoice } from '@v2/network/CoreAPI';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBillsStore } from '@v2/stores/BillsStore';
import { BADGE_VARIANTS } from '@notch-ordering/ui-components/dist/components/Badge/BadgeConstants';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { FETCH_EXTERNAL_INVOICES } from '@v2/network/BushwhackAPI';
import { InvoicesPageContext } from '@v2/Pages/Invoices/InvoicesPageContext';

type Props = {
    invoice: InvoiceData,
};

export const BillStatusBadge : React.FC<Props> = ({ invoice }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const [badgeVariant, setBadgeVariant] = useState<keyof typeof BADGE_VARIANTS>('GRAY');
    const [icon, setIcon] = useState(<CircleIcon />);
    const [fetchStripeStatus, setFetchStripeStatus] = useState(false);
    const [invoiceStatus, setInvoiceStatus] = useState<string>(EInvoiceStatus.UNPAID);
    const { buyer } = useBuyerHook();
    const { setStripeStatus, getStripeStatus } = useBillsStore();
    const { isAccountingEnabled } = useContext(InvoicesPageContext);

    const { fetchNextPage, hasNextPage } = useInfiniteQuery<ExternalInvoicesResponse>(
        [FETCH_EXTERNAL_INVOICES, buyer.urlsafe],
        ({ pageParam = null }) => fetchExternalInvoices(100, buyer.urlsafe, pageParam),
        {
            enabled: !!buyer.urlsafe && fetchStripeStatus,
            getNextPageParam: (lastPage) => lastPage.nextCursor,
            retry: false,
            onSuccess: (data) => {
                if (data?.pages) {
                    const externalInvoices: StripeInvoice[] = data.pages.reduce((arr, current) => arr.concat(current.results), []);
                    // Legacy invoices and invoices generated from an order will use orderUrlsafeKey, all others use just id
                    const searchID = invoice.orderUrlsafeKey ? invoice.orderUrlsafeKey : invoice.id;
                    const externalInvoice = externalInvoices.find((inv) => inv.id === searchID);
                    if (externalInvoice) {
                        setInvoiceStatus(externalInvoice.stripeStatus);
                        // Cache these in bills store so they can be accessed by MUI Data Grid
                        setStripeStatus(searchID, externalInvoice.stripeStatus);
                    } else if (hasNextPage) {
                        fetchNextPage();
                    } else {
                        const cachedStatus = getStripeStatus(searchID);
                        if (cachedStatus) {
                            setInvoiceStatus(cachedStatus);
                        }
                    }
                }
            }
        }
    );

    useEffect(() => {
    // Prepare invoice data from greevils-greed,
    // If found, we don't need to fetch the payment status from stripe or the accounting system.
        if (isAccountingEnabled && invoice?.isTwoWayAccounting) {
            if (invoice.lastEditedDateByAccounting && invoice.amountPaid > 0) {
            // note: We are not supporting partial payments currently so consider them full payment
                setInvoiceStatus(EInvoiceStatus.PAID);
            } else {
                setFetchStripeStatus(true);
            }
        } else {
            setFetchStripeStatus(true);
        }
    }, [isAccountingEnabled]);

    useEffect(() => {
        switch (invoiceStatus) {
            case EStripeStatus.PAID:
            case EInvoiceStatus.PAID:
            case EInvoiceStatus.REMOTE_PAID:
                setBadgeVariant('GREEN');
                setIcon(<CheckIcon />);
                break;
            case EStripeStatus.UNCOLLECTIBLE:
                setBadgeVariant('RED');
                setIcon(<HelpIcon />);
                break;
            case EStripeStatus.DELETED:
                setBadgeVariant('GRAY');
                setIcon(<TrashIcon />);
                break;
            case EStripeStatus.SYNCED:
                setBadgeVariant('RED');
                setIcon(<ArrowsBidrectionalIcon />);
                break;
            case EInvoiceStatus.REFUNDED:
                setBadgeVariant('TEAL');
                setIcon(<ArrowsTopLeftIcon />);
                break;
            case EStripeStatus.DRAFT:
            case EInvoiceStatus.PROCESSING:
            case EStripeStatus.OPEN: // An 'open' stripe status means a payment is pending since an associated stripe invoice exists
                setBadgeVariant('GRAY');
                setIcon(<PendingIcon />);
                break;
            case EStripeStatus.VOID:
            case EStripeStatus.FAILED:
                setBadgeVariant('RED');
                setIcon(<CloseIcon />);
                break;
            case EInvoiceStatus.UNPAID:
            default:
                setBadgeVariant('GRAY');
                setIcon(<CircleIcon/>);
                break;
        }
    }, [invoiceStatus]);

    let label;
    // Map the status strings to the display labels
    switch (invoiceStatus) {
        case EInvoiceStatus.UNPAID:
            label = 'open';
            break;
        case EStripeStatus.DRAFT:
        case EStripeStatus.OPEN:
            label = 'processing';
            break;
        default:
            label = invoiceStatus;
            break;
    }

    return <Badge variant={badgeVariant} size={'SMALL'} iconLeft={icon}>
        <span>{t(`Status.${label}`)}</span>
    </Badge>;
};

import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';

import Utils from '@/utils';
import { Typography } from '@notch-ordering/ui-components';
import Hero from '../hero';
import OrderInfoSection from '../order-info-section';

const OrderInfo = ({
  deliveryDay,
  shippingAddress,
  vendorName,
  location: route,
}) => {
  const { isAddOnOrder } = route.state || {};
  const deliveryDate = Utils.formatDate(deliveryDay,'dddd, MMMM D');

  return (
    <div className="OrderInfo">
      <Hero />

      <div className="OrderInfo__secondary-container">
        <Typography variant='XL' desktopSize='lg:text-6' weight='font-regular' className="OrderInfo__main-confirmation-text">
          {isAddOnOrder ? 'The changes to your order' : 'Your order'} from{' '}
          <span className="OrderInfo__supplier-name">{vendorName}</span> has
          been submitted for delivery on {deliveryDate}.
        </Typography>

        <OrderInfoSection heading="Delivery address">
          <Typography weight='font-medium' className="OrderInfo__address">
            {`${shippingAddress.line1}
              ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zip}`}
          </Typography>
        </OrderInfoSection>
      </div>
    </div>
  );
};

OrderInfo.propTypes = {
  deliveryDay: PropTypes.string.isRequired,
  shippingAddress: PropTypes.shape({
    line1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }).isRequired,
  vendorName: PropTypes.string.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      isAddOnOrder: PropTypes.bool,
    })
  }).isRequired,
};

export default withRouter(OrderInfo);

import React, { useEffect } from 'react';
import { ScanOverviewItem } from '@v2/components/ScanReview/ScanOverview/ScanOverviewItem';

import { t } from '@v2/i18n';
import { LineItemFocusType, useOcrInvoiceStore, LineItemCategory, LineItemCategoryEnum } from '@notch-ordering/shared-logic';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import WarningIcon from '@icons/warning-filled-icon.svg';

import WarningInProgressIcon from '@icons/warning-in-progress-icon.svg';
import LowConfidenceScanIcon from '@icons/low-confidence-scan-filled-icon.svg';
import LowConfidenceScanInProgressIcon from '@icons/low-confidence-scan-in-progress-icon.svg';
import SuccessIcon from '@icons/success-icon.svg';
import { getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';

export const WarningAlert = <WarningIcon className="h-8 w-8 text-orange-400" />;
export const WarningAlertInProgress = <WarningInProgressIcon className="h-[39px] w-[39px]" />;
export const LowConfidenceAlertInProgress = <LowConfidenceScanInProgressIcon className="h-[39px] w-[39px]" />;
export const LowConfidenceAlert = <LowConfidenceScanIcon className="h-8 w-8 text-orange-400" />;

export const SuccessAlert = <div className="bg-green-100 rounded-lg h-8 w-8 flex items-center justify-center relative">
    <SuccessIcon className="h-4 w-4 text-green-500" />
</div>;
/**
 Gets the message, icon, and optional button text for the required fields section of the scan overview.
 *
 @param {number} initialErrorCount - The initial number of missing required fields.
 @param {number} currentErrorCount - The current number of missing required fields.
 @returns {{ message: React.ReactNode, icon: JSX.Element, buttonText?: string }} The message, icon, and optional button text.
 */
const getMessageAndIconRequiredFields = (initialErrorCount, currentErrorCount): { message: React.ReactNode, icon: JSX.Element, buttonText?: string } => {
    if (initialErrorCount === 0) {
        return { message: t('ScanReview.LineItems.noRequiredFieldsAreMissing'), icon: SuccessAlert };
    }
    if (currentErrorCount === 0) {
        return { message: t('ScanReview.LineItems.fieldsHaveBeenResolved', { count: initialErrorCount }), icon: SuccessAlert, buttonText: '' };
    }
    if (initialErrorCount > 0 && currentErrorCount === 0) {
        return { message: t('ScanReview.LineItems.requiredFieldsCount', { count: initialErrorCount }), icon: WarningAlert, buttonText: t('ScanReview.LineItems.resolve') };
    }
    if (currentErrorCount === 0) {
        return { message: t('ScanReview.LineItems.fieldsHaveBeenResolved', { count: initialErrorCount }), icon: SuccessAlert };
    }
    if (currentErrorCount > 0 && currentErrorCount < initialErrorCount) {
        return { message: <>{initialErrorCount - currentErrorCount} <span className="text-gray-600">{t('ScanReview.LineItems.ofRequiredFieldsHaveBeenResolved', { count: initialErrorCount })}</span></>,
            icon: WarningAlertInProgress,
            buttonText: t('ScanReview.LineItems.resolve') };
    }

    return { message: `${initialErrorCount} required fields are missing`, icon: WarningAlert, buttonText: t('ScanReview.LineItems.resolve') };
};

/**
 Gets the message, icon, and optional button text for the low confidence fields section of the scan overview.
 *
 @param {number} initialErrorCount - The initial number of missing low confidence fields.
 @param {number} currentErrorCount - The current number of missing low confidence fields.
 @returns {{ message: React.ReactNode, icon: JSX.Element, buttonText?: string }} The message, icon, and optional button text.
 */
const getMessageAndIconLowConfidenceFields = (initialErrorCount, currentErrorCount): { message: React.ReactNode, icon: JSX.Element, buttonText?: string } => {
    if (initialErrorCount === 0) {
        return { message: t('ScanReview.LineItems.noLowConfidenceFieldsAreMissing'), icon: SuccessAlert };
    }
    if (currentErrorCount === 0) {
        return { message: t('ScanReview.LineItems.fieldsHaveBeenResolved', { count: initialErrorCount }), icon: SuccessAlert, buttonText: t('ScanReview.LineItems.review') };
    }
    if (initialErrorCount > 0 && currentErrorCount === 0) {
        return { message: t('ScanReview.LineItems.lowConfidenceFieldsCount', { count: initialErrorCount }), icon: LowConfidenceAlert, buttonText: t('ScanReview.LineItems.review') };
    }
    if (currentErrorCount === 0) {
        return { message: t('ScanReview.LineItems.fieldsHaveBeenResolved', { count: initialErrorCount }), icon: SuccessAlert };
    }
    if (currentErrorCount > 0 && currentErrorCount < initialErrorCount) {
        return { message: <>{initialErrorCount - currentErrorCount} <span className="text-gray-600">{t('ScanReview.LineItems.ofLowConfidenceFieldsHaveBeenResolved', { count: initialErrorCount })}</span></>,
            icon: LowConfidenceAlertInProgress,
            buttonText: '' };
    }

    return { message: `${initialErrorCount} low confidence fields are missing`, icon: LowConfidenceAlert, buttonText: t('ScanReview.LineItems.review') };
};

export const ScanOverviewList : React.FC = () => {
    const { draftInvoice, setFocusType, setShowFocusPanel, formErrors, validateForm } = useOcrInvoiceStore();
    const { setCurrentView, setShowRequiredFieldsOverviewCard, showRequiredFieldsOverviewCard } = useOcrInvoiceContext();
    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();

    const { lowConfidenceFields } = draftInvoice;
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);
    const totalMissingRequiredFields = draftInvoice?.missingRequiredFields[lineItemCategoryToValidate]?.totalCount ?? 0;
    const hasLowConfidenceFields = formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount > 0 ?? 0;
    const hasMissingRequiredFields = totalMissingRequiredFields > 0;
    const errorLineItemFieldsCount = formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount ?? 0;
    const hasErrorLineItemFields = errorLineItemFieldsCount > 0;
    const requiredMessageIconAndButtonText = getMessageAndIconRequiredFields(totalMissingRequiredFields, errorLineItemFieldsCount);
    const lowConfidenceMessageIconAndButtonText = getMessageAndIconLowConfidenceFields(lowConfidenceFields[lineItemCategoryToValidate]?.totalCount, formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount);

    useEffect(() => {
        if (hasErrorLineItemFields && !showRequiredFieldsOverviewCard) {
            setShowRequiredFieldsOverviewCard(true);
        }
    }, [hasErrorLineItemFields]);

    useEffect(() => {
        validateForm(lineItemCategoryToValidate);
    }, [selectedTab]);

    return <>
        {hasMissingRequiredFields && showRequiredFieldsOverviewCard && <ScanOverviewItem
            icon={requiredMessageIconAndButtonText.icon}
            title={requiredMessageIconAndButtonText.message}
            onClick={(): void => {
                if (hasErrorLineItemFields) {
                    setCurrentView(lineItemCategoryToValidate === LineItemCategoryEnum.ChartOfAccountDetails ? ScanReviewViews.ChartOfAccountDetails : ScanReviewViews.LineItems);
                    setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);
                    setShowFocusPanel(true);
                } else {
                    setShowRequiredFieldsOverviewCard(false);
                }
            }}
            separator={!!hasLowConfidenceFields}
            showClose={!hasErrorLineItemFields}
            buttonText={requiredMessageIconAndButtonText?.buttonText}/>}
        {hasLowConfidenceFields && <ScanOverviewItem icon={lowConfidenceMessageIconAndButtonText.icon}
            title={lowConfidenceMessageIconAndButtonText.message}
            description="Double check to make sure this information is correct."
            buttonText={lowConfidenceMessageIconAndButtonText?.buttonText}
            onClick={(): void => {
                setCurrentView(lineItemCategoryToValidate === LineItemCategoryEnum.ChartOfAccountDetails ? ScanReviewViews.ChartOfAccountDetails : ScanReviewViews.LineItems);
                setFocusType(LineItemFocusType.LowConfidence, lineItemCategoryToValidate);
                setShowFocusPanel(true);
            }}
            separator={false}/>}
    </>;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
import { IPackage, IProduct, ISupplierCatalog } from '@v2/network/types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getPackageFromOrder(productsInOrder, packageId: string) {
    return productsInOrder?.find((p) => p?.sourceUrlsafe === packageId);
}

type MapToOrderProductArgs = {
    product: IProduct,
    packageProduct: IPackage,
    notes?: string,
};

/**
 *
 * Calculates the price of a product package
 *
 * @param pack : IPackage
 */
export function calculatePackagePrice(pack: IPackage) : number {
    return pack.pricePerUnit * pack.quantity;
}

/**
 *
 *
 * @description Map product from api into order product format
 * @param product
 * @param packageProduct
 * @param notes
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function mapPackageToOrderProduct({
    product,
    packageProduct,
    notes = ''
}: MapToOrderProductArgs) {
    const price = calculatePackagePrice(packageProduct);
    const quantityInCart = packageProduct.quantityInCart ?? 1;
    return {
        id: packageProduct.id,
        name: product.name,
        original_unit_quantity: quantityInCart,
        disputedDescription: product.description,
        originalQuantity: quantityInCart,
        isTaxable: !!product.taxCode,
        pricePerUnit: packageProduct.pricePerUnit,
        salesUnit: packageProduct.unit,
        unit_price: packageProduct.pricePerUnit,
        unitName: packageProduct.unit,
        externalId: product.sku,
        packaging_price: packageProduct.pricePerUnit,
        sourceUrlsafe: packageProduct.id,
        unit_quantity: quantityInCart,
        packaging_quantity: quantityInCart,
        totalQuantity: quantityInCart,
        categories: [
            product.category
        ],
        original_packaging_quantity: quantityInCart,
        productCode: product.id,
        salesQuantity: quantityInCart,
        description: product.description,
        price,
        unitDescription: packageProduct.description,
        quantity: quantityInCart,
        priceTotal: price,
        total: price,
        urlsafe: packageProduct.id,
        notes,
        isOrganic: false,
        expected_delivery_quantity: 0,
        taxPercentage: 0,
        isLocal: false,
        receivalIssueDescription: '',
        expected_delivery_unit_description: '',
        isBYOS: false,
        expected_delivery_unit_name: '',
        receivalIssueChange: '',
        createdBy: '',
        hasReceivalIssue: false,
        taxAmount: 0,
        is_old_package: false,
        expected_delivery_sales_quantity: 0,
        modifiedAt: '',
        genericItem: {
            imageURL: ''
        },
        isAddonItem: false,
        createdAt: '',
        expected_delivery_total: 0,
        modifiedBy: '',
        sourceID: '',
        expected_delivery_unit_quantity: 0,
        expected_delivery_price: 0,
        expected_delivery_sales_unit: '',
        disputedFlow: '',
        imageUrl: '',
        isPreOrder: false,
        editMode: false,
        vendorID: '',

    };
}

/**
 * @description Returns the supplier catalog object by vendor url safe key
 * @param suppliers
 * @param vendorUrlSafe
 */
export const getSupplierByVendorUrlSafe = (suppliers: ISupplierCatalog[], vendorUrlSafe: string):ISupplierCatalog => suppliers.find((supplier) => supplier.supplierUrlsafeKey === vendorUrlSafe);

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OrderItemsContainer from '../../../../../containers/checkout/OrderItemsContainer.jsx';

export default class OrderCheckoutBodyReview extends Component {
  render() {
    const { orderProducts } = this.props;

    return <OrderItemsContainer orderItems={orderProducts} {...this.props} />;
  }
}

OrderCheckoutBodyReview.propTypes = {
  orderProducts: PropTypes.array.isRequired,
  buyer: PropTypes.object.isRequired,
};

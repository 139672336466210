import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

export const ScanOverviewHeader : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview' });
    return <div className="py-5 px-8 mt-5 mb-2">
        <Typography as="h1"
            variant="3XL"
            className="text-gray-700 mx-0 mb-2 mt-0"
            desktopSize="lg:text-7"
            weight="font-bold">{t('header.reviewScannedBill')}</Typography>
        <Typography className="text-gray-600 m-0" weight="font-regular" >
            {t('header.makeSureScannedInformation')}
        </Typography>
    </div>;
};

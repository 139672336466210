import React from 'react';
import { Typography } from '@notch-ordering/ui-components';

export interface Props {
    title: React.ReactNode,
    description: React.ReactNode,
}

export const MarketEmptyState : React.FC<Props> = ({ title, description }) => <>
    <div className={'w-full flex justify-center items-center flex-col gap-2 text-center h-full '}>
        <Typography as="h1" weight="font-semibold" className="p-0 m-0">
            {title}
        </Typography>
        <Typography as="p" variant="BASE" className="text-gray-600 p-0 m-0">
            {description}
        </Typography>
    </div>
</>;

export default MarketEmptyState;

import React, { useState } from 'react';
import { IActiveFilters } from '@v2/Pages/Market/MarketConstants';
import { IProduct } from '@v2/network/types';
import { addSavedProduct, removeFavoriteProduct, TFetchProductsType } from '@v2/network/SecretShopAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { favoriteMutationOptions } from '@v2/Pages/Market/MarketQueries.hook';
import { toast } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

export type TMarketModals = {
    switchOrderGuide: boolean,
    deleteOrderGuide: boolean,
    addProductToOrderGuide: boolean,
    addEditOrderGuide: boolean,
    unsaveProduct: boolean,
};

export const MARKET_MODAL_INITIAL_STATE:TMarketModals = {
    addEditOrderGuide: false,
    addProductToOrderGuide: false,
    deleteOrderGuide: false,
    switchOrderGuide: false,
    unsaveProduct: false
};

interface MarketPageContextProps {
    filters:IActiveFilters,
    setFilters:(filters:IActiveFilters)=>void,
    activeProduct?:IProduct,
    setActiveProduct?:(productId:IProduct)=>void,
    fetchTypeSelected: TFetchProductsType,
    setFetchTypeSelected: (type: TFetchProductsType) => void,
    orderGuideIDSelected?:string,
    setOrderGuideIDSelected?:(orderGuideID:string)=>void,
    setSavedProduct?:(product:IProduct)=>Promise<void>,
    modals?:TMarketModals,
    setModalVisibility?:(modal:keyof TMarketModals, value: boolean)=>void,
}

/**
 *
 * BuyerSupplierContext - Context for the buyer supplier context
 */
export const MarketContext = React.createContext<MarketPageContextProps>({
    filters: {} as IActiveFilters,
    setFilters: () => {},
    fetchTypeSelected: TFetchProductsType.SAVED,
    setFetchTypeSelected: () => {},
    setSavedProduct: () => Promise.resolve(),
    setModalVisibility: () => {},
});

type MarketProviderProps = MarketPageContextProps & { children: React.ReactNode };

export const MarketProvider = ({
    children, setFilters, filters,
    activeProduct,
    setActiveProduct,
    fetchTypeSelected,
    setFetchTypeSelected,

}:MarketProviderProps): JSX.Element => {
    const [orderGuideSelected, setOrderGuideSelected] = React.useState('');
    const [modals, setModals] = useState<TMarketModals>(MARKET_MODAL_INITIAL_STATE);
    const { buyer } = useBuyerHook();
    const queryClient = useQueryClient();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Shop' });
    const setModalVisibility = (modal: keyof TMarketModals, show: boolean):void => {
        setModals({
            ...modals,
            [modal]: show
        });
    };
    const shouldRemoveFromListSavedProductOptimistically = fetchTypeSelected === TFetchProductsType.SAVED || fetchTypeSelected === TFetchProductsType.ORDER_GUIDE;
    const addSavedProductMutation = useMutation(addSavedProduct, { ...favoriteMutationOptions(queryClient, filters, fetchTypeSelected),
        onSuccess: () => {
            toast.show({
                message: <>Saved to <strong>All Saved Products</strong></>,
                showClose: false,
                action: {
                    text: 'Edit',
                    onClick: () => {
                        setModalVisibility('addProductToOrderGuide', true);
                    }
                }
            });
        } });
    const removeSavedProductMutation = useMutation(removeFavoriteProduct, { ...favoriteMutationOptions(queryClient, filters, fetchTypeSelected, shouldRemoveFromListSavedProductOptimistically),
        onSuccess: () => {
            toast.show({
                message: t('productRemovedFromSavedProducts'),
                showClose: false,
            });
        } });
    const setSavedProduct = async (product?: IProduct): Promise<void> => {
        const payload = {
            buyerUrlsafeKey: buyer.urlsafe,
            productID: product.id,
        };
        if (!product.isSaved) {
            setActiveProduct(product);
            addSavedProductMutation.mutate(payload);
        } else {
            removeSavedProductMutation.mutate(payload);
        }
    };

    return <MarketContext.Provider value={{
        filters,
        setFilters,
        activeProduct,
        setActiveProduct,
        fetchTypeSelected,
        setFetchTypeSelected,
        orderGuideIDSelected: orderGuideSelected,
        setOrderGuideIDSelected: setOrderGuideSelected,
        setSavedProduct,
        modals,
        setModalVisibility,
    }}>{children}</MarketContext.Provider>;
};

import PropTypes from 'prop-types';
import React from 'react';

export default function SettingsBody(props) {
  return (
    <div className="settings-view-panel">{props.currentSettingsTabView}</div>
  );
}

SettingsBody.propTypes = {
  currentSettingsTabView: PropTypes.object.isRequired, // These are Settings Body Views coming from the route
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';

import * as OrderActions from '../../actions/orderActions';
import * as CheckoutActions from '../../actions/checkoutActions';
import * as RouterActions from '../../actions/routerActions';

import OrderItemsList from '../../components/checkout/step2/OrderItemsList.jsx';
import { AnalyticsEventSources } from '../../constants/AnalyticsEventSources';

class OrderItemsContainer extends Component {
  componentDidMount() {
    const {
      isEditingSingleOrder,
      order,
      singleOrderBeforeChange,
      router,
      orderActions,
      checkoutActions,
    } = this.props;

    // Preserve original order quantites before addOn changes to see if any are made.
    if (isEditingSingleOrder && order.isEditingAddOnOrder) {
      // We need to create a proper deep copy of the item object or else it will be passed
      // by reference and change the reducer object (singleOrder quantity for items)
      const originalOrder = {
        ...order,
        items: order.items.map((item) => ({
          ...item,
          quantity: item.quantity,
        })),
      };

      // older original gets written by the previous
      orderActions.receiveSingleOrders(originalOrder);

      const currentRoute =
        !_.isEmpty(router) && router.routes
          ? (router.routes || []).find(
              (r) => r.path === '/checkout/pending/:order_urlsafe/'
            )
          : null;

      if (currentRoute) {
        this.unregisterLeaveHook = router.setRouteLeaveHook(
          currentRoute,
          this.routerWillLeave
        );
      }
    }
  }

  componentWillUnmount() {
    const { order, checkoutActions } = this.props;

    // If we are going back to the summary page for AddOn orders, we cancel all the changes made.
    // Otherwise, we won't be able to compare what changes they made.
    const currentLocation = browserHistory.getCurrentLocation();
    if (
      currentLocation &&
      currentLocation.pathname === '/checkout/pending/' &&
      order.isEditingAddOnOrder
    ) {
      checkoutActions.loadOrdersCheckout();
    }

    if (this.unregisterLeaveHook) {
      this.unregisterLeaveHook();
    }
  }

  routerWillLeave = (nextLocation) => {
    const { singleOrderBeforeChange, order, routerActions } = this.props;

    // Use the original order from the server and the order we are currently editing
    // and determine if we are adding any items or changing quantities
    const isOriginalOrderChanged = (order.items || []).some(
      (itemInEditedOrder) => {
        const itemOriginalOrder =
          singleOrderBeforeChange.items.find(
            (item) => item.urlsafe === itemInEditedOrder.urlsafe
          ) || {};
        if (
          !_.isEmpty(itemOriginalOrder) &&
          itemInEditedOrder.quantity === itemOriginalOrder.quantity
        ) {
          return false;
        }
        return true;
      }
    );

    if (isOriginalOrderChanged) {
      // Using native 'confirm' method to show confirmation message
      const result = confirm(
        'Are you sure you want to leave this page? Any unsaved work will be lost'
      );
      if (result) {
        // navigation confirmed
        return true;
      } else {
        // navigation canceled, pushing the previous path
        routerActions.changeRoute(`/checkout/pending/${order.urlsafe}/`);
        return false;
      }
    }
  };

  changeQuantity = (order_urlSafe, orderProduct, quantity, type, product) => {
    const { orderActions } = this.props;

    if (orderProduct && orderProduct.urlsafe) {
      orderActions.changeQuantityWithID(
        order_urlSafe,
        orderProduct,
        quantity,
        type,
        AnalyticsEventSources.CHECKOUT
      );
    } else {
      orderActions.changeQuantityWithoutID(
        order_urlSafe,
        orderProduct,
        quantity,
        type,
        AnalyticsEventSources.CHECKOUT,
        product
      );
    }
  };

  onClickEdit = (order_urlSafe, orderProduct) => {
    const { orderActions, orders } = this.props;

    orderActions.onClickEdit(orders, order_urlSafe, orderProduct);
  };

  saveNoteCart = (order_urlSafe, orderProduct, note) => {
    const { orderActions } = this.props;

    orderActions.saveNoteCart(order_urlSafe, orderProduct, note);
  };

  render() {
    return (
      <OrderItemsList
        {...this.props}
        changeQuantity={this.changeQuantity}
        onClickEdit={this.onClickEdit}
        saveNoteCart={this.saveNoteCart}
      />
    );
  }
}

OrderItemsContainer.propTypes = {
  buyer: PropTypes.object.isRequired,

  changeCheckoutView: PropTypes.func.isRequired,
};

function mapStatetoProps(state) {
  return {
    orders: state.ordersReducer.orders,
    showOrdersTotal: state.ordersReducer.showOrdersTotal,
    singleOrderBeforeChange: state.ordersReducer.singleOrder,

    errorCheckout: state.checkoutReducer.error,
    errorTypeCheckout: state.checkoutReducer.errorType,
    errorMessageCheckout: state.checkoutReducer.errorMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(OrderActions, dispatch),
    checkoutActions: bindActionCreators(CheckoutActions, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
  };
}

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(OrderItemsContainer)
);

import PropTypes from "prop-types";
import React from "react";

import SettingsBody from "./SettingsBody.jsx";
import {Typography} from '@notch-ordering/ui-components';
import {useTitle} from "@v2/hooks/useTitle.hook";
import {tNavigation} from "@v2/i18n";

const SettingsMain = (props) => {
  const { pathname } = props;
  const headerTitle = "Settings";
  useTitle(tNavigation('Settings'));

  return (
    <div className="settings-main col-md-11">
      <Typography
        as="h1"
        weight="font-medium"
        variant="4XL"
        size="text-6"
        className="text-gray-700 text-center pt-safe"
      >
        {headerTitle}
      </Typography>
      <div className="settings-container">
        <SettingsBody {...props} />
      </div>
    </div>
  );
};

SettingsMain.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default SettingsMain;

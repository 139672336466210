import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CheckoutActions from '../../actions/checkoutActions';
import * as OrderActions from '../../actions/orderActions';
import * as BuyerActions from '../../actions/buyerActions';
import * as AnalyticsActions from '../../actions/analyticsActions';

import DeliveryRequests from '../../components/checkout/step1/delivery_requests/DeliveryRequests.jsx';

class DeliveryRequestsContainer extends Component {
  updateOrdersDeliveryRequests = (
    deliveryContactName,
    deliveryContactNumber,
    deliveryRequests
  ) => {
    const { orderActions } = this.props;

    orderActions.updateOrdersDeliveryRequests(
      deliveryContactName,
      deliveryContactNumber,
      deliveryRequests
    );
  };

  changeOrderDeliveryRequestsView = (view) => {
    this.props.checkoutActions.changeOrderDeliveryRequestsView(view);
  };

  showError = (errorType, errorMessage) => {
    this.props.checkoutActions.showError('checkoutError', errorMessage);
  };

  analyticsViewPage = (screenName) => {
    this.props.analyticsActions.viewPage(screenName);
  };

  render() {
    return (
      <DeliveryRequests
        {...this.props}
        updateOrdersDeliveryRequests={this.updateOrdersDeliveryRequests}
        changeOrderDeliveryRequestsView={this.changeOrderDeliveryRequestsView}
        showError={this.showError}
        analyticsViewPage={this.analyticsViewPage}
      />
    );
  }
}

DeliveryRequestsContainer.propTypes = {
  buyer: PropTypes.object.isRequired,
  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

function mapStatetoProps(state) {
  return {
    ...state.checkoutReducer,
    shippingAddresses: state.buyerReducer.buyer.shippingAddresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkoutActions: bindActionCreators(CheckoutActions, dispatch),
    orderActions: bindActionCreators(OrderActions, dispatch),
    buyerActions: bindActionCreators(BuyerActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
  };
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(DeliveryRequestsContainer);

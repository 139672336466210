import React from 'react';
import { OcrState } from '@v2/types/UploadsTypes';
import { Badge } from '@notch-ordering/ui-components';
import { BADGE_VARIANTS } from '@notch-ordering/ui-components/src/components/Badge/BadgeConstants';
import { t } from '@v2/i18n';

export interface UploadOcrStateBadgeProps {
    ocrState: OcrState,
}

const getBadgeVariant = (ocrState: OcrState):keyof typeof BADGE_VARIANTS => {
    switch (ocrState) {
        case 'in_progress':
        case 'parsed':
            return 'GRAY';
        case 'reviewed':
        case 'verified':
            return 'GREEN';
        default:
            return 'GRAY';
    }
};

const getBadgeText = (ocrState: OcrState):string => {
    switch (ocrState) {
        case 'in_progress':
        case 'parsed':
            return t('Uploads.processing');
        case 'reviewed':
        case 'verified':
            return t('Uploads.toReview');
        default:
            return t('Uploads.processing');
    }
};

export const UploadOcrStateBadge : React.FC<UploadOcrStateBadgeProps> = ({ ocrState }) => <>
    <Badge className="inline-block" variant={getBadgeVariant(ocrState)} >
        {getBadgeText(ocrState)}
    </Badge>
</>;

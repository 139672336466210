import React from 'react';
import PropTypes from 'prop-types';
import useOrder from '../hooks/useOrder';

const ReceivingToolWebHeader = ({ loading, closeWebView }) => {
  const {
    order: { data },
  } = useOrder();

  const close = () => {
    closeWebView();
  };

  return (
    <div className="od-web-header-container">
      <div className="od-header">
        <div className="title">
          Report Issue <span>| {data.vendorName}</span>
          <div>
            <span>Order #{data.id}</span>
          </div>
        </div>

        <button onClick={close} disabled={loading}>
          <img
            src="https://storage.googleapis.com/chefhero-storage-release/static/fe-supplier/images/black-close-icon.svg"
            alt="Close Icon"
          />
        </button>
      </div>
    </div>
  );
};

ReceivingToolWebHeader.defaultProps = {
  closeWebView: null,
};

ReceivingToolWebHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  closeWebView: PropTypes.func,
};

export default ReceivingToolWebHeader;

import useFirebaseRemoteConfig from '@v2/hooks/useFirebaseRemoteConfig.hook';
import { Typography } from '@notch-ordering/ui-components';
import React, { useState, useEffect } from 'react';
import { EApPlatform } from '@notch-ordering/shared-logic';
import { tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { IntegrationsExploreCard } from './IntegrationsExploreCard';
import { IntegrationDisplayData } from './IntegrationsUtils';

type Props = {
    onClickConnect: (EApPlatform) => void,
    activeIntegrations: Array<string>,
    accountingIntegrations: Array<IntegrationDisplayData>,
    erpIntegrations: Array<IntegrationDisplayData>,
    hasAccountingIntegration: boolean,
    hasERPIntegration: boolean,
};

export const IntegrationsExplore: React.FC<Props> = ({ onClickConnect, activeIntegrations, accountingIntegrations, erpIntegrations, hasAccountingIntegration, hasERPIntegration }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const { getList } = useFirebaseRemoteConfig();
    const [listOfActiveAccountingIntegrations, setActiveAccountingIntegrations] = useState<Array<string>>([]);

    useEffect(() => {
        const getListOfAccountingIntegrations = async (): Promise<void> => {
            const remoteListOfAccountingIntegrations = await getList(EFirebaseParams.LIST_OF_BUYER_ACCOUNTING_INTEGRATIONS);
            setActiveAccountingIntegrations(remoteListOfAccountingIntegrations);
        };
        getListOfAccountingIntegrations();
    }, []);

    function onClick(plat: EApPlatform): () => void {
        if (Object.values(EApPlatform).includes(plat)) {
            return () => { onClickConnect(plat); };
        }
        return () => {};
    }

    return (
        <div className="p-10">
            {/* Accounting integrations */}
            <Typography as="div" variant="LG-2" weight="font-semibold">{t('accounting')}</Typography>
            <div className="pt-8 flex flex-row flex-wrap gap-10">
                {accountingIntegrations.map((integration) => {
                    if (listOfActiveAccountingIntegrations.includes(integration.name.toLowerCase())) {
                        return <IntegrationsExploreCard
                            key={integration.name}
                            icon={integration.exploreIcon}
                            name={integration.name}
                            description={integration.description}
                            disabled={hasAccountingIntegration}
                            isConnected={activeIntegrations.includes(integration.name)}
                            onClickConnect={onClick(integration.platform as EApPlatform)}
                            enableTooltip />;
                    }
                    return <IntegrationsExploreCard
                        key={integration.name}
                        icon={integration.exploreIcon}
                        name={integration.name}
                        description={integration.description}
                        disabled
                        isConnected={false}
                        onClickConnect={(): void => {}}
                        enableTooltip={false}
                        comingSoon />;
                })}
            </div>
            {/* ERP integrations */}
            <Typography as="div" variant="LG-2" weight="font-semibold" className="pt-20">{t('erp')}</Typography>
            <div className="pt-8 flex flex-row flex-wrap gap-10">
                {erpIntegrations.map((integration) => <IntegrationsExploreCard
                    key={integration.name}
                    icon={integration.exploreIcon}
                    name={integration.name}
                    description={integration.description}
                    disabled={hasERPIntegration}
                    isConnected={activeIntegrations.includes(integration.name)}
                    contactUs
                    enableTooltip={false} />)}
            </div>
        </div>
    );
};

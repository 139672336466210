import React, { FC, useEffect } from 'react';
import { isPlatform } from '@ionic/react';
import { Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { EIonicPlatforms } from '@/constants/Mobile';
import * as Intercom from '../../../third-party/Intercom';

const InvalidVersion: FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'InvalidVersion' });
    let appPage = 'itms-apps://apps.apple.com/ca/app/notch-ordering/id1067130489';
    if (isPlatform(EIonicPlatforms.ANDROID)) {
        appPage = 'https://play.google.com/store/apps/details?id=com.vendorhero.storefront';
    }

    useEffect(() => {
        Intercom.initLoggedOutUser();
    }, []);

    return (
        <div className="flex justify-center items-center flex-col h-full w-full bg-white px-8 sm:px-36 text-[16px]">
            <Typography
                variant="3XL"
                as="h3"
                className="text-center text-gray-700 m-0 p-0">
                {t('madeChanges')}
            </Typography>

            <Typography
                as="div"
                className="sm:text-[18px] text-left text-gray-700 mt-9">
                {t('downloadLatest')}
            </Typography>

            <a
                className="btn-tertiary-large  w-full mt-10 text-center"
                href={appPage}>
                {t('goToAppStore')}
            </a>

            <div className="fixed left-0 bottom-0 z-[2001] w-[70px] h-[70px] bg-white" />
        </div>
    );
};

export default InvalidVersion;

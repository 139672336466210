import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import LogoIcon from '@icons/logo-icon.svg';
import BackIcon from '@icons/back-icon.svg';
import { Button, Input, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { EPaths } from '@v2/constants/EPaths';
import * as Intercom from '../../third-party/Intercom';

import { forgotPassword } from './actions/forgotPasswordActions';

export default function ForgotPasswordContainer(): JSX.Element {
    const [accountEmail, setAccountEmail] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const [wasPasswordReseted, setPasswordReseted] = useState(false);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'SignIn' });

    const dispatch = useDispatch();

    useEffect(() => {
        Intercom.initLoggedOutUser();
    }, []);

    const submitForgotPassword = async (): Promise<void> => {
        setLoading(true);

        const result = await dispatch(forgotPassword(accountEmail));

        if (result) {
            setPasswordReseted(true);
        }

        setLoading(false);
    };

    const onChangeText = (event): void => {
        const text = event.target.value;
        setAccountEmail(text);
    };

    return (
        <>
            <div className="bg-plum-500 w-full h-full absolute" />
            <div className="bg-gray-700 opacity-75 w-full h-full absolute" />
            <div className="w-full h-full flex items-center px-5 z-10">
                <div className="bg-white rounded-xl sm:p-10 sm:pt-5 p-6 pt-0 m-auto flex flex-col w-full sm:max-w-[430px] items-center z-10">
                    <LogoIcon className="h-[18px] w-[69px] relative sm:-top-[118px] -top-[60px] text-white" />
                    {!wasPasswordReseted
                        ? <div className="flex flex-col items-stretch w-full">
                            <Button variant="TEXT" size="NO_BACKGROUND" className="pl-0" onClick={(): void => dispatch(push(EPaths.SIGN_IN))}>
                                <div className="flex flex-row">
                                    <div className="text-gray-700 pb-2"><BackIcon className="w-4 h-4" /></div>
                                </div>
                            </Button>
                            <div className="flex flex-col justify-between">
                                <Typography variant="3XL" desktopSize="m-0 text-7 pb-1">{t('resetPassword')}</Typography>
                                <Typography className="text-gray-600 m-0 pb-8">{t('sendEmail')}</Typography>
                            </div>
                            <div>
                                <Input label="Email address"
                                    type="EMAIL"
                                    value={accountEmail}
                                    required={true}
                                    className="pb-[86px] sm:pb-[103px]"
                                    onChange={onChangeText} />
                            </div>
                            <div>
                                <Button variant="SECONDARY"
                                    className="w-full"
                                    loading={isLoading}
                                    disabled={!accountEmail || isLoading}
                                    onClick={submitForgotPassword}
                                    isDarkLoader
                                    type="submit">{t('submit')}</Button>
                            </div>
                        </div> : <div className="flex flex-col items-stretch w-full">
                            <Button variant="TEXT" size="NO_BACKGROUND" className="pl-0" onClick={(): void => { setPasswordReseted(false); }}>
                                <div className="flex flex-row">
                                    <div className="text-gray-700 pb-2"><BackIcon className="w-4 h-4" /></div>
                                </div>
                            </Button>
                            <div className="flex flex-col justify-between">
                                <Typography variant="2XL" desktopSize="sm:text-7" className="p-0 mb-1" weight="font-bold" >{t('checkEmail')}</Typography>
                                <Typography className="text-gray-600 m-0 pb-8">{t('sentEmail', { accountEmail })}</Typography>
                            </div>
                            <Typography className="text-gray-600 m-0 sm:mt-[84px] mt-6 mb-5">{t('dontSeeEmail')}</Typography>
                            <div>
                                <Button variant="SECONDARY"
                                    className="w-full mb-3"
                                    loading={isLoading}
                                    disabled={!accountEmail || isLoading}
                                    onClick={submitForgotPassword}
                                    isDarkLoader
                                    type="submit">{t('resendEmail')}</Button>
                            </div>
                            <div>
                                <Button variant="TERTIARY_FILLED"
                                    className="w-full"
                                    onClick={(): void => dispatch(push(EPaths.SIGN_IN))}
                                    isDarkLoader
                                    type="submit">{t('backToSignIn')}</Button>
                            </div>
                        </div> }

                </div>
            </div>
        </>
    );
}

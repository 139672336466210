/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { OrderData } from '@v2/types/OrderData';
import { useCheckoutStore } from '@v2/stores/CheckoutStore';
import { Button, Typography } from '@notch-ordering/ui-components';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { PRIVACY_POLICY, TERMS_OF_USE } from '@v2/constants/ExternalUrls';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { CHECKOUT_STEPS } from '@/constants/Checkout';
import { getEditOrderProductLoading } from '@/selectors/ordersSelectors';

type Props = {
    order: OrderData,
    currentStep: any,
    nextStep: () => any,
    isEditingAddonOrder: any,
    singleOrderBeforeChange: any,
    isEditingOrderDeliveryDay: any,
    checkoutActions: any,
    orderActions: any,
    vendor: any,
};

/**
 * This is legacy code moved from Summary.jsx to allow for proper styling
 */
export const ContinueButton: React.FC<Props> = ({ order, currentStep, nextStep, isEditingAddonOrder, singleOrderBeforeChange, isEditingOrderDeliveryDay, checkoutActions, orderActions, vendor }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Checkout.ContinueButton' });
    const { deliveryInstructions } = useCheckoutStore();
    const editOrderProductLoading = useSelector(getEditOrderProductLoading);

    const isOriginalOrderChanged = (order.items || []).some(
        (itemInEditedOrder) => {
            const itemOriginalOrder = singleOrderBeforeChange.items.find(
                (item) => item.urlsafe === itemInEditedOrder.urlsafe
            ) || {};
            return !(!_.isEmpty(itemOriginalOrder)
                && itemInEditedOrder.quantity === itemOriginalOrder.quantity);
        }
    );

    let actionButtonText = t('continue');
    if (isEditingAddonOrder) {
        actionButtonText = t('confirmAndSave');
    } else if (currentStep === CHECKOUT_STEPS.INFO && !order.isBYOS) {
        actionButtonText = t('placeOrder');
    }
    if (
        currentStep === CHECKOUT_STEPS.INFO
    ) {
        actionButtonText = t('placeOrder');
    }
    const isEditDisabled = !order.isEditingAddonOrder && editOrderProductLoading;
    const isEditAndNothingHasUpdated = order.isEditingAddonOrder && !isOriginalOrderChanged;
    const isInfoStep = currentStep === CHECKOUT_STEPS.INFO;
    const orderHasNoDeliveryDay = !order.deliveryDay;
    const isInfoStepAndOrderHasNoDeliveryDay = isInfoStep && orderHasNoDeliveryDay;
    const vendorHasNoDeliveryDays = isInfoStep && vendor.region.nextAvailableDeliveryDays.length === 0;
    const isButtonDisabled = isEditDisabled || isEditAndNothingHasUpdated || isInfoStepAndOrderHasNoDeliveryDay || isEditingOrderDeliveryDay || vendorHasNoDeliveryDays;

    return <>
        <Button
            className="w-full py-3"
            variant="SECONDARY"
            size="MEDIUM"
            onClick={(): void => {
                document.querySelector('.container-checkout').scrollTo(0, 0);
                if (order.isEditingAddonOrder) {
                    checkoutActions.saveChangesToAddOnOrder(order.urlsafe);
                    orderActions.receiveSingleOrders(order);
                } else if (window.location.href.includes('/pending/')) {
                    checkoutActions.exitCheckout();
                } else if (
                    currentStep === CHECKOUT_STEPS.INFO
                ) {
                    checkoutActions.changeCheckoutView(
                        'pendingView',
                        '',
                        () => {
                            window.location.href = `/checkout/${order.urlsafe}/confirmation`;
                        },
                        deliveryInstructions
                    );
                } else {
                    nextStep();
                }
                if (currentStep === CHECKOUT_STEPS.INFO) {
                    sendTrackingEvent(TrackingEvents.placeOrderClicked);
                }
            }}
            disabled={isButtonDisabled}>
            <Typography as="div" variant="LG-2" weight="font-medium">{actionButtonText}</Typography>
        </Button>
        <div className="flex flex-row justify-center py-6">
            <Typography as="span">
                            By continuing, you agree to our{' '}
                <a
                    href={PRIVACY_POLICY}
                    rel="noopener noreferrer"
                    target="_blank">
                            privacy policy
                </a>{' '}
                          and{' '}
                <a
                    href={TERMS_OF_USE}
                    rel="noopener noreferrer"
                    target="_blank">
                            terms of use
                </a>
                          .</Typography>
        </div>
    </>;
};

import { Button, Modal, onMobileSetLarge, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import Protected from '@images/protected.svg';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';

type FirstUploadProps = {
    isOpen: boolean,
    close: () => void,
};

export const FirstUploadModal: React.FC<FirstUploadProps> = ({
    isOpen = false,
    close
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Uploads' });
    const isMobileWidth: boolean = useIsMobileWidth();
    const textSize = isMobileWidth ? 'XL' : '3XL';

    return (
        <Modal
            hideHeader
            modalSize={onMobileSetLarge('MEDIUM')}
            hideCloseButton
            desktopModalWidth="lg:w-[750px]"
            isOpen={isOpen}
            close={close}>
            <div className="flex flex-col justify-center items-center px-4">
                <div className="flex flex-col gap-y-0.5 mt-[77px] justify-center items-center max-w-[448px]">
                    <Protected className="flex w-[151px] h-[92px]"/>
                    <Typography variant={textSize} className="mt-4 text-center">{t('thatsAll')}</Typography>
                    <Typography className="text-gray-600 text-center mb-10">{t('uploadedFirstBill')}</Typography>
                </div>
                <Button className="w-full lg:mb-[53px] lg:w-auto"
                    size={'MEDIUM'}
                    variant={'SECONDARY'}
                    onClick={close}>{tCommon('Buttons.finish')}</Button>
            </div>
        </Modal>);
};

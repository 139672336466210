import React, { useState } from 'react';
import AttachmentIcon from '@icons/file-attachment-icon.svg';
import { AttachmentThumbnail } from '@v2/Pages/Orders/AttachmentThumbnail';
import { Badge, Button } from '@notch-ordering/ui-components';
import { InvoiceData, getInvoicePDF } from '@v2/network/GreevilsGreedApi';
import useBuyerHook from '@v2/hooks/useBuyer.hook';

type ImageUploadProps = {
    attachments: {
        image_serving_url: string,
        image_key:string,
        image_url: string, }[],
    generatedInvoice: InvoiceData,
};

const ImageUpload: React.FC<ImageUploadProps> = ({ attachments, generatedInvoice, }):JSX.Element => {
    const [isDownloading, setIsDownloading] = useState(false);
    const { buyer } = useBuyerHook();
    const hyperLinkWidth = 'w-[77px]';
    const hyperLinkHeight = 'h-[77px]';
    const imageSize = 77;

    return (

        <div>
            <div className="w-full flex flex-row flex-wrap gap-4">
                {generatedInvoice && <div className="mt-2">
                    <Button variant="TERTIARY_OUTLINED"
                        size="NO_BACKGROUND"
                        borderColor="border-gray-300"
                        hoverColor="bg-white"
                        className="w-[77px] h-[77px]"
                        loading={isDownloading}
                        isDarkLoader

                        onClick={(): void => {
                            setIsDownloading(true);
                            getInvoicePDF(generatedInvoice?.id, buyer.urlsafe)
                                .finally(() => setIsDownloading(false));
                        }}>
                        <div className="flex flex-col gap-y-1.5 items-center">
                            <AttachmentIcon className="w-4 h-4" />
                            <Badge size="SMALL" widthPadding="px-1" backgroundColor="bg-red-200">PDF</Badge>
                        </div>
                    </Button>
                </div>}
                {attachments?.map((image) => (
                    <div key={image.image_key} className="flex-row flex-wrap gap-y-1">
                        <a className={`mt-2 flex flex-row flex-wrap justify-center items-center bg-transparent ${hyperLinkWidth} ${hyperLinkHeight}`} href={image.image_url}>
                            <AttachmentThumbnail
                                pdfHeight="h-[77px]"
                                pdfWidth="w-[77px]"
                                smallThumbnail={false}
                                imageSize={imageSize}
                                url={image.image_serving_url}/>
                        </a>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default ImageUpload;

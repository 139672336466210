import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import * as checkoutSelectors from '../../../../../selectors/checkoutSelectors';
import * as checkoutHelpers from '../../../../../helpers/checkoutHelpers';
import Utils from '../../../../../utils';
import { SupplierStatus } from '../../../../../constants/SupplierStatus';

import PreOrderDialogue from '../../../step1/PreOrderDialogue.jsx';
import ListItemCheckoutText from '../../../step1/ListItemCheckoutText.jsx';
import ListItemCheckoutDatePicker from '../../../step1/ListItemCheckoutDatePicker.jsx';
import { Typography } from '@notch-ordering/ui-components';

class OrderCheckoutBodyDelivery extends Component {
  componentDidMount() {
    const {
      isDisabledDayAlreadyChosen,
      vendor,
      order,
      findSelectedDeliveryDayOptionIndex,
    } = this.props;

    // When the component mounts we need to check if the deliveryDay was already
    // chosen for a disabled day. If it is, then we need to reset the deliveryDay
    if (isDisabledDayAlreadyChosen) {
      this.resetDeliveryDay();
    } else {
      // Find the selected delivery day index based on the set deliveryDay previously
      // and set it as the selected index in the order
      findSelectedDeliveryDayOptionIndex(order, vendor);
    }
  }

  resetDeliveryDay = () => {
    const { changeSelectedDeliveryDayOptionIndex, order } = this.props;

    // Make absolutely sure we do not reset an pending order
    if (order.isInShoppingCart) {
      changeSelectedDeliveryDayOptionIndex(order.urlsafe, -1);
    }
  };

  // Method for when you selected a hard coded date
  selectHardCodedDate = (selectedDeliveryDayOptionIndex) => {
    // Save the new index so we can highlight the item
    const { changeSelectedDeliveryDayOptionIndex, vendor, order } = this.props;
    const deliveryDay = Utils.formatDate(
      vendor.region.nextAvailableDeliveryDays[selectedDeliveryDayOptionIndex]
        .date
    );

    changeSelectedDeliveryDayOptionIndex(
      order.urlsafe,
      selectedDeliveryDayOptionIndex
    );
    this.onSaveDeliveryDay(deliveryDay);
  };

  selectCustomOption = () => {
    const { changeSelectedDeliveryDayOptionIndex, order } = this.props;

    // Change the index to 3 for custom date
    changeSelectedDeliveryDayOptionIndex(order.urlsafe, 3);
  };

  // Method for when you selected a custom date
  selectCustomDate = (customDate) => {
    const deliveryDay = Utils.formatDate(customDate);
    this.onSaveDeliveryDay(deliveryDay);
  };

  onSaveDeliveryDay = (deliveryDay) => {
    const { order, onSaveDeliveryDay } = this.props;

    onSaveDeliveryDay(order.urlsafe, deliveryDay);
  };

  render() {
    const { order, vendor, isPendingOrders } = this.props;
    const deliveryDaySize = 3;
    const minDate = moment(vendor.region.nextAvailableDeliveryDays[0]?.date);

    const selectedCutoffDate = Utils.formatDate(
      vendor.region.nextAvailableDeliveryDays.find(
        (availableDay) => availableDay.date === order.deliveryDay
      )?.cutoffDate,
      'dddd, MMMM D'
    );

    const selectedCutoffTime = vendor.region.cutOffTime;

    const textColor = '#38087B';
    const backgroundColor = '#F5ECFC';

    if (vendor.region.nextAvailableDeliveryDays.length === 0) {
      return 'Ordering unavailable';
    }

    return (
      <div className="order-checkout-body-delivery">
        <div className="order-checkout-delivery-subheader">
          <Typography variant='LG-2' className="instructions">
            Pick your preferred delivery date for this supplier
          </Typography>
          {vendor.customer.vendorStatus === SupplierStatus.PENDING && (
            <div className="pending-vendor-label pull-right">
              1st ORDER - 2 Day Lead Time
              <span className="info-image tip-bottom">
                <span className="tip-text">
                  Your first order with a new supplier will require 2 business
                  days of lead time to make sure your business is set up just
                  right for a sublime delivery experience. Subsequent orders
                  will not require this lead time.
                </span>
              </span>
            </div>
          )}
        </div>
        <div className="delivery-day-items">
          {/*
           *** Props for ListItems (that required explanation)
           *** editable is true if we are on the last item of the deliveryDayOptions
           *** disabled is true if it's after cutoffTime and the index 0 or 1 (i.e Today or Tomorrow)
           *** daysToFilterOut: Array of days in numbers that we need to filter out.
           */}
          {vendor.region.nextAvailableDeliveryDays
            .slice(0, deliveryDaySize)
            .map((option, index, self) => (
              <ListItemCheckoutText
                key={option.date}
                index={index}
                item={option}
                itemSelected={self[order.selectedDeliveryDayOptionIndex]}
                className="delivery-day-item col-md-3 col-lg-3 col-sm-3 col-xs-3"
                onClick={(index) => this.selectHardCodedDate(index)}
                isPendingOrders={isPendingOrders}
                {...this.props}
              />
            ))}
          <ListItemCheckoutDatePicker
            item={{ date: 'CUSTOM' }}
            itemSelected={
              order.selectedDeliveryDayOptionIndex === 3
                ? { date: 'CUSTOM' }
                : null
            }
            itemSelectedStyle={{
              backgroundColor,
              color: textColor,
              border: '1px solid ' + textColor,
              borderBottom: '4px solid ' + textColor,
            }}
            orderDeliveryDay={order.deliveryDay}
            className="delivery-day-item col-md-3 col-lg-3 col-sm-3 col-xs-3"
            minDate={minDate}
            selectCustomOption={this.selectCustomOption}
            selectCustomDate={this.selectCustomDate}
            daysToFilterOut={checkoutHelpers
              .getVendorClosedDays(vendor)
              .map((day) => day.dayOfWeekNumber)}
          />
        </div>

        <div className="cutoff-date">
          <i>
            <Typography as='span' weight='font-semibold'>Cutoff time:</Typography>
            <Typography as='span'>{` ${selectedCutoffDate} at ${selectedCutoffTime}`}</Typography>
          </i>
          <span className="info-image tip-bottom">
            <span className="tip-text">
              You have until this date and time to make any changes to your
              order.
            </span>
          </span>
        </div>

        {order.preOrderRestricted ? <PreOrderDialogue {...this.props} /> : ''}
      </div>
    );
  }
}

OrderCheckoutBodyDelivery.propTypes = {
  order: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  isPendingOrders: PropTypes.bool.isRequired,
  isDisabledDayAlreadyChosen: PropTypes.bool.isRequired,

  onSaveDeliveryDay: PropTypes.func.isRequired,
  changeSelectedDeliveryDayOptionIndex: PropTypes.func.isRequired,
  findSelectedDeliveryDayOptionIndex: PropTypes.func.isRequired,
};

function mapStatetoProps(state, props) {
  return {
    isDisabledDayAlreadyChosen:
      checkoutSelectors.checkIfDisabledDayWasAlreadyChosen(props),
  };
}

export default connect(mapStatetoProps, undefined)(OrderCheckoutBodyDelivery);

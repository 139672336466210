import React from 'react';
import { Button, Input, Modal, onMobileSetLarge, toast } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { useMutation } from '@tanstack/react-query';
import { createOfflineSupplier } from '@v2/network/CoreAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import CheckIcon from '@icons/check-icon.svg';
import { isInvalidEmail } from '@v2/utils/FormValidation';

export interface AddBillSupplierModalProps {
    isOpen: boolean,
    closeModal: (newSupplierUrlsafeKey?:string) => void,
}

export const AddBillSupplierModal : React.FC<AddBillSupplierModalProps> = ({
    closeModal,
    isOpen
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [formSubmitted, setFormSubmitted] = React.useState<boolean>(false);
    const [supplierName, setSupplierName] = React.useState<string>('');
    const [supplierEmail, setSupplierEmail] = React.useState<string>('');
    const isInvalidEmailValue = isInvalidEmail(supplierEmail);
    const isValidForm = supplierName !== '' && !isInvalidEmailValue;
    const createOfflineSupplierMutation = useMutation(createOfflineSupplier);
    const { buyer } = useBuyerHook();

    const cleanUp = (): void => {
        setSupplierName('');
        setFormSubmitted(false);
        setIsLoading(false);
    };

    const onCloseModal = async (newSupplierUrlsafeKey?: string): Promise<void> => {
        await closeModal(newSupplierUrlsafeKey);
        cleanUp();
    };

    const handleSubmitModal = (e):void => {
        e.preventDefault();
        setFormSubmitted(true);
        if (isValidForm) {
            setIsLoading(true);
            createOfflineSupplierMutation.mutate({
                supplierName,
                supplierEmail,
                buyerUrlsafeKey: buyer.urlsafe
            }, {
                onSuccess: async (data) => {
                    await onCloseModal(data.supplierUrlsafeKey);
                    toast.show({
                        message: tCommon('Toasts.success.create'),
                        icon: <CheckIcon />,
                        showClose: false
                    });
                },
                onError: () => {
                    setIsLoading(false);
                }
            });
        }
    };

    return <Modal
        isOpen={isOpen}
        modalSize={onMobileSetLarge('SMALL', true)}
        titleSeparatorDesktop
        titleSeparatorMobile
        close={onCloseModal}
        title={t('createANewSupplier')}>
        <form onSubmit={handleSubmitModal} >
            <div className="mx-4 mt-5 lg:mx-5 mb-5">
                <Input invalidMessage={tCommon('Errors.invalidName')}
                    isInvalid={formSubmitted && supplierName === ''}
                    value={supplierName}
                    onChange={(e):void => setSupplierName(e.target.value)}
                    label={tCommon('Labels.name')}
                    inputProps={{
                        tabIndex: 0,
                        autoFocus: true,
                    }}
                    required />
            </div>
            <div className="mx-4 mt-5 lg:mx-5 mb-5">
                <Input invalidMessage={tCommon('Errors.invalidEmail')}
                    isInvalid={formSubmitted && (supplierEmail === '' || isInvalidEmailValue)}
                    type={'EMAIL'}
                    value={supplierEmail}
                    onChange={(e):void => setSupplierEmail(e.target.value)}
                    label={tCommon('Labels.emailAddress')}
                    helperText={t('inCaseYouWantToPay')}
                    required />
            </div>
            <div className="sticky bottom-0 border-t border-gray-200">
                <div className="mx-4 lg:mx-5 py-5 bg-white">
                    <div className="flex gap-2 justify-end ">
                        <Button variant="TERTIARY_FILLED"
                            size="MEDIUM"
                            as="div"
                            className="cursor-pointer"
                            onClick={onCloseModal}>{tCommon('Buttons.cancel')}</Button>
                        <Button variant="SECONDARY"
                            size="MEDIUM"
                            disabled={isLoading}
                            loading={isLoading}>{tCommon('Buttons.create')}</Button>
                    </div>
                </div>
            </div>
        </form>
    </Modal>;
};

import React from 'react';
import { useLegacySuppliers } from '@v2/hooks/useLegacySuppliers.hook';
import { useUploadsStore } from '@v2/stores/UploadsStore';
import { UploadFilterBadge } from '@v2/components/Uploads/UploadFilters/UploadFilterBadge';
import { tCommon } from '@v2/i18n';
import cx from 'classnames';

export const UploadsFilterBadges = ():JSX.Element => {
    const { searchParams } = useUploadsStore();
    const { legacySuppliers = [] } = useLegacySuppliers();
    const hasSupplierFilterSelected = !!searchParams?.supplierUrlsafeKey?.length;
    // this flag is in case we include more filters in the future
    const hasFiltersSelected = hasSupplierFilterSelected;
    return <div className={cx('w-full flex gap-2 flex-wrap', { 'h-6': !hasFiltersSelected })}>
        {hasSupplierFilterSelected
            && <UploadFilterBadge filterType={'supplierUrlsafeKey'} label={tCommon('Filters.supplier', { count: searchParams?.supplierUrlsafeKey?.length })}>
                {searchParams?.supplierUrlsafeKey?.map((supplierUrlsafeKey) => legacySuppliers.find((vendor) => vendor.urlsafe === supplierUrlsafeKey)?.name).join(', ') }
            </UploadFilterBadge>}
    </div>;
};

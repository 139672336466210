import { GridColDef } from '@notch-ordering/ui-components';
import { ESortBy, ESortDirection } from '@v2/components/Market/MarketHeader/MarketHeaderConstants';
import { t } from '@v2/i18n';

const keyPrefix = 'BulkUpload';

export type CatalogManagementRowData = {
    id: string,
    productID?: string,
    isProduct: boolean,
    name: string,
    size: string,
    sku: string,
    pricePerUnit: string,
    category: string,
    availability: string,
    supplierCatalogID?: string,
};

export const commonColumn: GridColDef = { field: '',
    flex: 1,
    sortable: false,
    hideable: false,
    headerAlign: 'left',
    headerClassName: 'text-gray-600 font-body text-med',
    cellClassName: 'text-gray-700 font-body text-med',
    align: 'left', };

export type CatalogManagementQuery = {
    upload: boolean,
};

export type SortOption = {
    sortMode: ESortBy,
    sortDirection: ESortDirection,
    label: string,
};

export const SortingOptions = [
    {
        label: t(`${keyPrefix}.nameAZ`),
        sortMode: ESortBy.NAME,
        sortDirection: ESortDirection.ASC
    },
    {
        label: t(`${keyPrefix}.nameZA`),
        sortMode: ESortBy.NAME,
        sortDirection: ESortDirection.DESC
    },
    {
        label: t(`${keyPrefix}.priceLowHigh`),
        sortMode: ESortBy.PRICE,
        sortDirection: ESortDirection.ASC,
    },
    {
        label: t(`${keyPrefix}.priceHighLow`),
        sortMode: ESortBy.PRICE,
        sortDirection: ESortDirection.DESC
    },
    {
        label: t(`${keyPrefix}.frequentlyOrdered`),
        sortMode: ESortBy.FREQUENTLY_ORDERED,
        sortDirection: ESortDirection.DESC
    },
    {
        label: t(`${keyPrefix}.recentlyOrdered`),
        sortMode: ESortBy.RECENTLY_ORDERED,
        sortDirection: ESortDirection.DESC
    }];

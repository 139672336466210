import { Button, Input, Modal, Separator, toast, Typography } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { OrderGuidesList } from '@v2/components/Market/OrderGuideList/OrderGuideList';
import { useGetOrderGuides } from '@v2/Pages/Market/MarketQueries.hook';
import { OrderGuideListCreateItem } from '@v2/components/Market/AddProductToOrderGuidesModal/OrderGuideListCreateItem';
import { addProductToOrderGuide, addSavedProduct, createOrderGuide } from '@v2/network/SecretShopAPI';
import CheckIcon from '@icons/check-icon.svg';
import { IFavoriteResponse, OrderGuide } from '@v2/network/types';

export interface Props {
    isOpen: boolean,
    productLabel: string,
    buyerUrlsafe: string,
    selectedProductIDs: string[],
    closeFunction: () => void,
}

export const OrderGuidesModal: React.FC<Props> = ({ isOpen, productLabel, buyerUrlsafe, selectedProductIDs, closeFunction }) => {
    const [isCreateOrderGuideModalOpen, setIsCreateOrderGuideModalOpen] = useState<boolean>(false);
    const [orderGuideNameInput, setOrderGuideNameInput] = useState<string>('');
    const [orderGuidesSelected, setOrderGuidesSelected] = useState<string[]>([]);
    const isSaveButtonDisabled = orderGuidesSelected.length === 0;
    const { t } = useTranslation(tNamespace, { keyPrefix: 'BulkUpload' });

    const { data, refetch: refetchOrderGuides } = useGetOrderGuides(buyerUrlsafe);
    const orderGuides = data?.orderGuides || [];
    const getOrderGuideById = (orderGuideList: OrderGuide[], id: string): OrderGuide | undefined => orderGuideList.find((orderGuide) => orderGuide.id === id);

    const handleOrderGuideSelected = (id: string): void => {
        if (orderGuidesSelected.includes(id)) {
            setOrderGuidesSelected(orderGuidesSelected.filter((item) => item !== id));
        } else {
            setOrderGuidesSelected([...orderGuidesSelected, id]);
        }
    };

    const closeOrderGuideModal = (): void => {
        setOrderGuidesSelected([]);
        closeFunction();
    };

    async function saveProducts(productIDs: string[]): Promise<IFavoriteResponse> {
        return addSavedProduct({
            buyerUrlsafeKey: buyerUrlsafe,
            productIDs,
            showErrorToaster: false
        });
    }

    async function addToOrderGuides(productIDs: string[]): Promise<void> {
        orderGuidesSelected.forEach(async (orderGuideID) => {
            try {
                await addProductToOrderGuide({
                    buyerUrlsafeKey: buyerUrlsafe,
                    orderGuideID,
                    productIDs,
                });
                toast.show({
                    message: `${productLabel} added to ${getOrderGuideById(orderGuides, orderGuideID).name} order guide`,
                    icon: <CheckIcon />
                });
            } catch (e) {
                if (e.error.includes('already added to this order guide')) {
                    const products = selectedProductIDs.length > 1 ? 'One or more selected products' : productLabel;
                    const existence = selectedProductIDs.length > 1 ? 'exist' : 'exists';
                    toast.show({
                        message: `${products} already ${existence} in ${getOrderGuideById(orderGuides, orderGuideID).name} order guide`,
                    });
                } else {
                    toast.show({
                        message: `Failed to add ${productLabel} to ${getOrderGuideById(orderGuides, orderGuideID).name} order guide`,
                    });
                }
            }
        });
    }

    async function handleAddToOrderGuides(): Promise<void> {
        try {
            await saveProducts(selectedProductIDs);
            await addToOrderGuides(selectedProductIDs);
        } catch (e) {
            if (e.error.includes('has already saved product')) {
                // already saved, proceed
                await addToOrderGuides(selectedProductIDs);
                closeOrderGuideModal();
                return;
            }
            toast.show({
                message: 'Failed to save product(s)',
            });
        }
        closeOrderGuideModal();
    }

    async function handleCreateOrderGuide(): Promise<void> {
        try {
            await createOrderGuide({
                buyerUrlsafeKey: buyerUrlsafe,
                name: orderGuideNameInput
            });
            setOrderGuideNameInput('');
            setIsCreateOrderGuideModalOpen(false);
            refetchOrderGuides();
            toast.show({
                message: `Created ${orderGuideNameInput} order guide`,
                icon: <CheckIcon />
            });
        } catch (e) {
            toast.show({
                message: `Error creating ${orderGuideNameInput} order guide`,
            });
        }
    }

    return <>
        <Modal isOpen={isOpen}
            title={t('addToGuides')}
            description={productLabel}
            titleSeparatorDesktop
            close={closeOrderGuideModal}>
            <div className="w-full h-full flex flex-col gap-3 mt-6">
                <div className="flex gap-6 flex-col">
                    <OrderGuidesList isSelectMode orderGuidesSelected={orderGuidesSelected} onSelectOrderGuide={handleOrderGuideSelected} />
                    <div className="pb-3">
                        <OrderGuideListCreateItem onClick={():void => {
                            setIsCreateOrderGuideModalOpen(true);
                        }}/>
                    </div>
                </div>
                <Separator variant="small" />
                <div className={'px-4 pt-2 mt-auto sticky bottom-0'}>
                    <div className="flex items-center justify-between flex-col gap-3">
                        <Button variant="SECONDARY"
                            size="MEDIUM"
                            className={'w-full'}
                            onClick={handleAddToOrderGuides}
                            disabled={isSaveButtonDisabled}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.add')}
                            </Typography>
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
        {/* Create new Order Guide modal */}
        <Modal isOpen={isCreateOrderGuideModalOpen}
            titleSeparatorDesktop
            title={t('createNewGuide')}
            close={(): void => setIsCreateOrderGuideModalOpen(false)}>
            <div className="w-full h-full flex flex-col gap-3 overflow-auto">
                <div className="flex gap-6 flex-col mt-5 mb-2 px-4 lg:px-5">
                    <Input
                        className="mt-0 lg:m-0 w-full"
                        label={tCommon('Labels.name')}
                        type="TEXT"
                        value={orderGuideNameInput}
                        onChange={(e): void => setOrderGuideNameInput(e.target.value)}
                        required />
                </div>
            </div>
            <Separator className="my-4" />
            <div className={'px-4 lg:px-5 mt-auto sticky bottom-0'}>
                <div className="flex items-center justify-between flex-col gap-3">
                    <Button variant="SECONDARY"
                        size="MEDIUM"
                        className="w-full"
                        onClick={handleCreateOrderGuide}
                        disabled={!orderGuideNameInput}>
                        <Typography as={'span'} weight={'font-medium'}>
                            {tCommon('Buttons.create')}
                        </Typography>
                    </Button>
                </div>
            </div>
        </Modal>
    </>;
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as AnalyticsActions from '../../actions/analyticsActions';
import * as OrderActions from '../../actions/orderActions';

import SettingsMain from '../../components/settings/SettingsMain.jsx';

const SettingsContainer = (props) => {
  useEffect(() => {
    const { analyticsActions, orderActions } = props;
    orderActions.loadCartOrders();
    analyticsActions.viewPage('Settings');
  }, []);

  return <SettingsMain {...props} currentSettingsTabView={props.children} />;
};

SettingsContainer.propTypes = {};

function mapStatetoProps(state, ownProps) {
  return {
    ...state.settingsReducer,
    ...state.orderInvoicesReducer,
    pendingOrders: state.ordersReducer.pendingOrders,
    pathname: ownProps.location.pathname,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
    orderActions: bindActionCreators(OrderActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(SettingsContainer);

import React from 'react';
import PropTypes from 'prop-types';

export default function MarketTag(props) {
  const { isOnDarkBackground, containerStyles, textStyles } = props;

  let containerColor = '#3d3d3d';
  let textColor = '#ffffff';

  return (
    <span
      className="market-tag-container"
      style={{ backgroundColor: containerColor, ...containerStyles }}
    >
      <span
        className="market-tag-text"
        style={{ color: textColor, ...textStyles }}
      >
        MARKET
      </span>
    </span>
  );
}

MarketTag.propTypes = {
  isOnDarkBackground: PropTypes.bool,
  containerStyles: PropTypes.objectOf(PropTypes.any),
  textStyles: PropTypes.objectOf(PropTypes.any),
};

MarketTag.defaultProps = {
  isOnDarkBackground: false,
  containerStyles: {},
  textStyles: {},
};

import React from 'react';
import classNames from 'classnames';

const renderField = ({
  id,
  input,
  label,
  placeholder,
  type,
  className,
  disabled,
  meta: { active, touched, error },
  labelClassName
}) => {
  return (
    <div className="input-group">
      <div>
        <input
          id={id}
          className={classNames(className, { 'form-error': error && touched })}
          {...input}
          disabled={disabled}
          type={type}
          value={input.value}
          placeholder={placeholder}
        />
        {disabled && (
          <span className="form-disabled">This field cannot be modified</span>
        )}
        <label className={`field-label ${!labelClassName ? '' : labelClassName}`}>{label}</label>
      </div>
      <div className="redux-form-error">
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export default renderField;

import PropTypes from "prop-types";
import React, { Component } from "react";

import DisplayUnit from "../../DisplayUnit.jsx";
import InlineConfirm from "../../InlineConfirm.jsx";
import EditBox from "../../general/EditBox.jsx";

import Utils from "../../../utils";
import { BUCKET_ASSETS_STATIC_URL } from "@v2/constants/Assets";

export default class OrderItemRow extends Component {
  state = {
    quantity: 0,
  };

  componentDidMount() {
    const { orderItem } = this.props;
    const quantity = orderItem ? orderItem.quantity : 0;

    this.setState({ quantity });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { orderItem } = nextProps;
    const quantity = orderItem ? orderItem.quantity : 0;

    this.setState({ quantity });
  }

  onImageError = (event) => {
    event.target.src = `${BUCKET_ASSETS_STATIC_URL}images/your-item-market-placeholder.png`;
  };

  typedQuantityChange = (event) => {
    const oldQuantity = event.target.value;
    const quantity = Utils.changeQuantityHelper(oldQuantity, "CUSTOM");

    this.setState({ quantity });
  };

  onFocusQuantity = () => {
    // Remove any old quantity value
    this.setState({ quantity: "" });
  };

  onBlurQuantity = () => {
    const { orderItem, order_urlSafe, order, changeQuantity } = this.props;

    const oldQuantity = orderItem ? orderItem.quantity : 0;

    // Don't allow editing a quantity and revert to old quantity if
    // 1) the user leaves the the input box blank
    // 2) the new Quantity is less than old Quantity for editing AddOn Orders (temporaroy)
    if (
      this.state.quantity === "" ||
      (order.isEditingAddOnOrder &&
        this.state.quantity < orderItem.originalQuantity)
    ) {
      // Keep the original quantity if there is no value in the input box
      this.setState({ quantity: oldQuantity });
      return;
    }

    changeQuantity(order_urlSafe, orderItem, this.state.quantity, "CUSTOM");
  };

  onClickEdit = () => {
    const { order_urlSafe, orderItem, onClickEdit } = this.props;

    onClickEdit(order_urlSafe, orderItem);
  };

  onClickSaveNote = (note) => {
    const { order_urlSafe, orderItem, saveNoteCart } = this.props;

    saveNoteCart(order_urlSafe, orderItem, note);
  };

  toggleChangeUnitModalCheckout = () => {
    const { order_urlSafe, orderItem, order, toggleChangeUnitModalCheckout } =
      this.props;

    if (!order.isEditingAddOnOrder) {
      toggleChangeUnitModalCheckout(order_urlSafe, orderItem);
    }
  };

  render() {
    const { order_urlSafe, orderItem, order, changeQuantity } = this.props;
    const name = orderItem.name ? orderItem.name : orderItem.productName;
    const image = orderItem.genericItem
      ? orderItem.genericItem.imageURL
      : Utils.getBackupImage(orderItem.productCode);
    const unit = orderItem.unit ? orderItem.unit : orderItem.unitName;

    // Change the position of the delete button when the editMode is true
    const deleteStyle = orderItem.editMode
      ? { float: "left" }
      : { marginLeft: "10px" }; // need to change this for order guide
    return (
      <tr>
        {!order.isBYOS && (
          <td className="product-image">
            <img
              src={image}
              onError={this.onImageError}
              height="60px"
              style={{ maxWidth: "80px" }}
            />
          </td>
        )}
        <td className="product-col2">
          <div className="name">{name}</div>
          <div className="product-add-note-delete">
            {/* IIFE in JSX */}
            {(() => {
              if (!orderItem.editMode) {
                if (!order.isEditingAddOnOrder) {
                  return (
                    <div>
                      <div className="add-note" onClick={this.onClickEdit}>
                        {orderItem.notes ? "Edit" : "Add"} Note
                      </div>
                      <div>
                        <InlineConfirm
                          confirmedYes={() =>
                            changeQuantity(
                              order_urlSafe,
                              orderItem,
                              0,
                              "CUSTOM"
                            )
                          }
                          confirmText="Delete Item?"
                        >
                          <span className="delete-item" style={deleteStyle}>
                            {" "}
                            Delete{" "}
                          </span>
                        </InlineConfirm>
                      </div>
                    </div>
                  );
                } else if (order.isEditingAddOnOrder && !orderItem.urlsafe) {
                  return (
                    <div>
                      <InlineConfirm
                        confirmedYes={() =>
                          changeQuantity(order_urlSafe, orderItem, 0, "CUSTOM")
                        }
                        confirmText="Delete Item?"
                      >
                        <span className="delete-item" style={deleteStyle}>
                          {" "}
                          Delete{" "}
                        </span>
                      </InlineConfirm>
                    </div>
                  );
                }
              }
            })()}
          </div>
          <div>
            <EditBox
              className="edit-box-description"
              text={orderItem.notes}
              editMode={orderItem.editMode}
              onClickSave={this.onClickSaveNote}
              onClickEdit={this.onClickEdit}
            />
          </div>
        </td>
        {!order.isBYOS && (
          <td>
            <div
              className="product-row-unit"
              style={{ cursor: order.isEditingAddOnOrder ? "auto" : "pointer" }}
              onClick={this.toggleChangeUnitModalCheckout}
              title="Click Here to Change This Unit"
            >
              <DisplayUnit
                unitName={unit}
                unitDescription={orderItem.unitDescription}
              />
              {!order.isEditingAddOnOrder && (
                <i className="fa fa-pencil pull-right" aria-hidden="true"></i>
              )}
            </div>
          </td>
        )}
        <td>
          <div className="input-group quantity-change" style={{ zIndex: 0 }}>
            {order.isEditingAddOnOrder &&
            this.state.quantity <= orderItem.originalQuantity ? (
              <span
                className="input-group-btn"
                title="You cannot remove items after 20 minutes"
              >
                <button
                  type="button"
                  className="btn btn-number minus"
                  style={{ cursor: "not-allowed" }}
                >
                  <span
                    className="glyphicon glyphicon-minus"
                    style={{ color: "#F2F2F2" }}
                  ></span>
                </button>
              </span>
            ) : (
              <span className="input-group-btn">
                <button
                  onClick={() =>
                    changeQuantity(
                      order_urlSafe,
                      orderItem,
                      this.state.quantity,
                      "DECREASE"
                    )
                  }
                  type="button"
                  className="btn btn-number minus"
                  style={{ outline: "0" }}
                >
                  <span className="glyphicon glyphicon-minus"></span>
                </button>
              </span>
            )}
            <input
              type="text"
              name="quantity"
              className="input form-control pull-right input-quantity"
              onChange={this.typedQuantityChange}
              onFocus={this.onFocusQuantity}
              onBlur={this.onBlurQuantity}
              value={this.state.quantity}
            />
            <span className="input-group-btn">
              <button
                onClick={() =>
                  changeQuantity(
                    order_urlSafe,
                    orderItem,
                    this.state.quantity,
                    "INCREASE"
                  )
                }
                type="button"
                className="btn btn-number plus"
                style={{ outline: "0" }}
              >
                <span className="glyphicon glyphicon-plus"></span>
              </button>
            </span>
          </div>
        </td>
        <td className="product-price">
          <div>
            {Utils.formatAsCurrency(orderItem.price)}
            {orderItem.isPreOrder && (
              <div className="pre-order-label">48HR notice</div>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

OrderItemRow.propTypes = {
  orderItem: PropTypes.object.isRequired,
  order_urlSafe: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,

  changeQuantity: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  saveNoteCart: PropTypes.func.isRequired,
  toggleChangeUnitModalCheckout: PropTypes.func.isRequired,
};

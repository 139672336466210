import React from 'react';
import { Typography, Separator } from '@notch-ordering/ui-components';
import { OrderGuideListItem,
    OrderGuideListItemPlaceholder } from '@v2/components/Market/OrderGuideListItem/OrderGuideListItem';
import ListIcon from '@icons/list-icon.svg';
import { useGetOrderGuides } from '@v2/Pages/Market/MarketQueries.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { generatePlaceholdersByCount } from '@v2/utils/Loader';

export interface OrderGuidesListProps {
    isSelectMode?: boolean,
    onSelectOrderGuide?: (orderGuideID: string) => void,
    orderGuidesSelected?: string[],
}

export const OrderGuidesList : React.FC<OrderGuidesListProps> = ({ isSelectMode, onSelectOrderGuide, orderGuidesSelected }) => {
    const { buyer } = useBuyerHook();
    const { data, isLoading } = useGetOrderGuides(buyer.urlsafe);
    const orderGuides = data?.orderGuides || [];

    return (
        <>
            {isLoading && generatePlaceholdersByCount(OrderGuideListItemPlaceholder, 5)}
            {!isLoading && !orderGuides.length && (
                <Typography as="div" className="px-4 lg:px-5 mb-0 lg:w-3/5 text-center mx-auto w-full py-0 flex justify-center items-center text-gray-600">
                            You haven’t created any order guides yet, get started by pressing Create.
                </Typography>
            )}
            {orderGuides.map((orderGuide) => (
                <React.Fragment key={orderGuide.id}>
                    <OrderGuideListItem
                        id={orderGuide.id}
                        showActions
                        label={orderGuide.name}
                        icon={ <ListIcon />}
                        productCount={orderGuide.productCount}
                        showCheckbox={isSelectMode}
                        onClick={():void => onSelectOrderGuide(orderGuide.id)}
                        isSelected={orderGuidesSelected?.includes(orderGuide.id)}/>
                    <Separator/>
                </React.Fragment>
            ))}

        </>
    );
};

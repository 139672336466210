/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* ^ This is to avoid linter errors on old code copied from legacy JSX file */
import React, { useEffect, useState } from 'react';
import 'react-dates/initialize';
import { useDispatch, useSelector } from 'react-redux';

import { Banner, Button, EBannerType, Separator, Typography } from '@notch-ordering/ui-components';
import { useQuery } from '@tanstack/react-query';
import { getSupplier } from '@v2/network/LegacyAPI';
import { useCheckoutStore } from '@v2/stores/CheckoutStore';
import { Buyer } from '@v2/types/Buyer';
import { OrderData } from '@v2/types/OrderData';
import { Supplier } from '@v2/types/Supplier';
import InfoIcon from '@icons/info-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { IonDatetime } from '@ionic/react';
import cx from 'classnames';
import CalendarNoDatesIcon from '@icons/calendar-x-icon.svg';
import { EditSupplierModal } from '@v2/components/Shared/EditSupplierModal/EditSupplierModal';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { getLinkedSupplierCatalogs } from '@v2/network/SecretShopAPI';
import { RootState } from '@/v2';
import { onSaveDeliveryDayFromCheckout } from '@/actions/checkoutActions';
import { updateDeliveryInstructions } from '@/actions/orderActions';
import { REQUEST_SUPPLIER } from '../Shared/SupplierCard/SupplierCardConstants';
import { SupplierInfo } from '../Shared/SupplierInfo/SupplierInfo';
import { loadVendors } from '@/actions/vendorsActions';

type Props = {
    buyer: Buyer,
    order: OrderData,
    vendor: Supplier,
};

export const OrderInformation: React.FC<Props> = ({ buyer, order, vendor }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Checkout' });
    const { account } = useSelector((state: RootState) => state.accountReducer);

    const { deliveryInstructions, setDeliveryInstructions } = useCheckoutStore();
    const dispatch = useDispatch();

    const saveDeliveryDay = (value) => dispatch(onSaveDeliveryDayFromCheckout([order], order.urlsafe, value));

    const supplierUrlsafe = order.vendorUrlsafe;

    const { data: supplier, refetch } = useQuery([REQUEST_SUPPLIER, supplierUrlsafe], () => getSupplier(supplierUrlsafe), {
        enabled: !!(supplierUrlsafe),
        staleTime: 60000,
        retry: 3,
        retryDelay: 10000
    });

    const [controlledByBuyer, setControlledByBuyer] = useState<boolean>(false);

    useQuery(['REQUEST_LINKED_CATALOGS', buyer.urlsafe], () => getLinkedSupplierCatalogs(buyer.urlsafe), {
        enabled: !!(supplierUrlsafe),
        onSuccess: ({ supplierCatalogs }) => {
            const supplierCatalog = supplierCatalogs.find((catalog) => catalog.supplierUrlsafeKey === supplierUrlsafe);
            setControlledByBuyer(supplierCatalog?.canControlSupplierCatalog);
        }
    });

    const { error } = useSelector((state: RootState) => state.checkoutReducer);

    useEffect(() => {
        if (!error) {
            dispatch(
                updateDeliveryInstructions(
                    order.urlsafe,
                    order.deliveryRequests || order.shippingAddress.driveInstructions
                )
            );
        }
    }, []);

    function formatDate(date: string): string {
        const dateParsed = parse(date, 'yyyy-MM-dd', new Date());
        return format(dateParsed, 'MMM. do');
    }

    const availableDeliveryDays = vendor.region.nextAvailableDeliveryDays;
    const nextAvailableDeliveryDay = availableDeliveryDays[0];
    const [chosenDeliveryDate, setChosenDeliveryDate] = useState<string>(nextAvailableDeliveryDay?.date);
    const [isScheduleButtonActive, setIsScheduleButtonActive] = useState<boolean>(false);
    const [isDatePickerHidden, setIsDatePickerHidden] = useState<boolean>(true);
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
    const scheduleButtonLabel = isScheduleButtonActive
        ? <Typography as="div" className="text-gray-700">{t('orderScheduledFor')} <span className="font-medium">{formatDate(chosenDeliveryDate)}</span></Typography>
        : <Typography as="div" weight="font-medium" className="text-gray-700">{t('scheduleATime')}</Typography>;

    useEffect(() => {
        if (!error) {
            saveDeliveryDay(chosenDeliveryDate);
        }
    }, [chosenDeliveryDate, error]);

    // every time the delivery date changes, we need to update the delivery day selected
    useEffect(() => {
        if (vendor?.region?.nextAvailableDeliveryDays.length) {
            setChosenDeliveryDate(vendor?.region?.nextAvailableDeliveryDays[0]?.date);
        }
    }, [vendor?.region?.nextAvailableDeliveryDays]);

    return (
        <div className="m-[30px]">
            <div className="pt-10">
                <Typography as="div" variant="LG-2" weight="font-medium" className="text-gray-700">{t('yourInformation')}</Typography>
                <div className="text-gray-600 pt-4">
                    <Typography as="div" className="pb-1">{buyer.name}</Typography>
                    <Typography as="div" className="pb-1">{`${account.firstName} ${account.lastName}`}</Typography>
                    <Typography as="div" className="pb-1">{account.id}</Typography>
                    <Typography as="div">{account.mobile}</Typography>
                </div>
                <Separator variant="small" className="my-10"/>
            </div>
            <div className="grid grid-cols-2">
                <span className="inline-grid grid-cols-1 justify-items-start">
                    <Typography as="span" variant="LG-2" weight="font-medium" className="text-gray-700">{t('supplierDetails')}</Typography>
                </span>
                <span className="inline-grid grid-cols-1 justify-items-end" hidden={!controlledByBuyer}>
                    <Button as="span"
                        variant="LINK"
                        size="NO_BACKGROUND"
                        className="p-0 cursor-pointer"
                        onClick={() => {
                            setIsEditModalOpen(true);
                        }}>
                        <Typography as="span" variant="LG-2">{tCommon('Buttons.edit')}</Typography>
                    </Button>
                </span>
            </div>
            {!!supplier && <div className="mt-1.5"><SupplierInfo className="mx-0" supplier={supplier} /></div>}
            <Separator variant="small" className="my-10"/>
            <Typography as="div" variant="LG-2" weight="font-medium" className="text-gray-700">{t('preferredDeliveryDate')}</Typography>
            { nextAvailableDeliveryDay
                ? <div>
                    <Banner alertType={EBannerType.SUBDUED} body={t('confirmYourDate')} icon={<InfoIcon className="text-gray-600"/>} className="mt-4"/>
                    <div className="flex flex-col gap-4 mt-4">
                        <Button variant="TERTIARY_OUTLINED"
                            heightPadding="py-6"
                            widthPadding="px-6"
                            selected={!isScheduleButtonActive}
                            onClick={() => {
                                setIsScheduleButtonActive(false);
                                setChosenDeliveryDate(nextAvailableDeliveryDay.date);
                                setIsDatePickerHidden(true);
                            }}>
                            <div className="text-left">
                                <Typography as="div" weight="font-medium" className="text-gray-700 pb-1">{t('ASAP')}</Typography>
                                <Typography as="span" className="text-gray-600 ">{t('getYourOrderOn')}</Typography>
                                <Typography as="span" className="text-gray-700">{formatDate(nextAvailableDeliveryDay.date)} </Typography>
                                <Typography as="span" className="text-gray-600">{t('ifYouOrderBy')}</Typography>
                                <Typography as="span" className="text-gray-700">{formatDate(nextAvailableDeliveryDay.cutoffDate)}, {vendor.region.cutOffTime}.</Typography>
                            </div>
                        </Button>
                        <Button variant="TERTIARY_OUTLINED"
                            heightPadding="py-6"
                            widthPadding="px-6"
                            selected={isScheduleButtonActive}
                            onClick={() => {
                                setIsScheduleButtonActive(true);
                                if (chosenDeliveryDate === nextAvailableDeliveryDay.date && availableDeliveryDays.length >= 2) {
                                    // date picker should not open with the ASAP option selected unless that's the only date available
                                    setChosenDeliveryDate(availableDeliveryDays[1].date);
                                }
                                setIsDatePickerHidden(!isDatePickerHidden);
                            }}>
                            <div className="flex justify-between items-center">
                                {scheduleButtonLabel}
                                <div className="inline-grid grid-cols-1 justify-items-end align-center"><ChevronDownIcon className="w-4 h-4 text-gray-600" /></div>
                            </div>
                        </Button>
                        <div className="flex flex-col items-end">
                            <IonDatetime className={cx('drop-shadow-xl', { hidden: isDatePickerHidden })}
                                value={chosenDeliveryDate}
                                onIonChange={({ detail: { value } }) => {
                                    const dateString = value as string;
                                    setChosenDeliveryDate(dateString);
                                    if (!isDatePickerHidden) {
                                        // only hide the date picker if it's already showing, otherwise it will never show the first time you click the button
                                        setIsDatePickerHidden(true);
                                    }
                                    if (dateString === nextAvailableDeliveryDay.date) {
                                        // if they chose the ASAP option, switch to that button for clarity
                                        setIsScheduleButtonActive(false);
                                    }
                                }}
                                presentation="date"
                                isDateEnabled={(dateString: string): boolean => {
                                    const deliveryDate = availableDeliveryDays.find((value) => value.date === dateString);
                                    return deliveryDate !== undefined;
                                }}/>
                        </div>
                    </div>
                </div>
                : <div className="pt-10">
                    <div className="w-10 h-10 p-2.5 bg-gray-100 rounded-xl"><CalendarNoDatesIcon className="h-5 w-5" /></div>
                    <Typography as="div" weight="font-medium" className="pt-4">{t('noDeliveryTimes')}</Typography>
                    <Typography as="div" className="text-gray-600 pt-2 pb-6">{t('editYourSupplierDetailsToMatch')}</Typography>
                    <Button variant="SECONDARY"
                        size="MEDIUM"
                        hidden={!controlledByBuyer}
                        onClick={() => {
                            setIsEditModalOpen(true);
                        }}>
                        {t('editSupplierDetails')}
                    </Button>
                </div>
            }
            {controlledByBuyer && <EditSupplierModal isOpen={isEditModalOpen}
                supplierUrlsafeKey={supplierUrlsafe}
                onSave={() => {
                    // after closing the modal we need to refresh the supplier details
                    dispatch(loadVendors(false, vendor.urlsafe));
                    refetch();
                }}
                close={() => {
                    setIsEditModalOpen(false);
                }} />}
            <Separator variant="small" className="my-10"/>
            <Typography as="div" variant="LG-2" weight="font-medium" className="text-gray-700">{t('deliveryInstructions')}</Typography>
            <textarea
                className= "resize-none h-20 border border-solid border-gray-400 mt-4 px-4 py-3 w-full text-2 font-normal text-gray-700 bg-white rounded-xl focus-visible:outline-2 focus-visible:outline-gray-700"
                value={deliveryInstructions}
                onChange={(e) => setDeliveryInstructions(e.target.value)}
                placeholder="Add delivery instructions (optional)"
                onBlur={() => {
                    if (deliveryInstructions !== order.deliveryRequests) {
                        dispatch(
                            updateDeliveryInstructions(
                                order.urlsafe,
                                deliveryInstructions
                            )
                        );
                    }
                }}/>
        </div>
    );
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ErrorMessage from '../../ErrorMessage.jsx';

export default class DeleteMember extends Component {
  render() {
    const { error, errorMessage, buyer, onClickCancel, onClickDelete } =
      this.props;

    return (
      <div className="delete-member-modal">
        <div>
          <p style={{ fontSize: '22px' }}>Remove Team Members</p>
          <p>
            Are you sure you want to remove these team members from {buyer.name}
            ?
          </p>
        </div>
        <ErrorMessage error={error} errorMessage={errorMessage} />
        <div className="footer">
          <button
            className="btn btn-white btn-lg pull-left"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary btn-lg pull-right"
            onClick={onClickDelete}
          >
            Yes, Remove Team Members
          </button>
        </div>
      </div>
    );
  }
}

DeleteMember.propTypes = {
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.array.isRequired,
  buyer: PropTypes.object.isRequired,

  onClickCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

import React from 'react';

type Props = {
    accountingIcon: React.ReactNode,
    erpIcon: React.ReactNode,
    emptyIcon: React.ReactNode,
    isAccountingSynced: boolean,
    isImsSynced: boolean,
};

export const UploadSyncStatus : React.FC<Props> = ({ isAccountingSynced, isImsSynced, accountingIcon, erpIcon, emptyIcon }) => {
    const firstIcon = isAccountingSynced ? (accountingIcon ?? emptyIcon) : emptyIcon;
    const secondIcon = isImsSynced ? (erpIcon ?? emptyIcon) : emptyIcon;

    return <div className="flex flex-row gap-2.5">
        {firstIcon}
        {secondIcon}
    </div>;
};

import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import CustomDropDown from "./CustomDropDown.jsx";
import ReceivingToolWebFooter from "./ReceivingToolWebFooter.jsx";
import utils from "../../../utils";
import ItemPriceEdit from "./item-edit/ItemPriceEdit.jsx";
import orderItemType from "../types/orderItemType.js";
import ItemWeightEdit from "./item-edit/ItemWeightEdit.jsx";
import ItemQuantityEdit from "./item-edit/ItemQuantityEdit.jsx";
import ItemOriginals from "./item-view/ItemOriginals.jsx";
import { isEqual } from "lodash";
import { customStyles } from "@/constants/CustomStyles";
import {
  BUCKET_ASSETS_STATIC_URL,
  BUCKET_ASSETS_URL,
} from "@v2/constants/Assets";

const ReceivingToolWebEdit = ({
  orderItem,
  visible,
  updateOrder,
  isPackage,
  closeModal,
  items,
  closeWebView,
}) => {
  const [orderItemCopy, updateOrderItemCopy] = useState(() =>
    JSON.parse(JSON.stringify(orderItem))
  );
  const [editPrice, toggleEditPrice] = useState(false);

  const handlePriceForPackaging = ({ target: { value } }) => {
    const cleanedValue = Number(value ? Number(value).toFixed(2) : 0);

    updateOrderItemCopy((state) => ({
      ...state,
      packagingPrice: cleanedValue,
      total: cleanedValue * state.packagingQuantity,
    }));
  };

  const handlePrice = ({ target: { value } }) => {
    const cleanedValue = Number(value ? Number(value).toFixed(2) : 0);

    updateOrderItemCopy((state) => ({
      ...state,
      unitPrice: cleanedValue,
      total: cleanedValue * state.unitQuantity,
    }));
  };

  const handleQuantity = (value) => {
    updateOrderItemCopy((state) => {
      const data = {
        ...state,
        packagingQuantity: value,
      };

      if (isPackage) {
        data.total = value * state.packagingPrice;
      } else {
        data.unitQuantity = utils.roundOff2(state.originalUnitQuantity) * value;
        data.total = data.unitQuantity * state.unitPrice;
      }

      return data;
    });
  };

  const handleReason = (value) => {
    updateOrderItemCopy((state) => ({
      ...state,
      receivalIssueDescription: "",
      receivalIssueChange: value,
    }));
  };

  const handleReasonDescription = ({ target: { value } }) => {
    updateOrderItemCopy((state) => ({
      ...state,
      receivalIssueDescription: value,
    }));
  };

  const handleWeight = ({ target: { value } }) => {
    const cleanedValue = Number(value ? Number(value).toFixed(3) : 0);
    updateOrderItemCopy((state) => ({
      ...state,
      total: cleanedValue * state.unitPrice,
      unitQuantity: cleanedValue,
    }));
  };

  const handleVariant = (variant) => {
    if (orderItemCopy.sourceUrlsafe !== variant.urlsafe) {
      const newProduct = {
        imageUrl: variant.genericItem.imageURL,
        name: variant.productName,
        productCode: variant.productCode,
        unitName: variant.unit,
        unitDescription: variant.unitDescription,
        packagingDescription: variant.unitDescription,
        salesUnit: variant.salesUnit.name,
        externalId: variant.variantExternalCode,
        packagingQuantity: 1,
        packagingPrice: variant.price,
        unitQuantity: isPackage ? null : utils.roundOff2(variant.salesQuantity),
        originalUnitQuantity: isPackage
          ? null
          : variant.price / variant.pricePerStandardUnit,
        unitPrice: variant.pricePerStandardUnit,
        total: isPackage
          ? variant.price
          : variant.pricePerStandardUnit * variant.salesQuantity,
        productId: variant.productCode,
        sourceId: variant.urlsafe,
        isNewPackage: true,
      };

      updateOrderItemCopy(() => newProduct);
    }
  };

  const [editWeight, toggleEditWeight] = useState(false);
  const editWeightRef = useRef(null);
  useEffect(() => {
    if (editWeight && editWeightRef.current) {
      editWeightRef.current.focus();
    }
  }, [editWeight]);

  const reasons = [
    "Short",
    "Poor quality",
    "Item substituted",
    "Incorrect items",
    "Other",
  ];

  if (visible) {
    // Disable Scroll
    $("body").css("overflow", "hidden");
  } else {
    // Enable Scroll
    $("body").css("overflow", "auto");
  }

  const saveAdjustment = () => {
    if (orderItemCopy.receivalIssueChange) {
      if (!isEqual(orderItemCopy, orderItem)) {
        let isPriceUpdated = false;
        if (isPackage) {
          isPriceUpdated =
            Number(orderItemCopy.packagingPrice) !==
            Number(orderItem.packagingPrice);
        } else {
          isPriceUpdated =
            Number(orderItemCopy.unitPrice) !== Number(orderItem.unitPrice);
        }

        const updatedOrderItem = {
          ...orderItemCopy,
          isWeightUpdated:
            orderItemCopy.unitQuantity !== orderItem.unitQuantity,
          isPriceUpdated,
          isQuantityUpdated:
            orderItemCopy.packagingQuantity !== orderItem.packagingQuantity,
          isReasonUpdated:
            orderItemCopy.receivalIssueChange !==
              orderItem.receivalIssueChange ||
            orderItemCopy.receivalIssueDescription !==
              orderItem.receivalIssueDescription,
        };
        const hasOlderVariants = items.find(
          (item) => item.name === orderItemCopy.name && item.isOldPackage
        );

        const list =
          orderItemCopy.isNewPackage && hasOlderVariants
            ? items.filter(
                (item) => orderItemCopy.isNewPackage && !item.isLocal
              )
            : items;
        const newItems = list.reduce((acc, item) => {
          if (orderItem.sourceUrlsafe === item.sourceUrlsafe) {
            if (orderItemCopy.sourceUrlsafe !== orderItem.sourceUrlsafe) {
              if (!hasOlderVariants) {
                acc.push({
                  ...JSON.parse(JSON.stringify(item)),
                  isOldPackage: true,
                  isPackageUpdated: true,
                });
                acc.push({ ...updatedOrderItem, isNew: true });
              } else {
                acc.push({
                  ...JSON.parse(JSON.stringify(orderItem)),
                  isOldPackage: true,
                  isDelete: true,
                  isPackageUpdated: true,
                });
                acc.push({ ...updatedOrderItem, isNew: true, isLocal: true });
              }
            } else {
              acc.push({ ...updatedOrderItem });
            }
            return acc;
          }
          acc.push(item);
          return acc;
        }, []);
        updateOrder(newItems);
      }
      closeModal();
    }
  };

  return (
    <Modal isOpen={visible} style={customStyles} ariaHideApp={false}>
      <div className="od-edit-web-body">
        <div className="edit-header">
          <span className="od-title">
            <button onClick={closeModal}>
              <img
                src={`${BUCKET_ASSETS_URL}caret-left-icon.svg`}
                alt="Back Icon"
              />
            </button>
            Edit item
          </span>

          <button onClick={closeWebView}>
            <img
              src="https://storage.googleapis.com/chefhero-storage-release/static/fe-supplier/images/black-close-icon.svg"
              alt="Close Icon"
            />
          </button>
        </div>

        <div className="edit-body">
          <div className="edit-product-image">
            <img
              src={
                orderItem.imageUrl
                  ? orderItem.imageUrl
                  : `${BUCKET_ASSETS_STATIC_URL}images/your-item-market-placeholder.png`
              }
              alt={name}
            />
          </div>

          <div className="edit-product-body">
            <div className="item">
              <span>{orderItem.name}</span>
            </div>

            <CustomDropDown
              label="Packaging"
              orderItem={orderItemCopy}
              handleVariant={handleVariant}
            />

            <div className="od-dropdown">
              <ItemPriceEdit
                orderItem={orderItemCopy}
                handlePrice={handlePrice}
                handlePriceForPackaging={handlePriceForPackaging}
                editPrice={editPrice}
                toggleEditPrice={toggleEditPrice}
                isPackage={isPackage}
              />

              {/** Weight Update */}
              {!isPackage && (
                <ItemWeightEdit
                  orderItem={orderItemCopy}
                  handleWeight={handleWeight}
                  editWeight={editWeight}
                  toggleEditWeight={toggleEditWeight}
                  isPackage={isPackage}
                />
              )}
            </div>

            <ItemOriginals
              isPackage={isPackage}
              orderItem={orderItem}
              orderItemCopy={orderItemCopy}
            />

            {/** Quantity Update */}
            <ItemQuantityEdit
              orderItem={orderItem}
              orderItemCopy={orderItemCopy}
              handleQuantity={handleQuantity}
            />

            {/** Reason Update */}
            <div className="od-reason">
              <div className="od-reason-label">
                <span className="od-edit-label">Reason for change</span>
              </div>

              {reasons.map((reason) => {
                const onClick = () => handleReason(reason);
                const isSelected = orderItemCopy.receivalIssueChange === reason;
                const isItemSubstituted = reasons[2] === reason;
                const isOther = reasons[4] === reason;
                return (
                  <div className="od-reason-desc" key={reason}>
                    <button className="od-reasons-row" onClick={onClick}>
                      {isSelected ? (
                        <img
                          src={`${BUCKET_ASSETS_URL}selected-icon.svg`}
                          alt="Selected Icon"
                        />
                      ) : (
                        <div />
                      )}
                      <span>{reason}</span>
                    </button>
                    {isSelected && (isItemSubstituted || isOther) && (
                      <input
                        placeholder={
                          isItemSubstituted
                            ? "What was the substituted item?"
                            : "What was wrong?"
                        }
                        value={orderItemCopy.receivalIssueDescription}
                        onChange={handleReasonDescription}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <ReceivingToolWebFooter
          className="purple"
          text="Save adjustment"
          onClick={saveAdjustment}
          disabled={!orderItemCopy.receivalIssueChange}
          clearClick={() => updateOrderItemCopy(orderItem)}
        />
      </div>
    </Modal>
  );
};

export default ReceivingToolWebEdit;

ReceivingToolWebEdit.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      sourceUrlsafe: PropTypes.string,
    })
  ).isRequired,
  isPackage: PropTypes.bool.isRequired,
  updateOrder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  orderItem: orderItemType.isRequired,
  closeWebView: PropTypes.func.isRequired,
};

export const init = () => {
  /* eslint-disable */
  (function () {
    var e = document.createElement('script'),
      t = document.getElementsByTagName('body')[0];
    (e.async = 1),
      (e.src = `https://js.stripe.com/v3/`),
      (e.charset = 'UTF-8'),
      t.parentNode.appendChild(e);
  })();
  /* eslint-enable */
};

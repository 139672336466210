import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OrderCheckoutHeaderDelivery from './OrderCheckoutHeaderDelivery.jsx';
import OrderCheckoutHeaderPending from './OrderCheckoutHeaderPending.jsx';

export default class OrderCheckoutHeader extends Component {
  render() {
    const { checkoutView, order, ordersGrandTotal } = this.props;

    const showCheckoutView = () => {
      switch (checkoutView) {
        case 'deliveryView':
          return (
            <OrderCheckoutHeaderDelivery
              vendorName={order.vendorName}
              deliveryDay={order.deliveryDay}
              order_urlsafe={order.urlsafe}
              {...this.props}
            />
          );
        case 'reviewView':
          return (
            <OrderCheckoutHeaderDelivery
              vendorName={order.vendorName}
              deliveryDay={order.deliveryDay}
              order_urlsafe={order.urlsafe}
              {...this.props}
            />
          );
        case 'pendingView':
          return (
            <OrderCheckoutHeaderPending
              placeOrderDate={order.placeOrderDate}
              vendorName={order.vendorName}
              deliveryDay={order.deliveryDay}
              order_urlsafe={order.urlsafe}
              total={order.total}
              ordersGrandTotal={ordersGrandTotal}
              {...this.props}
            />
          );
        default:
          return (
            <OrderCheckoutHeaderDelivery
              vendorName={order.vendorName}
              deliveryDay={order.deliveryDay}
              order_urlsafe={order.urlsafe}
              {...this.props}
            />
          );
      }
    };

    return <div>{showCheckoutView()}</div>;
  }
}

OrderCheckoutHeader.propTypes = {
  checkoutView: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  ordersGrandTotal: PropTypes.number.isRequired,

  toggleCollapse: PropTypes.func.isRequired,
  collapseOrder: PropTypes.func.isRequired,
};

import { Combobox as HeadlessCombobox, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import cx from 'classnames';

export const comboboxTimeCutoff: ComboOption[] = [
    { label: '12:00AM', value: '00:00:00' },
    { label: '12:30AM', value: '00:30:00' },
    { label: '1:00AM', value: '01:00:00' },
    { label: '1:30AM', value: '01:30:00' },
    { label: '2:00AM', value: '02:00:00' },
    { label: '2:30AM', value: '02:30:00' },
    { label: '3:00AM', value: '03:00:00' },
    { label: '3:30AM', value: '03:30:00' },
    { label: '4:00AM', value: '04:00:00' },
    { label: '4:30AM', value: '04:30:00' },
    { label: '5:00AM', value: '05:00:00' },
    { label: '5:30AM', value: '05:30:00' },
    { label: '6:00AM', value: '06:00:00' },
    { label: '6:30AM', value: '06:30:00' },
    { label: '7:00AM', value: '07:00:00' },
    { label: '7:30AM', value: '07:30:00' },
    { label: '8:00AM', value: '08:00:00' },
    { label: '8:30AM', value: '08:30:00' },
    { label: '9:00AM', value: '09:00:00' },
    { label: '9:30AM', value: '09:30:00' },
    { label: '10:00AM', value: '10:00:00' },
    { label: '10:30AM', value: '10:30:00' },
    { label: '11:00AM', value: '11:00:00' },
    { label: '11:30AM', value: '11:30:00' },
    { label: '12:00PM', value: '12:00:00' },
    { label: '12:30PM', value: '12:30:00' },
    { label: '1:00PM', value: '13:00:00' },
    { label: '1:30PM', value: '13:30:00' },
    { label: '2:00PM', value: '14:00:00' },
    { label: '2:30PM', value: '14:30:00' },
    { label: '3:00PM', value: '15:00:00' },
    { label: '3:30PM', value: '15:30:00' },
    { label: '4:00PM', value: '16:00:00' },
    { label: '4:30PM', value: '16:30:00' },
    { label: '5:00PM', value: '17:00:00' },
    { label: '5:30PM', value: '17:30:00' },
    { label: '6:00PM', value: '18:00:00' },
    { label: '6:30PM', value: '18:30:00' },
    { label: '7:00PM', value: '19:00:00' },
    { label: '7:30PM', value: '19:30:00' },
    { label: '8:00PM', value: '20:00:00' },
    { label: '8:30PM', value: '20:30:00' },
    { label: '9:00PM', value: '21:00:00' },
    { label: '9:30PM', value: '21:30:00' },
    { label: '10:00PM', value: '22:00:00' },
    { label: '10:30PM', value: '22:30:00' },
    { label: '11:00PM', value: '23:00:00' },
    { label: '11:30PM', value: '23:30:00' },
    { label: '11:59PM', value: '23:59:00' }];

export type ComboboxProps = {
    onChange: (option: ComboOption) => void,
    initialValue: string,
    initialLabel?: string,
    disabled?: boolean,
    options: ComboOption[],
};

export type ComboOption = {
    value: string,
    label: string,
};

type UniqueComboOption = ComboOption & {
    id: number,
};

export const Combobox: React.FC<ComboboxProps> = ({ initialValue, initialLabel, onChange, disabled, options }) => {
    const selectOptions: UniqueComboOption[] = [
        { id: 1, label: initialLabel, value: initialValue }
    ];

    for (let i: number = 0; i < options.length; ++i) {
        const option: ComboOption = options[i];
        const selectOption: UniqueComboOption = {
            id: (i + 2),
            label: option.label,
            value: option.value,
        };

        selectOptions.push(selectOption);
    }

    const [selected, setSelected] = useState<ComboOption>(selectOptions[0]);
    const [query, setQuery] = useState('');

    const filter = query === ''
        ? selectOptions
        : selectOptions.filter((option) => option.label
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, '')));

    useEffect(() => {
        setSelected(selectOptions[0]);
    }, [initialValue, initialLabel]);

    return (
        <div className="w-full">
            <HeadlessCombobox value={selected}
                onChange={(option: ComboOption): void => {
                    onChange(option);
                    setSelected(option);
                }}>
                <div className="relative mt-2">
                    <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left border border-gray-400 sm:text-1">
                        <HeadlessCombobox.Button className="w-full p-0">
                            <HeadlessCombobox.Input
                                className={cx('w-full border-none py-2 pl-3 pr-10 text-1 leading-5 text-gray-700 ring-0 outline-0', {
                                    'bg-gray-100': disabled,
                                })}
                                displayValue={(time: ComboOption): string => time.label}
                                onChange={(event): void => setQuery(event.target.value)}
                                disabled={disabled}/>
                        </HeadlessCombobox.Button>
                        <HeadlessCombobox.Button className="absolute inset-y-0 right-0 flex items-center bg-white pr-2" disabled={disabled}>
                            <ChevronDownIcon
                                className="h-4 w-4 text-gray-600"
                                aria-hidden="true"/>
                        </HeadlessCombobox.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={(): void => setQuery('')}>
                        <HeadlessCombobox.Options className="absolute mt-1 z-50 max-h-48 opacity-100 w-full overflow-auto rounded-lg bg-gray-100 py-1 px-0 text-2 shadow-z3 sm:text-1">
                            {filter.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found.
                                </div>
                            ) : (
                                filter.map((option) => (
                                    <HeadlessCombobox.Option
                                        key={option.id}
                                        className={({ active }): string => `relative opacity-100 cursor-default select-none py-2 px-0 list-none ${
                                            active ? 'bg-gray-300 text-white' : 'text-gray-700'
                                        }`
                                        }
                                        value={option}>
                                        <span
                                            className={`block truncate px-4 ${
                                                selected ? 'font-semibold' : 'font-regular'
                                            }`}>
                                            {option.label}
                                        </span>
                                    </HeadlessCombobox.Option>
                                ))
                            )}
                        </HeadlessCombobox.Options>
                    </Transition>
                </div>
            </HeadlessCombobox>
        </div>
    );
};

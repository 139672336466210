export const basicCustomStyles = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(1, 1, 1, 0.6)',
    transform: 'translate(0%, 0%)',
  },
  overlay: {
    zIndex: 20,
  },
};

export const customStyles = {
  ...basicCustomStyles,
  content: {
    ...basicCustomStyles.content,
    backgroundColor: 'rgba(0,0,0,0.35)',
    padding: '10px 0 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

import React from 'react';
import useAccount from '@v2/hooks/useAccount.hook';
import { Typography, Container } from '@notch-ordering/ui-components';
import useCompletedOrdersToday from '@v2/hooks/useCompletedOrdersToday.hook';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { OrderData } from '@v2/types/OrderData';
import HomeDeliveries from './HomeDeliveries';

/**
 * Greets the user and displays the list of deliveries on that day on the home page
 */
const HomeHeader: React.FC = () => {
    const { firstName = '' }: { firstName: string } = useAccount();
    const todaysOrders: OrderData[] = useCompletedOrdersToday();
    const areTodaysOrders = !!todaysOrders.length;
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.Header' });

    const getDayGreetings = (): string => {
        const hours: number = new Date().getHours();
        if (hours >= 6 && hours < 12) {
            return t('goodMorning');
        } if (hours >= 12 && hours <= 17) {
            return t('goodAfternoon');
        }
        return t('goodEvening');
    };

    return (
        <Container fluid className={'bg-white'}>
            <Container className="lg:px-10 lg:py-16 py-10">
                <Typography
                    as="h1"
                    variant="6XL"
                    size="text-7"
                    className={!areTodaysOrders ? 'text-gray-700 mb-4 lg:mb-2 mt-0' : 'text-gray-700 mb-0 mt-0'}>
                    {getDayGreetings()}, {firstName}
                </Typography>

                {!areTodaysOrders && (
                    <Typography as="span"
                        className="text-gray-600">
                        {t('noOrders')}
                    </Typography>
                )}
            </Container>
            {areTodaysOrders && <HomeDeliveries orders={todaysOrders} />}
        </Container>
    );
};

export default HomeHeader;

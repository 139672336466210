import React from 'react';
import RotateIcon from '@icons/rotate-icon.svg';
import ZoomOutIcon from '@icons/zoom-out-icon.svg';
import ZoomInIcon from '@icons/zoom-in-icon.svg';
import ExternalLinkIcon from '@icons/external-link-icon.svg';
import DownloadIcon from '@icons/download-icon.svg';
import { Typography } from '@notch-ordering/ui-components';
import { useTransformEffect } from 'react-zoom-pan-pinch';
import cx from 'classnames';
import { mergeClassNames } from '@v2/utils/Helpers';

export interface ScanReviewImageControlsProps {
    zoomIn?: () => void,
    zoomOut?: () => void,
    rotate?: () => void,
    download?: () => void,
    url?: string,
    totalPages?: number,
    pageNumber?: number,
}

export const ScanReviewImageControls : React.FC<ScanReviewImageControlsProps> = ({
    rotate,
    zoomIn,
    zoomOut,
    download,
    url,
    totalPages,
    pageNumber
}) => {
    const [percentage, setPercentage] = React.useState(100);
    const [zoomInDisabled, setZoomInDisabled] = React.useState(false);
    const [zoomOutDisabled, setZoomOutDisabled] = React.useState(false);
    useTransformEffect((state) => {
        const { scale } = state.state;
        const scalePercentage = Math.round(scale * 100);
        setPercentage(scalePercentage);
        setZoomInDisabled(scale >= state.instance.setup.maxScale);
        setZoomOutDisabled(scale <= state.instance.setup.minScale);
    });
    const openInNewTab = ():void => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return <div data-testid="scan-review-image-controls" className="bg-gray-700 h-12 flex px-2.5 mx-auto rounded-b-lg bottom-0 w-full justify-between p-3">
        <div className="border-gray-675 pr-3 h-full flex items-center">
            <div className="px-1.5 h-full flex items-center" title="Scan review page info">
                <Typography as="div" weight="font-medium" className="text-white select-none w-10 text-center text-2">{`${pageNumber} of ${totalPages}`}</Typography>
            </div>
        </div>
        <div className="items-center h-full flex">
            <div className="border-r border-gray-675 flex items-center gap-3 justify-center h-full px-3">
                <div role="button"
                    className={mergeClassNames('px-1.5 py-0 flex items-center cursor-pointer', {
                        disabled: zoomOutDisabled,
                    })}
                    title="Zoom out"
                    onClick={(): void => {
                        if (zoomOutDisabled) return;

                        zoomOut();
                    }
                    }><ZoomOutIcon className={cx('h-4 w-4 text-white ', { 'opacity-50': zoomOutDisabled })}/></div>
                <Typography as="div" weight="font-medium" className="text-white select-none w-10 text-center text-2">{percentage}%</Typography>
                <div role="button"
                    title="Zoom in"
                    className={mergeClassNames('px-1.5 py-0 flex items-center cursor-pointer', {
                        disabled: zoomInDisabled,
                    })}
                    onClick={(): void => {
                        if (zoomInDisabled) return;

                        zoomIn();
                    }
                    }><ZoomInIcon className={cx('h-4 w-4 text-white ', { 'opacity-50': zoomInDisabled })}/></div>
            </div>
            <div className="border-gray-675 p-3 h-full flex items-center justify-center">
                <div title="Rotate" className="px-1.5 h-full flex items-center cursor-pointer" role="button" onClick={rotate}>
                    <RotateIcon className="h-4 w-4 text-white"/>
                </div>
            </div>
        </div>
        <div className="items-center h-full flex">
            <div className="pl-3 h-full flex items-center" onClick={openInNewTab}>
                <div className="px-1.5 h-full flex items-center cursor-pointer" title="Open in new tab" role="button">
                    <ExternalLinkIcon className="h-4 w-4 text-white"/>
                </div>
            </div>
            <div className=" h-full flex items-center pl-1.5" onClick={download}>
                <a className="px-1.5 h-full flex items-center cursor-pointer"
                    href={url}
                    download
                    title="Download"
                    role="button"
                    target="_blank"
                    rel="noreferrer" >
                    <DownloadIcon className="h-4 w-4 text-white"/>
                </a>
            </div>
        </div>
    </div>;
};

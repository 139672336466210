import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, Typography } from '@notch-ordering/ui-components';
import { isValid } from '@v2/utils/isValid';
import React, { ReactNode } from 'react';

import { BillLabelBadge } from '@v2/components/Bills/BillLabelBadge';
import { BillStatusBadge } from '@v2/components/Bills/BillStatusBadge';
import { AccountingSyncStatus } from '@v2/components/Bills/AccountingSyncStatus/AccountingSyncStatus';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { centsToDollars } from '@v2/utils/CurrencyUtils';
import { EApPlatform, formatDate } from '@notch-ordering/shared-logic';
import Utils from '@/utils';
import { BillsOrderNumberColumn } from './BillsOrderNumberColumn';
import { BillsSupplierNameColumn } from './BillsSupplierNameColumn';

export const enum EIntegrationSyncStatus {
    PendingApproval = 'Pending approval',
    NotUploaded = 'Not uploaded',
    Syncing = 'Syncing',
    NotSynced = 'Not synced',
    SyncError = 'Sync error',
}

export const INVOICE_SYNC_STATUS_FIELD = 'orderSyncDetails';

const headerClassName = 'text-gray-600 font-body text-med';
const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

export const enum EBillSyncStatus {
    PendingApproval = 'Pending approval',
    NotUploaded = 'Not uploaded',
    Syncing = 'Syncing',
    NotSynced = 'Not synced',
    SyncError = 'Sync error',
    Synced = 'Synced',
}

const getBillSyncStatus = (orderSyncDetails, isDraft: boolean): EBillSyncStatus => {
    if (isDraft) {
        return EBillSyncStatus.PendingApproval;
    }

    if (orderSyncDetails.isSyncInProgress) {
        return EBillSyncStatus.Syncing;
    }

    if (orderSyncDetails.error) {
        return EBillSyncStatus.SyncError;
    }

    if (!orderSyncDetails.isTwoWayMatched) {
        return EBillSyncStatus.NotUploaded;
    }

    if (!orderSyncDetails.lastSyncTime) {
        return EBillSyncStatus.NotSynced;
    }

    return EBillSyncStatus.Synced;
};

export const getInvoiceTableColumns = ():GridColDef<InvoiceData & { platform: EApPlatform }>[] => [
    {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 40,
        minWidth: 40,
        headerAlign: 'center',
        align: 'center',
        type: 'boolean'
    },
    {
        ...commonGridDef,
        field: 'invoiceNumber',
        headerName: 'Invoice #',
        minWidth: 160,
        flex: 0.5,
        sortable: false,
        renderCell: ({ value, row }): ReactNode => <Typography
            as="div"
            className="text-gray-700">
            {value || row.uploadID}
        </Typography>
    },
    {
        ...commonGridDef,
        field: 'invoice_status',
        sortable: false,
        headerName: '',
        flex: 0.5,
        minWidth: 130,
        renderCell: ({ row }): ReactNode => (<BillStatusBadge invoice={row} />)
    },
    { ...commonGridDef,
        field: 'payToAddress',
        headerName: 'Supplier',
        sortable: false,
        flex: 1,
        minWidth: 160,
        renderCell: ({ value, row }): ReactNode => (<BillsSupplierNameColumn name={value.name} supplierUrlsafeKey={row.supplierUrlsafeKey} />) },
    {
        ...commonGridDef,
        field: 'invoiceTotal',
        headerName: 'Amount',
        sortable: false,
        minWidth: 48,
        valueFormatter: ({ value }): string => {
            if (typeof value === 'number') {
                return Utils.formatAsCurrency(centsToDollars(value));
            }

            return '--';
        }
    },
    {
        ...commonGridDef,
        field: 'issuedDate',
        sortable: false,
        headerName: 'Issued date',
        minWidth: 120,
        valueFormatter: ({ value }): string => {
            if (isValid(value)) {
                return formatDate(value, 'MMM dd, yyyy') || '--';
            }

            return '--';
        }
    },
    {
        field: 'dueDate',
        headerName: 'Due date',
        minWidth: 120,
        headerAlign: 'left',
        sortable: false,
        headerClassName,
        cellClassName,
        align: 'left',
        valueFormatter: ({ value }): string => {
            if (isValid(value)) {
                return formatDate(value, 'MMM dd, yyyy') || '--';
            }

            return '--';
        }
    },
    {
        ...commonGridDef,
        width: 80,
        field: INVOICE_SYNC_STATUS_FIELD,
        headerName: 'Sync',
        sortable: false,
        hide: true,
        renderCell: ({ value, row: invoice }): ReactNode => {
            if (!value) {
                return null;
            }
            const isDraft = !!value.isDraft;
            const billSyncStatus = getBillSyncStatus(value, isDraft);
            return <AccountingSyncStatus status={billSyncStatus} invoice={invoice} allowResync={!isDraft} />;
        }
    },
    {
        ...commonGridDef,
        field: 'receival_status', // TODO [PAY-57]: replace with new label field
        flex: 0.25,
        sortable: false,
        headerName: 'Label',
        minWidth: 160,
        renderCell: ({ row }): JSX.Element => <BillLabelBadge invoice={row} platform={row.platform} />
    },
    {
        ...commonGridDef,
        field: 'orderNumber',
        headerName: 'Order #',
        minWidth: 160,
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }): ReactNode => <BillsOrderNumberColumn orderUrlsafeKey={row.orderUrlsafeKey} billNumber={row.invoiceNumber} />
    },
];

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReceivingToolWebHeader from './ReceivingToolWebHeader.jsx';
import ReceivingToolWebBody from './ReceivingToolWebBody.jsx';
import ReceivingToolPrice from './ReceivingToolPrice.jsx';
import ReceivingToolWebFooter from './ReceivingToolWebFooter.jsx';
import useOrder from '../hooks/useOrder';

const ReceivingToolWeb = ({ closeWebView }) => {
  const {
    order: {
      data: {
        items = [],
        urlsafe,
        vendorUrlsafe,
        canEditReceivingTool,
        buyer: { isReceivingToolEnabled },
        vendorName,
      } = {
        items: [],
      },
    },
    editOrderData
  } = useOrder();

  const [loading, updateLoading] = useState(false);

  const [productList, updateProducts] = useState(() => [
    ...JSON.parse(JSON.stringify(items)),
  ]);

  const updateProductList = (list) => {
    updateProducts(list);
  };

  useEffect(() => {
    if (items.length) {
      // update product list after submitting order changes
      updateProducts([...JSON.parse(JSON.stringify(items))]);
    }
  }, [items]);

  let isSubtotalUpdated = false;
  let priceEdited = false;
  let newSubTotal = 0;
  productList.forEach((product) => {
    if (
      product.isNew ||
      product.isPackageUpdated ||
      product.isPriceUpdated ||
      product.isQuantityUpdated ||
      product.isWeightUpdated ||
      product.isReasonUpdated
    ) {
      priceEdited = true;
    }

    if (product.expectedDeliveryTotal !== product.total) {
      isSubtotalUpdated = true;
    }

    newSubTotal += product.total;
  });

  const onSubmit = () => {
    const data = productList
      .filter(
        (product) =>
          product.isReasonUpdated ||
          product.isPackageUpdated ||
          product.isNew ||
          product.isPriceUpdated ||
          product.isQuantityUpdated ||
          product.isWeightUpdated
      )
      .map((product) => {
        if (product.isPackageUpdated) {
          return {
            id: product.urlsafe,
            externalId: product.externalId,
            packagingQuantity: 0,
            receivalIssueChange: product.receivalIssueChange,
            receivalIssueDescription: product.receivalIssueDescription,
            isOldPackage: !!product.isOldPackage,
          };
        }
        const data = {
          id: product.urlsafe,
          ...product,
          unitQuantity: product.unitQuantity
            ? Number(product.unitQuantity)
            : null,
        };
        return data;
      });

    editOrderData({
      orderUrlSafe: urlsafe,
      data: { items: data },
      vendorUrlsafe,
      callback: async (load) => {
        updateLoading(load);
        closeWebView();
      },
      isWeb: true,
      vendorName
    });
    updateLoading(true);
  };

  const isOrderEditable = isReceivingToolEnabled && canEditReceivingTool;

  return (
    <div className="od-container-web">
      <ReceivingToolWebHeader loading={loading} closeWebView={closeWebView} />
      <div className="od-data-web-content">
        <ReceivingToolWebBody
          productList={productList}
          updateProductList={updateProductList}
          isOrderEditable={isOrderEditable}
          loading={loading}
          closeWebView={closeWebView}
        />
      </div>
      <div className="od-data-web-fixed">
        <div
          style={{
            marginRight: isOrderEditable ? 52 : 25,
          }}
        >
          <ReceivingToolPrice
            priceEdited={priceEdited}
            newSubTotal={newSubTotal}
            isSubtotalUpdated={isSubtotalUpdated}
            autoScroll={false}
          />
        </div>
        {isOrderEditable && (
          <ReceivingToolWebFooter
            className="pink"
            text="Submit changes"
            onClick={onSubmit}
            disabled={!priceEdited}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default ReceivingToolWeb;

ReceivingToolWeb.defaultProps = {
  closeWebView: null,
};

ReceivingToolWeb.propTypes = {
  closeWebView: PropTypes.func,
  updateSubmitForm: PropTypes.func.isRequired,
};

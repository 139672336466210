import React, { useEffect, useMemo, useState } from 'react';
import { tNavigation } from '@v2/i18n';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { Container,
    GridSortModel,
    Loading,
    NotchDataGrid,
    Separator,
    twMergeCustom } from '@notch-ordering/ui-components';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { AddBillModal } from '@v2/components/Bills/AddBillModal/AddBillModal';
import Router from 'react-router';
import { UploadsHeader } from '@v2/components/Uploads/UploadsHeader/UploadsHeader';
import { UPLOAD_TABS, UPLOADS_TABS_DEFAULT, UploadsTabs } from '@v2/components/Uploads/UploadsTabs/UploadsTabs';
import { useGetOcrInvoices } from '@v2/Pages/Uploads/UploadsQueries.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { INVOICE_SEARCH_PARAMS_INITIAL_STATE, useUploadsStore } from '@v2/stores/UploadsStore';
import { getColumns, getColumnsVisibilityModel } from '@v2/Pages/Uploads/UploadsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { InvoiceQueryParams, InvoicesSearchParams } from '@v2/network/FalsePromiseApi';
import { UploadPDFModal } from '@v2/components/Uploads/UploadPDFModal/UploadPDFModal';
import { UploadsEmptyState } from '@v2/components/Uploads/UploadsEmptyState/UploadsEmptyState';
import { OcrState } from '@v2/types/UploadsTypes';
import { UploadsFilters } from '@v2/components/Uploads/UploadFilters/UploadsFilters';
import { UploadsNoResults } from '@v2/components/Uploads/UploadsNoResults/UploadsNoResults';
import cx from 'classnames';
import { EPaths } from '@v2/constants/EPaths';
import { useQuery } from '@tanstack/react-query';
import { FETCH_ACCOUNTING_INTEGRATIONS } from '@v2/components/Bills/PayBillModal';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { OCConfiguration, getOCConfiguration } from '@v2/network/IntegrationAPI';
import { EErpPlatform, getAccountingConfiguration } from '@notch-ordering/shared-logic';
import { searchInvoices } from '@v2/network/GreevilsGreedApi';
import ImagesModal from '@/components/shared/ImagesModal/ImagesModal';
import { RootState } from '@/v2';
import { showInvoiceImagesModal } from '@/actions/orderInvoicesActions';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';

export interface UploadsProps {router: Router}

export const Uploads : React.FC<UploadsProps> = ({ router }) => {
    const isMobileWidth = useIsMobileWidth();
    // this value comes from redux store
    const { imagesModal } = useSelector((state:RootState) => state.orderInvoicesReducer);
    const dispatch = useDispatch();
    const { queryParams, searchParams, updateQueryParams, updateSearchParams, hasSearchParamsSelected, pagination: { page, mapPageToNextCursor }, resetPagination, updatePagination } = useUploadsStore();
    const [showAddInvoiceModal, setShowAddInvoiceModal] = React.useState(false);
    const { buyer } = useBuyerHook();
    const [selectedTab, setSelectedTab] = React.useState(UPLOADS_TABS_DEFAULT);
    const [imsIntegrationFetched, setImsIntegrationFetched] = useState<boolean>(false);
    const [erpPlatform, setERPPlatform] = useState<EErpPlatform>(null);
    const accountingIntegration = useQuery([FETCH_ACCOUNTING_INTEGRATIONS, buyer.urlsafe], () => getAccountingConfiguration(buyer.urlsafe), { enabled: !!buyer.urlsafe, retry: 1 });
    const accountingPlatform = getAccountingConfigurationType(accountingIntegration?.data);
    const isApprovedTabSelected = selectedTab === UPLOAD_TABS.APPROVED;
    const isInReviewTabSelected = selectedTab === UPLOAD_TABS.IN_REVIEW;
    const hasFiltersSelected = useMemo(() => hasSearchParamsSelected(), [searchParams]);
    const allIntegrationsFetched = imsIntegrationFetched && accountingIntegration.isFetched;
    const hasAnyIntegration = allIntegrationsFetched && (!!accountingPlatform || !!erpPlatform);

    const ocrInvoicesParams = useMemo(() => {
        let { hasAccountingSynced, hasImsSynced } = searchParams;

        if (allIntegrationsFetched) {
            if (hasImsSynced === undefined && !!erpPlatform) {
                hasImsSynced = null;
            }

            if (hasAccountingSynced === undefined && !!accountingPlatform) {
                hasAccountingSynced = null;
            }
        }

        let ocrState = Array.isArray(searchParams.ocrState) ? searchParams.ocrState : [searchParams.ocrState];
        if (!hasAnyIntegration && isInReviewTabSelected) {
            ocrState = ocrState.filter((state) => state !== 'verified');
        }

        return {
            searchParams: {
                ...searchParams,
                buyerUrlsafeKey: buyer?.urlsafe,
                hasImsSynced,
                hasAccountingSynced,
                ocrState,
            },
            queryParams: {
                ...queryParams,
                cursor: mapPageToNextCursor[page - 1],
            },
            queryOptions: {
                enabled: !!buyer?.urlsafe && allIntegrationsFetched,
            }
        };
    }, [searchParams, queryParams, page, buyer?.urlsafe, allIntegrationsFetched]);

    const { data: allUploads, refetch: refetchAllUploads, isLoading: loadingAll } = useGetOcrInvoices({ searchParams: { buyerUrlsafeKey: buyer?.urlsafe }, queryParams: {} });
    const showEmptyState = allUploads?.total === 0;

    const { data: uploadInvoices, isLoading: isLoadingUploadInvoices, refetch: refetchUploadInvoices, status: loadingStatusUploadInvoices } = useGetOcrInvoices(ocrInvoicesParams);
    const uploadInvoicesResults = uploadInvoices?.results ?? [];
    const hasResults = uploadInvoicesResults.length > 0;
    const invoiceNumbers = uploadInvoicesResults.map((invoice) => invoice.externalReferenceID);
    const { data: greevilsResponse, refetch: refetchGreevilsBills } = useQuery(
        ['UPLOADED_GREEVILS_BILLS'],
        () => searchInvoices(buyer.urlsafe, { search: invoiceNumbers }, { page: page + 1, limit: invoiceNumbers?.length }),
        {
            enabled: invoiceNumbers?.length > 0,
            refetchOnWindowFocus: false,
        }
    );
    const greevilsBills = greevilsResponse?.results;
    const successStatus = loadingStatusUploadInvoices === 'success';
    const showLoading = isLoadingUploadInvoices;
    const showResults = successStatus && hasResults;
    const shouldShowSyncColumn = !!accountingPlatform || !!erpPlatform;
    const shouldShowSyncStatusFilter = !!accountingPlatform && !!erpPlatform && isInReviewTabSelected;

    useTitle(tNavigation('Uploads'));

    const showModal = (): void => {
        setShowAddInvoiceModal(true);
        sendTrackingEvent(TrackingEvents.addBillClicked);
    };

    const onCloseInvoiceImagesModal = ():void => {
        dispatch(showInvoiceImagesModal(false));
    };

    useEffect(() => {
        if (uploadInvoicesResults?.length > 0) {
            refetchGreevilsBills();
        }
    }, [uploadInvoicesResults]);

    useEffect(() => {
        async function checkERPIntegration(): Promise<void> {
            // For now, since the only ERP integration we have is OC, just look for the OC config
            const config = await getOCConfiguration(buyer.urlsafe).catch(() => {});
            const ocConfig = config as OCConfiguration;
            setERPPlatform((ocConfig?.error?.length === 0) ? EErpPlatform.OPTIMUMCONTROL : null);
            setImsIntegrationFetched(true);
        }
        checkERPIntegration();
    }, [buyer.urlsafe]);

    useEffect(() => {
        if (loadingStatusUploadInvoices === 'success' && uploadInvoices?.nextCursor) {
            // We add nextCursor when available
            updatePagination({
                mapPageToNextCursor: {
                    ...mapPageToNextCursor,
                    [page]: uploadInvoices?.nextCursor
                }
            });
        }
    }, [page, loadingStatusUploadInvoices, uploadInvoices?.nextCursor]);

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined
        };
        updateQueryParams({
            sort: field as InvoiceQueryParams['sort'],
            direction: sort
        });
        resetPagination();
    }, []);

    const onChangeTab = (tab: string): void => {
        setSelectedTab(tab);
        const searchParamsUpdates: Partial<InvoicesSearchParams> = {};

        if (tab === UPLOAD_TABS.IN_REVIEW) {
            searchParamsUpdates.ocrState = [...INVOICE_SEARCH_PARAMS_INITIAL_STATE.ocrState] as OcrState[];
            searchParamsUpdates.requireAllIntegrationsSynced = false;
        } else {
            searchParamsUpdates.ocrState = ['verified'] as OcrState[];
            searchParamsUpdates.requireAllIntegrationsSynced = true;
        }

        updateSearchParams(searchParamsUpdates);
        resetPagination();
    };

    return <>
        <Loading isDark hidden={!loadingAll} className="mt-8"/>
        {!showEmptyState && !loadingAll
        && <Container fluid className="relative lg:pb-10 pb-36">
            <div className={twMergeCustom(cx('lg:px-10 px-0', { 'bg-white pb-5 border-b border-gray-200': isMobileWidth }))}>
                <UploadsHeader onClickBillsButton={():void => router.push(EPaths.INVOICES)} onClickAddBill={showModal}/>

                {!isMobileWidth && <UploadsTabs onChange={onChangeTab} selectedTab={selectedTab} />}
            </div>
            {!isMobileWidth && <Separator className="mb-8"/>}

            {!isMobileWidth && <div className="lg:px-10 px-6">
                <UploadsFilters
                    erpPlatform={erpPlatform}
                    accountingPlatform={accountingPlatform}
                    shouldShowSyncStatusFilter={shouldShowSyncStatusFilter}/>
            </div>}

            {showLoading && <Loading isDark className="mt-10" />}
            {showResults && <div className="lg:px-10 px-0"><NotchDataGrid
                onRowClick={(params): void => {
                    // not redirecting to review page on mobile
                    if (isMobileWidth) {
                        return;
                    }
                    const { id = '', ocrState } = params.row;
                    const isReviewed = ocrState === 'reviewed';
                    const isVerified = ocrState === 'verified';
                    const allowClickRow = isInReviewTabSelected && (isReviewed || isVerified);
                    if (allowClickRow) {
                        router.push(EPaths.SCANS_REVIEW.replace(':invoiceKey', id));
                    }
                }}
                autoHeight
                loading={isLoadingUploadInvoices}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                columnVisibilityModel={getColumnsVisibilityModel({ isMobileWidth,
                    isApprovedTabSelected,
                    shouldShowSyncColumn })}
                columns={getColumns(accountingPlatform, erpPlatform, isInReviewTabSelected, greevilsBills)}
                page={page}
                rowCount={uploadInvoices?.total ?? 0}
                rowsPerPageOptions={[queryParams.limit]}
                pageSize={queryParams.limit}
                paginationMode="server"
                rowHeight={isMobileWidth ? 150 : 61}
                onPageChange={(newPage): void => {
                    if (newPage === 0 || mapPageToNextCursor[newPage - 1]) {
                        updatePagination({ page: newPage });
                    }
                }}
                rows={uploadInvoicesResults}

                sx={{
                    // hover state for the row action buttons
                    '& .MuiDataGrid-row:hover .scan-actions': {
                        display: 'flex!important',
                        background: '#F6F6F6'
                    },
                    // select first cell in row
                    '& .MuiDataGrid-cell:first-child': {
                        overflow: 'visible !important',
                    },
                    // hiding the headers in mobile to avoid extra space in the top of the grid
                    '& .MuiDataGrid-virtualScroller': {
                        marginTop: isMobileWidth ? '0 !important' : 56,
                        overflowY: 'visible !important',
                        overflow: 'visible !important',
                    },
                    // column headers if mobile hide
                    '& .MuiDataGrid-columnHeaders': {
                        display: isMobileWidth ? 'none' : 'flex'
                    }

                }}/>
            </div>}
            {!showResults && <UploadsNoResults type={selectedTab} hasFiltersSelected={hasFiltersSelected}/>}
        </Container>}
        {/* no uploads view */}
        {showEmptyState && <UploadsEmptyState onClickUploadBill={showModal} />}
        <UploadPDFModal />
        <ImagesModal
            open={imagesModal.open}
            onClose={onCloseInvoiceImagesModal}
            title={
                <div className="truncate flex flex-col gap-1 min-w-0 w-full">
                    <div className="mb-1">{imagesModal.invoiceNumber}</div>
                    <div className="truncate">{imagesModal.vendorName}</div>
                </div>
            }
            slideShowView
            images={imagesModal.images?.map((image) => {
                const isAppEngineHosted = image.url.toLocaleLowerCase().indexOf('lh3.googleusercontent.com/') > -1;
                return {
                    ...image,
                    url: `${image.url}${isAppEngineHosted ? '=s1200' : ''}`
                };
            })}/>
        <AddBillModal
            onBillAdded={(): void => {
                refetchUploadInvoices();
                refetchAllUploads();
            }}
            closeModal={(): void => {
                setShowAddInvoiceModal(false);
            }}
            isOpen={showAddInvoiceModal}/>
    </>;
};

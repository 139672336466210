import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FileUploadContainer from './ChatFileUploadContainer.jsx';
import Header from '../components/ChatSelectedHeader.jsx';
import Body from '../components/ChatSelectedBody.jsx';
import Footer from '../components/ChatSelectedFooter.jsx';
import LoadingData from '../../../components/LoadingData.jsx';
import { ChatSelectedContext } from '../context';
import { sendChatMessage } from '../actions/chatAuthActions';
import cx from 'classnames';

export default () => {
  const context = useContext(ChatSelectedContext);
  const [openFileUploadDialog, setOpenFileUploadDialog] = useState(false);

  const {
    messages: { isRefreshing },
    chatSelected: { sid },
  } = useSelector((store) => store.chat);
  const dispatch = useDispatch();

  const showFileUpload = () => setOpenFileUploadDialog(true);
  const hideFileUpload = () => setOpenFileUploadDialog(false);

  const sendMessage = (message) => dispatch(sendChatMessage(message));

  return (
    <div
      className={cx('chat-selected-list-container', 'chat-view', {
        'hidden-mobile': !sid,
      })}
    >
      {context.messages ? (
        <>
          <Header />

          <Body>
            {isRefreshing ? (
              <LoadingData display={true} text="Loading more messages" />
            ) : null}
          </Body>

          <Footer showFileUpload={showFileUpload} sendHandler={sendMessage}>
            <FileUploadContainer
              shouldOpen={openFileUploadDialog}
              sendHandler={sendMessage}
              closeHandler={hideFileUpload}
            />
          </Footer>
        </>
      ) : (
        <LoadingData display={true} text="Loading" />
      )}
    </div>
  );
};

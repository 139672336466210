import React, { useEffect, createRef, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { ChatSelectedContext } from '../context';
import { loadNextSetOfChatMessages } from '../actions/chatMessagesActions';
import {
  sortSelectedBodyHandler,
  formatDateTimePerChat,
} from '../helpers/chatClientHelper';
import {
  CHAT_MESSAGES_HISTORY_PAGE_LOADING_IN_MSECONDS,
  FIRST_MESSAGE,
} from '../../../constants/GlobalConstants';
import ChatSystemMessage from './ChatSystemMessage.jsx';

const ChatSelectedBody = ({ children }) => {
  const context = useContext(ChatSelectedContext);
  const chatBodyRef = createRef();

  const {
    chat: { auth },
  } = useSelector((store) => store);
  const dispatch = useDispatch();

  const [isLoadingMoreMessages, setIsLoadingMoreMessges] = useState(false);

  useEffect(() => {
    const debounceWrapper = _.debounce(
      trackScrolling,
      CHAT_MESSAGES_HISTORY_PAGE_LOADING_IN_MSECONDS
    );
    if (chatBodyRef.current)
      chatBodyRef.current.addEventListener('scroll', debounceWrapper);

    return () => {
      if (chatBodyRef.current) {
        chatBodyRef.current.removeEventListener('scroll', debounceWrapper);
      }
    };
  }, []);

  useEffect(() => {
    if (isLoadingMoreMessages) loadMoreMessages();
  }, [isLoadingMoreMessages]);
  useEffect(() => {
    if (context.sid)
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [Object.keys(context.messages || {}).length, context.sid]); // auto scrolls to bottom on each new message received or sent

  const trackScrolling = (e) => {
    if (e.target && e.target.scrollTop === 0) setIsLoadingMoreMessges(true);
  };

  const loadMoreMessages = () => {
    dispatch(loadNextSetOfChatMessages(context.sid));
    setIsLoadingMoreMessges(false);
  };

  return (
    <>
      {children}
      <div id="twilio-chat-body" className="body" ref={chatBodyRef}>
        {Object.keys(context.messages || {}).length > 0 &&
          context.messages.sort(sortSelectedBodyHandler).map((message) => {
            if (message.state.body === FIRST_MESSAGE) {
              return null;
            }
            if (message.state.isSystemMessage)
              return (
                <ChatSystemMessage
                  key={message.state.sid}
                  message={message.state.message}
                />
              );

            const isOutgoingMessage =
              message.state.author.toLowerCase().trim() === auth.identity;

            const { channelMembers = {} } = context.messageAttributes;
            return (
              <div className="body-container" key={message.state.sid}>
                <div
                  className={`${
                    isOutgoingMessage ? 'outgoing-bubble' : 'incoming-bubble'
                  }`}
                >
                  {message.type === 'media' ? (
                    <>
                      <img className="image" src={message.chatImageUrl} />
                      <div className="image-text">
                        {message.state.media.state.filename}
                      </div>
                    </>
                  ) : (
                    <pre className="text">{message.state.body}</pre>
                  )}
                </div>
                <div
                  className={`${
                    isOutgoingMessage
                      ? 'outgoing-bubble-footer'
                      : 'incoming-bubble-footer'
                  }`}
                >
                  <div className="author">
                    {channelMembers[message.state.author] ||
                      message.state.author}
                  </div>
                  <div className="time">
                    {formatDateTimePerChat(message.state.dateUpdated)}
                  </div>
                  {isOutgoingMessage && (
                    <div className="time">&nbsp;&nbsp;&nbsp;Sent</div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ChatSelectedBody;

ChatSelectedBody.defaultProps = {
  children: null,
};

ChatSelectedBody.propTypes = {
  children: PropTypes.element,
};

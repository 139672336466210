import AddIcon from '@icons/add-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { SupplierInitials } from '@v2/components/Shared/SupplierCard/SupplierInitials';
import { Button, Typography } from '@notch-ordering/ui-components';
import React, { useState } from 'react';

export type DiscoverableSupplier = {
    catalogID: string,
    supplierName: string,
};

type Props = {
    name: string,
    id: string,
    onAdd: (supplier: DiscoverableSupplier) => void,
    onRemove: (supplier: DiscoverableSupplier) => void,
    isLoading?: boolean,
};

export const DiscoveredSupplier: React.FC<Props> = ({
    name,
    id,
    onAdd,
    onRemove,
    isLoading = false
}) => {
    const [selected, setSelected] = useState(false);
    const buttonVariant = selected ? 'SECONDARY' : 'TERTIARY_FILLED';

    return (
        <div className="h-24 flex items-center justify-between">
            <div className="flex items-center gap-x-5">
                <SupplierInitials name={name} className="flex-shrink-0" />
                <Typography as="div" weight="font-medium" className="flex-grow overflow-hidden">{name}</Typography>
            </div>
            <Button variant={buttonVariant}
                size="ICON_MEDIUM"
                className="flex items-center justify-center flex-shrink-0"
                disabled={isLoading}
                onClick={(): void => {
                    if (!selected) {
                        onAdd({ catalogID: id, supplierName: name });
                    } else {
                        onRemove({ catalogID: id, supplierName: name });
                    }

                    setSelected(!selected);
                }}>
                <div className="h-4 w-4">{selected ? <CheckIcon /> : <AddIcon />}</div>
            </Button>
        </div>);
};

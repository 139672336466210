import { Typography, Popover } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import MoreIcon from '@icons/more-icon.svg';
import React from 'react';
import { ProductData } from '@v2/network/SecretShopAPI';
import Utils from '@/utils';

type Props = {
    product: ProductData,
    editItem: (product) => void,
    deleteItem: (id: string) => void,
};

export const CreatedItem: React.FC<Props> = ({
    product,
    editItem,
    deleteItem,
}) => {
    const [pack] = product.packages;
    const [price] = pack.prices;
    return (
        <>
            <div className="flex" key={pack.sku}>
                <div className="grid grid-cols-2 items-start justify-items-start w-full gap-y-1">
                    <Typography as="div" weight="font-medium" className="text-gray-700">
                        {product.name}
                    </Typography>
                    <Typography as="div" weight="font-medium" className="text-gray-700">
                        {Utils.formatAsCurrency(price.pricePerUnit)}
                    </Typography>
                    <Typography as="div" variant="BASE" className="text-gray-600">
                        {tCommon('Labels.sku')}: {pack.sku}
                    </Typography>
                    <Typography as="div" variant="BASE" className="text-gray-600">
                        {tCommon('per')
                            .toLowerCase()}{pack.quantity > 1 ? ` ${pack.quantity}` : ''} {pack.unit}
                    </Typography>
                </div>
                <Popover
                    button={
                        <div
                            className="bg-white flex items-center justify-center h-10 w-10 hover:bg-white lg:hover:bg-gray-100  rounded-xl">
                            <div className="text-gray-700 w-4 h-4"><MoreIcon/></div>
                        </div>
                    }
                    items={[
                        {
                            label: tCommon('Buttons.edit'),
                            onClick: (): void => editItem(product),
                        },
                        {
                            label: tCommon('Buttons.delete'),
                            onClick: (): void => deleteItem(product.id),
                            className: 'text-red-300'
                        }
                    ]}/>
            </div>
            <div className="w-full h-px bg-gray-300 my-5"/>
        </>);
};

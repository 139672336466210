import React from 'react';
import { InvoiceSearchParamsType } from '@v2/network/FalsePromiseApi';
import { useUploadsStore } from '@v2/stores/UploadsStore';
import { FiltersBadge } from '@v2/components/Shared/FiltersBadge/FiltersBadge';

export interface BillsFilterBadgeProps {
    children: React.ReactNode,
    filterType: InvoiceSearchParamsType,
    label?: string,
}

export const UploadFilterBadge : React.FC<BillsFilterBadgeProps> = ({ children, filterType, label }) => {
    const { resetSearchParamsByKey } = useUploadsStore();
    const clearFilter = ():void => {
        resetSearchParamsByKey(filterType);
    };
    return <FiltersBadge label={label} clearFilter={clearFilter}>{children}</FiltersBadge>;
};

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import orderItemType from '../../types/orderItemType';

const ItemWeightEdit = ({
  orderItem,
  handleWeight,
  editWeight,
  toggleEditWeight,
}) => {
  const editWeightRef = useRef(null);
  useEffect(() => {
    if (editWeight && editWeightRef.current) {
      editWeightRef.current.focus();
    }
  }, [editWeight]);

  return (
    <div className="od-row col-50">
      <div className="od-data">
        <span className="od-edit-label">Weight</span>
        <div className="od-data-row">
          <input
            type={editWeight ? 'number' : 'text'}
            className="od-product"
            value={
              editWeight
                ? orderItem.unitQuantity
                : `${orderItem.unitQuantity}${orderItem.salesUnit}`
            }
            onFocus={() => toggleEditWeight(true)}
            onBlur={() => toggleEditWeight(false)}
            ref={editWeightRef}
            onChange={handleWeight}
          />
          {editWeight && (
            <span className="od-product gray">{orderItem.salesUnit}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemWeightEdit;

ItemWeightEdit.propTypes = {
  handleWeight: PropTypes.func.isRequired,
  editWeight: PropTypes.bool.isRequired,
  toggleEditWeight: PropTypes.func.isRequired,
  orderItem: orderItemType.isRequired,
};

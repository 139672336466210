import React, { useContext, useMemo, useState } from 'react';
import { Button, Separator, Tooltip, Typography, toast } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { InvoiceLineItem, changeApprovedToPay, listInvoices } from '@v2/network/GreevilsGreedApi';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { BillStatusBadge } from '@v2/components/Bills/BillStatusBadge';
import useGetSupplier from '@v2/hooks/useGetSupplier.hook';
import { Order, getOrder } from '@v2/network/LegacyAPI';
import { UploadAsset } from '@v2/components/Uploads/UploadAsset/UploadAsset';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@v2/index';
import { UploadPDFModal } from '@v2/components/Uploads/UploadPDFModal/UploadPDFModal';
import { browserHistory } from 'react-router';
import { SyncStatusBillDetails } from '@v2/components/Bills/SyncStatusBillDetails';
import BackIcon from '@icons/back-icon.svg';
import { PayBillModal } from '@v2/components/Bills/PayBillModal';
import useRolesHook from '@v2/hooks/useRolesHook';
import { ERoleName } from '@v2/types/OrgData';
import { requestInvoicesApproval } from '@v2/network/CoreAPI';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@icons/check-icon.svg';
import { logError } from '@v2/utils/logError';
import { AccountData } from '@v2/types/AccountData';
import useAccount from '@v2/hooks/useAccount.hook';
import { useBillsStore } from '@v2/stores/BillsStore';
import { isInvoiceInClosedPeriod } from '@v2/utils/AccountingUtils';
import ImageUpload from '@/domains/OrderDetails/components/ImageUpload';
import { showInvoiceImagesModal } from '@/actions/orderInvoicesActions';
import ImagesModal from '@/components/shared/ImagesModal/ImagesModal';
import { useGetOcrInvoices } from '../Uploads/UploadsQueries.hook';
import Utils from '@/utils';
import { InvoicesPageContext } from './InvoicesPageContext';
import { PaymentDetails } from './PaymentDetails';

type BillDetailProps = {
    params: {
        billNumber: string,
    },
    location: {
        query: {
            [key: string]: string | undefined,
        },
    },
};

export interface IBillInfoSection {
    title: string,
    billInfo: React.ReactNode,
}

export const BillDetails: React.FC<BillDetailProps> = ({ params, location }) => {
    // Component data
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const [isPayBillOpen, setIsPayBillOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isApprovalRequested, setIsApprovalRequested] = useState(false);
    const [showPayTooltip, setShowPayTooltip] = useState<boolean>(false);
    const queryClient = useQueryClient();

    // Look up the user data
    const { hasRole } = useRolesHook();
    const { firstName, lastName }: AccountData = useAccount();

    // Look up the bill data
    const { buyer } = useBuyerHook();
    const supplierUrlsafeKey = location.query?.supplier;
    const { data: billResponse } = useQuery(
        ['FETCH_BILL_DATA', params.billNumber, supplierUrlsafeKey],
        () => listInvoices(1, buyer.urlsafe, { search: [params.billNumber], supplierUrlsafeKey }),
        {
            enabled: !!params.billNumber,
        }
    );
    const bill = billResponse?.results[0];
    const billNumber = bill?.invoiceNumber ?? params.billNumber;
    const billTotalInCents = bill?.invoiceTotal ?? 0;
    const billTotal = billTotalInCents / 100;
    const lineItems = bill?.lineItems;
    let billSubtotalInCents = 0;
    let billTaxInCents = 0;

    // Look up the supplier
    const { getSupplierByUrlsafeKey } = useGetSupplier();
    const supplierUrlsafe = bill?.supplierUrlsafeKey;
    const supplier = getSupplierByUrlsafeKey(supplierUrlsafe);
    const supplierName = supplier?.name;

    // Look up the order data
    const orderUrlsafe = bill?.orderUrlsafeKey;
    const { data: orderResponse } = useQuery<{ data: Order }>(
        ['FETCH_ORDER_NUMBER', orderUrlsafe],
        () => getOrder(orderUrlsafe),
        {
            enabled: !!orderUrlsafe
        }
    );
    const orderNumber = orderResponse?.data?.id;
    const orderAttachments = orderResponse?.data?.images;

    // Look up the uploaded attachment(s)
    const ocrInvoicesParams = {
        searchParams: {
            buyerUrlsafeKey: buyer?.urlsafe,
            externalReferenceID: billNumber
        },
        queryParams: {
            limit: 1,
        },
        queryOptions: {
            enabled: !!buyer?.urlsafe && !!billNumber,
        }
    };
    const { data: uploadInvoices } = useGetOcrInvoices(ocrInvoicesParams);
    const billAttachmentUrls = uploadInvoices?.results[0]?.cdnUrls;
    const hasBillAttachments = billAttachmentUrls?.length > 0;

    // The various subsections of the Basic Details section
    const billInfoSections:IBillInfoSection[] = [
        {
            title: tCommon('Labels.issueDate'),
            billInfo: Utils.formatDate(bill?.issuedDate),
        },
        {
            title: tCommon('Labels.dueDate'),
            billInfo: Utils.formatDate(bill?.dueDate),
        },
        {
            title: tCommon('Labels.supplier'),
            billInfo: (supplierName ?? '--'),
        },
        {
            title: tCommon('Labels.orderNumber'),
            billInfo: (orderNumber ? <Button variant="LINK"
                size="NO_BACKGROUND"
                className="p-0 mb-8 flex justify-left"
                onClick={async (): Promise<void> => {
                    browserHistory.push(`/order_detail/${orderUrlsafe}`);
                }}>
                {orderNumber}
            </Button> : '--'),
        },
        {
            title: tCommon('Labels.attachments'),
            billInfo: (hasBillAttachments ? <UploadAsset urls={billAttachmentUrls} invoiceNumber={billNumber} supplierName={supplierName} className="w-20 h-20 object-cover rounded-lg" />
                : <ImageUpload attachments={orderAttachments} generatedInvoice={bill}/>),
        },
    ];

    // Modal for displaying image attachments
    const { imagesModal } = useSelector((state: RootState) => state.orderInvoicesReducer);
    const dispatch = useDispatch();
    const onCloseInvoiceImagesModal = ():void => {
        dispatch(showInvoiceImagesModal(false));
    };

    // Determine if bill is eligible for payment
    const { getStripeStatus } = useBillsStore();
    const [stripeStatus, setStripeStatus] = useState(getStripeStatus(orderUrlsafe || bill?.id));
    const { isAccountingEnabled, accountingInfo } = useContext(InvoicesPageContext);

    const isReadyForApprove = useMemo(() => {
        const isUnpaid = bill?.amountPaid === 0;
        const hasNoStripeStatus = !stripeStatus;
        const hasInvoiceNumber = !!billNumber;
        const notInClosedAccountingPeriod = !(isAccountingEnabled && isInvoiceInClosedPeriod(bill, accountingInfo));
        return isUnpaid && hasNoStripeStatus && hasInvoiceNumber && notInClosedAccountingPeriod;
    }, [bill, stripeStatus]);

    const isReadyForPayment = useMemo(() => isReadyForApprove && bill?.approvedToPay, [bill, stripeStatus, isReadyForApprove]);

    // Allow the option to request approval for user roles who lack permission to make payments
    function requestApproval(): void {
        setIsLoading(true);
        requestInvoicesApproval(buyer.urlsafe, supplierUrlsafe, [billNumber], `${firstName} ${lastName}`).then(() => {
            setIsLoading(false);
            setIsApprovalRequested(true);
            toast.show({
                icon: <CheckIcon />,
                message: t('invoicesSentForApproval')
            });
        }).catch((error) => {
            logError('Failed to request invoice approval', error, true);
            setIsLoading(false);
        });
    }

    function onClickRetract(): void {
        setIsLoading(true);
        const invoiceNumbers = [bill?.id];

        changeApprovedToPay(buyer.urlsafe, invoiceNumbers, false).then(() => {
            queryClient.invalidateQueries(['FETCH_BILL_DATA', params.billNumber, supplierUrlsafeKey]);
            setIsLoading(false);
            toast.show({
                icon: <CheckIcon />,
                message: t('billsRetracted')
            });
        }).catch((error) => {
            logError('Failed to retract', error, true);
            setIsLoading(false);
        });
    }

    function onClickApprove(): void {
        setIsLoading(true);
        const invoiceNumbers = [bill?.id];
        changeApprovedToPay(buyer.urlsafe, invoiceNumbers, true).then(() => {
            queryClient.invalidateQueries(['FETCH_BILL_DATA', params.billNumber, supplierUrlsafeKey]);
            setIsLoading(false);
            toast.show({
                icon: <CheckIcon />,
                message: t('billsApproved')
            });
        }).catch((error) => {
            logError('Failed to approve', error, true);
            setIsLoading(false);
        });
    }

    return <div className="w-full h-full flex flex-col">
        {/* Navigation */}
        <div className="flex flex-row justify-between">
            <Button variant="ICON"
                size="ICON_SMALL"
                className="h-6 p-0 ml-10 mt-[38px]"
                onClick={(): void => browserHistory.goBack()}>
                <BackIcon className="w-4 h-4"/>
            </Button>
            <div className="pr-10 py-8 flex flex-row justify-between">
                <Button className="mr-2"
                    variant="SECONDARY"
                    hidden={!hasRole([ERoleName.OWNER, ERoleName.BOOKKEEPER], false) || !bill?.approvedToPay || (bill?.amountPaid !== 0) }
                    size="SMALL"
                    onClick={onClickRetract}
                    disabled={!isReadyForApprove}>
                    {t('retract')}
                </Button>
                <Button className="mr-2"
                    variant="SECONDARY"
                    hidden={!hasRole([ERoleName.OWNER, ERoleName.BOOKKEEPER], false) || bill?.approvedToPay || (bill?.amountPaid !== 0) }
                    size="SMALL"
                    onClick={onClickApprove}
                    disabled={!isReadyForApprove}>
                    {t('approve')}
                </Button>
                <Tooltip show={showPayTooltip && !isReadyForPayment && !bill?.approvedToPay && (bill?.amountPaid === 0)}
                    placement="bottom"
                    tooltipClassName="w-[170px]"
                    onShow ={(): void => { setShowPayTooltip(true); }}
                    onHide ={(): void => { setShowPayTooltip(false); }}
                    trigger={
                        <Button className="mr-2"
                            variant="SECONDARY"
                            size="SMALL"
                            hidden={!hasRole([ERoleName.OWNER, ERoleName.BOOKKEEPER, ERoleName.MANAGER], false) }
                            onClick={(): void => {
                                setIsPayBillOpen(true);
                            }}
                            disabled={!isReadyForPayment}>
                            {t('pay')}
                        </Button>}>
                        Bill needs to be approved for payment
                </Tooltip>
                <Button variant="SECONDARY"
                    size="SMALL"
                    hidden={!hasRole([ERoleName.MANAGER], false) }
                    onClick={requestApproval}
                    loading={isLoading}
                    disabled={isApprovalRequested || !isReadyForPayment}>
                    {t('requestApproval')}
                </Button>
            </div>
        </div>
        {/* Header */}
        <Typography className="pl-10 m-0 mb-2 text-gray-600">
            {`${tCommon('Labels.billNumber')}${billNumber}`}
        </Typography>
        <div className="flex flex-row">
            <Typography className="pl-10 pb-7 m-0" variant="4XL">
                {`${Utils.formatAsCurrency(billTotal)}`}
            </Typography>
            {!!bill && <div className="p-4"><BillStatusBadge invoice={bill} /></div>}
        </div>
        <Separator />
        {/* Line Items */}
        <div className="flex flex-row h-full">
            <div className="flex flex-col w-4/5 pb-16">
                <div className="pl-10">
                    <PaymentDetails invoice={bill} />
                </div>
                <Separator />
                <Typography className="pt-10 pl-10" variant="LG-2" weight="font-semibold">
                    {`${tCommon('Labels.items')}`}
                </Typography>
                <div className="flex gap-4 mr-8 pl-10">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    className="min-w-[72px] py-5 pr-2 text-left text-2 font-medium text-gray-600">
                                    {tCommon('Labels.quantity')}
                                </th>
                                <th
                                    scope="col"
                                    className="min-w-[120px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                    {tCommon('Labels.productName')}
                                </th>
                                <th
                                    scope="col"
                                    className="min-w-[90px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                    {tCommon('Labels.description')}
                                </th>
                                <th
                                    scope="col"
                                    className="min-w-[90px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                    {tCommon('Labels.unitPrice')}
                                </th>
                                <th
                                    scope="col"
                                    className="min-w-[60px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                    {tCommon('Labels.sku')}
                                </th>
                                <th
                                    scope="col"
                                    className="min-w-[90px] py-5 pl-2 text-right text-2 font-medium text-gray-600">
                                    {tCommon('Labels.estTotal')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {lineItems?.map((product: InvoiceLineItem, i: number) => {
                                const unitPriceInCents = product.pricePerUnit ?? 0;
                                const productTotalInCents = product.total ?? 0;
                                const productTaxInCents = product.tax ?? 0;
                                billSubtotalInCents += productTotalInCents;
                                billTaxInCents += productTaxInCents;
                                return (
                                    <tr key={i}
                                        className="border-b border-gray-200">
                                        <td className="py-5 pr-2 text-left text-2 font-regular">{parseFloat(`${product.quantity}`)}</td>
                                        <td className="py-5 px-2 text-left text-2 font-regular">{product.name}</td>
                                        <td className="py-5 px-2 text-left text-2 font-regular">{product.description}</td>
                                        <td className="py-5 px-2 text-left text-2 font-regular">{Utils.formatAsCurrency(unitPriceInCents / 100)}</td>
                                        <td className="py-5 px-2 text-left text-2 font-regular">{product.sku}</td>
                                        <td className="py-5 pl-2 text-right text-2 font-regular">{Utils.formatAsCurrency(productTotalInCents / 100)}</td>
                                    </tr>);
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="flex flex-row justify-end items-end mt-5 mr-8">
                    <div className="flex flex-col justify-end items-end mr-20">
                        <Typography className="mb-2 text-gray-600">{tCommon('Labels.subTotal')}</Typography>
                        <Typography className="mb-2 text-gray-600">{tCommon('Labels.tax')}</Typography>
                        <Typography className="mb-2 font-medium text-gray-700">{tCommon('Labels.estimatedTotal')}</Typography>
                    </div>
                    <div className="flex flex-col justify-end items-end">
                        <Typography className="mb-2" >{Utils.formatAsCurrency(billSubtotalInCents / 100)}</Typography>
                        <Typography className="mb-2">{Utils.formatAsCurrency(billTaxInCents / 100)}</Typography>
                        <Typography className="mb-2 font-semibold">{Utils.formatAsCurrency(billTotal)}</Typography>
                    </div>
                </div>
            </div>
            <Separator className="h-full w-[1px]" />
            {/* Basic Details */}
            <div className="flex flex-col w-1/5 mt-8 ml-8 mr-10 pb-16">
                <Typography className="mb-6" variant="LG-2" weight="font-semibold">{tCommon('Labels.basicDetails')}</Typography>
                {billInfoSections.map(({ title, billInfo }, i) => (
                    <div key={i}>
                        <Typography weight="font-medium" className="mb-2 text-gray-600">{title}</Typography>
                        <Typography className="mb-8" >{billInfo}</Typography>
                    </div>))}
                {/* Sync Status and Labels */}
                <SyncStatusBillDetails invoice={bill} />
            </div>
        </div>
        {/* Modals for displaying attachments */}
        <UploadPDFModal />
        <ImagesModal
            open={imagesModal.open}
            onClose={onCloseInvoiceImagesModal}
            title={
                <div className="truncate flex flex-col gap-1 min-w-0 w-full">
                    <div className="mb-1">{imagesModal.invoiceNumber}</div>
                    <div className="truncate">{imagesModal.vendorName}</div>
                </div>
            }
            slideShowView
            images={imagesModal.images?.map((image) => {
                const isAppEngineHosted = image.url.toLocaleLowerCase().indexOf('lh3.googleusercontent.com/') > -1;
                return {
                    ...image,
                    url: `${image.url}${isAppEngineHosted ? '=s1200' : ''}`
                };
            })}/>
        {/* Modal for paying bill */}
        {bill && <PayBillModal
            close={(): void => { setIsPayBillOpen(false); }}
            isOpen={isPayBillOpen}
            bills={[bill]}
            supplier={supplier}
            onClose={(): void => {
                // get latest Stripe status
                setStripeStatus(getStripeStatus(orderUrlsafe || bill?.id));
            }} />}
    </div>;
};

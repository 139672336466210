import PropTypes from "prop-types";
import React, { Component } from "react";
import Utils from "../../utils";
import { BUCKET_ASSETS_STATIC_URL } from "@v2/constants/Assets";

export default class ChangeUnitHeader extends Component {
  onImageError(event) {
    event.target.src = `${BUCKET_ASSETS_STATIC_URL}images/your-item-market-placeholder.png`;
  }

  render() {
    const { originalVariant, checkIfModalCanClose } = this.props;
    return (
      <div>
        <button
          onClick={checkIfModalCanClose}
          type="button"
          className="close pull-right"
        >
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div className="change-unit-image-title">
          <img
            src={
              originalVariant.genericItem
                ? originalVariant.genericItem.imageURL
                : Utils.getBackupImage(originalVariant.productCode)
            }
            onError={this.onImageError}
          />
          <span> {originalVariant.productName} </span>
        </div>
      </div>
    );
  }
}

ChangeUnitHeader.propTypes = {
  originalVariant: PropTypes.object.isRequired,

  checkIfModalCanClose: PropTypes.func.isRequired,
};

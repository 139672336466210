import { throwAxiosError } from '@v2/utils/AxiosUtils';
import axios, { AxiosResponse } from 'axios';
import Stripe from 'stripe';
import { OrderData } from '@v2/types/OrderData';
import { EStripeStatus } from '@v2/constants/EInvoiceStatus';
import { IPagination } from './types';

const { coreUrl } = process.env.BASE_URLS;

export type CreateSupplierData = {
    name: string,
    email: string,
    phone?: string,
};

export type AttachmentData = {
    id: string,
    supplierOnboardingID: string,
    buyerUrlsafeKey: string,
    attachmentURL: string,
    updatedAt: string,
    createdAt: string,
};

type CreateSupplierBody = {
    buyerUrlsafeKey: string,
    data: CreateSupplierData,
};

type UpdateSupplierBody = {
    buyerUrlsafeKey: string,
    supplierData: UpdateSupplierData,
    supplierUrlsafeKey: string,
    regionID: string,
};

export type IAddCreditCardPayload = {
    paymentMethodData?: Stripe.PaymentMethodCreateParams,
    buyerData:{
        email: string,
        name: string,
        id: string,
    },
    buyerUrlsafeKey: string,
    supplierUrlsafeKey?: string,
    invoices?: LegacyInvoiceData[],
};

type OperationWeekdayData = {
    enable?: boolean,
};

type OperationalDays = {
    sunday: OperationWeekdayData,
    monday: OperationWeekdayData,
    tuesday: OperationWeekdayData,
    wednesday: OperationWeekdayData,
    thursday: OperationWeekdayData,
    friday: OperationWeekdayData,
    saturday: OperationWeekdayData,
};
export type UpdateSupplierData = {
    name?: string,
    email?: string,
    phone?: string,
    defaultCutOffTime?: string,
    defaultSameDayCutOffTime?: string,
    defaultHasSameDayCutOffTime?: boolean,
    operationalDays?: OperationalDays,
    minimumOrderAmount?: number,
};

export type OnboardedSupplier = {
    name: string,
    email: string,
    address: AddressData,
    phone: string,
    paymentTerms: number,
    basisOfTerms: number,
    minimumOrderAmount: number,
    operationalDays: OperationalDays,
    defaultCutOffTime: string,
    fees: number,
    deliveryZones: string[],
    catalogueURL: string,
};

export type RegionSupplierData = {
    defaultCutOffTime?: string,
    defaultSameDayCutOffTime?: string,
    defaultHasSameDayCutOffTime?: boolean,
    operationalDays?: OperationalDays,
    minimumOrderAmount?: number,
};

export type AddressData = {
    name: string,
    line1: string,
    line2?: string,
    zip: string,
    city: string,
    state: string,
    country: string,
    geocode?: string,
    formatted?: string,
    driveInstructions?: string,
};

export type PayToAddress = {
    email: string,
    address: AddressData,
};

export type LegacyInvoiceData = {
    created_at?: string,
    orderSyncDetails?:InvoiceOrderSyncDetails,
    updated_at?: string,
    customer_id?: string,
    buyer_id?: string,
    order_number?: string,
    place_at?: string,
    delivery_date?: string,
    amount: number,
    receival_status?: string,
    supplier_reference_id?: string,
    images?: string[],
    id?: string,
    order_id?: string,
    invoice_number: string,
    invoice_status?: string,
    pdf_url?: string,
    vendor_id?: string,
    vendor_name?: string,
    vendor_type?: string,
    payment_method_id?: string,
    payment_method_type?: string,
    payment_method_last_4?: string,
    payment_method_brand?: string,
    external_id?: string,
    due_date?: string,
    last_attempt_error?: string,
    buyer_name?: string,
    buyer_address?: AddressData,
    shipping_address?: AddressData,
    gl_code_id?: string,
};

export type SupplierData = {
    customer?: {
        id: string,
    },
    email?: string,
    enableConnect?: boolean,
    id?: string,
    isBYOS?: boolean,
    isPayFacLite?: boolean,
    isTesting?: boolean,
    name?: string,
    primaryVendorId?: string,
    region?: Region,
    type?: string,
    urlsafe?: string,
    filesUploaded?: number,
    itemsCreated?: number,
};

export type Region = {
    cutOffTime: string,
    minimumOrderAmount: number,
    nextAvailableDeliveryDays: DeliveryDay[],
    operationalDays: {
        isActive: boolean, // NOTE: 'isActive' is actually named 'enable' in the backend
    }[],
};

export type DeliveryDay = {
    date: string,
    cutoffDate: string,
    dayOfWeek: string,
};

export type InvoiceOrderSyncDetails = {
    isSyncInProgress?: boolean,
    error?: string,
    lastSyncTime?: string,
    isTwoWayMatched?: boolean,
    isDraft?: boolean,
    isOffline?: boolean,
    order?: OrderData,
};

export type InvoicesApprovalResponse = {
    invoiceNumbers: string[],
    buyerUrlsafeKey: string,
    ownerEmails: string[],
};

export type SetupPaymentResponse = {
    paymentSetupComplete: boolean,
    stripeClientSecret?: string,
};

export enum EAcctingPymntStatus {
    PAID = 'paid',
    UNPAID = 'unpaid',
    REFUNDED = 'refunded',
    CHARGED = 'charged',
}

export enum EAcctingPymntMethod {
    CARD = 'card',
    CHECK = 'check'
}

/**
 * Note: this uses the PaymentSchema from bushwhack-api and is intended to use for accounting sync to bushwhack.
 */
export type InvoicePayment = {
    id: string, // payment id
    buyerId: string,
    supplierId: string,
    status: EAcctingPymntStatus,
    invoiceNumber: string,
    chargeDate: string,
    amount: number,
    paymentMethod: EAcctingPymntMethod,
    paymentMethodBrand?: number,
};

/**
 *
 * @param buyerUrlsafeKey - Urlsafe Key of the buyer to remove the supplier catalog from
 */
export async function createSupplier(buyerUrlsafeKey: string, supplierData: CreateSupplierData): Promise<SupplierData> {
    const url = `${coreUrl}/onboardingSuppliers`;
    const body: CreateSupplierBody = {
        buyerUrlsafeKey,
        data: supplierData
    };
    const response: AxiosResponse<SupplierData> = await axios.post(url, body)
        .catch((e) => throwAxiosError(e, 'Could not create supplier'));

    return response.data;
}

export async function getSupplierRegionData(supplierUrlsafeKey: string): Promise<RegionSupplierData> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierUrlsafeKey}/regions`;
    const response: AxiosResponse<RegionSupplierData> = await axios.get(url)
        .catch((e) => throwAxiosError(e, 'Could not get supplier region data'));

    return response.data;
}

export async function updateSupplier(buyerUrlsafeKey: string, updateSupplierData: UpdateSupplierData, supplierUrlsafeKey: string, regionID: string, supplierOnboardingID: string): Promise<UpdateSupplierData> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierOnboardingID}/update`;
    const body: UpdateSupplierBody = {
        buyerUrlsafeKey,
        supplierUrlsafeKey,
        regionID,
        supplierData: updateSupplierData
    };
    const response: AxiosResponse<UpdateSupplierData> = await axios.patch(url, body)
        .catch((e) => throwAxiosError(e, 'Could not update supplier'));

    return response.data;
}

export async function getSupplierAttachments(supplierUrlsafeKey): Promise<AttachmentData[]> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierUrlsafeKey}/attachments`;
    const response: AxiosResponse<AttachmentData[]> = await axios.get(url)
        .catch((e) => throwAxiosError(e, 'Could not get supplier attachments'));

    return response.data;
}

export async function uploadSupplierAttachments(buyerUrlsafeKey: string, supplierUrlsafeKey: string, attachment: File): Promise<AttachmentData> {
    const url = `${coreUrl}/buyerOnboardingSuppliers/${buyerUrlsafeKey}/suppliers/${supplierUrlsafeKey}/attachments`;
    const body = new FormData();
    body.append('catalog', attachment);
    const response: AxiosResponse<AttachmentData> = await axios.post(url, body)
        .catch((e) => throwAxiosError(e, 'Could not upload supplier attachments'));

    return response.data;
}

export async function deleteSupplierAttachment(supplierUrlsafeKey: string, fileName: string): Promise<void> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierUrlsafeKey}/attachments/${encodeURI(fileName)}`;
    const response: AxiosResponse<void> = await axios.delete(url)
        .catch((e) => throwAxiosError(e, 'Could not delete supplier attachment'));

    return response.data;
}

export async function emailSupplier(supplierOnboardingID: string, buyerName: string, buyerEmail: string): Promise<void> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierOnboardingID}/onboardingEmail`;
    let email = buyerEmail;

    if (!email || email === '') {
        email = 'unknown@unknown.com';
    }

    const body = {
        data: {
            buyerName,
            buyerEmail: email
        }
    };

    const response: AxiosResponse<void> = await axios.post(url, body)
        .catch((e) => throwAxiosError(e, 'Could not send email to supplier'));

    return response.data;
}

export async function getSupplierID(supplierUrlsafeKey: string): Promise<string> {
    const url = `${coreUrl}/onboardingSuppliers?supplierUrlsafeKey=${supplierUrlsafeKey}`;
    const response: AxiosResponse<string> = await axios.get(url)
        .catch((e) => throwAxiosError(e, 'Could not get supplier id', false));

    return response.data;
}

export async function getOnboardedSupplier(supplierOnboardingID: string): Promise<OnboardedSupplier> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierOnboardingID}`;
    const response: AxiosResponse<OnboardedSupplier> = await axios.get(url)
        .catch((e) => throwAxiosError(e, 'Could not get supplier'));

    return response.data;
}

type RemoveBuyerFromSupplierParams = {
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
};

/**
 * Remove buyer from supplier.
 */
export async function removeBuyerFromSupplier({
    buyerUrlsafeKey,
    supplierUrlsafeKey
}:RemoveBuyerFromSupplierParams): Promise<void> {
    const url = `${coreUrl}/buyerOnboardingSuppliers/${buyerUrlsafeKey}/suppliers/${supplierUrlsafeKey}`;
    const response: AxiosResponse<void> = await axios.delete(url)
        .catch((e) => throwAxiosError(e, 'Could not remove buyer'));

    return response.data;
}

export async function setupPayment(payload: IAddCreditCardPayload): Promise<SetupPaymentResponse> {
    const url = `${coreUrl}/setupPayment`;
    const response: AxiosResponse<SetupPaymentResponse> = await axios.post(url, payload)
        .catch((error) => {
            throw error?.response ?? error;
        });

    return response.data;
}

export enum EPaymentType {
    CARD = 'card',
    PRE_AUTHORIZED_DEBIT = 'acss_debit'
}

export type PaymentMethod = {
    id: string, // Stripe's ID,
    type: EPaymentType,
    last4: string, // Last four digits of the bank account number or credit card
    stripeCustomerId: string,
    stripeSupplierAccountId: string,
};

export type PaymentMethodsResponse = {
    paymentMethods: PaymentMethod[],
};

/**
 * Gets all payment methods between this buyer and supplier on Stripe.
 *
 * @param buyerUrlsafeKey buyer's urlsafe key
 * @param supplierUrlsafeKey supplier's urlsafe key
 * @param invoices Invoice data
 * @returns array of payment methods
 */
export async function getPaymentMethods(buyerUrlsafeKey: string, supplierUrlsafeKey: string, invoices?: LegacyInvoiceData[]): Promise<PaymentMethod[]> {
    const url = `${coreUrl}/paymentMethods`;
    const response: AxiosResponse<PaymentMethodsResponse> = await axios.get(url, { params: {
        buyerUrlsafeKey,
        supplierUrlsafeKey,
        invoices
    } })
        .catch((error) => {
            throw error?.response ?? error;
        });

    return response.data.paymentMethods;
}

export async function requestInvoicesApproval(buyerUrlsafeKey: string, supplierUrlsafeKey: string, invoiceNumbers: string[], accountName: string): Promise<InvoicesApprovalResponse> {
    const url = `${coreUrl}/requestInvoicesApproval`;
    const response: AxiosResponse<InvoicesApprovalResponse> = await axios.post(url, { buyerUrlsafeKey, supplierUrlsafeKey, invoiceNumbers, accountName })
        .catch((e) => throwAxiosError(e));
    return response.data;
}

export type ProcessStripePaymentResponse = {
    results: Array<{
        success: boolean,
        invoiceId: string, // orderUrlsafeKey | offline invoice UUID
    }>,
};

export type ProcessStripePaymentInvoice = {
    amount: number,
    invoice_number: number,
    id: string,
    items: [],
    vendor_name: string,
    pdf_url?: string,
};

export type ProcessStripePaymentRequest = {
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
    invoices: LegacyInvoiceData[],
    stripeCustomerID: string,
    stripeSupplierAccountID: string,
    stripePaymentMethodID: string,
};

export async function processStripePayment(body: ProcessStripePaymentRequest): Promise<ProcessStripePaymentResponse> {
    const url = `${coreUrl}/processStripePayment`;
    const response: AxiosResponse<ProcessStripePaymentResponse> = await axios.post(url, body).catch((error) => {
        throw error?.response ?? error;
    });

    return response.data;
}

export type StripeInvoice = {
    id: string, // Will always match invoice.id from be-billing
    supplierUrlsafeKey: string,
    stripeID?: string,
    stripeStatus?: EStripeStatus,
};

export type ExternalInvoicesResponse = IPagination & {
    results: StripeInvoice[],
};

export async function fetchExternalInvoices(limit: number, buyerUrlsafeKey: string, cursor?: string): Promise<ExternalInvoicesResponse> {
    const url = `${coreUrl}/externalInvoices`;
    const response: AxiosResponse<ExternalInvoicesResponse> = await axios.get(url, { params: {
        limit,
        buyerUrlsafeKey,
        cursor
    } }).catch((error) => {
        throw error?.response ?? error;
    });

    return response.data;
}

type CreateOfflineSupplierParams = {
    buyerUrlsafeKey: string,
    supplierName: string,
    supplierEmail: string,
};

type CreateOfflineSupplierResponse = {
    supplierUrlsafeKey: string,
};
export async function createOfflineSupplier({
    supplierName,
    supplierEmail,
    buyerUrlsafeKey
}:CreateOfflineSupplierParams): Promise<CreateOfflineSupplierResponse> {
    const url = `${coreUrl}/buyers/${buyerUrlsafeKey}/offlineSuppliers`;
    const response: AxiosResponse = await axios.post(url, { name: supplierName, email: supplierEmail })
        .catch((e) => throwAxiosError(e));

    return response.data;
}

export async function getSupplierPayToAddress(supplierUrlsafeKey: string): Promise<PayToAddress> {
    const url = `${coreUrl}/onboardingSuppliers/${supplierUrlsafeKey}/supplierData`;
    const response: AxiosResponse = await axios.get(url)
        .catch((e) => throwAxiosError(e, 'Could not get supplier address'));
    return {
        email: response.data.vendor?.email,
        address: response.data.vendor?.address,
    };
}

import React from 'react';
import { ScanReviewImage } from '@v2/components/ScanReview/ScanReviewImage/ScanReviewImage';
import { ScanReviewPDF } from '@v2/components/ScanReview/ScanReviewPDF/ScanReviewPDF';

import { useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { usePdfData } from '@v2/hooks/usePdfData.hook';

export const ScanReviewAsset : React.FC = () => {
    const { draftInvoice: { rawInvoiceData } } = useOcrInvoiceStore();
    const urls = rawInvoiceData?.cdnUrls ?? [];
    return (
        <div>
            {urls.map((url, index) => {
                const { pdfData, pdfBytesArray } = usePdfData(url);

                if (url.toLowerCase().includes('.pdf') && pdfData) {
                    return <ScanReviewPDF
                        url={url}
                        pdfData={pdfData}
                        pdfBytesArray={pdfBytesArray}
                        key={index}/>;
                }
                return <ScanReviewImage url={url} pageNumber={index + 1} totalPages={urls.length} key={index}/>;
            })}
        </div>
    );
};

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const ReceivingToolFooter = ({ text, onClick, className, disabled, loading }) => {
  return (
    <div className="od-footer">
      <button
        className={cx({
          [`${className}`]: true,
          'disable-button': disabled,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? <i
          style={{ fontSize: '16px', color: 'white' }}
          className="spin-spinner fa fa-circle-o-notch"
        /> : <span>{text}</span>}
      </button>
    </div>
  );
};

ReceivingToolFooter.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
};

ReceivingToolFooter.defaultProps = {
  loading: false
};

export default ReceivingToolFooter;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'redux-form';

import renderField from '../../redux-form/renderField';

export default class GoogleAddressAutoComplete extends Component {
  state = {
    fullAddress: '',
    autoComplete: '',
    inputValue: this.props.value,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ inputValue: nextProps.value });
  }

  initGoogleMapsAPI = () => {
    const { id, autoCompleteType } = this.props;

    //Determines the type, as well as the restrictions of countries to display on Autocomplete
    const options = {
      types: autoCompleteType,
      componentRestrictions: { country: ['ca', 'us'] },
    };

    // Sets up google places autocomplete.
    const initAutocomplete = () => {
      // Create the autocomplete object, restricting the search to geographical
      // location types.
      const autoComplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (document.getElementById(id)),
        options
      );

      // When the user selects an address from the dropdown, populate the address
      // fields in the form.
      autoComplete.addListener('place_changed', this.fillInAddress);
      this.setState({ autoComplete });
    };

    // Load google places libarary script tag if it is not already loaded.
    // If it's already loaded then we call initAutoComplete to set up the autocomplete.
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      const script = document.createElement('script');
      script.src = `//maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_PARAMS.key}&libraries=places`;
      script.async = 1;
      script.onload = () => {
        initAutocomplete();
      };

      document.body.appendChild(script);
    } else {
      initAutocomplete();
    }
  };

  handleChange = (event) => {
    this.setState({ inputValue: event.target.value });
  };

  fillInAddress = () => {
    const { autoCompleteType, onAutoComplete, id } = this.props;

    // Get the place details from the autocomplete object.
    const autoComplete = this.state.autoComplete;
    const place = autoComplete.getPlace();
    let inputValue = '';

    if (!_.isEmpty(place) && autoCompleteType[0] === 'establishment') {
      const placeNameOnly = `${place ? place.name : ''}`;
      inputValue = placeNameOnly;
      onAutoComplete(placeNameOnly, id);
    } else if (!_.isEmpty(place) && autoCompleteType[0] === 'geocode') {
      // check place.address_components is valid (it should be an array).
      if (place.address_components) {
        place.address_components.forEach((component) => {
          if (component) {
            if (component.types.includes('street_number')) {
              inputValue = component.long_name;
            } else if (component.types.includes('route')) {
              inputValue = `${inputValue} ${component.long_name}`;
            }
          }
        });
      }

      onAutoComplete(place, id);
    }

    this.setState({ inputValue, place });
  };

  // Gets triggered before fillInAddress
  // We need to call this because the user might not use autoComplete
  onBlur = () => {
    const { onAutoComplete, id } = this.props;

    onAutoComplete(this.state.inputValue, id);
  };

  render() {
    const { placeholder, className, id, label } = this.props;

    return (
      <div id="locationField">
        <Field
          id={id}
          name={id}
          className={className}
          label={label}
          placeholder={placeholder || ''}
          type="text"
          required="true"
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onFocus={this.initGoogleMapsAPI}
          value={this.state.inputValue}
          component={renderField}
        />
      </div>
    );
  }
}

GoogleAddressAutoComplete.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoCompleteType: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,

  onAutoComplete: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';

// Needs to be imported for functionality like dropdown-toggle to work
import 'bootstrap/dist/js/bootstrap.min.js';

import CartItemNotification from '../../components/cart/cart-item-notification';

import * as Intercom from '../../third-party/Intercom';
import { initLibrariesNotRequiringLogin } from '../../helpers/initThirdPartyLibraries';
import withUseIsMobileWidth from '../../domains/shared/hooks/withUseIsMobileWidth';
import {
  addBackButtonAndroidListener,
  addStatusBarTapEventListener,
} from '../../helpers/common/mobile';

class AppWrapper extends Component {
  state = {
    isMobileWidth: false,
  };

  onResizeWindow = () =>
    this.setState({
      isMobileWidth: this.props.isMobileWidth,
    });

  componentDidMount() {
    // Set state, init libraries, init Intercom and setup resize event
    const {
      location: { pathname = '' },
      isMobileWidth,
    } = this.props;
    if (isMobileWidth) {
      this.setState({ isMobileWidth: true });
    }
    Intercom.init();
    initLibrariesNotRequiringLogin();
    // adding listener to check if the window is resized
    window.addEventListener('resize', this.onResizeWindow);
    addBackButtonAndroidListener();
    addStatusBarTapEventListener();
  }

  render() {
    const { cartItemNotification, online = true, location } = this.props;
    return (
      <div>
        <div className="app-wrapper">
          {cartItemNotification.isVisible && (
            <CartItemNotification {...cartItemNotification} />
          )}

          <div className="app-content">{this.props.children}</div>
          <ReduxToastr
            closeButton={true}
            debug={false}
            progressBar={false}
            preventDuplicates={true}
            positionClass="toast-top-right"
            showDuration={400}
            hideDuration={1000}
            timeOut={4000}
            extendedTimeOut={1000}
            showEasing="swing"
            hideEasing="linear"
            showMethod="fadeIn"
            hideMethod="fadeOut"
          />
        </div>
      </div>
    );
  }
}

AppWrapper.propTypes = {
  online: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  cartItemNotification: PropTypes.shape({
    isVisible: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

function mapStatetoProps(state) {
  const {
    cartReducer: { cartItemNotification },
  } = state;

  return {
    ...state.offline,
    cartItemNotification,
  };
}

export default compose(
  connect(mapStatetoProps, null),
  withUseIsMobileWidth
)(AppWrapper);

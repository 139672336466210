import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Utils from '../../utils';

class CheckPermission extends Component {
  // Checks the given 'roles' (prop) against the default buyer role
  // list and renders the children only if the role is present.

  getUserRole = () => {
    const { buyer, org } = this.props;
    const defaultBuyer = JSON.parse(
      window.localStorage.getItem('chefhero_defb')
    );
    const currentBuyer = buyer;

    // If the following conditions are not met and error will be displayed:
    // 1) If default buyer is not set OR,
    // 2) The default buyer doesn't contain any role OR,
    // 3) The buyer in the reducer does not exist in the list of buyers for the account
    if (
      _.isEmpty(defaultBuyer) ||
      defaultBuyer.roles.length === 0 ||
      (org.length > 0 &&
        !org.map((o) => o.buyerKey).includes(currentBuyer.urlsafe))
    ) {
      Utils.trackException(
        !_.isEmpty(defaultBuyer) && defaultBuyer.roles.length === 0,
        'An Error occured with CheckPermission: Invalid default buyer, missing buyer member role.'
      );
      return '';
    }

    return defaultBuyer.roles[0].name;
  };

  hasPermission = () => {
    const { roles } = this.props;
    return roles.includes(this.getUserRole());
  };

  render() {
    if (!this.hasPermission()) {
      return null;
    }
    return this.props.children;
  }
}

CheckPermission.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.node.isRequired,
};

function mapStatetoProps(state) {
  return {
    ...state.accountReducer,
    ...state.buyerReducer,
  };
}

export default connect(mapStatetoProps, null)(CheckPermission);

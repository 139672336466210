import FileIcon from '@icons/file-filled-icon.svg';
import { Button, Loading, Typography } from '@notch-ordering/ui-components';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { getInvoicePDF } from '@v2/network/GreevilsGreedApi';
import React, { useState } from 'react';
import Utils from '@/utils';

type Props = {
    id: string,
    invoiceNumber: string,
    total: number,
};

export const PayBillModalInvoice : React.FC<Props> = ({
    id,
    invoiceNumber,
    total
}) => {
    const { buyer } = useBuyerHook();
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
                <Button variant="TERTIARY_FILLED"
                    size="ICON_MEDIUM"
                    className="p-0 w-8 h-8 rounded-lg flex items-center justify-center"
                    onClick={(): void => {
                        setIsLoading(true);
                        getInvoicePDF(id, buyer.urlsafe)
                            .finally(() => setIsLoading(false));
                    }}>
                    { isLoading ? <Loading isDark /> : <FileIcon className=" text-gray-600 w-5 h-5" /> }
                </Button>
                <Typography as="div">#{invoiceNumber}</Typography>
            </div>
            <Typography as="div" className="text-gray-600">{Utils.formatAsCurrency(total)}</Typography>
        </div>);
};

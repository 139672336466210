import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const OrderItemsToggle = ({ itemsCount, toggleItems, isOpen }) => {
  return (
    <div
      className={cx('order-items-toggle', { open: isOpen })}
      onClick={toggleItems}
    >
      <span>
        {isOpen ? 'Hide' : 'View'}&nbsp;
        {itemsCount > 1 ? `${itemsCount} items`: 'item'}
        <i className="fa fa-chevron-down" />
      </span>
    </div>
  );
};

OrderItemsToggle.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  toggleItems: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default OrderItemsToggle;

import CheckIcon from '@icons/check-icon.svg';
import InfoIcon from '@icons/info-icon.svg';
import { Button, Input, Modal, toast, Typography, Separator, RadioTile, Checkbox, Tooltip, onMobileSetLarge } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { getOnboardedSupplier, getSupplierID, getSupplierRegionData, RegionSupplierData, updateSupplier, UpdateSupplierData } from '@v2/network/CoreAPI';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { isInvalidEmail, isInvalidPhoneNumber, isInvalidString } from '@v2/utils/FormValidation';
import React, { useState, useEffect } from 'react';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { format } from 'date-fns-tz';
import { parse } from 'date-fns';
import { isValid } from '@v2/utils/isValid';
import { Combobox, comboboxTimeCutoff, ComboOption } from '@v2/Pages/Home/components/Combobox';
import { logException } from '@/domains/shared/logger';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';

export enum Weekdays {
    sunday = 'sunday',
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday'
}

enum Tab {
    GENERAL,
    DELIVERY
}

type OperationWeekdayData = {
    enable?: boolean,
};

type OperationalDays = {
    sunday: OperationWeekdayData,
    monday: OperationWeekdayData,
    tuesday: OperationWeekdayData,
    wednesday: OperationWeekdayData,
    thursday: OperationWeekdayData,
    friday: OperationWeekdayData,
    saturday: OperationWeekdayData,
};

type Props = {
    isOpen: boolean,
    close: () => void,
    supplierUrlsafeKey?: string,
    onSave?: () => void,
};

type FormErrors = {
    network?: boolean,
    email?: boolean,
    phone?: boolean,
    minimumOrderAmount?: boolean,
};

type SupplierFormData = {
    name: string,
    email: string,
    phone: string,
};

type DeliveryFormData = {
    defaultCutOffTime: string,
    defaultHasSameDayCutOffTime: boolean,
    defaultSameDayCutOffTime: string,
    minimumOrderAmount: number,
    operationalDays: OperationalDays,
};

 type OnboardedSupplier = {
     buyerID: string,
     catalogueURL: string,
     email: string,
     id: string,
     isOnboardingCompleted: boolean,
     name: string,
     OnboardingURL: string,
     phone: string,
     regionID: string,
     supplierUrlsafeKey: string,
 };

const initialDeliveryFormData = {
    defaultCutOffTime: '',
    defaultHasSameDayCutOffTime: false,
    defaultSameDayCutOffTime: '',
    minimumOrderAmount: 0,
    operationalDays: {
        monday: { enable: false },
        tuesday: { enable: false },
        wednesday: { enable: false },
        thursday: { enable: false },
        friday: { enable: false },
        saturday: { enable: false },
        sunday: { enable: false }
    }
};

const initialSupplierFormData = {
    name: '',
    email: '',
    phone: '',
};

// Modal that appears when choosing Edit Details popover option on supplier card.

export const EditSupplierModal: React.FC<Props> = ({
    isOpen = false,
    close,
    supplierUrlsafeKey,
    onSave
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.EditSupplierModal' });
    const radioTiles: { id: number, value: Weekdays, label: string }[] = [{ id: 1, value: Weekdays.monday, label: 'Mon' },
        { id: 2, value: Weekdays.tuesday, label: 'Tue' },
        { id: 3, value: Weekdays.wednesday, label: 'Wed' },
        { id: 4, value: Weekdays.thursday, label: 'Thu' },
        { id: 5, value: Weekdays.friday, label: 'Fri' },
        { id: 6, value: Weekdays.saturday, label: 'Sat' },
        { id: 7, value: Weekdays.sunday, label: 'Sun' }];

    const [supplierFormData, setSupplierFormData] = useState<SupplierFormData>(initialSupplierFormData);
    const [deliveryFormData, setDeliveryFormData] = useState<DeliveryFormData>(initialDeliveryFormData);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [currentDeliveryData, setCurrentDeliveryData] = useState(null);
    const [currentSupplierData, setCurrentSupplierData] = useState(null);
    const [tab, setTab] = useState<Tab>(Tab.GENERAL);
    const [standardToolTip, setStandardToolTip] = useState<boolean>(false);
    const [sameDayToolTip, setSameDayToolTip] = useState<boolean>(false);
    const [defaultTimeValue, setDefaultTimeValue] = useState<string>('');
    const [defaultTimeLabel, setDefaultTimeLabel] = useState<string>('');
    const [sameDayTimeValue, setSameDayTimeValue] = useState<string>('');
    const [sameDayTimeLabel, setSameDayTimeLabel] = useState<string>('');
    const { buyer } = useBuyerHook();
    const [isLoading, setIsLoading] = useState(true);
    const queryClient = useQueryClient();

    const { data: supplierID } = useQuery(['getSupplierID', supplierUrlsafeKey], () => getSupplierID(supplierUrlsafeKey), {
        enabled: !!supplierUrlsafeKey && isOpen,
        retry: 2,
        onError: () => {
            if (isOpen) {
                close();
                toast.show({
                    message: `${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`,
                    icon: <InfoIcon />,
                    showClose: false
                });
            }
        }
    });

    const { data: supplier } = useQuery(['fetchingOnboardedSupplierForEditModal', supplierID], () => getOnboardedSupplier(supplierID), {
        enabled: !!supplierID && !currentSupplierData && isOpen,
        onSuccess: (data: OnboardedSupplier) => {
            setCurrentSupplierData(data);
            setSupplierFormData({ name: `${data?.name}`,
                email: `${data?.email}`,
                phone: `${data?.phone}` });
        }
    });
    useQuery(['fetchingRegionDataForEditModal', supplierUrlsafeKey], () => getSupplierRegionData(supplierUrlsafeKey), {
        enabled: !!supplierUrlsafeKey && !currentDeliveryData,
        onSuccess: (data: RegionSupplierData) => {
            const region = data?.[0];
            setCurrentDeliveryData(region);

            let cutOffTimeValue = '';
            if (region?.defaultCutOffTime) {
                const cutOffTimeDate: Date = parse(`${region?.defaultCutOffTime}Z`, 'HH:mm:ssX', new Date());
                cutOffTimeValue = format(cutOffTimeDate, 'HH:mm:ss');
                setDefaultTimeValue(cutOffTimeValue);
                setDefaultTimeLabel(format(cutOffTimeDate, 'h:mm a'));
            }
            let sameDayCutOffTimeValue = '';
            if (region?.defaultSameDayCutOffTime) {
                const sameDayCutOffTimeDate: Date = parse(`${region?.defaultSameDayCutOffTime}Z`, 'HH:mm:ssX', new Date());
                sameDayCutOffTimeValue = format(sameDayCutOffTimeDate, 'HH:mm:ss');
                setSameDayTimeValue(sameDayCutOffTimeValue);
                setSameDayTimeLabel(format(sameDayCutOffTimeDate, 'h:mm a'));
            }
            setDeliveryFormData({ ...deliveryFormData,
                defaultCutOffTime: cutOffTimeValue,
                defaultHasSameDayCutOffTime: region?.defaultHasSameDayCutOffTime,
                defaultSameDayCutOffTime: sameDayCutOffTimeValue,
                minimumOrderAmount: region?.minimumOrderAmount,
                operationalDays: {
                    monday: { enable: region?.operationalDays[Weekdays.monday].enable },
                    tuesday: { enable: region?.operationalDays[Weekdays.tuesday].enable },
                    wednesday: { enable: region?.operationalDays[Weekdays.wednesday].enable },
                    thursday: { enable: region?.operationalDays[Weekdays.thursday].enable },
                    friday: { enable: region?.operationalDays[Weekdays.friday].enable },
                    saturday: { enable: region?.operationalDays[Weekdays.saturday].enable },
                    sunday: { enable: region?.operationalDays[Weekdays.sunday].enable }
                } });
        }
    });
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';

    useEffect(() => {
        if (tab === Tab.GENERAL) {
            setIsLoading(!currentSupplierData);
        } else if (tab === Tab.DELIVERY) {
            setIsLoading(!currentDeliveryData);
        }
    }, [currentSupplierData, currentDeliveryData, tab]);
    function isInvalidPositiveNumber(val: number): boolean {
        return !(val !== null && val !== undefined && !Number.isNaN(val) && Number(val) > -1);
    }

    function refreshTab(): void {
        setCurrentSupplierData(null);
        setCurrentDeliveryData(null);
        setFormErrors({});
        setSupplierFormData(initialSupplierFormData);
        setDeliveryFormData(initialDeliveryFormData);
        queryClient.invalidateQueries(['fetchingOnboardedSupplierForEditModal', supplierID]);
        queryClient.invalidateQueries(['fetchingRegionDataForEditModal', supplierUrlsafeKey]);
    }

    function onClose(): void {
        refreshTab();
        close();
    }

    function supplierOnSubmit(e): void {
        e.preventDefault();
        if (supplierFormData && currentDeliveryData) {
            setIsLoading(true);
            const errors = { ...formErrors };

            errors.network = false;
            errors.email = isInvalidString(supplierFormData.email) || isInvalidEmail(supplierFormData.email);
            errors.phone = !isInvalidString(supplierFormData.phone) && isInvalidPhoneNumber(supplierFormData.phone);

            setFormErrors(errors);

            const payload: UpdateSupplierData = {
                ...supplierFormData,
                defaultCutOffTime: isValid(currentDeliveryData.defaultCutOffTime) ? currentDeliveryData.defaultCutOffTime : undefined,
                defaultSameDayCutOffTime: isValid(currentDeliveryData.defaultSameDayCutOffTime) ? currentDeliveryData.defaultSameDayCutOffTime : undefined,
                defaultHasSameDayCutOffTime: currentDeliveryData.defaultHasSameDayCutOffTime,
                minimumOrderAmount: currentDeliveryData.minimumOrderAmount
            };

            if (payload.phone === '') {
                payload.phone = undefined;
            }

            if (!(errors.email || errors.phone)) {
                updateSupplier(buyer.urlsafe, payload, supplier.supplierUrlsafeKey, supplier.regionID, supplierID)
                    .then(() => {
                        setIsLoading(false);
                        toast.show({
                            message: <>{t('editedSupplier')} <span className="font-semibold">{supplier.name}</span></>,
                        });
                        setCurrentSupplierData(null);
                        if (onSave) {
                            onSave();
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        logException(error);
                        setFormErrors({ network: true });
                    });
            } else {
                setIsLoading(false);
            }
        }
    }

    function deliveryOnSubmit(e): void {
        e.preventDefault();
        if (deliveryFormData && currentSupplierData) {
            setIsLoading(true);
            const errors = { ...formErrors };

            errors.network = false;
            errors.minimumOrderAmount = isInvalidPositiveNumber(deliveryFormData.minimumOrderAmount);

            setFormErrors(errors);
            const cutOffTimePayload: Date = parse(`${deliveryFormData.defaultCutOffTime}`, 'HH:mm:ss', new Date());
            const cutOffTimePayloadFormat = `${cutOffTimePayload.getUTCHours()}:${cutOffTimePayload.getUTCMinutes()}:00`;

            const payload = { ...deliveryFormData,
                defaultCutOffTime: cutOffTimePayloadFormat,
                name: currentSupplierData.name,
                email: currentSupplierData.email,
                phone: currentSupplierData.phone !== '' ? currentSupplierData.phone : undefined };

            if (deliveryFormData.defaultHasSameDayCutOffTime && isValid(deliveryFormData.defaultSameDayCutOffTime)) {
                const sameDayCutOffTimePayload: Date = parse(`${deliveryFormData.defaultSameDayCutOffTime}`, 'HH:mm:ss', new Date());
                const sameDayCutOffTimePayloadFormat = `${sameDayCutOffTimePayload.getUTCHours()}:${sameDayCutOffTimePayload.getUTCMinutes()}:00`;
                payload.defaultSameDayCutOffTime = sameDayCutOffTimePayloadFormat;
            } else {
                payload.defaultSameDayCutOffTime = undefined;
            }

            if (!(errors.minimumOrderAmount)) {
                updateSupplier(buyer.urlsafe, payload, supplier.supplierUrlsafeKey, supplier.regionID, supplierID)
                    .then(() => {
                        setIsLoading(false);
                        toast.show({
                            message: <>{t('editedSupplier')} <span className="font-semibold">{supplier.name}</span></>,
                            icon: <CheckIcon />,
                            showClose: false
                        });
                        setCurrentSupplierData(null);
                        if (onSave) {
                            onSave();
                        }
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        logException(error);
                        setFormErrors({ network: true });
                    });
            } else {
                setIsLoading(false);
            }
        }
    }
    return <Modal
        isOpen={isOpen}
        close={onClose}
        modalSize={onMobileSetLarge('MEDIUM')}
        title={<>
            <Typography className="text-gray-700 lg:mb-3" variant="XL" desktopSize="lg:text-5" weight="font-semibold">
                {t('editSupplierDetails')}
            </Typography>
        </>}>
        <div className="flex items-center p-0 flex-row justify-start lg:mx-8 ml-2 m-0 w-auto gap-x-4">
            <Button
                variant="TEXT"
                size="NO_BACKGROUND"
                heightPadding="pt-1 pb-3"
                widthPadding="lg:px-0 px-2"
                className="w-fit m-0"
                onClick={(): void => {
                    setTab(Tab.GENERAL);
                    refreshTab();
                }}
                disabled={!supplierID}>
                <Typography as="span" weight={tab === Tab.GENERAL ? 'font-semibold' : 'font-medium'}>
                    {tCommon('Buttons.general')}
                </Typography>
            </Button>
            <Button
                variant="TEXT"
                size="NO_BACKGROUND"
                heightPadding="pt-1 pb-3"
                widthPadding="px-0"
                className="w-fit m-0"
                onClick={(): void => {
                    setTab(Tab.DELIVERY);
                    refreshTab();
                }}
                disabled={!supplierID}>
                <Typography as="span" weight={tab === Tab.DELIVERY ? 'font-semibold' : 'font-medium'}>
                    {tCommon('Buttons.deliveryInformation')}
                </Typography>
            </Button>
        </div>
        {tab === Tab.GENERAL ? <div className="lg:ml-8 lg:w-14 ml-4 w-16 h-1 bg-gray-700" /> : <div className="lg:ml-[98px] lg:w-36 ml-[100px] w-40 h-1 bg-gray-700" />}
        <Separator />
        <>
            {tab === Tab.GENERAL ? <form onSubmit={supplierOnSubmit} noValidate>
                <div className="flex flex-col lg:block my-6 mx-4 gap-y-6 lg:mx-8 lg:mt-6 lg:mb-8 lg:items-start">
                    <div className="flex flex-col w-full">
                        <Input
                            className="m-0 w-full"
                            label={tCommon('Labels.name')}
                            type="TEXT"
                            value={supplierFormData.name}
                            onChange={null}
                            disabled
                            required/>
                    </div>
                    <div className="flex flex-col lg:flex-row lg:justify-between gap-y-6 lg:gap-y-0 lg:gap-x-4 lg:mb-[186px] lg:mt-6">
                        <Input
                            className="mt-0 lg:m-0 lg:w-72"
                            label={tCommon('Labels.emailAddress')}
                            type="EMAIL"
                            value={supplierFormData.email}
                            isInvalid={formErrors.email}
                            invalidMessage={t('invalidEmail')}
                            onChange={(e): void => setSupplierFormData({ ...supplierFormData, email: e.target.value })}
                            disabled={isLoading}
                            required />
                        <Input
                            className="mt-0 lg:m-0 lg:w-52"
                            label={tCommon('Labels.phoneNumber')}
                            type="TEL"
                            value={supplierFormData.phone}
                            isInvalid={formErrors.phone}
                            invalidMessage={t('invalidPhoneNumber')}
                            disabled={isLoading}
                            onChange={(e): void => setSupplierFormData({ ...supplierFormData, phone: e.target.value })} />
                    </div>
                </div>
                <div className="w-full">
                    <Separator />
                    {formErrors.network
                        && <Typography as="div"
                            className="text-red-300 mt-2 text-center w-full">
                            {tCommon('somethingWentWrong')} {tCommon('tryAgain')}
                        </Typography>}
                    <div className="flex items-center flex-col w-full px-4 lg:p-0 lg:flex-row lg:justify-end lg:mt-6 lg:mx-8 m-0 lg:w-auto lg:gap-x-2">
                        {!isMobileWidth
                            && <Button
                                as="div"
                                variant="TERTIARY_FILLED"
                                size="MEDIUM"
                                className="w-full lg:w-fit cursor-pointer"
                                onClick={onClose}
                                disabled={isLoading}>
                                <Typography as="span" weight="font-medium">
                                    {tCommon('Buttons.cancel')}
                                </Typography>
                            </Button>
                        }
                        <div className="w-full lg:w-auto lg:flex lg:flex-row-reverse">
                            <Button
                                type="submit"
                                variant="SECONDARY"
                                size={buttonSize}
                                className="w-full lg:w-fit mt-6 lg:m-0 lg:ml-2"
                                disabled={isLoading}>
                                <Typography as="span" weight="font-medium">
                                    {tCommon('Buttons.save')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
                // Tab Form
                : <form onSubmit={deliveryOnSubmit} noValidate>
                    <div className="flex flex-col lg:block my-6 mx-4 gap-y-4 lg:gap-y-6 lg:mx-8 lg:mt-6 lg:mb-6 lg:items-start">
                        <Typography
                            as="div"
                            weight="font-medium"
                            className="mb-4"
                            desktopSize="lg:text-3">
                            {t('deliveryDays')}
                        </Typography>
                        <div className="flex flex-row gap-2">
                            {radioTiles.map((radioTile) => (
                                <RadioTile className="hover:border-gray-700"
                                    key = {radioTile.id}
                                    value={radioTile.value}
                                    onChange={(val, isChecked): void => {
                                        const updatedFormData = { ...deliveryFormData };
                                        updatedFormData.operationalDays[radioTile.value].enable = isChecked;
                                        setDeliveryFormData(updatedFormData);
                                    }}
                                    selected= {deliveryFormData.operationalDays[radioTile.value].enable}
                                    disabled={isLoading}>
                                    <span>{radioTile.label}</span>
                                </RadioTile>
                            ))}
                        </div>
                    </div>
                    <Separator/>
                    <div className="flex flex-col lg:block my-6 mx-4 gap-y-4 lg:gap-y-6 lg:mx-8 lg:mt-6 lg:mb-6 lg:items-start">
                        <div className="flex flex-col items-start justify-start mt-4">
                            <div className="flex flex-row gap-2 items-center justify-center">
                                <Typography className="mb-0">{t('standardCutOffTime')}</Typography>
                                <Tooltip
                                    show={standardToolTip}
                                    className="flex"
                                    tooltipClassName={'w-60'}
                                    showArrow={false}
                                    onShow ={(): void => { setStandardToolTip(true); }}
                                    onHide ={(): void => { setStandardToolTip(false); }}
                                    placement="top"
                                    trigger={<InfoIcon className="w-4 h-4 text-gray-600 "/>}>
                                    {t('nonSameDayDeliveryTooltip')}
                                </Tooltip>
                            </div>
                            <Combobox initialValue={defaultTimeValue}
                                initialLabel={defaultTimeLabel}
                                onChange={(option: ComboOption): void => {
                                    setDeliveryFormData({ ...deliveryFormData, defaultCutOffTime: option.value as string });
                                }}
                                disabled={isLoading}
                                options={comboboxTimeCutoff}/>

                        </div>
                        <div className="lg:my-6 mt-4">
                            <Checkbox
                                label={t('sameDayDeliveryAvailable')}
                                value={''}
                                position= {'left'}
                                onChange={(val, isChecked): void => {
                                    setDeliveryFormData({ ...deliveryFormData,
                                        defaultHasSameDayCutOffTime: isChecked });
                                }}
                                checked={deliveryFormData.defaultHasSameDayCutOffTime}
                                disabled={isLoading}/>
                        </div>
                        {deliveryFormData.defaultHasSameDayCutOffTime
                        && <div className="flex flex-col items-start justify-start mt-4">
                            <div className="flex flex-row gap-2 items-center justify-center">
                                <Typography className="mb-0">{t('sameDayCutOffTime')}</Typography>
                                <Tooltip
                                    show={sameDayToolTip}
                                    showArrow={false}
                                    className="flex"
                                    tooltipClassName="w-60"
                                    onShow ={(): void => { setSameDayToolTip(true); }}
                                    onHide ={(): void => { setSameDayToolTip(false); }}
                                    placement="top"
                                    trigger={<InfoIcon className="w-4 h-4 text-gray-600 "/>}>
                                    {t('sameDayDeliveryTooltip')}
                                </Tooltip>
                            </div>
                            <Combobox initialValue={sameDayTimeValue}
                                initialLabel={sameDayTimeLabel}
                                onChange={(option): void => {
                                    setDeliveryFormData({ ...deliveryFormData, defaultSameDayCutOffTime: option.value as string });
                                }}
                                disabled={isLoading}
                                options={comboboxTimeCutoff}/>
                            <Typography className="mt-1 mb-0 text-gray-600">{t('cuttOffTimeOverride')}</Typography>
                        </div>}
                    </div>
                    <Separator />
                    <div className="flex flex-col lg:block my-6 mx-4 gap-y-4 lg:gap-y-6 lg:mx-8 lg:mt-6 lg:mb-6 lg:items-start">
                        <Input
                            className="lg:my-6 z-0"
                            label="Minimum order"
                            value={ deliveryFormData.minimumOrderAmount }
                            onChange={(e): void => {
                                setDeliveryFormData(({ ...deliveryFormData, minimumOrderAmount: parseInt(e.target.value) }));
                                setFormErrors({ ...formErrors, minimumOrderAmount: false });
                            }}
                            type={'CURRENCY'}
                            isInvalid={formErrors.minimumOrderAmount}
                            invalidMessage={'A minimum order amount is required'}
                            required
                            disabled={isLoading}/>
                    </div>
                    <div className="w-full">
                        <Separator />
                        {formErrors.network
                        && <Typography as="div"
                            className="text-red-300 mt-2 text-center w-full">
                            {tCommon('somethingWentWrong')} {tCommon('tryAgain')}
                        </Typography>}
                        <div className="flex items-center flex-col w-full px-4 lg:p-0 lg:flex-row lg:justify-end lg:mt-6 lg:mx-8 m-0 lg:w-auto lg:gap-x-2">
                            {!isMobileWidth
                            && <Button
                                as="div"
                                variant="TERTIARY_FILLED"
                                size="MEDIUM"
                                className="w-full lg:w-fit cursor-pointer"
                                onClick={onClose}
                                disabled={isLoading}>
                                <Typography as="span" weight="font-medium">
                                    {tCommon('Buttons.cancel')}
                                </Typography>
                            </Button>
                            }
                            <div className="w-full lg:w-auto lg:flex lg:flex-row-reverse">
                                <Button
                                    type="submit"
                                    variant="SECONDARY"
                                    size={buttonSize}
                                    className="w-full lg:w-fit mt-6 lg:m-0 lg:ml-2"
                                    disabled={isLoading}>
                                    <Typography as="span" weight="font-medium">
                                        {tCommon('Buttons.save')}
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>}
        </>
    </Modal>;
};

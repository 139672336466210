// Libraries
import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import * as CheckoutActions from '../../actions/checkoutActions';
import * as OrderActions from '../../actions/orderActions';
import * as AnalyticsActions from '../../actions/analyticsActions';
import * as ModalsActions from '../../actions/modalsActions';
import * as RouterActions from '../../actions/routerActions';

// Selectors
import * as checkoutSelectors from '../../selectors/checkoutSelectors';
import * as ordersSelectors from '../../selectors/ordersSelectors';

// Components
import CheckoutMain from '../../components/checkout/CheckoutMain.jsx';
import CheckPermission from '../../components/general/CheckPermission.jsx';
import * as Intercom from '../../third-party/Intercom';
import * as Stripe from '../../third-party/Stripe';
import Utils from '../../utils';
import Modal from '../../components/shared/Modal/Modal.jsx';

class CheckoutContainer extends Component {
  componentDidMount = async () => {
    const {
      checkoutActions,
      isPendingOrdersURLParamProp,
      currentlyEditingOrderUrlsafe,
    } = this.props;

    // Initialize Stripe
    Stripe.init();

    // Shift Intercom Icon in
    Intercom.resetPosition();

    checkoutActions.setOrderToPlaceUrlsafe(currentlyEditingOrderUrlsafe);
    // Initialize checkout data when checkout component first loads
    await checkoutActions.initCheckout(isPendingOrdersURLParamProp);
    // If the url contains a specific order, we will go to the review stage directly
    // We have to wait until initCheckout() gets our order info first
    if (currentlyEditingOrderUrlsafe && isPendingOrdersURLParamProp) {
      this.changeCheckoutView('reviewView');
    } else {
      this.changeCheckoutView('deliveryView');
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.locationBeforeTransitions &&
      this.props.locationBeforeTransitions
    ) {
      const regex = /([^checkout/pending/]).$/;

      // Always go to the pendingView if the url transition from anywhere to '/checkout/pending/'
      if (
        nextProps.locationBeforeTransitions.pathname === '/checkout/pending/' &&
        this.props.locationBeforeTransitions.pathname !== '/checkout/pending/'
      ) {
        this.changeCheckoutView('pendingView');
      }
      // Always go to the reviewView if the url transitions from anywhere to '/checkout/pending/<urlsafe>/'
      else if (
        nextProps.locationBeforeTransitions.pathname.match(regex) &&
        !this.props.locationBeforeTransitions.pathname.match(regex)
      ) {
        this.changeCheckoutView('reviewView');
      }
    }
  }

  componentWillUnmount() {
    const { checkoutActions } = this.props;
    checkoutActions.clearData();
  }

  onSaveDeliveryDay = (orderUrlsafe, deliveryDay) => {
    const { orders, checkoutActions } = this.props;

    checkoutActions.onSaveDeliveryDayFromCheckout(
      orders,
      orderUrlsafe,
      deliveryDay
    );
  };

  cancelOrder = () => {
    const { cancelOrderUrlsafe, checkoutActions } = this.props;

    checkoutActions.cancelOrderCheckout(cancelOrderUrlsafe);
  };

  reverseOrder = () => {
    const { cancelOrderUrlsafe, checkoutActions } = this.props;

    checkoutActions.reverseOrderCheckout(cancelOrderUrlsafe);
  };

  orderLater = () => {
    const { orderActions, orders, cancelOrderUrlsafe } = this.props;
    const isCheckout = true; // Will be forced to false

    orderActions.toggleIsCheckoutOrder(orders, cancelOrderUrlsafe, isCheckout);

    // Close modal manually instead of in an action since there is async behaviour.
    this.toggleCancelOrderModal('');
  };

  changeCheckoutView = (checkoutView) => {
    const { checkoutStep1Complete, checkoutActions, ordersWithItems } =
      this.props;

    checkoutActions.changeCheckoutView(checkoutView, checkoutStep1Complete);

    const checkedOutOrders = ordersWithItems.filter((o) => o.isCheckout);
    if (checkoutView === 'deliveryView') {
      this.props.analyticsActions.checkOutDeliveryScreenLoaded(
        checkedOutOrders
      );
    } else if (checkoutView === 'reviewView') {
      this.props.analyticsActions.checkOutPaymentScreenLoaded(checkedOutOrders);
    }
  };

  toggleCollapse = (order_urlsafe) => {
    const { orders, checkoutView, checkoutActions } = this.props;

    // Only allow toggleCollapse for reviewView
    if (checkoutView === 'reviewView') {
      checkoutActions.toggleCollapse(orders, order_urlsafe);
    }
  };

  collapseOrder = (order_urlsafe) => {
    const { orders, checkoutActions } = this.props;

    checkoutActions.collapseOrder(orders, order_urlsafe, true);
  };

  toggleChangeUnitModalCheckout = (order_urlSafe, product) => {
    const { checkoutActions, changeUnitProduct, showChangeUnitModal } =
      this.props;

    const productChosen = product ? product : {};

    checkoutActions.toggleChangeUnitModalCheckout(
      order_urlSafe,
      productChosen,
      showChangeUnitModal
    );
  };

  exitCheckout = () => {
    this.props.checkoutActions.exitCheckout();
  };

  changePhoneNumber = (accountPhoneNumber) => {
    const { checkoutActions } = this.props;
    checkoutActions.changePhoneNumber(accountPhoneNumber);
  };

  savePhoneNumber = () => {
    const { checkoutActions, account, buyer } = this.props;
    this.props.checkoutActions.savePhoneNumber(account, buyer);
  };

  toggleCancelOrderModal = (order_urlsafe) => {
    const { checkoutActions, showCancelOrderModal } = this.props;
    checkoutActions.toggleCancelOrderModal(showCancelOrderModal, order_urlsafe);
  };

  changeSelectedDeliveryDayOptionIndex = (
    order_urlsafe,
    selectedDeliveryDayOptionIndex
  ) => {
    const { orderActions, orders } = this.props;

    orderActions.changeSelectedDeliveryDayOptionIndex(
      orders,
      order_urlsafe,
      selectedDeliveryDayOptionIndex
    );
  };

  findSelectedDeliveryDayOptionIndex = (order, vendor) => {
    const selectedDeliveryDayIndex = Utils.findSelectedDeliveryDayOptionIndex(
      order.deliveryDay,
      vendor.region.nextAvailableDeliveryDays
    );
    this.changeSelectedDeliveryDayOptionIndex(
      order.urlsafe,
      selectedDeliveryDayIndex
    );
  };

  showPreOrderRestriction = (order_urlsafe, preOrderRestriced) => {
    const { orderActions, orders } = this.props;

    orderActions.showPreOrderRestriction(
      orders,
      order_urlsafe,
      preOrderRestriced
    );
  };

  selectNextAvailableDate = (orderUrlsafe) => {
    const { checkoutActions, orders, vendors } = this.props;

    checkoutActions.selectNextAvailableDate(orders, orderUrlsafe, vendors);
  };

  deletePreOrderItemsFromCheckout = (order_urlsafe) => {
    const { checkoutActions, orders } = this.props;

    checkoutActions.deletePreOrderItemsFromCheckout(orders, order_urlsafe);
  };

  toggleBuyerSuspendedModal = () => {
    const { modalsActions } = this.props;

    modalsActions.toggleBuyerSuspendedModal();
  };

  toggleBuyerCreditCardModal = () => {
    const { modalsActions } = this.props;

    modalsActions.toggleBuyerCreditCardModal();
  };

  changePaymentMethod = (paymentMethod) => {
    const { checkoutActions } = this.props;

    checkoutActions.changePaymentMethod(paymentMethod);
  };

  saveChangesToAddOnOrder = () => {
    const { currentlyEditingOrderUrlsafe, checkoutActions } = this.props;

    checkoutActions.saveChangesToAddOnOrder(currentlyEditingOrderUrlsafe);
  };

  cancelChangeToAddOnOrder = () => {
    this.props.checkoutActions.cancelChangeToAddOnOrder();
  };

  changeRoute = (location) => {
    const { routerActions } = this.props;

    routerActions.changeRoute(location);
  };

  closeModal = () => {
    const { modalsActions, checkoutActions } = this.props;
    modalsActions.hideOrderIsAlreadyCheckedOutModal();
    this.changeRoute('/orders/');
  };

  render() {
    const {
      ordersWithItems,
      unusedAmount,
      isShowingOrderCheckedOutModal,
    } = this.props;
    return (
      <div className='bg-white p-safe h-full overflow-hidden'>
        <CheckPermission roles={['Owner', 'Manager']}>
          {isShowingOrderCheckedOutModal && (
            <Modal
              isOpen={isShowingOrderCheckedOutModal}
              title="This order has already been submitted"
              desc="It looks like this order has already been submitted from another device or another User."
              onAccept={this.closeModal}
              acceptButtonText="View My Orders"
            />
          )}
          <CheckoutMain
            {...this.props}
            unusedAmount={unusedAmount}
            orders={ordersWithItems}
            onSaveDeliveryDay={this.onSaveDeliveryDay}
            cancelOrder={this.cancelOrder}
            reverseOrder={this.reverseOrder}
            orderLater={this.orderLater}
            changeCheckoutView={this.changeCheckoutView}
            toggleCollapse={this.toggleCollapse}
            collapseOrder={this.collapseOrder}
            toggleChangeUnitModalCheckout={this.toggleChangeUnitModalCheckout}
            changePhoneNumber={this.changePhoneNumber}
            savePhoneNumber={this.savePhoneNumber}
            toggleCancelOrderModal={this.toggleCancelOrderModal}
            exitCheckout={this.exitCheckout}
            changeSelectedDeliveryDayOptionIndex={
              this.changeSelectedDeliveryDayOptionIndex
            }
            findSelectedDeliveryDayOptionIndex={
              this.findSelectedDeliveryDayOptionIndex
            }
            showPreOrderRestriction={this.showPreOrderRestriction}
            selectNextAvailableDate={this.selectNextAvailableDate}
            deletePreOrderItemsFromCheckout={
              this.deletePreOrderItemsFromCheckout
            }
            toggleBuyerSuspendedModal={this.toggleBuyerSuspendedModal}
            toggleBuyerCreditCardModal={this.toggleBuyerCreditCardModal}
            changePaymentMethod={this.changePaymentMethod}
            saveChangesToAddOnOrder={this.saveChangesToAddOnOrder}
            cancelChangeToAddOnOrder={this.cancelChangeToAddOnOrder}
            changeRoute={this.changeRoute}
          />
        </CheckPermission>
      </div>
    );
  }
}

CheckoutContainer.propTypes = {
  buyer: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  isPendingOrdersURLParamProp: PropTypes.bool.isRequired,
  ordersWithItems: PropTypes.array.isRequired,
  unusedAmount: PropTypes.number.isRequired,
};

function mapStatetoProps(state, props) {
  const ordersWithItems = ordersSelectors
    .filterOrdersWithItems(state)
    .filter((order) => {
      const { vendors } = state.vendorsReducer;
      const { isPendingOrdersURLParamProp } = props;

      const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

      if (vendor && isPendingOrdersURLParamProp) {
        return !Utils.isAfterDeliveryDayCutOffTime(vendor, order);
      }

      return true;
    });

  return {
    ...state.checkoutReducer,
    ...state.modalsReducer,
    ...state.routing,

    loadingCheckout: state.checkoutReducer.loading,
    stripePubKey: state.configReducer.stripePubKey,
    orders: state.ordersReducer.orders,
    loadingOrders: state.ordersReducer.loading,
    showOrdersTotal: state.ordersReducer.showOrdersTotal,
    singleOrderBeforeChange: state.ordersReducer.singleOrder,
    buyer: state.buyerReducer.buyer,
    account: state.accountReducer.account,
    vendors: state.vendorsReducer.vendors,
    editOrderProductLoading: state.ordersReducer.editOrderProductLoading,

    // Selectors
    ordersWithItems,
    cancelOrderVendor: checkoutSelectors.getCanceledOrderVendor(state),
    vendorsByUrlsafe: checkoutSelectors.getVendorsByUrlsafe(state),
    ordersGrandTotal: checkoutSelectors.getCheckoutOrdersGrandTotal(
      state,
      props
    ),
    creditUsedInOrder: ordersSelectors.getCreditUsedInOrder(state, props),
    additionalOrderTotals: checkoutSelectors.getAdditionalOrderTotals(
      state,
      props
    ),
    checkoutStep1Complete: checkoutSelectors.checkIfCheckoutStep1Complete(
      state,
      props
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkoutActions: bindActionCreators(CheckoutActions, dispatch),
    orderActions: bindActionCreators(OrderActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
    modalsActions: bindActionCreators(ModalsActions, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(CheckoutContainer);

import { useQuery } from '@tanstack/react-query';
import { Button, Typography, toast } from '@notch-ordering/ui-components';
import { ActionsPanel, SetupInfo } from '@v2/components/Setup/Setup';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { tCommon, tNamespace } from '@v2/i18n';
import { emailSupplier, getSupplierID } from '@v2/network/CoreAPI';
import { useSetupStore } from '@v2/stores/SetupStore';
import cx from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ECatalogSetupOptions } from '../constants/catalog.enum';

interface Props {
    router: {
        goBack: () => void,
        push: (route: string) => void,
    },
}

type SetupCatalogOption = {
    label: string,
    text: string,
    trigger: string,
    path?: string,
    disabled?: boolean,
    next: () => void,
};

/**
 * View that list the options to select how to upload the catalog
 *
 * @param props - component properties
 * @param props.router - for navigation
 */
export const SetupCatalog: React.FC<Props> = ({ router }) => {
    const { supplier, setSupplier, setSetupActions } = useSetupStore();
    const { buyer } = useBuyerHook();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Catalog' });
    const [isActionsExpanded, setIsActionsExpanded] = useState(false);
    const [selectedOption, setSelectedOption] = useState<SetupCatalogOption>(() => (null));

    const supplierIdQueryResult = useQuery(
        ['getSupplierID', supplier.urlsafe],
        () => getSupplierID(supplier.urlsafe),
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            onError: () => {
                toast.show({
                    message: tCommon('somethingWentWrong'),
                    action: {
                        text: tCommon('tryAgain'),
                        onClick: (): void => window.location.reload()
                    },
                    showClose: false
                });
            }
        }
    );

    useEffect(() => {
        setSetupActions({ didCreateItems: false, didRequestPriceList: false, didUploadFiles: false });
    }, []);

    const close = (): void => {
        setSupplier(null);
        router.push(EPaths.HOME);
    };

    const back = (): void => {
        router.goBack();
    };

    const getSetupCatalogOptions = (): Array<SetupCatalogOption> => [
        {
            label: 'requestPriceList',
            text: 'takesAverage',
            trigger: ECatalogSetupOptions.REQUEST,
            disabled: supplierIdQueryResult.isError,
            next: async (): Promise<void> => {
                setSetupActions({ didRequestPriceList: true });
                await emailSupplier(supplierIdQueryResult.data, buyer.displayName, buyer.email);
                router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_COMPLETE}`);
            }
        },
        {
            label: 'addMyself',
            text: 'byUploading',
            trigger: ECatalogSetupOptions.MANUAL,
            next: (): void => {
                router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS}`);
            }
        },
        {
            label: 'allAbove',
            text: '',
            trigger: ECatalogSetupOptions.BOTH,
            disabled: supplierIdQueryResult.isError,
            next: async (): Promise<void> => {
                setSetupActions({ didRequestPriceList: true });
                // TODO: send the request email
                await emailSupplier(supplierIdQueryResult.data, buyer.displayName, buyer.email);
                router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS}`);
            }
        }
    ];

    const isNextDisabled: boolean = useMemo((): boolean => {
        if (!selectedOption) {
            return true;
        }

        if (supplierIdQueryResult.error) {
            return selectedOption.trigger === ECatalogSetupOptions.BOTH || selectedOption.trigger === ECatalogSetupOptions.REQUEST;
        }

        return false;
    }, [supplierIdQueryResult.isError, selectedOption]);

    return (
        <>
            <SetupInfo
                close={close}
                back={back}
                isLoading={supplierIdQueryResult.isLoading}
                heading={supplier.name}
                title={t('howWouldYou')}
                shortTitle={t('populateCatalog')}
                description={t('noCatalog')}
                gradient
                showShortTitle={isActionsExpanded}>
                <ActionsPanel
                    back={back}
                    next={selectedOption?.next}
                    nextButtonDisabled={isNextDisabled}
                    totalSteps={3}
                    currentStep={1}
                    onExpand={(isExpanded: boolean): void => {
                        setIsActionsExpanded(isExpanded);
                    }}>
                    {getSetupCatalogOptions().map((option: SetupCatalogOption) => (
                        <Button
                            variant="TERTIARY_OUTLINED"
                            size="MEDIUM"
                            className={cx(
                                'p-6 mb-4 focus:ring-offset-0 focus:ring-1 text-left w-full'
                            )}
                            key={option.label}
                            disabled={option.disabled}
                            onClick={(): void => {
                                setSelectedOption(option);
                            }}
                            selected={selectedOption?.trigger === option.trigger}>
                            <div>
                                <Typography
                                    as="div"
                                    weight="font-medium"
                                    variant="LG-2"
                                    desktopSize="lg:text-4">
                                    {t(option.label)}
                                </Typography>
                                {option.text && (
                                    <Typography
                                        as="div"
                                        className="text-gray-600 mt-1"
                                        variant="LG-2"
                                        desktopSize="lg:text-4">
                                        {t(option.text)}
                                    </Typography>
                                )}
                            </div>
                        </Button>
                    ))}
                </ActionsPanel>
            </SetupInfo>
        </>
    );
};

import React, { useMemo, useState } from 'react';
import { BILLS_FILTERS_INITIAL_STATE, useBillsStore } from '@v2/stores/BillsStore';
import { debounce, isEqual } from 'lodash';

import { SearchInput } from '@v2/components/Shared/SearchInput/SearchInput';
import { Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import { useLegacySuppliers } from '@v2/hooks/useLegacySuppliers.hook';
import CheckIcon from '@icons/check-icon.svg';
import { FilterButton } from '@v2/components/Shared/FiltersButton/FilterButton';
import { BillsFiltersOptions,
    dateToString,
    FILTER_TYPE,
    FilterDateTypeValue,
    FILTERS,
    FilterType,
    formatDateRange,
    getStartEndDate,
    tFilters } from '@v2/components/Bills/BillsFilters/BillsFiltersConstants';
import { BillsFilterDateRange } from '@v2/Pages/Invoices/BillsQueries.hook';
import { BillsFilterBadges } from '@v2/components/Bills/BillsFilters/BillsFilterBadges';
import DateFilter from '@/deprecated/fe-shared-components/dateFilter';

const DEBOUNCE_SEARCH_QUERY_DELAY = 100;

const mapFilterTypeToDatesRangeLabel = (filterType: typeof FILTER_TYPE[keyof typeof FILTER_TYPE]): string => {
    switch (filterType) {
        case FILTER_TYPE.DUE_DATE:
            return tFilters('selectDueDate');
        case FILTER_TYPE.ISSUED_DATE:
            return tFilters('selectIssuedDate');
        default:
            return tFilters('selectDatesRange');
    }
};

export type BillsFiltersProps = {
    onChangeFilters?: () => void,
};

export const BillsFilters : React.FC<BillsFiltersProps> = ({ onChangeFilters }) => {
    const { filters, updateFilters, resetFilterByType } = useBillsStore();
    const [searchQuery, setSearchQuery] = useState<string[]>(filters.search);
    const [customDateRange, setCustomDateRange] = useState({
        open: false,
        type: null,
    });
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices.Filters' });
    const { legacySuppliers = [] } = useLegacySuppliers();
    const areFiltersApplied = useMemo(() => !isEqual(filters, BILLS_FILTERS_INITIAL_STATE), [filters]);

    const filteredVendorsOptions = useMemo<PopoverItem[]>(() => legacySuppliers.sort((a, b) => a?.name?.localeCompare(b?.name)).map((vendor) => ({
        label: <Typography className="mb-0 flex gap-2 relative items-center"><span>{vendor.name}</span> {filters.supplierUrlsafeKey === vendor.urlsafe
            && <div className="w-4 ml-auto"><CheckIcon className="w-4 h-4 ml-auto"/></div>}</Typography>,
        onClick: ():void => {
            updateFilters({ supplierUrlsafeKey: vendor.urlsafe });
            if (onChangeFilters) {
                onChangeFilters();
            }
        }
    })), [filters.supplierUrlsafeKey, legacySuppliers]);

    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        updateFilters({ search: query ? [query] : undefined });
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);

    const resetFilterType = (type:FilterType):void => {
        resetFilterByType(type);
    };

    const handleDateFilter = (type:FilterType, date:FilterDateTypeValue):void => {
        const {
            startDate,
            endDate
        } = getStartEndDate(date);
        updateFilters({
            [type]: {
                startDate,
                endDate,
                type: date
            }
        });
    };

    const handleQueryChange = (query: string):void => {
        const search = query?.length > 0 ? query : undefined;
        setSearchQuery(search ? [search] : undefined);
        debounceSearchQuery(search);
        if (onChangeFilters) {
            onChangeFilters();
        }
    };

    const handleCustomDateSelection = (startDate, endDate):void => {
        const [startDateStr, endDateStr] = [
            dateToString(startDate),
            dateToString(endDate),
        ];
        updateFilters({
            [customDateRange.type]: {
                startDate: startDateStr,
                endDate: endDateStr,
                type: FILTERS.DATE.CUSTOM
            }
        });
        setCustomDateRange({ open: false, type: null });
    };

    return <div className="flex flex-col gap-4">
        <div className="w-full flex gap-2 flex-wrap">
            <SearchInput onChange={handleQueryChange}
                value={searchQuery}
                inputProps={{
                    placeholder: tFilters('searchByInvoiceNumber'),
                }
                } />
            <FilterButton label={t('supplier')} items={filteredVendorsOptions} className="h-80 overflow-auto"/>
            {BillsFiltersOptions.map((currentFilter) => <FilterButton key={currentFilter.label}
                label={currentFilter.label}
                items={currentFilter.items.map(({ value, label }) => {
                    const { type } = currentFilter;
                    const { DUE_DATE, ISSUED_DATE } = FILTER_TYPE;
                    const { DATE: { CUSTOM } } = FILTERS;
                    const isDateFilter = type === DUE_DATE || type === ISSUED_DATE;
                    const dateFilter = filters[type] as BillsFilterDateRange;
                    const isDateSelected = (isDateFilter && dateFilter?.type === value && dateFilter?.startDate && dateFilter?.endDate);
                    const isSelectedItem = filters[type] === value || isDateSelected;
                    const isCustomDateFilter = isDateFilter && value === CUSTOM;
                    const shouldDisplayCustomDateLabel = isCustomDateFilter && isDateSelected;
                    const optionLabel = shouldDisplayCustomDateLabel ? formatDateRange({
                        startDate: dateFilter.startDate,
                        endDate: dateFilter.endDate
                    }) : label;
                    return ({
                        label:
                       <Typography className="mb-0 flex gap-2 relative items-center">
                           <span>{optionLabel}</span>
                           { isSelectedItem && <div className="w-4 ml-auto">
                               <CheckIcon className="w-4 h-4 ml-auto"/></div>}
                       </Typography>,
                        onClick: (): void => {
                            if (onChangeFilters) {
                                onChangeFilters();
                            }
                            if (isSelectedItem) {
                                resetFilterType(type);
                                return;
                            }
                            if (isCustomDateFilter) {
                                setCustomDateRange({ open: true, type });
                            } else if (isDateFilter) {
                                handleDateFilter(type, value as FilterDateTypeValue);
                            } else {
                                updateFilters({ [type]: value });
                            }
                        }
                    });
                })}/>)}

        </div>
        {areFiltersApplied && <BillsFilterBadges/>}

        <DateFilter
            isOpen={customDateRange.open}
            onHide={():void => setCustomDateRange({ open: false, type: null })}
            updateDates={handleCustomDateSelection}
            headerText={mapFilterTypeToDatesRangeLabel(customDateRange.type)}/>
    </div>;
};

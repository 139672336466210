/**
 * This component displays a message to the user when we're unable to find the
 * requested page (i.e. a 404 error)
 */

import React from 'react';
import NotchLogoImg from '@images/notch-logo.svg';
import { Typography, Button } from '@notch-ordering/ui-components';
import { Link } from 'react-router';
import { EPaths } from '@v2/constants/EPaths';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';

const NotFound: React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'NotFound' });

    return (
        <div className="flex justify-center items-center flex-col h-full w-full bg-white px-4">
            <div className="w-[216px] h-[168px]">
                <NotchLogoImg title="notch"/>
            </div>
            <Typography
                variant="3XL"
                weight="font-medium"
                as="h3"
                className="text-center text-gray-700">
                {t('pageNotFound')}
            </Typography>

            <Typography
                as="div"
                className="text-center text-gray-600 mt-4">
                {t('unableToFind')}
            </Typography>

            <Link to={EPaths.HOME}>
                <Button variant="PRIMARY"
                    size="MEDIUM"
                    className="my-10">
                    {tCommon('Buttons.goToOrderDesk')}
                </Button>
            </Link>
        </div>
    );
};

export default NotFound;

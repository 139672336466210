import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as SettingsActions from '../../actions/settingsActions';
import * as BuyerActions from '../../actions/buyerActions';
import * as AnalyticsActions from '../../actions/analyticsActions';

import LocationsMain from '../../components/settings/locations/LocationsMain.jsx';

class LocationsContainer extends Component {
  componentDidMount() {
    const { analyticsActions } = this.props;

    analyticsActions.viewPage('MyLocations');
  }

  toggleLocationsModal = () => {
    const { settingsActions, showLocationsModal } = this.props;

    settingsActions.toggleLocationsModal(showLocationsModal);
  };

  updateLocationsModalIndex = (index) => {
    this.props.settingsActions.updateLocationsModalIndex(index);
  };

  addShippingAddress = () => {
    const {
      buyerActions,
      locationsModalIndex,
      LocationsForm,
      shippingAddresses,
    } = this.props;

    // make AddNewLocation action only if we have LocationsForm is active
    // We have to wrap this in a try block to show any swallowed errors from redux-form handleSubmit
    try {
      if (LocationsForm) {
        const shippingAddressData = {
          name: LocationsForm.values.shippingName,
          line1: LocationsForm.values.shippingAddress,
          line2: LocationsForm.values.shippingUnitNumber,
          zip: LocationsForm.values.shippingPostalCode,
          city: LocationsForm.values.shippingCity,
          state: LocationsForm.values.shippingState,
          country: LocationsForm.values.shippingCountry,
          driveInstructions: LocationsForm.values.driveInstructions,
          // These two fields are not on the form, so only include them if we are updating an existing address
          deliveryContactName:
            locationsModalIndex > -1
              ? shippingAddresses[locationsModalIndex].deliveryContactName
              : '',
          deliveryContactNumber:
            locationsModalIndex > -1
              ? shippingAddresses[locationsModalIndex].deliveryContactNumber
              : '',
        };

        if (locationsModalIndex > -1) {
          buyerActions.updateBuyerShippingAddress(
            locationsModalIndex,
            shippingAddressData
          );
        } else {
          buyerActions.createBuyerShippingAddress(shippingAddressData);
        }

        this.toggleLocationsModal();
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <LocationsMain
        {...this.props}
        toggleLocationsModal={this.toggleLocationsModal}
        addShippingAddress={this.addShippingAddress}
        updateLocationsModalIndex={this.updateLocationsModalIndex}
      />
    );
  }
}

LocationsContainer.propTypes = {};

function mapStatetoProps(state) {
  return {
    ...state.locationsReducer,
    ...state.settingsReducer,
    ...state.buyerReducer,
    ...state.form,

    shippingAddresses: state.buyerReducer.buyer.shippingAddresses,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(SettingsActions, dispatch),
    buyerActions: bindActionCreators(BuyerActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(LocationsContainer);

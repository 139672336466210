import React from 'react';
import ReactSelect from 'react-select';

import './styles.scss';

const Select = (props) => (
  <ReactSelect
    className="Select"
    classNamePrefix="Select"
    {...props}
  />
);

export default Select;

import * as buyerActions from '../../actions/buyerActions';

const asyncValidate = async (values, dispatch) => {
  // Only validate existing emails for new members, ignore existing members during edit mode
  if (values.memberIndex === -1) {
    const isValid = await dispatch(
      buyerActions.validateMemberEmail(values.email)
    );

    if (!isValid) {
      throw { email: 'This email is already taken' };
    }
  }
};

export default asyncValidate;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as ordersSelectors from '../../../../../selectors/ordersSelectors';
import Utils from '../../../../../utils';

import MarketTag from '../../../../general/MarketTag.jsx';
import { Typography } from '@notch-ordering/ui-components';

class OrderCheckoutHeaderPending extends Component {
  onClickEditOrder = () => {
    const { toggleCollapse, changeCheckoutView, order_urlsafe, changeRoute } =
      this.props;

    changeRoute(`/checkout/pending/${order_urlsafe}/`);
    changeCheckoutView('reviewView');
    toggleCollapse(order_urlsafe);
  };

  toggleCancelOrderModal = (event) => {
    const { toggleCancelOrderModal, order_urlsafe } = this.props;

    // Don't allow to bubble up to trigger onClickEditOrder
    event.stopPropagation();

    toggleCancelOrderModal(order_urlsafe);
  };

  render() {
    const { order, vendorName, deliveryDay, orderGrandTotal, vendor } =
      this.props;

    return (
      <div
        className="order-checkout-header-pending"
        onClick={this.onClickEditOrder}
      >
        <div className="order-checkout-header-table">
          <div className="vendor-col">
            <Typography as='span' className="vendor-name-text">{vendorName}</Typography>
            {!vendor.customer.isBYOS && !vendor.isPayFacLite && <MarketTag />}
            <div className="vendor-statuses">
              {order.isPendingVendorOnboarding && (
                <div className="pending-vendor-label pending-vendor-chip">
                  1st ORDER
                </div>
              )}
            </div>
            <div className="order-buttons">
              <div
                className="btn btn-ch-primary-link-on-white"
                onClick={this.onClickEditOrder}
              >
                Edit
              </div>
              {!order.isEditingAddOnOrder && (
                <div
                  className="btn btn-ch-warning-link-on-white cancel-order"
                  onClick={this.toggleCancelOrderModal}
                >
                  Cancel Order
                </div>
              )}
            </div>
          </div>
          <Typography as='div' weight='font-semibold' className="delivery-col">
            {Utils.formatDate(deliveryDay, 'dddd MM/DD/YYYY')}
          </Typography>
          <Typography as='div' weight='font-semibold' className="total-col">
            {orderGrandTotal === 0 ? (
              'TBD'
            ) : (
              <span>{Utils.formatAsCurrency(orderGrandTotal)}</span>
            )}
          </Typography>
        </div>
      </div>
    );
  }
}

OrderCheckoutHeaderPending.propTypes = {
  order: PropTypes.object.isRequired,
  order_urlsafe: PropTypes.string.isRequired,
  vendorName: PropTypes.string.isRequired,

  changeCheckoutView: PropTypes.func.isRequired,
  collapseOrder: PropTypes.func.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  toggleCancelOrderModal: PropTypes.func.isRequired,
  changeRoute: PropTypes.func.isRequired,
};

function mapStatetoProps(state, props) {
  return {
    orderGrandTotal: ordersSelectors.getSingleOrderGrandTotal(state, props),
  };
}

export default connect(mapStatetoProps, null)(OrderCheckoutHeaderPending);

import { toastr } from 'react-redux-toastr';

import * as types from '../constants/ActionTypes';
import * as DataAPI from '../api/DataAPI';
import * as checkoutActions from './checkoutActions';
import * as orderActions from './orderActions';
import * as analyticsActions from './analyticsActions';
import Utils from '../utils';
import { AnalyticsEventSources } from '../constants/AnalyticsEventSources';
import { logException } from '../domains/shared/logger';

// Regular Action Creators
export function initValues(orderguide, originalVariant, openedBy) {
  return {
    type: types.INIT_VALUES_CHANGE_UNIT,
    orderguide,
    originalVariant,
    openedBy,
  };
}

export function receiveProductVariants(variants) {
  return {
    type: types.RECEIVE_VARIANTS_CHANGE_UNIT,
    variants,
  };
}

export function showLoader(isChangeUnitLoading) {
  return {
    type: types.SHOW_LOADER_CHANGE_UNIT,
    isChangeUnitLoading,
  };
}

export function changeVariantsSelected(variantsSelected) {
  return {
    type: types.CHANGE_VARIANTS_SELECTED_CHANGE_UNIT,
    variantsSelected,
  };
}

export function initAllVariantsLists(
  initialVariantsSelected,
  variantsSelected,
  variantsCheckout
) {
  return {
    type: types.INIT_ALL_VARIANTS_LISTS_CHANGE_UNIT,
    initialVariantsSelected,
    variantsSelected,
    variantsCheckout,
  };
}

export function changeCheckoutVariants(variantIndex, quantity) {
  return {
    type: types.CHANGE_CHECKOUT_VARIANTS_CHANGE_UNIT,
    variantIndex,
    quantity,
  };
}

export function showModalConfirmPrompt(isModalConfirmPromptShowing) {
  return {
    type: types.SHOW_MODAL_CONFIRM_PROMPT_CHANGE_UNIT,
    isModalConfirmPromptShowing,
  };
}

export function clearData() {
  return {
    type: types.CLEAR_DATA_CHANGE_UNIT,
  };
}

// Thunk Action Creators
export function initChangeUnit({
  orderguideFromProps,
  originalVariantFromProps,
  openedByFromProps,
}) {
  return async (dispatch, getState) => {
    // If there is no originalVariant object, abadon and report here
    if (_.isEmpty(originalVariantFromProps)) {
      toastr.error(`An Error occured using the unit picker`);
      return;
    }

    const { orders } = getState().ordersReducer;
    const order = Utils.getOrderContainingOrderProductUrlsafe(
      orders,
      originalVariantFromProps.sourceUrlsafe
    );

    // Init these initial values
    dispatch(
      initValues(
        orderguideFromProps,
        originalVariantFromProps,
        openedByFromProps
      )
    );

    // Load all the variants associated with the product clicked.
    // Not really necesary to add 'await', however if is needed for tests to run in sequence.
    const { productCode, inStock } = originalVariantFromProps;
    await dispatch(
      fetchProductVariants({ vendorID: order.vendorID, productCode, inStock })
    );
  };
}

export function initVariantsSelected(variants) {
  return (dispatch, getState) => {
    try {
      // First find the order belonging to the variant that opened the unit picker.
      const { orders } = getState().ordersReducer;
      const { currentOrder_urlSafe } = getState().checkoutReducer;

      const order = orders.find(
        (order) => currentOrder_urlSafe === order.urlsafe
      );

      if (!order) {
        throw new Error(
          `Error: no order for currentOrder_urlSafe found ${currentOrder_urlSafe}`
        );
      }

      // This is actually a list for all variants, but just organized differently to account
      // for the existence of OrderProduct urlsafe and quantity
      const variantsCheckout = variants.map((variant) => {
        const { id, price, inStock } = variant;
        let quantity = 0;
        let orderProductUrlsafe = '';

        const orderProduct = order.items.find(
          (item) => item.sourceID === variant.id
        );

        if (orderProduct) {
          // Only obtain the qty from the OrderProduct if the variant is inStock
          // otherwise it will become 0 and the server will remove it on any call.
          quantity = inStock ? orderProduct.quantity : 0;
          orderProductUrlsafe = orderProduct.urlsafe;
        }

        return { id, quantity, price, orderProductUrlsafe };
      });

      dispatch(initAllVariantsLists(variantsCheckout, [], variantsCheckout));
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with initVariantsSelected');
      console.error(error);
      logException(error);
    }
  };
}

export function addRemoveVariantCheckout(
  selectedVariant,
  variantsCheckout,
  quantity,
  type
) {
  return (dispatch) => {
    // This helper function will make sure the user can only place valid inputs for a quantity.
    // Note, this is a helper in the action file (we don't need a dispatch)
    const santitizedQuantity = Utils.changeQuantityHelper(quantity, type);

    const variantIndex = variantsCheckout.findIndex(
      (variant) => variant.id === selectedVariant.id
    );

    // If variant is found in selected variants list we update the variantsCheckout array.
    if (variantIndex > -1) {
      dispatch(changeCheckoutVariants(variantIndex, santitizedQuantity));
    }
  };
}

export const fetchProductVariants = ({ vendorID, productCode, inStock }) => {
  return async (dispatch) => {
    // Show loader while loading variants
    dispatch(showLoader(true));

    try {
      const response = await DataAPI.fetchProductVariants(
        vendorID,
        productCode
      );
      dispatch(receiveProductVariants(response.data));
      dispatch(initVariantsSelected(response.data));
      dispatch(
        analyticsActions.changeUnitOrderGuidePopup(inStock, response.data)
      );
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with fetchProductVariants');
      console.error(error);
      logException(error);
    } finally {
      // Hide loader after the request is done (whether it passes or fails).
      dispatch(showLoader(false));
    }
  };
};

export const fetchNewProductVariants = ({ vendorID, productCode }) => {
  return async (dispatch) => {
    // Show loader while loading variants
    dispatch(showLoader(true));

    try {
      const response = await DataAPI.fetchProductVariants(
        vendorID,
        productCode
      );
      dispatch(receiveProductVariants(response.data));
    } catch (error) {
      let errorMessage = '';

      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).errorMessage ||
          'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      logException(error);
    } finally {
      // Hide loader after the request is done (whether it passes or fails).
      dispatch(showLoader(false));
    }
  };
};

export const changeVariantsOrder = () => {
  return async (dispatch, getState) => {
    const { variants, variantsCheckout, initialVariantsSelected } =
      getState().changeUnitReducer;
    const { changeUnitProduct, showChangeUnitModal, currentOrder_urlSafe } =
      getState().checkoutReducer;

    if (variantsCheckout.every((v) => v.quantity <= 0)) {
      toastr.error(`Error: You must have at least one variant selected`);
      return;
    }

    for (const variant of variantsCheckout) {
      const product = variants.find((v) => v.id === variant.id);
      const initialVariant = initialVariantsSelected.find(
        (v) => v.id === variant.id
      );

      if (
        !variant ||
        !initialVariant ||
        variant.quantity === initialVariant.quantity
      ) {
        continue;
      }

      if (variant.orderProductUrlsafe) {
        const { orders } = getState().ordersReducer;
        const orderProduct = Utils.getOrderAndOrderProductForCode(
          orders,
          product.vendorKey,
          product.urlsafe
        ).orderProduct;

        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          orderActions.changeQuantityWithID(
            currentOrder_urlSafe,
            orderProduct,
            variant.quantity,
            'CUSTOM',
            AnalyticsEventSources.CHECKOUT
          )
        );
      } else {
        // eslint-disable-next-line no-await-in-loop
        await dispatch(
          orderActions.changeQuantityWithoutID(
            currentOrder_urlSafe,
            null,
            variant.quantity,
            'CUSTOM',
            AnalyticsEventSources.CHECKOUT,
            product
          )
        );
      }
    }

    dispatch(
      checkoutActions.toggleChangeUnitModalCheckout(
        currentOrder_urlSafe,
        changeUnitProduct,
        showChangeUnitModal
      )
    );

    toastr.success('Unit Changed Successfully');
  };
};

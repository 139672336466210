import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import cx from 'classnames';
import { SupplierInitials } from './SupplierInitials';

export interface Props {
    name: string,
    className?: string,
    children?: React.ReactNode,
    onClick?: () => void,
}

export const SupplierCard: React.FC<Props> = ({
    name,
    children,
    className,
    onClick = (): void => {},
}) => (
    <>
        <div onClick={onClick} className={cx('flex flex-col h-full items-start bg-white shadow-z2 border border-solid border-gray-300 rounded-xl w-full py-6', className)}>
            <SupplierInitials name={name} className="ml-6" />
            <Typography
                as="span"
                variant="LG-2"
                weight="font-semibold"
                className="text-gray-700 mt-4 mx-6">
                {name}
            </Typography>
            {children}
        </div>
    </>
);

export default SupplierCard;

/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-shadow */
import { Button, Typography, Input, Modal, NONE_SELECTED, Select, SelectOption, onMobileSetLarge, Separator, Badge, Popover, toast } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { createProduct, createProductPackage,
    deleteProductPackage,
    fetchSupplierCatalogProduct,
    getCategories,
    getUnits,
    updateProduct,
    updateProductPackage,
    updateProductPackagePrice, } from '@v2/network/SecretShopAPI';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import AddIcon from '@icons/add-icon.svg';
import { EProductAvailability } from '@v2/stores/BulkUploadStore';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import useBuyerHook from '@v2/hooks/useBuyer.hook';

type Props = {
    isOpen: boolean,
    close: () => void,
    productID?: string,
    catalogID?: string,
    isEditing?: boolean,
};

export type ProductData = {
    name: string,
    description?: string,
    taxCode?: string,
    category: string,
    packages: Array<CatalogPackageData>,
    id?: string,
};

export type CatalogPackageData = {
    id?: string,
    availability?: string,
    description?: string,
    sku: string,
    unit?: string,
    quantity?: number,
    prices?: CatalogPriceData[],
    isNew?: boolean,
};

export type CatalogPriceData = {
    id?: string,
    pricePerUnit: number,
    priceListId?: string,
    available?: boolean,
    isNew?: boolean,
};

type SingleProductErrors = {
    name?: boolean,
    network?: boolean,
    category?: boolean,
    packages?: Array<ProductPackageErrors>,
};

type ProductPackageErrors = {
    sku?: boolean,
    quantity?: boolean,
    unit?: boolean,
};

export const FULLY_TAX_CODE = '';
export const EXEMPT_TAX_CODE = '99999';

const TaxCodeOptions: SelectOption[] = [
    {
        value: EXEMPT_TAX_CODE,
        label: 'Other - Exempt',
    },
    {
        value: FULLY_TAX_CODE,
        label: 'Fully Taxable',
    },
];

const DEFAULT_UNIT = 'each';

export const ProductDetailsModal: React.FC<Props> = ({
    isOpen = false,
    close,
    productID,
    catalogID,
    isEditing = false,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Catalog.AddItems.Create' });
    const { completeTask } = useOnboardingStore();
    const { buyer } = useBuyerHook();
    const [deletedData, setDeletedData] = useState<ProductData>({
        name: '',
        category: '',
        id: '',
        taxCode: '',
        description: '',
        packages: [
            {
                unit: DEFAULT_UNIT,
                quantity: 1,
                sku: '',
                description: '',
                prices: [{
                    pricePerUnit: 0,
                    isNew: false,
                }
                ],
                availability: '',
                isNew: false,
            }
        ]
    });
    const [productData, setProductData] = useState<ProductData>({
        name: '',
        category: '',
        id: '',
        taxCode: '',
        description: '',
        packages: [
            {
                unit: DEFAULT_UNIT,
                quantity: 1,
                sku: '',
                description: '',
                prices: [{
                    pricePerUnit: 0,
                    isNew: false,
                }
                ],
                availability: EProductAvailability.IN_STOCK,
                isNew: false,
            }
        ]
    });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [units, setUnits] = useState<SelectOption[]>([]);
    const [categories, setCategories] = useState<SelectOption[]>([]);

    function resetForm(): void {
        setProductData({
            name: '',
            category: '',
            id: '',
            taxCode: '',
            description: '',
            packages: [
                {
                    unit: DEFAULT_UNIT,
                    quantity: 1,
                    sku: '',
                    description: '',
                    prices: [
                        {
                            pricePerUnit: 0,
                            isNew: false,
                        }
                    ],
                    availability: EProductAvailability.IN_STOCK,
                    isNew: false,
                }
            ]
        });
        setError({});
    }

    function resetDeleteData(): void {
        setDeletedData({
            name: '',
            category: '',
            id: '',
            taxCode: '',
            description: '',
            packages: [
                {
                    unit: DEFAULT_UNIT,
                    quantity: 1,
                    sku: '',
                    description: '',
                    prices: [
                        {
                            pricePerUnit: 0,
                            isNew: false,
                        }
                    ],
                    availability: '',
                    isNew: false,
                }
            ]
        });
        setError({});
    }

    useEffect(() => {
        if (isEditing) {
            setIsLoading(true);
            fetchSupplierCatalogProduct(catalogID, productID)
                .then((productResponse: ProductData) => {
                    setProductData(productResponse);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    console.error('Product could not be found');
                });
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const categoriesPromise = getCategories();
        const unitsPromise = getUnits();
        Promise.all([categoriesPromise, unitsPromise])
            .then(([{ categories }, { units }]) => {
                setCategories(categories.map((category) => ({ value: category, label: category })));
                setUnits(units.map((unit:string) => ({ value: unit, label: unit })));
                setIsLoading(false);
            });
    }, []);

    function closeModal(): void {
        resetForm();
        close();
    }

    function addNewPackage(): void {
        const prod = { ...productData };
        prod.packages.push({
            description: '',
            quantity: 1,
            unit: units[0]?.value as string || '',
            prices: [
                {
                    pricePerUnit: 0,
                    isNew: true,
                }
            ],
            sku: '',
            availability: EProductAvailability.IN_STOCK,
            isNew: true,
        });

        setProductData(prod);
    }

    function deletePackage(index): void {
        const prod = { ...productData };
        const arr = { ...deletedData };
        arr.packages.push({ ...productData.packages[index] });
        setDeletedData(arr);
        prod.packages.splice(index, 1);
        setProductData(prod);
    }

    function onCreateProductError(data): void {
        if (data.error.includes('already exists')) {
            setError({
                sku: true
            });
        } else {
            setError({
                network: true
            });
        }

        setIsLoading(false);
    }

    async function onSubmit(e): Promise<void> {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        let isError = false;
        let success = 0;

        // Check required fields
        const errors: SingleProductErrors = {
            packages: Array<ProductPackageErrors>(productData.packages.length),
        };

        errors.network = false;

        if (!productData.name || productData.name === '') {
            errors.name = true;
            isError = true;
        }

        if (!productData.category || productData.category === NONE_SELECTED) {
            errors.category = true;
            isError = true;
        }

        // Packages
        for (let i = 0; i < productData.packages.length; i++) {
            const pack = productData.packages[i];
            const packageErrors: ProductPackageErrors = {};
            if (!pack.sku || pack.sku === '') {
                packageErrors.sku = true;
                isError = true;
            }

            if (!pack.quantity || pack.quantity <= 0) {
                packageErrors.quantity = true;
                isError = true;
            }

            if (!pack.unit || pack.unit === '' || pack.unit === NONE_SELECTED) {
                packageErrors.unit = true;
                isError = true;
            }

            errors.packages[i] = packageErrors;
        }

        if (isError) {
            setError(errors);
            setIsLoading(false);
            return;
        }
        try {
            if (isEditing) {
            // Update base product
                await updateProduct({
                    data: productData,
                    supplierCatalogId: catalogID
                });
                success++;

                // Packages
                for (let i = 0; i < productData.packages.length; i++) {
                    const pack = productData.packages[i];
                    if (!pack.isNew) {
                        await updateProductPackage({
                            productID: productData.id,
                            pack: { ...pack },
                            supplierCatalogID: catalogID
                        });
                        success++;
                    } else {
                        await createProductPackage({
                            productID: productData.id,
                            pack: { ...pack },
                            supplierCatalogID: catalogID
                        });
                        success++;
                    }
                }
                // Prices
                for (let i = 0; i < productData.packages.length; i++) {
                    const pack = productData.packages[i];
                    for (let j = 0; j < pack.prices.length; j++) {
                        const price = pack.prices[j];
                        if (!price.isNew) {
                            await updateProductPackagePrice({ productID: productData.id,
                                packageID: pack.id,
                                priceID: price.id,
                                pricePerUnit: productData.packages[i].prices[0].pricePerUnit,
                                supplierCatalogID: catalogID });
                            success++;
                        }
                    }
                }
                // Deleted packages
                for (let i = 0; i < deletedData.packages.length; i++) {
                    const dPack = deletedData.packages[i];
                    if (deletedData.packages[i].id && !dPack.isNew) {
                        await deleteProductPackage({
                            productID: productData.id,
                            pack: { ...dPack },
                            supplierCatalogID: catalogID
                        });
                    }
                    success++;
                }
                close();
            } else {
                sendTrackingEvent(TrackingEvents.createProductClicked);
                await createProduct({ ...productData }, catalogID);
                success++;
                completeTask(buyer.urlsafe, EOnboardingTasks.CREATE_CATALOG);
                close();
            }
            if (success) {
                toast.show({
                    message: isEditing ? 'Product has been edited' : 'Product created',
                });
            }
            setIsLoading(false);
            resetDeleteData();
        } catch (error) {
            onCreateProductError(error);
            setIsLoading(false);
        }
    }

    return (
        <>
            <Modal
                title= {isEditing ? 'Edit Product' : 'Add Product'}
                modalSize={onMobileSetLarge('MEDIUM', true)}
                titleSeparatorMobile
                titleSeparatorDesktop
                desktopModalWidth="lg:w-[1000px]"
                isOpen={isOpen}
                close={close}>
                <form onSubmit={onSubmit}>
                    <div className="flex flex-col gap-y-6 gap-x-6 m-8">
                        <Typography desktopSize="text-3"weight="font-semi-bold">{tCommon('Buttons.general')}</Typography>
                        <div className="flex flex-row gap-x-4">
                            <Input
                                className="w-full"
                                label={tCommon('Labels.productName')}
                                type="TEXT"
                                value={productData.name || ''}
                                onChange={(e): void => setProductData({ ...productData, name: e.target.value })}
                                isInvalid={error?.name}
                                invalidMessage={t('invalidName')}
                                required
                                disabled={isLoading}/>
                        </div>
                        <Input
                            label={tCommon('Labels.description')}
                            value={productData.description || ''}
                            onChange={(e): void => setProductData({ ...productData, description: e.target.value })}
                            disabled={isLoading}/>
                        <div className="flex flex-row gap-x-4">
                            <Select
                                className="w-1/2"
                                label={tCommon('Labels.category')}
                                value={productData.category || ''}
                                placeholder={t('selectCategory')}
                                onChange={(e): void => setProductData({ ...productData, category: e.target.value })}
                                options={categories}
                                id="category"
                                disabled={isLoading}
                                isInvalid={error?.category}
                                invalidMessage={t('invalidCategory')}
                                required/>
                            <Select
                                label={tCommon('Labels.taxCode')}
                                className="w-1/2"
                                placeholder="Select a tax code"
                                value={!productData.taxCode ? FULLY_TAX_CODE : productData.taxCode}
                                options={TaxCodeOptions}
                                onChange={(e): void => {
                                    if (e.target.value === NONE_SELECTED) {
                                        // this will prevent to select none option in the select and reset to previous value
                                        setProductData({ ...productData, taxCode: productData.taxCode });
                                    } else {
                                        setProductData({ ...productData, taxCode: e.target.value });
                                    }
                                }}
                                disabled={isLoading}/>
                        </div>
                    </div>
                    <Separator/>
                    <Typography desktopSize="text-3" weight="font-semi-bold" className="pl-8 pt-8 pb-2">{tCommon('Labels.sizes')}</Typography>
                    <div className="flex flex-row mt-5 mb-2.5">
                        <Typography className="ml-8 text-gray-600">{tCommon('Labels.description')}</Typography>
                        <Typography className="ml-[144px] text-gray-600">{tCommon('Labels.quantity')}</Typography>
                        <Typography className="ml-[80px] text-gray-600">{tCommon('Labels.unitOfMeasure')}</Typography>
                        <Typography className="ml-[32px] text-gray-600">{tCommon('Labels.unitPrice')}</Typography>
                        <Typography className="ml-[70px] text-gray-600">{tCommon('Labels.sku')}</Typography>
                        <Typography className="ml-[108px] text-gray-600">{tCommon('Labels.status')}</Typography>
                    </div>
                    <Separator />
                    {productData.packages?.map((currentPackage, i) => (
                        <div key={i} className="flex flex-row pt-5 pl-8 hover:bg-gray-25">
                            <div className="flex flex-row pb-5 gap-x-4">
                                <Input
                                    className="w-[200px]"
                                    value={currentPackage.description || ''}
                                    onChange={(e): void => {
                                        const prod = { ...productData };
                                        prod.packages[i].description = e.target.value;
                                        setProductData({ ...prod });
                                    }}
                                    type="TEXT"
                                    disabled={isLoading}/>
                                <Input
                                    type="NUMBER"
                                    className="w-[120px]"
                                    value={currentPackage.quantity || ''}
                                    onChange={(e): void => {
                                        const prod = { ...productData };
                                        prod.packages[i].quantity = parseFloat(e.target.value);
                                        setProductData(prod);
                                    }}
                                    required
                                    isInvalid={error?.packages?.[i]?.quantity}
                                    invalidMessage="Enter a number greater than 0"
                                    disabled={isLoading}/>
                                <Select
                                    className="w-[120px]"
                                    placeholder={t('selectUnit')}
                                    options={units}
                                    id="unit"
                                    value={currentPackage.unit || ''}
                                    isInvalid={error?.packages?.[i]?.unit}
                                    invalidMessage={t('invalidUnit')}
                                    onChange={(e): void => {
                                        const prod = { ...productData };
                                        prod.packages[i].unit = e.target.value;
                                        setProductData(prod);
                                    }}
                                    required
                                    disabled={isLoading}/>
                                <Input
                                    className="w-[120px]"
                                    type="CURRENCY"
                                    value={currentPackage.prices[0].pricePerUnit || ''}
                                    onChange={(e): void => {
                                        const prod = { ...productData };
                                        prod.packages[i].prices[0].pricePerUnit = parseFloat(e.target.value);
                                        setProductData(prod);
                                    }}
                                    placeholder="0.00"
                                    disabled={isLoading}/>
                                <Input
                                    className="w-[120px]"
                                    value={currentPackage.sku || ''}
                                    onChange={(e): void => {
                                        const prod = { ...productData };
                                        prod.packages[i].sku = e.target.value;
                                        setProductData(prod);
                                    }}
                                    type="TEXT"
                                    isInvalid={error?.packages?.[i]?.sku}
                                    invalidMessage="Enter a SKU"
                                    required
                                    disabled={isLoading}/>
                                <div className="w-[80px]">
                                    <Popover
                                        className="w-44"
                                        placement="bottom-end"
                                        button={
                                            <Badge className={'mt-2 w-fit whitespace-nowrap cursor-pointer'}
                                                variant={productData.packages[i].availability === EProductAvailability.IN_STOCK ? 'GREEN' : 'GRAY'}
                                                stopPropagation={false}
                                                size={'MEDIUM'}
                                                iconRight={<ChevronDownIcon className="w-4 h-4"/>}>
                                                {productData.packages[i].availability === EProductAvailability.IN_STOCK ? 'In stock' : 'Out of stock'}
                                            </Badge>
                                        }
                                        items={[
                                            {
                                                label: <Badge className={'w-fit'}
                                                    stopPropagation = {false}
                                                    variant={'GREEN'}
                                                    size={'MEDIUM'}>
                                                    {'In stock'}
                                                </Badge>,
                                                onClick: (): void => {
                                                    const prod = { ...productData };
                                                    prod.packages[i].availability = EProductAvailability.IN_STOCK;
                                                    setProductData(prod);
                                                },
                                            },
                                            {
                                                label: <Badge className={'w-fit'}
                                                    stopPropagation = {false}
                                                    variant={'GRAY'}
                                                    size={'MEDIUM'}>
                                                    {'Out of stock'}
                                                </Badge>,
                                                onClick: (): void => {
                                                    const prod = { ...productData };
                                                    prod.packages[i].availability = EProductAvailability.OUT_OF_STOCK;
                                                    setProductData(prod);
                                                },
                                            },
                                        ]}/>
                                </div>
                            </div>
                            {(productData.packages.length !== 1) && <Button variant="ICON"
                                size="ICON_MEDIUM"
                                as="div"
                                className="mb-[18px] ml-12 cursor-pointer"
                                onClick= {(): void => { deletePackage(i); }}>
                                <div className="h-4 w-4 text-gray-600"><TrashIcon/></div>
                            </Button>}

                        </div>
                    ))}
                    <Separator />
                    <Button className="my-5 w-full cursor-pointer"
                        variant={'LINK'}
                        size={'NO_BACKGROUND'}
                        as="div"
                        onClick={addNewPackage}
                        disabled={isLoading}>
                        <div className="flex gap-3 items-center pl-6">
                            <AddIcon className="w-4 h-4 text-teal-300"/>
                            <Typography className="mb-0" weight="font-medium">{tCommon('Buttons.addNewSize')}</Typography>
                        </div></Button>
                    <Separator />
                    <div className="mt-6 w-full">
                        {error?.network
                                && <Typography as="div"
                                    className="text-red-300 mt-2 text-center w-full">
                                    {tCommon('somethingWentWrong')} {tCommon('tryAgain')}
                                </Typography>}
                        <div className="flex flex-row justify-end mt-6 mr-8 gap-x-2">
                            <Button
                                variant="TERTIARY_FILLED"
                                size="MEDIUM"
                                as="div"
                                className="w-full lg:w-fit mb-12 lg:m-0 cursor-pointer"
                                onClick={closeModal}
                                disabled={isLoading}>
                                <Typography as="span" weight="font-medium">
                                    {tCommon('Buttons.cancel')}
                                </Typography>
                            </Button>
                            <div className="w-full lg:w-auto lg:flex lg:flex-row-reverse">
                                <Button
                                    type="submit"
                                    variant="SECONDARY"
                                    size="MEDIUM"
                                    className="w-full lg:w-fit mt-6 mb-4 lg:m-0 lg:ml-2"
                                    disabled={isLoading}>
                                    <Typography as="span" weight="font-medium">
                                        {isEditing ? tCommon('Buttons.save') : tCommon('Buttons.create')}
                                    </Typography>
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
        </>
    );
};

import React from 'react';
import { Separator, Typography } from '@notch-ordering/ui-components';
import { EErpPlatform, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import cx from 'classnames';
import { Tabs, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { integrationNameKeys } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import { tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';

export const ScanOverviewTabs : React.FC = () => {
    const { draftInvoice } = useOcrInvoiceStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const { selectedTab, setSelectedTab, setPrevSelectedTab } = useOcrInvoiceContext();
    const selectedTabIndicator = <div className="mt-2.5">
        <Separator className="bg-gray-700" />
        <Separator className="bg-gray-700" />
    </div>;
    const accountingPlatform = integrationNameKeys(draftInvoice?.accountingConfiguration?.data?.platform);
    const imsPlatform = integrationNameKeys(EErpPlatform.OPTIMUMCONTROL);

    return (
        <div className="px-8 pt-3">
            {/* Integration Tabs */}

            <div className="flex flex-row gap-6">
                {/* "Accounting Tab" */}
                <div className="cursor-pointer"
                    onClick={(): void => {
                        setSelectedTab(Tabs.ACCOUNTING);
                        setPrevSelectedTab(Tabs.ACCOUNTING);
                    }}>
                    <Typography
                        as="div"
                        className={cx({ 'font-regular': selectedTab !== Tabs.ACCOUNTING, 'font-medium': selectedTab === Tabs.ACCOUNTING })}>
                        {t(accountingPlatform)}
                    </Typography>
                    {selectedTab === Tabs.ACCOUNTING && selectedTabIndicator}
                </div>
                {/* "IMS Tab" */}
                <div className="cursor-pointer"
                    onClick={(): void => {
                        setSelectedTab(Tabs.IMS);
                        setPrevSelectedTab(Tabs.IMS);
                    }}>
                    <Typography
                        as="div"
                        className={cx({ 'font-regular': selectedTab !== Tabs.IMS, 'font-medium': selectedTab === Tabs.IMS })}>
                        {t(imsPlatform)}
                    </Typography>
                    {selectedTab === Tabs.IMS && selectedTabIndicator}
                </div>
            </div>
        </div>
    );
};

import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';

const AddCustomItemMessage = ({ onClick, background, itemsLength }) => {
  return (
    <section
      className={cx('cart-order-custom-item', {
        'bg-checkout-gray': background,
      })}
    >
      <p className="cart-order-custom-item-label">
        {!itemsLength
          ? 'Can’t find product you need?'
          : `Custom product${itemsLength > 1 ? 's' : ''} added by you.`}{' '}
        <button className="cart-order-custom-item-button" onClick={onClick}>
          {!itemsLength ? 'Add a custom product' : 'Add more'}
        </button>
      </p>
    </section>
  );
};

AddCustomItemMessage.defaultProps = {
  background: false,
  itemsLength: 0,
};

AddCustomItemMessage.propTypes = {
  onClick: PropTypes.func.isRequired,
  background: PropTypes.bool,
  itemsLength: PropTypes.number,
};

export default AddCustomItemMessage;

import React from 'react';
import { AddToCartCard, IAddToCartCardProps } from '@v2/components/Market/AddToCartCard/AddToCartCard';
import { Modal, onMobileSetLarge } from '@notch-ordering/ui-components';

export type Props = {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
} & IAddToCartCardProps;

export const AddToCartModal: React.FC<Props> = ({ isOpen, setIsOpen, isLoading }) => (
    <Modal
        isOpen={isOpen}
        modalSize={onMobileSetLarge('SMALL')}
        hideHeader
        close={():void => setIsOpen(false)}>
        <div className="mt-5">
            <AddToCartCard
                setIsOpen={setIsOpen}
                isLoading={isLoading}
                onCancelClick={(): void => setIsOpen(false)}/>
        </div>
    </Modal>
);

export default AddToCartModal;

import React, { useMemo } from 'react';
import cx from 'classnames';

import { Typography } from '@notch-ordering/ui-components';
import packageJson from '../../../../package.json';

type Props = {
    className?: string,
};

export const HomeFooter: React.FC<Props> = ({ className }) => {
    const year = useMemo(() => new Date().getFullYear(), []);
    const environment = useMemo(() => (process.env.DEPLOY_ENV !== 'development' ? 'Release' : 'Development'), []);

    return (
        <Typography as="div" variant="BASE" className={cx('text-center p-2 text-gray-600', className)} >
            Copyright &copy; notch {year} {environment} {packageJson.version}
        </Typography>
    );
};

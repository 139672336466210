import { Button, Popover } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import MoreIcon from '@icons/more-icon.svg';
import BrokenLinkIcon from '@icons/link-broken.svg';
import SettingIcon from '@icons/setting-icon.svg';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { RutterSubsidiary, SupplierMappings, VendorDetails } from '@v2/network/BushwhackAPI';
import { ApClassSchema, AccountingConfiguration, EApPlatform, TaxCodeSchema, EErpPlatform } from '@notch-ordering/shared-logic';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { useApPlatform } from '@v2/hooks/useApPlatform.hook';
import { ManageIntegrationModal } from './ManageIntegrationModal';
import { DisconnectIntegrationModal } from './DisconnectIntegrationModal';
import { integrationNameKeys } from './IntegrationPlatformUtils';

export interface Props {
    integration: AccountingConfiguration,
    integrationPlatform?: EApPlatform | EErpPlatform,
    refetch: () => void,
    onSave?: (supplierMappings: SupplierMappings) => void,
    onConfigUpdate: (config: AccountingConfiguration) => void,
    notchSuppliers?: SupplierCatalogData[],
    accountingVendors?: VendorDetails[],
    subsidiaries?: RutterSubsidiary[],
    supplierMappingDefaults?: SupplierMappings,
    taxCodes?: TaxCodeSchema[],
    accountingClasses?: ApClassSchema[],
    companyName?: string,
    isInitialSyncComplete: boolean,
}

export const IntegrationsMoreButton: React.FC<Props> = ({
    integration,
    integrationPlatform,
    refetch,
    onSave,
    onConfigUpdate,
    notchSuppliers,
    accountingVendors,
    subsidiaries,
    supplierMappingDefaults,
    taxCodes,
    accountingClasses,
    companyName,
    isInitialSyncComplete,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const [showManageModel, setShowManageModal] = useState(false);
    const [showDisconnectModal, setShowDisconnectModal] = useState(false);

    const integrationType = integrationPlatform ? t(integrationNameKeys(integrationPlatform)) : '';
    const platform = useApPlatform();
    return (
        <div>
            <Popover
                className="w-[200px]"
                button=
                    {<Button variant="ICON"
                        size="NO_BACKGROUND"
                        stopPropagation={false}>
                        <div className={'w-4 h-4'}>
                            <MoreIcon title="..." />
                        </div>
                    </Button>}
                items={[
                    {
                        label: <div className="flex flex-row items-center gap-x-3">
                            <SettingIcon className="w-4 h-4" />
                            {tCommon('Buttons.manage')}
                        </div>,
                        onClick: (): void => {
                            setShowManageModal(true);
                        },
                    },
                    {
                        label: <div className="flex flex-row items-center gap-x-3">
                            <BrokenLinkIcon className="w-4 h-4" />
                            {tCommon('Buttons.disconnect')}
                        </div>,
                        className: 'text-red-300',
                        onClick: (): void => {
                            setShowDisconnectModal(true);
                        },
                    },
                ]}/>
            {showManageModel && <ManageIntegrationModal
                isOpen={showManageModel}
                refetch={refetch}
                close={(): void => { setShowManageModal(false); refetch(); }}
                onConfigUpdate={onConfigUpdate}
                integration={integration}
                integrationType={integrationType}
                notchSuppliers={notchSuppliers}
                accountingVendors={accountingVendors}
                subsidiaries={subsidiaries}
                supplierMappingDefaults={supplierMappingDefaults}
                taxCodes={taxCodes}
                accountingClasses={accountingClasses}
                onSave={onSave}
                companyName={companyName}
                isInitialSyncComplete={isInitialSyncComplete}/>}

            <DisconnectIntegrationModal
                isOpen={showDisconnectModal}
                refetch={refetch}
                close={(): void => { setShowDisconnectModal(false); }}
                disconnectSuccess={(): void => { setShowDisconnectModal(false); }}
                accessToken={integration?.data?.accessToken}
                platform={platform}
                integrationType={integrationType} />
        </div>
    );
};

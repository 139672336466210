import { OrgData } from '@v2/types/OrgData';
import { useSelector } from 'react-redux';
import { RootState } from '..';

const useOrgHook = ():{ org: OrgData } => {
    const org: OrgData = useSelector((state: RootState) => state.accountReducer.org);

    return {
        org,
    };
};

export default useOrgHook;

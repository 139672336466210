import { useQueryClient } from '@tanstack/react-query';
import { useHomeStore } from '@v2/stores/HomeStore';
import { BuyerData } from '@v2/types/BuyerData';
import { useDispatch, useSelector } from 'react-redux';
import { EPaths } from '@v2/constants/EPaths';
import { isPlatform } from '@ionic/react';
import { selectDefaultBuyer } from '@/actions/buyerActions';
import { RootState } from '..';
import { EIonicPlatforms } from '@/constants/Mobile';

const useSwitchBuyerHook = ():RootState => {
    const { defaultBuyer } = useSelector((store: RootState) => store.buyerReducer);
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const targetBuyerParam = 'targetBuyer';
    const sessionKeyParam = 'sessionKey';

    /**
     * Switch the active buyer/location/venue
     *
     * @param targetBuyer - The buyer to switch to
     * @param goHome - Redirect to the home page
     */
    function switchBuyer(targetBuyer: BuyerData, goHome: boolean = false): void {
        if (targetBuyer && targetBuyer.buyerKey !== defaultBuyer.buyerKey) {
            /**
             * 1. Invalidate queries to clear caching
             * 2. Clear local storage
             * 3. Change the active buyer in the app
             * 4. Refresh to reinitialize with the correct buyer
             */
            queryClient.invalidateQueries();
            useHomeStore.persist.clearStorage();
            dispatch(selectDefaultBuyer(targetBuyer));

            if (window.location.search.includes(sessionKeyParam)) {
                // When switching after using an impersonation link, clear the url
                window.location.search = '';
            } else if (window.location.search.includes(targetBuyerParam)) {
                // Remove targetBuyer from the url leaving the other query params
                let search = window.location.search.substring(1); // Remove the starting '?'
                const params = search.split('&');
                search = '';
                params.forEach((p) => {
                    if (!p.includes(targetBuyerParam)) {
                        search = search === '' ? `?${p}` : `${search}&${p}`;
                    }
                });

                window.location.search = search;
            } else if (goHome) {
                const route = `${EPaths.HOME}?switchedLocation=true`;
                if (isPlatform(EIonicPlatforms.CAPACITOR)) {
                    window.location.href = route;
                } else {
                    window.location.href = `../..${route}`;
                }
            } else {
                window.location.reload();
            }
        }
    }

    return {
        switchBuyer
    };
};

export default useSwitchBuyerHook;

import i18n, { tNamespace } from '@v2/i18n';
import { GetOrdersParams } from '@v2/network/LegacyAPI';
import moment from 'moment/moment';

export type OrdersFilterDateRange = {
    startDate: string,
    endDate: string,
    type?: FilterDateTypeValue,
};

export type FilterSelectionItem = {
    label?: string,
    value?: string,
};

export type TOrdersPagination = {
    page: number,
    limit: number,
    offset?: number,
};

export type TOrdersSortBy = 'deliveryDay' | 'vendorName' | 'total';
export type TOrdersSortOrder = 'asc' | 'desc';

export const tFilters = i18n.getFixedT(null, tNamespace, 'Orders');

export const OrdersSortingOptions = [
    {
        label: tFilters('Sort.deliveryDayNewtoOld'),
        value: '-deliveryDay'
    },
    {
        label: tFilters('Sort.deliveryDayOldtoNew'),
        value: 'deliveryDay'
    },
    {
        label: tFilters('Sort.vendorNameAtoZ'),
        value: 'vendorName'
    },
    {
        label: tFilters('Sort.vendorNameZtoA'),
        value: '-vendorName'
    },
    {
        label: tFilters('Sort.totalHightoLow'),
        value: '-total'
    },
    {
        label: tFilters('Sort.totalLowtoHigh'),
        value: 'total'
    },
];

export const dateToString = (date: moment.Moment): string => date.format('YYYY-MM-DD');
export const FILTERS = {
    DATE: {
        ALL: '',
        LAST_MONTH: 'last_month',
        THIS_MONTH: 'this_month',
        LAST_THREE_MONTHS: 'last_three_months',
        LAST_SIX_MONTHS: 'last_six_months',
        CUSTOM: 'custom',
    },
} as const;
export const FILTER_TYPE = {
    DUE_DATE: 'dueDate',
    DELIVERY_DATE: 'deliveryDate',
    CUSTOM: 'custom',
    VENDOR: 'vendor',
    PAYMENT_STATUS: 'paymentStatus',
    RECEIVAL_STATUS: 'receivalStatus',
    SEARCH_TERM: 'searchTerm',
} as const;

export type FilterType = keyof GetOrdersParams;
export type FilterSelection = {
    label: string,
    type: string,
    items: FilterSelectionItem[],
};

export const DEFAULT_ORDERS_FILTERS = {
    DATE: {
        ALL: '',
        LAST_MONTH: 'last_month',
        THIS_MONTH: 'this_month',
        LAST_THREE_MONTHS: 'last_three_months',
        LAST_SIX_MONTHS: 'last_six_months',
        CUSTOM: 'custom',
    },
} as const;

export type FilterDateTypeKey = keyof typeof DEFAULT_ORDERS_FILTERS['DATE'];
export type FilterDateTypeValue = typeof DEFAULT_ORDERS_FILTERS.DATE[FilterDateTypeKey];

export const OrdersDateFilters: FilterSelection = {
    type: FILTER_TYPE.DELIVERY_DATE,
    label: tFilters('deliveryDay'),
    items: [
        { label: tFilters('thisMonth'), value: DEFAULT_ORDERS_FILTERS.DATE.THIS_MONTH },
        { label: tFilters('lastMonth'), value: DEFAULT_ORDERS_FILTERS.DATE.LAST_MONTH },
        { label: tFilters('lastThreeMonths'), value: DEFAULT_ORDERS_FILTERS.DATE.LAST_THREE_MONTHS },
        { label: tFilters('lastSixMonths'), value: DEFAULT_ORDERS_FILTERS.DATE.LAST_SIX_MONTHS },
        { label: tFilters('customDateRange'), value: DEFAULT_ORDERS_FILTERS.DATE.CUSTOM },
    ],
};

export const getStartEndDate = (dateFilter: string):OrdersFilterDateRange => {
    const dates: OrdersFilterDateRange = {
        startDate: dateToString(moment()),
        endDate: dateToString(moment()),
    };

    const month = 'month';

    switch (dateFilter) {
        case DEFAULT_ORDERS_FILTERS.DATE.THIS_MONTH:
            dates.startDate = dateToString(moment().startOf(month));
            dates.endDate = dateToString(moment().endOf(month));
            break;
        case DEFAULT_ORDERS_FILTERS.DATE.LAST_MONTH:
            dates.startDate = dateToString(moment().subtract(1, month).startOf(month));
            dates.endDate = dateToString(moment().subtract(1, month).endOf(month));
            break;
        case DEFAULT_ORDERS_FILTERS.DATE.LAST_THREE_MONTHS:
            dates.startDate = dateToString(moment().subtract(2, month).startOf(month));
            dates.endDate = dateToString(moment().endOf(month));
            break;
        case DEFAULT_ORDERS_FILTERS.DATE.LAST_SIX_MONTHS:
            dates.startDate = dateToString(moment().subtract(5, month).startOf(month));
            dates.endDate = dateToString(moment().endOf(month));
            break;
        default:
            return { startDate: '', endDate: '' };
    }
    return dates;
};

export const formatDateRange = (dateRange:OrdersFilterDateRange, format = 'MM/DD/YYYY'):string => `${moment(dateRange.startDate).format(format)} - ${moment(dateRange.endDate).format(format)}`;

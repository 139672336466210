import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import CartMain from '../../components/cart/CartMain.jsx';

import * as ordersSelectors from '../../selectors/ordersSelectors';
import * as checkoutSelectors from '../../selectors/checkoutSelectors';

import * as OrderActions from '../../actions/orderActions';
import * as CartActions from '../../actions/cartActions';
import * as ModalsActions from '../../actions/modalsActions';
import * as SearchActions from '../../actions/searchActions';
import * as RouteActions from '../../actions/routerActions';

import { AnalyticsEventSources } from '../../constants/AnalyticsEventSources.js';

class CartContainer extends Component {
  componentWillUnmount() {
    const { cartActions } = this.props;

    // Set cart to close when the component unmounts
    cartActions.showCart(false);
  }

  changeQuantity = (orderUrlsafe, orderProduct, quantity, type) => {
    const { orderActions } = this.props;
    const isFromCart = true;

    if (orderProduct && orderProduct.urlsafe) {
      orderActions.changeQuantityWithID(
        orderUrlsafe,
        orderProduct,
        quantity,
        type,
        AnalyticsEventSources.CART,
        isFromCart
      );
    } else {
      // orderProduct would already exists, but not the product (hence undefined)
      orderActions.changeQuantityWithoutID(
        orderUrlsafe,
        orderProduct,
        quantity,
        type,
        AnalyticsEventSources.CART,
        undefined,
        isFromCart
      );
    }
  };

  onClickEdit = (order_urlsafe, orderProduct) => {
    const { orders, orderActions } = this.props;

    orderActions.onClickEdit(orders, order_urlsafe, orderProduct);
  };

  saveNoteCart = (order_urlsafe, orderProduct, note) => {
    const { orderActions } = this.props;

    orderActions.saveNoteCart(order_urlsafe, orderProduct, note);
  };

  toggleIsCheckoutOrder = (order_urlsafe, isCheckout) => {
    const { orderActions, orders } = this.props;

    orderActions.toggleIsCheckoutOrder(orders, order_urlsafe, isCheckout);
  };

  toggleCartManagersModal = () => {
    const { cartActions, isShowCartManagersModal } = this.props;

    cartActions.toggleCartManagersModal(isShowCartManagersModal);
  };

  toggleBuyerSuspendedModal = () => {
    const { modalsActions } = this.props;

    modalsActions.toggleBuyerSuspendedModal();
  };

  proceedToCheckout = (orderId) => {
    const { cartActions } = this.props;

    cartActions.proceedToCheckout(orderId);
  };

  changeVendorFilters = (vendorName, vendorID) => {
    const { searchActions, selectedVendors } = this.props;

    searchActions.changeVendorFilters(vendorID, vendorName, selectedVendors);
  };

  changeRoute = (location) => {
    const { routerActions } = this.props;

    routerActions.changeRoute(location);
  };

  render() {
    const { ordersWithItems } = this.props;

    return (
      <CartMain
        {...this.props}
        orders={ordersWithItems}
        changeQuantity={this.changeQuantity}
        onClickEdit={this.onClickEdit}
        saveNoteCart={this.saveNoteCart}
        toggleIsCheckoutOrder={this.toggleIsCheckoutOrder}
        toggleCartManagersModal={this.toggleCartManagersModal}
        toggleBuyerSuspendedModal={this.toggleBuyerSuspendedModal}
        proceedToCheckout={this.proceedToCheckout}
        changeVendorFilters={this.changeVendorFilters}
        changeRoute={this.changeRoute}
      />
    );
  }
}

CartContainer.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.any).isRequired,
  ordersWithItems: PropTypes.arrayOf(PropTypes.any).isRequired,
  isShowCartManagersModal: PropTypes.bool.isRequired,
  cartActions: PropTypes.objectOf(PropTypes.any).isRequired,
  orderActions: PropTypes.objectOf(PropTypes.any).isRequired,
  modalsActions: PropTypes.objectOf(PropTypes.any).isRequired,
  searchActions: PropTypes.objectOf(PropTypes.any).isRequired,
  routerActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStatetoProps(state, props) {
  const { selectedVendors } = state.searchReducer;

  return {
    ...state.cartReducer,
    ...state.ordersReducer,
    ...state.buyerReducer,
    ...state.modalsReducer,
    ...state.vendorsReducer,
    ...state.suppliersReducer,

    ordersWithItems: ordersSelectors.filterOrdersWithItems(state),
    additionalOrderTotals: checkoutSelectors.getAdditionalOrderTotals(
      state,
      props
    ),
    selectedVendors,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(OrderActions, dispatch),
    cartActions: bindActionCreators(CartActions, dispatch),
    modalsActions: bindActionCreators(ModalsActions, dispatch),
    searchActions: bindActionCreators(SearchActions, dispatch),
    routerActions: bindActionCreators(RouteActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(CartContainer);

import PropTypes from 'prop-types';
import React from 'react';

import CartOrderCustomItem from './CartOrderCustomItem.jsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteCustomItem,
  updateCustomItemQuantity,
} from '../../../actions/orderActions';

const CartOrderCustomItems = ({ urlsafe, orderItems }) => {
  const dispatch = useDispatch();
  const checkoutView = useSelector(
    (state) => state.checkoutReducer.checkoutView
  );
  // disable the edit if order is in review mode
  const isDisabled = checkoutView === 'reviewView';
  const onRemoveItem = (productId) => {
    dispatch(deleteCustomItem(urlsafe, productId));
  };

  const onChangeQty = (qty, productId) => {
    dispatch(updateCustomItemQuantity(urlsafe, productId, qty));
  };

  return (
    <div className="cart-order-items">
      {orderItems.map((orderItem, index) => {
        return (
          <CartOrderCustomItem
            onChangeQty={onChangeQty}
            onRemove={onRemoveItem}
            key={orderItem.id}
            orderItemIndex={index}
            orderItem={orderItem}
            isDisabled={isDisabled}
          />
        );
      })}
    </div>
  );
};

CartOrderCustomItems.propTypes = {
  orderItems: PropTypes.array.isRequired,
};

export default CartOrderCustomItems;

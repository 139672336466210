import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';

import { browserHistory, Router } from 'react-router';

import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import { IonApp } from '@ionic/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactModal from 'react-modal';
import createRoutes from '../../routes';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

class Root extends Component {
  componentDidMount() {
    /* Globally tells ReactModal to set aira-hidden on main app when modals are open
     *  This is benefecial for screenreaders and accessibility in general
     */
    ReactModal.setAppElement('#root');

    const el = document.getElementById('root-loader');
    if (el) {
      el.classList.add('root-loader-hide');
    }
  }

  handleRouteChange = () => {
    const { protocol, host, pathname, search } = window.location;
    let newurl = search
      ? `${protocol}//${host}${pathname}${search}`
      : `${protocol}//${host}${pathname}`;
    let hasSearchParameters = !!search;
    let queryParameters = '';

    // Handle Query Parameters
    if (window.location) {
      // Handle Canary deployments
      if (window.canaryRelease) {
        if (!window.location.search.includes('canary')) {
          queryParameters += hasSearchParameters
            ? `&canary=${window.canaryRelease}`
            : `?canary=${window.canaryRelease}`;
          hasSearchParameters = true;
        }
      }

      // Handle Third party logos via fromSubdomain
      if (window.fromSubdomain) {
        if (!window.location.search.includes('from')) {
          queryParameters += hasSearchParameters
            ? `&from=${window.fromSubdomain}`
            : `?from=${window.fromSubdomain}`;
          hasSearchParameters = true;
        }
      }
    }

    newurl = queryParameters ? `${newurl}${queryParameters}` : newurl;
    window.history.replaceState({ path: newurl }, '', newurl);
  };

  render() {
    const { store } = this.props;
    const history = syncHistoryWithStore(browserHistory, store);
    const queryClient = new QueryClient()
    return (
      <IonApp>
        <QueryClientProvider client={queryClient}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.GOOGLE_RECAPTCHA_KEY}
          useEnterprise
        >
          <Provider store={store}>
            <Router
              history={history}
              routes={createRoutes(store)}
              onUpdate={this.handleRouteChange}
            />
          </Provider>
        </GoogleReCaptchaProvider>
        </QueryClientProvider>
      </IonApp>
    );
  }
}

Root.propTypes = {
  store: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default DragDropContext(HTML5Backend)(Root);

import PropTypes from "prop-types";
import React, {Component} from "react";

import Utils from "../../../utils";
import {BUCKET_ASSETS_STATIC_URL} from "@v2/constants/Assets";
import { Typography } from '@notch-ordering/ui-components';
/*
 ** Note: The editMode feature is only enabled if we have passed in the prop editable as true
 */
export default class ListItemCheckoutText extends Component {
  onClick = (index, event) => {
    event.preventDefault();

    // Send up which index got clicked
    this.props.onClick(index);
  };

  render() {
    const { order, item, index, itemSelected, className } = this.props;

    let style = {};
    let itemSelectedStyle;

    // ItemSelectedStyle is highlighted in green under regular conditions.
    // However, if the order is preOrderRestricted, then it is bordered in red
    itemSelectedStyle =
      order.preOrderRestricted && _.isEqual(item, itemSelected)
        ? { border: "1px solid #FF0000" }
        : {
            backgroundColor: "#F5ECFC",
            color: "#38087B",
            border: "1px solid #38087B",
            borderBottom: "4px solid #38087B",
          };

    // If the item === itemSelected, Highlight green
    style = _.isEqual(item, itemSelected)
      ? { ...style, ...itemSelectedStyle }
      : style;

    return (
      <div className="delivery-day-item-wrapper">
        <div
          className={className}
          onClick={(event) => this.onClick(index, event)}
        >
          <div className="checkout-list-item" style={style}>
            {_.isEqual(item, itemSelected) && (
              <img src={`${BUCKET_ASSETS_STATIC_URL}images/check%402x.png`} />
            )}
            <div>
              <Typography weight='font-medium' variant="LG-2" className="day-of-week">{item.dayOfWeek}</Typography>
              <Typography variant='BASE' className="date">
                {Utils.formatDate(item.date, "MMMM D")}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ListItemCheckoutText.propTypes = {
  order: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  itemSelected: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string.isRequired,
  isPendingOrders: PropTypes.bool,

  onClick: PropTypes.func.isRequired,
};

import React, { ReactNode } from 'react';
import { commonGridDef } from '@v2/Pages/Invoices/InvoicesConstants';
import { GridColDef, Typography } from '@notch-ordering/ui-components';
import { UploadAssetColumn } from '@v2/components/Uploads/UploadAssetRow/UploadAssetColumn';
import i18n, { tNamespace } from '@v2/i18n';
import { UploadsMobileTableCell } from '@v2/components/Uploads/UploadsMobileTableCell/UploadsMobileTableCell';
import { UploadOcrStateBadge } from '@v2/components/Uploads/UploadOcrStateBadge/UploadOcrStateBadge';
import { UploadOcrLabelBadge } from '@v2/components/Uploads/UploadOcrLabelBadge/UploadOcrLabelBadge';
import { UploadActionButtons } from '@v2/components/Uploads/UploadActionButtons/UploadActionButtons';
import { OcrInvoice } from '@v2/network/FalsePromiseApi';
import { UploadSyncStatus } from '@v2/components/Uploads/UploadSyncStatus/UploadSyncStatus';
import { EApPlatform, EErpPlatform, formatDate } from '@notch-ordering/shared-logic';
import { OcrLabel } from '@v2/types/UploadsTypes';
import EmptyIcon from '@images/integrations/empty-icon.svg';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import Utils from '@/utils';
import { integrationIcons } from '../Integrations/IntegrationPlatformUtils';

export const tUploadsFilters = i18n.getFixedT(null, tNamespace, 'Uploads.Filters');
export const tUploadsColumnHeaders = i18n.getFixedT(null, tNamespace, 'Uploads.TableColumnHeaders');
const uploadsGridDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
};

const syncStatusIconHeight = 'h-6';
const syncStatusIconWidth = 'w-6';

export const COLUMN_NO_VALUE = <span className="text-gray-600">—</span>;

export const UPLOAD_COLUMNS = {
    updatedAt: 'updatedAt',
    externalReferenceID: 'externalReferenceID',
    ocrParsedInvoiceData: 'ocrParsedInvoiceData',
    orderNumber: 'orderNumber',
    supplierName: 'supplierName',
    createdAt: 'createdAt',
    mobileOnly: 'mobileOnly',
    ocrState: 'ocrState',
    sync: 'sync',
    actions: 'actions'
} as const;

export const getColumns = (accountingPlatform: EApPlatform, erpPlatform: EErpPlatform, isInReviewTabSelected: boolean, greevilsBills: InvoiceData[]): GridColDef<OcrInvoice>[] => [{
    ...uploadsGridDef,
    field: UPLOAD_COLUMNS.externalReferenceID,
    headerName: tUploadsColumnHeaders('billNumber'),
    minWidth: 160,
    flex: 1,
    sortable: true,
    renderCell: ({
        value,
        row
    }): ReactNode => <UploadAssetColumn supplierName={row?.supplierName}
        urls={row?.cdnUrls ?? []}
        invoiceNumber={value}/>
},
{
    ...uploadsGridDef,
    headerName: tUploadsColumnHeaders('issuedDate'),
    field: UPLOAD_COLUMNS.ocrParsedInvoiceData,
    flex: 1,
    renderCell: ({ value }): ReactNode => {
        const id = Object.keys(value)[0];
        const invoiceData = value[id];
        const formattedDate = formatDate(invoiceData?.invoiceDate?.value, 'MMM dd, yyyy') || COLUMN_NO_VALUE;

        return <Typography as="div">{formattedDate}</Typography>;
    }
},
{
    ...uploadsGridDef,
    headerName: tUploadsColumnHeaders('orderNumber'),
    sortable: true,
    field: UPLOAD_COLUMNS.orderNumber,
    flex: 1,
    renderCell: ({ value }) => value ?? COLUMN_NO_VALUE

}, {
    ...uploadsGridDef,
    headerName: tUploadsColumnHeaders('supplier'),
    field: UPLOAD_COLUMNS.supplierName,
    sortable: true,
    flex: 1,
    renderCell: ({ value }) => <span className="truncate pr-2">{value}</span> ?? COLUMN_NO_VALUE

},
{
    ...uploadsGridDef,
    headerName: tUploadsColumnHeaders('uploadedDate'),
    field: UPLOAD_COLUMNS.createdAt,
    flex: 1,
    sortable: true,
    renderCell: ({ value }) => <Typography as="div">{Utils.formatDate(value, 'MMM DD, YYYY')}</Typography>
},
{
    ...uploadsGridDef,
    headerName: tUploadsColumnHeaders('verifiedDate'),
    field: UPLOAD_COLUMNS.updatedAt,
    flex: 1,
    sortable: true,
    renderCell: ({ value }) => <Typography as="div">{Utils.formatDate(value, 'MMM DD, YYYY')}</Typography>
},
{
    ...uploadsGridDef,
    headerName: tUploadsColumnHeaders('sync'),
    field: UPLOAD_COLUMNS.sync,
    flex: 1,
    renderCell: ({ row }): ReactNode => {
        const invoice = greevilsBills?.find((bill) => bill.invoiceNumber === row.externalReferenceID);
        let isAccountingSynced: boolean;

        if (accountingPlatform === EApPlatform.BIGCHIP) {
            isAccountingSynced = invoice?.isApprovedForBigChip;
        } else {
            isAccountingSynced = row.accountingSyncAt?.length > 0;
        }

        return <UploadSyncStatus
            accountingIcon={integrationIcons(accountingPlatform, syncStatusIconHeight, syncStatusIconWidth)}
            isAccountingSynced={isAccountingSynced}
            isImsSynced={row.imsSyncAt?.length > 0}
            erpIcon={integrationIcons(erpPlatform, syncStatusIconHeight, syncStatusIconWidth)}
            emptyIcon={<EmptyIcon className={`${syncStatusIconHeight} ${syncStatusIconWidth}`}/>} />;
    }
},
{
    ...uploadsGridDef,
    headerName: '',
    flex: 1,
    field: UPLOAD_COLUMNS.ocrState,
    headerClassName: 'hidden',
    cellClassName: 'overflow-visible',
    renderCell: ({ value, row }): ReactNode => {
        const rejectReason = row.metadata?.rejectReason as OcrLabel;

        return (rejectReason)
            ? <UploadOcrLabelBadge ocrLabel={rejectReason}/>
            : <UploadOcrStateBadge ocrState={value}/>;
    }
},
{
    ...uploadsGridDef,
    headerName: '',
    flex: 1,
    field: UPLOAD_COLUMNS.actions,
    cellClassName: 'overflow-visible', // This must be set for the popover to be visible
    headerClassName: 'hidden',
    renderCell: ({ row }): ReactNode => <UploadActionButtons ocrInvoice={row} isReviewAllowed={isInReviewTabSelected}/>
},
{
    ...uploadsGridDef,
    headerName: '',
    flex: 1,
    field: UPLOAD_COLUMNS.mobileOnly,
    headerClassName: 'hidden',
    renderCell: ({ row }): ReactNode => <UploadsMobileTableCell ocrInvoice={row}/>

}
// TODO: add action items on hover will be addressed on a separate ticket https://vendorhero.atlassian.net/browse/INV-36
// {
//     ...uploadsGridDef,
//     headerName: '',
//     align: 'center',
//     field: 'updatedAt',
//     flex: 0.35,
//     renderCell: ({ row }) => <UploadActionButtons ocrInvoice={row} />
// },
];

/**
 * Returns a model of column visibility for the upload grid.
 *
 @param {object} options - An object containing conditions for the visibility model, if needed more can be added.
 @param {boolean} options.isMobileWidth - Indicates whether the current width is considered mobile.
 @param {boolean} options.isApprovedTabSelected - Indicates whether the approved tab is currently selected.
 @param {boolean} options.shouldShowSyncColumn - Indicates whether the Sync column should be shown..
 @returns {Record<string, boolean>} A record of column names and their visibility status.
 */
export const getColumnsVisibilityModel = ({
    isMobileWidth,
    isApprovedTabSelected,
    shouldShowSyncColumn
}): Record<string, boolean> => {
    const {
        updatedAt,
        externalReferenceID,
        ocrParsedInvoiceData,
        orderNumber,
        supplierName,
        createdAt,
        mobileOnly,
        ocrState,
        sync,
        actions
    } = UPLOAD_COLUMNS;

    // if keys are not present in the object by default, all the columns are visible.
    const desktopVisibilityModel = {
        [updatedAt]: isApprovedTabSelected,
        [ocrState]: !isApprovedTabSelected,
        [sync]: shouldShowSyncColumn,
        [mobileOnly]: false,
    };

    // if a new column is added to the mobile view, it should be added here if it is not present in the mobile view by default.
    const mobileVisibilityModel = {
        [updatedAt]: false,
        [externalReferenceID]: false,
        [ocrParsedInvoiceData]: false,
        [orderNumber]: false,
        ocrState: false,
        [supplierName]: false,
        [createdAt]: false,
        [mobileOnly]: true,
        [sync]: false,
        [actions]: false,
    };

    return isMobileWidth ? mobileVisibilityModel : desktopVisibilityModel;
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import orderItemType from '../../types/orderItemType';

const ItemQuantityEdit = ({ orderItemCopy, handleQuantity, orderItem }) => {
  return (
    <div className="od-quantity">
      <div className="od-quantity-label">
        <span className="od-edit-label">Quantity accepted</span>
      </div>
      <div className="od-quantity-row">
        <button
          className={cx('none-button', {
            'od-quantity-disabled': !orderItemCopy.packagingQuantity,
          })}
          onClick={() => handleQuantity(0)}
          disabled={!orderItemCopy.packagingQuantity}
        >
          <span>None</span>
        </button>
        <div className="od-quantity-controls">
          <button
            className={cx({
              'od-quantity-disabled': !orderItemCopy.packagingQuantity,
            })}
            disabled={!orderItemCopy.packagingQuantity}
            onClick={() =>
              handleQuantity(Number(orderItemCopy.packagingQuantity) - 1)
            }
          >
            <span>-</span>
          </button>
          <div className="od-quantity-value">
            <span>{orderItemCopy.packagingQuantity}</span>
          </div>
          <button
            onClick={() =>
              handleQuantity(Number(orderItemCopy.packagingQuantity) + 1)
            }
          >
            <span>+</span>
          </button>
        </div>
      </div>
      <div className="od-quantity-change">
        {!!orderItem.expectedDeliveryQuantity &&
          orderItemCopy.packagingQuantity !==
            orderItem.expectedDeliveryQuantity && (
          <span className="od-edit-label od-bold">
              Original: {orderItem.expectedDeliveryQuantity}
          </span>
        )}
      </div>
    </div>
  );
};

export default ItemQuantityEdit;

ItemQuantityEdit.propTypes = {
  handleQuantity: PropTypes.func.isRequired,
  orderItem: orderItemType.isRequired,
  orderItemCopy: orderItemType.isRequired,
};

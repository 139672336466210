import React, { useContext, useState } from 'react';
import { Toggle, Typography, Button, Checkbox, Separator, Skeleton } from '@notch-ordering/ui-components';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import { useGetProductCategories, useGetSuppliersPerBuyer } from '@v2/Pages/Market/MarketQueries.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { generatePlaceholdersByCount } from '@v2/utils/Loader';
import { fetchProducts, TFetchProductsType } from '@v2/network/SecretShopAPI';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

export interface Props {
    onClickShowResults: () => void,
    fetchType: TFetchProductsType,
}

export const MarketFilters: React.FC<Props> = ({ onClickShowResults, fetchType }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Shop' });
    const { filters, setFilters } = useContext(MarketContext);
    const { buyer } = useBuyerHook();
    const { data: supplierCatalogsData, isLoading: isLoadingSuppliers } = useGetSuppliersPerBuyer({ buyerId: buyer.urlsafe });
    const { data: productCategories, isLoading: isLoadingCategories } = useGetProductCategories();
    const supplierFilters = supplierCatalogsData?.supplierCatalogs ?? [];
    const categoryFilters = productCategories?.categories ?? [];
    const [selectedFilters, setSelectedFilters] = useState(filters);
    const { data } = useQuery(['FETCH_TOTAL_FILTERED_RESULTS', selectedFilters], () => fetchProducts({ buyerUrlsafeKey: buyer.urlsafe, params: selectedFilters, type: fetchType }));
    const clearFilters = (): void => {
        setSelectedFilters({
            ...selectedFilters,
            categories: [],
            inStockOnly: false,
            supplierCatalogs: [],
            favorite: false
        });
    };

    function applyFilters(): void {
        setFilters(selectedFilters);
        onClickShowResults();
    }

    return (
        <>
            {/* We need such a large bottom padding to allow the modal body to fully scroll to the bottom,
            since the action bar renders on top of it */}
            <div className="overflow-auto h-full pb-32">
                <div className={'px-8 pt-8'}>
                    <Typography
                        as="h3"
                        weight="font-semibold"
                        className="mt-0 p-0 mb-5"
                        variant="LG-2">
                        Suppliers
                    </Typography>
                    <div className={'grid lg:grid-cols-2 gap-3'}>
                        {isLoadingSuppliers
                    && generatePlaceholdersByCount(<Skeleton size="full"/>, 14)
                        }
                        {supplierFilters.map((supplier, idx) => (
                            <Checkbox
                                value={supplier.id}
                                key={`${supplier.id}-${idx}`}
                                onChange={(val, isChecked): void => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        supplierCatalogs: isChecked ? [...selectedFilters.supplierCatalogs, val] : selectedFilters.supplierCatalogs.filter((c) => c !== val)
                                    });
                                }}
                                label={supplier.supplierName}
                                checked={selectedFilters.supplierCatalogs.includes(supplier.id)}/>
                        ))}
                    </div>
                </div>

                <Separator className={'mt-8'}/>

                <div className={'px-8 pt-8'}>
                    <Typography
                        as="h3"
                        weight="font-semibold"
                        className="mt-0 p-0 mb-5"
                        variant="LG-2">
                        Categories
                    </Typography>
                    <div className={'grid lg:grid-cols-2 gap-3'}>
                        {isLoadingCategories
                        && generatePlaceholdersByCount(<Skeleton size="full"/>, 14)
                        }
                        {categoryFilters.map((category) => (
                            <Checkbox
                                value={category}
                                key={category}
                                onChange={(val, isChecked): void => {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        categories: isChecked ? [...selectedFilters.categories, val] : selectedFilters.categories.filter((c) => c !== val)
                                    });
                                }}
                                label={category}
                                checked={selectedFilters.categories.includes(category)}/>
                        ))}
                    </div>
                </div>
                <Separator className={'mt-8'}/>
                <div className={'px-8 pt-8'}>
                    <Typography
                        as="h3"
                        weight="font-semibold"
                        className="mt-0 p-0 mb-5"
                        variant="LG-2">
                    More
                    </Typography>
                    <div className={'grid grid-cols-2 gap-3'}>
                        <Typography>
                        Include out of stock items
                        </Typography>
                        <div className={'w-full flex justify-end'}><Toggle isEnabled={!selectedFilters.inStockOnly}
                            onChange={(value):void => {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    inStockOnly: !value
                                });
                            }}/></div>
                    </div>
                </div>
            </div>
            <div className="sticky bottom-0 bg-white">
                <Separator className={'mt-8'} variant="large" />
                <div className={'px-6 pt-6 flex justify-between'}>
                    <Button onClick={clearFilters} variant="LINK" size="NO_BACKGROUND">Clear filters</Button>
                    <Button variant="SECONDARY" size="MEDIUM" onClick={applyFilters}>{t('showResults', { count: data ? data.total : 0 })}</Button>
                </div>
            </div>
        </>
    );
};

export default MarketFilters;

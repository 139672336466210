import { toastr } from 'react-redux-toastr';

import * as DataAPI from '../../../api/DataAPI';

export const FORGET_PASSWORD_REQUEST = 'FORGET_PASSWORD_REQUEST';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';

export const forgotPassword = (accountEmail) => {
  return async (dispatch) => {
    try {
      dispatch({ type: FORGET_PASSWORD_REQUEST });

      const response = await DataAPI.forgotPassword(accountEmail);

      if (response && response.data) {
        dispatch({ type: FORGET_PASSWORD_SUCCESS });

        return response.data;
      } else {
        dispatch({ type: FORGET_PASSWORD_FAILED });
      }
    } catch (error) {
      dispatch({ type: FORGET_PASSWORD_FAILED });

      let errorMessage = '';
      if (!error.response) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage =
          (((error || {}).response || {}).data || {}).message ||
          'Please try again';
      }

      toastr.error(`${errorMessage}`);
    }
  };
};

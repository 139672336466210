import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Typography } from '@notch-ordering/ui-components';
export default function ProgressBar(props) {
  const {
    currentStep,
    backToCart,
    steps,
    showFullProgress,
    order,
    isEditingOrder,
  } = props;

  let firstDotClass = 'progress-step';
  let secondDotClass = 'progress-step';

  if (currentStep === 'step1') {
    firstDotClass = 'progress-step is-active';
  } else if (currentStep === 'step2') {
    firstDotClass = 'progress-step is-complete';
    secondDotClass = 'progress-step is-active';
  } 

  if (showFullProgress) {
    firstDotClass = 'progress-step is-complete';
    secondDotClass = 'progress-step is-complete';
  }

  return (
    <div className="custom-progress-bar">
      <div className="progress-flow">
        <button
          className={cx('checkout-step', {
            selected: currentStep === 'cart',
          })}
          disabled={currentStep === 'cart'}
          onClick={() => backToCart('cart')}
        >
          <Typography as='div' weight='font-medium' className='pb-3'>Cart</Typography>
        </button>

        {!isEditingOrder && !window.location.href.includes('/pending/') && (
          <>
            <button
              className={cx('checkout-step', {
                selected: currentStep === 'info',
                disabled: currentStep !== 'info',
              })}
              disabled
            >
              <Typography as='div' weight='font-medium' className='pb-3'>Delivery information</Typography>
            </button>
          </>
        )}
      </div>
    </div>
  );
}

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  currentStep: PropTypes.string.isRequired,
  showFullProgress: PropTypes.bool,
  selectPreviousStep: PropTypes.func,
};

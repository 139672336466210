import React, { useMemo } from 'react';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { DEFAULT_INPUT_DATE_FORMAT, useOcrInvoiceStore, sanitizeExternalReferenceID, EApPlatform, formatDate } from '@notch-ordering/shared-logic';
import { ScanTopNavigation } from '@v2/components/ScanReview/ScanTopNavigation/ScanTopNavigation';
import { Button, Combobox, Input, Separator, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { ScanReviewViews, Tabs, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { DatePickerInput } from '@v2/components/Shared/DatePickerInput/DatePickerInput';
import SelectTaxCalculationSettings from '@v2/components/Shared/QBO/SelectTaxCalculationSettings';
import { format } from 'date-fns-tz';
import Utils from '@/utils';

export const datePickerFormatDateFactory = (dateString: string) => (): string => {
    const formattedDate = formatDate(dateString, DEFAULT_INPUT_DATE_FORMAT);
    if (formattedDate) return formattedDate;

    // In case there is no date to pass to calendar picker then pass user's current date in his local time zone
    return format(new Date(), DEFAULT_INPUT_DATE_FORMAT);
};

export enum ScanBasicDetailsKeys {
    supplierName = 'supplierName',
    invoiceNumber = 'invoiceNumber',
    invoiceDate = 'invoiceDate',
    dueDate = 'dueDate',
    paymentTerms = 'paymentTerms',
    billToAddress = 'billToAddress',
    shipToAddress = 'shipToAddress',
    accountingClass = 'accountingClass',
    buyerName = 'buyerName',
    bookCloseDate = 'bookCloseDate'
}

/**
 * A component that renders a form for capturing basic details of an invoice.
 *
 * @component
 * @returns {JSX.Element} - The component's rendered elements.
 */
export const ScanBasicDetails : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.BasicDetails' });
    const { setCurrentView, selectedTab } = useOcrInvoiceContext();
    const { buyer } = useBuyerHook();
    const { draftInvoice, updateInvoiceTopLevelData } = useOcrInvoiceStore();
    const isShowGLCodeOptions = draftInvoice.accountingConfiguration?.isEnabled && draftInvoice.accountingConfiguration.data?.platform === EApPlatform.MD365;
    const accountingClassOptions = draftInvoice?.accountingClasses?.map((accountingClass) => ({
        value: accountingClass.id,
        label: accountingClass.name
    })) ?? [];

    const accountingVendorsOptions = draftInvoice?.accountingVendors?.map((accountingVendor) => ({
        value: accountingVendor.id,
        label: accountingVendor.displayName
    })) ?? [];

    const accountingSubsidiaryOptions = draftInvoice?.accountingSubsidiaries?.map((accountingSubsidiary) => ({
        value: accountingSubsidiary.id,
        label: accountingSubsidiary.name
    })) ?? [];

    const accountingGlCodeOptions = draftInvoice?.glCodes?.map((accountingGlCode) => ({
        value: accountingGlCode.id,
        label: accountingGlCode.name
    })) ?? [];

    const { topLevelData } = draftInvoice ?? {};

    const { supplierName, buyerName, issueDate, dueDate, externalReferenceID, accountingClassID, accountingVendorID, accountingSubsidiaryID, glCodeID } = topLevelData ?? {};

    const issueDateString = useMemo(datePickerFormatDateFactory(issueDate), [issueDate]);
    const dueDateString = useMemo(datePickerFormatDateFactory(dueDate), [dueDate]);

    const handleDateChange = (field: 'issueDate' | 'dueDate', value: string): void => {
        const utcIsoDateString = new Date(value).toISOString();
        updateInvoiceTopLevelData(field, utcIsoDateString);
    };

    return <>
        <>
            <ScanTopNavigation rightContent={<Button variant="SECONDARY"
                size="SMALL"
                onClick={(): void => {
                    setCurrentView(ScanReviewViews.Overview);
                }
                }>
                {tCommon('Buttons.done')}
            </Button>}/>
            <div className="px-8 py-4">
                <Typography className="lg:text-7 m-0"
                    as="h1"
                    variant="3XL"
                    weight="font-bold">{t('basicDetails')}</Typography>
            </div>
            <Separator variant="large"/>
        </>
        <>
            <div className="px-8 py-10">
                <Typography weight="font-bold" desktopSize="lg:text-3" className="mt-0 mb-3">General</Typography>
                <div className="flex gap-4 mb-3">
                    <Input variant="ADVANCED_ALTERNATIVE"
                        id={ScanBasicDetailsKeys.supplierName}
                        value={supplierName}
                        disabled
                        label={t(ScanBasicDetailsKeys.supplierName)}/>

                    <Input variant="ADVANCED_ALTERNATIVE"
                        label={t(ScanBasicDetailsKeys.invoiceNumber)}
                        id={ScanBasicDetailsKeys.invoiceNumber}
                        onChange={(e): void => updateInvoiceTopLevelData('externalReferenceID', sanitizeExternalReferenceID(e.target.value)) }
                        isWarning={!draftInvoice?.topLevelData?.externalReferenceID}
                        value={sanitizeExternalReferenceID(externalReferenceID)}/>
                </div>
                <div className="flex gap-2 relative">
                    <DatePickerInput
                        id={ScanBasicDetailsKeys.invoiceDate}
                        value={issueDateString}
                        onChange={(value): void => handleDateChange('issueDate', value)}
                        label={t(ScanBasicDetailsKeys.invoiceDate)} />
                    <DatePickerInput
                        id={ScanBasicDetailsKeys.dueDate}
                        value={dueDateString}
                        onChange={(value): void => handleDateChange('dueDate', value)}
                        label={t(ScanBasicDetailsKeys.dueDate)} />
                    <Input variant="ADVANCED_ALTERNATIVE"
                        label={t(ScanBasicDetailsKeys.buyerName)}
                        disabled={true}
                        id={ScanBasicDetailsKeys.buyerName}
                        value={buyerName}/>

                </div>
            </div>
            <Separator/>
            <div className="px-8 py-10">
                <Typography weight="font-bold" desktopSize="lg:text-3" className="mt-0 mb-3">Addresses</Typography>
                <div className="flex flex-col gap-3">
                    <Input variant="ADVANCED_ALTERNATIVE"
                        disabled={true}
                        label={t(ScanBasicDetailsKeys.billToAddress)}
                        id={ScanBasicDetailsKeys.billToAddress}
                        value={ buyer?.billingAddress?.formatted}/>
                    <Input variant="ADVANCED_ALTERNATIVE"
                        label={t(ScanBasicDetailsKeys.shipToAddress)}
                        disabled={true}
                        id={ScanBasicDetailsKeys.shipToAddress}
                        value={buyer?.shippingAddresses[0]?.formatted}/>
                </div>
            </div>
            <Separator/>
            {(draftInvoice?.isQBOEnabled && (selectedTab === Tabs.ACCOUNTING || selectedTab === Tabs.NO_TABS)) && <div className="px-8 py-10">
                <Typography weight="font-bold" desktopSize="lg:text-3" className="mt-0 mb-3">Advanced</Typography>
                <div className="flex gap-3 mb-3">
                    {accountingClassOptions.length > 0 && <Combobox
                        onChange={(value): void => updateInvoiceTopLevelData('accountingClassID', value.value) }
                        options={accountingClassOptions}
                        value={accountingClassID}
                        isWarning={!draftInvoice?.topLevelData?.accountingClassID }
                        label={t(ScanBasicDetailsKeys.accountingClass)}
                        variant="ADVANCED_ALTERNATIVE"/>}
                    {accountingVendorsOptions.length > 0 && <Combobox
                        onChange={(value): void => updateInvoiceTopLevelData('accountingVendorID', value.value) }
                        isWarning={!draftInvoice?.topLevelData?.accountingVendorID }
                        options={accountingVendorsOptions}
                        value={accountingVendorID}
                        label="Vendor"
                        variant="ADVANCED_ALTERNATIVE"/>}
                </div>
                <div className="flex gap-3">
                    {accountingSubsidiaryOptions.length > 0 && <Combobox
                        onChange={(value): void => updateInvoiceTopLevelData('accountingSubsidiaryID', value.value) }
                        isWarning={!draftInvoice?.topLevelData?.accountingSubsidiaryID }
                        options={accountingSubsidiaryOptions}
                        value={accountingSubsidiaryID}
                        label="Subsidiary"
                        variant="ADVANCED_ALTERNATIVE"/>}
                    <SelectTaxCalculationSettings
                        integrationType= {draftInvoice?.accountingConfiguration?.type}
                        value={draftInvoice?.topLevelData?.taxCalculationSettings}
                        onChange={(v): void => updateInvoiceTopLevelData('taxCalculationSettings', v)} />
                    {draftInvoice.accountingInfo?.bookCloseDate && <Input
                        variant="ADVANCED_ALTERNATIVE"
                        disabled={true}
                        label={t(ScanBasicDetailsKeys.bookCloseDate)}
                        id={ScanBasicDetailsKeys.bookCloseDate}
                        value={ Utils.formatDate(draftInvoice.accountingInfo.bookCloseDate)}/>}
                    {isShowGLCodeOptions && accountingGlCodeOptions.length > 0 && <Combobox
                        onChange={(value): void => updateInvoiceTopLevelData('glCodeID', value.value) }
                        isWarning={!draftInvoice?.topLevelData?.glCodeID }
                        options={accountingGlCodeOptions}
                        value={glCodeID}
                        label="GL Code"
                        variant="ADVANCED_ALTERNATIVE"/>}
                </div>
            </div>}
        </>
    </>;
};

import moment from 'moment';
import { createSelector } from 'reselect';

import Utils from '../utils';
import { ADDITIONAL_FEE_TYPES } from '../constants/AdditionalFeeTypes';

const getOrder = (state, props) => props.order;
const getVendors = (state) => state.vendorsReducer.vendors;

export const getStatus = createSelector([getOrder], (order) => {
  const { orderBuyerStatus = '' } = order || {};

  return orderBuyerStatus;
});

export const getVendor = createSelector(
  [getOrder, getVendors],
  (order, vendors) => vendors.find((v) => v.urlsafe === order.vendorUrlsafe)
);

/**
 * Returns the humanized remaing time until order becomes uneditable. This moment is one hour before cut-off time one
 * day before order's delivery day. Example: "22 days"
 */
export const getFormattedRemaingTimeUntilOrderIsUneditable = createSelector(
  [getOrder, getVendor],
  (order, vendor) => {
    const cutOffDateBeforeDelivery = Utils.getDeliveryDayCutOffDateTime(
      vendor,
      order
    );

    return moment.duration(cutOffDateBeforeDelivery.diff()).humanize();
  }
);

/**
 * Returns the first relevant delivery instructions for an order from the following list:
 * - specialDeliveryRequsts
 * - shippingAddress.driveInstructions
 * - 'No delivery instructions provided'
 */
export const getRelevantDeliveryRequest = createSelector(
  [getOrder],
  (order) => {
    if (Object.keys(order).length === 0)
      return 'No delivery instructions provided';
    const driveInstructions =
      (order.shippingAddress || '').driveInstructions || '';

    return (
      order.deliveryRequests ||
      driveInstructions ||
      'No delivery instructions provided'
    );
  }
);

/**
 * An order is considered overdue if
 *1) order has an invoice
 *2) order has not been paid yet
 *3) dueAt is a day or more past today's date
 * @param {*} order
 */
export const determineIfOverdue = createSelector([getOrder], (order) => {
  let dueDate = null;
  // Use dueAt for pre-notchPay orders and due_date (be-billing) for post-notchPay orders.
  if (order.due_date) {
    dueDate = order.due_date;
  } else {
    dueDate = order.dueAt;
  }
  const dueAt = moment.utc(dueDate);
  const today = moment.utc();
  return (
    (order.invoices || []).length > 0 &&
    !order.isPaid &&
    today.diff(dueAt, 'days') >= 1
  );
});

export const getIsLoadingSingleOrder = ({
  ordersReducer: { isLoadingSingleOrder },
}) => isLoadingSingleOrder;

const singleOrderSelector = ({ ordersReducer: { singleOrder } }) => singleOrder;

const includeAddonItemsInOriginalOrder = (order) => {
  let processedOrderItems = order.items.filter((i) => !_.isEmpty(i));

  if (order?.addOnOrders?.length > 0) {
    for (const [addOnOrderIndex, addOnOrder] of order.addOnOrders.entries()) {
      const previousAddOnOrder =
        addOnOrderIndex > 0 ? order.addOnOrders[addOnOrderIndex - 1] : null;

      if (!addOnOrder.items) {
        continue;
      }

      for (const addOnItem of addOnOrder.items) {
        // If the item already exists on the current order, then just add up the quantities
        let addOnItemInExistingOrderOrAddOn = order.items.find(
          (item) => item.sourceID === addOnItem.sourceID
        );

        // If the item doesn't exist in the current order then just adjust the quantities in the current order.
        if (!addOnItemInExistingOrderOrAddOn && previousAddOnOrder) {
          addOnItemInExistingOrderOrAddOn = previousAddOnOrder.items.find(
            (item) => item.sourceID === addOnItem.sourceID
          );
        }

        // If the addOn item is found either in the order or the previous addOn then just adjust the quantities in the current order.
        if (addOnItemInExistingOrderOrAddOn) {
          const itemIndex = processedOrderItems.findIndex(
            (item) => item.sourceID === addOnItemInExistingOrderOrAddOn.sourceID
          );
          processedOrderItems = processedOrderItems
            .slice(0, itemIndex)
            .concat([
              {
                ...processedOrderItems[itemIndex],
                quantity:
                  processedOrderItems[itemIndex].quantity + addOnItem.quantity,
                originalQuantity:
                  processedOrderItems[itemIndex].quantity + addOnItem.quantity,
                total: processedOrderItems[itemIndex].total + addOnItem.total,
                taxAmount:
                  processedOrderItems[itemIndex].total + addOnItem.taxAmount,
              },
            ])
            .concat(processedOrderItems.slice(itemIndex + 1));
        } else {
          // Otherwise, just add it to the list of items
          processedOrderItems.unshift(addOnItem);
        }
      }
    }
  }

  order.items = processedOrderItems;
};

export const getSingleOrder = createSelector(
  [singleOrderSelector, getVendors],
  (_order, vendors) => {
    // make a copy of the order to work with.
    const order = { ..._order };

    // merge add-on order tax, subtotal, and total into main order's if applicable.
    if (order.addOnOrders.length) {
      includeAddonItemsInOriginalOrder(order);

      const { addOnOrders } = order;

      order.tax = addOnOrders.reduce(
        (totalTax, { tax }) => totalTax + tax,
        order.tax
      );

      order.subtotal = addOnOrders.reduce(
        (grandSubtotal, { subtotal }) => grandSubtotal + subtotal,
        order.subtotal
      );

      order.total = addOnOrders.reduce(
        (grandTotal, { total }) => grandTotal + total,
        order.total
      );
    }

    // sum up items total.
    order.itemsTotal = order.items.reduce(
      (itemsTotal, { price, quantity }) =>
        itemsTotal + Number(price * quantity),
      0
    );

    // find order vendor and attach delivery fee if applicable.
    const orderVendor = vendors.find(
      (vendor) => vendor.urlsafe === order.vendorUrlsafe
    );

    if (orderVendor) {
      const {
        region: { deliveryFee, minimumOrderAmount },
      } = orderVendor;
      const qualifiesForFreeDelivery = order.subtotal >= minimumOrderAmount;

      order.vendorDeliveryFee = qualifiesForFreeDelivery ? 0 : deliveryFee;
    }

    // attach individual addition fees if applicable.
    const { additionalFees } = order;

    if (Array.isArray(additionalFees) && additionalFees.length) {
      // attach service fee is applicable.
      const serviceFee = additionalFees.find(
        (fee) => fee.feeType === ADDITIONAL_FEE_TYPES.CHEHFERO_SERVICES_FEE
      );

      if (serviceFee) {
        order.serviceFee = serviceFee.amount.toFixed(2);
      }
    }

    // subtract applied credit amount from total if applicable.
    if (order.creditTotalAmount > 0) {
      order.total = order.total - order.creditTotalAmount;
    }

    // subtract taxes from total if iSBYOS order.
    if (order.isBYOS) {
      order.total = order.total - order.tax;
    }

    return order;
  }
);

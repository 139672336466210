import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as SignInActions from '../../actions/signInActions';
import * as AnalyticsActions from '../../actions/analyticsActions';

import SignInMain from '../../components/signin/SignInMain.jsx';

import * as Intercom from '../../third-party/Intercom';
import * as Segment from '../../third-party/Segment';
import { IonPage, isPlatform } from '@ionic/react';
import { checkVersion } from '@v2/utils/CheckVersion';
import { EIonicPlatforms } from '@/constants/Mobile';

class SignInContainer extends Component {
  componentDidMount() {
    checkVersion();

    Intercom.initLoggedOutUser();
    Segment.init();

    this.props.analyticsActions.signinScreenLoaded();
  }

  submitSignInForm = () => {
    const { SignInForm, signInActions } = this.props;
    const { email, password } = SignInForm.values;

    if (SignInForm.values) {
      signInActions.signin(email, password);
    }
  };

  analyticsViewPage = (screenName) => {
    this.props.analyticsActions.viewPage(screenName);
  };

  render() {
    return (
      <IonPage>
        <SignInMain
          {...this.props}
          submitSignInForm={this.submitSignInForm}
          analyticsViewPage={this.analyticsViewPage}
        />
      </IonPage>
    );
  }
}

SignInContainer.propTypes = {
  analyticsActions: PropTypes.objectOf(PropTypes.any).isRequired,
  signInActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStatetoProps(state) {
  return {
    ...state.form,
    ...state.errorsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    signInActions: bindActionCreators(SignInActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(SignInContainer);

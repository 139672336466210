import PropTypes from "prop-types";
import React, { Component } from "react";
import cx from "classnames";

import Utils from "../../../utils";
import DisplayUnit from "../../DisplayUnit.jsx";
import { BUCKET_ASSETS_STATIC_URL } from "@v2/constants/Assets";

export default class SearchSuggestion extends Component {
  onImageError = (event) => {
    event.target.src = `${BUCKET_ASSETS_STATIC_URL}images/your-item-market-placeholder.png`;
  };

  selectValue = () => {
    const item = this.props.data;
    this.props.setValue(item);
  };

  render() {
    const item = this.props.data;
    return (
      <div
        className={cx("search-suggestion", {
          "add-item-option-alt-colour": this.props.isFocused,
        })}
        onClick={this.selectValue}
        ref={this.props.innerRef}
        {...this.props.innerProps}
      >
        <div className="left">
          <div className="item-name">{item?.name}</div>
        </div>
        <div className="right">
          <div className="external-ref">{item?.package?.sku}</div>
          <div className="item-unit">
            <DisplayUnit
              unitName={item?.package?.description ?? item.package.unit }
              unitDescription={undefined}
            />
          </div>
          <div className="item-price">{Utils.formatAsCurrency(item.package.pricePerUnit)}</div>
        </div>
      </div>
    );
  }
}

SearchSuggestion.propTypes = {
  option: PropTypes.object.isRequired,

  selectValue: PropTypes.func.isRequired,
};

import React, { useContext } from 'react';

import { ChatSelectedContext } from '../context';
import Utils from '../../../utils';

const ChatSelectedDetailsBody = () => {
  const context = useContext(ChatSelectedContext);

  const {
    orderBuyerStatus = '',
    placedAt = '',
    deliveryDay = '',
    deliveryRequests = '',
    shippingAddress: {
      driveInstructions = '',
      formatted: shippingAddressFormatted = '',
    } = {},
  } = context.order || {};

  const deliveryInstructions = deliveryRequests
    ? deliveryRequests.split(';')[0].replace('deliveryRequests:', '').trim()
    : driveInstructions || 'No delivery instructions provided';

  const onImageError = (event) =>
    (event.target.src =
      'https://storage.googleapis.com/chefhero-storage/products/images/product-placeholder.png');

  return (
    <div className="body">
      <div className="order-status">{orderBuyerStatus}</div>

      <div className="prop-header">Order day:</div>
      <div className="prop">
        {Utils.formatDate(placedAt, 'dddd, DD MMMM YYYY')}
      </div>

      <div className="prop-header">Delivery date:</div>
      <div className="prop">
        {Utils.formatDate(deliveryDay, 'dddd, DD MMMM YYYY')}
      </div>

      <div className="prop-header">Shipping Address:</div>
      <div className="prop">{shippingAddressFormatted}</div>

      <div className="prop-header">Delivery Instructions:</div>
      <div className="prop">{deliveryInstructions}</div>

      <div className="h-line-full" />

      <div className="prop-header">Order Items:</div>

      {context.order &&
        (context.order.items || []).map((item, idx) => {
          // Check if item exists
          if (!_.isEmpty(item)) {
            return (
              <div key={item.urlsafe}>
                <div className="item-container">
                  <img
                    className="image"
                    src={
                      Object.keys(item.genericItem).length > 0
                        ? item.genericItem.imageURL
                        : Utils.getBackupImage(item.productCode)
                    }
                    onError={onImageError}
                  />

                  <div className="body">
                    <div className="name">{item.name}</div>
                    <div className="description">
                      {item.unitDescription || '-'}
                    </div>

                    <div className="body-footer">
                      <div className="qty">
                        x
                        {item.originalQuantity
                          ? item.originalQuantity
                          : item.quantity}
                      </div>
                      {item.originalPrice || item.price ? (
                        <div className="price">
                          $
                          {item.originalPrice ? item.originalPrice : item.price}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {idx !== context.order.items.length - 1 && (
                  <div className="h-line" />
                )}
              </div>
            );
          }
        })}
    </div>
  );
};

export default ChatSelectedDetailsBody;

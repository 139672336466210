import { Typography } from '@notch-ordering/ui-components';
import React from 'react';

const OrderConfirmationPageTitle = ({ buyer: { displayName }, id }) => (
  <div className="OrderConfirmationPageTitle">
    <img
      className="OrderConfirmationPageTitle__check-icon"
      src="https://storage.googleapis.com/chefhero-storage/static/fe-buyer/images/order-confirmation-check-icon.svg"
      alt="Check Icon"
    />

    <div>
      <Typography className="OrderConfirmationPageTitle__order-no">Order #{id}</Typography>
      <Typography variant="2XL" font="font-title" weight="font-regular" className="OrderConfirmationPageTitle__thank-you-note">
        Thank you{' '}
        <span className="OrderConfirmationPageTitle__buyer-name">
          {displayName}
        </span>
        !
      </Typography>
    </div>
  </div>
);

OrderConfirmationPageTitle.propTypes = {};

export default OrderConfirmationPageTitle;

import React, { useContext } from 'react';

import { ChatSelectedContext } from '../context';

const ChatSelectedDetailsHeader = () => {
  const context = useContext(ChatSelectedContext);
  const { orderNumber = '' } = context.messageAttributes || {};

  return (
    <div
      className="header"
      onClick={() => {
        context.setShowChatDetails(!context.showChatDetails);
      }}
    >
      {context.showChatDetails && (
        <>
          <div className="order-number" title={`Order #${orderNumber}`}>
            Order #{orderNumber}{' '}
          </div>
          <div className="btn-xs glyphicon glyphicon-chevron-right" />
        </>
      )}
    </div>
  );
};

export default ChatSelectedDetailsHeader;

// useTitle hook updates the title on landing on a new page

import { useEffect, useRef } from 'react';

const env = process.env.DEPLOY_ENV ?? 'experimental';
const isProduction = env === 'production';
export const DEFAULT_PAGE_TITLE = isProduction ? 'notch' : `notch | ${env}`;
export const useTitle = (title: string): void => {
    const prevTitle = useRef<string>(document.title);
    useEffect(() => {
        document.title = title ? `${title} | ${DEFAULT_PAGE_TITLE}` : DEFAULT_PAGE_TITLE;
        return ():void => {
            document.title = prevTitle?.current ?? DEFAULT_PAGE_TITLE;
        };
    }, [title]);
};

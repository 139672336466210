import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PhoneNumberForm from './step1/PhoneNumberForm.jsx';
import DeliveryRequestsContainer from '../../containers/checkout/DeliveryRequestsContainer.jsx';
import OrdersList from './checkout_body/OrdersList.jsx';
import Cart from './checkout_body/Cart.jsx';
import { OrderInformation } from '../../v2/components/Checkout/OrderInformation.tsx';
import * as checkoutSelectors from '../../selectors/checkoutSelectors';
import Utils from '../../utils';
import { CHECKOUT_STEPS } from '../../constants/Checkout';
import {sendTrackingEvent} from "@v2/utils/Tracking";
import {TrackingEvents} from "@v2/constants/Tracking";

const CheckoutBody = (props) => {
  const {
    buyer,
    orders,
    checkoutView,
    account,
    currentlyEditingOrderUrlsafe,
    vendors,
    currentStep,
    goBack,
    isEditingAddon,
  } = props;

  const order = orders.find(
    (ord) => ord.urlsafe === currentlyEditingOrderUrlsafe
  );
  const vendor = vendors.find((vend) => order.vendorUrlsafe === vend.urlsafe);
  const saveDeliveryDay = (deliveryDay) =>
    props.checkoutActions.onSaveDeliveryDayFromCheckout(
      [order],
      order.urlsafe,
      deliveryDay
    );

  useEffect(() => {
    const hasNoDeliveryDay =
      order &&
      vendor?.region?.nextAvailableDeliveryDays?.length &&
      (!order.deliveryDay ||
        !vendor?.region?.nextAvailableDeliveryDays.find(
          ({ date }) => date === order.deliveryDay
        ));
    // if no delivery day or the delivery day is not in the list of available delivery days,
    // and the order hasn't preOrder items then by default set the first available delivery day
    if (hasNoDeliveryDay) {
      if (order.preOrderRestricted) {
        // since it has preOrder items, we need to set the delivery day to the following two days (48 hours)
        saveDeliveryDay(
          vendor.region.nextAvailableDeliveryDays.find(
            ({ date }) =>
              !(Utils.isDateToday(date) || Utils.isDateTomorrow(date))
          ).date
        );
      } else {
        // since it has no preOrder items, we need to set the delivery day to the first available delivery day
        saveDeliveryDay(vendor.region.nextAvailableDeliveryDays[0].date);
      }
    }
  }, [order, vendor.region]);
  // on load check if there are custom products in order
  useEffect(() => {
    props.orderActions.fetchCartOrderCustomItems([order.urlsafe]);
  }, []);

  useEffect(() => {
    if(currentStep === CHECKOUT_STEPS.CART){
      sendTrackingEvent(TrackingEvents.checkoutStep1);
    }
    if (currentStep === CHECKOUT_STEPS.INFO) {
      sendTrackingEvent(TrackingEvents.checkoutStep2);
    }

  },[currentStep]);
  return (
    <div className="checkout-body">
      {order ? (
        <>
          {currentStep === CHECKOUT_STEPS.CART && (
            <div className="checkout-box">
              <Cart
                order={order}
                buyer={buyer}
                vendor={vendor}
                isEditingAddon={isEditingAddon}
              />
            </div>
          )}
          {(currentStep === CHECKOUT_STEPS.INFO ||
            currentStep === CHECKOUT_STEPS.PAYMENT) && (
              <>
                <OrderInformation buyer={buyer} order={order} vendor={vendor} />
              </>
            )}
        </>
      ) : (
        <>
          {checkoutView === 'deliveryView' && (
            <div>
              {/* Only show the <PhoneNumberForm> component if the account has no phone number */}
              {!account.mobile && <PhoneNumberForm {...props} />}
              <DeliveryRequestsContainer buyer={buyer} orders={orders} />
            </div>
          )}
          <OrdersList orders={orders} {...props} />
        </>
      )}
    </div>
  );
};

CheckoutBody.propTypes = {
  account: PropTypes.object.isRequired,
  buyer: PropTypes.object.isRequired,
  checkoutView: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
};

export default connect((state, props) => ({
  creditToUse: checkoutSelectors.getCreditToUse(state, props),
  terms: state.checkoutReducer.checkoutPaymentTerms,
}))(CheckoutBody);

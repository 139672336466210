/**
 * @param url - url path of the file
 * @returns - the end of the url containing only the file name
 */
export function getFileNameFromURL(url: string): string {
    const split = url.split('/');
    return split[split.length - 1];
}

/**
 * @param fileName - name of the file including the extension
 * @returns the file extension
 */
export function getExtensionFromFileName(fileName: string): string {
    const splitName = fileName.split('.');
    return splitName[splitName.length - 1].toLowerCase();
}

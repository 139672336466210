import { Button, Container, Typography } from '@notch-ordering/ui-components';
import { HomeFooter } from '@v2/components/Home/HomeFooter';
import SupplierCard from '@v2/components/Shared/SupplierCard/SupplierCard';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import { tCommon, tNamespace } from '@v2/i18n';
import { removeSupplier } from '@v2/network/LegacyAPI';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { useSetupStore } from '@v2/stores/SetupStore';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Route from 'react-router';
import { removeBuyerFromSupplier, SupplierData } from '@v2/network/CoreAPI';
import { useMutation } from '@tanstack/react-query';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import AddIcon from '@icons/add-icon.svg';
import HomeSupplierCard from '@v2/components/Home/HomeSupplierCard/HomeSupplierCard';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import { createOrSelectGeneralChatChannelInStore } from '@/domains/Chat/actions/chatSelectedActions';
import { changeRoute } from '@/actions/routerActions';

type Props = {
    router: Route,
    supplierCatalogs: SupplierCatalogData[],
    onRemoveSupplier: () => void,
};

/**
 * Home page displaying all supplier catalogs accessible by the buyer.
 *
 * @param props.router - for navigation
 * @param props.supplierCatalogs - list of suppliers
 */
const HomeSuppliers: React.FC<Props> = ({ supplierCatalogs, router, onRemoveSupplier }) => {
    const { setCatalogID, setSupplier, supplier, clearSetupActions } = useSetupStore();
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'ICON_MEDIUM' : 'SMALL';
    const { buyer } = useBuyerHook();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.Suppliers' });
    const removeBuyerFromSupplierMutation = useMutation(removeBuyerFromSupplier);

    const dispatch = useDispatch();

    const onClickManageCatalog = (supplierData: SupplierData): void => {
        router.push(EPaths.CATALOG_MANAGEMENT.replace(':supplierUrlsafeKey', supplierData.urlsafe));
    };

    const onClickUploadCatalog = (catalogId: string, supplierData: SupplierData): void => {
        setSupplier(supplierData);
        setCatalogID(catalogId);
        router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS_UPLOAD}`);
    };

    const onClickRemoveSupplier = (supplierUrlsafeKey: string): void => {
        removeBuyerFromSupplierMutation.mutate({
            supplierUrlsafeKey,
            buyerUrlsafeKey: buyer.urlsafe
        });
        removeSupplier(supplierUrlsafeKey)
            .then(onRemoveSupplier);
    };

    const onClickShop = (catalogId: string): void => {
        router.push(`${EPaths.SHOP}?supplierCatalogs=${catalogId}`);
    };
    const onClickChat = (supplierData: SupplierData): void => {
        dispatch(
            createOrSelectGeneralChatChannelInStore({
                customerUrlsafe: supplierData.customer.id,
                vendorUrlsafe: supplierData.urlsafe,
                vendorName: supplierData.name,
            })
        );
        dispatch(changeRoute('/inbox/'));
    };

    useEffect(() => {
        clearSetupActions();
    }, [supplier]);

    return (
        <Container className="h-min bg-gray-100" fluid>
            <Container className="lg:py-10 lg:px-10 pt-10">
                <div className="flex flex-row items-start lg:items-center justify-between mb-5">
                    <Typography
                        variant="XL"
                        weight="font-semibold"
                        as="h3"
                        className="mb-0 text-gray-700">
                        {t('yourSuppliers', { count: supplierCatalogs.length })}
                    </Typography>
                    <Button variant="TERTIARY_FILLED"
                        size={buttonSize}
                        onClick={(): void => {
                            sendTrackingEvent(TrackingEvents.addSupplierClicked);
                            router.push(`${EPaths.SETUP}/${ESetupPaths.SUPPLIERS}`);
                        } }>

                        {isMobileWidth
                            ? <div className="w-4 h-4 text-gray-600"><AddIcon /></div>
                            : tCommon('Buttons.addSuppliers')
                        }
                    </Button>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4 lg:pb-0">
                    {supplierCatalogs.map((catalog: SupplierCatalogData) => (
                        <SupplierCard key={catalog.id}
                            name={catalog.supplierName}>
                            <HomeSupplierCard
                                productCount={catalog.productCount}
                                hasCatalog={catalog.productCount > 0}
                                controlledByBuyer={catalog.canControlSupplierCatalog && catalog.priceListCount < 1}
                                supplierUrlsafeKey={catalog.supplierUrlsafeKey}
                                catalogID={catalog.id}
                                goToShop={onClickShop}
                                goToChat={onClickChat}
                                goToManageCatalog={onClickManageCatalog}
                                goToUploadAttachments={onClickUploadCatalog}
                                removeSupplier={onClickRemoveSupplier}/>
                        </SupplierCard>
                    ))}
                </div>
            </Container>
            <div className="lg:pb-0 pb-safe mb-[62px]">
                <HomeFooter />
            </div>
        </Container>
    );
};

export default HomeSuppliers;

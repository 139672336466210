import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getIsLoadingSingleOrder,
  getSingleOrder,
} from '../../../selectors/orderSelectors';
import { loadSingleOrder } from '../../../actions/orderActions';
import LoadingData from '../../LoadingData.jsx';

import { PageTitle, OrderInfo, OrderSummary } from './components';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';

import './styles.scss';
import {sendTrackingEvent} from "@v2/utils/Tracking";
import {TrackingEvents} from "@v2/constants/Tracking";
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import useBuyerHook from '@v2/hooks/useBuyer.hook';

const OrderConfirmationPage = ({ params: routeParams }) => {
  const { orderUrlSafe } = routeParams;
  const dispatch = useDispatch();
  const { completeTask } = useOnboardingStore();
  const { buyer } = useBuyerHook();

  useEffect(() => {
    dispatch(loadSingleOrder(orderUrlSafe));
    sendTrackingEvent(TrackingEvents.orderConfirmation);
    completeTask(buyer.urlsafe, EOnboardingTasks.PLACE_ORDER);
  }, [orderUrlSafe]);

  const isLoadingSingleOrder = useSelector(getIsLoadingSingleOrder);
  const order = useSelector(getSingleOrder);
  const orderCustomItems = useSelector(
    (state) => state.ordersReducer.customItems.orders
  );

  if (isLoadingSingleOrder) {
    return <LoadingData display text={''} />;
  }

  return (
    <div className="OrderConfirmationPage text-2">
      <div className="OrderConfirmationPage__col1">
        <div className="OrderConfirmationPage__col1-inner-container">
          <PageTitle {...order} />
          <OrderInfo {...order} />
        </div>
      </div>

      <div className="OrderConfirmationPage__col2">
        <OrderSummary
          {...order}
          customItems={orderCustomItems[order.urlsafe]}
        />
      </div>
    </div>
  );
};

export default OrderConfirmationPage;

import React from 'react';
import Router from 'react-router';
import { Button, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import BillsEmptyImage from '@images/bills-empty.svg';
import { EPaths } from '@v2/constants/EPaths';

type Props = {
    router: Router,
};

export const BillsEmpty : React.FC<Props> = ({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    return <div className="flex flex-col justify-center items-center h-full p-4 lg:p-0">
        <BillsEmptyImage className="w-40 mb-10" />
        <Typography as="div"
            className="text-center"
            variant="XL"
            desktopSize="text-5"
            weight="font-semibold">{t('viewAndPay')}</Typography>
        <Typography as="div" className="text-gray-600 text-center mt-2 mb-16 max-w-[423px]">{t('addYourFirstBill')}</Typography>
        <Button variant="TERTIARY_FILLED"
            onClick={(): void => {
                router.push(`${EPaths.UPLOADS}`);
            }}>
            {t('goToUploads')}
        </Button>
    </div>;
};

import { TFetchProductsType } from '@v2/network/SecretShopAPI';

export const LABELS = {
    headline: 'Shop',
    subHeadline: 'Shop',
};

export enum ESortBy {
    PRICE = 'price',
    NAME = 'name',
    FREQUENTLY_ORDERED = 'timesOrdered',
    RECENTLY_ORDERED = 'lastOrderedAt',
}

export enum ESortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

const SAVED_HEADLINE = {
    headline: 'All saved products',
    subHeadline: 'Collections',
};

const SEARCH_HEADLINE = {
    headline: 'Full catalog',
    subHeadline: 'Collections',
};

const ORDER_GUIDE_HEADLINE = {
    subHeadline: 'Order guides',
};

export const getHeadlineAndSubHeadline = (fetchTypeSelected: TFetchProductsType, orderGuideName = ''): {
    headline: string,
    subHeadline: string,
} => {
    switch (fetchTypeSelected) {
        case TFetchProductsType.SAVED:
            return SAVED_HEADLINE;
        case TFetchProductsType.SEARCH:
            return SEARCH_HEADLINE;
        case TFetchProductsType.ORDER_GUIDE:
            return { ...ORDER_GUIDE_HEADLINE, headline: orderGuideName };
        default:
            return SAVED_HEADLINE;
    }
};

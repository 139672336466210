import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Typography, Input, Separator, toast, onMobileSetLarge } from '@notch-ordering/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createOrderGuide, editOrderGuide, MutateOrderGuideData } from '@v2/network/SecretShopAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { GET_ORDER_GUIDES, useGetOrderGuide } from '@v2/Pages/Market/MarketQueries.hook';
import { OrderGuide } from '@v2/network/types';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import CheckIcon from '@icons/check-icon.svg';
import { tCommon } from '@v2/i18n';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';

export interface OrderGuideAddModalProps {
    onClose?: () => void,
    orderGuide?: OrderGuide,
}

export const AddEditNewOrderGuideModal : React.FC<OrderGuideAddModalProps> = ({ onClose = (): void => null, }) => {
    const [orderGuideName, setOrderGuideName] = useState<string>('');
    const { buyer } = useBuyerHook();
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';
    const { orderGuideIDSelected, setOrderGuideIDSelected, modals, setModalVisibility } = useContext(MarketContext);
    const isEditMode = !!orderGuideIDSelected;
    const { data: orderGuideSelected } = useGetOrderGuide({ buyerId: buyer.urlsafe, orderGuideID: orderGuideIDSelected });

    const queryClient = useQueryClient();
    const closeModal = ():void => {
        setModalVisibility('addEditOrderGuide', false);
        setOrderGuideName('');
        setOrderGuideIDSelected(null);
        onClose();
    };

    useEffect(() => {
        if (orderGuideSelected && isEditMode) {
            setOrderGuideName(orderGuideSelected?.name);
        } else {
            setOrderGuideName('');
        }
    }, [orderGuideSelected?.name]);

    const onSuccess = (): void => {
        // this will reload the order guides list
        queryClient.invalidateQueries([GET_ORDER_GUIDES, buyer.urlsafe]);

        closeModal();
        let message = tCommon('Toasts.success.create');
        if (isEditMode) {
            message = tCommon('Toasts.success.rename');
        }
        toast.show({
            icon: <CheckIcon />,
            message
        });
    };
    const { mutate: addOrderGuideMutation, isLoading: isLoadingCreate } = useMutation(createOrderGuide, {
        onSuccess
    });

    const { mutate: editOrderGuideMutation, isLoading: isLoadingEdit } = useMutation(editOrderGuide, {
        onSuccess
    });

    const isLoading = false;
    const isButtonDisabled = isLoadingCreate || !orderGuideName || isLoadingEdit;
    const handleAddOrderGuide = (): void => {
        const payload:MutateOrderGuideData = {
            name: orderGuideName,
            buyerUrlsafeKey: buyer.urlsafe
        };
        if (!isEditMode) {
            addOrderGuideMutation(payload);
        } else {
            editOrderGuideMutation({ ...payload, orderGuideID: orderGuideIDSelected });
        }
    };

    return (
        <Modal isOpen={modals.addEditOrderGuide}
            modalSize={onMobileSetLarge('SMALL', true)}
            titleSeparatorDesktop
            titleSeparatorMobile
            close={closeModal}
            onClose={closeModal}
            title={`${isEditMode ? 'Rename' : 'Create new'}  guide`}>
            <div className="w-full h-full flex flex-col gap-3 overflow-auto">
                <div className="flex gap-6 flex-col mt-5 mb-2 px-4 lg:px-5">
                    <Input
                        className="mt-0 lg:m-0 w-full"
                        label={'Name'}
                        type="TEXT"
                        value={orderGuideName}
                        onChange={(e): void => setOrderGuideName(e.target.value)}
                        disabled={isLoading}
                        required />
                </div>

            </div>
            <Separator className="my-4" />
            <div className={'px-4 lg:px-5 mt-auto sticky bottom-0'}>
                <div className="flex items-center justify-between flex-col gap-3">
                    <Button variant="SECONDARY"
                        size={buttonSize}
                        className={'w-full'}
                        onClick={handleAddOrderGuide}
                        disabled={isButtonDisabled}
                        loading={isLoading}>
                        <Typography as={'span'} weight={'font-medium'}>
                            {isEditMode ? tCommon('Buttons.save') : tCommon('Buttons.create')}
                        </Typography>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export const TextField = forwardRef(
  (
    { icon, className, onBlur, defaultValue, placeholder, setValueOnEnter },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);
    return (
      <div className="text-input-field">
        <input
          className={cx(className, {
            'with-icon': Boolean(icon),
          })}
          placeholder={placeholder}
          ref={ref}
          onBlur={(e) => onBlur(e.target.value)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyPress={(e) => {
            if (setValueOnEnter && (e.key === 'Enter' || e.keyCode === 13)) {
              e.target.blur();
            }
          }}
        />
        {icon && <div className="icon">{icon}</div>}
      </div>
    );
  }
);

TextField.propTypes = {
  icon: PropTypes.element,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.string,
  setValueOnEnter: PropTypes.bool,
};

TextField.defaultProps = {
  icon: '',
  className: '',
  placeholder: '',
  onBlur: () => {},
  defaultValue: '',
  setValueOnEnter: false,
};

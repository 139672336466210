import React from 'react';
import PropTypes from 'prop-types';

const SettingsBackButton = ({ router }) => {
  const onClick = () => router.goBack();

  return (
    <button className="settings-body-header" onClick={onClick}>
      <i className="fa fa-chevron-left" />
      Back
    </button>
  );
};

SettingsBackButton.propTypes = {
  router: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default SettingsBackButton;

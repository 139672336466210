import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchNewProductVariants,
  receiveProductVariants,
} from '../../../actions/changeUnitActions';

const useProduct = () => {
  const {
    changeUnitReducer: { variants, isChangeUnitLoading },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  const getProductVariants = useCallback(
    (vendorID, productCode) => {
      dispatch(fetchNewProductVariants({ vendorID, productCode }));
    },
    [dispatch]
  );

  const clearProductVariants = useCallback(() => {
    dispatch(receiveProductVariants([]));
  }, [dispatch]);

  return {
    getProductVariants,
    clearProductVariants,
    variants,
    loading: isChangeUnitLoading,
  };
};

export default useProduct;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Container, Loading, toast } from '@notch-ordering/ui-components';
import { initializeOcr, saveOcrData, useOcrInvoice, useOcrInvoiceStore, EErpPlatform, getAccountingConfiguration } from '@notch-ordering/shared-logic';
import Router from 'react-router';
import { useUploadsStore } from '@v2/stores/UploadsStore';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { OcrInvoiceContextProvider,
    OcrInvoiceContextType,
    OnSaveOcrInvoiceArgs,
    Tabs } from '@v2/Pages/ScanReview/ScanReviewContext';
import { ScanReviewAsset } from '@v2/components/ScanReview/ScanReviewAsset/ScanReviewAsset';
import { ScanReviewView } from '@v2/Pages/ScanReview/ScanReviewView';
import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { OCConfiguration, getOCConfiguration } from '@v2/network/IntegrationAPI';
import { FETCH_ACCOUNTING_INTEGRATIONS } from '@v2/components/Bills/PayBillModal';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import AlertIcon from '@icons/alert-icon.svg';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { FETCH_OCR_INVOICES_QUERY_KEY, useGetOcrInvoices, TUseGetOcrInvoicesQuery } from '../Uploads/UploadsQueries.hook';
import { RootState } from '@/v2';
import { logException } from '@/domains/shared/logger';
import { updateAlignment } from '@/third-party/Intercom';

const {
    secretShopUrl,
    bushwhackUrl,
    falsePromiseApi,
    baseUrlv3,
    greevilsGreedApi
} = process.env.BASE_URLS || {};

const loadOcrURLs = {
    SECRET_SHOP_API: secretShopUrl,
    BUSHWHACK_API: bushwhackUrl,
    OCR_API: falsePromiseApi,
    BASE_API_V3: baseUrlv3,
    GREEVILS_GREED_API: greevilsGreedApi
};

// this initializes the module by passing the urls to the module
initializeOcr(loadOcrURLs);

export interface ScanReviewPageProps {
    router: Router,
    //  this property is added to the component by react-router
    params: {
        invoiceKey: string,
    },
}

const MAX_LIMIT = 200;
/**
 * A component that renders a page for reviewing and saving OCR invoice data.
 *
 * @component
 * @param {object} props.params - The parameters object passed to the component from the router.
 * @param {object} props.router - The router object used to navigate between pages.
 */

export const ScanReviewPage: React.FC<ScanReviewPageProps> = ({ params, router }) => {
    // Destructure functions and state from the useOcrInvoiceStore hook in shared-logic module
    const { validateForm, validateTopLevelData, setInvoices, draftInvoice, resetStore } = useOcrInvoiceStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview' });
    const [isSavingOcrInvoice, setIsSavingOcrInvoice] = useState(false);
    const [imsIntegrationFetched, setImsIntegrationFetched] = useState<boolean>(false);
    const [erpPlatform, setERPPlatform] = useState<EErpPlatform>(null);
    const [defaultTab, setDefaultTab] = useState<Tabs>(null);
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);

    // This state variables are used in the context definition below to handle current view and loading state
    // this state variable is used to handle loading state of the page coming from redux, to make request only when the page is loaded and user authenticated
    const isLoadingAppInit = useSelector(
        (state:RootState) => state.accountReducer.isLoadingAppInit
    );

    const queryClient = useQueryClient();

    // getting initial parameters to load all the ocr invoices to move across them
    const {
        queryParams,
        searchParams,
    } = useUploadsStore();

    const { buyer } = useBuyerHook();

    // Define a mutation for saving the OCR invoice data
    const saveOcrInvoiceMutation = useMutation(saveOcrData, {
        onMutate: () => {
            setIsSavingOcrInvoice(true);
        }
    });

    /**
     * Format the error messages for our users to give them instructions or at least better context of why this
     * error is occurring.
     *
     * Rutter errors:
     * https://docs.rutter.com/rest/2023-02-07/errors
     *
     * QBO errors:
     * https://developer.intuit.com/app/developer/qbo/docs/develop/troubleshooting/handling-common-errors
     */
    const parseSaveOCRErrorMsg = (error: string): string => {
        let errorMsg = error;
        if (error.includes('The account period has closed and the account books cannot be updated')) {
            errorMsg = 'Unable to re-sync Bill because the accounting period has closed. If the Notch Bill and accounting Bill are unrelated then update the Invoice # to be unique and sync again.';
        } else if (error.includes('The document date cannot be before the end') || error.includes('does not fall between the start and end dates of your accounting period') || error.includes('Posting Date is not within your range') || error.includes('The account period has closed')) {
            errorMsg = 'Unable to sync Bill because the accounting period has closed.';
        } else if (error.includes('invalid_grant') || error.includes('needs to re-authenticate this connection') || error.includes('Invalid credentials to access this connection')) {
            errorMsg = 'Unable to authenticate. If this error presists try reconnecting through Settings -> Integrations.';
        }

        return errorMsg;
    };

    // Define a mutation for saving the OCR invoice data this is passed to the context to be used in the child components
    const onSaveOcrInvoice = useCallback(({
        ocrState,
        onSuccess,
        onError,
        verifyOnSave,
        syncAccountingOnSave,
        syncIMSOnSave,
        hasIMSIntegration,
        hasAccountingIntegration,
        accountingSyncName,
        imsSyncName,
        lineItemCategory,
        triggeredBy
    }: OnSaveOcrInvoiceArgs = {
        ocrState: draftInvoice?.rawInvoiceData?.ocrState ?? 'reviewed',
        verifyOnSave: false,
        syncAccountingOnSave: false,
        syncIMSOnSave: false,
        hasIMSIntegration: false,
        hasAccountingIntegration: false,
        accountingSyncName: null,
        imsSyncName: null,
        lineItemCategory: null,
        triggeredBy: ''
    }) :void => {
        saveOcrInvoiceMutation.mutate({
            ocrState,
            verifyOnSave,
            syncAccountingOnSave,
            syncIMSOnSave,
            hasIMSIntegration,
            hasAccountingIntegration,
            accountingSyncName,
            imsSyncName,
            lineItemCategory,
            triggeredBy
        }, {
            onSuccess: () => {
                setIsSavingOcrInvoice(false);
                // Invalidate the OCR invoice query when the mutation is successful
                queryClient.invalidateQueries([FETCH_OCR_INVOICES_QUERY_KEY, queryParams, searchParams]);
                // Show a toast message indicating if the invoice was saved successfully
                toast.show({
                    message: t('savedSuccessfully')
                });
                if (onSuccess) onSuccess();
            },
            onError: (error) => {
                const errorMsg: string = parseSaveOCRErrorMsg(error.toString());
                setIsSavingOcrInvoice(false);
                toast.show({
                    message: `${t('saveFailed')}:  ${errorMsg}`,
                    duration: 60000,
                    showClose: true,
                    icon: <AlertIcon className="text-red-300" />,
                });
                // eslint-disable-next-line no-console
                console.log(error, 'error ');
                logException(error);
                if (onError) onError();
            }
        });
    }, [saveOcrInvoiceMutation, t, queryParams, searchParams, queryClient, draftInvoice]);

    const accountingIntegration = useQuery([FETCH_ACCOUNTING_INTEGRATIONS, buyer.urlsafe], () => getAccountingConfiguration(buyer.urlsafe), { enabled: !!buyer.urlsafe, cacheTime: 0, retry: 1 });

    const hasLoadedPage = !isLoadingAppInit
        && !!params.invoiceKey
        && !!buyer?.urlsafe
        && accountingIntegration.isFetched
        && imsIntegrationFetched;

    // Define a query to get the OCR invoice data from shared module
    const { isLoading: isLoadingOcrInvoice = false, invoice } = useOcrInvoice({
        invoiceKey: params.invoiceKey,
        enabled: hasLoadedPage,
        platform: getAccountingConfigurationType(accountingIntegration?.data),
        accessToken: accountingIntegration?.data?.data?.accessToken,
        useSeparateIMSLineItems
    });

    const ocrInvoicesParams: TUseGetOcrInvoicesQuery = useMemo(() => {
        let { hasAccountingSynced, hasImsSynced } = searchParams;

        if (hasImsSynced === undefined && !!erpPlatform) {
            hasImsSynced = null;
        }

        if (hasAccountingSynced === undefined && !!getAccountingConfigurationType(accountingIntegration?.data)) {
            hasAccountingSynced = null;
        }

        return {
            queryParams: {
                ...queryParams,
                limit: MAX_LIMIT,
            },
            searchParams: {
                ...searchParams,
                ocrState: ['reviewed', 'verified'],
                hasAccountingSynced,
                hasImsSynced,
                requireAllIntegrationsSynced: false,
                buyerUrlsafeKey: buyer?.urlsafe
            },
            queryOptions: {
                onSuccess: (data): void => {
                    setInvoices(data);
                },
                enabled: hasLoadedPage,
            }
        };
    }, [queryParams, searchParams, buyer?.urlsafe, hasLoadedPage]);

    const { isLoading: isLoadingUploadInvoices } = useGetOcrInvoices(ocrInvoicesParams);

    const contextValue: OcrInvoiceContextType = useMemo(
        () => ({
            router,
            onSaveOcrInvoice,
            isSavingOcrInvoice,
            setIsSavingOcrInvoice,
            initialInvoice: invoice
        }),
        [router, onSaveOcrInvoice, isSavingOcrInvoice, setIsSavingOcrInvoice, invoice]
    );

    useEffect(() => {
        const ocrState = useOcrInvoiceStore.getState();
        if (ocrState.getInvoiceLineItemsPrimaryData('lineItems')) {
            validateForm('lineItems');
        }
        if (ocrState.getInvoiceLineItemsPrimaryData('imsLineItems')) {
            validateForm('imsLineItems');
        }
        if (ocrState.getInvoiceLineItemsPrimaryData('chartOfAccountDetails')) {
            validateForm('chartOfAccountDetails');
        }

        validateTopLevelData();
        // the Intercom widget has been moved to the left side to prevent overlapping.
        updateAlignment('left');
        return () => {
            resetStore();
            // the Intercom widget will be updated to its original alignment when the component unmounts.
            updateAlignment('right');
        };
    }, []);

    useEffect(() => {
        async function checkERPIntegration(): Promise<void> {
            // For now, since the only ERP integration we have is OC, just look for the OC config
            const config = await getOCConfiguration(buyer?.urlsafe).catch(() => {});
            const ocConfig = config as OCConfiguration;
            setERPPlatform((ocConfig?.error?.length === 0) ? EErpPlatform.OPTIMUMCONTROL : null);
            setImsIntegrationFetched(true);
        }
        checkERPIntegration();
    }, [buyer?.urlsafe]);

    useEffect(() => {
        const hasAccountingIntegration: boolean = accountingIntegration.data?.isEnabled;
        const hasIMSIntegration: boolean = !!erpPlatform;
        const tabsEnabled: boolean = hasIMSIntegration && hasAccountingIntegration;
        if (tabsEnabled) {
            setDefaultTab(Tabs.ACCOUNTING);
        } else {
            setDefaultTab(Tabs.NO_TABS);
        }
    }, [hasLoadedPage]);

    const showLoader = isLoadingOcrInvoice || isLoadingUploadInvoices || !buyer?.urlsafe || isLoadingAppInit;

    if (showLoader) {
        return <div className="h-screen flex items-center justify-center"><Loading isDark/></div>;
    }

    return draftInvoice && hasLoadedPage
        ? (<OcrInvoiceContextProvider value={contextValue} defaultTab={defaultTab} >
            <Container fluid className="relative flex lg:flex-row flex-col lg:h-screen lg:overflow-hidden">
                <div className="lg:w-1/2 overflow-y-auto bg-gray-650">
                    <ScanReviewAsset />
                </div>
                <div className="lg:w-1/2 lg:h-full overflow-auto scroll-smooth border-l-gray-200 border-l ">
                    <ScanReviewView />
                </div>
            </Container>
        </OcrInvoiceContextProvider>)
        : null;
};

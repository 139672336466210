import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';

import CartEmpty from './CartEmpty.jsx';
import CartHeader from './CartHeader.jsx';
import CartOrders from './cart_orders/CartOrders.jsx';
import LoadingData from '../LoadingData.jsx';
import ModalBox from '../general/ModalBox.jsx';
import CartManagers from './CartManagers.jsx';
import BuyerSuspendedModal from '../modals/BuyerSuspendedModal.jsx';
import { basicCustomStyles } from '../../constants/CustomStyles';

export default class CartMain extends Component {
  renderModal = () => {
    const {
      isShowCartManagersModal,
      isBuyerSuspendedModalOpen,
      toggleCartManagersModal,
      toggleBuyerSuspendedModal,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={isShowCartManagersModal}
          style={basicCustomStyles}
          ariaHideApp={false}
        >
          <ModalBox
            title={'Managers'}
            activateSave={false}
            loading={false}
            onClickClose={toggleCartManagersModal}
            customStyles={{
              height: 'auto',
              marginTop: '8%',
              borderRadius: '2px',
            }}
            showHeader={true}
            showFooter={false}
            {...this.props}
          >
            <CartManagers
              {...this.props}
              onClickCancel={toggleCartManagersModal}
            />
          </ModalBox>
        </Modal>
        <Modal
          isOpen={isBuyerSuspendedModalOpen}
          style={basicCustomStyles}
          ariaHideApp={false}
        >
          <BuyerSuspendedModal
            onCloseModal={() => toggleBuyerSuspendedModal()}
          />
        </Modal>
      </div>
    );
  };

  render() {
    const { orders, isCartLoading } = this.props;

    const filteredOrders = orders.filter((order) => order.isInShoppingCart);
    const isEmptyCart = filteredOrders.length === 0;

    return (
      <div className="cart-container">
        <CartHeader cartsCount={filteredOrders.length} />

        {isCartLoading && (
          <LoadingData text="Loading Cart" display={isCartLoading} />
        )}

        {!isCartLoading &&
          (isEmptyCart ? <CartEmpty /> : <CartOrders {...this.props} />)}

        {this.renderModal()}
      </div>
    );
  }
}

CartMain.propTypes = {
  orders: PropTypes.array.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  isShowCartManagersModal: PropTypes.bool.isRequired,
  isBuyerSuspendedModalOpen: PropTypes.bool.isRequired,

  toggleCartManagersModal: PropTypes.func.isRequired,
  toggleBuyerSuspendedModal: PropTypes.func.isRequired,
};

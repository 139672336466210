import { useEffect, useState } from 'react';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import useFirebaseRemoteConfig from '@v2/hooks/useFirebaseRemoteConfig.hook';

export const useHasFeatureFlagEnabled = (featureFlag: EFirebaseParams): boolean => {
    const { isEnabled } = useFirebaseRemoteConfig();
    const [isFeatureFlagEnabled, setIsFeatureFlagEnabled] = useState<boolean>();

    useEffect(() => {
        const getHasFeatureFlag = async (): Promise<void> => {
            const hasFeatureFlag = await isEnabled(featureFlag);
            setIsFeatureFlagEnabled(hasFeatureFlag);
        };
        getHasFeatureFlag();
    }, []);

    return isFeatureFlagEnabled;
};

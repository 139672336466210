import { Button, Modal, onMobileSetLarge, Separator } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { Router, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { BulkUploadFileStep } from '@v2/components/BulkUpload/BulkUploadFileStep/BulkUploadFileStep';
import { getDuplicatedColumnsSelected,
    hasRequiredFields,
    IColumnMap } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStepConstants';
import { generateFormData } from '@v2/components/BulkUpload/BulkUploadFileStep/BulkUploadFileStepConstants';
import { useMutation } from '@tanstack/react-query';
import { bulkImportProductsValidate, IBulkImportRequestPayload } from '@v2/network/SecretShopAPI';
import { EModalSteps,
    getModalHeader,
    parseCSV,
    processCSVColumns,
    processCSVData } from '@v2/components/BulkUpload/BulkUploadModal/BulkUploadModalConstants';
import cx from 'classnames';
import { BulkMapFieldsStep } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStep';
import { TValidateImportInfo, useBulkUploadStore } from '@v2/stores/BulkUploadStore';
import { EPaths } from '@v2/constants/EPaths';
import { useGetProductCategories, useGetProductUnits } from '@v2/Pages/Market/MarketQueries.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

export interface BulkUploadModalProps {
    router?: Router,
}

export const BulkUploadModal: React.FC<BulkUploadModalProps> = withRouter(({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'BulkUpload' });
    const [file, setFile] = useState<File | null>(null);
    const [errors, setErrors] = useState<string[]>([]);
    const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [missingColumns, setMissingColumns] = useState<string[]>([]);
    const [currentStep, setCurrentStep] = useState<EModalSteps>(EModalSteps.UploadFile);
    const isUploadStep = currentStep === EModalSteps.UploadFile;
    const isMapStep = currentStep === EModalSteps.MapColumns;
    const { setValidateImportInfo, csvColumns, setCsvColumns, setColumnMapping, setBulkImportRequestPayload, columnMapping, isModalBulkUploadOpen, setModalBulkUploadOpen, currentSupplier } = useBulkUploadStore();
    const { data: unitsData } = useGetProductUnits();
    const { data: categoriesData } = useGetProductCategories();

    const bulkImportProductsValidateMutation = useMutation(bulkImportProductsValidate);
    // revert all modified states to initial state
    const cleanUpState = ():void => {
        setFile(null);
        setCurrentStep(EModalSteps.UploadFile);
        setMissingColumns([]);
        setErrors([]);
        setIsFormSubmitted(false);
    };

    const closeModal = ():void => {
        setModalBulkUploadOpen(false);
        cleanUpState();
    };

    const handleMapFields = (columnMap:IColumnMap, processedFile: File):void => {
        const payload:IBulkImportRequestPayload = { supplierCatalogID: currentSupplier?.id, data: generateFormData(processedFile, columnMap) };
        setLoading(true);
        setBulkImportRequestPayload(payload);
        bulkImportProductsValidateMutation.mutate(payload, {
            onSuccess: (response:TValidateImportInfo) => {
                setValidateImportInfo(response);
                setModalBulkUploadOpen(false);
                router.push(EPaths.SUPPLIER_CATALOG_IMPORT.replace(':supplierUrlsafeKey', currentSupplier?.supplierUrlsafeKey));
            },
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            onError: (error:any) => {
                setErrors([error?.response?.data.error as string]);
            }
        });
    };

    const preProcessCSV = async (columnMap: IColumnMap):Promise<File> => {
        const result = await parseCSV(file);
        return processCSVData({
            result,
            columnMap,
            validCategories: categoriesData.categories,
            validUnits: unitsData.units
        });
    };

    const handleUploadFile = async ():Promise<void> => {
        try {
            setLoading(true);
            const result = await parseCSV(file);
            const { missedColumns, hasMissedColumns, defaultColumnMapping, validColumns } = processCSVColumns(result.meta.fields);
            // adding as columns only truthy values
            setCsvColumns(validColumns);
            // if there are missing columns, define them as invalid
            setMissingColumns(missedColumns);
            // set default column map if found column headers
            setColumnMapping(defaultColumnMapping);
            setLoading(false);
            setErrors([]);
            if (hasMissedColumns) {
                setCurrentStep(EModalSteps.MapColumns);
            } else {
                // if all fields match assign them to column map
                const fileProcessed = await preProcessCSV(defaultColumnMapping);
                handleMapFields(defaultColumnMapping, fileProcessed);
                setModalBulkUploadOpen(false);
            }
        } catch (error) {
            setErrors((er) => er.map((e) => e));
            setLoading(false);
        }
    };

    const handleSubmitModal = async ():Promise<void> => {
        if (currentStep === EModalSteps.UploadFile) {
            handleUploadFile();
            sendTrackingEvent(TrackingEvents.catalogFileUploadContinue);
        }
        if (currentStep === EModalSteps.MapColumns) {
            setIsFormSubmitted(true);
            const isValid = hasRequiredFields(columnMapping) && getDuplicatedColumnsSelected(columnMapping).length === 0;
            if (isValid) {
                const fileProcessed = await preProcessCSV(columnMapping);
                handleMapFields(columnMapping, fileProcessed);
            }
            sendTrackingEvent(TrackingEvents.catalogMatchingFieldContinue);
        }
    };

    return <Modal
        isOpen={isModalBulkUploadOpen}
        modalSize={onMobileSetLarge('MEDIUM')}
        close={closeModal}
        title={getModalHeader({
            headline: isUploadStep ? t('uploadCatalogFor', { supplierName: currentSupplier?.supplierName || '' }) : t('weDidntRecognizedFile'),
            subHeadline: isUploadStep ? t('useUploadCatalog') : t('useASupplierCatalog'),
        })}>
        <div className="overflow-auto max-h-full">
            {isUploadStep && <BulkUploadFileStep errors={errors} setErrors={setErrors} setFile={setFile} file={file}/>}
            {isMapStep
            && <BulkMapFieldsStep missingColumns={missingColumns}
                columns={csvColumns}
                formSubmitted={isFormSubmitted}/>}
            <Separator className="w-full"/>
        </div>
        <div className="sticky bottom-0 border-t border-gray-200">
            <div className="mx-10 pt-6 bg-white">
                <div className="flex gap-2 justify-end ">
                    <Button variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        className={cx({ 'mr-auto': isMapStep })}
                        onClick={closeModal}>{tCommon('Buttons.cancel')}</Button>
                    {isMapStep && <Button variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        onClick={(): void => {
                            setCurrentStep(EModalSteps.UploadFile);
                            cleanUpState();
                        }}>{tCommon('Buttons.reupload')}</Button>}
                    <Button variant="SECONDARY"
                        size="MEDIUM"
                        loading={loading}
                        disabled={isUploadStep && !file}
                        onClick={handleSubmitModal}>{tCommon('Buttons.continue')}</Button>
                </div>

            </div>
        </div>
    </Modal>;
});

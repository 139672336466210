import React from 'react';

import CalendarIcon from '@icons/calendar-icon.svg';
import { Container, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { OrderData } from '@v2/types/OrderData';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { changeRoute } from '@/actions/routerActions';
import Utils from '@/utils';

interface Props {
    orders: OrderData[],
}

/**
 * This component lists the orders that are being on a particular day in the home page
 *
 * @param props - component properties
 * @param props.orders - Contains the list of orders
 */
const HomeDeliveries: React.FC<Props> = ({ orders }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.Deliveries' });
    const goToOrder = (id: string): void => {
        dispatch(changeRoute(`/order_detail/${id}/`));
    };
    return (
        <>
            <div className="w-full h-px bg-gray-200" />
            <Container className="bg-white" fluid>
                <Container className={'lg:py-16 py-10 lg:px-10'}>
                    <Typography
                        as="h3"
                        variant="4XL"
                        size="text-6"
                        desktopSize="text-6"
                        className="text-gray-700 mb-2 mt-0">
                        {t('ordersBeingDelivered')}
                    </Typography>
                    <Typography as="span"
                        className="text-gray-600">
                        {t('thereAreOrders', { count: orders.length })}
                    </Typography>
                    <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-8 ">
                        {orders.map((order: OrderData) => (
                            <div
                                key={order.id}
                                className="flex flex-col items-start bg-white shadow-z2 border border-solid border-gray-300 rounded-xl w-full py-6 cursor-pointer"
                                onClick={(): void => goToOrder(order.urlsafe)}>
                                <div className="flex items-center justify-between w-full">
                                    <Typography
                                        as="span"
                                        weight="font-semibold"
                                        className="text-gray-700 ml-6">
                                        {order.vendorName}
                                    </Typography>

                                    <Typography
                                        as="span"
                                        variant="BASE"
                                        className="text-gray-700 mr-6 bg-gray-200 rounded-4xl py-0.5 px-1.5">
                                        {tCommon('itemsCount', { count: order.items.length })}
                                    </Typography>
                                </div>
                                <Typography
                                    as="span"
                                    className="text-gray-600 mx-6 mt-1">
                                    {tCommon('order')} #{order.id}
                                </Typography>
                                <div className="w-full h-px bg-gray-200 mt-6 mb-5" />
                                <div className="flex justify-left items-left w-full px-6">
                                    <Typography
                                        as="span"
                                        className="text-gray-700 flex items-center">
                                        <div className="h-5 w-5 lg:w-4 lg:h-4 mr-3.5 text-gray-700">
                                            <CalendarIcon title="calendar"/>
                                        </div>
                                        {t('deliveryScheduled')}{' '}
                                        {Utils.formatDate(order.deliveryDay, 'DD/MM/YY')}
                                    </Typography>
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </Container>
        </>
    );
};

export default HomeDeliveries;

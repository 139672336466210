/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-shadow */
import CheckIcon from '@icons/check-icon.svg';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Separator, toast } from '@notch-ordering/ui-components';
import { ActionsPanel, SetupInfo } from '@v2/components/Setup/Setup';
import { EPaths } from '@v2/constants/EPaths';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { tCommon, tNamespace } from '@v2/i18n';
import { addSupplierCatalogToBuyer, discoverSupplierCatalogs, SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { AddSupplierModal } from '@v2/Pages/Home/components/AddSupplierModal';
import { generatePlaceholdersByCount } from '@v2/utils/Loader';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'react-router';
import { FETCH_SUPPLIER_CATALOGS_QUERY } from '@v2/hooks/useLinkedSupplierCatalogs';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { DiscoverableSupplier, DiscoveredSupplier } from './DiscoveredSupplier';
import { DiscoveredSupplierSkeleton } from './DiscoveredSupplierSkeleton';

type Props = {
    prop: string,
    router: Router,
};

export const SetupSuppliers: React.FC<Props> = ({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Suppliers' });
    const [isAddSupplierOpen, setIsAddSupplierOpen] = useState(false);
    const [suppliers, setSuppliers] = useState<SupplierCatalogData[]>([]);
    const [nextCursor, setNextCursor] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isBusy, setIsBusy] = useState(false);
    const [linkQueue, setLinkQueue] = useState<DiscoverableSupplier[]>([]);
    const { buyer } = useBuyerHook();
    const queryClient = useQueryClient();
    const { completeTask } = useOnboardingStore();

    function getNextSupplierCatalogs(): void {
        if (!hasNextPage) {
            return;
        }

        setIsLoading(true);

        discoverSupplierCatalogs(buyer.urlsafe, 20, nextCursor)
            .then((data) => {
                setSuppliers([...suppliers, ...data.results.filter((supplier) => !!supplier.supplierName)]);
                setHasNextPage(data.hasNextPage);
                setNextCursor(data.nextCursor);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                toast.show({
                    message: tCommon('somethingWentWrong'),
                    action: {
                        text: tCommon('tryAgain'),
                        onClick: (): void => window.location.reload()
                    },
                    showClose: false
                });
            });
    }

    useEffect(() => {
        const { query } = router.location;
        if (query.createSupplier) {
            setIsAddSupplierOpen(true);
        }
    }, []);

    useEffect(() => {
        if (buyer?.urlsafe) {
            getNextSupplierCatalogs();
        }
    }, [buyer.urlsafe]);

    function goToOrderDesk(): void {
        router.push(EPaths.HOME);
    }

    function openCreateNewSupplierModal(): void {
        sendTrackingEvent(TrackingEvents.openCreateNewSupplierClicked);
        setIsAddSupplierOpen(true);
    }

    async function linkQueuedSuppliers(): Promise<void> {
        setIsBusy(true);

        const buyerUrlsafeKey = buyer.urlsafe;
        let success = 0;

        for (let i = 0; i < linkQueue.length; i++) {
            const supplier: DiscoverableSupplier = linkQueue[i];
            try {
                await addSupplierCatalogToBuyer(supplier.catalogID, buyerUrlsafeKey);
                success++;
            } catch {
                toast.show({
                    message: t('supplierNotAdded', { supplierName: supplier.supplierName })
                });
            }
        }

        if (success > 0) {
            toast.show({
                message: t('addedSuppliers', { count: success }),
                icon: <CheckIcon />,
                showClose: false
            });
            completeTask(buyerUrlsafeKey, EOnboardingTasks.ADD_SUPPLIER);
        }

        setIsBusy(false);
        goToOrderDesk();
    }

    return (
        <>
            <SetupInfo
                title={t('addYourCurrentSuppliers')}
                shortTitle={t('addSuppliers')}
                close={goToOrderDesk}
                back={goToOrderDesk}
                hideCloseMobile
                drawerEnabled={false}
                showShortTitle
                mobileOptions={[
                    {
                        label: t('createNewSupplier'),
                        onClick: openCreateNewSupplierModal
                    }
                ]}/>
            <ActionsPanel
                nextButtonDisabled={linkQueue.length < 1 || isBusy}
                isBusy={isBusy}
                alignTop
                drawerEnabled={false}
                next={linkQueuedSuppliers}
                nextButtonLabel={tCommon('Buttons.done')}
                nextButtonVariant="PRIMARY"
                footerMessage={t('suppliersAdded', { count: linkQueue.length })}
                totalSteps={1}
                currentStep={0}
                onScrolledToBottom={getNextSupplierCatalogs}
                header={
                    <div className="flex justify-start items-center mx-20 py-6 h-full">
                        <Button
                            variant="TERTIARY_FILLED"
                            size="MEDIUM"
                            onClick={openCreateNewSupplierModal}>
                            {t('createNewSupplier')}
                        </Button>
                    </div>}>
                <div className="w-full">
                    {suppliers.map((supplier, i) => (
                        <React.Fragment key={supplier.id}>
                            <Separator hidden={i === 0}/>
                            <DiscoveredSupplier
                                key={supplier.id}
                                name={supplier.supplierName}
                                id={supplier.id}
                                onAdd={(supplier): void => {
                                    setLinkQueue([...linkQueue, supplier]);
                                }}
                                isLoading={isLoading || isBusy}
                                onRemove={(supplier): void => {
                                    setLinkQueue(linkQueue.filter((s) => s.catalogID !== supplier.catalogID));
                                }}/>
                        </React.Fragment>
                    ))}
                    {isLoading && generatePlaceholdersByCount(<DiscoveredSupplierSkeleton />, 10)}
                </div>
            </ActionsPanel>
            <AddSupplierModal isOpen={isAddSupplierOpen}
                close={(): void => setIsAddSupplierOpen(false)}
                onCreateSupplier={(): void => {
                    queryClient.invalidateQueries([FETCH_SUPPLIER_CATALOGS_QUERY, buyer.urlsafe]);
                    goToOrderDesk();
                }}/>
        </>
    );
};

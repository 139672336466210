import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';

import SettingsBox from '../SettingsBox.jsx';
import AccountBox from './AccountBox.jsx';
import NotificationSettings from './NotificationSettings.jsx';
import AccountSettingsForm from './forms/AccountSettingsForm.jsx';
import ModalBox from '../../general/ModalBox.jsx';
import LoadingData from '../../LoadingData.jsx';
import SettingsBackButton from '../header/SettingsBackButton.jsx';
import { basicCustomStyles } from '../../../constants/CustomStyles';
export default class AccountMain extends Component {
  getModalView = () => {
    const {
      showAccountSettingsModal,
      toggleAccountSettingsModal,
      loadingSettingsModal,
      saveAccountSettingsForm,
      error,
    } = this.props;

    return (
      <Modal
        isOpen={showAccountSettingsModal}
        style={basicCustomStyles}
        ariaHideApp={false}
      >
        <ModalBox
          title={'Edit Account Settings'}
          activateSave={true}
          loading={false}
          onClickClose={toggleAccountSettingsModal}
          customStyles={{ height: '100%', marginTop: '8%', borderRadius: '9px' }}
          showHeader={true}
          showFooter={false}
          {...this.props}
        >
          {loadingSettingsModal ? (
            <LoadingData display={loadingSettingsModal} text="Saving" />
          ) : (
            <AccountSettingsForm
              {...this.props}
              errorAsync={error}
              onClickCancel={toggleAccountSettingsModal}
              onSubmit={saveAccountSettingsForm}
            />
          )}
        </ModalBox>
      </Modal>
    );
  };

  render() {
    const { account = {}, toggleAccountSettingsModal } = this.props;

    // Initialize the object of key/values we are sending into the account <AccountBox /> Component
    const accountFields = {
      'First Name': account.firstName,
      'Last Name': account.lastName,
      Email: account.email,
      Password: 'fakepasswordtomask',
    };

    return (
      <>
        <SettingsBox
          title={'Account Settings'}
          icon={'user'}
          onClickEdit={toggleAccountSettingsModal}
        >
          <AccountBox
            toggleAccountSettingsModal={toggleAccountSettingsModal}
            fields={accountFields}
          />
        </SettingsBox>
        <SettingsBox title={'Notification Settings'} icon={'bell'}>
          <NotificationSettings {...this.props} />
        </SettingsBox>
        {this.getModalView()}
      </>
    );
  }
}

AccountMain.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  showAccountSettingsModal: PropTypes.bool.isRequired,
  loadingSettingsModal: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,

  toggleAccountSettingsModal: PropTypes.func.isRequired,
  saveAccountSettingsForm: PropTypes.func.isRequired,
  router: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

import React, { useContext } from 'react';
import { Typography, Button, twMergeCustom } from '@notch-ordering/ui-components';
import FilterIcon from '@icons/filter-icon.svg';
import cx from 'classnames';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';

export interface Props {
    onClick?: () => void,
    hideFilterText?: boolean,
    hideBorderButton?: boolean,
}

export const SearchFilterButton: React.FC<Props> = ({
    hideBorderButton,
    onClick,
    hideFilterText,
}) => {
    const { filters } = useContext(MarketContext);
    const categoriesCount = filters.categories.length;
    const supplierCatalogsCount = filters.supplierCatalogs.length;
    const hasCategories = categoriesCount > 0;
    const hasSupplierCatalogs = supplierCatalogsCount > 0;
    const hasActiveFilters = [!filters.inStockOnly, hasCategories, hasSupplierCatalogs].filter(Boolean).length > 0;
    const countActiveFilters = categoriesCount + supplierCatalogsCount + (+(!filters.inStockOnly));
    return (
        <Button
            onClick={onClick}
            variant="TERTIARY_FILLED"
            size="MEDIUM"
            className={twMergeCustom(
                cx('flex items-center justify-center h-10 gap-2 relative', {
                    'border-2 border-solid border-gray-700': hasActiveFilters,
                    'border-none shadow-transparent': hideBorderButton,
                })
            )}>
            <FilterIcon className={'w-4 h-4 max-w-none'}/>
            {hasActiveFilters && (
                <>
                    <span className="bg-gray-700 rounded-full w-5  h-5 flex justify-center items-center absolute -top-2 -right-2">
                        {countActiveFilters > 0 && (
                            <Typography className="text-white m-0 p-0" variant="BASE">
                                {countActiveFilters}
                            </Typography>

                        )}
                    </span>
                </>
            )}

            {!hideFilterText && (
                <Typography as="span" weight="font-medium">
                    Filters
                </Typography>
            )}
        </Button>
    );
};

export default SearchFilterButton;

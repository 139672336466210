import { IColumnMap, MAP_FIELD_KEYS } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStepConstants';
import { Data } from 'react-csv/components/CommonPropTypes';
import { TInvalidImport } from '@v2/stores/BulkUploadStore';

const PRODUCT_COLUMN_PREFIX = 'productColumn_';

export const QUERY_PARAM_UPLOAD = 'upload';
export const getProductColumnName = (columnMapObj: IColumnMap, name: keyof typeof MAP_FIELD_KEYS, prefix = PRODUCT_COLUMN_PREFIX): string => columnMapObj[`${prefix}${name}`] ?? name;

export const getInvalidCsvData = (invalidProducts: Partial<TInvalidImport[]>, columnMapping: IColumnMap):Data => invalidProducts?.map(({ product }) => ({
    [getProductColumnName(columnMapping, 'packageDescription')]: product.packageDescription,
    [getProductColumnName(columnMapping, 'packageQuantity')]: product.packageQuantity,
    [getProductColumnName(columnMapping, 'packageUnit')]: product.packageUnit,
    [getProductColumnName(columnMapping, 'packageAvailability')]: product.packageAvailability,
    [getProductColumnName(columnMapping, 'productCategory')]: product.productCategory,
    [getProductColumnName(columnMapping, 'productDescription')]: product.productDescription,
    [getProductColumnName(columnMapping, 'productName')]: product.productName,
    [getProductColumnName(columnMapping, 'packageSku')]: product.packageSku,
    [getProductColumnName(columnMapping, 'productTaxCode')]: product.productTaxCode,
}));

import React from 'react';
import { OrderData } from '@v2/types/OrderData';
import { IProduct } from '@v2/network/types';

interface ProductCardContextProps {
    order: OrderData,
    product?: IProduct,
    numberOfProductsInOrder: number,
    productsInOrder?: OrderData['items'],
}

/**
 *
 * BuyerSupplierContext - Context for the buyer supplier context
 */
export const ProductCardContext = React.createContext<ProductCardContextProps>({
    order: {} as OrderData,
    product: {} as IProduct,
    numberOfProductsInOrder: 0,
    productsInOrder: [],
});

type ProductCartProviderProps = ProductCardContextProps & { children: React.ReactNode };

export const ProductCardProvider = ({ children, order, numberOfProductsInOrder = 0, product }:ProductCartProviderProps): JSX.Element => <ProductCardContext.Provider value={{
    order,
    numberOfProductsInOrder,
    productsInOrder: order?.items,
    product
}}>{children}</ProductCardContext.Provider>;

import React from 'react';
import { AccountingInfo } from '@notch-ordering/shared-logic';
import { useAccounting } from '@v2/hooks/useAccounting.hook';

export interface InvoicesPageContextType {
    isAccountingEnabled: boolean,
    accountingInfo: AccountingInfo,
    isLoading?: boolean,
}

export const InvoicesPageContext = React.createContext<InvoicesPageContextType>({
    isAccountingEnabled: false,
    accountingInfo: null,
});

type InvoicesPageContextProps = { children: React.ReactNode };

export const InvoicesPageContextProvider = ({ children }: InvoicesPageContextProps): JSX.Element => {
    const accounting = useAccounting();
    return <InvoicesPageContext.Provider value={accounting}>{children}</InvoicesPageContext.Provider>;
};

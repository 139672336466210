import React from 'react';
import { Button, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { LineItemTypeOptions, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import ChartOfAccountDetailsEmptyImage from '@images/chart-of-account-details-empty.svg';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';

export const ScanChartOfAccountEmpty : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.ChartOfAccountDetails' });
    const { addFormLineItem, draftInvoice } = useOcrInvoiceStore();
    const { setCurrentView } = useOcrInvoiceContext();
    const heightOfFooter = 142; // pixels
    const emptyPage = <div className={`h-full flex flex-col items-center justify-center pb-[${heightOfFooter}px]`}>
        <ChartOfAccountDetailsEmptyImage className="w-[68px] h-[89px] mb-8" />
        <Typography as="div" variant="LG-2" weight="font-semibold">{t('youHaveNoCodes')}</Typography>
        <Typography as="div" className="text-gray-600 w-[343px] pt-1 mb-8">{t('assignGLCodes')}</Typography>
        <Button variant={'TERTIARY_FILLED'}
            size={'SMALL'}
            onClick={():void => { setCurrentView(ScanReviewViews.LineItems); }}>
            {t('goToLineItems')}
        </Button>
    </div>;
    if (draftInvoice?.isAutoCalculateOn) {
        return emptyPage;
    }
    addFormLineItem(LineItemTypeOptions.PRODUCT, 'chartOfAccountDetails');
    return <></>;
};

import React, { useState } from 'react';
import { Button, ComboOption, Combobox, Popover, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { useOcrInvoiceStore, getLineItemFieldByIndex, getChartOfAccountsName, EConfigurationType } from '@notch-ordering/shared-logic';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';
import { CurrencyInput } from '../ScanLineItems/ScanLineItemsForm/InputCurrency';

type Props = {
    index: number,
    reactKey: string,
    num: number,
    glCode: string,
    glCodeOptions: ComboOption[],
    handleOnFocusField: (e: React.FocusEvent<HTMLInputElement>) => void,
    hasErrorFieldIndex: (index: number, field: string) => boolean,
    onBlur: () => void,
    taxCode: string,
    taxCodeOptions: ComboOption[],
    total: number,
    tax: number,
    isAutoCalculateOn: boolean,
};

export const ScanChartOfAccountLineItem : React.FC<Props> = ({
    index,
    reactKey,
    num,
    glCode,
    glCodeOptions,
    handleOnFocusField,
    hasErrorFieldIndex,
    onBlur,
    taxCode,
    taxCodeOptions,
    total,
    tax,
    isAutoCalculateOn
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.ChartOfAccountDetails' });
    const { removeLineItem, updateLineItemPrimaryData, draftInvoice } = useOcrInvoiceStore();
    const [currentTotalInput, setCurrentTotalInput] = useState<number>(total);
    const [currentTaxInput, setCurrentTaxInput] = useState<number>(tax);
    const isBigChip = draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip;

    return <div className="px-8 py-10">
        <div className="flex flex-row justify-between">
            <Typography weight="font-bold" desktopSize="lg:text-3" className="mt-0 mb-4">{num}</Typography>
            {!isAutoCalculateOn && <Popover
                className="w-44"
                button={<Button
                    variant="TERTIARY_FILLED"
                    className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                    size="SMALL"
                    stopPropagation={false}>
                    <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                        <EllipsisIcon className="h-4 w-4"/>
                    </div>
                </Button>}
                items={[
                    {
                        label: <Typography className="flex gap-3 items-center m-0">
                            <TrashIcon className="w-4 h-4"/>
                            {tCommon('Buttons.delete')}
                        </Typography>,
                        className: 'text-red-300',
                        onClick: (): void => {
                            removeLineItem(reactKey, 'chartOfAccountDetails');
                            sendTrackingEvent({ ...TrackingEvents.moreActionsSelected, label: TrackingLabel.OCR });
                        },
                    },
                ]}/>}
        </div>
        <div className="flex gap-2 mb-2">
            <Combobox
                onChange={(value): void => {
                    updateLineItemPrimaryData(reactKey, 'glCodeID', value.value, 'chartOfAccountDetails');
                    const chartOfAccountsName = getChartOfAccountsName(draftInvoice?.taxCodes, taxCode, draftInvoice?.glCodes, value.value, draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip);
                    updateLineItemPrimaryData(reactKey, 'name', chartOfAccountsName, 'chartOfAccountDetails');
                    updateLineItemPrimaryData(reactKey, 'sku', chartOfAccountsName, 'chartOfAccountDetails');
                    sendTrackingEvent({ ...TrackingEvents.glCodeDropdownSelected, label: TrackingLabel.OCR });
                }}
                onFocus={():void => {
                    handleOnFocusField({ target: { id: getLineItemFieldByIndex(index, 'glCodeID') } } as React.FocusEvent<HTMLInputElement>);
                }}
                disabled={isAutoCalculateOn}
                id={getLineItemFieldByIndex(index, 'glCodeID')}
                isWarning={hasErrorFieldIndex(index, 'glCodeID')}
                onBlur={onBlur}
                options={glCodeOptions}
                value={glCode}
                label={t('glCode')}
                variant="ADVANCED_ALTERNATIVE"/>
            {!isBigChip && <Combobox
                onChange={(value): void => {
                    updateLineItemPrimaryData(reactKey, 'taxCodeID', value.value, 'chartOfAccountDetails');
                    const chartOfAccountsName = getChartOfAccountsName(draftInvoice?.taxCodes, value.value, draftInvoice?.glCodes, glCode, draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip);
                    updateLineItemPrimaryData(reactKey, 'name', chartOfAccountsName, 'chartOfAccountDetails');
                    updateLineItemPrimaryData(reactKey, 'sku', chartOfAccountsName, 'chartOfAccountDetails');
                    sendTrackingEvent({ ...TrackingEvents.taxCodeDropdownSelected, label: TrackingLabel.OCR });
                }}
                onFocus={():void => {
                    handleOnFocusField({ target: { id: getLineItemFieldByIndex(index, 'taxCodeID') } } as React.FocusEvent<HTMLInputElement>);
                }}
                id={getLineItemFieldByIndex(index, 'taxCodeID')}
                isWarning={hasErrorFieldIndex(index, 'taxCodeID')}
                onBlur={onBlur}
                disabled={isAutoCalculateOn}
                options={taxCodeOptions}
                value={taxCode}
                label={t('taxCode')}
                variant="ADVANCED_ALTERNATIVE"/>}
            <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                onChange={(values): void => { setCurrentTotalInput(values.floatValue ?? 0); }}
                onBlur={(): void => {
                    updateLineItemPrimaryData(reactKey, 'total', currentTotalInput, 'chartOfAccountDetails');
                    onBlur();
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>): void => {
                    sendTrackingEvent({ ...TrackingEvents.totalFieldFocus, label: TrackingLabel.OCR });
                    handleOnFocusField(e);
                }}
                disabled={isAutoCalculateOn}
                id={getLineItemFieldByIndex(index, 'total')}
                isWarning={hasErrorFieldIndex(index, 'total')}
                label={t('total')}
                value={ currentTotalInput }
                allowNegative />
            <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                onChange={(values): void => { setCurrentTaxInput(values.floatValue ?? 0); }}
                onBlur={(): void => {
                    updateLineItemPrimaryData(reactKey, 'tax', { dollarAmount: currentTaxInput }, 'chartOfAccountDetails');
                    onBlur();
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>): void => {
                    sendTrackingEvent({ ...TrackingEvents.taxFieldFocus, label: TrackingLabel.OCR });
                    handleOnFocusField(e);
                }}
                disabled={isAutoCalculateOn}
                id={getLineItemFieldByIndex(index, 'tax')}
                isWarning={hasErrorFieldIndex(index, 'tax')}
                label={t('tax')}
                value={ currentTaxInput }
                allowNegative />
        </div>
    </div>;
};

import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from 'react-redux';

import { AnalyticsEventSources } from '../../../constants/AnalyticsEventSources';
import { changeQuantityWithoutID } from '../../../actions/orderActions';
import CartItem from './CartItem/CartItem.jsx';
import SearchSuggestion from '../step2/SearchSuggestion.jsx';
import CartOrderCustomItems from '../../cart/cart_order_custom_items/CartOrderCustomItems.jsx';
import { logException } from '@/domains/shared/logger';
import { fetchProducts, TFetchProductsType } from "@v2/network/SecretShopAPI";
import { getSupplierByVendorUrlSafe, mapPackageToOrderProduct } from "@v2/utils/ShopUtils";
import { useOnboardedSuppliers } from "@v2/hooks/useOnboardedSuppliers.hook";
import Utils from "@/utils";
import { Typography } from '@notch-ordering/ui-components';

const Cart = ({ order, buyer, vendor, isEditingAddon }) => {
  const [editingItemUrlsafe, setEditingItemUrlsafe] = useState('');
  const [isAddingItem, setIsAddingItem] = useState('');
  const singleOrder = useSelector((state) => state.ordersReducer.singleOrder);
  const customItems = useSelector((state) => state.ordersReducer.customItems);
  const dispatch = useDispatch();
  const { suppliers = [] } = useOnboardedSuppliers();
  const filterOption = ({ data }) => {
    const orderItemIDs = order.items.map((item) => item.sourceUrlsafe);
    // Filter items already added to the order
    return !orderItemIDs.includes(data?.package?.id);
  };

  const selectNewItem = (selectedItem) => {
    setIsAddingItem(false);
    const product = mapPackageToOrderProduct({
      product: selectedItem,
      packageProduct: selectedItem.package,
    });
    dispatch(
      changeQuantityWithoutID(
        order.urlsafe,
        null,
        1,
        Utils.QUANTITY_TYPES.CUSTOM,
        AnalyticsEventSources.CHECKOUT,
        product
      )
    );
  };

  const getOptions = async (query, callback) => {
    try {
      // Don't return anything if we have no query
      if (!query) {
        return;
      }

      const supplier = getSupplierByVendorUrlSafe(suppliers, vendor.urlsafe);
      const params = {
        supplierCatalogs: [supplier.id],
        inStockOnly: true,
        name: query,
        limit: 20
      };
      const response = await fetchProducts({ buyerUrlsafeKey: buyer.urlsafe, type: TFetchProductsType.SEARCH, params });
      const options = response ? response.results : [];
      const mergedPackagesIntoProduct = options.reduce((acc, item) => {
        return acc.concat(item.packages.map((pack) => {
          return {
            ...item,
            package: pack
          }
        }))
      }, []);
      callback(mergedPackagesIntoProduct);
    } catch (error) {
      logException(error);
    }
  };
  const cItems = customItems.orders[order.urlsafe] ?? [];
  return (
    <div className="cart">
      <div className="header">
        <Typography as='span' variant='LG-2' weight='font-medium'>Your item{order.items.length > 1 && 's'}</Typography>
        {!isAddingItem && (
          <button className="add-item" onClick={() => setIsAddingItem(true)}>
            <Typography as='span' variant='LG-2' className='text-teal-300'>Add item</Typography>
          </button>
        )}
        {isAddingItem && (
          <div className="new-item-search">
            <AsyncSelect
              name="search"
              cache={false}
              loadOptions={getOptions}
              placeholder={
                <div>
                  <Typography as='div'>Type to search...</Typography>
                  <span className="fa fa-lg fa-search search-icon"></span>
                </div>
              }
              searchPromptText=""
              onChange={selectNewItem}
              components={{
                Option: SearchSuggestion,
                IndicatorsContainer: () => null,
              }}
              filterOption={filterOption}
              onBlur={() => setIsAddingItem(false)}
              noOptionsMessage={({ inputValue }) => {
                if (inputValue) {
                  return <Typography as='span'>No items were found</Typography>;
                } else {
                  return null;
                }
              }}
              focus
              autoFocus
            />
          </div>
        )}
      </div>
      <div className="body">
        {order.items.map((item) => (
          <CartItem
            order={order}
            item={item}
            key={item.urlsafe}
            onEdit={(urlsafe) => setEditingItemUrlsafe(urlsafe)}
            isEditing={item.urlsafe === editingItemUrlsafe}
            originalQuantity={
              singleOrder.items.find(
                (orderItem) => orderItem.urlsafe === item.urlsafe
              )?.quantity
            }
            isEditingAddon={isEditingAddon}
          />
        ))}
        {cItems.length > 0 && (
          <>
            <div className="header">
              <Typography as='h2' variant='XL' weight='font-semibold' desktopSize='lg:text-5'>Added by you for this order</Typography>
            </div>
            <CartOrderCustomItems orderItems={cItems} urlsafe={order.urlsafe} />
          </>
        )}
      </div>
    </div>
  );
};

export default Cart;

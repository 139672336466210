import React, { createContext, useMemo, useState } from 'react';
import Router from 'react-router';
import { OcrInvoice, SaveOcrArgs } from '@notch-ordering/shared-logic';

export const enum ScanReviewViews {
    Overview = 'Overview',
    LineItems = 'LineItems',
    BasicDetails = 'BasicDetails',
    ChartOfAccountDetails = 'ChartOfAccountDetails'
}

export enum Tabs {
    ACCOUNTING,
    IMS,
    NO_TABS
}

export type OnSaveOcrInvoiceArgs = SaveOcrArgs & {
    onSuccess?: () => void,
    onError?: () => void,
};

export type OcrInvoiceContextType = {
    router: Router,
    initialInvoice: OcrInvoice,
    onSaveOcrInvoice: (onSaveOcrInvoiceArgs?:OnSaveOcrInvoiceArgs) => void,
    isSavingOcrInvoice?: boolean,
    setIsSavingOcrInvoice?: (isSaving: boolean) => void,
    currentView?:ScanReviewViews,
    setCurrentView?: (currentView: ScanReviewViews) => void,
    selectedTab?: Tabs,
    setSelectedTab?: (tabsSelected: Tabs) => void,
    prevSelectedTab?: Tabs,
    setPrevSelectedTab?: (tabsSelected: Tabs) => void,
    currentErrorField?: number,
    setCurrentErrorField?: (currentErrorField: number) => void,
    showRequiredFieldsOverviewCard?: boolean,
    setShowRequiredFieldsOverviewCard?: (showRequiredFieldsOverviewCard: boolean) => void,
    hasImsIntegration?: boolean | null, // null to indicate that request is still in flight
    setHasImsIntegration?: (hasImsIntegration: boolean) => void,
};
export const OcrInvoiceContext = createContext<OcrInvoiceContextType>(null);

export type OcrInvoiceContextProviderProps = {
    value: OcrInvoiceContextType,
    children: React.ReactNode,
    defaultTab: Tabs,
};

export const OcrInvoiceContextProvider:React.FC<OcrInvoiceContextProviderProps> = ({
    children,
    value,
    defaultTab
}):JSX.Element => {
    // local state
    const [showRequiredFieldsOverviewCard, setShowRequiredFieldsOverviewCard] = useState(true);
    const [currentView, setCurrentView] = useState<ScanReviewViews>(ScanReviewViews.Overview);
    const [selectedTab, setSelectedTab] = useState<Tabs>(defaultTab);
    const [prevSelectedTab, setPrevSelectedTab] = useState<Tabs>(defaultTab);
    const [currentErrorField, setCurrentErrorField] = useState(0);
    const [hasImsIntegration, setHasImsIntegration] = useState(null);

    // memoized value including external state
    const updatedValue: OcrInvoiceContextType = useMemo(() => ({
        ...value,
        showRequiredFieldsOverviewCard,
        setShowRequiredFieldsOverviewCard,
        currentView,
        setCurrentView,
        selectedTab,
        setSelectedTab,
        prevSelectedTab,
        setPrevSelectedTab,
        currentErrorField,
        setCurrentErrorField,
        hasImsIntegration,
        setHasImsIntegration,
    }), [
        value,
        showRequiredFieldsOverviewCard,
        setShowRequiredFieldsOverviewCard,
        currentView,
        setCurrentView,
        selectedTab,
        setSelectedTab,
        prevSelectedTab,
        setPrevSelectedTab,
        currentErrorField,
        setCurrentErrorField,
        hasImsIntegration,
        setHasImsIntegration,
    ]);

    return <OcrInvoiceContext.Provider value={updatedValue}>{children}</OcrInvoiceContext.Provider>;
};

export const useOcrInvoiceContext = (): OcrInvoiceContextType => React.useContext(OcrInvoiceContext);

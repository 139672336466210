import PropTypes from 'prop-types';
import React, { Component } from 'react';

import VariantRowOrderProduct from './VariantRowOrderProduct.jsx';

export default function VariantsList(props) {
  const { variants = [] } = props;

  return (
    <div className="variants-list">
      {variants.map((variant) => (
        <VariantRowOrderProduct key={variant.id} variant={variant} {...props} />
      ))}
    </div>
  );
}

VariantsList.propTypes = {
  variants: PropTypes.array.isRequired,
};

import { IonCheckbox } from '@ionic/react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class NotificationSettings extends Component {
  clickCheckBox = (event, checked) => {
    const { account, buyerMembers } = this.props;
    const buyerMemberForAccount = buyerMembers.find(
      (member) => member.account.urlsafe === account.urlsafe
    );

    if (
      buyerMemberForAccount &&
      buyerMemberForAccount.receiveNotification !== checked
    ) {
      this.props.updateEmailNotficationAccount();
    }
  };

  render() {
    const { account, buyerMembers } = this.props;
    const buyerMemberForAccount = buyerMembers.find(
      (member) => member.account.urlsafe === account.urlsafe
    );

    return (
      <div className="account-notification">
        <div>
          <IonCheckbox
            class="notification-checkbox"
            checked={
              !_.isEmpty(buyerMemberForAccount)
                ? buyerMemberForAccount.receiveNotification
                : false
            }
            onIonChange={this.clickCheckBox}
          />
          <label htmlFor="checkbox1">Receive email notifications?</label>
        </div>
      </div>
    );
  }
}

NotificationSettings.propTypes = {
  account: PropTypes.object.isRequired,
  buyerMembers: PropTypes.array.isRequired,

  updateEmailNotficationAccount: PropTypes.func.isRequired,
};

import React, { FunctionComponent } from 'react';
import cx from 'classnames';

export type Type = 'button' | 'submit' | 'reset';

export type Color =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'dismiss'
  | 'link'
  | 'warning'
  | 'warning-invert'
  | 'dismiss-white-bg';

export interface ButtonProps {
  children: string | string[] | React.ReactElement;
  disabled?: boolean;
  blockStyle?: boolean;
  sliceOffRightSide?: boolean;
  color?: Color;
  className?: string;
  type?: Type;
  dataTestid?: string;
  onClick?: (event: React.MouseEvent) => void;
  isLoading?: boolean;
}

const Button: FunctionComponent<ButtonProps> = ({
  children,
  disabled = false,
  blockStyle = false,
  sliceOffRightSide = false,
  className = '',
  color = 'primary',
  type = 'button',
  dataTestid = 'button',
  onClick = undefined,
  isLoading = false,
}: ButtonProps): JSX.Element => {
  const classes = cx(
    'button',
    {
      [`button-${color}`]: color,
      'button-block': blockStyle,
      'button-slice': sliceOffRightSide,
      'button-disabled': disabled,
      'is-loading': isLoading,
    },
    className
  );

  return (
    <button
      data-testid={dataTestid}
      className={classes}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="button-content">{children}</span>
    </button>
  );
};

export default Button;

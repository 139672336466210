import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';

import renderFieldTextArea from '../../../../redux-form/renderFieldTextArea.js';
import renderField from '../../../../redux-form/renderField.js';
import { Typography } from '@notch-ordering/ui-components';

class EditDeliveryRequestsView extends Component {
  state = {
    deliveryInstructions: '',
    deliveryContactName: '',
    deliveryContactNumber: '',
  };

  initialState = {};

  componentDidMount() {
    this.renderOrderDetails();
    this.initialState = this.state;
    this.props.analyticsViewPage('EditDeliveryRequests');
  }

  onClickCancelSelect = (event) => {
    event.preventDefault();
    const { chosenLocation, changeOrderDeliveryRequestsView } = this.props;

    // If we have a chosen address go to ShowDeliveryRequestsView
    if (chosenLocation) {
      changeOrderDeliveryRequestsView('ShowDeliveryRequestsView');
    }
    // Else, don't do anything
    else {
      return false;
    }
  };

  getOrderDeliverySpecialRequests = (order) => {
    // We can just refer to the first (of multiple) orders at checkout because the special requests will apply to all of them
    const orderSpecialRequests = [null, null, null];
    let specialRequestsIndex = 0;
    order.deliveryRequests.split('; ').forEach((orderDeliveryRequest) => {
      // order.deliveryRequests are always in the form
      // deliveryRequests: ...; deliveryContactName: ...; deliveryContactNumber: ...
      // To make sure colons that appear within the delivery requests don't cause an issue, we will split by regex
      // TODO: Look into doing the same for semicolons above
      const labelAndRequest = orderDeliveryRequest.split(/delivery.*?: /);
      orderSpecialRequests[specialRequestsIndex] = labelAndRequest[1];
      specialRequestsIndex++;
    });
    return {
      orderDeliveryRequests: orderSpecialRequests[0],
      orderDeliveryContactName: orderSpecialRequests[1],
      orderDeliveryContactNumber: orderSpecialRequests[2],
    };
  };

  renderOrderDetails = () => {
    const { shippingAddresses, orders } = this.props;
    const {
      orderDeliveryRequests,
      orderDeliveryContactName,
      orderDeliveryContactNumber,
    } = this.getOrderDeliverySpecialRequests(orders[0]);

    // There will only be one shipping address with every buyer, hence we can just use shipppingAddresses[0]
    this.props.changeFieldValue(
      'deliveryInstructions',
      orderDeliveryRequests
        ? orderDeliveryRequests
        : shippingAddresses[0].driveInstructions
    );
    this.props.changeFieldValue(
      'deliveryContactName',
      orderDeliveryContactName
        ? orderDeliveryContactName
        : shippingAddresses[0].deliveryContactName
    );
    this.props.changeFieldValue(
      'deliveryContactNumber',
      orderDeliveryContactNumber
        ? orderDeliveryContactNumber
        : shippingAddresses[0].deliveryContactNumber
    );
    this.setState({
      deliveryInstructions: orderDeliveryRequests
        ? orderDeliveryRequests
        : shippingAddresses[0].driveInstructions,
      deliveryContactName: orderDeliveryContactName
        ? orderDeliveryContactName
        : shippingAddresses[0].deliveryContactName,
      deliveryContactNumber: orderDeliveryContactNumber
        ? orderDeliveryContactNumber
        : shippingAddresses[0].deliveryContactNumber,
    });
  };

  onChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  confirmDeliveryRequests = (event) => {
    event.preventDefault();

    this.props.updateOrdersDeliveryRequests(
      this.state.deliveryContactName,
      this.state.deliveryContactNumber,
      this.state.deliveryInstructions
    );
  };

  render() {
    const { shippingAddresses } = this.props;

    return (
      <div>
        {shippingAddresses.length === 0 && (
          <Typography as='div' className="error-message">
            No delivery locations found. Please contact our support to add a
            shipping location to your account.
          </Typography>
        )}
        <div className="full-address">
          <Typography as='span'>Delivers to: </Typography>
          {/* This can be done because there is only one shipping address per buyer */}
          <Typography as='span'>{shippingAddresses[0].formatted}</Typography>
        </div>
        <div>
          <div className="edit-delivery-instructions">
            <Typography className="text-gray-500">
              Special Delivery Request (for this order only):
            </Typography>
            <div>
              <Typography className="delivery-instructions-subheader">
                Please note: We are unable to guarantee all delivery requests.
                We will do our best to accomodate this request.
              </Typography>
            </div>
            <div>
              <Typography className="delivery-instructions-subheader text-red-500">
                If this request is urgent, please contact our support team for
                immediate followup.
              </Typography>
            </div>
            <Field
              name="deliveryInstructions"
              id="deliveryInstructions"
              className="form-control"
              ref="deliveryInstructions"
              hintText="* Where should the driver deliver? Front door/Side door/ Loading Dock etc.&#10;* Lockboxes, doorbells, codes etc?&#10;* Parking instructions?&#10;* Who's receiving the order? What's their phone number?&#10;* Anything else to help us deliver correctly"
              label=""
              type="text"
              onChange={this.onChange}
              component={renderFieldTextArea}
            />
          </div>
          <Typography className="contact-info-help-text">
            Please enter the preferred contact person for this order. This
            information helps drivers ensure your order is delivered in the most
            efficient way possible.
          </Typography>
          <div className="edit-contact-info-input">
            <div className="contact-name-input">
              <Typography as='span' className="contact-name-label">Contact Name</Typography>
              <Field
                type="text"
                className="form-control"
                name="deliveryContactName"
                id="deliveryContactName"
                ref="deliveryContactName"
                onChange={this.onChange}
                label=""
                component={renderField}
              />
            </div>
            <div className="contact-phone-input">
              <Typography as='span' className="contact-phone-label">Contact Phone Number</Typography>
              <Field
                type="text"
                className="form-control"
                name="deliveryContactNumber"
                id="deliveryContactNumber"
                ref="deliveryContactNumber"
                onChange={this.onChange}
                label=""
                component={renderField}
              />
            </div>
          </div>
        </div>
        {/* Cancel Button */}
        <button
          type="button"
          className="btn btn-sm flat cancel"
          onClick={this.onClickCancelSelect}
        >
          <Typography as='span' variant='BASE'>Cancel</Typography>
        </button>
        {/* Save Button */}
        {/* Disabled button if no selected address */}
        <button
          type="button"
          title="Please select or add a new address before saving"
          className="btn btn-sm flat save"
          onClick={this.confirmDeliveryRequests}
          disabled={this.state === this.initialState}
        >
          <Typography as='span' variant='BASE'>Save</Typography>
        </button>
      </div>
    );
  }
}

EditDeliveryRequestsView.propTypes = {
  updateOrdersDeliveryRequests: PropTypes.func.isRequired,
  changeOrderDeliveryRequestsView: PropTypes.func.isRequired,

  shippingAddresses: PropTypes.array.isRequired,
  chosenLocation: PropTypes.string.isRequired,
  deliveryInstructions: PropTypes.string.isRequired,
  deliveryContactName: PropTypes.string.isRequired,
  deliveryContactNumber: PropTypes.string.isRequired,
  analyticsViewPage: PropTypes.func.isRequired,

  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect(
  (state) => {
    return {
      initialValues: {
        deliveryInstructions: '',
        deliveryContactName: '',
        deliveryContactNumber: '',
      },
    };
  },
  (dispatch) => {
    return {
      // This will be passed as a property to the presentational component
      changeFieldValue: (field, value) => {
        dispatch(change('EditDeliveryRequestsView', field, value));
      },
    };
  }
)(
  reduxForm({
    // Decorate the form component
    form: 'EditDeliveryRequestsView',
  })(EditDeliveryRequestsView)
);

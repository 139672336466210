import PropTypes from 'prop-types';
import React, { Component } from 'react';

import InlineConfirm from '../InlineConfirm.jsx';

export default class SettingsBox extends Component {
  onClickEdit = () => {
    this.props.onClickEdit();
  };

  onClickDelete = () => {
    this.props.onClickDelete();
  };

  render() {
    const { title, icon, onClickEdit, hideEdit, onClickDelete } = this.props;

    return (
      <div className="settings-box">
        <div className="settings-box-header">
          <span className="settings-box-header-title">
            <i className={`fa fa-${icon}`} aria-hidden="true"></i>
            {title}
          </span>

          <div className="pull-right">
            {!hideEdit && onClickEdit && (
              <button
                className="btn btn-link change-btn"
                onClick={this.onClickEdit}
              >
                Edit
              </button>
            )}

            {onClickDelete && (
              <InlineConfirm confirmedYes={this.onClickDelete}>
                <button className="btn btn-link delete-btn">Delete</button>
              </InlineConfirm>
            )}
          </div>
        </div>
        <div className="settings-box-body">{this.props.children}</div>
      </div>
    );
  }
}

SettingsBox.propTypes = {
  title: PropTypes.string.isRequired,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  hideEdit: PropTypes.bool,
};

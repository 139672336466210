import create from 'zustand';
import { persist } from 'zustand/middleware';

type CheckoutStoreState = {
    deliveryInstructions: string,
    setDeliveryInstructions: (deliveryInstructions: string) => void,
};

export const useCheckoutStore = create<CheckoutStoreState>()(
    persist(
        (set): CheckoutStoreState => ({
            deliveryInstructions: '',
            setDeliveryInstructions: (deliveryInstructions: string): void => set(() => ({ deliveryInstructions })),
        }),
        { name: 'checkout' }
    )
);

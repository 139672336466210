import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SettingsBox from '../SettingsBox.jsx';
import BuyerBox from './BuyerBox.jsx';
import SettingsBackButton from '../header/SettingsBackButton.jsx';

export default class BuyerMain extends Component {
  render() {
    const { buyer } = this.props;

    // Initialize the object of key/values we are sending into the buyer <BuyerBox /> Component
    const buyerFields = {
      'Business Name': buyer.displayName ? buyer.displayName : buyer.name,
    };

    // There are the keys and values we are sending into the buyer <BuyerBox /> component,
    // We need to make sure we only create the following keys if they have valid values,
    // otherwise don't show them
    if (buyer.billingAddress) {
      const {
        name = '',
        line1,
        line2 = '',
        zip,
        city,
        state,
        country,
      } = buyer.billingAddress;

      buyerFields['Billing Name'] = name;
      buyerFields[
        'Billing Address'
      ] = `${line1} ${line2}, ${city}, ${state} ${zip}, ${country}`;
    }

    if (buyer.accountingEmails && buyer.accountingEmails.length > 0) {
      buyerFields['Accounting Email'] = buyer.accountingEmails;
    }
    if (buyer.phone && buyer.phone.length > 0) {
      buyerFields['Business Phone Number'] = buyer.phone;
    }
    if (buyer.fax && buyer.fax.length > 0) {
      buyerFields['Fax Number'] = buyer.fax;
    }
    if (buyer.email && buyer.email.length > 0) {
      buyerFields['Email'] = buyer.email;
    }

    return (
      <>
        <SettingsBackButton router={this.props.router} />
        <SettingsBox title={'Business Settings'} icon={'home'}>
          <BuyerBox {...this.props} fields={buyerFields} />
        </SettingsBox>
      </>
    );
  }
}

BuyerMain.propTypes = {
  buyer: PropTypes.objectOf(PropTypes.any).isRequired,
  showEditBuyerModal: PropTypes.bool.isRequired,
  loadingSettingsModal: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  router: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ReceivingToolEdit from "./ReceivingToolEdit.jsx";
import ReceivingToolSearch from "./ReceivingToolSearch.jsx";
import utils from "../../../utils";
import {
  BUCKET_ASSETS_STATIC_URL,
  BUCKET_ASSETS_URL,
} from "@v2/constants/Assets";

const ReceivingToolBody = ({
  updateProductList,
  productList,
  isOrderEditable,
  loading,
}) => {
  const [visible, updateVisiable] = useState(false);
  const [selectedOrderItem, updateSelectedOrderItem] = useState(() => {});

  const editOrder = (orderItem) => {
    updateVisiable(true);
    const hasOlderLocalVariant = productList.find(
      (item) => item.name === orderItem.name && item.isDelete
    );
    updateSelectedOrderItem(
      hasOlderLocalVariant ? hasOlderLocalVariant : orderItem
    );
  };

  const closeModal = () => {
    updateVisiable(false);
  };

  // whether to deal with the item as a packaging or a per unit
  const isPackageCheck = (orderItem) => {
    return (
      orderItem?.unitQuantity == null ||
      orderItem?.salesUnit == null ||
      orderItem?.salesUnit === ""
    );
  };

  return (
    <div className="od-body-container">
      <div className="note">
        <span>
          Review this order. You may modify any of the items below if they
          differ from what you received.
        </span>
      </div>
      {productList.map((item, index) => {
        if (item.isDelete) {
          return null;
        }
        const isPackage = isPackageCheck(item);
        const totalPriceEdited =
          utils.roundOff2(item.expectedDeliveryTotal) !==
            utils.roundOff2(item.total) ||
          item.isQuantityUpdated ||
          item.isWeightUpdated ||
          item.isPriceUpdated;
        const isItemEditable = !isOrderEditable || loading || item.isOldPackage;
        return (
          <button
            className={cx("item", {
              "item-disabled": isItemEditable,
            })}
            key={index}
            onClick={() => editOrder(item)}
            disabled={isItemEditable}
          >
            <div className="description">
              <img
                src={
                  item.imageUrl
                    ? item.imageUrl
                    : `${BUCKET_ASSETS_STATIC_URL}images/your-item-market-placeholder.png`
                }
                alt="product"
              />
            </div>
            <div className="description-body-container">
              <div className="description-body">
                <div className="header">{item.name}</div>
                {!isItemEditable && (
                  <img
                    src={`${BUCKET_ASSETS_URL}edit-purple-icon.svg`}
                    alt="Edit Icon"
                  />
                )}
              </div>
              <div className="description-body">
                <div className="body">
                  {item.unitName} - {item.unitDescription}
                </div>
                <div
                  className={cx("header", {
                    "edited-bg":
                      item.packagingQuantity !==
                        item.expectedDeliveryQuantity || item.isWeightUpdated,
                  })}
                >
                  x{" "}
                  <span
                    className={cx({
                      "edited-text":
                        item.packagingQuantity !==
                          item.expectedDeliveryQuantity || item.isWeightUpdated,
                    })}
                  >
                    {item.packagingQuantity}
                  </span>
                </div>
              </div>
              <div className="description-body">
                {isPackage ? (
                  <>
                    <div className="body">${item.packagingPrice}</div>
                    <div
                      className={cx("body", {
                        "edited-bg": totalPriceEdited,
                      })}
                    >
                      <span
                        className={cx({
                          "edited-text": totalPriceEdited,
                        })}
                      >
                        ${utils.roundOff2(item.total)}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="body">
                      ${item.unitPrice}/{item.salesUnit}
                    </div>
                    <div
                      className={cx("body", {
                        "edited-bg": totalPriceEdited,
                      })}
                    >
                      <span
                        className={cx({
                          "edited-text": totalPriceEdited,
                        })}
                      >
                        ${utils.roundOff2(item.total)}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="description-body">
                {item.receivalIssueChange && (
                  <div className="reason">
                    <div className="reason-text">
                      {item.receivalIssueChange}
                    </div>
                    <div className="reason-text">
                      {item.receivalIssueDescription}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </button>
        );
      })}
      <ReceivingToolSearch
        isEditable={!isOrderEditable || loading}
        updateOrder={updateProductList}
        items={productList}
        isWeb={false}
      />
      {visible && (
        <ReceivingToolEdit
          visible={visible}
          orderItem={selectedOrderItem}
          isPackage={isPackageCheck(selectedOrderItem)}
          updateOrder={updateProductList}
          closeModal={closeModal}
          items={productList}
        />
      )}
    </div>
  );
};

export default ReceivingToolBody;

ReceivingToolBody.propTypes = {
  productList: PropTypes.arrayOf(
    PropTypes.shape({
      productCode: PropTypes.string,
      unitName: PropTypes.string,
      unitDescription: PropTypes.string,
      unitQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      salesUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  updateProductList: PropTypes.func.isRequired,
  isOrderEditable: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

import React from 'react';

export const useIntersectionObserver = (ref: React.RefObject<HTMLElement>, options: IntersectionObserverInit):boolean => {
    const [isIntersecting, setIsIntersecting] = React.useState(false);

    React.useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
        }, options);

        if (ref.current) {
            observer.observe(ref.current);
        }

        return ():void => {
            if (ref?.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref.current]);

    return isIntersecting;
};

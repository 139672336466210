import { Button, Popover, Tooltip } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { tNamespace } from '@v2/i18n';
import { browserHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Order } from '@v2/network/LegacyAPI';
import { listInvoices } from '@v2/network/GreevilsGreedApi';
import { useQuery } from '@tanstack/react-query';
import { createOrSelectGeneralChatChannelInStore } from '@/domains/Chat/actions/chatSelectedActions';
import { changeRoute } from '@/actions/routerActions';
import { reorder } from '../../../actions/orderActions';

export interface Props {
    row?: Order,
    openAddBillsModal?: () => void,
    openGenerateBillsModal?: () => void,
}

export const OrderRowMoreButton: React.FC<Props> = ({ row, openAddBillsModal, openGenerateBillsModal }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const [moreToolTip, setMoreToolTip] = useState<boolean>(false);
    const dispatch = useDispatch();

    const { data: orderInvoices } = useQuery(
        ['ORDER_INVOICES', row?.urlsafe],
        () => listInvoices(1, row?.buyer?.urlsafe, { search: [row?.id.toString(10)] }),
        {
            refetchOnWindowFocus: false,
        }
    );

    const invoice = orderInvoices?.results[0];
    const hasImages = row?.images?.length > 0;
    const hasAttachments = !!invoice || hasImages;

    const onClickChat = (): void => {
        dispatch(
            createOrSelectGeneralChatChannelInStore({
                customerUrlsafe: row.customerUrlsafe,
                vendorUrlsafe: row.vendorUrlsafe,
                vendorName: row.vendorName,
            })
        );
        dispatch(changeRoute('/inbox/'));
    };
    return (
        <div>
            <Tooltip
                show={moreToolTip}
                className="flex"
                tooltipClassName={'py-1 px-2 rounded-lg'}
                showArrow={false}
                onShow ={(): void => { setMoreToolTip(true); }}
                onHide ={(): void => { setMoreToolTip(false); }}
                placement="top"
                trigger={<Popover
                    className="w-44"
                    button=
                        {<Button
                            variant="TERTIARY_OUTLINED"
                            className="bg-white border-0 group-hover:border flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                            size="SMALL"
                            stopPropagation={false}>
                            <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                <EllipsisIcon className="mt-[1px] lg:mt-0 h-4 w-4" />
                            </div>
                        </Button>}
                    items={[
                        {
                            label: t('viewOrder'),
                            onClick: (): void => {
                                browserHistory.push(`/order_detail/${row.urlsafe}`);
                            },
                        },
                        {
                            label: t('reorder'),
                            separatorEnabled: true,
                            onClick: (): void => {
                                dispatch(reorder(row));
                                setMoreToolTip(false);
                            },
                        },
                        {
                            label: t('attachBill'),
                            onClick: (): void => {
                                openAddBillsModal();
                                setMoreToolTip(false);
                            },
                            separatorEnabled: hasAttachments
                        },
                        {
                            label: t('generateBill'),
                            hidden: hasAttachments,
                            separatorEnabled: true,
                            onClick: (): void => {
                                openGenerateBillsModal();
                                setMoreToolTip(false);
                            }
                        },
                        {
                            label: t('messageSupplier'),
                            onClick: (): void => {
                                onClickChat();
                            },
                        },
                    ]}/>}>
                {t('more')}
            </Tooltip>
        </div>
    );
};

import { useState, useEffect } from 'react';
import { getPdfFromUrl } from '@v2/utils/PDF';

type PdfData = {
    pdfData: string,
    pdfBytesArray: Uint8Array,
    isLoading: boolean,
};

export function usePdfData(url:string):PdfData {
    const [pdfData, setPdfData] = useState('');
    const [pdfBytesArray, setPdfBytesArray] = useState<Uint8Array>(new Uint8Array(0));
    const [isLoading, setIsLoading] = useState(true);
    // validate if url is valid and if it is a pdf
    const isValidUrl = url && url.length > 0 && url.toLocaleLowerCase().includes('.pdf');
    useEffect(() => {
        if (isValidUrl) {
            setIsLoading(true);
            getPdfFromUrl(url)
                .then(({
                    pdfBytes,
                    pdfUrlObject
                }) => {
                    setPdfData(`${pdfUrlObject}`);
                    setPdfBytesArray(pdfBytes);
                    setIsLoading(false);
                });
        }
    }, [url]);

    return {
        pdfData,
        pdfBytesArray,
        isLoading
    };
}

import { QueryKey, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { FilterDateTypeValue } from '@v2/components/Bills/BillsFilters/BillsFiltersConstants';
import { EInvoiceStatus, EStripeStatus } from '@v2/constants/EInvoiceStatus';
import { getResourceSyncDetails, SyncStatus } from '@v2/network/BushwhackAPI';
import { InvoiceOrderSyncDetails } from '@v2/network/CoreAPI';
import { InvoicesResponse, searchInvoices } from '@v2/network/GreevilsGreedApi';

export const FETCH_INVOICES_QUERY_KEY = 'FETCH_INVOICES';
export const FETCH_INVOICES_PARALLEL_QUERY_KEY = 'FETCH_INVOICES_PARALLEL_QUERY_KEY';

export type BillsFilterDateRange = {
    startDate: string,
    endDate: string,
    type?: FilterDateTypeValue,
};
export type TBillsSortBy = 'order_number' | 'order_date' | 'vendor_name' | 'amount_due' | 'due_date';
export type TBillsSortOrder = 'asc' | 'desc';

export type TBillsFilters = {
    search?: string[],
    supplierUrlsafeKey?: string,
    orderUrlsafeKey?: string,
    paymentStatus?: EStripeStatus & EInvoiceStatus | undefined,
    dueDate?: BillsFilterDateRange,
    issuedDate?: BillsFilterDateRange,
    sortBy?: TBillsSortBy,
    sortOrder?: TBillsSortOrder,
};

export type TBillsPagination = {
    page: number,
    nextCursor?: string,
    limit: number,
};

export type TUseBillsQuery = {
    buyerUrlsafeKey: string,
    filters: TBillsFilters,
    transformResponse?: boolean,
    enabled?: boolean,

};

const getInvoiceSyncDetails = (syncDetails: SyncStatus[], syncIds: string[]): InvoiceOrderSyncDetails => {
    const statuses = syncDetails.filter((status) => syncIds.indexOf(status.resourceIdentifier) > -1);
    const firstError: SyncStatus = statuses.find((status) => status.error);
    const hasStatus = statuses?.length > 0;
    const lastSyncTime = hasStatus ? statuses[0]?.lastSyncTime : null;
    const isTwoWayMatched = lastSyncTime && !firstError;
    return {
        isSyncInProgress: false,
        error: firstError?.error,
        lastSyncTime: statuses[0]?.lastSyncTime,
        isTwoWayMatched,
    };
};

const addSyncDetailsToInvoicesResponse = (invoicesResponse: InvoicesResponse, orderUrlsafeKey: string, orderSyncDetails: InvoiceOrderSyncDetails):void => {
    invoicesResponse.results.forEach((invoice) => {
        if (invoice.orderUrlsafeKey === orderUrlsafeKey) {
            invoice.orderSyncDetails = orderSyncDetails;
        }
    });
};

/**
 * TODO: Restore this functionality?
 * This function was moved from the orderInvoicesReducer.js, the purpose of this function is to transform the invoices response to a format that is easier to work with.
 * Adding the response the order sync details object for each online invoice requesting the order api
 *
 * @param invoicesResponse
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const transformInvoicesResponseToOrderSync = async (buyerUrlsafeKey: string, invoicesResponse: InvoicesResponse): Promise<InvoicesResponse> => {
    const { results } = invoicesResponse;
    const invoiceIDs = results.map((invoice) => invoice.id);
    const syncDetails = await getResourceSyncDetails(buyerUrlsafeKey, [...invoiceIDs].filter(Boolean));

    results.forEach((invoice) => {
        const invoiceSyncDetails = getInvoiceSyncDetails(syncDetails, [invoice.id]);
        addSyncDetailsToInvoicesResponse(invoicesResponse, invoice.orderUrlsafeKey, { ...invoiceSyncDetails });
    });
    return invoicesResponse;
};

export type UseGetBillsQuery = {
    buyerUrlsafeKey: string,
    filters?: TBillsFilters,
    pagination?: TBillsPagination,
    options?: UseQueryOptions<InvoicesResponse, unknown, InvoicesResponse, QueryKey>,
};

export const useGetBillsQuery = ({
    buyerUrlsafeKey,
    pagination,
    filters,
    options
}:UseGetBillsQuery): UseQueryResult<InvoicesResponse> => useQuery<InvoicesResponse, unknown>([FETCH_INVOICES_QUERY_KEY, buyerUrlsafeKey, filters, pagination], async (): Promise<InvoicesResponse> => {
    const invoiceList = await searchInvoices(buyerUrlsafeKey, filters, pagination);
    return transformInvoicesResponseToOrderSync(buyerUrlsafeKey, invoiceList);
}, options);

import React from 'react';
import { Separator, Toggle, Typography } from '@notch-ordering/ui-components';
import { tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { ScanChartOfAccountTotalsItem } from './ScanChartOfAccountTotalsItem';

type Props = {
    subtotal: number,
    tax: number,
    total: number,
    allowAutoCalculate: boolean,
    isAutoCalculateOn: boolean,
    onToggleAutoCalculate: () => void,
};

export const ScanChartOfAccountTotals : React.FC<Props> = ({ subtotal, tax, total, allowAutoCalculate, isAutoCalculateOn, onToggleAutoCalculate }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.ChartOfAccountDetails' });

    return <div className="sticky bottom-0 bg-white">
        <Separator variant="small"/>
        <div className="px-8 py-7">
            <div className="flex flex-row justify-between pb-3">
                <Typography as="div" className="font-semibold">{t('chartOfAccountTotals')}</Typography>
                <div hidden={!allowAutoCalculate} className="flex flex-row gap-2">
                    <Typography as="div" className="font-medium">{t('autoCalculate')}</Typography>
                    <Toggle isEnabled={isAutoCalculateOn} onChange={onToggleAutoCalculate}/>
                </div>
            </div>
            <div className="flex flex-row gap-6">
                <ScanChartOfAccountTotalsItem label={t('subtotal')} value={subtotal} />
                <ScanChartOfAccountTotalsItem label={t('tax')} value={tax} />
                <ScanChartOfAccountTotalsItem label={t('total')} value={total} isTotal />
            </div>
        </div>
    </div>;
};

import { EApPlatform, AccountingInfo, formatDate } from '@notch-ordering/shared-logic';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';

export function isPaidExternally(bill: InvoiceData, integrationType: EApPlatform = EApPlatform.QBO): boolean {
    if (!bill) {
        return false;
    }

    if (bill.isTwoWayAccounting && bill.lastEditedDateByAccounting && bill.amountPaid > 0) {
        if (bill.payments?.some((p) => p.source.toLowerCase() === integrationType.toLowerCase())) {
            return true;
        }
    }

    return false;
}

export function isEditedExternally(bill: InvoiceData): boolean {
    if (!bill) {
        return false;
    }

    if (bill.isTwoWayAccounting && bill.lastEditedDateByAccounting) {
        return true;
    }

    return false;
}

export function isInvoiceInClosedPeriod(invoice: InvoiceData, accountingInfo: AccountingInfo): boolean {
    const issueDate = formatDate(invoice.issuedDate, 'yyyy-MM-dd');
    return !accountingInfo || (accountingInfo.bookCloseDate && new Date(issueDate) <= new Date(accountingInfo.bookCloseDate));
}

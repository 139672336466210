import React, { useMemo, useState } from 'react';
import { debounce, isEqual } from 'lodash';
import { SearchInput } from '@v2/components/Shared/SearchInput/SearchInput';
import { Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import { useLegacySuppliers } from '@v2/hooks/useLegacySuppliers.hook';
import CheckIcon from '@icons/check-icon.svg';
import { FilterButton } from '@v2/components/Shared/FiltersButton/FilterButton';
import { ORDERS_FILTERS_INITIAL_STATE, useOrdersStore } from '@v2/stores/OrdersStore';
import { BillsFilterBadge } from '@v2/components/Bills/BillsFilters/BillsFilterBadge';
import { OrdersDateFilters,
    getStartEndDate,
    OrdersSortingOptions,
    DEFAULT_ORDERS_FILTERS,
    formatDateRange } from './OrdersFiltersConstants';
import DateFilter from '@/deprecated/fe-shared-components/dateFilter';

const DEBOUNCE_SEARCH_QUERY_DELAY = 100;

export type OrdersFiltersProps = {
    onChangeFilters?: () => void,
};

export const OrdersFilters : React.FC<OrdersFiltersProps> = ({ onChangeFilters }) => {
    const { filters, updateFilters, resetFilterByType } = useOrdersStore();
    const [searchQuery, setSearchQuery] = useState<string>(filters.id);
    const [isCustomDateRangeOpen, setIsCustomDateRangeOpen] = useState(false);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const { legacySuppliers = [] } = useLegacySuppliers();
    const areFiltersApplied = useMemo(() => !isEqual(filters, ORDERS_FILTERS_INITIAL_STATE), [filters]);

    const filteredVendorsOptions = useMemo<PopoverItem[]>(() => legacySuppliers.sort((a, b) => a?.name?.localeCompare(b?.name)).map((vendor) => ({
        label: <Typography className="mb-0 flex gap-2 relative items-center"><span>{vendor.name}</span> {filters.vendorID === vendor.id
            && <div className="w-4 ml-auto"><CheckIcon className="w-4 h-4 ml-auto"/></div>}</Typography>,
        onClick: ():void => {
            updateFilters({ vendorID: vendor.id });
            if (onChangeFilters) {
                onChangeFilters();
            }
        }
    })), [filters.vendorID, legacySuppliers]);

    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        updateFilters({ id: query });
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);

    const handleQueryChange = (query: string = ''):void => {
        setSearchQuery(query);
        debounceSearchQuery(query);
        if (onChangeFilters) {
            onChangeFilters();
        }
    };

    const formatDateFilter = (dateFilter: string):string => {
        if (dateFilter === 'this_month') {
            return t('thisMonth');
        }
        if (dateFilter === 'last_month') {
            return t('lastMonth');
        }
        if (dateFilter === 'last_three_months') {
            return t('lastThreeMonths');
        }
        if (dateFilter === 'last_six_months') {
            return t('lastSixMonths');
        }
        return null;
    };

    return <div className="w-full flex gap-2 flex-wrap">
        <SearchInput onChange={handleQueryChange}
            value={searchQuery}
            inputProps={{
                placeholder: t('searchByOrderNumber'),
            }
            } />
        <FilterButton label={t('supplier')} items={filteredVendorsOptions} className="h-80 overflow-auto"/>
        <FilterButton
            label={OrdersDateFilters.label}
            items={OrdersDateFilters.items.map(({ value, label }) => {
                const isSelectedItem = filters.dateFilter === value;
                return ({
                    label:
                       <Typography className="mb-0 flex gap-2 relative items-center">
                           <span>{label}</span>
                           { isSelectedItem && <div className="w-4 ml-auto">
                               <CheckIcon className="w-4 h-4 ml-auto"/></div>}
                       </Typography>,
                    onClick: (): void => {
                        if (value === DEFAULT_ORDERS_FILTERS.DATE.CUSTOM) {
                            setIsCustomDateRangeOpen(true);
                        } else {
                            const { startDate, endDate } = getStartEndDate(value);
                            updateFilters({
                                dateFilter: value,
                                deliveryDayStart: startDate,
                                deliveryDayEnd: endDate
                            });
                        }
                    }
                });
            })}/>
        <FilterButton label={t('sortBy')}
            items={OrdersSortingOptions.map((option) => ({
                label: <div className="flex flex-row justify-between items-center">
                    <span>{option.label}</span>
                    {(filters.sortBy?.value === option.value) && <CheckIcon className="h-4 w-4"/>}
                </div>,
                onClick: (): void => {
                    updateFilters({
                        sortBy: {
                            value: option.value,
                            label: option.label
                        }
                    });
                },
            }))}
            className="overflow-auto w-56" />
        {areFiltersApplied
            && <div className="w-full flex gap-2 flex-wrap">
                {filters.vendorID && <BillsFilterBadge
                    onClickClear={resetFilterByType}
                    filterType={'vendorID'}
                    label={ t('supplierIs')}>
                    {legacySuppliers.find((vendor) => vendor.id === filters.vendorID)?.name}
                </BillsFilterBadge>}
                {filters.sortBy && <BillsFilterBadge
                    onClickClear={resetFilterByType}
                    filterType={'sortBy'}
                    label={ t('sortingBy')}>
                    {filters.sortBy?.label}
                </BillsFilterBadge>}
                {filters.deliveryDayStart && <BillsFilterBadge
                    onClickClear= {(): void => {
                        resetFilterByType('deliveryDayStart');
                        updateFilters({
                            dateFilter: undefined,
                            deliveryDayStart: undefined,
                            deliveryDayEnd: undefined
                        });
                    }}
                    filterType={'deliveryDayStart'}
                    label={ t('deliveryDayIs')}>
                    {filters.dateFilter !== DEFAULT_ORDERS_FILTERS.DATE.CUSTOM ? formatDateFilter(filters.dateFilter) : formatDateRange({ startDate: filters.deliveryDayStart, endDate: filters.deliveryDayEnd })}
                </BillsFilterBadge>}
            </div>
        }
        <DateFilter
            isOpen={isCustomDateRangeOpen}
            onHide={():void => setIsCustomDateRangeOpen(false)}
            updateDates={(startDate, endDate): void => {
                updateFilters({
                    deliveryDayStart: startDate.format('YYYY-MM-DD'),
                    deliveryDayEnd: endDate.format('YYYY-MM-DD'),
                    dateFilter: DEFAULT_ORDERS_FILTERS.DATE.CUSTOM
                });
            }}
            headerText= {t('selectDeliveryDate')}/>
    </div>;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import LoadingData from '../LoadingData.jsx';

import ChangeUnitHeader from './ChangeUnitHeader.jsx';
import ChangeUnitBody from './ChangeUnitBody.jsx';
import ChangeUnitFooter from './ChangeUnitFooter.jsx';

import ChangeUnitConfirmClose from './ChangeUnitConfirmClose.jsx';

export default class ChangeUnitMain extends Component {
  render() {
    const { isChangeUnitLoading, isModalConfirmPromptShowing } = this.props;

    return (
      <div className="change-unit-modal">
        <div className="change-unit-box">
          {/* Show Loader when loading variable is true */}
          {/* Else, show The Main View for Changing Units */}

          {(() => {
            if (isChangeUnitLoading) {
              return (
                <div>
                  <LoadingData display={isChangeUnitLoading} text="Loading" />
                </div>
              );
            } else {
              if (isModalConfirmPromptShowing) {
                return <ChangeUnitConfirmClose {...this.props} />;
              } else {
                return (
                  <div>
                    <ChangeUnitHeader {...this.props} />
                    <ChangeUnitBody {...this.props} />
                    <ChangeUnitFooter {...this.props} />
                  </div>
                );
              }
            }
          })()}
        </div>
      </div>
    );
  }
}

ChangeUnitMain.propTypes = {
  isChangeUnitLoading: PropTypes.bool.isRequired,
  isModalConfirmPromptShowing: PropTypes.bool.isRequired,
};

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import useOrder from "../hooks/useOrder";
import utils from "../../../utils";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";

const ReceivingToolPrice = ({
  priceEdited,
  newSubTotal,
  isSubtotalUpdated,
  autoScroll,
}) => {
  const {
    order: { data },
    vendor,
  } = useOrder();
  const [showPrice, updatePrice] = useState(false);
  const togglePrice = () => updatePrice((state) => !state);

  const priceEndRef = useRef(null);
  const scrollToBottom = () => {
    if (priceEndRef.current) {
      priceEndRef.current.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    if (showPrice && autoScroll) {
      scrollToBottom();
    }
  }, [showPrice, autoScroll]);

  if (priceEdited) {
    return (
      <div className="od-price-container">
        <div className="subtotal-row">
          <div className="header-text">Estimated subtotal</div>
          <div
            className={cx("header-text", {
              "edited-bg": priceEdited,
            })}
          >
            ${utils.roundOff2(newSubTotal)}
          </div>
        </div>
        <div className="subtotal-row">
          <div className="body-text">Not including taxes, fees, and credit</div>
        </div>
      </div>
    );
  }

  return (
    <div className="od-price-container">
      <button onClick={togglePrice}>
        <img
          src={`${BUCKET_ASSETS_URL}caret_down_new_icon.svg`}
          alt="caret-icon"
          className={cx({
            rotated: !showPrice,
          })}
        />
        <div className="data">
          <div className="header-text">Estimated Total</div>
          <div
            className={cx("header-text", {
              "edited-bg": isSubtotalUpdated,
            })}
          >
            ${utils.roundOff2(data.total)}
          </div>
        </div>
      </button>
      <div className="price-row">
        <div className="body-text">Including taxes, fees, and credit</div>
      </div>
      {showPrice && (
        <>
          <div className="price-row top-margin">
            <div className="body-text">Items</div>
            <div
              className={cx("body-text", {
                "edited-text": isSubtotalUpdated,
                "edited-bg": isSubtotalUpdated,
              })}
            >
              ${utils.roundOff2(data.subtotal)}
            </div>
          </div>

          <div className="price-row top-margin bottom-margin-30">
            <div className="body-text">Delivery Fee</div>
            <div className="body-text">
              {vendor && vendor.customer && (
                <>${utils.roundOff2(vendor.customer.deliveryFee)}</>
              )}
            </div>
          </div>

          <div className="price-row top-margin">
            <div className="body-text">Subtotal</div>
            <div
              className={cx("body-text", {
                "edited-text": isSubtotalUpdated,
                "edited-bg": isSubtotalUpdated,
              })}
            >
              ${utils.roundOff2(data.subtotal)}
            </div>
          </div>
          <div
            className={cx("price-row top-margin", {
              "bottom-margin-10": !data.creditTotalAmount,
            })}
          >
            <div className="body-text">Taxes</div>
            <div className="body-text">${utils.roundOff2(data.tax)}</div>
          </div>
          {!!data.creditTotalAmount && (
            <div className="price-row top-margin bottom-margin-10">
              <div className="body-text gray">Credit</div>
              <div className="body-text gary">
                (${utils.roundOff2(data.creditTotalAmount)})
              </div>
            </div>
          )}
        </>
      )}
      <div ref={priceEndRef} />
    </div>
  );
};

ReceivingToolPrice.propTypes = {
  isSubtotalUpdated: PropTypes.bool.isRequired,
  priceEdited: PropTypes.bool.isRequired,
  newSubTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  autoScroll: PropTypes.bool.isRequired,
};

export default ReceivingToolPrice;

// tracking constant events

export const TrackingEventCategory = {
    bills: 'Bills',
    ordering: 'Ordering',
    catalogManagement: 'Catalog Management',
    integrations: 'Integrations',
    navigation: 'Navigation',
    ocr: 'OCR',
    onboarding: 'Onboarding',
    payments: 'Payments',
    staff: 'Staff',
} as const;

export enum TrackingLabel {
    OCR = 'OCR',
    UPLOADS = 'Uploads'
}

export type TrackingEvent = {
    category: typeof TrackingEventCategory[keyof typeof TrackingEventCategory],
    action: string,
    label?: string,
    value?: number,
};

export const TrackingEventActions = {
    // OCR
    accountingSyncButtonClicked: 'accounting_sync_button_clicked',
    autoCalculateClickedOff: 'auto_calculate_clicked_off',
    autoCalculateClickedOn: 'auto_calculate_clicked_on',
    finalSyncButtonClicked: 'final_sync_button_clicked',
    glCodeDropdownSelected: 'gl_code_dropdown_selected',
    imsSyncButtonClicked: 'ims_sync_button_clicked',
    moreActionsSelected: 'more_actions_selected',
    newLineItemClicked: 'new_line_item_clicked',
    reviewBillClicked: 'review_bill_clicked',
    searchFocus: 'search_focus',
    supplierFilterNameSelected: 'supplier_filter_name_selected',
    syncNextBillClicked: 'sync_next_bill_clicked',
    syncStatusFilterSelected: 'sync_status_filter_selected',
    taxCodeDropdownSelected: 'tax_code_dropdown_selected',
    taxFieldFocus: 'tax_field_focus',
    totalFieldFocus: 'total_field_focus',
    // Bills
    addBillClicked: 'add_bill_clicked',
    addButtonClicked: 'add_button_clicked',
    addPaymentClicked: 'add_payment_clicked',
    addProductsClicked: 'add_products_clicked',
    addSupplierClicked: 'add_supplier_clicked',
    addToCart: 'add_to_cart',
    addToSavedProducts: 'add_to_saved_products',
    allSavedProductsClicked: 'all_saved_products_clicked',
    billApprovedClicked: 'bill_approved_clicked',
    billRequestApprovalClicked: 'bill_request_approval_clicked',
    billSubmitUploadClicked: 'bill_submit_upload_clicked',
    catalogBulkUploadConfirm: 'catalog_bulk_upload_confirm',
    catalogFileUploadContinue: 'catalog_file_upload_continue',
    catalogMatchingFieldContinue: 'catalog_matching_field_continue',
    chatButtonClicked: 'chat_button_clicked',
    checkoutStarted: 'checkout_started',
    checkoutStep1: 'checkout_step_1',
    checkoutStep2: 'checkout_step_2',
    createProductClicked: 'create_product_clicked',
    createStaffClicked: 'create_staff_clicked',
    createSupplierClicked: 'create_supplier_clicked',
    customOrderGuideClicked: 'custom_order_guide_clicked',
    emptyHomeAddSupplierClicked: 'empty_home_add_supplier_clicked',
    enterProductSearchBar: 'enter_product_search_bar',
    exportCatalogClicked: 'export_catalog_clicked',
    integrationsClicked: 'integrations_clicked',
    navigationMenuClicked: 'navigation_menu_clicked',
    newItemClicked: 'new_item_clicked',
    newStaffClicked: 'new_staff_clicked',
    onboardingBillsClicked: 'onboarding_bills_clicked',
    onboardingCatalogClicked: 'onboarding_catalog_clicked',
    onboardingStepExpandClicked: 'onboarding_step_expand_clicked',
    onboardingIntegrationsClicked: 'onboarding_integrations_clicked',
    onboardingShopClicked: 'onboarding_shop_clicked',
    onboardingSkipStepClicked: 'onboarding_skip_step_clicked',
    onboardingStaffClicked: 'onboarding_staff_clicked',
    onboardingSuppliersClicked: 'onboarding_suppliers_clicked',
    openCreateNewSupplierClicked: 'open_create_new_supplier_clicked',
    orderConfirmation: 'order_confirmation',
    placeOrderClicked: 'place_order_clicked',
    productQuantityDecreased: 'product_quantity_decreased',
    productQuantityIncreased: 'product_quantity_increased',
    refreshSyncClicked: 'refresh_sync_clicked',
    removeFromSavedProducts: 'remove_from_saved_products',
    setUpCatalogClicked: 'set_up_catalog_clicked',
    setupSuppliersClicked: 'setup_suppliers_clicked',
    shopFilterClicked: 'shop_filter_clicked',
    shopSupplierClicked: 'shop_supplier_clicked',
    supplierCardClicked: 'supplier_card_clicked',
    uploadCatalogClicked: 'upload_catalog_clicked',
    venueSelected: 'venue_selected',
    venueSwitcherClicked: 'venue_switcher_clicked',
    viewCart: 'view_cart',
    viewFullCatalogClicked: 'view_full_catalog_clicked',
} as const;

export const TrackingEvents: Record<keyof typeof TrackingEventActions, TrackingEvent> = {
    // OCR
    accountingSyncButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.accountingSyncButtonClicked,
    },
    autoCalculateClickedOff: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.autoCalculateClickedOff,
    },
    autoCalculateClickedOn: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.autoCalculateClickedOn,
    },
    finalSyncButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.finalSyncButtonClicked,
    },
    glCodeDropdownSelected: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.glCodeDropdownSelected,
    },
    imsSyncButtonClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.imsSyncButtonClicked,
    },
    moreActionsSelected: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.moreActionsSelected,
    },
    newLineItemClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.newLineItemClicked,
    },
    reviewBillClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.reviewBillClicked,
    },
    searchFocus: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.searchFocus,
    },
    supplierFilterNameSelected: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.supplierFilterNameSelected,
    },
    syncNextBillClicked: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.syncNextBillClicked,
    },
    syncStatusFilterSelected: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.syncStatusFilterSelected,
    },
    taxCodeDropdownSelected: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.taxCodeDropdownSelected,
    },
    taxFieldFocus: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.taxFieldFocus,
    },
    totalFieldFocus: {
        category: TrackingEventCategory.ocr,
        action: TrackingEventActions.totalFieldFocus,
    },
    // Bills
    addBillClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.addBillClicked,
    },
    refreshSyncClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.refreshSyncClicked,
    },
    addButtonClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addButtonClicked,
    },
    addPaymentClicked: {
        category: TrackingEventCategory.payments,
        action: TrackingEventActions.addPaymentClicked
    },
    addProductsClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.addProductsClicked,
    },
    addSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addSupplierClicked,
    },
    addToCart: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addToCart,
    },
    addToSavedProducts: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.addToSavedProducts,
    },
    allSavedProductsClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.allSavedProductsClicked,
    },
    billApprovedClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.billApprovedClicked,
    },
    billRequestApprovalClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.billRequestApprovalClicked,
    },
    billSubmitUploadClicked: {
        category: TrackingEventCategory.bills,
        action: TrackingEventActions.billSubmitUploadClicked,
    },
    catalogBulkUploadConfirm: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.catalogBulkUploadConfirm,
    },
    catalogFileUploadContinue: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.catalogFileUploadContinue,
    },
    catalogMatchingFieldContinue: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.catalogMatchingFieldContinue,
    },
    chatButtonClicked: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.chatButtonClicked,
    },
    checkoutStarted: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.checkoutStarted,
    },
    checkoutStep1: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.checkoutStep1,
    },
    checkoutStep2: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.checkoutStep2,
    },
    createProductClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.createProductClicked
    },
    createStaffClicked: {
        category: TrackingEventCategory.staff,
        action: TrackingEventActions.createStaffClicked
    },
    createSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.createSupplierClicked
    },
    customOrderGuideClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.customOrderGuideClicked,
    },
    emptyHomeAddSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.emptyHomeAddSupplierClicked
    },
    enterProductSearchBar: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.enterProductSearchBar,
    },
    exportCatalogClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.exportCatalogClicked,
    },
    integrationsClicked: {
        category: TrackingEventCategory.integrations,
        action: TrackingEventActions.integrationsClicked
    },
    navigationMenuClicked: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.navigationMenuClicked,
    },
    newItemClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.newItemClicked
    },
    newStaffClicked: {
        category: TrackingEventCategory.staff,
        action: TrackingEventActions.newStaffClicked
    },
    onboardingBillsClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.addBillClicked
    },
    onboardingCatalogClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingCatalogClicked
    },
    onboardingIntegrationsClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingIntegrationsClicked
    },
    onboardingShopClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingShopClicked
    },
    onboardingSkipStepClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingSkipStepClicked
    },
    onboardingStaffClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingStaffClicked
    },
    onboardingStepExpandClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingStepExpandClicked
    },
    onboardingSuppliersClicked: {
        category: TrackingEventCategory.onboarding,
        action: TrackingEventActions.onboardingSuppliersClicked
    },
    openCreateNewSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.openCreateNewSupplierClicked
    },
    orderConfirmation: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.orderConfirmation,
    },
    placeOrderClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.placeOrderClicked,
    },
    productQuantityDecreased: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.productQuantityDecreased,
    },
    productQuantityIncreased: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.productQuantityIncreased,
    },
    removeFromSavedProducts: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.removeFromSavedProducts,
    },
    setUpCatalogClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.setUpCatalogClicked,
    },
    setupSuppliersClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.setupSuppliersClicked
    },
    shopFilterClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.shopFilterClicked,
    },
    shopSupplierClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.shopSupplierClicked,
    },
    supplierCardClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.supplierCardClicked
    },
    uploadCatalogClicked: {
        category: TrackingEventCategory.catalogManagement,
        action: TrackingEventActions.uploadCatalogClicked,
    },
    venueSelected: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.venueSelected,
    },
    venueSwitcherClicked: {
        category: TrackingEventCategory.navigation,
        action: TrackingEventActions.venueSwitcherClicked,
    },
    viewCart: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.viewCart,
    },
    viewFullCatalogClicked: {
        category: TrackingEventCategory.ordering,
        action: TrackingEventActions.viewFullCatalogClicked,
    }
};

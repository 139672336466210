import QboIcon from '@icons/qbo-icon-synced.svg';
import RefreshIcon from '@icons/refresh-icon.svg';
import { Button, toast } from '@notch-ordering/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FETCH_INVOICES_QUERY_KEY } from '@v2/Pages/Invoices/BillsQueries.hook';
import { EBillSyncStatus } from '@v2/Pages/Invoices/InvoicesConstants';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { tCommon } from '@v2/i18n';
import { InvoiceData, reSyncOfflineInvoice } from '@v2/network/GreevilsGreedApi';
import { useBillsStore } from '@v2/stores/BillsStore';
import { logError } from '@v2/utils/logError';
import cx from 'classnames';
import React, { useContext, useState } from 'react';
import { InvoicesPageContext } from '@v2/Pages/Invoices/InvoicesPageContext';
import { isInvoiceInClosedPeriod } from '@v2/utils/AccountingUtils';

export interface AccountingSyncStatusProps {
    status: EBillSyncStatus,
    allowResync?: boolean,
    onSyncClick?: () => void,
    invoice: InvoiceData,
}

const getIcon = (status: EBillSyncStatus):React.ReactNode => {
    const defaultClassName = 'w-8 h-8';
    let className = '';
    switch (status) {
        case EBillSyncStatus.Synced:
            className = 'text-green-300';
            break;
        case EBillSyncStatus.Syncing:
            className = 'text-teal-300';
            break;
        case EBillSyncStatus.SyncError:
            className = 'text-red-300';
            break;
        case EBillSyncStatus.PendingApproval:
            className = 'text-orange-300';
            break;
        default:
            return null;
    }
    return <span title={status}><QboIcon className={cx(defaultClassName, className)}/></span>;
};

export const AccountingSyncStatus : React.FC<AccountingSyncStatusProps> = ({ allowResync, status, invoice }) => {
    const reSyncInvoiceMutation = useMutation(reSyncOfflineInvoice);
    const [isSyncing, setIsSyncing] = useState(false);
    const { buyer } = useBuyerHook();
    const { filters } = useBillsStore();
    const queryClient = useQueryClient();
    const { accountingInfo } = useContext(InvoicesPageContext);

    // if status is not uploaded we show a blank status in the ui
    if (status === EBillSyncStatus.NotUploaded) {
        return null;
    }

    // we must not sync invoices in closed accounting periods
    const invoiceInClosedPeriod = isInvoiceInClosedPeriod(invoice, accountingInfo);

    const showSyncButton = allowResync
        && (status === EBillSyncStatus.SyncError || status === EBillSyncStatus.NotSynced || status === EBillSyncStatus.PendingApproval)
        && !invoiceInClosedPeriod;

    const queryKey = [FETCH_INVOICES_QUERY_KEY, buyer.urlsafe, filters, true];
    // this will refetch the existing react query data to load the latest data
    const refetchBills = async ():Promise<void> => queryClient.invalidateQueries(queryKey);
    const showToast = (hasError:boolean):void => {
        const toastMessage = hasError ? tCommon('somethingWentWrong') : tCommon('Toasts.success.sync');
        toast.show({
            message: toastMessage,
            showClose: false
        });
    };
    const onSyncClick = async ():Promise<void> => {
        try {
            setIsSyncing(true);
            reSyncInvoiceMutation.mutate({
                buyerUrlsafeKey: buyer?.urlsafe,
                invoiceID: invoice.id
            }, {
                onSuccess: async () => {
                    showToast(false);
                    refetchBills();
                    setIsSyncing(false);
                },
                onError: () => {
                    showToast(true);
                    setIsSyncing(false);
                }
            });
        } catch (e) {
            setIsSyncing(false);
            showToast(true);
            logError('Error while syncing invoice', e, false);
        }
    };

    return <div className="flex gap-2 justify-center items-center">
        {getIcon(status)}
        {showSyncButton && <Button variant="TERTIARY_FILLED" size="ICON_MEDIUM" className="p-1.5 rounded-lg flex justify-center items-center" onClick={onSyncClick}>
            <RefreshIcon className={cx('w-4 h-4', { 'animate-spin': isSyncing })}/>
        </Button>}

    </div>;
};

import React from 'react';
import SwitcherIcon from '@icons/switcher-icon.svg';
import { Button, Popover, Typography } from '@notch-ordering/ui-components';
import WarningIcon from '@icons/warning-icon.svg';
import LowConfidenceScanIcon from '@icons/low-confidence-scan-icon.svg';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

import CheckIcon from '@icons/check-icon.svg';
import { LineItemCategory, LineItemFocusType, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategory } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';

export interface ScanLineItemsFocusDropdownProps {
    onChooseFocusType?: (type: LineItemFocusType) => void,
    totalMissingRequiredFields?: number,
    currentMissingRequiredField?: number,
    totalLowConfidenceFields?: number,
    currentLowConfidenceField?: number,
    focusType?: LineItemFocusType,

}

export const ScanLineItemsFocusDropdown : React.FC<ScanLineItemsFocusDropdownProps> = ({
    currentMissingRequiredField = 1,
    totalMissingRequiredFields = 0,
    totalLowConfidenceFields = 0,
    currentLowConfidenceField = 1,
    onChooseFocusType,
}) => {
    const { focusType, setFocusType } = useOcrInvoiceStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.LineItems' });

    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const { draftInvoice } = useOcrInvoiceStore();
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategory: LineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, false, hasImsIntegration);

    const handleChooseFocusType = (typeSelected: LineItemFocusType): void => {
        setFocusType(typeSelected, lineItemCategory);
        onChooseFocusType?.(typeSelected);
    };

    const getLabel = ():React.ReactNode => {
        switch (focusType) {
            case LineItemFocusType.Required:
                if (totalMissingRequiredFields > 0) {
                    return <>{currentMissingRequiredField} <span className="text-gray-600">{t('ofRequiredFields', { count: totalMissingRequiredFields })}</span></>;
                }
                return null;

            case LineItemFocusType.LowConfidence:
                if (totalLowConfidenceFields > 0) {
                    return <>{currentLowConfidenceField} <span className="text-gray-600">{t('ofLowConfidenceFields', { count: totalLowConfidenceFields })}</span></>;
                }
                return null;
            default:
                return null;
        }
    };

    return <div className="flex gap-5 items-center">
        {focusType === LineItemFocusType.Required ? <WarningIcon className="h-4 w-4 text-orange-300 "/> : <LowConfidenceScanIcon className="h-4 w-4"/>}
        <div className="relative flex flex-col gap-0.5">
            <Typography variant="SM" desktopSize="lg:text-1 m-0" className="text-gray-600">
                {t('focusedOn')}
            </Typography>
            <Popover
                className="min-w-[245px]"
                placement="bottom-start"
                header={<Typography variant="SM" desktopSize="lg:text-1" className="text-gray-600 m-0" weight="font-medium">{t('focusView')}</Typography>}
                headerEnabled
                stopPropagation={false}
                button={
                    <div
                        className="relative w-full cursor-pointer flex items-center gap-1">
                        <Typography className="block truncate m-0 text-gray-700" weight="font-medium">
                            {getLabel()}
                        </Typography>
                        <Button variant="ICON" size="ICON_SMALL" className="p-0 h-5 w-5 flex items-center" stopPropagation={false}>
                            <SwitcherIcon
                                className="h-4 w-4 text-gray-600"
                                aria-hidden="true"/>
                        </Button>
                    </div>
                }
                items={[
                    {
                        label: <div className="flex items-center gap-3">
                            <WarningIcon className="h-4 w-4 text-orange-400"/>
                            <Typography variant="BASE" className="m-0 block truncate" desktopSize="lg:text-2">{t('requiredFields')}</Typography>
                            {focusType === LineItemFocusType.Required && <CheckIcon className="h-4 w-4 ml-auto"/>}
                        </div>,
                        onClick: (): void => handleChooseFocusType(LineItemFocusType.Required),
                        active: focusType === LineItemFocusType.Required && totalMissingRequiredFields > 0,
                        hidden: totalMissingRequiredFields === 0
                    },
                    {
                        label: <div className="flex items-center gap-3">
                            <LowConfidenceScanIcon className="h-4 w-4" />
                            <Typography variant="BASE" className="m-0 block truncate" desktopSize="lg:text-2">{t('lowConfidenceFields')}</Typography>
                            {focusType === LineItemFocusType.LowConfidence && <CheckIcon className="h-4 w-4 ml-auto"/>}
                        </div>,
                        onClick: (): void => handleChooseFocusType(LineItemFocusType.LowConfidence),
                        active: focusType === LineItemFocusType.LowConfidence && totalLowConfidenceFields > 0,
                        hidden: totalLowConfidenceFields === 0
                    }

                ]}/>

        </div>
    </div>;
};

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import LoadingData from "@/components/LoadingData";
import { IonContent } from "@ionic/react";
import { checkVersion } from "@v2/utils/CheckVersion";
import * as signInActions from "../../actions/signInActions";
import * as buyerActions from "../../actions/buyerActions";
import useFeatures from "../../domains/shared/hooks/useFeatures";
import AddCustomItemContainer from "../../components/modals/AddCustomItem/AddCustomItemContainer.jsx";
import useChatClient from "../../domains/Chat/hooks/useChatClient";
import { MAIN_ION_CONTENT_ID } from "@/helpers/common/mobile";
import { Loading } from '@notch-ordering/ui-components';
import { activateFeatureFlags, isFeatureFlagActivated } from "../../domains/shared/feature-flag";
import useOrgHook from "@v2/hooks/useOrg.hook";
import useSwitchBuyerHook from "@v2/hooks/useSwitchBuyer.hook";

const LoggedInWrapper = ({ children, router }) => {
  const dispatch = useDispatch();
  /**
   * Initialize app features
   */
  const { feature, getAppFeatures } = useFeatures();
  const { targetBuyer } = router.location.query;
  const { switchBuyer } = useSwitchBuyerHook();
  const { org } = useOrgHook();

  /**
   * Initialize the Twilio Chat instance once user logs in...
   */
  useChatClient();

  useEffect(() => {
    checkVersion();
    dispatch(signInActions.postSignin());
    
    const getRemoteConfig = async () => {
        await activateFeatureFlags();
        getAppFeatures();
    };

    if (!isFeatureFlagActivated()) {
        getRemoteConfig();
    } else {
        getAppFeatures();
    }
  }, []);
  
  /**
   * Checks for targetBuyer query param and switches user to that buyer if valid
   */
  useEffect(() => {
    if (targetBuyer) {
        const buyerData = org?.find((org) => org.buyerKey === targetBuyer);
        switchBuyer(buyerData);
    }
  }, [org, targetBuyer])

  const defaultBuyer = JSON.parse(window.localStorage.getItem("chefhero_defb"));

  return (
    <React.Suspense fallback={<Loading className="pt-safe"/>}>
      <div className="logged-in-wrapper">
        <IonContent
          id={MAIN_ION_CONTENT_ID}
          scrollEvents
          className="app-content"
        >
          {!_.isEmpty(defaultBuyer) && defaultBuyer.buyerKey ? children : null}
        </IonContent>
        <AddCustomItemContainer />
      </div>
    </React.Suspense>
  );
};

LoggedInWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoggedInWrapper;

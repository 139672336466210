import {toastr} from 'react-redux-toastr';
import {replace} from 'react-router-redux';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import {CheckoutFlowWebViewEvents} from '../../../constants';
import {fetchSingleOrder, orderEdit} from '../../../api/DataAPI';
import {logException} from '../../../domains/shared/logger';
import {
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILED,
} from '../types';

/**
 * Get Order Details
 *
 * @param {string} orderUrlsafe Order unique id
 */
export const getOrderDetails = (orderUrlsafe) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_DETAILS_START });
    const { data = null } = await fetchSingleOrder(orderUrlsafe);
    if (data) {
      dispatch({
        type: GET_ORDER_DETAILS_SUCCESS,
        payload: camelCaseKeys(data, { deep: true }),
      });
    }
  } catch (error) {
    dispatch({ type: GET_ORDER_DETAILS_FAILED });
    const { response = null } = error;
    let message = '';

    if (!response) {
      message = 'Detected a connection problem, please refresh this page';
    } else {
      const {
        data: { errorMessage = 'Please try again' } = {
          errorMessage: 'Please try again',
        },
        status = 0,
      } = response;

      if (status === 403) {
        message = 'Unauthorized error!';
      } else {
        message = errorMessage;
      }
    }

    toastr.error(`Error: ${message}`);
    console.error(error);
    logException(error);
  }
};

/**
 * Edit Order Details
 *
 * @param {string} orderUrlsafe Order unique id
 * @data {Array} data array of order items
 */
export const editOrder = ({
  orderUrlSafe,
  data,
  isWeb,
  vendorName,
  callback,
}) => {
  return async (dispatch) => {
    try {
      const response = await orderEdit(
        orderUrlSafe,
        snakeCaseKeys(data, { deep: true })
      );
      if (response.data) {
        dispatch({
          type: GET_ORDER_DETAILS_SUCCESS,
          payload: camelCaseKeys(response.data, { deep: true }),
        });
        callback(false);
        if (isWeb) {
          toastr.success(
            `Revisions to your invoice from ${vendorName} have been submitted for review.`
          );
        } else {
          dispatch(
            replace({
              pathname: location.pathname,
              query: {
                event: CheckoutFlowWebViewEvents.CLOSE,
              },
            })
          );
        }
      }
    } catch (error) {
      logException(error);
      callback(false);
      toastr.error(`Error: ${error}`);
    }
  };
};

import React from 'react';

import { UploadAsset } from '@v2/components/Uploads/UploadAsset/UploadAsset';
import { Tooltip, Typography } from '@notch-ordering/ui-components';
import { COLUMN_NO_VALUE } from '@v2/Pages/Uploads/UploadsConstants';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

export interface UploadAssetRowProps {
    urls: string[],
    supplierName?:string,
    invoiceNumber?: string,
}

const INVOICE_NUMBER_NOT_FOUND = 'NOT_FOUND';

export const UploadAssetColumn : React.FC<UploadAssetRowProps> = ({ urls = [], supplierName, invoiceNumber = '' }) => {
    const [showViewImagesTooltip, setShowViewImagesTooltip] = React.useState(false);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Uploads' });
    return <div className="flex gap-2.5 items-center flex-wrap">
        <Tooltip show={showViewImagesTooltip}
            tooltipClassName="py-1.5 px-2 rounded-md z-50"
            arrowClassName=" -left-6"
            showArrow
            placement="top-start"
            trigger={
                <div onMouseLeave={():void => setShowViewImagesTooltip(false)} onMouseEnter={():void => setShowViewImagesTooltip(true)}><UploadAsset urls={urls}
                    supplierName={supplierName}
                    invoiceNumber={invoiceNumber}
                    className="w-10 h-10 object-cover rounded-lg"/></div>
            }>
            <Typography as="span" variant="BASE">{t('viewImageTooltip')}</Typography>
        </Tooltip>
        <Typography as="div"
            className="truncate flex-1">{invoiceNumber === INVOICE_NUMBER_NOT_FOUND ? COLUMN_NO_VALUE : invoiceNumber}</Typography>
    </div>;
};

import React from 'react';
import { Separator, Typography } from '@notch-ordering/ui-components';
import ChevronRightIcon from '@icons/chevron-right-icon.svg';
import CloseIcon from '@icons/close-icon.svg';

export interface ScanOverviewDetailsItemProps {
    title?: React.ReactNode,
    description?: React.ReactNode,
    onClick: (e?: React.MouseEvent) => void,
    icon?: React.ReactNode,
    separator?: boolean,
    showClose?: boolean,
}

export const ScanOverviewDetailsItem : React.FC<ScanOverviewDetailsItemProps> = ({ icon, title, description, separator, onClick, showClose }) => <>
    <div className="py-8 px-8 flex items-center cursor-pointer group" onClick={onClick} role="button" >
        <div className="flex gap-1 flex-col">
            <div className="flex gap-3 h-full">
                <Typography weight="font-semibold"
                    variant="LG-2"
                    as="div"
                    className="text-gray-700 m-0">{title}</Typography>
                {icon}
            </div>
            <Typography className="text-gray-600 m-0">{description}</Typography>
        </div>

        {showClose ? <CloseIcon className="w-4 h-4 text-gray-500 ml-auto" /> : <ChevronRightIcon className="w-4 h-4 text-gray-500 ml-auto group-hover:text-gray-600 " />}

    </div>
    {separator && <Separator variant="small"/>}
</>;

import React, { useEffect, useState } from 'react';
import { Button, Popover, Typography } from '@notch-ordering/ui-components';
import { ELineItemPrecision, LineItemTaxType, valueToFixedNumber, valueToFixedString } from '@notch-ordering/shared-logic';
import CheckIcon from '@icons/check-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { CurrencyInput } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsForm/InputCurrency';
import { mergeClassNames } from '@v2/utils/Helpers';

export const enum InputToggleType {
    dollarAmount = 'dollarAmount',
    percent = 'percent',
}

export interface InputTaxProps {
    label?: string,
    id: string,
    value: LineItemTaxType,
    onChange: (itemTax: LineItemTaxType) => void,
    itemSubtotal?: number,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    className?: string,
}

export const INPUT_TAX_SYMBOLS: Record<InputToggleType, string> = {
    [InputToggleType.dollarAmount]: '$',
    [InputToggleType.percent]: '%',
};

export const InputTax : React.FC<InputTaxProps> = ({
    onChange,
    label, id,
    value = {
        dollarAmount: 0,
        percent: 0,
    },
    itemSubtotal = 0,
    onFocus = ():void => {},
    className
}) => {
    const [taxDisplayMode, setTaxDisplayMode] = useState<InputToggleType>(InputToggleType.dollarAmount);
    const [inputValue, setInputValue] = useState<string>('0');
    const [tax, setTax] = useState<LineItemTaxType>(value);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.LineItems.ProductFields' });

    const isDollarAmount = taxDisplayMode === InputToggleType.dollarAmount;
    const isPercent = taxDisplayMode === InputToggleType.percent;

    /**
     * Tax dollar amounts round to 2 precision.
     * Tax percent round to 4 precision.
     */
    const calculateTaxAmounts = ():void => {
        if (isDollarAmount) {
            const dollarAmount: number = valueToFixedNumber(inputValue, ELineItemPrecision.TAX_AMOUNT);
            const percent: number = valueToFixedNumber((dollarAmount / itemSubtotal) * 100, ELineItemPrecision.TAX_PERCENT);
            setTax({
                dollarAmount,
                percent,
            });
        } else {
            const percent: number = valueToFixedNumber(inputValue, ELineItemPrecision.TAX_PERCENT);
            const dollarAmount: number = valueToFixedNumber(itemSubtotal * (percent / 100), ELineItemPrecision.TAX_AMOUNT);
            setTax({
                dollarAmount,
                percent,
            });
        }
    };

    useEffect(() => {
        calculateTaxAmounts();
        onChange(tax);
        setInputValue(valueToFixedString(tax[taxDisplayMode], isPercent ? ELineItemPrecision.TAX_PERCENT : ELineItemPrecision.TAX_AMOUNT));
    }, [taxDisplayMode]);

    useEffect(() => {
        calculateTaxAmounts();
    }, [inputValue]);

    useEffect(() => {
        setTax(value);
        setInputValue(valueToFixedString(value[taxDisplayMode], isPercent ? ELineItemPrecision.TAX_PERCENT : ELineItemPrecision.TAX_AMOUNT));
    }, [value]);

    const handleTaxTypeChange = (option:InputToggleType):void => {
        setTaxDisplayMode(option);
        onChange(tax);
    };

    return <div className={mergeClassNames('relative flex gap-1 border-gray-400 border rounded-lg items-center w-full', className)}>
        <CurrencyInput variant="ADVANCED_ALTERNATIVE"
            onChange={(v):void => {
                setInputValue(v.value);
            }}
            inputClassName={'border-none'}
            onFocus={onFocus}
            id={id}
            suffix={isPercent ? INPUT_TAX_SYMBOLS.percent : ''}
            prefix={isDollarAmount ? INPUT_TAX_SYMBOLS.dollarAmount : ''}
            decimalScale={isPercent ? ELineItemPrecision.TAX_PERCENT : ELineItemPrecision.TAX_AMOUNT}
            fixedDecimalScale={false}
            value={inputValue}
            onBlur={():void => {
                setInputValue(valueToFixedString(tax[taxDisplayMode], isPercent ? ELineItemPrecision.TAX_PERCENT : ELineItemPrecision.TAX_AMOUNT));
                onChange(tax);
            }}
            label={label}/>

        <Popover
            className="w-48"
            parentDivClassName={'h-full w-14 flex items-center border-l border-gray-200 ml-auto'}
            buttonClassName="ml-auto h-full w-full"
            placement="bottom-start"
            button={<Button variant="TEXT"
                size="NO_BACKGROUND"
                className="w-full h-full px-3 border border-gray-400 rounded-l-none rounded-r-lg
                [.open_&]:bg-gray-100 group-hover:bg-gray-100  gap-3 flex items-center justify-center"
                stopPropagation={false}>
                <div>{INPUT_TAX_SYMBOLS[taxDisplayMode]}</div>
                <ChevronDownIcon className="w-4 shrink-0 h-4 text-gray-600" />

            </Button>}
            items={[
                {
                    label: <div className="flex items-center justify-between">
                        <Typography className="flex items-center gap-4 m-0 truncate pr-2">
                            <span>$</span>
                            <span>{t('dollar')}</span>

                        </Typography>
                        {isDollarAmount && <CheckIcon className="w-4 h-4"/>}
                    </div>,
                    onClick: (): void => handleTaxTypeChange(InputToggleType.dollarAmount),
                    active: isDollarAmount,
                },
                {
                    label: <div className="flex items-center justify-between">
                        <Typography className="flex items-center gap-4 m-0 truncate pr-2">
                            <span>%</span>
                            <span>{t('percentage')}</span>
                        </Typography>
                        {isPercent && <CheckIcon className="w-4 h-4"/>}
                    </div>,
                    onClick: (): void => handleTaxTypeChange(InputToggleType.percent),
                    active: isPercent,
                },

            ]}/>
    </div>;
};

/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { Button, Loading, Separator, Typography } from '@notch-ordering/ui-components';
import IntegrationsIcon from '@icons/integration-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import ReceiptIcon from '@icons/receipt-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import CustomersIcon from '@icons/customers-icon.svg';
import ForkIcon from '@icons/fork-icon.svg';
import ShoppingIcon from '@icons/shopping-bag-icon.svg';
import TruckIcon from '@icons/truck-icon.svg';
import { t } from '@v2/i18n';
import cx from 'classnames';
import { EPaths, ESettingsPaths, ESetupPaths } from '@v2/constants/EPaths';
import { Router } from 'react-router';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { getOcrInvoices } from '@v2/network/FalsePromiseApi';
import { useLinkedSupplierCatalogs } from '@v2/hooks/useLinkedSupplierCatalogs';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import useFirebaseRemoteConfig from '@v2/hooks/useFirebaseRemoteConfig.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvent, TrackingEvents } from '@v2/constants/Tracking';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { listInvoices } from '@v2/network/GreevilsGreedApi';
import { getAccountingConfiguration } from '@notch-ordering/shared-logic';
import AddCreditCardModal from '../Shared/AddCreditCard/AddCreditCardModal';

export interface OnboardingProps {
    router: Router,
}

export interface IOnboardingTask {
    key: string,
    title: string,
    description: string,
    icon: React.ReactNode,
    buttonName: string,
    path: string,
    checkInitialState?: (buyerUrlsafeKey?: string) => Promise<boolean>,
    disabledOnMobile?: boolean,
    trackingEvent: TrackingEvent,
    paywall?: boolean,
}

export enum EOnboardingTasks {
    SETUP_INTEGRATION = 'setupIntegration',
    ADD_SUPPLIER = 'addSupplier',
    ADD_BILL = 'addBill',
    ADD_STAFF = 'addStaff',
    CREATE_CATALOG = 'createCatalog',
    PLACE_ORDER = 'placeOrder',
}

export const OnboardingTasksKeys: string[] = Object.values(EOnboardingTasks);

export const OnboardingTasks : React.FC<OnboardingProps> = ({ router }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isExpanded, setIsExpanded] = useState<boolean[]>([].fill(false, OnboardingTasksKeys.length));
    const [hideIntegration, setHideIntegration] = useState(false);
    const isMobileWidth = useIsMobileWidth();
    const { isTaskComplete, completeTask } = useOnboardingStore();
    const { buyer, buyerMembers } = useBuyerHook();
    const { fetchQuery: supplierCatalogsQuery } = useLinkedSupplierCatalogs();
    const firebaseConfig = useFirebaseRemoteConfig();
    const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
    const buyerHasPaymentMethodConfigured = !!buyer.stripeCustomerID;

    // Set the initial state of catalog related onboarding tasks
    supplierCatalogsQuery({
        enabled: !isTaskComplete(buyer.urlsafe, EOnboardingTasks.ADD_SUPPLIER) || !isTaskComplete(buyer.urlsafe, EOnboardingTasks.CREATE_CATALOG),
        onSuccess: (catalogs) => {
            for (let i = 0; i < catalogs?.supplierCatalogs.length; i++) {
                const catalog = catalogs.supplierCatalogs[i];
                if (catalog.productCount > 0) {
                    completeTask(buyer.urlsafe, EOnboardingTasks.CREATE_CATALOG, false);
                    break;
                }
            }

            if (catalogs?.supplierCatalogs?.length > 0) {
                completeTask(buyer.urlsafe, EOnboardingTasks.ADD_SUPPLIER, false);
            }
        }
    });

    const onboardingTasksList:IOnboardingTask[] = [
        {
            key: EOnboardingTasks.SETUP_INTEGRATION,
            title: t('OnboardingTasks.setupIntegration'),
            description: t('OnboardingTasks.setupIntegrationDescription'),
            icon: <IntegrationsIcon className="w-4 h-4" />,
            buttonName: t('OnboardingTasks.viewIntegrations'),
            path: `${ESettingsPaths.INTEGRATIONS}`,
            checkInitialState: async (): Promise<boolean> => {
                try {
                    if (!buyer?.urlsafe) return false;
                    const accountIntegration = await getAccountingConfiguration(buyer.urlsafe);
                    return accountIntegration?.isEnabled;
                } catch (e) {
                    console.info(e);
                    return false;
                }
            },
            trackingEvent: TrackingEvents.onboardingIntegrationsClicked
        },
        {
            key: EOnboardingTasks.ADD_SUPPLIER,
            title: t('OnboardingTasks.addYourSuppliers'),
            description: t('OnboardingTasks.addYourSuppliersDescription'),
            icon: <TruckIcon className="w-4 h-4" />,
            buttonName: t('OnboardingTasks.addSuppliers'),
            path: `${EPaths.SETUP}/${ESetupPaths.SUPPLIERS}?createSupplier=true`,
            trackingEvent: TrackingEvents.onboardingSuppliersClicked,
            paywall: true,
        },
        {
            key: EOnboardingTasks.ADD_BILL,
            title: t('OnboardingTasks.addBills'),
            description: t('OnboardingTasks.addBillsDescription'),
            icon: <ReceiptIcon className="w-4 h-4" />,
            buttonName: t('OnboardingTasks.uploadBill'),
            path: EPaths.UPLOADS,
            checkInitialState: async (buyerUrlsafeKey: string): Promise<boolean> => {
                const ocrInvoices = await getOcrInvoices({ buyerUrlsafeKey }, { limit: 1 });
                return ocrInvoices?.total > 0;
            },
            trackingEvent: TrackingEvents.onboardingBillsClicked,
            paywall: true,
        },
        {
            key: EOnboardingTasks.ADD_STAFF,
            title: t('OnboardingTasks.addYourStaff'),
            description: t('OnboardingTasks.addYourStaffDescription'),
            icon: <CustomersIcon className="w-4 h-4" />,
            buttonName: t('OnboardingTasks.addStaff'),
            path: `${EPaths.SETTINGS}/${ESettingsPaths.MEMBERS}`,
            checkInitialState: async (): Promise<boolean> => buyerMembers?.length > 1,
            trackingEvent: TrackingEvents.onboardingStaffClicked
        },
        {
            key: EOnboardingTasks.CREATE_CATALOG,
            title: t('OnboardingTasks.createCatalog'),
            description: isMobileWidth ? t('OnboardingTasks.onlyOnDesktop') : t('OnboardingTasks.createCatalogDescription'),
            icon: <ForkIcon className="w-4 h-4" />,
            buttonName: t('OnboardingTasks.uploadCatalog'),
            disabledOnMobile: !!isMobileWidth,
            path: EPaths.CATALOG,
            trackingEvent: TrackingEvents.onboardingCatalogClicked
        },
        {
            key: EOnboardingTasks.PLACE_ORDER,
            title: t('OnboardingTasks.placeOrder'),
            description: t('OnboardingTasks.placeOrderDescription'),
            icon: <ShoppingIcon className="w-4 h-4" />,
            buttonName: t('OnboardingTasks.goToShop'),
            path: EPaths.SHOP,
            checkInitialState: async (buyerUrlsafeKey: string): Promise<boolean> => {
                const data = await listInvoices(1, buyerUrlsafeKey);
                return data?.total > 0;
            },
            trackingEvent: TrackingEvents.onboardingShopClicked
        },
    ];

    useEffect(() => {
        async function checkIntegrationsFeatureFlag(): Promise<void> {
            const isIntegrationsEnabled = await firebaseConfig.isEnabled(EFirebaseParams.INTEGRATIONS_ENABLED);
            const isAccountingEnabled = await firebaseConfig.isEnabledForBuyer(EFirebaseParams.ACCOUNTING_BUYERS);
            if (!isIntegrationsEnabled || !isAccountingEnabled) {
                setHideIntegration(true);
                completeTask(buyer.urlsafe, EOnboardingTasks.SETUP_INTEGRATION);
            }
        }

        checkIntegrationsFeatureFlag();
    }, []);

    /**
     * On load, check all incomplete tasks for completion based on backend data
     */
    useEffect(() => {
        const checkTasks = async (): Promise<void> => {
            setIsLoading(true);
            for (let i = 0; i < onboardingTasksList.length; i++) {
                const task = onboardingTasksList[i];
                if (!isTaskComplete(buyer.urlsafe, task.key)) {
                    const isComplete = await task.checkInitialState?.(buyer.urlsafe);
                    if (isComplete) {
                        completeTask(buyer.urlsafe, task.key, false);
                    }
                }
            }
            setIsLoading(false);
        };

        checkTasks();
    }, []);

    return <>
        <Loading hidden={!isLoading} isDark className="lg:mb-10 mb-8"/>
        <div hidden={isLoading}>
            <Separator variant={isMobileWidth ? 'large' : 'small'} className="lg:mb-10 mb-8" />
            <div className="flex flex-col lg:px-10 px-4">
                <Typography className="lg:pt-0 pt-2 pb-8" variant="XL" desktopSize="text-5" weight="font-semibold">{t('OnboardingTasks.gettingStarted')}</Typography>
                {onboardingTasksList.map(({ key, title, description, icon, buttonName, path, trackingEvent, disabledOnMobile, paywall }, i) => (
                    <div key={key}
                        className={cx('flex flex-col cursor-pointer', {
                            'cursor-default': isTaskComplete(buyer.urlsafe, key),
                            'lg:mb-[74px] mb-10': i === onboardingTasksList.length - 1
                        })}
                        hidden={hideIntegration && key === EOnboardingTasks.SETUP_INTEGRATION}>
                        <div className="flex flex-row justify-between"
                            onClick= {():void => {
                                if (!isTaskComplete(buyer.urlsafe, key)) {
                                    const expanded: boolean[] = [...isExpanded];
                                    expanded[i] = !isExpanded[i];
                                    if (expanded[i]) {
                                        sendTrackingEvent({ ...TrackingEvents.onboardingStepExpandClicked, label: key });
                                    }
                                    setIsExpanded(expanded);
                                }
                            } }>
                            <div className="flex flex-col gap-y-[30px]">
                                <div className= "flex flex-row ">
                                    {!isTaskComplete(buyer.urlsafe, key) && <div className="bg-gray-100 h-10 min-w-[40px] rounded-xl flex items-center justify-center">
                                        {icon}
                                    </div>}
                                    {isTaskComplete(buyer.urlsafe, key) && <div className="bg-green-100 h-10 min-w-[40px] rounded-3xl flex items-center justify-center">
                                        <CheckIcon className="w-5 h-5 text-green-300 relative top-[1px]" />
                                    </div>}
                                    <div className="flex flex-col ml-6 justify-center">
                                        <Typography as="div"
                                            className={cx({ 'mb-1': isExpanded[i] })}
                                            weight={cx({ 'font-semibold': isExpanded[i] && !isTaskComplete(buyer.urlsafe, key), 'font-medium': !isExpanded[i] })}>{title}</Typography>
                                        {(isExpanded[i] && !isTaskComplete(buyer.urlsafe, key)) && <Typography as="div" className="text-gray-600 mr-[18px] lg:mr-20">{description}</Typography>}
                                    </div>
                                </div>
                                {(isExpanded[i] && !isTaskComplete(buyer.urlsafe, key) && !disabledOnMobile) && <div className="flex flex-row gap-x-2 ml-16">
                                    <Button variant="SECONDARY"
                                        size="SMALL"
                                        onClick={(): void => {
                                            if (paywall && !buyerHasPaymentMethodConfigured) {
                                                setIsCreditCardModalOpen(true);
                                            } else {
                                                router.push(`${path}`);
                                                sendTrackingEvent(trackingEvent);
                                            }
                                        }}>
                                        {buttonName}
                                    </Button>
                                    <Button
                                        variant="TERTIARY_FILLED"
                                        size="SMALL"
                                        onClick={(): void => {
                                            completeTask(buyer.urlsafe, key);
                                            sendTrackingEvent({ ...TrackingEvents.onboardingSkipStepClicked, label: key });
                                        }}>
                                        {t('OnboardingTasks.skip')}
                                    </Button>
                                </div>}
                            </div>
                            {!isTaskComplete(buyer.urlsafe, key) && <div className="mt-4 cursor-pointer" >
                                <ChevronDownIcon className={cx('w-4 h-4 text-gray-500', {
                                    'rotate-180': isExpanded[i]
                                })} />
                            </div>}
                        </div>
                        {i < onboardingTasksList.length - 1 && <Separator className="my-[30px]" />}
                    </div>))}
            </div>
        </div>
        <AddCreditCardModal setIsOpen={setIsCreditCardModalOpen}
            isOpen={isCreditCardModalOpen}/>
    </>;
};

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import DisplayUnit from '../../DisplayUnit.jsx';
import Utils from '../../../utils';

import { ProductQuantitySelect } from '../../shared';
import { Typography } from '@notch-ordering/ui-components';

const CartOrderCustomItem = ({
  orderItem,
  onChangeQty,
  onRemove,
  isDisabled,
}) => {
  const [total, setTotal] = useState(0);
  const options = Utils.getInputQuantityOptions();

  useEffect(() => {
    if (orderItem.quantity && orderItem.price) {
      setTotal(orderItem.quantity * orderItem.price);
    } else {
      setTotal(0);
    }
  }, [orderItem.quantity]);

  return (
    <div className="cart-order-item">
      <ProductQuantitySelect
        isDisabled={isDisabled}
        className="cart-order-item__quantity-select"
        options={options}
        onChange={({ value }) => {
          onChangeQty(value, orderItem.id);
        }}
        value={{
          value: orderItem.quantity,
          label: orderItem.quantity,
        }}
        clearable={false}
      />

      <div className="cart-order-item__name-and-unit">
        <div>
          <Typography weight='font-semibold' className="cart-order-item__name">{orderItem.name}</Typography>
        </div>

        <div className="cart-order-item__unit">
          <DisplayUnit
            unitName={orderItem.packaging ?? ''}
            unitDescription={orderItem.externalRef ?? ''}
          />
        </div>
      </div>

      <div className="cart-order-item__price">
        <Typography as='span'>{total && total > 0 ? Utils.formatAsCurrency(total) : '-'}</Typography>
        <div
          className={cx({
            'cart-order-item-remove': true,
            'cart-order-item-remove--disabled': isDisabled,
          })}
          onClick={() => !isDisabled && onRemove(orderItem.id)}
        >
          <span className="fa fa-trash fa-1" />
        </div>
      </div>
    </div>
  );
};

CartOrderCustomItem.propTypes = {
  orderItem: PropTypes.object.isRequired,
  onChangeQty: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default CartOrderCustomItem;

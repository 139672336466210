import { useGetProductUnits } from '@v2/Pages/Market/MarketQueries.hook';
import React, { useEffect } from 'react';
import { LineItem, LineItemFocusType, useOcrInvoiceStore, LineItemTypeOptions, LineItemCategory, LineItemCategoryEnum, getLineItems } from '@notch-ordering/shared-logic';
import cx from 'classnames';
import { Tabs, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { FeeLineItem } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsForm/FeeLineItem';
import { TaxLineItem } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsForm/TaxLineItem';
import { getLineItemCategory, getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { ProductLineItem } from './ProductLineItem';

/**
 React component for displaying and editing line item details on an OCR invoice form.
 *
 @returns JSX.Element
 */
export const ScanLineItemsForm : React.FC = () => {
    const { data: unitsData, status } = useGetProductUnits();
    const hasLoadedUnits = status === 'success';
    const {
        setFocusType,
        formErrors,
        validateForm,
        draftInvoice,
        updateLineItemPrimaryData,
        showFocusPanel,
        setShowFocusPanel,
    } = useOcrInvoiceStore();
    const { setCurrentErrorField, selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const { glCodes, taxCodes } = draftInvoice;
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);

    // For this line item we ignore chart of accounts because they get calculated
    const lineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, true, hasImsIntegration);
    const lineItems = getLineItems(draftInvoice, lineItemCategory, useSeparateIMSLineItems);

    // For this line item we include chart of accounts if auto calculate is on
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);

    const units = unitsData?.units || [];
    const unitOptions = units.map((u) => ({
        label: u,
        value: u,
    }));

    const glCodeOptions = glCodes?.map((g) => ({
        label: g.code ? `${g.name} - ${g.code}` : g.name,
        value: g.id
    })) ?? [];

    const taxCodeOptions = taxCodes?.map((g) => ({
        label: g.name,
        value: g.id
    })) ?? [];

    const onBlur = ():void => {
        validateForm(lineItemCategoryToValidate);
    };

    useEffect(() => {
        validateForm(lineItemCategoryToValidate);
    }, []);

    const isIMSLineItem = selectedTab === Tabs.IMS || (selectedTab === Tabs.NO_TABS && hasImsIntegration);

    const hasErrorFieldIndex = (index: number, field: string): boolean => {
        if (lineItemCategoryToValidate === LineItemCategoryEnum.ChartOfAccountDetails) {
            return false;
        }

        if (formErrors[lineItemCategoryToValidate]?.requiredFields?.errorFields) {
            return formErrors[lineItemCategoryToValidate]?.requiredFields.errorFields[index]?.[field] !== undefined;
        }

        return false;
    };

    const hasLowConfidenceFieldIndex = (index: number, field: string): boolean => {
        if (formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.errorFields) {
            if (formErrors[lineItemCategoryToValidate]?.lowConfidenceFields.errorFields[index]?.[field] === undefined) {
                return false;
            }
            return formErrors[lineItemCategoryToValidate]?.lowConfidenceFields.errorFields[index]?.[field] !== null;
        }
        return false;
    };

    const handleOnFocusField = (e: React.FocusEvent<HTMLInputElement>): void => {
        const { id } = e.target;
        validateForm(lineItemCategoryToValidate);
        const { requiredFields, lowConfidenceFields } = formErrors[lineItemCategoryToValidate];
        const isMissingField = requiredFields?.errorLineItemRefs?.includes(id);
        const isLowConfidenceField = lowConfidenceFields?.errorLineItemRefs?.includes(id);
        // if field is not missing and not low confidence and the focus panel is open then hide focus panel
        if (!isMissingField && !isLowConfidenceField && showFocusPanel) {
            setShowFocusPanel(false);
            setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);
            return;
        }
        if (isMissingField) {
            const indexError = requiredFields?.errorLineItemRefs?.indexOf(id);
            setCurrentErrorField(indexError);
            // if the focus panel is not opened then open and set focus type to required
            if (!showFocusPanel) {
                setShowFocusPanel(true);
            }
            setShowFocusPanel(true);

            return;
        }
        if (isLowConfidenceField) {
            const indexError = lowConfidenceFields?.errorLineItemRefs?.indexOf(id);
            setCurrentErrorField(indexError);
            // if the focus panel is not opened then open and set focus type to low confidence
            if (!showFocusPanel) {
                setShowFocusPanel(true);
            } else {
                // if the focus panel is opened then set focus type to low confidence
            }

            setFocusType(LineItemFocusType.LowConfidence, lineItemCategoryToValidate);
        }
    };

    const hasGLCodeOptions = glCodeOptions.length > 0;
    const hasTaxCodeOptions = taxCodeOptions.length > 0;

    const updateFormData = <T extends keyof LineItem>(reactKey: string, lineItemKey: T, value: LineItem[T]): void => {
        updateLineItemPrimaryData(reactKey, lineItemKey, value, lineItemCategory);
    };
    return <div className={cx({ 'pt-16': showFocusPanel })}>
        {lineItems.map((ocrMatchedLineItem, index) => {
            const lineItemPrimaryData = ocrMatchedLineItem.primaryItemData;

            switch (ocrMatchedLineItem.type) {
                case LineItemTypeOptions.TAX:
                    return (
                        <TaxLineItem
                            key={ocrMatchedLineItem.reactKey}
                            lineItemPrimaryData={lineItemPrimaryData}
                            reactKey={ocrMatchedLineItem.reactKey}
                            index={index}
                            lineItems={lineItems}
                            onBlur={onBlur}
                            hasErrorFieldIndex={hasErrorFieldIndex}
                            hasLowConfidenceFieldIndex={hasLowConfidenceFieldIndex}
                            handleOnFocusField={handleOnFocusField}
                            updateFormData={updateFormData}
                            glCodeOptions={glCodeOptions}/>
                    );
                case LineItemTypeOptions.FEE:
                    return (
                        <FeeLineItem
                            key={ocrMatchedLineItem.reactKey}
                            lineItemPrimaryData={lineItemPrimaryData}
                            reactKey={ocrMatchedLineItem.reactKey}
                            index={index}
                            lineItems={lineItems}
                            onBlur={onBlur}
                            hasErrorFieldIndex={hasErrorFieldIndex}
                            hasLowConfidenceFieldIndex={hasLowConfidenceFieldIndex}
                            handleOnFocusField={handleOnFocusField}
                            hasGLCodeOptions={hasGLCodeOptions}
                            hasTaxCodeOptions={hasTaxCodeOptions}
                            glCodeOptions={glCodeOptions}
                            taxCodeOptions={taxCodeOptions}
                            updateFormData={updateFormData}
                            isIMSLineItem={isIMSLineItem}/>
                    );
                case LineItemTypeOptions.PRODUCT:
                default:
                    return (
                        <ProductLineItem
                            key={ocrMatchedLineItem.reactKey}
                            lineItemPrimaryData={lineItemPrimaryData}
                            ocrMatchedLineItem={ocrMatchedLineItem}
                            reactKey={ocrMatchedLineItem.reactKey}
                            index={index}
                            lineItems={lineItems}
                            unitOptions={unitOptions}
                            onBlur={onBlur}
                            hasErrorFieldIndex={hasErrorFieldIndex}
                            hasLowConfidenceFieldIndex={hasLowConfidenceFieldIndex}
                            handleOnFocusField={handleOnFocusField}
                            hasGLCodeOptions={hasGLCodeOptions}
                            hasTaxCodeOptions={hasTaxCodeOptions}
                            updateFormData={updateFormData}
                            glCodeOptions={glCodeOptions}
                            taxCodeOptions={taxCodeOptions}
                            hasLoadedUnits={hasLoadedUnits}
                            isIMSLineItem={isIMSLineItem}/>
                    );
            }
        })}
        <div className="mt-12" />
    </div>;
};

import { Button, Popover, toast } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { tCommon } from '@v2/i18n';
import { deleteProduct, deleteProductPackage } from '@v2/network/SecretShopAPI';
import { CatalogManagementRowData } from '../CatalogManagementConstants';
import { ConfirmDeletionModal } from './ConfirmDeletionModal';
import { OrderGuidesModal } from './OrderGuidesModal';

export interface Props {
    row: CatalogManagementRowData,
    buyerUrlsafe: string,
    onDelete: () => void,
    openProductDetailsModal: () => void,
}

export const ProductRowMoreButton: React.FC<Props> = ({ row, buyerUrlsafe, onDelete, openProductDetailsModal }) => {
    const [isOrderGuidesModalOpen, setIsOrderGuidesModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const id = row.isProduct ? row.productID : row.id;
    return (
        <div>
            <Popover
                className="w-44"
                button=
                    {<Button
                        variant="TERTIARY_TABLE"
                        className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                        size="SMALL"
                        stopPropagation={false}>
                        <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                            <EllipsisIcon className="h-4 w-4" />
                        </div>
                    </Button>}
                items={[
                    {
                        label: tCommon('Buttons.edit'),
                        onClick: openProductDetailsModal,
                    },
                    {
                        label: tCommon('Buttons.addToOrderGuide'),
                        onClick: (): void => {
                            setIsOrderGuidesModalOpen(true);
                        },
                    },
                    {
                        label: tCommon('Buttons.delete'),
                        className: 'text-red-300',
                        onClick: (): void => {
                            setIsDeleteModalOpen(true);
                        },
                    },
                ]}/>
            <OrderGuidesModal isOpen={isOrderGuidesModalOpen}
                productLabel={row.name}
                buyerUrlsafe={buyerUrlsafe}
                selectedProductIDs={[id]}
                closeFunction={(): void => setIsOrderGuidesModalOpen(false)} />
            <ConfirmDeletionModal isOpen={isDeleteModalOpen}
                productLabel={row.isProduct ? `"${row.name}"?` : `"${row.name} - ${row.size}"?`}
                deleteFunction={(): void => {
                    try {
                        if (row.isProduct) {
                            deleteProduct(id, row.supplierCatalogID);
                        } else {
                            deleteProductPackage({ pack: { id: row.id, sku: row.sku }, productID: row.productID, supplierCatalogID: row.supplierCatalogID });
                        }
                    } catch (e) {
                        toast.show({
                            message: `An error occurred while deleting ${row.name}`,
                        });
                    }
                    toast.show({
                        message: `Successfully deleted ${row.name}`,
                    });
                    setIsDeleteModalOpen(false);
                    onDelete();
                }}
                closeFunction={(): void => setIsDeleteModalOpen(false)} />
        </div>
    );
};

import React from 'react';
import { isPlatform } from '@ionic/react';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';
import { blobToBase64, PDF_CONTENT_TYPE } from '@v2/utils/PDF';
import { EIonicPlatforms } from '@/constants/Mobile';

export interface ScanReviewPDFItemProps {
    url: string,
    pdfData: string,
    pdfBytesArray: Uint8Array,
}

export const ScanReviewPDF : React.FC<ScanReviewPDFItemProps> = ({ url, pdfData, pdfBytesArray }) => {
    const isNativeDevice = isPlatform(EIonicPlatforms.CAPACITOR);

    const handlePdfClick = async (): Promise<void> => {
        try {
            if (isNativeDevice) {
                const fileName = url.split('/')
                    .pop();
                const data = await blobToBase64(new Blob([pdfBytesArray], { type: PDF_CONTENT_TYPE })) as string;
                const file = await Filesystem.writeFile({
                    data,
                    path: fileName,
                    directory: Directory.Documents
                });
                await FileOpener.open({
                    filePath: file.uri,
                    contentType: PDF_CONTENT_TYPE
                });
                // when the file viewer is closed then it will delete the file from the system
                await Filesystem.deleteFile({
                    path: file.uri,
                    directory: Directory.Documents
                });
            }
        } catch (e) {
            console.info(e, 'error');
        }
    };

    return <div onClick={handlePdfClick} className="cursor-pointer p-9" data-testid="scan-review-pdf">
        <object type="application/pdf" data={`${pdfData}#navpanes=0&scrollbar=0`} className="w-full lg:h-screen" />
    </div>;
};

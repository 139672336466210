import { useContext, useEffect, useState } from 'react';
import { AccountingInfo, getAccountingInfo } from '@notch-ordering/shared-logic';
import { InvoicesPageContext } from '@v2/Pages/Invoices/InvoicesPageContext';
import useBuyerHook from './useBuyer.hook';
import { useApPlatform } from './useApPlatform.hook';

type UseAccounting = {
    isAccountingEnabled: boolean,
    accountingInfo: AccountingInfo,
};

type AccountingInfoState = {
    loading: boolean,
    accountingInfo: AccountingInfo,
};

export const useAccounting = () : UseAccounting => {
    const { buyer } = useBuyerHook();
    const apPlatform = useApPlatform();
    const { isAccountingEnabled } = useContext(InvoicesPageContext);

    const buyerUrlsafeKey = buyer.urlsafe;

    const [accountingInfoState, setAccountingInfoState] = useState<AccountingInfoState>({
        loading: true,
        accountingInfo: null
    });

    useEffect(() => {
        if (buyerUrlsafeKey && apPlatform) {
            const fetchAccountingInfo = async (): Promise<void> => {
                const res = await getAccountingInfo(apPlatform, buyerUrlsafeKey);
                setAccountingInfoState({
                    loading: false,
                    accountingInfo: res
                });
            };

            fetchAccountingInfo();
        }
    }, [buyerUrlsafeKey, isAccountingEnabled, setAccountingInfoState, apPlatform]);

    return {
        isAccountingEnabled,
        accountingInfo: accountingInfoState.accountingInfo,
    };
};

/**
 * Return the supplier initials based on spaces and valid alphanumeric chars
 *
 * @param supplierName supplier name to get initials from
 */
export const getSupplierInitials = (supplierName:string): string => {
    let initials = '';
    const [firstName, lastName] = supplierName.split(' ');
    const regex = /^[a-zA-Z]+$/;
    if (firstName && regex.test(firstName)) {
        initials += firstName[0];
    }

    if (lastName && regex.test(lastName)) {
        initials += lastName[0];
    }

    return `${initials.toUpperCase()}`;
};

export const REQUEST_SUPPLIER = 'REQUEST_SUPPLIER';

import React, { useEffect, useState } from 'react';
import { Button,
    Loading,
    Popover,
    Separator,
    Skeleton,
    toast,
    Typography } from '@notch-ordering/ui-components';
import { REQUEST_SUPPLIER } from '@v2/components/Shared/SupplierCard/SupplierCardConstants';
import ChatIcon from '@icons/chat-icon.svg';
import MoreIcon from '@icons/more-icon.svg';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { getSupplier } from '@v2/network/LegacyAPI';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { emailSupplier, getSupplierAttachments, getSupplierID, SupplierData } from '@v2/network/CoreAPI';
import cx from 'classnames';
import { useSetupStore } from '@v2/stores/SetupStore';
import { EditSupplierModal } from '@v2/components/Shared/EditSupplierModal/EditSupplierModal';
import { SupplierInfo } from '@v2/components/Shared/SupplierInfo/SupplierInfo';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import Router, { withRouter } from 'react-router';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

export interface Props {
    supplierUrlsafeKey: string,
    name: string,
    catalogID: string,
    hasCatalog?: boolean,
    productCount: number,
    controlledByBuyer: boolean,
    goToShop?: (catalogID: string) => void,
    goToChat?: (supplier: SupplierData) => void,
    goToManageCatalog?: (supplier: SupplierData) => void,
    goToUploadAttachments?: (catalogID: string, supplier: SupplierData) => void,
    removeSupplier?: (supplierUrlsafeKey: string) => void,
    editSupplier?: (supplierUrlsafeKey: string) => void,
    router?: typeof Router,
}

export const HomeSupplierCard: React.FC<Props> = ({
    supplierUrlsafeKey,
    catalogID,
    hasCatalog = false,
    controlledByBuyer,
    productCount,
    goToManageCatalog,
    goToUploadAttachments,
    goToShop,
    goToChat,
    removeSupplier,
    router
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.Suppliers' });
    const [attachmentCount, setAttachmentCount] = useState(0);
    const { setProductsCreated, setFilesUploaded, setSetupActions } = useSetupStore();
    const [isEditSupplierOpen, setIsEditSupplierOpen] = useState(false);
    const [loadingRequestPriceList, setLoadingRequestPriceList] = useState(false);
    const isMobileWidth = useIsMobileWidth();
    const iconSize = isMobileWidth ? 'ICON_LARGE' : 'ICON_MEDIUM';
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';
    const queryClient = useQueryClient();
    const { buyer } = useBuyerHook();
    const { data: supplier, isLoading, error } = useQuery([REQUEST_SUPPLIER, supplierUrlsafeKey], () => getSupplier(supplierUrlsafeKey), {
        enabled: !!supplierUrlsafeKey,
        staleTime: 60000,
        retry: 3,
        retryDelay: 10000,
    });

    const { refetch: refetchSupplierID } = useQuery(['getSupplierID', supplierUrlsafeKey], () => getSupplierID(supplierUrlsafeKey), {
        enabled: false
    });

    const isDisabled: boolean = !!error || isLoading;

    useEffect(() => {
        if (supplier && controlledByBuyer && !hasCatalog) {
            getSupplierAttachments(supplier?.urlsafe)
                .then((attachments) => {
                    setAttachmentCount(attachments.length);
                })
                .catch((e) => {
                    console.warn(`Could not fetch attachments for supplier ${supplier?.urlsafe}\n${e}`);
                });
        }
    }, [supplier]);

    useEffect(() => {
        queryClient.invalidateQueries([REQUEST_SUPPLIER, supplierUrlsafeKey]);
    }, [isEditSupplierOpen]);

    function setSetupActionCounts(): void {
        setProductsCreated(productCount);
        setFilesUploaded(attachmentCount);
    }

    function getSetupButtonLabel(): string {
        if (controlledByBuyer && attachmentCount === 0) {
            return t('setUpForThisSupplier');
        } if (attachmentCount > 0) {
            return t('priceListSentToEmail');
        }

        return t('supplierGettingSetup');
    }

    function openEditNewSupplierModal(): void {
        setIsEditSupplierOpen(true);
    }

    async function handleRequestPriceList() : Promise<void> {
        setLoadingRequestPriceList(true);
        const { data: supplierID } = await refetchSupplierID();
        setSetupActions({ didRequestPriceList: true });
        await emailSupplier(supplierID, buyer.displayName, buyer.email).then(() => {
            setLoadingRequestPriceList(false);
            router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_COMPLETE}`);
        }).catch(() => {
            setLoadingRequestPriceList(false);
            toast.show({
                message: <>{t('errorRequestingPriceList')}</>,
            });
        });
    }

    return (
        <>
            {isLoading || isDisabled || !supplier
                ? <div className="flex flex-col gap-4 items-center pt-4 px-6 w-full">
                    <Skeleton className="h-5" size="full"/>
                    <Skeleton className="h-5" size="full"/>
                    <Skeleton className="h-5" size="full"/>
                    <div className="w-full h-px bg-gray-200 mt-2" />
                </div>
                : <>

                    {hasCatalog && <SupplierInfo supplier={supplier} />}
                    {(!hasCatalog && !isMobileWidth) && <Typography
                        as="div"
                        className={cx('px-6 text-gray-600 mb-6 mt-2', { hidden: hasCatalog })}>
                        {getSetupButtonLabel()}
                    </Typography>}
                    {(isMobileWidth && !hasCatalog) && <Typography className="px-6 text-gray-600 mb-6 mt-2">{t('setUpSupplierOnDesktop')}</Typography>}

                </>
            }
            {/* Buttons */}
            <div className="flex flex-col w-full mt-auto">
                <Separator className ="mt-6 mb-5" hidden={!hasCatalog}/>
                <div className="flex justify-center items-center w-full mt-auto">
                    <div className={cx(
                        'w-full px-6',
                        {
                            'flex gap-2': (isMobileWidth && hasCatalog) || (!isMobileWidth && !hasCatalog) || (!isMobileWidth && hasCatalog)
                        }
                    )}>
                        {(!isMobileWidth && !hasCatalog)
                            && <Button
                                variant="PRIMARY"
                                size={buttonSize}
                                className="w-full max-h-10 text-ellipsis overflow-hidden whitespace-pre hover:whitespace-nowrap hover:overflow-visible hover:pl-2.5"
                                onClick={(): void => {
                                    goToManageCatalog(supplier);
                                    sendTrackingEvent(TrackingEvents.setUpCatalogClicked);
                                }}>
                                <Typography as="span" weight="font-medium">
                                    {controlledByBuyer && attachmentCount === 0 ? t('setupCatalog') : t('uploadCatalog')}
                                </Typography>
                            </Button>}
                        {hasCatalog && <Button
                            variant="SECONDARY"
                            size={buttonSize}
                            className="w-full"
                            onClick={(): void => {
                                goToShop(catalogID);
                                sendTrackingEvent(TrackingEvents.shopSupplierClicked);
                            }}
                            disabled={isDisabled}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.shop')}
                            </Typography>
                        </Button>}
                        <div className="flex" >
                            <Button
                                variant="TERTIARY_FILLED"
                                size={iconSize}
                                hidden={!hasCatalog && controlledByBuyer }
                                className={cx('flex grow h-12 lg:h-10 items-center justify-center mr-2')}
                                onClick={(): void => {
                                    sendTrackingEvent(TrackingEvents.chatButtonClicked);
                                    goToChat(supplier);
                                }}
                                disabled={isDisabled}>
                                <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                    <ChatIcon title="chat" />
                                </div>
                            </Button>
                            <Popover
                                parentDivClassName="grow"
                                className="w-44"
                                buttonClassName="w-full"
                                button={<Button
                                    variant="TERTIARY_FILLED"
                                    size={iconSize}
                                    className="flex items-center justify-center w-full"
                                    stopPropagation={false}
                                    disabled={isDisabled || isLoading}>
                                    <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                        <MoreIcon title="..." />
                                    </div>
                                </Button>}
                                items={[
                                    {
                                        label: <div
                                            onClick={async (e):Promise<void> => {
                                                e.stopPropagation();
                                                await handleRequestPriceList();
                                            }}>
                                            <div className="flex gap-x-2">
                                                <span>{t('requestPriceList')}</span>
                                                {loadingRequestPriceList && <Loading isDark />}
                                            </div>

                                        </div>,
                                        stopPropagation: true,
                                        // onClick method is handled in the label listener to avoid the popover closing
                                        onClick: (): void => {
                                        },
                                        hidden: hasCatalog,
                                    },
                                    {
                                        label: t('manageCatalog'),
                                        onClick: (): void => {
                                            goToManageCatalog(supplier);
                                        },
                                        hidden: !controlledByBuyer || isMobileWidth || !hasCatalog,
                                    },
                                    {
                                        label: t('uploadCatalog'),
                                        onClick: (): void => {
                                            setSetupActionCounts();
                                            goToUploadAttachments(catalogID, supplier);
                                        },
                                        hidden: true
                                    },
                                    {
                                        label: t('editDetails'),
                                        onClick: (): void => {
                                            openEditNewSupplierModal();
                                        },
                                        hidden: !controlledByBuyer
                                    },
                                    {
                                        label: t('remove'),
                                        onClick: (): void => removeSupplier(supplierUrlsafeKey),
                                        className: 'text-red-300'
                                    },
                                ]}/>
                        </div>
                    </div>
                </div>
            </div>
            {isEditSupplierOpen && <EditSupplierModal isOpen={isEditSupplierOpen}
                supplierUrlsafeKey = {supplierUrlsafeKey}
                close={(): void => setIsEditSupplierOpen(false)}/>}
        </>
    );
};

export default withRouter(HomeSupplierCard);

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, ComboOption, Combobox, Modal, Separator, Typography, onMobileSetLarge } from '@notch-ordering/ui-components';
import { RutterSubsidiary, TaxCalculationSettings } from '@v2/network/BushwhackAPI';
import { t, tCommon } from '@v2/i18n';
import { EApPlatform, EConfigurationType, TaxCodeSchema } from '@notch-ordering/shared-logic';
import { integrationIcons, integrationNameKeys } from './IntegrationPlatformUtils';

export interface TaxSettingsProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onClose?: () => void,
    onSave?: (taxCalculationSettings: TaxCalculationSettings, taxCodeSelected: ComboOption, taxCodeNonTaxableSelected: ComboOption, subsidiarySelected?: ComboOption) => void,
    integration: EConfigurationType,
    taxCalculationSettingsDefault: TaxCalculationSettings,
    taxCodes: TaxCodeSchema[],
    taxCodeDefault: TaxCodeSchema,
    taxCodeNonTaxableDefault: TaxCodeSchema,
    subsidiaries?: RutterSubsidiary[],
    integrationPlatform: EApPlatform,
}

/**
 * Modal to allow for tax settings to be set during integration setup - Step 2/3
 */
const IntegrationsTaxSettingsModal : React.FC<TaxSettingsProps> = ({
    isOpen,
    setIsOpen,
    onClose,
    onSave,
    integration,
    taxCalculationSettingsDefault,
    taxCodes,
    taxCodeDefault,
    taxCodeNonTaxableDefault,
    subsidiaries,
    integrationPlatform,
}) => {
    const [taxCalculationSettings, setTaxCalculationSettings] = useState<TaxCalculationSettings>(taxCalculationSettingsDefault);
    const [taxCodeOptions, setTaxCodeOptions] = useState<ComboOption[]>([]);
    const [taxCodeSelected, setTaxCodeSelected] = useState<ComboOption>();
    const [taxCalculationSettingsOptions, setTaxCalculationSettingsOptions] = useState<ComboOption[]>([]);
    const [taxCodeNonTaxableSelected, setTaxCodeNonTaxableSelected] = useState<ComboOption>();
    const [subsidiaryOptions, setSubsidiaryOptions] = useState<ComboOption[]>([]);
    const [subsidiarySelected, setSubsidiarySelected] = useState<ComboOption>(null);

    const integrationType = t(`Integrations.${integrationNameKeys(integrationPlatform)}`);

    const TAX_CALCULATION_SETTINGS_OPTIONS = [{
        value: TaxCalculationSettings.ACCOUNTING,
        label: t('Accounting.taxCalculationSettings.accountingLabel'),
        helperText: t('Accounting.taxCalculationSettings.accountingHelperText')
    },
    {
        value: TaxCalculationSettings.CUSTOM,
        label: t('Accounting.taxCalculationSettings.customLabel'),
        helperText: t('Accounting.taxCalculationSettings.customHelperText')
    }];

    const TAX_CALCULATION_SETTINGS_OPTIONS_RUTTER = [{
        value: TaxCalculationSettings.ACCOUNTING,
        label: t('Accounting.taxCalculationSettings.accountingLabel'),
    }];

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const tcOptions: ComboOption[] = taxCodes.map((tc) => ({ label: tc.name, value: tc.id }));
        setTaxCodeOptions(tcOptions);

        if (integration === EConfigurationType.ApRutter) {
            setTaxCalculationSettingsOptions(TAX_CALCULATION_SETTINGS_OPTIONS_RUTTER);
        } else {
            const tcsOptions: ComboOption[] = TAX_CALCULATION_SETTINGS_OPTIONS.map((tcs) => ({ label: tcs.label, value: tcs.value }));
            setTaxCalculationSettingsOptions(tcsOptions);
        }

        if (subsidiaries?.length > 0) {
            const sOptions: ComboOption[] = subsidiaries.map((sub) => ({ label: sub.name, value: sub.id }));
            setSubsidiaryOptions(sOptions);
        } else {
            setSubsidiaryOptions([]);
        }
    }, [isOpen]);

    useEffect(() => {
        setTaxCodeSelected({ label: taxCodeDefault.name, value: taxCodeDefault.id });
    }, [taxCodeDefault]);

    useEffect(() => {
        setTaxCodeNonTaxableSelected({ label: taxCodeNonTaxableDefault.name, value: taxCodeNonTaxableDefault.id });
    }, [taxCodeNonTaxableDefault]);

    function onTaxCodeUpdate(option: ComboOption): void {
        setTaxCodeSelected(option);
    }

    function onTaxCodeNonTaxableUpdate(option: ComboOption): void {
        setTaxCodeNonTaxableSelected(option);
    }

    function onSubsidiaryUpdate(option: ComboOption): void {
        setSubsidiarySelected(option);
    }

    function onCloseModal(): void {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }

    function onSaveModal(): void {
        if (onSave) {
            onSave(taxCalculationSettings, taxCodeSelected, taxCodeNonTaxableSelected, subsidiarySelected);
        }

        onCloseModal();
    }

    return <Modal
        isOpen={isOpen}
        title={<div className="flex flex-row items-center ">
            <div className="flex items-center mr-4">{integrationIcons(integrationPlatform, 'w-6', 'h-6')}</div>
            <Typography weight="font-medium" className="m-0 mr-1">
                {t('Integrations.stepTwoOfThree')}
            </Typography>
            <Typography weight="font-medium" className="m-0 text-gray-600">
                {t('Integrations.accountSetup', { integrationType })}
            </Typography>
        </div>}
        hideCloseButton={true}
        close={onCloseModal}
        modalSize={onMobileSetLarge('MEDIUM')}
        desktopModalWidth="lg:w-[650px]"
        topPadding="pt-8"
        leftPadding="pl-10"
        rightPadding="pr-10" >
        <div className="flex flex-col justify-start pt-2.5 pb-6 px-10">
            <Button variant="ICON"
                size="NO_BACKGROUND"
                focus=""
                hoverColor=""
                activeColor=""
                className="text-left p-0 cursor-text"><Typography className="mb-2" variant="3XL" weight="font-bold">
                    {t('Integrations.configureTaxSettings')}
                </Typography></Button>
            <Typography className="text-gray-600 mb-10">
                {t('Integrations.selectTaxSetting', { integrationType })}
            </Typography>
            <div className="flex flex-col w-full pb-4">
                <div className="flex flex-row gap-x-3">
                    <div className="flex flex-col w-full">
                        <Typography className="mb-2" weight="font-medium">{tCommon('Labels.taxCode')}</Typography>
                        {taxCodeOptions.length > 0 && <Combobox
                            value={taxCodeDefault.id ? taxCodeDefault.id : (taxCodeOptions[0].value ?? '')}
                            width={'w-full'}
                            options={taxCodeOptions}
                            onChange={(option: ComboOption): void => { onTaxCodeUpdate(option); }} />}
                    </div>
                    <div className="flex flex-col w-full">
                        <Typography className="mb-2" weight="font-medium">{t('Integrations.nonTaxable')}</Typography>
                        {taxCodeOptions.length > 0 && <Combobox
                            value={taxCodeNonTaxableDefault.id ? taxCodeNonTaxableDefault.id : (taxCodeOptions[0].value ?? '')}
                            width={'w-full'}
                            options={taxCodeOptions}
                            onChange={(option: ComboOption): void => { onTaxCodeNonTaxableUpdate(option); }} />}
                    </div>
                </div>
                <Typography className="mt-6 mb-2" weight="font-medium">{t('Integrations.defaultTaxRate')}</Typography>
                {taxCalculationSettingsOptions.length > 0 && <Combobox
                    onChange={(v): void => setTaxCalculationSettings(TaxCalculationSettings[v.value])}
                    width={'w-full'}
                    options={taxCalculationSettingsOptions}
                    value={taxCalculationSettings}/>}
                <Typography className="text-gray-600 mt-1">{t('Integrations.chooseTaxConfiguration')}</Typography>
                {subsidiaryOptions.length > 0 && <Typography className="mt-6 mb-1" weight="font-medium">{'Subsidiary'}</Typography>}
                {subsidiaryOptions.length > 0 && <div className="">
                    <Combobox
                        variant="SMALL"
                        onChange={(subsidiaryOption): void => onSubsidiaryUpdate(subsidiaryOption)}
                        width={'w-full'}
                        options={subsidiaryOptions}
                        value={subsidiaryOptions[0].label ?? ''}/>
                </div>}
            </div>
        </div>
        <Separator />
        <div className="flex justify-end pt-0 mx-10">
            <Button className="mt-6"
                variant="SECONDARY"
                size="MEDIUM"
                onClick={onSaveModal}>
                {tCommon('Buttons.next')}
            </Button>
        </div>
    </Modal>;
};

export default withRouter(IntegrationsTaxSettingsModal);

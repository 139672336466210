import { toastr } from 'react-redux-toastr';
import { batch } from 'react-redux';
import * as types from '../constants/ActionTypes';
import * as DataAPI from '../api/DataAPI';
import * as Intercom from '../third-party/Intercom';
import * as Segment from '../third-party/Segment';
import * as accountActions from './accountActions';
import * as buyerActions from './buyerActions';
import * as vendorsActions from './vendorsActions';
import * as modalActions from './modalsActions';
import * as configActions from './configActions';
import * as routerActions from './routerActions';
import { initLibrariesAfterLogin } from '../helpers/initThirdPartyLibraries';
import { logException, startNewSession as startNewLoggingSession} from '../domains/shared/logger';
import { isAccountOwner, isAccountImpersonationEmpty } from '../selectors/accountSelectors';
import { useHomeStore } from '@v2/stores/HomeStore';
import { setupAxiosAuth } from '@v2/utils/AxiosUtils';

// Async Thunks
export const signin = (email, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SIGNIN_REQUEST });
      const response = await DataAPI.createSession(email, password);

      if (response && response.data) {
        dispatch({ type: types.SIGNIN_SUCCESS });

        // Assign sessionKey, refreshToken and session expirty to localStorage
        // Assign accessToken to global window variable.
        window.localStorage.setItem('chefhero_sk', response.data.urlsafe);
        window.localStorage.setItem('chefhero_rt', response.data.refreshToken);
        window.localStorage.setItem('chefhero_e', response.data.expiresAt);
        window.__accessToken__ = response.data.accessToken;

        // We need now to fetch the session to determine which path the app will go
        // depending on if we have a multiple buyers or just one for an account
        dispatch(accountActions.fetchSession());
      }
    } catch (error) {
      dispatch({ type: types.SIGNIN_FAILED });

      let errorMessage = '';
      window.localStorage.removeItem('chefhero_sk');
      window.localStorage.removeItem('chefhero_rt');
      window.localStorage.removeItem('chefhero_e');
      window.__accessToken__ = '';

      if (!error) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        // This means error comes from the xhr call
        if (error.response) {
          errorMessage =
            (((error || {}).response || {}).data || {}).message ||
            'Error Occurred, Please try again';
        } else {
          // This means the error comes directly from the try block (i.e JS error)
          errorMessage =
            (error || {}).message || 'Error Occurred, Please try again';
        }
      }

      toastr.error('Error', errorMessage);
      console.error('An Error occurred with signin');
      console.error(error);
      logException(error);
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: types.SIGNOUT_REQUEST });

      window.localStorage.removeItem('chefhero_sk');
      window.localStorage.removeItem('chefhero_rt');
      window.localStorage.removeItem('chefhero_e');
      window.localStorage.removeItem('chefhero_defb');

      dispatch({ type: types.SIGNOUT_SUCCESS });

      // Clear Intercom Session and reload page.
      // This will force you back to signin page.
      Intercom.clearSession();

      // Reset analytics
      Segment.reset();

      // Reset Logging session
      startNewLoggingSession();

      // pipe the search params (when available) to the route
      const searchParams = window.location ? window.location.search || '' : '';

      // Redirect to Chefhero sign-out page
      const route = `/account/signin/${searchParams}`;
      dispatch(routerActions.changeRoute(route));
      window.location.reload();
    } catch (error) {
      dispatch({ type: types.SIGNOUT_FAILED });

      let errorMessage = '';

      if (!error) {
        errorMessage =
          'Detected a connection problem, please refresh this page';
      } else {
        errorMessage = (error || {}).message || 'Please try again';
      }

      toastr.error(`Error: ${errorMessage}`);
      console.error('An Error occured with signin');
      console.error(error);
      logException(error);
    }
  };
};

/**
 * Post Sign in Actions
 * After you sign in, we must call a few setup endpoints to make the app usable.
 * 1) Call fetchSession to renew the access token Only if we don't have one setup yet.
 *    This is required for Impersonation and autlogging in the user.
 * 2) Show a loader and call config, members and balance. They are not required for immediate use in the UI,
 *    so we load them at our leisure and don't wait for them.
 * 3) The buyer and vendors that are required for usage in the app. We wait until they are done to show the oader
 */
export const postSignin = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.IS_LOADING_APP_INIT, isLoadingAppInit: true });

      // NOTE: See point # 1 above on in comments why we do this.
      if (!window.__accessToken__) {
        await dispatch(accountActions.fetchSession());
      }

      setupAxiosAuth();

      batch(() => {
        dispatch(configActions.loadAPIConfig()); // Needed for setup variables
        dispatch(buyerActions.loadBuyerMembers()); // Needed to show multibuyers on top bar
      });

      const useCache = true;
      const promises = [dispatch(buyerActions.loadBuyer())];
      const queryParams = new URLSearchParams(
        new URL(window.location.href).search
      );

      if (queryParams.has('vendorId')) {
        promises.push(
          dispatch(
            vendorsActions.loadVendors(true, queryParams.get('vendorId'))
          )
        );
      } else {
        promises.push(dispatch(vendorsActions.loadVendors(useCache)));
      }

      await Promise.all(promises);
      const { account, impersonator } = getState().accountReducer;
      const { buyer } = getState().buyerReducer;
      const { vendors } = getState().vendorsReducer;
      initLibrariesAfterLogin(buyer, account, impersonator, vendors);

      useHomeStore.persist.clearStorage();

      // Libraries need to load before the UI is rendered because
      // analytics events are dispatched from component lifecycles and libraries need to be loaded at that time
      batch(() => {
        // The Add multiple suppliers modal needs to be displayed for existing buyers that have not gone through this flow
        if (isAccountOwner(getState()) && isAccountImpersonationEmpty(getState())) {
            dispatch(
                modalActions.showAddMultipleSuppliersModal(
                !buyer.hasSuppliersSetup
            ));
        }

        dispatch({ type: types.IS_LOADING_APP_INIT, isLoadingAppInit: false });
      });
    } catch (error) {
      console.error('error occurred in postSignin');
      console.error(Error);
      logException(error);
    }
  };
};

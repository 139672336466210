import { Button, Container, DataGridCheckbox, GridColDef, Loading, NotchDataGrid, Separator, Tooltip, Typography } from '@notch-ordering/ui-components';
import { tNamespace } from '@v2/i18n';
import React, { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChatIcon from '@icons/chat-icon.svg';
import EditPencilIcon from '@icons/edit-pencil-icon.svg';
import { formatInTimeZone } from 'date-fns-tz';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { useQuery } from '@tanstack/react-query';
import { Order, getOrders } from '@v2/network/LegacyAPI';
import { AddBillModal } from '@v2/components/Bills/AddBillModal/AddBillModal';
import Router, { browserHistory } from 'react-router';
import { isValid } from '@v2/utils/isValid';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { useDispatch } from 'react-redux';
import { TrackingEvents } from '@v2/constants/Tracking';
import cx from 'classnames';
import useRolesHook from '@v2/hooks/useRolesHook';
import { ERoleName } from '@v2/types/OrgData';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { useOrdersStore } from '@v2/stores/OrdersStore';
import Utils from '@/utils';
import { OrdersEmpty } from './OrdersEmpty';
import { OrderRowMoreButton } from './OrdersRowMoreButton';
import { OrdersFilters } from './OrdersFilters';
import { createOrSelectGeneralChatChannelInStore } from '@/domains/Chat/actions/chatSelectedActions';
import { changeRoute } from '@/actions/routerActions';
import { OrdersMobileTableCell } from './OrdersMobileTableCell';
import { GenerateBillModal } from './GenerateBillModal';
import { BillGeneratedModal } from './BillGeneratedModal';
import { AttachBillButton } from './AttachBillButton';

type Props = {
    router?: Router,
};

const DEFAULT_PAGE_SIZE = 20;
const FETCH_ORDERS_QUERY = 'FETCH_ORDERS_QUERY';

export const Orders: React.FC<Props> = ({ router }) => {
    const [showAddBillModal, setShowAddBillModal] = useState<boolean>(false);
    const [showGenerateBillModal, setShowGenerateBillModal] = useState<boolean>(false);
    const [showBillGeneratedModal, setShowBillGeneratedModal] = useState<boolean>(false);
    const [messageToolTip, setMessageToolTip] = useState<boolean>(false);
    const [editToolTip, setEditToolTip] = useState<boolean>(false);
    const [invoiceID, setInvoiceID] = useState<string>('');
    const [showEmpty, setShowEmpty] = useState<boolean>(false);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [orderUrlsafeKeyRow, setOrderUrlsafeKeyRow] = useState<string>('');
    const [rowOrderData, setRowOrderData] = useState<Order>(undefined);
    const isMobileWidth = useIsMobileWidth();
    const [page, setPage] = useState<number>(0);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const dispatch = useDispatch();
    const { hasRole } = useRolesHook();
    const { buyer } = useBuyerHook();
    const { filters } = useOrdersStore();

    const { data: orderResponse, isLoading: areOrdersLoading, refetch } = useQuery(
        [FETCH_ORDERS_QUERY, buyer.urlsafe, page, filters],
        () => getOrders({
            deliveryDayStart: filters.deliveryDayStart,
            deliveryDayEnd: filters.deliveryDayEnd,
            sortBy: filters.sortBy?.value,
            id: filters.id,
            vendorID: filters.vendorID,
            limit: DEFAULT_PAGE_SIZE,
            offset: page * DEFAULT_PAGE_SIZE
        }),
        {
            onSuccess: (data) => setTotalRows(data.totalCount),
            onSettled: () => setIsInitialLoad(false)
        }
    );

    useQuery([FETCH_ORDERS_QUERY, buyer.urlsafe], () => getOrders({ limit: 1 }), {
        onSuccess: (data) => setShowEmpty(data.totalCount === 0),
        onError: () => setShowEmpty(true),
    });

    const rows = orderResponse?.data;
    const headerClassName = 'text-gray-600 font-body text-med';
    const cellClassName = 'text-gray-700 font-body text-med';

    const commonGridDef: GridColDef = {
        field: '',
        headerAlign: 'left',
        headerClassName,
        cellClassName,
        align: 'left',
    };

    const onClickChat = (rowData: Order): void => {
        dispatch(
            createOrSelectGeneralChatChannelInStore({
                customerUrlsafe: rowData.customerUrlsafe,
                vendorUrlsafe: rowData.vendorUrlsafe,
                vendorName: rowData.vendorName,
            })
        );
        dispatch(changeRoute('/inbox/'));
    };

    const ORDERS_COLUMNS = {
        id: 'id',
        deliveryDay: 'deliveryDay',
        vendorName: 'vendorName',
        total: 'total',
        attachBill: 'attachBill',
        messageEditButtons: 'messageEditButtons',
        more: 'more',
        mobileOnly: 'mobileOnly',
    } as const;

    const getOrdersTableColumns = ():GridColDef<Order>[] => [
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.id,
            headerName: 'Order #',
            minWidth: 150,
            flex: 1,
            sortable: false,
        },
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.deliveryDay,
            sortable: false,
            headerName: 'Delivery day',
            flex: 0.5,
            minWidth: 100,
            valueFormatter: ({ value }): string => {
                if (isValid(value)) {
                    const date = new Date(value);
                    // Delivery date is always stored as midnight in UTC, it is expected to be displayed
                    // as the exact date given with no timezone conversion
                    const formatted = formatInTimeZone(date, 'UTC', 'MMM dd, yyyy');
                    return formatted;
                }

                return '--';
            }
        },
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.vendorName,
            sortable: false,
            headerName: 'Supplier',
            flex: 1,
            minWidth: 110,
        },
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.total,
            sortable: false,
            headerName: 'Estimated total',
            flex: 0.5,
            minWidth: 120,
            valueFormatter: ({ value }): string => {
                if (typeof value === 'number') {
                    return Utils.formatAsCurrency(value);
                }

                return '--';
            }
        },
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.attachBill,
            sortable: false,
            headerName: 'Attach bill',
            cellClassName: 'overflow-visible',
            minWidth: 80,
            renderCell: ({ row: order }): JSX.Element => <>
                <AttachBillButton onClick={():void => {
                    setOrderUrlsafeKeyRow(order.urlsafe);
                    setShowAddBillModal(true);
                }}
                orderImages={order?.images} />

            </>
        },
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.messageEditButtons,
            sortable: false,
            width: 80,
            headerClassName: 'hidden',
            cellClassName: 'group overflow-visible',
            align: 'right',
            renderCell: ({ row: order }): JSX.Element => {
                // This is taken directly from the previous OrderHistory page that was written 8 years ago.
                const enableEditButton = !Utils.isAfterDeliveryDayCutOffTime({ name: order.vendorName }, order) // For some reason all it wants is a non-empty object for vendor
                    && !order.isAddOnOrder
                    && !(order.isReplacementOrder || order.isShortedOrder)
                    && !order.deliveryConfirmedBySupplierAt
                    && !order.isVendorEmailSent
                    && !['paid', 'invoiced', 'delivered'].includes(order.orderBuyerStatus.toLowerCase());
                return <div className={cx('flex invisible group-hover:visible', {
                    'gap-x-2': enableEditButton
                })}>
                    <Tooltip
                        show={messageToolTip}
                        className="flex"
                        tooltipClassName={'py-1 px-2 rounded-lg'}
                        showArrow={false}
                        onShow ={(): void => { setMessageToolTip(true); }}
                        onHide ={(): void => { setMessageToolTip(false); }}
                        placement="top"
                        trigger={<Button className="bg-white justify-center items-center p-0 min-w-[32px] w-8 h-8 flex "
                            variant="TERTIARY_OUTLINED"
                            size="ICON_SMALL"
                            onClick={(): void => {
                                sendTrackingEvent(TrackingEvents.chatButtonClicked);
                                onClickChat(order);
                            }}><ChatIcon className="w-4 h-4" /></Button>}>
                        {t('chat')}
                    </Tooltip>
                    <Tooltip
                        show={editToolTip}
                        className="flex"
                        tooltipClassName={'py-1 px-2 rounded-lg'}
                        showArrow={false}
                        onShow ={(): void => { setEditToolTip(true); }}
                        onHide ={(): void => { setEditToolTip(false); }}
                        placement="top"
                        trigger={<Button className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                            variant="TERTIARY_OUTLINED"
                            size="ICON_SMALL"
                            hidden={!enableEditButton}
                            onClick={(): void => {
                                if (hasRole([ERoleName.OWNER, ERoleName.MANAGER])) {
                                    browserHistory.push(`/checkout/pending/${order.urlsafe}/`);
                                }
                            }}><EditPencilIcon className="w-4 h-4" /></Button>}>
                        {t('edit')}
                    </Tooltip>
                </div>;
            }
        },
        {
            ...commonGridDef,
            field: ORDERS_COLUMNS.more,
            sortable: false,
            cellClassName: 'overflow-visible group', // This must be set for the popover to be visible
            width: 38,
            headerClassName: 'hidden',
            renderCell: ({ row: order }): JSX.Element => (<div onClick={(e): void => e.stopPropagation()}>
                <OrderRowMoreButton row={order}
                    openAddBillsModal={(): void => {
                        setOrderUrlsafeKeyRow(order.urlsafe);
                        setShowAddBillModal(true);
                    }}
                    openGenerateBillsModal={(): void => {
                        setRowOrderData(order);
                        setShowGenerateBillModal(true);
                    }} />
            </div>)
        },
        {
            ...commonGridDef,
            headerName: '',
            sortable: false,
            flex: 1,
            field: ORDERS_COLUMNS.mobileOnly,
            cellClassName: 'overflow-visible',
            headerClassName: 'hidden',
            renderCell: ({ row: order }): ReactNode => <OrdersMobileTableCell ordersData={order}
                openAddBillsModal={(): void => { setShowAddBillModal(true); }}
                openGenerateBillsModal={(): void => { setShowGenerateBillModal(true); }}
                orderUrlsafeKey={(): void => { setOrderUrlsafeKeyRow(order.urlsafe); }}
                row={(): void => { setRowOrderData(order); }}
                onClick={():void => {
                    setShowAddBillModal(true);
                    setOrderUrlsafeKeyRow(order.urlsafe);
                }} />

        }

    ];

    const getColumnsVisibilityModel = (): Record<string, boolean> => {
        const {
            id,
            deliveryDay,
            vendorName,
            total,
            attachBill,
            messageEditButtons,
            more,
            mobileOnly,
        } = ORDERS_COLUMNS;

        // if keys are not present in the object by default, all the columns are visible.
        const desktopVisibilityModel = {
            [mobileOnly]: false,
        };

        // if a new column is added to the mobile view, it should be added here if it is not present in the mobile view by default.
        const mobileVisibilityModel = {
            [id]: false,
            [deliveryDay]: false,
            [vendorName]: false,
            [total]: false,
            [attachBill]: false,
            [messageEditButtons]: false,
            [more]: false,
            [mobileOnly]: true
        };

        return isMobileWidth ? mobileVisibilityModel : desktopVisibilityModel;
    };

    return (<> {isInitialLoad
        ? <Loading isDark className="mt-8"/>
        : <>
            {showEmpty && <OrdersEmpty router={router} />}
            {!showEmpty && <Container fluid className={'bg-white pb-safe lg:px-10'}>
                <Container className="flex flex-col px-0 lg:pb-10 pb-28">
                    <div className="flex justify-between items-center lg:px-0 px-4">
                        {/* Header */}
                        <Typography
                            as="h1"
                            variant="6XL"
                            size="text-7"
                            weight="font-medium"
                            className="text-gray-700 mb-0 mt-0 lg:pt-10 pt-8 lg:pb-8 pb-4">
                            {t('orders')}
                        </Typography>
                    </div>
                    {/* Orders Search and filters to be added */}
                    <div className="pb-6 lg:px-0 px-4"><OrdersFilters /></div>
                    {isMobileWidth && <Separator />}
                    <NotchDataGrid
                        autoHeight
                        disableSelectionOnClick
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        sortingMode={'server'}
                        getRowId={(row: Order): number => row.id}
                        rows={rows || []}
                        rowCount={totalRows}
                        rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                        onRowClick={({ row: order }): void => browserHistory.push(`/order_detail/${order.urlsafe}`)}
                        page={page}
                        pageSize={DEFAULT_PAGE_SIZE}
                        columns={getOrdersTableColumns()}
                        columnVisibilityModel={getColumnsVisibilityModel()}
                        rowHeight={isMobileWidth ? 124 : 61}
                        loading={areOrdersLoading}
                        paginationMode="server"
                        onPageChange={(newPage): void => {
                            setPage(newPage);
                        }}
                        components={{
                            BaseCheckbox: DataGridCheckbox
                        }}
                        sx={{
                            '& .MuiDataGrid-virtualScroller': {
                                // hiding the headers in mobile to avoid extra space in the top of the grid
                                marginTop: isMobileWidth ? '0 !important' : '56px',
                                // These are the same as in NotchDataGrid since sx does not do a deep merge
                                overflowY: 'visible !important',
                                minHeight: '500px'
                            },
                            // column headers if mobile hide
                            '& .MuiDataGrid-columnHeaders': {
                                display: isMobileWidth ? 'none' : 'flex'
                            },
                            '& .MuiDataGrid-row.Mui-selected:hover, & .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            },
                        }}/>
                </Container>
                {showGenerateBillModal && <GenerateBillModal isOpen={showGenerateBillModal}
                    close={(): void => {
                        setShowGenerateBillModal(false);
                    }}
                    onSuccess={(generatedInvoiceID): void => {
                        setInvoiceID(generatedInvoiceID);
                        setShowBillGeneratedModal(true);
                    }}
                    row={rowOrderData}/>}
                {showBillGeneratedModal && <BillGeneratedModal
                    isOpen={showBillGeneratedModal}
                    close={(): void => { setShowBillGeneratedModal(false); }}
                    orderID={rowOrderData.id}
                    invoiceID={invoiceID} />}
                { showAddBillModal && <AddBillModal
                    closeModal={(): void => {
                        setShowAddBillModal(false);
                        refetch();
                    }}
                    isOpen={showAddBillModal}
                    orderUrlsafeKey={orderUrlsafeKeyRow}/>}
            </Container>}
        </>}
    </>);
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Utils from '../../utils';

export default class QuantityChanger extends Component {
  state = {
    quantity: 0,
  };

  componentDidMount() {
    this.setState({ quantity: this.props.quantity });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ quantity: nextProps.quantity });
  }

  typedQuantityChange = (event) => {
    const oldQuantity = event.target.value;
    const quantity = Utils.changeQuantityHelper(oldQuantity, Utils.QUANTITY_TYPES.CUSTOM);

    this.setState({ quantity });
  };

  changeQuantity = (event, quantity, type) => {
    this.props.changeQuantity(event, quantity, type);
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.changeQuantity(event, this.state.quantity, Utils.QUANTITY_TYPES.CUSTOM);
    }
  };

  onFocusQuantity = () => {
    // Remove any old quantity value
    this.setState({ quantity: '' });
  };

  onBlurQuantity = (event) => {
    if (this.state.quantity === '') {
      // Keet the original quantity if there is no value in the input box
      this.setState({ quantity: this.props.quantity });
      return;
    }

    this.changeQuantity(event, this.state.quantity, Utils.QUANTITY_TYPES.CUSTOM);
  };

  render() {
    const { className, isDisabled, disabledText } = this.props;

    const finalClassName = `quantity-changer ${className}`;

    let quantity = this.state.quantity === 0 ? 'Quantity' : this.state.quantity;
    if (isDisabled) {
      quantity = disabledText;
    }

    return (
      <div className={finalClassName}>
        <span className="input-group-btn">
          <button
            onClick={(e) =>
              this.changeQuantity(e, this.state.quantity, Utils.QUANTITY_TYPES.DECREASE)
            }
            type="button"
            className="btn btn-primary btn-number"
            disabled={isDisabled || this.props.isLoading}
          >
            <span className="glyphicon glyphicon-minus"></span>
          </button>
        </span>
        <input
          type="text"
          name="quantity"
          className="input form-control input-quantity"
          onChange={this.typedQuantityChange}
          onBlur={this.onBlurQuantity}
          onFocus={this.onFocusQuantity}
          value={quantity}
          disabled={isDisabled}
          style={{ textAlign: 'center' }}
          onKeyPress={this.handleKeyPress}
        />
        <span className="input-group-btn input-group-btn-right">
          <button
            onClick={(e) =>
              this.changeQuantity(e, this.state.quantity, Utils.QUANTITY_TYPES.INCREASE)
            }
            type="button"
            className="btn btn-primary btn-number"
            disabled={isDisabled || this.props.isLoading}
          >
            <span className="glyphicon glyphicon-plus"></span>
          </button>
        </span>
      </div>
    );
  }
}

QuantityChanger.propTypes = {
  className: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  disabledText: PropTypes.string,
  changeQuantity: PropTypes.func.isRequired,
  quantity: PropTypes.number.isRequired,
};

QuantityChanger.defaultProps = {
  isDisabled: false,
  isLoading: false,
  disabledText: '',
};

import React from 'react';
import FavoriteEmptyIcon from '@icons/favorite-empty-icon.svg';
import FavoriteIcon from '@icons/favorite-icon.svg';

export interface Props {
    checked?: boolean,
    className?: string,
}

export const FavoriteCheckBox: React.FC<Props> = ({ checked = false }) => (
    <>
        {!checked && <FavoriteEmptyIcon className="lg:w-4 lg:h-4 w-5 h-5"/>}
        {checked && <FavoriteIcon className="lg:w-4 lg:h-4 w-5 h-5"/>}
    </>
);

import { Banner, Button, EBannerType, Modal, Separator, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import RightArrowIcon from '@icons/arrow-right-icon.svg';
import { EConfigurationType, EErpPlatform, getLineItems, getTotals, OcrInvoice, OcrMatchedLineItem } from '@notch-ordering/shared-logic';
import { t, tCommon } from '@v2/i18n';
import { ESyncDataType } from '@v2/network/BushwhackAPI';
import { Tabs, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategory } from '@v2/Pages/Integrations/IntegrationsUtils';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';
import { integrationNameKeys } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import InfoIcon from '@icons/info-icon.svg';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import Utils from '@/utils';

type SyncConfirmationProps = {
    draftInvoice: OcrInvoice,
    isOpen: boolean,
    disabled: boolean,
    setIsLoadingNext: () => void,
    setIsLoadingSync: () => void,
    isLoadingSync: boolean,
    isLoadingNext: boolean,
    invoiceData?: InvoiceData,
    isAtLeastPartiallySynced: boolean,
    close: () => void,
    syncOnClick?: (shouldNavigateToNextScanAfterSync: boolean) => void,
};

export const SyncConfirmationModal: React.FC<SyncConfirmationProps> = ({
    draftInvoice,
    disabled,
    setIsLoadingNext,
    setIsLoadingSync,
    isLoadingSync,
    isLoadingNext,
    isOpen = false,
    invoiceData,
    isAtLeastPartiallySynced,
    close,
    syncOnClick,
}) => {
    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const syncTypeChartOfAccount = draftInvoice?.accountingConfiguration?.data?.syncDataType === ESyncDataType.CHART_OF_ACCOUNT_DETAILS;
    const chartOfAccountDetailsEnabled = ((selectedTab === Tabs.ACCOUNTING || selectedTab === Tabs.NO_TABS) && syncTypeChartOfAccount);
    const syncTypeDesc = chartOfAccountDetailsEnabled ? t('ScanReview.ChartOfAccountDetails.chartOfAccountDetailsNoCap') : t('ScanReview.LineItems.lineItemsNoCap');
    const imsEnabled = selectedTab === Tabs.IMS || (selectedTab === Tabs.NO_TABS && hasImsIntegration);
    const imsName = t(`Integrations.${integrationNameKeys(EErpPlatform.OPTIMUMCONTROL)}`);
    const accountingName = t(`Integrations.${integrationNameKeys(draftInvoice?.accountingConfiguration?.data?.platform)}`);
    const platform = imsEnabled ? imsName : accountingName;
    const invoiceNumber = draftInvoice?.invoiceData?.externalReferenceID;
    const supplier = draftInvoice?.supplierName;

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, false, hasImsIntegration);
    const invoiceTotals = getTotals(draftInvoice, lineItemCategory, useSeparateIMSLineItems);
    const invoiceLineItems = getLineItems(draftInvoice, lineItemCategory, useSeparateIMSLineItems);
    const bigChipEnabled = draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip;
    const isBigChip = bigChipEnabled && selectedTab !== Tabs.IMS;
    const accountingEnabled = draftInvoice?.accountingConfiguration?.isEnabled;
    const taxDisplayPurposesOnly = (selectedTab === Tabs.ACCOUNTING && !isBigChip) || (selectedTab === Tabs.NO_TABS && accountingEnabled && !isBigChip);

    return (
        <Modal
            hideHeader
            overflowAuto
            modalSize={'MEDIUM'}
            desktopModalWidth="lg:w-[498px]"
            isOpen={isOpen}
            close={close}>
            <>
                <div className="flex flex-col items-start mt-6 w-full h-full">
                    <div className="mx-8">
                        <div className="flex justify-center items-center w-10 h-10 rounded-[10px] bg-green-100">
                            <RightArrowIcon className="w-4 h-4 text-green-500 m-0 p-0" />
                        </div>
                        <Typography variant="2XL"
                            weight="font-bold"
                            className="pb-2 pt-5 m-0">
                            {isBigChip ? t('ScanReview.approveBill') : t('ScanReview.syncBillTo', { platform })}
                        </Typography>
                        <Typography variant="LG-1"
                            weight="font-regular"
                            className="text-gray-600 m-0">
                            {t('ScanReview.billFromSupplier', { invoiceNumber, supplier })}
                        </Typography>
                    </div>
                    <Separator className="mt-8" />
                    <div className="w-full px-8 overflow-auto pb-20">
                        <Typography variant="LG-2"
                            weight="font-semibold"
                            className="m-0 mb-1 mt-8">
                            {isBigChip ? t('ScanReview.toBeApproved') : t('ScanReview.toBeSynced')}
                        </Typography>
                        <Typography variant="LG-1"
                            weight="font-regular"
                            className="m-0 mb-7 text-gray-600">
                            {isBigChip ? t('ScanReview.willBeApproved', { syncTypeDesc }) : t('ScanReview.willBeSynced', { syncTypeDesc })}
                        </Typography>
                        {invoiceLineItems?.map((lineItem: OcrMatchedLineItem, i: number) => {
                            const subTotal = lineItem.primaryItemData.total - lineItem.primaryItemData.tax.dollarAmount;
                            return (<div key={i} className="flex flex-col">
                                <div className="flex flex-col">
                                    <div className="flex flex-row justify-between">
                                        <Typography className="text-gray-600 m-0">{lineItem?.primaryItemData?.name}</Typography>
                                        <Typography className="text-gray-600 m-0">{Utils.formatAsCurrency(subTotal)}</Typography>
                                    </div>
                                    <Separator className="my-3"/>
                                </div>
                            </div>
                            );
                        })}
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-between">
                                <Typography className="m-0">{tCommon('Labels.subTotal')}</Typography>
                                <Typography className="m-0">{Utils.formatAsCurrency(invoiceTotals.subtotal)}</Typography>
                            </div>
                            <Separator className="my-3"/>
                        </div>
                        <div className="flex flex-col">
                            <div className = "flex flex-col">
                                <div className="flex flex-row justify-between">
                                    <Typography className="m-0">{tCommon('Labels.tax')}</Typography>
                                    <Typography className="m-0">{Utils.formatAsCurrency(invoiceTotals.tax)}</Typography>
                                </div>
                                {taxDisplayPurposesOnly && <Typography className="text-gray-600 m-0">{t('ScanReview.displayPurposes')}</Typography>}
                            </div>
                            <Separator className="my-3"/>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex flex-row justify-between">
                                <Typography weight="font-medium" className="m-0">{tCommon('Labels.total')}</Typography>
                                <Typography weight="font-medium" className="m-0 mb-8">{Utils.formatAsCurrency(invoiceTotals.total)}</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky bottom-0 bg-white">
                    { (invoiceData && !isAtLeastPartiallySynced && !bigChipEnabled) && <div className="w-full mb-4 px-8">
                        <Banner alertType={EBannerType.WARNING}
                            body={<Typography as="div" className="text-gray-600">
                                {`Bill #${invoiceNumber} already exists. Please verify you want to re-sync this Bill, otherwise change the Invoice # to be unique.`}
                            </Typography>}
                            icon={<InfoIcon className="w-4 h-4 text-orange-300"/>}
                            isDismissable={false}/>
                    </div>
                    }
                    <Separator/>
                    <div className="flex flex-row justify-between px-8 pt-5 w-full">
                        <Button
                            variant="TERTIARY_FILLED"
                            size="SMALL"
                            onClick={close}
                            disabled={isLoadingNext || isLoadingSync}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.cancel')}
                            </Typography>
                        </Button>
                        <div className="flex flex-row">
                            <Button onClick={(): void => {
                                syncOnClick(true);
                                setIsLoadingNext();
                                sendTrackingEvent({ ...TrackingEvents.finalSyncButtonClicked, label: TrackingLabel.OCR });
                            }}
                            className="mr-4"
                            loading={isLoadingNext}
                            disabled={disabled}
                            variant="TERTIARY_FILLED"
                            size="SMALL">
                                {isBigChip ? t('ScanReview.approveAndReviewNextBill') : t('ScanReview.syncAndReviewNextBill')}
                            </Button>
                            <Button onClick={(): void => {
                                syncOnClick(false);
                                setIsLoadingSync();
                                sendTrackingEvent({ ...TrackingEvents.finalSyncButtonClicked, label: TrackingLabel.OCR });
                            }}
                            loading={isLoadingSync}
                            disabled={disabled}
                            variant="PRIMARY"
                            size="SMALL">
                                {isBigChip ? t('ScanReview.approveBill') : t('ScanReview.syncBill')}
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        </Modal>);
};

import { Typography } from '@notch-ordering/ui-components';
import React from 'react';

import Utils from '../../../../../utils';

const OrderItem = ({
  genericItem: { imageURL = '' } = {},
  name,
  unitName,
  unitDescription,
  price,
  quantity,
}) => (
  <div className="OrderItem">
    <div
      className="OrderItem__image"
      style={{ backgroundImage: `url(${imageURL})` }}
    >
      <span className="OrderItem__quantity">{quantity}</span>
    </div>

    <div className="OrderItem__name-and-unit">
      <Typography  weight='font-semibold' className="OrderItem__name">{name}</Typography>
      <Typography className="OrderItem__unit">
        {unitName}
        {unitDescription && unitName && ' - '}
        {unitDescription && `${unitDescription}`}
      </Typography>
    </div>

    <Typography className="OrderItem__price">
      {price && !isNaN(price) ? Utils.formatAsCurrency(price * quantity) : '-'}
    </Typography>
  </div>
);

export default OrderItem;

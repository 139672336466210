import React, { useEffect, useState } from 'react';
import ShoppingBagIcon from '@icons/shopping-bag-icon.svg';
import ShoppingBagActiveIcon from '@icons/shopping-bag-active-icon.svg';
import ShoppingCartIcon from '@icons/shopping-cart-icon.svg';
import ShoppingCartActiveIcon from '@icons/shopping-cart-active-icon.svg';
import HouseIcon from '@icons/house-icon.svg';
import HouseActiveIcon from '@icons/house-active-icon.svg';
import CameraIcon from '@icons/camera-icon.svg';
import CameraIconFilled from '@icons/camera-icon-filled.svg';
import MenuIcon from '@icons/menu-icon.svg';
import MenuNotificationIcon from '@icons/menu-notification-icon.svg';
import TicketIcon from '@icons/ticket-icon.svg';
import TicketActiveIcon from '@icons/ticket-active-icon.svg';
import MessagesIcon from '@icons/message-icon.svg';
import MessagesActiveIcon from '@icons/message-active-icon.svg';
import MessagesUnreadIcon from '@icons/message-unread-icon.svg';
import MessagesUnreadActiveIcon from '@icons/message-unread-active-icon.svg';
import HelpIcon from '@icons/help-icon.svg';
import SettingsIcon from '@icons/setting-icon.svg';
import SettingsActiveIcon from '@icons/setting-active-icon.svg';
import AccountIcon from '@icons/account-icon.svg';
import AccountActiveIcon from '@icons/account-active-icon.svg';
import MarkerIcon from '@icons/marker-icon.svg';
import SelectIcon from '@icons/select-icon.svg';
import ReceiptIcon from '@icons/receipt-icon.svg';
import ReceiptActiveIcon from '@icons/receipt-active-icon.svg';
import NavLink from '@v2/components/Shared/NavLink/NavLink';
import { BuyerSwitcherMobile } from '@v2/components/Shared/BuyerSwitcherMobile/BuyerSwitcherMobile';
import { EPaths, ESettingsPaths } from '@v2/constants/EPaths';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Separator, Typography } from '@notch-ordering/ui-components';
import { HELP_DESK } from '@v2/constants/ExternalUrls';
import { RootState } from '@v2/index';
import IntercomMobile from '@v2/components/ThirdParty/Intercom/IntercomMobile';
import useRolesHook from '@v2/hooks/useRolesHook';
import { ERoleName } from '@v2/types/OrgData';
import { showCart } from '@/actions/cartActions';

function NavLinkContentWrapper({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
    return <div className="flex flex-row gap-4 items-center">
        {children}
    </div>;
}

type Props = {
    locationName?: string,
    location?: Location,
};

export const BottomNavigation: React.FC<Props> = ({ locationName = '', location = { pathname: '' } }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Navigation' });
    const [isMoreActive, setIsMoreActive] = useState<boolean>(false);
    const [settingsActive, setSettingsActive] = useState<boolean>(false);
    const { roles, hasRole } = useRolesHook();
    const [isSwitcherOpen, setIsSwitcherOpen] = useState<boolean>(false);
    const { channels: { unreadMessagesCount }, } = useSelector((store: RootState) => store.chat);
    const { isCartOpen = false } = useSelector((store: RootState) => store.cartReducer);

    function getMessagesIcon(isMessagesActive = false): JSX.Element {
        if (isMessagesActive) {
            return unreadMessagesCount > 0 ? <MessagesUnreadActiveIcon /> : <MessagesActiveIcon />;
        }
        return unreadMessagesCount > 0 ? <MessagesUnreadIcon /> : <MessagesIcon />;
    }

    useEffect(() => {
        setSettingsActive(hasRole([ERoleName.MANAGER, ERoleName.OWNER], false));
    }, [roles]);

    return <div className="fixed bottom-0 z-50 w-full flex flex-col gap-4 pointer-events-none">
        <div className="pr-4 flex justify-end bg-transparent">
            <div className="pointer-events-auto"><IntercomMobile location={location} /></div>
        </div>
        <nav className="bg-white pb-safe pointer-events-auto">
            <Separator className="bg-gray-300" />
            <div className="grid grid-cols-5 justify-items-center">
                <NavLink
                    to={EPaths.HOME}
                    key={t('Home')}
                    isActive={location.pathname.includes(EPaths.HOME) && !isCartOpen}
                    onClick={(): void => {
                        setIsMoreActive(false);
                    }}
                    className="w-full justify-center">
                    <div className="flex flex-col gap-1 items-center">
                        <div className="w-4 h-4 flex">
                            {location.pathname.includes(EPaths.HOME) && !isCartOpen ? <HouseActiveIcon /> : <HouseIcon />}
                        </div>
                        <div className="text-1">{t('Home')}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={EPaths.SHOP}
                    key={t('Shop')}
                    isActive={location.pathname.includes(EPaths.SHOP) && !isCartOpen}
                    onClick={(): void => {
                        setIsMoreActive(false);
                    }}
                    className="w-full justify-center">
                    <div className="flex flex-col gap-1 items-center">
                        <div className="w-4 h-4 flex">
                            {location.pathname.includes(EPaths.SHOP) && !isCartOpen ? <ShoppingBagActiveIcon /> : <ShoppingBagIcon />}
                        </div>
                        <div className="text-1">{t('Shop')}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={EPaths.UPLOAD}
                    key={t('Upload')}
                    isActive={location.pathname.includes(EPaths.UPLOAD) && !isCartOpen}
                    onClick={(): void => {
                        setIsMoreActive(false);
                    }}
                    className="w-full justify-center">
                    <div className="flex flex-col gap-1 items-center">
                        <div className="w-4 h-4 flex">
                            {location.pathname.includes(EPaths.UPLOAD) && !isCartOpen ? <CameraIconFilled className="text-teal-500" /> : <CameraIcon />}
                        </div>
                        <div className="text-1">{t('Upload')}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={null}
                    key={t('Cart')}
                    isActive={isCartOpen}
                    onClick={(): void => {
                        dispatch(showCart(true));
                        setIsMoreActive(false);
                    }}
                    className="w-full justify-center">
                    <div className="flex flex-col gap-1 items-center">
                        <div className="w-4 h-4 flex">
                            {isCartOpen ? <ShoppingCartActiveIcon /> : <ShoppingCartIcon />}
                        </div>
                        <div className="text-1">{t('Cart')}</div>
                    </div>
                </NavLink>
                <NavLink
                    to={null}
                    key={t('More')}
                    isActive={isMoreActive}
                    onClick={(): void => {
                        setIsMoreActive(true);
                    }}
                    className="w-full justify-center">
                    <div className="flex flex-col gap-1 items-center">
                        <div className="flex">
                            {unreadMessagesCount > 0 ? <MenuNotificationIcon className="w-[17px] h-[17px]" /> : <MenuIcon className="w-4 h-4" />}
                        </div>
                        <div className="text-1">{t('More')}</div>
                    </div>
                </NavLink>

                {/* "More Options" Drawer */}
                {<Modal hideHeader isOpen={isMoreActive} close={(): void => { setIsMoreActive(false); }}>
                    <div className="pt-[60px] px-1 pb-3">
                        <button
                            onClick={(): void => {
                                setIsSwitcherOpen(true);
                                setIsMoreActive(false);
                            }}
                            className="border-0 px-3 py-2 text-gray-700 bg-white shadow-none rounded-xl w-full">
                            <div className="group flex flex-row gap-4 justify-between items-center">
                                <div className={'w-5 h-5 flex-none'}>
                                    <MarkerIcon />
                                </div>
                                <Typography as="span"
                                    weight="font-medium"
                                    className="truncate">{locationName}</Typography>
                                <div className={'w-5 h-5 text-gray-700 flex-none'}>
                                    <SelectIcon />
                                </div>
                            </div>
                        </button>
                    </div>
                    <Separator />
                    <div className="pt-1.5 px-4 pb-7">
                        <NavLink
                            to={EPaths.INVOICES}
                            key={t('Bills')}
                            isActive={location.pathname.includes(EPaths.INVOICES)}
                            onClick={(): void => { setIsMoreActive(false); }}>
                            <NavLinkContentWrapper>
                                <div className="w-5 h-5 flex">
                                    {location.pathname.includes(EPaths.INVOICES) ? <ReceiptActiveIcon /> : <ReceiptIcon />}
                                </div>
                                <div className="font-medium">{t('Bills')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>
                        {(location.pathname.includes(EPaths.INVOICES) || location.pathname.includes(EPaths.UPLOADS)) && <NavLink
                            to={EPaths.UPLOADS}
                            key={t('Uploads')}
                            isActive={location.pathname.includes(EPaths.UPLOADS)}
                            onClick={(): void => { setIsMoreActive(false); }}>
                            <NavLinkContentWrapper>
                                <div className="font-medium ml-9">{t('Uploads')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>}
                        <NavLink
                            to={EPaths.ORDER_HISTORY}
                            key={t('Orders')}
                            isActive={location.pathname.includes(EPaths.ORDER_HISTORY)}
                            onClick={(): void => { setIsMoreActive(false); }}>
                            <NavLinkContentWrapper>
                                <div className="w-5 h-5 flex">
                                    {location.pathname.includes(EPaths.ORDER_HISTORY) ? <TicketActiveIcon /> : <TicketIcon />}
                                </div>
                                <div className="font-medium">{t('Orders')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>
                        <NavLink
                            to={EPaths.DIRECT_CHAT}
                            key={t('Messages')}
                            isActive={location.pathname.includes(EPaths.DIRECT_CHAT)}
                            onClick={(): void => { setIsMoreActive(false); }}>
                            <NavLinkContentWrapper>
                                <div className="w-5 h-5 flex">
                                    {location.pathname.includes(EPaths.DIRECT_CHAT) ? getMessagesIcon(true) : getMessagesIcon()}
                                </div>
                                <div className="font-medium">{t('Messages')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>
                        <NavLink
                            to={HELP_DESK}
                            key={t('Help')}
                            isActive={false}
                            target="_blank"
                            rel="noreferrer noopener">
                            <NavLinkContentWrapper>
                                <div className="w-5 h-5 flex">
                                    <HelpIcon />
                                </div>
                                <div className="font-medium">{t('Help')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>
                        {settingsActive && <NavLink
                            to={EPaths.SETTINGS}
                            key={t('Settings')}
                            isActive={location.pathname.includes(EPaths.SETTINGS)}
                            onClick={(): void => { setIsMoreActive(false); }}>
                            <NavLinkContentWrapper>
                                <div className="w-5 h-5 flex">
                                    {location.pathname.includes(EPaths.SETTINGS) ? <SettingsActiveIcon /> : <SettingsIcon />}
                                </div>
                                <div className="font-medium">{t('Settings')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>}
                        <NavLink
                            to={ESettingsPaths.ACCOUNT}
                            key={t('Account')}
                            isActive={location.pathname.includes(ESettingsPaths.ACCOUNT)}
                            onClick={(): void => { setIsMoreActive(false); }}>
                            <NavLinkContentWrapper>
                                <div className="w-5 h-5 flex">
                                    {location.pathname.includes(ESettingsPaths.ACCOUNT) ? <AccountActiveIcon /> : <AccountIcon />}
                                </div>
                                <div className="font-medium">{t('Account')}</div>
                            </NavLinkContentWrapper>
                        </NavLink>
                    </div>
                </Modal>}

                {/* Venue Switcher drawer */}
                <BuyerSwitcherMobile
                    currentBuyerName={locationName}
                    isOpen={isSwitcherOpen}
                    close={(): void => { setIsSwitcherOpen(false); }} />
            </div>
        </nav>
    </div>;
};

import { tNamespace } from '@v2/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ActiveTabEmptyImage from '@images/teamwork.svg';
import { Button, Typography } from '@notch-ordering/ui-components';
import { AccountingConfiguration, ApClassSchema, EApPlatform, TaxCodeSchema } from '@notch-ordering/shared-logic';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { RutterSubsidiary, SupplierMappings, VendorDetails } from '@v2/network/BushwhackAPI';
import { IntegrationDisplayData } from './IntegrationsUtils';
import { IntegrationsActiveCard } from './IntegrationsActiveCard';

type Props = {
    onClickBrowse: () => void,
    refetch: () => void,
    onSave?: (supplierMappings: SupplierMappings) => void,
    onConfigUpdate: (config: AccountingConfiguration) => void,
    activeAccountingIntegration: IntegrationDisplayData,
    accountingIntegrationConfig: AccountingConfiguration,
    integrationPlatform?: EApPlatform,
    notchSuppliers?: SupplierCatalogData[],
    accountingVendors?: VendorDetails[],
    subsidiaries?: RutterSubsidiary[],
    supplierMappingDefaults?: SupplierMappings,
    taxCodes?: TaxCodeSchema[],
    accountingClasses?: ApClassSchema[],
    companyName?: string,
    activeERPIntegration: IntegrationDisplayData,
    isInitialIntegrationSyncComplete: boolean,
    isWaitingOnSyncInfo: boolean,
};

export const IntegrationsActive: React.FC<Props> = ({
    onClickBrowse,
    refetch,
    onSave,
    onConfigUpdate,
    activeAccountingIntegration,
    activeERPIntegration,
    integrationPlatform,
    notchSuppliers,
    accountingVendors,
    subsidiaries,
    supplierMappingDefaults,
    taxCodes,
    accountingClasses,
    companyName,
    accountingIntegrationConfig,
    isInitialIntegrationSyncComplete,
    isWaitingOnSyncInfo
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    return (
        <>
            {/* Empty state */}
            <div hidden={!!activeAccountingIntegration || !!activeERPIntegration} className="flex flex-col w-full items-center pt-40">
                <ActiveTabEmptyImage className="w-64 h-44" />
                <Typography as="div" variant="2XL" weight="font-semibold" className="pt-12">
                    {t('seamlesslyIntegrate')}
                </Typography>
                <Typography as="div" className="pt-2 text-gray-600">
                    {t('browseOurAvailable')}
                </Typography>
                <Typography as="div" className="pt-1/2 text-gray-600">
                    {t('fastDataFlow')}
                </Typography>
                <Button className="mt-16" onClick={onClickBrowse}>
                    {t('browseIntegrations')}
                </Button>
            </div>
            {/* Active state */}
            <div hidden={!activeAccountingIntegration && !activeERPIntegration} className="px-10 py-6">
                <div className="grid grid-cols-4">
                    <Typography as="div" weight="font-medium" className="text-gray-600">{t('name')}</Typography>
                    <Typography as="div" weight="font-medium" className="text-gray-600">{t('connectedOn')}</Typography>
                    <Typography as="div" weight="font-medium" className="text-gray-600">{t('syncStatus')}</Typography>
                </div>
                {activeAccountingIntegration && <IntegrationsActiveCard refetch={refetch}
                    integration={accountingIntegrationConfig}
                    data={activeAccountingIntegration}
                    integrationPlatform={integrationPlatform}
                    notchSuppliers={notchSuppliers}
                    accountingVendors={accountingVendors}
                    subsidiaries={subsidiaries}
                    supplierMappingDefaults={supplierMappingDefaults}
                    taxCodes={taxCodes}
                    accountingClasses={accountingClasses}
                    companyName={companyName}
                    onSave={onSave}
                    onConfigUpdate={onConfigUpdate}
                    isInitialSyncComplete={isInitialIntegrationSyncComplete}
                    isWaitingOnSyncInfo={isWaitingOnSyncInfo} />}
                {activeERPIntegration && <IntegrationsActiveCard
                    data={activeERPIntegration}
                    integrationPlatform={activeERPIntegration.platform}
                    isInitialSyncComplete={true}
                    isWaitingOnSyncInfo={false} />}
            </div>
        </>
    );
};

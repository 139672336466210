import React from 'react';
import PropTypes from 'prop-types';

import Utils from '../../../utils';
import Tag from '@/deprecated/fe-shared-components/tag';

const ChannelFooter = ({ status, orderDate, deliveryDate }) => {
  return (
    <div className="footer-wrapper">
      <div className="list-footer">
        {status && (
          <>
            <div className="date-format">
              <span className="date-label">Placed:</span> {orderDate || '-'}{' '}
            </div>
            <div className="date-format">
              <span className="date-label">Delivery:</span>{' '}
              {deliveryDate || '-'}
            </div>
          </>
        )}
      </div>
      <Tag
        text={Utils.lowercaseSentence(status)}
        type={Utils.getTagTypePerStatus(status)}
      />
    </div>
  );
};

export default ChannelFooter;

ChannelFooter.defaultProps = {
  status: '',
  orderDate: '',
  deliveryDate: '',
};

ChannelFooter.propTypes = {
  status: PropTypes.string,
  orderDate: PropTypes.string,
  deliveryDate: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import renderField from '../../../../redux-form/renderField';
import validate from '../../../../redux-form/AccountSettingsForm/validate';
import ErrorMessage from '../../../ErrorMessage.jsx';

class AccountSettingsForm extends Component {
  componentDidMount() {
    this.props.analyticsViewPage('EditAccount');
  }

  render() {
    const {
      pristine,
      submitting,
      handleSubmit,
      onClickCancel,
      errorAsync,
      errorMessage,
    } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <Field
          name="firstName"
          className="form-control input-lg"
          component={renderField}
          label="First Name"
          type="text"
        />
        <Field
          name="lastName"
          className="form-control input-lg"
          component={renderField}
          label="Last Name"
          type="text"
        />
        <Field
          name="email"
          className="form-control input-lg"
          component={renderField}
          disabled={true}
          label="Email"
          type="text"
        />
        <Field
          name="password"
          className="form-control input-lg"
          component={renderField}
          label="Old Password"
          type="password"
        />
        <Field
          name="password1"
          className="form-control input-lg"
          component={renderField}
          label="New Password"
          type="password"
        />
        <Field
          name="password2"
          className="form-control input-lg"
          component={renderField}
          label="Re-Enter New Password"
          type="password"
        />

        <div className="footer-accounts text-center">
          <ErrorMessage error={errorAsync} errorMessage={errorMessage} />
          <button
            type="button"
            className="btn btn-white btn-lg pull-left"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg pull-right"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

AccountSettingsForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorAsync: PropTypes.bool.isRequired,
  errorMessage: PropTypes.array.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  analyticsViewPage: PropTypes.func.isRequired,
};

// Decorate the form component
AccountSettingsForm = reduxForm({
  form: 'AccountSettingsForm', // a unique name for this form
  validate,
})(AccountSettingsForm);

AccountSettingsForm = connect((state) => {
  const { account } = state.accountReducer;

  return {
    initialValues: {
      email: account.email,
      firstName: account.firstName,
      lastName: account.lastName,
    },
  };
})(AccountSettingsForm);

export default AccountSettingsForm;

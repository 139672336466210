import React, { useEffect } from 'react';
import { ScanOverviewTopNavigation } from '@v2/components/ScanReview/ScanOverview/ScanOverviewTopNavigation';
import { ScanOverviewHeader } from '@v2/components/ScanReview/ScanOverview/ScanOverviewHeader';
import { ScanOverviewList } from '@v2/components/ScanReview/ScanOverview/ScanOverviewList';
import { Separator } from '@notch-ordering/ui-components';
import { ScanOverviewDetailsList } from '@v2/components/ScanReview/ScanOverview/ScanOverviewDetailsList';
import { ScanOverviewTotals } from '@v2/components/ScanReview/ScanOverview/ScanOverviewTotals';
import { ScanOverviewBottomNavigation } from '@v2/components/ScanReview/ScanOverview/ScanOverviewBottomNavigation';
import { EConfigurationType, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { getOCConfiguration, OCConfiguration } from '@v2/network/IntegrationAPI';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { DoubleSyncOnboardingSteps, useDoubleSyncOnboardingStore } from '@v2/stores/DoubleSyncOnboardingStore';
import useAccount from '@v2/hooks/useAccount.hook';
import { ScanDoubleSyncOnboardingModal } from '@v2/components/ScanReview/ScanDoubleSyncOnboarding/ScanDoubleSyncOnboardingModal';
import { listInvoices } from '@v2/network/GreevilsGreedApi';
import { useQuery } from '@tanstack/react-query';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { ScanDoubleSyncOnboardingTooltip } from '@v2/components/ScanReview/ScanDoubleSyncOnboarding/ScanDoubleSyncOnboardingTooltip';
import cx from 'classnames';
import { ScanOverviewTabs } from './ScanOverviewTabs';
import { SyncSummaryPage } from '../SyncSummaryPage';

export enum Tabs {
    ACCOUNTING,
    IMS,
    NO_TABS
}
/**
 * Renders a scan overview page with top navigation, header, tabs, list, details, totals and bottom navigation.
 *
 * @returns {JSX.Element} - Rendered component.
 */
export const ScanOverview : React.FC = () => {
    const { draftInvoice } = useOcrInvoiceStore();
    const { selectedTab, setSelectedTab, prevSelectedTab, hasImsIntegration, setHasImsIntegration } = useOcrInvoiceContext();
    const { buyer } = useBuyerHook();
    const { urlsafe } = useAccount();
    const { hasOnboardingBeenSeenByAccount, setAccountSeen, currentStep, setCurrentStep } = useDoubleSyncOnboardingStore();

    const hasAccountingIntegration = draftInvoice?.accountingConfiguration?.isEnabled;
    const hasImsIntegrationInfoFetched = hasImsIntegration !== null;
    const tabsEnabled: boolean = hasImsIntegration && hasAccountingIntegration;

    // the modal will be open if account has not seen onboarding before, double sync is enabled or an accounting integration enabled, and we're at the onboarding modal step.
    const isOpenOnboardingModal = !!tabsEnabled && !hasOnboardingBeenSeenByAccount(urlsafe) && currentStep === DoubleSyncOnboardingSteps.Modal;
    // if the user is on the onboarding flow and in the modal is open, or if user is on a different step of the onboarding flow, disable the editor unless the user closes the modal or tooltips
    const disableEditorIfOnboarding = isOpenOnboardingModal || currentStep !== DoubleSyncOnboardingSteps.Modal;

    const accountingSynced = draftInvoice?.invoiceData?.accountingSyncAt?.length > 0;
    const imsSynced = draftInvoice?.invoiceData?.imsSyncAt?.length > 0;

    useEffect(() => {
        /**
         * Checks buyer's Optimum Control config
         * For now, since the only ERP integration we have is OC, just look for the OC config
         * The buyer id is taken from the invoice data and the result is set in the context to be used in child components.
         */
        async function checkERPIntegration(): Promise<void> {
            if (draftInvoice?.invoiceData?.buyerUrlsafeKey) {
                const config = await getOCConfiguration(draftInvoice?.invoiceData?.buyerUrlsafeKey).catch(() => {});
                const ocConfig = config as OCConfiguration;
                setHasImsIntegration(ocConfig?.error?.length === 0);
            }
        }
        checkERPIntegration();
    }, [draftInvoice?.invoiceData?.buyerUrlsafeKey]);

    const { data: invoiceResponse, isLoading: isFetchingInvoice } = useQuery(
        ['SYNCED_GREEVILS_BILL', draftInvoice?.topLevelData?.invoiceNumber],
        () => listInvoices(1, buyer.urlsafe, { search: [draftInvoice?.topLevelData?.invoiceNumber], supplierUrlsafeKey: draftInvoice?.invoiceData?.supplierUrlsafeKey }),
        {
            enabled: !!draftInvoice?.topLevelData?.invoiceNumber,
            refetchOnWindowFocus: false,
        }
    );

    const invoice = invoiceResponse?.results[0];
    const isApprovedForBigChip = invoice?.isApprovedForBigChip && draftInvoice.accountingConfiguration.type === EConfigurationType.BigChip;

    useEffect(() => {
        if (tabsEnabled) {
            setSelectedTab(prevSelectedTab === Tabs.NO_TABS ? Tabs.ACCOUNTING : prevSelectedTab);
        } else {
            setSelectedTab(Tabs.NO_TABS);
        }
    }, [tabsEnabled]);

    // if the user lands and hasn't seen or close the onboarding flow then show the modal by default, and set the current step to modal
    useEffect(() => {
        if (currentStep !== DoubleSyncOnboardingSteps.Modal && !hasOnboardingBeenSeenByAccount(urlsafe)) {
            setCurrentStep(DoubleSyncOnboardingSteps.Modal);
        }
    }, []);

    return <div className={cx('flex flex-col h-full', {
        'pointer-events-none': disableEditorIfOnboarding,
    })}>
        <ScanOverviewTopNavigation/>
        <ScanOverviewHeader/>
        {(tabsEnabled) && <ScanDoubleSyncOnboardingTooltip step={DoubleSyncOnboardingSteps.AccountingTabs} trigger={<ScanOverviewTabs />} placement="top-start" offset={[50, 8]} />}
        <Separator className="flex-shrink-0" variant="large"/>
        {
            !isFetchingInvoice && <div>
                {
                    ((selectedTab === Tabs.ACCOUNTING && (accountingSynced || isApprovedForBigChip)) || (selectedTab === Tabs.IMS && imsSynced)) || (selectedTab === Tabs.NO_TABS && (imsSynced || accountingSynced || isApprovedForBigChip))
                        ? <div>
                            <SyncSummaryPage invoice={invoice}
                                isFetchingInvoice = {isFetchingInvoice} />
                        </div>
                        : <div>
                            {hasImsIntegrationInfoFetched && <ScanOverviewList/>}
                            <ScanOverviewDetailsList />
                            <ScanOverviewTotals />
                            <ScanOverviewBottomNavigation
                                accountingSynced={accountingSynced}
                                imsSynced={imsSynced}/>
                        </div>
                }
            </div>
        }
        <ScanDoubleSyncOnboardingModal isOpen={isOpenOnboardingModal}
            onClose={():void => {
                setAccountSeen(urlsafe);
            }} />
    </div>;
};

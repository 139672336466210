import ExternaLinkIcon from '@icons/external-icon.svg';
import HelpIcon from '@icons/help-icon.svg';
import { EApPlatform } from '@notch-ordering/shared-logic';
import { Badge } from '@notch-ordering/ui-components';
import { t } from '@v2/i18n';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { isEditedExternally, isPaidExternally } from '@v2/utils/AccountingUtils';
import React, { ReactNode } from 'react';

export interface BillLabelBadgeProps {
    invoice: InvoiceData,
    platform: EApPlatform,
}

export const enum EInvoiceLabel {
    UNVERIFIED = 'unverified',
    PAID_EXTERNALLY = 'paidExternally',
    EDITED_EXTERNALLY = 'editedExternally'
}

const getStatusLabelBadge = (val: string):JSX.Element => {
    let label: ReactNode;
    let badgeVariant;
    let icon: ReactNode;

    switch (val) {
        case EInvoiceLabel.PAID_EXTERNALLY:
            badgeVariant = 'ORANGE';
            label = <span>{t('Invoices.paidExternally')}</span>;
            icon = <ExternaLinkIcon width="14" height="14" />;
            break;
        case EInvoiceLabel.EDITED_EXTERNALLY:
            badgeVariant = 'ORANGE';
            label = <span>{t('Invoices.editedExternally')}</span>;
            icon = <ExternaLinkIcon width="14" height="14" />;
            break;
        case EInvoiceLabel.UNVERIFIED:
        default:
            badgeVariant = 'GRAY';
            label = t('Invoices.unverified');
            icon = <HelpIcon />;
            break;
    }
    return <Badge
        variant={badgeVariant}
        iconLeft={icon}>
        {label}
    </Badge>;
};

export const BillLabelBadge : React.FC<BillLabelBadgeProps> = ({ invoice, platform }) => {
    if (isPaidExternally(invoice, platform)) {
        // note: We are not supporting partial payments currently
        // if payments via an accounting integration are found, we set the status as Remote Paid
        return getStatusLabelBadge(EInvoiceLabel.PAID_EXTERNALLY);
    }
    if (isEditedExternally(invoice)) {
        return getStatusLabelBadge(EInvoiceLabel.EDITED_EXTERNALLY);
    }
    if (invoice.approvedToPay) {
        return <Badge
            variant = "GREEN">
            <span>{t('Invoices.approvedToPay')}</span>
        </Badge>;
    }
    return null;
};

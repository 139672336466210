import PropTypes from 'prop-types';
import React from 'react';

import Utils from '../../../../../utils';

const OrderCheckoutFooterPending = ({ ordersGrandTotal }) => {
  return (
    <div className="order-checkout-footer-pending">
      <div style={{ overflow: 'hidden' }}>
        <span className="left">Total</span>
        <span className="right">
          {Utils.formatAsCurrency(ordersGrandTotal)}
        </span>
      </div>
    </div>
  );
};

OrderCheckoutFooterPending.propTypes = {
  ordersGrandTotal: PropTypes.number.isRequired,
};

export default OrderCheckoutFooterPending;

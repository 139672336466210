import React from 'react';
import PropTypes from 'prop-types';
import useFeatures from '../../shared/hooks/useFeatures';

const ChannelBody = ({ orderNumber, text }) => {
  const {
    feature: { isOrderChat },
  } = useFeatures();
  return (
    <div className="list-body">
      {orderNumber && isOrderChat && (
        <div className="order-number">Order #{orderNumber}</div>
      )}
      <div className="text">{text}</div>
    </div>
  );
};

export default ChannelBody;

ChannelBody.propTypes = {
  orderNumber: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import CheckPermission from '../general/CheckPermission.jsx';
import useIsMobileWidth from '../../domains/shared/hooks/useIsMobileWidth';
import { PAGE_WRAPPER_CONTENT_ID, scrollToTop } from '@/helpers/common/mobile';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
} from '@ionic/react';
import useFirebaseRemoteConfig from '@v2/hooks/useFirebaseRemoteConfig.hook';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';

/***
 * This list contains all the possible routes from settings page.
 * Each route has it's label, description, path and display icon.
 * Path Props are used to pass additional properties to the Link navigation (mainly for external routes).
 * Permission is used incase we need to check for user's role specific routes.
 */
const settingsList = [
  {
    name: 'Business Setting',
    icon: 'fa fa-home',
    description: 'View business name and billing information',
    path: '/settings/buyer/',
    pathProps: {},
    permission: true,
  },
  {
    name: 'Members',
    icon: 'fa fa-users',
    description: 'View and edit members information',
    path: '/settings/members/',
    pathProps: {},
    permission: true,
  },
  {
    name: 'Shipping Locations',
    icon: 'fa fa-map-marker',
    description: 'View, edit and add new shipping locations',
    path: '/settings/locations/',
    pathProps: {},
    permission: true,
  }
];

/***
 * Responsive component that lists all setting routes
 */
const Settings = () => {
    const isMobileWidth = useIsMobileWidth();

  useEffect(() => {
    scrollToTop(PAGE_WRAPPER_CONTENT_ID);
  }, []);

  return (
    <IonContent className="ionic-settings-container">
      {isMobileWidth ? (
        <div className="side-nav-bar-main">
          <ul className="side-nav-bar-section">
            {settingsList.map((setting) => {
              const row = (
                <li key={setting.path}>
                  <Link
                    className="side-nav-bar-tab"
                    to={setting.path}
                    {...setting.pathProps}
                  >
                    <span>
                      <i className={setting.icon} />
                      {setting.name}
                    </span>

                    <i className="fa fa-chevron-right" />
                  </Link>
                </li>
              );
              if (setting.permission) {
                return (
                  <CheckPermission
                    roles={['Owner', 'Manager']}
                    key={setting.path}
                  >
                    {row}
                  </CheckPermission>
                );
              }
              return row;
            })}
          </ul>
        </div>
      ) : (
        <IonGrid>
          <IonRow>
            {settingsList.map((setting) => {
              const row = (
                <IonCol size="4" key={setting.path}>
                  <Link
                    className="side-nav-bar-tab"
                    to={setting.path}
                    {...setting.pathProps}
                  >
                    <IonCard className="settings-ionic-box">
                      <IonCardHeader className="settings-box-header">
                        <IonCardTitle className="settings-box-header-title">
                          <i className={setting.icon} />
                          {setting.name}
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent className="settings-box-body">
                        {setting.description}
                      </IonCardContent>
                    </IonCard>
                  </Link>
                </IonCol>
              );

              if (setting.permission) {
                return (
                  <CheckPermission
                    roles={['Owner', 'Manager']}
                    key={setting.path}
                  >
                    {row}
                  </CheckPermission>
                );
              }
              return row;
            })}
          </IonRow>
        </IonGrid>
      )}
    </IonContent>
  );
};

export default Settings;

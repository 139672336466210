import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ShowDeliveryRequestsView from './ShowDeliveryRequestsView.jsx';
import EditDeliveryRequestsView from './EditDeliveryRequestsView.jsx';

export default class DeliveryRequests extends Component {
  render() {
    const { shippingAddresses, orderDeliveryRequestsView } = this.props;
    return (
      <div className="checkout-box">
        {/* IIFE in JSX */}
        {(() => {
          if (shippingAddresses) {
            if (orderDeliveryRequestsView === 'ShowDeliveryRequestsView') {
              return <ShowDeliveryRequestsView {...this.props} />;
            } else if (
              orderDeliveryRequestsView === 'EditDeliveryRequestsView'
            ) {
              return <EditDeliveryRequestsView {...this.props} />;
            }
          }
        })()}
      </div>
    );
  }
}

DeliveryRequests.propTypes = {
  shippingAddresses: PropTypes.array.isRequired,
  orderDeliveryRequestsView: PropTypes.string.isRequired,

  orders: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

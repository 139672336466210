import React, { useState } from 'react';
import { ScanTopNavigation } from '@v2/components/ScanReview/ScanTopNavigation/ScanTopNavigation';
import { Button, Modal, Separator, Typography, twMergeCustom } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { useOcrInvoiceStore, LineItemTypeOptions, valueToNumber, LineItemCategory, LineItemFocusType, LineItemCategoryEnum, ESyncDataType } from '@notch-ordering/shared-logic';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';
import { getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { Transition } from '@headlessui/react';
import cx from 'classnames';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { ScanChartOfAccountLineItem } from './ScanChartOfAccountLineItem';
import { ScanChartOfAccountTotals } from './ScanChartOfAccountTotals';
import { ScanChartOfAccountEmpty } from './ScanChartOfAccountEmpty';
import { ScanLineItemsFocusNavigation } from '../ScanLineItems/ScanLineItemsFocusNavigation/ScanLineItemsFocusNavigation';
import { ScanLineItemsBadge, ScanLineItemsBadgeType } from '../ScanLineItems/ScanLineItemsHeader/ScanLineItemsBadge';
import { onNavigate } from '../ScanReviewUtils';

export const ScanChartOfAccountDetails : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.ChartOfAccountDetails' });
    const { setCurrentView, selectedTab, setCurrentErrorField, hasImsIntegration } = useOcrInvoiceContext();
    const { draftInvoice, addFormLineItem, setIsAutoCalculateOn, autoCalculateChartOfAccountDetails, validateForm, formErrors, setShowFocusPanel, setFocusType, showFocusPanel } = useOcrInvoiceStore();

    const [shouldShowAutoCalculateModal, setShouldShowAutoCalculateModal] = useState<boolean>(false);
    const hasChartOfAccountDetails = draftInvoice?.chartOfAccountDetails?.length > 0;
    const invoiceTotals = draftInvoice.topLevelData.chartOfAccountTotals;
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);
    const hasRequiredFields = formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount > 0;
    const hasErrors = lineItemCategoryToValidate === LineItemCategoryEnum.ChartOfAccountDetails && hasRequiredFields;
    const allowAutoCalculate = draftInvoice?.accountingConfiguration?.data?.syncDataType === ESyncDataType.CHART_OF_ACCOUNT_DETAILS;

    const onBlur = ():void => {
        validateForm(lineItemCategoryToValidate);
    };

    React.useEffect(() => {
        validateForm(lineItemCategoryToValidate);
    }, []);

    const glCodeOptions = draftInvoice?.glCodes?.map((glCode) => ({
        value: glCode.id,
        label: glCode.code ? `${glCode.name} - ${glCode.code}` : glCode.name
    })) ?? [];

    const taxCodeOptions = draftInvoice?.taxCodes?.map((taxCode) => ({
        value: taxCode.id,
        label: taxCode.name
    })) ?? [];

    const closeAutoCalculateModal = (shouldTurnOnAutoCalculate?: boolean): void => {
        if (shouldTurnOnAutoCalculate) {
            setIsAutoCalculateOn(true);
            autoCalculateChartOfAccountDetails();
            validateForm(LineItemCategoryEnum.LineItems);
            sendTrackingEvent({ ...TrackingEvents.autoCalculateClickedOn, label: TrackingLabel.OCR });
        }
        setShouldShowAutoCalculateModal(false);
    };

    const hasErrorFieldIndex = (index: number, field: string): boolean => {
        if (lineItemCategoryToValidate !== LineItemCategoryEnum.ChartOfAccountDetails) {
            return false;
        }

        if (formErrors[lineItemCategoryToValidate]?.requiredFields?.errorFields) {
            return formErrors[lineItemCategoryToValidate]?.requiredFields.errorFields[index]?.[field] !== undefined;
        }

        return false;
    };

    const handleOnFocusField = (e: React.FocusEvent<HTMLInputElement>): void => {
        const { id } = e.target;

        validateForm(lineItemCategoryToValidate);
        const { requiredFields } = formErrors[lineItemCategoryToValidate];
        const isMissingField = requiredFields?.errorLineItemRefs?.includes(id);
        // if field is not missing and the focus panel is open then hide focus panel
        if (!isMissingField && showFocusPanel) {
            setShowFocusPanel(false);
            setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);
            return;
        }
        if (isMissingField) {
            const indexError = requiredFields?.errorLineItemRefs?.indexOf(id);
            setCurrentErrorField(indexError);
            // if the focus panel is not opened then open and set focus type to required
            if (!showFocusPanel) {
                setShowFocusPanel(true);
            }
        }
    };

    const heightOfFooter = 108; // pixels

    return <>
        <div className={`h-full overflow-auto pb-[${heightOfFooter}px]`}>
            <ScanTopNavigation rightContent={<>
                <Button variant="TERTIARY_FILLED"
                    size="SMALL"
                    disabled={draftInvoice?.isAutoCalculateOn}
                    onClick={(): void => {
                        addFormLineItem(LineItemTypeOptions.PRODUCT, 'chartOfAccountDetails');
                        sendTrackingEvent({ ...TrackingEvents.newLineItemClicked, label: TrackingLabel.OCR });
                        validateForm(LineItemCategoryEnum.ChartOfAccountDetails);
                    }}
                    className="mr-1">
                    {t('newLine')}
                </Button>
                <Button variant="SECONDARY"
                    size="SMALL"
                    onClick={(): void => {
                        setCurrentView(ScanReviewViews.Overview);
                    }}>
                    {tCommon('Buttons.done')}
                </Button>
            </>} />
            <Transition
                show={!showFocusPanel}
                enter="transition ease-in duration-100"
                enterFrom="opacity-0  transform -translate-y-10"
                enterTo="opacity-100 transform translate-y-0"
                leave="transition ease-out duration-100"
                leaveFrom="opacity-100 transform translate-y-0"
                leaveTo="opacity-0 transform -translate-y-10">
                <div className={twMergeCustom(cx('px-8 pt-4 pb-4', {
                    'pb-8': !hasErrors,
                }),)}>
                    <Typography className="lg:text-7 m-0"
                        as="h1"
                        variant="3XL"
                        weight="font-bold">{t('chartOfAccountDetails')}</Typography>
                </div>
                {hasErrors && <div className="flex space-x-2 px-8 mt-2 mb-10">
                    <ScanLineItemsBadge type={ScanLineItemsBadgeType.MissingFields}
                        title={t('countRequiredField', { count: formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount ?? 0 })}
                        onClick={():void => {
                            setShowFocusPanel(true);
                            setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);

                            if (formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs?.length > 0) {
                                onNavigate(formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs[0]);
                            }
                        }} />
                </div>}
            </Transition>
            <ScanLineItemsFocusNavigation/>
            <Separator variant="large"/>
            {hasChartOfAccountDetails && draftInvoice.chartOfAccountDetails.map((lineItem, index) => <div key={lineItem.reactKey}>
                <ScanChartOfAccountLineItem reactKey={lineItem.reactKey}
                    index={index}
                    num={draftInvoice.chartOfAccountDetails.indexOf(lineItem) + 1}
                    glCode={lineItem.primaryItemData?.glCodeID}
                    glCodeOptions={glCodeOptions}
                    handleOnFocusField={handleOnFocusField}
                    hasErrorFieldIndex={hasErrorFieldIndex}
                    onBlur={onBlur}
                    taxCode={lineItem.primaryItemData?.taxCodeID}
                    taxCodeOptions={taxCodeOptions}
                    total={valueToNumber(lineItem.primaryItemData?.total)}
                    tax={lineItem.primaryItemData?.tax?.dollarAmount}
                    isAutoCalculateOn={draftInvoice?.isAutoCalculateOn} />
                <Separator/>
            </div>)}
            {!hasChartOfAccountDetails && <ScanChartOfAccountEmpty />}
        </div>
        <ScanChartOfAccountTotals subtotal={invoiceTotals.subtotal}
            tax={invoiceTotals.tax}
            total={invoiceTotals.total}
            allowAutoCalculate={allowAutoCalculate}
            isAutoCalculateOn={draftInvoice?.isAutoCalculateOn}
            onToggleAutoCalculate={(): void => {
                if (!draftInvoice?.isAutoCalculateOn) {
                    setShouldShowAutoCalculateModal(true);
                } else {
                    setIsAutoCalculateOn(false);
                    validateForm(LineItemCategoryEnum.ChartOfAccountDetails);
                    sendTrackingEvent({ ...TrackingEvents.autoCalculateClickedOff, label: TrackingLabel.OCR });
                }
            }} />
        <Modal isOpen={shouldShowAutoCalculateModal}
            close={closeAutoCalculateModal}
            topPadding="0">
            <Typography as="div" variant="LG-2" weight="font-semibold" className="px-5 pb-2">{t('areYouSure')}</Typography>
            <Typography as="div" className="text-gray-600 px-5 pb-5">{t('turningOnAutoCalculate')}</Typography>
            <Separator />
            <div className="flex flex-row justify-between pt-5 px-5">
                <Button variant="TERTIARY_FILLED"
                    size="SMALL"
                    onClick={(): void => { closeAutoCalculateModal(); }}>
                    {tCommon('Buttons.cancel')}
                </Button>
                <Button variant="SECONDARY"
                    size="SMALL"
                    onClick={(): void => { closeAutoCalculateModal(true); }}>
                    {tCommon('Buttons.confirm')}
                </Button>
            </div>
        </Modal>
    </>;
};

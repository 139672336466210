export enum EPaths {
    SIGN_IN = '/account/signin',
    FORGOT_PASSWORD = '/account/forgot',
    RESET_PASSWORD = '/account/reset',
    CREATE_SELF_ONBOARD_BUYER = '/signup/:email/:code(/:fromSubdomain)',
    RECEIVING_TOOL = '/order/edit/:order_urlsafe',
    ORDER_GUIDE = '/store/:vendorID/:orderGuide',
    ORDER_GUIDE_WILD = '/store/:vendorID/*',
    SEARCH = '/search',
    ORDER_HISTORY = '/orders',
    ORDER_DETAILS = '/order_detail/:order_urlsafe',
    DIRECT_CHAT = '/inbox',
    SETTINGS = '/settings',
    HOME = '/home',
    SETUP = '/setup',
    INVALID_VERSION = '/invalid_version',
    SHOP = '/shop',
    MAINTENANCE = '/maintenance',
    INVOICES = '/invoices',
    BILL_DETAILS = '/bill/:billNumber',
    UPLOADS = '/uploads',
    UPLOAD = '/uploads/new',
    SCANS_REVIEW = '/uploads/:invoiceKey/review',
    SUPPLIER_CATALOG_IMPORT = '/catalog/:supplierUrlsafeKey/import',
    CATALOG_MANAGEMENT = '/catalog/:supplierUrlsafeKey',
    CATALOG = '/catalog',
}

export enum ESettingsPaths {
    ACCOUNT = '/account',
    BUYER = 'buyer',
    LOCATIONS = 'locations',
    MEMBERS = 'members',
    INTEGRATIONS = '/integrations',
}

export enum ESetupPaths {
    CATALOG = 'catalog',
    CATALOG_ADD_ITEMS = 'catalog/add-items',
    CATALOG_ADD_ITEMS_CREATE = 'catalog/add-items/create',
    CATALOG_ADD_ITEMS_UPLOAD = 'catalog/add-items/upload',
    CATALOG_COMPLETE = 'catalog/complete',
    SUPPLIERS = 'suppliers'
}

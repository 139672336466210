import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { toastr } from 'react-redux-toastr';

import { logException } from '../../../domains/shared/logger';
import DragAndDrop from '../../../components/drag_and_drop/DragAndDrop.jsx';
import ModalBox from '../../../components/general/ModalBox.jsx';
import { basicCustomStyles } from '../../../constants/CustomStyles';
import useIsMobileWidth from '../../shared/hooks/useIsMobileWidth';

const ChatFileUploadContainer = ({ shouldOpen, closeHandler, sendHandler }) => {
  const uploadFormRef = createRef();

  const handleDrop = (files) => performFilesUpload(files);

  const openUploadFilesDialog = () => uploadFormRef.current.click();

  const performFilesUpload = (selectedFiles) => {
    let largeFile = false;
    for (const file of selectedFiles) {
      /**
       * Filter images less than 5MB
       */
      if (Math.floor(file.size / 1024 / 1024) < 5) {
        const data = new FormData();
        data.append('file', file);
        sendHandler(data);
      } else {
        largeFile = true;
      }
    }

    closeHandler();

    if (largeFile) {
      const msg = 'Image size should be less than 5MB';
      toastr.error(msg);
      logException(new Error(msg));
    }
  };

  const isMobileWidth = useIsMobileWidth();

  return (
    <Modal isOpen={shouldOpen} style={basicCustomStyles} ariaHideApp={false}>
      <ModalBox
        id="chat-file-upload-modal"
        activateSave={false}
        loading={false}
        showDelete={false}
        onClickClose={() => {}}
        customStyles={{ height: 322, borderRadius: 8 }}
        showHeader={false}
        showFooter={false}
      >
        <div className="upload-invoice-container text-2">
          <div className="upload-invoice-header">
            <div className="header-text">Upload files</div>
            <div className="header-close-btn" onClick={closeHandler}>
              <i style={{ fontSize: '20px' }} className="fa fa-times"></i>
            </div>
          </div>

          <DragAndDrop handleDrop={handleDrop}>
            <div className="file-upload-container">
              <div className="file-upload-area">
                {!shouldOpen ? (
                  <div className="text">
                    Uploading file(s)<span className="dot-animation">.</span>
                  </div>
                ) : (
                  <div className="text">
                    {!isMobileWidth ? (
                      <>
                        Drop files here or &nbsp;
                        <span
                          className="text-browse"
                          role="button"
                          tabIndex={0}
                          onClick={openUploadFilesDialog}
                        >
                          browse computer
                        </span>
                        .{' '}
                      </>
                    ) : (
                      <span
                        className="text-browse"
                        role="button"
                        tabIndex={0}
                        onClick={openUploadFilesDialog}
                      >
                        Click to upload files
                      </span>
                    )}

                    <input
                      id="upload-chat-files"
                      type="file"
                      ref={uploadFormRef}
                      style={{ position: 'fixed', left: '-100vh' }}
                      onChange={() =>
                        performFilesUpload(uploadFormRef.current.files)
                      }
                      multiple="multiple"
                      accept=".gif,.jpg,.jpeg,.png"
                    />
                  </div>
                )}
              </div>
            </div>
          </DragAndDrop>
        </div>
      </ModalBox>
    </Modal>
  );
};

export default ChatFileUploadContainer;

ChatFileUploadContainer.propTypes = {
  shouldOpen: PropTypes.bool.isRequired,

  closeHandler: PropTypes.func.isRequired,
  sendHandler: PropTypes.func.isRequired,
};

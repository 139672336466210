import axios, { AxiosResponse } from 'axios';
import { throwAxiosError } from '@v2/utils/AxiosUtils';
import { OcrState } from '@v2/types/UploadsTypes';
import { LineItemType } from '@notch-ordering/shared-logic';
import { OcrInvoiceData } from '@notch-ordering/shared-logic/dist/src/ocr/network/OcrLoadNetwork';

const { falsePromiseApi } = process.env.BASE_URLS;

export type InvoiceQueryParams = {
    limit: number,
    cursor: string,
    sort: 'createdAt' | 'updatedAt',
    direction: 'asc' | 'desc',
};
export type InvoicesSearchParams = {
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string[],
    orderUrlsafeKey: string,
    buyerName: string,
    supplierName: string,
    createdAt: string,
    updatedAt: string,
    hasImsSynced: boolean | null | undefined,
    hasAccountingSynced: boolean | null | undefined,
    requireAllIntegrationsSynced: boolean,
    ocrState: OcrState | OcrState[],
    externalReferenceID: string,
};

export type InvoiceSearchParamsType = keyof InvoicesSearchParams;

export type InvoicesResults = {
    results: OcrInvoice[],
    total: number,
    hasNextPage: boolean,
    nextCursor: string | null,
};

export type ParsedOcrValue = {
    value: string,
    confidence: number,
};
export type OcrInvoiceLineItem = {
    quantity?: ParsedOcrValue,
    productCode?: ParsedOcrValue,
    unit?: ParsedOcrValue,
    description?: ParsedOcrValue,
    unitPrice?: ParsedOcrValue,
    amount?: ParsedOcrValue,
    weight?: ParsedOcrValue,
    isRemoved?: boolean,
    tax?: ParsedOcrValue,
    taxPercent?: ParsedOcrValue,
    taxIsSet?: boolean,
    glCodeID?: string,
    discountAmount?: ParsedOcrValue,
    type: LineItemType,
};

export type OcrInvoiceMetadata = {
    rejectReason?: string | null,
};

export type OcrInvoice = {
    id: string,
    ocrState: OcrState,
    cdnUrls: string[],
    orderUrlsafeKey: string,
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
    externalReferenceID: string | null,
    supplierName: string,
    buyerName: string,
    ocrParsedInvoiceData: Record<string, OcrInvoiceData>,
    assigneeID: string | null,
    assigneeName: string | null,
    orderNumber?: string,
    createdAt: number,
    updatedAt: number,
    expiresAt: number,
    accountingSyncAt?: string,
    imsSyncAt?: string,
    metadata?: OcrInvoiceMetadata,
};

export interface IBuyerDetails {
    id: string,
    operationCompletionTime: string,
    operationDays: WeekDays[],
    operationStartTime: string,
    operationEndTime: string,
    canPayBills?: boolean,
}

export enum WeekDays {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday',
}

export async function getOcrInvoices(searchParams: Partial<InvoicesSearchParams>, queryParams: Partial<InvoiceQueryParams>): Promise<InvoicesResults> {
    const url = `${falsePromiseApi}/invoices/search`;
    const response = await axios.post<AxiosResponse<InvoicesResults>>(url, searchParams, { params: queryParams }).catch((e) => throwAxiosError(e, 'Could not fetch ocr invoices', false));
    return response.data;
}

export type DeleteOcrInvoiceParams = {
    invoiceKey: string,
};

/**
 * Delete the OCR record
 */
export async function deleteOcrInvoice({ invoiceKey }:DeleteOcrInvoiceParams): Promise<void> {
    return axios.delete(`${falsePromiseApi}/invoices/${invoiceKey}`).then((resp) => resp.data);
}

export async function getInvoice(invoiceID: string): Promise<OcrInvoice> {
    const url = `${falsePromiseApi}/Invoices/${invoiceID}`;
    const response = await axios.get(url).catch((e) => throwAxiosError(e, 'Could not fetch invoice'));
    return response.data;
}

export async function getBuyerConfig(buyerID: string): Promise<IBuyerDetails> {
    const url = `${falsePromiseApi}/buyer_config/${buyerID}`;
    const response = await axios.get(url).catch((e) => throwAxiosError(e, 'Could not fetch buyer configuration'));
    return response.data;
}

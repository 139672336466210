import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';

import PreOrderItem from './PreOrderItem.jsx';

export default class PreOrderDialogue extends Component {
  deletePreOrderItemsFromCheckout = () => {
    const { order, deletePreOrderItemsFromCheckout } = this.props;

    deletePreOrderItemsFromCheckout(order.urlsafe);
  };

  selectNextAvailableDate = () => {
    const { order, selectNextAvailableDate } = this.props;

    selectNextAvailableDate(order.urlsafe);
  };

  render() {
    const { order } = this.props;

    return (
      <div className="pre-order-dialogue">
        <div className="pre-order-dialogue-header">
          <div className="main-title">
            To select this delivery date, you must delete the following 48 hour
            notice items:
          </div>
          <div>
            These items need a minimum of 48 hours (2 Days) notice to be
            delivered. Please select a date two days from now or delete the
            items from your order to select this delivery date.
          </div>
        </div>
        <div className="pre-order-list-box">
          <table>
            <colgroup>
              <col width="30%" />
              <col width="55%" />
              <col width="15%" />
            </colgroup>
            <tbody>
              {order.items.map((orderItem, index) => {
                if (orderItem.isPreOrder) {
                  return (
                    <PreOrderItem
                      key={index}
                      orderItem={orderItem}
                      {...this.props}
                    />
                  );
                }
              })}
            </tbody>
          </table>
          <div className="pre-order-dialogue-footer">
            <button
              onClick={this.deletePreOrderItemsFromCheckout}
              className="btn btn-red pull-left"
            >
              Delete Items
            </button>

            <button
              onClick={this.selectNextAvailableDate}
              className="btn btn-primary-hover pull-right"
            >
              Next Available Date
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PreOrderDialogue.propTypes = {
  order: PropTypes.object.isRequired,

  deletePreOrderItemsFromCheckout: PropTypes.func.isRequired,
  selectNextAvailableDate: PropTypes.func.isRequired,
};

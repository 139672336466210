import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as SettingsActions from '../../actions/settingsActions';
import * as AccountActions from '../../actions/accountActions';
import * as AnalyticsActions from '../../actions/analyticsActions';
import * as BuyerActions from '../../actions/buyerActions';

import AccountMain from '../../components/settings/account/AccountMain.jsx';
import {IonAlert} from '@ionic/react';
import {LogoutButton} from '@v2/components/Shared/Account/LogoutButton';
import {t, tNavigation} from "@v2/i18n";
import { withUseTitle } from "@/domains/shared/hooks/withUseTitle";

class AccountContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteAccountAlert: false
    };
  }

  componentDidMount() {
    this.props.analyticsActions.viewPage('MyAccount');
  }

  toggleAccountSettingsModal = () => {
    const { settingsActions, showAccountSettingsModal } = this.props;

    settingsActions.toggleAccountSettingsModal(showAccountSettingsModal);
  };

  saveAccountSettingsForm = () => {
    const { AccountSettingsForm, settingsActions, account } = this.props;

    // We have to wrap this in a try block to show any swalloed errors from redux-form handleSubmit
    try {
      if (AccountSettingsForm.values) {
        const { firstName, lastName, password, password1, password2 } =
          AccountSettingsForm.values;

        const data = {
          firstName: firstName,
          lastName: lastName,
          passwordOld: password,
          password1: password1,
          password2: password2,

          // We grab this from the account not the form, because we always need to send it with a value
          unsubscribed: account.unsubscribed,
          mobile: account.mobile,
        };

        settingsActions.updateAccountSettings(account.urlsafe, data);
      }
    } catch (e) {
      console.error(e);
    }
  };

  updateEmailNotficationAccount = () => {
    const { account, buyerActions } = this.props;

    buyerActions.updateMemberEmailNotification(account);
  };

  analyticsViewPage = (screenName) => {
    this.props.analyticsActions.viewPage(screenName);
  };

  setShowDeleteAccountAlert = (visible) => {
    this.setState({showDeleteAccountAlert: visible});
  }

  sendDeleteAccountEmail = async () => {
    const { accountActions, account } = this.props;
    await accountActions.deleteAccount(account.email, account.firstName, account.lastName);
  };

  render() {
    return (
      <div className="lg:p-10 pb-32 px-10">
        <h1 className="pb-6">{t('Navigation.Account')}</h1>
        <AccountMain
          {...this.props}
          toggleAccountSettingsModal={this.toggleAccountSettingsModal}
          saveAccountSettingsForm={this.saveAccountSettingsForm}
          updateEmailNotficationAccount={this.updateEmailNotficationAccount}
          analyticsViewPage={this.analyticsViewPage}
          setShowDeleteAccountAlert={this.setShowDeleteAccountAlert}
        />
        <div className="flex flex-row w-full justify-between">
          <LogoutButton/>
          <button className="btn btn-s btn-danger delete-account-button rounded-xl" onClick={() => {this.setShowDeleteAccountAlert(true)}}>
            Delete Account
          </button>
        </div>

        <IonAlert
          isOpen={this.state.showDeleteAccountAlert}
          onDidDismiss={() => {this.setShowDeleteAccountAlert(false)}}
          mode="ios"
          header="Delete Account"
          subHeader="Are you sure you want to permanently delete this account?"
          message="Deleting your account takes up to 5 business days. You will receive an email confirmation once this has been completed."
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel'
            },
            {
              text: 'Delete',
              role: 'destructive',
              handler: this.sendDeleteAccountEmail,
            },
          ]}
        />
      </div>
    );
  }
}

AccountContainer.propTypes = {};

function mapStatetoProps(state) {
  return {
    ...state.settingsReducer,
    ...state.accountReducer,
    ...state.buyerReducer,
    ...state.form,
    ...state.errorsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(SettingsActions, dispatch),
    accountActions: bindActionCreators(AccountActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
    buyerActions: bindActionCreators(BuyerActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(withUseTitle(AccountContainer, tNavigation('Account')));

import { createSelector } from 'reselect';

const getOrderguide = (state, props) => state.changeUnitReducer.orderguide;
const getVariantsSelected = (state, props) =>
  state.changeUnitReducer.variantsSelected;
const getInitialVariantsSelected = (state, props) =>
  state.changeUnitReducer.initialVariantsSelected;
const getVariantsCheckout = (state, props) =>
  state.changeUnitReducer.variantsCheckout;
const getOpenedBy = (state, props) => state.changeUnitReducer.openedBy;

const getVariant = (state, props) => props.variant;

// Selector that checks if a variant is in the list of selected variants.
export const checkIfVariantInList = createSelector(
  [getOrderguide, getVariantsSelected, getVariant],
  (orderguide, variantsSelected, variant) => {
    let isVariantInList = false;

    if (variantsSelected.includes(variant.productVariantUnit.id)) {
      isVariantInList = true;
    }

    return isVariantInList;
  }
);

// Selector that returns a variant's quantity.
export const getVariantQuantity = createSelector(
  [getVariantsCheckout, getVariant],
  (variantsCheckout, variant) => {
    let quantity = 0;

    const variantIndex = variantsCheckout.findIndex(
      (selectedVariant) => selectedVariant.id === variant.id
    );

    if (variantIndex > -1) {
      quantity = variantsCheckout[variantIndex].quantity;
    }

    return quantity;
  }
);

// Selector that checks if the initial list of selected variants has changed
export const checkIfVariantsListChanged = createSelector(
  [
    getInitialVariantsSelected,
    getVariantsSelected,
    getVariantsCheckout,
    getOpenedBy,
  ],
  (initialVariantsSelected, variantsSelected, variantsCheckout, openedBy) => {
    let hasVariantsListChanged = false;

    if (openedBy === 'ORDERGUIDE') {
      // Changes to order guide variants are handled right away.
      // Always set bool to true to display "Done" button in modal.
      hasVariantsListChanged = true;
    } else if (openedBy === 'CHECKOUT') {
      // _.isEqual performs a deep comparison between variantsCheckout and initVariantsSelected
      // to determine if they are equal or not.
      if (_.isEqual(initialVariantsSelected, variantsCheckout) === false) {
        hasVariantsListChanged = true;
      }
    }

    return hasVariantsListChanged;
  }
);

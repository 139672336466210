import CheckIcon from '@icons/check-icon.svg';
import { ActionBar, Button, Container, DataGridCheckbox, GridSortModel, Loading, NotchDataGrid, toast, Typography } from '@notch-ordering/ui-components';
import { AddBillModal } from '@v2/components/Bills/AddBillModal/AddBillModal';
import { BillsFilters } from '@v2/components/Bills/BillsFilters/BillsFilters';
import { PayBillModal } from '@v2/components/Bills/PayBillModal';
import { EInvoiceStatus, EStripeStatus } from '@v2/constants/EInvoiceStatus';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import useRolesHook from '@v2/hooks/useRolesHook';
import { tCommon, tNamespace, tNavigation } from '@v2/i18n';
import { requestInvoicesApproval, SupplierData } from '@v2/network/CoreAPI';
import { FETCH_INVOICES_QUERY_KEY, TBillsSortBy, useGetBillsQuery } from '@v2/Pages/Invoices/BillsQueries.hook';
import { useBillsStore } from '@v2/stores/BillsStore';
import { ERoleName } from '@v2/types/OrgData';
import { logError } from '@v2/utils/logError';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router, { browserHistory } from 'react-router';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { AccountData } from '@v2/types/AccountData';
import useAccount from '@v2/hooks/useAccount.hook';
import { changeApprovedToPay, InvoiceData } from '@v2/network/GreevilsGreedApi';
import RefreshIcon from '@icons/refresh-icon.svg';
import useGetSupplier from '@v2/hooks/useGetSupplier.hook';
import { isInvoiceInClosedPeriod } from '@v2/utils/AccountingUtils';
import { useApPlatform } from '@v2/hooks/useApPlatform.hook';
import { useQueryClient } from '@tanstack/react-query';
import { getInvoiceTableColumns, INVOICE_SYNC_STATUS_FIELD } from './InvoicesConstants';
import { BillsEmpty } from './BillsEmpty';
import { InvoicesPageContext } from './InvoicesPageContext';

export const PLACEHOLDER_AP_FEATURE_FLAG = true;

export type Props = {
    router?: Router,
};

type InvoiceQueryParams = {
    search: string, // This can only be an orderID
    filter: string, // comma separated list of order numbers
};

const SELECT_LIMIT = 10;

/**
 * Displays a table of invoices for the buyer. Currently only accessible to buyers
 * that have been specifically given access to through feature-flags.
 *
 * @param props - component properties
 * @param props.router - for navigation
 */
export const Invoices: React.FC<Props> = ({ router }) => {
    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedRows, setSelectedRows] = useState<InvoiceData[]>([]);
    const [showAddInvoiceModal, setShowAddInvoiceModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [singleSupplierSelected, setSingleSupplierSelected] = useState(false);
    const [onlyApprovedBillsSelected, setOnlyApprovedBillsSelected] = useState(false);
    const [onlyUnpaidBillsSelected, setOnlyUnpaidBillsSelected] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<SupplierData>(null);
    const queryClient = useQueryClient();

    useTitle(tNavigation('Bills'));

    const { query }: { query: InvoiceQueryParams } = router.location;
    const [isPayBillOpen, setIsPayBillOpen] = useState(false);
    const { filters, updateFilters, getStripeStatus, updatePagination, pagination } = useBillsStore();
    const { buyer } = useBuyerHook();
    const { hasRole } = useRolesHook();
    const { isAccountingEnabled, accountingInfo } = useContext(InvoicesPageContext);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const { getSupplierByUrlsafeKey } = useGetSupplier();
    const { firstName, lastName }: AccountData = useAccount();

    const filterInvoiceNumbers: string[] = query.filter?.split(',') ?? [];
    const hasFilterInvoiceNumbers = filterInvoiceNumbers.length > 0;
    const mapPageToNextCursor = React.useRef<{ [page: number]: string }>({});
    const getBillsParams = useMemo(() => ({
        pagination: {
            ...pagination,
            nextCursor: mapPageToNextCursor.current[pagination.page - 1]
        },
        buyerUrlsafeKey: buyer.urlsafe,
        filters
    }), [pagination.page, buyer?.urlsafe, filters]);
    // this query will be used when no query params are present in the url
    const { data: invoicesResponse, isLoading: areInvoicesLoading } = !hasFilterInvoiceNumbers ? useGetBillsQuery(getBillsParams) : { data: undefined, isLoading: false };

    // this query will be used to fetch invoices coming from the url query params
    const invoicesInQuery = hasFilterInvoiceNumbers ? useGetBillsQuery(
        {
            pagination,
            buyerUrlsafeKey: buyer?.urlsafe,
            filters: { search: filterInvoiceNumbers }
        },
    ) : undefined;

    // this is the main data source for the table this will be determined if there are invoices in the query params
    const invoices = invoicesInQuery ? invoicesInQuery.data?.results : invoicesResponse?.results;
    const totalRows = invoicesInQuery ? invoicesInQuery.data?.total : invoicesResponse?.total;

    // If filtering by status, filter out by Stripe status
    const filteredInvoices = invoices && filters.paymentStatus
        ? invoices?.filter((invoice) => {
            const stripeStatus = getStripeStatus(invoice.orderUrlsafeKey);
            if (filters.paymentStatus === EStripeStatus.OPEN && invoice.supplierUrlsafeKey === EInvoiceStatus.UNPAID) {
                return !stripeStatus;
            }
            if (filters.paymentStatus === EInvoiceStatus.PROCESSING) {
                return stripeStatus === EStripeStatus.DRAFT || stripeStatus === EStripeStatus.OPEN;
            }
            return stripeStatus === filters.paymentStatus;
        })
        : invoices;

    useEffect(() => {
        const supplierUrlsafe = selectedRows?.[0]?.supplierUrlsafeKey;
        setSelectedSupplier(getSupplierByUrlsafeKey(supplierUrlsafe));
        setSingleSupplierSelected(selectedRows.every((invoice) => invoice.supplierUrlsafeKey === supplierUrlsafe));
        setOnlyApprovedBillsSelected(selectedRows.every((invoice) => invoice.approvedToPay));
        setOnlyUnpaidBillsSelected(selectedRows.every((invoice) => invoice.amountPaid === 0));
    }, [selectedRows]);

    function clearSelected(): void {
        setSelectedRows([]);
        setSelectionModel([]);
    }

    function requestApproval(): void {
        if (!singleSupplierSelected) {
            toast.show({
                message: t('selectSingleSupplier')
            });
            return;
        }

        setIsLoading(true);
        const invoiceNumbers = selectedRows.map((invoice) => invoice.invoiceNumber);
        requestInvoicesApproval(buyer.urlsafe, selectedSupplier.urlsafe, invoiceNumbers, `${firstName} ${lastName}`).then(() => {
            setIsLoading(false);
            toast.show({
                icon: <CheckIcon />,
                message: t('invoicesSentForApproval')
            });
            clearSelected();
        }).catch((error) => {
            logError('Failed to request invoice approval', error, true);
            setIsLoading(false);
        });
    }

    function onClickApprove(): void {
        setIsLoading(true);
        const invoiceNumbers = selectedRows.filter((invoice) => !invoice.approvedToPay).map((invoice) => invoice.id);
        changeApprovedToPay(buyer.urlsafe, invoiceNumbers, true).then(() => {
            queryClient.invalidateQueries([FETCH_INVOICES_QUERY_KEY]);
            setIsLoading(false);
            toast.show({
                icon: <CheckIcon />,
                message: t('billsApproved')
            });
            clearSelected();
        }).catch((error) => {
            logError('Failed to approve', error, true);
            setIsLoading(false);
        });
    }

    function onClickRetract(): void {
        setIsLoading(true);
        const invoiceNumbers = selectedRows.filter((invoice) => invoice.approvedToPay).map((invoice) => invoice.id);

        changeApprovedToPay(buyer.urlsafe, invoiceNumbers, false).then(() => {
            queryClient.invalidateQueries([FETCH_INVOICES_QUERY_KEY]);
            setIsLoading(false);
            toast.show({
                icon: <CheckIcon />,
                message: t('billsRetracted')
            });
            clearSelected();
        }).catch((error) => {
            logError('Failed to retract', error, true);
            setIsLoading(false);
        });
    }

    async function onClickPay(): Promise<void> {
        if (!singleSupplierSelected) {
            toast.show({
                message: t('selectSingleSupplier')
            });
            return;
        }

        if (!hasRole([ERoleName.OWNER, ERoleName.MANAGER])) {
            return;
        }

        setIsPayBillOpen(true);
    }

    const actionsDisabled = !selectedRows || selectedRows.length < 1 || selectedRows.length > SELECT_LIMIT || !singleSupplierSelected || isPayBillOpen;
    const actionsLoading = areInvoicesLoading || isLoading;

    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        const { field, sort } = sortModel[0] ?? {
            field: undefined,
            sort: undefined
        };
        updateFilters({ sortBy: field === 'amount' ? 'amount_due' : field as TBillsSortBy, sortOrder: sort }, false);
    }, []);

    const platform = useApPlatform();

    const columns = getInvoiceTableColumns();

    // Get all invoices to check for empty state
    const { data: totalInvoices, isLoading: loadingAll } = useGetBillsQuery({
        pagination: {
            ...pagination,
            limit: 1
        },
        buyerUrlsafeKey: buyer.urlsafe,
        filters: undefined,
        options: {
            retry: false,
            retryOnMount: false,
            staleTime: Infinity,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false
        }
    });

    function isInvoiceSelectable(invoice: InvoiceData): boolean {
        const unpaid = invoice.amountPaid === 0;
        const hasNoStripeStatus = !getStripeStatus(invoice.orderUrlsafeKey || invoice.id);
        const hasInvoiceNumber = !!invoice.invoiceNumber;
        const notInClosedAccountingPeriod = !(isAccountingEnabled && isInvoiceInClosedPeriod(invoice, accountingInfo));

        return unpaid && hasNoStripeStatus && hasInvoiceNumber && notInClosedAccountingPeriod;
    }

    React.useEffect(() => {
        if (!areInvoicesLoading && invoicesResponse?.nextCursor) {
            // We add nextCursor when available
            mapPageToNextCursor.current[pagination?.page] = invoicesResponse?.nextCursor;
        }
    }, [pagination.page, areInvoicesLoading, invoicesResponse?.nextCursor]);

    const showEmpty = totalInvoices?.total === 0;

    return (<>
        {(selectedRows?.length > 0) && <div className="flex justify-center items-center">
            <ActionBar
                className="my-6"
                text={`${selectedRows.length} ${selectedRows.length === 1 ? t('billSelected') : t('billsSelected')}`}
                isOpen={selectedRows?.length > 0}
                close={():void => {
                    clearSelected();
                }}
                selection={selectedRows}
                onClose={clearSelected}
                buttons={[
                    {
                        variant: 'TERTIARY_DARK',
                        onClick: onClickRetract,
                        text: t('retract'),
                        hidden: !hasRole([ERoleName.OWNER, ERoleName.BOOKKEEPER], false) || !onlyApprovedBillsSelected || !onlyUnpaidBillsSelected
                    },
                    {
                        variant: 'TERTIARY_DARK',
                        onClick: onClickApprove,
                        text: t('approve'),
                        hidden: !hasRole([ERoleName.OWNER, ERoleName.BOOKKEEPER], false) || onlyApprovedBillsSelected
                    },
                    {
                        variant: 'TERTIARY_DARK',
                        onClick: onClickPay,
                        text: `${onlyApprovedBillsSelected ? t('pay') : t('BillNeedToApprove')}`,
                        disabled: !onlyApprovedBillsSelected,
                        hidden: !hasRole([ERoleName.OWNER, ERoleName.BOOKKEEPER, ERoleName.MANAGER], false),
                    },

                ]}/>
        </div>}
        <Loading isDark hidden={!loadingAll} className="mt-8"/>
        {showEmpty && <BillsEmpty router={router} />}
        {!showEmpty && !loadingAll && <Container fluid className={'bg-white lg:px-10'}>
            <Container className="flex flex-col gap-12">
                <div className="flex justify-between items-center">
                    {/* Header */}
                    <Typography
                        as="h1"
                        variant="4XL"
                        desktopSize="text-8"
                        weight="font-medium"
                        className="text-gray-700 mb-0 mt-0">
                        {t('bills')}
                    </Typography>
                    {/* Buttons */}
                    <div className="flex justify-end lg:flex-row flex-col gap-2">
                        { hasRole([ERoleName.OWNER, ERoleName.MANAGER], false)
                            ? undefined
                            : <Button variant="SECONDARY"
                                size="LARGE"
                                className="h-10 flex items-center justify-center"
                                onClick={requestApproval}
                                loading={actionsLoading}
                                disabled={actionsDisabled}>{t('requestApproval')}</Button>}

                        <Button variant="SECONDARY"
                            size="LARGE"
                            className="h-10 flex items-center justify-center"
                            disabled={isPayBillOpen}
                            onClick={():void => {
                                setShowAddInvoiceModal(true);
                                sendTrackingEvent(TrackingEvents.addBillClicked);
                            }}>{tCommon('Buttons.uploadBill')}</Button>
                        {
                            isAccountingEnabled
                            && <Button variant="TERTIARY_FILLED"
                                size="ICON_MEDIUM"
                                className="h-10 flex items-center justify-center"
                                onClick={():void => {
                                    sendTrackingEvent(TrackingEvents.refreshSyncClicked);
                                    window.location.reload();
                                }}><RefreshIcon className={'w-4 h-4'}/></Button>
                        }
                    </div>
                </div>

                {/* Search and filters */}
                <BillsFilters />
                <NotchDataGrid
                    columnVisibilityModel={
                        {
                            [INVOICE_SYNC_STATUS_FIELD]: isAccountingEnabled,
                        }
                    }
                    autoHeight
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    checkboxSelection
                    sortingMode={'server'}
                    onSortModelChange={handleSortModelChange}
                    getRowId={(row: InvoiceData): string => row.id}
                    rows={filteredInvoices ? filteredInvoices.map((invoiceData) => ({ ...invoiceData, platform })) : []}
                    rowCount={totalRows}
                    rowsPerPageOptions={[pagination.limit]}
                    onRowClick={({ row: bill }): void => browserHistory.push(`/bill/${bill.invoiceNumber}?supplier=${bill.supplierUrlsafeKey}`)}
                    page={pagination.page}
                    pageSize={pagination.limit}
                    columns={columns}
                    loading={areInvoicesLoading || isPayBillOpen }
                    paginationMode="server"
                    onPageChange={(nextPage): void => {
                        if (nextPage === 0 || mapPageToNextCursor.current[nextPage - 1]) {
                            updatePagination({ page: nextPage });
                        }
                    }}
                    onSelectionModelChange={(selected): void => {
                        setSelectionModel(selected);
                        setSelectedRows(invoices?.filter((invoice): boolean => selected.findIndex((id) => invoice.id === id) >= 0));
                    }}
                    selectionModel={selectionModel}
                    isRowSelectable={({ row }): boolean => isInvoiceSelectable(row)}
                    components={{
                        BaseCheckbox: DataGridCheckbox
                    }}
                    sx={{
                        border: 0,
                        '& .MuiDataGrid-iconSeparator': {
                            display: 'none',
                        },
                        '& .MuiCircularProgress-root': {
                            color: '#1A1719'
                        },
                        // Some existing CSS we have somewhere is causing the input and the checkbox icon to be misaligned. Overriding CSS to correct it.
                        '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                            height: '100%',
                            overflow: 'visible'
                        },
                        '& .MuiCheckbox-root': {
                            color: '#1A1719'
                        },
                        '& .MuiCheckbox-root:hover': {
                            'background-color': 'rgba(0,0,0,0)'
                        },
                        '& .MuiCheckbox-root input': {
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            margin: 0,
                        },
                        '& .MuiTouchRipple-root': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-checkboxInput': {
                            width: '100%',
                            height: '100%'
                        }
                    }}/>
            </Container>
            <AddBillModal
                closeModal={(): void => setShowAddInvoiceModal(false)}
                isOpen={showAddInvoiceModal}/>
            <PayBillModal close={(): void => { setIsPayBillOpen(false); }}
                isOpen={isPayBillOpen}
                bills={selectedRows}
                supplier={selectedSupplier}
                onClose={(): void => {
                    // Deselect all
                    clearSelected();
                }} />

        </Container>}
    </>);
};

import React from 'react';
import { GridColDef, GridRowHeightReturnValue, GridRowId, NotchDataGrid, Typography } from '@notch-ordering/ui-components';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { v4 as uuidv4 } from 'uuid';
import { centsToDollars } from '@v2/utils/CurrencyUtils';
import { t } from '@v2/i18n';
import PaymentMethodBadge from './PaymentMethodBadge';
import Utils from '@/utils';

type PaymentDetailsProps = {
    invoice: InvoiceData,
};

export interface TransactionCharge {
    amount: number,
    paymentMethodBrand: string,
    chargeDate: string,
}

export const gridSX = {
    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
        py: '20px',
        alignItems: 'flex-start',
    },
    '& .MuiDataGrid-virtualScroller': {
        minHeight: 'auto',
    },
};

export const INVOICE_PAYMENTS_COLUMNS = {
    amount: 'amount',
    paymentMethod: 'paymentMethodBrand',
    date: 'chargeDate',
} as const;

export const headerClassName = 'text-gray-600 font-body text-med';
export const cellClassName = 'text-gray-700 font-body text-med';

export const commonGridDef: GridColDef = {
    field: '',
    headerAlign: 'left',
    headerClassName,
    cellClassName,
    align: 'left',
};

const gridColDef: GridColDef = {
    ...commonGridDef,
    sortable: false,
    flex: 1,
};

const getInvoicePaymentColumns = (): GridColDef<TransactionCharge>[] => [
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.amount,
        headerName: 'Amount',
        renderCell: ({ row }) => Utils.formatAsCurrency(centsToDollars(row?.amount)),
    },
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.paymentMethod,
        headerName: 'Method',
        renderCell: ({ row }) => (row?.paymentMethodBrand ? <PaymentMethodBadge brand={row?.paymentMethodBrand}/> : '--'),
    },
    {
        ...gridColDef,
        field: INVOICE_PAYMENTS_COLUMNS.date,
        headerName: 'Date',
        renderCell: ({ row }) => (row?.chargeDate ? Utils.formatDate(row?.chargeDate, 'MMM, D, yyyy') : '--'),
    },

];

/**
 * This is the Payments section of the bill details page
 */
export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ invoice }) => {
    const payments = invoice?.payments.filter((payment) => payment.amount > 0) ?? [];
    const hasPayments = payments.length > 0;

    return (
        <div className="flex flex-col">
            <section className="py-10 pr-10">
                <Typography variant="LG-2" weight="font-semibold" className="mb-6">{t('Invoices.payments')}</Typography>
                {!hasPayments && <Typography className="text-gray-600">{t('Invoices.noPayments')}</Typography>}
                {hasPayments && <NotchDataGrid columns={getInvoicePaymentColumns()}
                    autoHeight
                    getRowHeight={():GridRowHeightReturnValue => 'auto'}
                    rowsPerPageOptions={[50, 100]}
                    sx={gridSX}
                    getRowId={(row):GridRowId => row?.external_id ?? uuidv4()}
                    rowCount={payments.length}
                    sortingMode="client"
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    hideFooter={true}
                    rows={payments}
                    components={{
                        NoRowsOverlay: () => <Typography className="mt-4 w-full">{t('Invoices.invoiceNoPayments')}</Typography>,
                    }}/>}
            </section>
        </div>
    );
};

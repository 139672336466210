import { NONE_SELECTED,
    Select,
    Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { useGetSuppliersPerBuyer } from '@v2/Pages/Market/MarketQueries.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { AddBillSupplierModal } from './AddBillSupplierModal';

export interface SupplierSelectInputProps {
    value: string,
    onChange?: (selectedSupplierUrlsafeKey: string) => void,
}

export const SupplierSelectInput : React.FC<SupplierSelectInputProps> = ({ value, onChange: setValue }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });
    const [addSupplierModalOpen, setAddSupplierModalOpen] = React.useState<boolean>(false);

    const { buyer } = useBuyerHook();
    const ADD_SUPPLIER_SELECT_OPTION_VALUE = 'add_supplier';
    const ADD_SUPPLIER_SELECT_OPTION = { label: t('createANewSupplier'), value: ADD_SUPPLIER_SELECT_OPTION_VALUE };
    const { data: { supplierCatalogs } = { supplierCatalogs: [] }, refetch } = useGetSuppliersPerBuyer({ buyerId: buyer.urlsafe, hideOffline: false });
    const selectOptions = [...supplierCatalogs.map((supplier) => ({ label: supplier.supplierName, value: supplier.supplierUrlsafeKey })), ADD_SUPPLIER_SELECT_OPTION];

    const onChangeSupplier = (e):void => {
        const selectedSupplierId = e.target.value;
        if (selectedSupplierId === ADD_SUPPLIER_SELECT_OPTION_VALUE) {
            setAddSupplierModalOpen(true);
        } else if (selectedSupplierId !== NONE_SELECTED) {
            setValue(selectedSupplierId);
        }
    };

    const closeAddSupplierModal = async (newSupplierUrlsafeKey):Promise<void> => {
        if (newSupplierUrlsafeKey) {
            await refetch();
            setValue(newSupplierUrlsafeKey);
        } else {
            setValue(value ?? NONE_SELECTED);
        }
        setAddSupplierModalOpen(false);
    };

    return <>
        <Typography weight="font-medium pt-2">{t('supplier')}</Typography>
        <Select
            placeholder="Select"
            required
            onChange={onChangeSupplier}
            value={value}
            options={selectOptions}/>
        <AddBillSupplierModal isOpen={addSupplierModalOpen}
            closeModal={closeAddSupplierModal} />
    </>;
};

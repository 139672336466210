import { Typography, twMergeCustom } from '@notch-ordering/ui-components';
import { getDeliveryDatesInLocalTime } from '@v2/utils/DeliveryDateUtils';
import React from 'react';
import ClockIcon from '@icons/clock-icon.svg';
import MinOrderIcon from '@icons/min-order-icon.svg';
import ScissorsIcon from '@icons/scissors-icon.svg';
import { format } from 'date-fns-tz';
import { isValid } from '@v2/utils/isValid';
import { Region, SupplierData } from '@v2/network/CoreAPI';
import Utils from '@/utils';

type Props = {
    supplier: SupplierData,
    className?: string,
};

interface ISupplierInfo {
    icon: string,
    label: string,
    value: string,
}

export const SupplierInfo: React.FC<Props> = ({ supplier, className = '' }) => {
    const getInfo = (region: Region): ISupplierInfo[] => {
        const localDeliveryDates = getDeliveryDatesInLocalTime(region);
        const nextDelivery = localDeliveryDates[0];
        const isCutoffToday = nextDelivery?.cutoffDate.getDate() === new Date(Date.now()).getDate();
        const cutoffFormat = isCutoffToday ? 'h:mma' : 'eee, h:mma';

        return [
            {
                icon: ClockIcon,
                label: 'Next delivery:',
                value: nextDelivery?.deliveryDate ? format(new Date(nextDelivery?.deliveryDate), 'eee, LLL d') : null,
            },
            {
                icon: MinOrderIcon,
                label: 'Minimum order:',
                value: Utils.formatAsCurrency(region.minimumOrderAmount, {
                    minimumFractionDigits: 0,
                }),
            },
            {
                icon: ScissorsIcon,
                label: 'Cut off time:',
                value: nextDelivery?.cutoffDate ? format(nextDelivery?.cutoffDate, cutoffFormat) : null
            },
        ];
    };

    return <>
        {getInfo(supplier.region).map((info, i) => (
            <div key={i} className={twMergeCustom('flex items-center mt-4 mx-6', className)}>
                <div className="h-5 w-5 lg:w-4 lg:h-4 mr-3 text-gray-700">
                    <info.icon />
                </div>
                <Typography as="span" className="text-gray-600">
                    {info.label}
                </Typography>
                <Typography
                    as="span"
                    weight="font-medium"
                    className="text-gray-700 ml-1">
                    {isValid(info.value) ? info.value : 'N/A'}
                </Typography>
            </div>))}
    </>;
};

import React, { useEffect, useMemo, useState } from 'react';
import { Container, Separator } from '@notch-ordering/ui-components';
import MarketHeader from '@v2/components/Market/MarketHeader/MarketHeader';
import ProductCard from '@v2/components/Market/ProductCard/ProductCard';
import useBuyer from '@v2/hooks/useBuyer.hook';
import { DEBOUNCE_SEARCH_QUERY_DELAY,
    DEFAULT_PRODUCTS_PER_PAGE,
    FILTERS_INITIAL_STATE,
    getMarketEmptyState,
    getProductCardsPlaceholder,
    IActiveFilters } from '@v2/Pages/Market/MarketConstants';
import MarketFiltersModal from '@v2/components/Market/MarketFiltersModal/MarketFiltersModal';
import { debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { useIntersectionObserver } from '@v2/hooks/useIntersectionObserver.hook';
import { useFetchMarketProductsQuery } from '@v2/Pages/Market/MarketQueries.hook';
import ProductCardPlaceholder from '@v2/components/Market/ProductCard/ProductCardPlaceholder';
import { TFetchProductsType } from '@v2/network/SecretShopAPI';
import { MarketProvider } from '@v2/Pages/Market/MarketPageContext';
import { SwitchOrderGuidesModal } from '@v2/components/Market/SwitchOrderGuidesModal/SwitchOrderGuidesModal';
import { AddProductToOrderGuidesModal } from '@v2/components/Market/AddProductToOrderGuidesModal/AddProductToOrderGuidesModal';
import { IProduct } from '@v2/network/types';
import { AddEditNewOrderGuideModal } from '@v2/components/Market/AddEditNewOrderGuideModal/AddEditNewOrderGuideModal';
import { DeleteOrderGuideModal } from '@v2/components/Market/DeleteOrderGuideModal/DeleteOrderGuideModal';
import { UnsaveProductModal } from '@v2/components/Market/UnsaveProductModal/UnsaveProductModal';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { tNavigation } from '@v2/i18n';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { PAGE_WRAPPER_CONTENT_ID, scrollToTop } from '@/helpers/common/mobile';
import { loadCartOrders } from '@/actions/orderActions';

export const MarketPage: React.FC = () => {
    const { search } = window.location;

    const params = new URLSearchParams(search);
    const supplierCatalogs = params.get('supplierCatalogs');
    const dispatch = useDispatch();
    const { buyer } = useBuyer();
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const initialFetchType:TFetchProductsType = supplierCatalogs ? TFetchProductsType.SEARCH : TFetchProductsType.SAVED;
    const [fetchTypeSelected, setFetchTypeSelected] = useState<TFetchProductsType>(initialFetchType);
    const [filters, setFilters] = useState<IActiveFilters>({ ...FILTERS_INITIAL_STATE, supplierCatalogs: supplierCatalogs ? supplierCatalogs.split(',') : [] });
    const [activeProduct, setActiveProduct] = useState<IProduct | null>(null);
    const bottomElement = React.useRef<HTMLDivElement>(null);
    const isIntersecting = useIntersectionObserver(
        bottomElement,
        { threshold: 0.1 },
    );
    const updateFilters = (newFilters: IActiveFilters):void => setFilters((prevState) => ({ ...prevState, ...newFilters }));
    const debounceSearchQuery = useMemo(() => debounce((query:string) => {
        updateFilters({ name: query });
    }, DEBOUNCE_SEARCH_QUERY_DELAY), []);
    const { data, isLoading, hasNextPage, fetchNextPage } = useFetchMarketProductsQuery({ buyerUrlsafeKey: buyer.urlsafe, params: filters, type: fetchTypeSelected });
    const pages = data?.pages || [];
    const hasResults = pages?.length && pages[0].total > 0;
    const displayedProductsCount = hasResults ? pages[0].total : 0;
    useTitle(tNavigation('Shop'));

    useEffect(() => {
        scrollToTop(PAGE_WRAPPER_CONTENT_ID);
    }, []);

    useEffect(() => {
        dispatch(loadCartOrders(true));
        return () => {
            debounceSearchQuery.cancel();
        };
    }, []);

    useEffect(() => {
        if (isIntersecting && hasNextPage) {
            fetchNextPage();
        }
    }, [isIntersecting, hasNextPage]);
    return (
        <div className="lg:px-10">
            <MarketProvider filters={filters}
                setFilters={setFilters}
                setActiveProduct={setActiveProduct}
                activeProduct={activeProduct}
                fetchTypeSelected={fetchTypeSelected}
                setFetchTypeSelected={setFetchTypeSelected}>
                <SwitchOrderGuidesModal />
                <DeleteOrderGuideModal/>
                <AddProductToOrderGuidesModal/>
                <AddEditNewOrderGuideModal/>
                <UnsaveProductModal/>
                <Container fluid as={'section'}>
                    {showFiltersModal ? <MarketFiltersModal fetchType={fetchTypeSelected}
                        setOpen={setShowFiltersModal}
                        isOpen={showFiltersModal}/> : null}
                    <MarketHeader productsDisplayed={displayedProductsCount}
                        isLoadingProducts={isLoading}
                        onClickFiltersBtn={(): void => {
                            setShowFiltersModal(true);
                            sendTrackingEvent(TrackingEvents.shopFilterClicked);
                        }}
                        onSearch={debounceSearchQuery}/>
                    <Container as={'section'}
                        className={'mt-2'}
                        fluid>
                        {isLoading
                    && getProductCardsPlaceholder(DEFAULT_PRODUCTS_PER_PAGE)
                        }
                        {pages?.map((page, index) => <React.Fragment key={index}>
                            {page?.results?.map((product, idx) => <React.Fragment key={`${product.sku}-${idx}`}><Container as={'article'}
                                className={'lg:py-6 py-5'}>
                                <ProductCard product={product}/>
                            </Container>
                            <Separator/></React.Fragment>)}
                        </React.Fragment>)}
                        {!hasResults ? getMarketEmptyState(fetchTypeSelected) : null}
                    </Container>
                    <article className="mx-auto container py-5"
                        ref={bottomElement}>
                        {hasNextPage && <ProductCardPlaceholder/>}
                    </article>
                </Container>
            </MarketProvider>
        </div>
    );
};
export default MarketPage;

import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import { ScanOverviewDetailsItem } from '@v2/components/ScanReview/ScanOverview/ScanOverviewDetailsItem';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { EApPlatform, getLineItems, LineItemCategory, LineItemCategoryEnum, LineItemFocusType, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { getLineItemCategory, getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { ScanDoubleSyncOnboardingTooltip } from '@v2/components/ScanReview/ScanDoubleSyncOnboarding/ScanDoubleSyncOnboardingTooltip';
import { DoubleSyncOnboardingSteps } from '@v2/stores/DoubleSyncOnboardingStore';
import { Tabs } from './ScanOverview';

const getIcon = (title: string, type: 'info' | 'warn' = 'info'): React.ReactNode => {
    const isInfo = type === 'info';
    const isWarn = type === 'warn';
    return <div className={cx('px-2 pt-0.5 rounded-md  max-h-5 ', { 'bg-teal-100': isInfo, 'bg-orange-100': isWarn })}>
        <div className="flex gap-1 items-center">
            <div className={cx('w-1.5 h-1.5 rounded-full ', { 'bg-teal-500': isInfo, 'bg-orange-400': isWarn })}/>
            <Typography variant="BASE" className="text-gray-700 m-0">{title}</Typography></div>
    </div>;
};

const renderLineItemsIcon = (formErrors, lineItemCategory: LineItemCategory): React.ReactNode => {
    const totalRequiredFiledsCount = formErrors[lineItemCategory]?.requiredFields?.totalCount ?? 0;
    const totalLowConfidenceFields = formErrors[lineItemCategory]?.lowConfidenceFields?.totalCount ?? 0;

    return <>
        {totalRequiredFiledsCount > 0 && getIcon(`${totalRequiredFiledsCount}`, 'warn')}
        {totalLowConfidenceFields > 0 && getIcon(`${totalLowConfidenceFields}`, 'info')}
    </>;
};

/**
 * A component that displays a list of scan overview details items, including basic details and line items.
 *
 * @returns JSX.Element
 */
export const ScanOverviewDetailsList : React.FC = () => {
    const { setFocusType, setShowFocusPanel, formErrors, draftInvoice, validateForm } = useOcrInvoiceStore();
    const { setCurrentView, selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const accountingRequiredFields = ['accountingVendorID'].filter((field) => !draftInvoice?.topLevelData?.[field]);
    const basicInfoRequiredFields = ['externalReferenceID'].filter((field) => !draftInvoice?.topLevelData?.[field]);
    const accountingConfigurationType = getAccountingConfigurationType(draftInvoice.accountingConfiguration);
    const hasAccountingMissingFields = draftInvoice?.isQBOEnabled && (accountingRequiredFields.length > 0 && accountingConfigurationType !== EApPlatform.BIGCHIP);
    const hasBasicInfoMissingFields = basicInfoRequiredFields.length > 0;
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview' });
    const missingFieldsCount = basicInfoRequiredFields.length + (hasAccountingMissingFields ? accountingRequiredFields.length : 0);
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const hasImsIntegrationInfoFetched = hasImsIntegration !== null;

    // For this line item we ignore chart of accounts because they get calculated
    const lineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, true, hasImsIntegration);
    const lineItems = getLineItems(draftInvoice, lineItemCategory, useSeparateIMSLineItems);
    const lineItemsArrayLength = lineItems?.length;

    // For this line item we include chart of accounts if auto calculate is on
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);

    const showLineItemsIcon = (lineItemCategoryToValidate !== LineItemCategoryEnum.ChartOfAccountDetails || draftInvoice?.isAutoCalculateOn) && hasImsIntegrationInfoFetched;

    React.useEffect(() => {
        validateForm(lineItemCategoryToValidate);
    }, [selectedTab]);

    return <>
        <ScanOverviewDetailsItem title={t('basicDetails')}
            description={t('supplierDeliveryDatesEtc')}
            icon={(hasAccountingMissingFields || hasBasicInfoMissingFields) && getIcon(`${missingFieldsCount}`, 'warn')}
            separator
            onClick={(): void => {
                setCurrentView(ScanReviewViews.BasicDetails);
            }}/>
        {((selectedTab === Tabs.ACCOUNTING || selectedTab === Tabs.NO_TABS) && accountingConfigurationType)
            && <ScanOverviewDetailsItem title={<ScanDoubleSyncOnboardingTooltip step={DoubleSyncOnboardingSteps.ChartOfAccounts} placement="right" arrowClassName="[&>span]:top-0 [&>span]:bottom-0 [&>span]:my-auto [&>span]:mx-0" trigger={<div>{t('ChartOfAccountDetails.chartOfAccountDetails')}</div>} /> }
                description={t('ChartOfAccountDetails.glCodes', { count: draftInvoice?.chartOfAccountDetails?.length ?? 0 })}
                icon={lineItemCategoryToValidate === LineItemCategoryEnum.ChartOfAccountDetails && !draftInvoice?.isAutoCalculateOn && renderLineItemsIcon(formErrors, lineItemCategoryToValidate)}
                separator
                onClick={(): void => {
                    setCurrentView(ScanReviewViews.ChartOfAccountDetails);
                    setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);
                    setShowFocusPanel(false);
                }
                }/>}
        <ScanOverviewDetailsItem title={t('LineItems.lineItems')}
            description={t('LineItems.items', { count: lineItemsArrayLength || 0 })}
            icon={showLineItemsIcon && renderLineItemsIcon(formErrors, lineItemCategoryToValidate)}
            separator
            onClick={(): void => {
                setCurrentView(ScanReviewViews.LineItems);
                setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);
                setShowFocusPanel(false);
            }}/>
    </>;
};

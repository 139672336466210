import React, { useContext } from 'react';
import { Button, Typography, Checkbox, Popover, Skeleton, twMergeCustom } from '@notch-ordering/ui-components';
import IconRightArrow from '@icons/arrow-right-icon.svg';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import MoreIcon from '@icons/more-icon.svg';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';

export interface OrderGuideListItemProps {
    id: string,
    label: string,
    showCheckbox?: boolean,
    onClick: (id:string) => void,
    isSelected?: boolean,
    icon?: React.ReactNode,
    productCount?: number,
    showActions?: boolean,
}
const SELECTED_CLASS = 'text-teal-300 font-semibold';

export const OrderGuideListItem: React.FC<OrderGuideListItemProps> = ({ label, id, showCheckbox, onClick, isSelected, icon, productCount, showActions }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Shop' });
    const { setModalVisibility, setOrderGuideIDSelected } = useContext(MarketContext);
    const onChange = (val: string, isChecked: boolean):void => {
        if (isChecked) {
            onClick(val);
        }
    };
    const selectedClassName = { [SELECTED_CLASS]: isSelected };
    const typographyWeight = isSelected ? 'font-semibold' : 'font-regular';
    const itemLabel = (<div className="flex flex-col gap-1"><span>{label}</span>
        {productCount > 0 && <Typography variant="BASE" className="text-gray-600 m-0 p-0">
            {t('productsCount', { count: productCount })}
        </Typography>}
    </div>);
    return <div
        onClick={(): void => {
            onClick(id);
        }}
        className="px-4 lg:px-5 flex w-full cursor-pointer">
        <div className="gap-4 flex items-center w-full">
            <Button as="div" variant="TERTIARY_FILLED" size="ICON_MEDIUM" className="flex items-center justify-center pointer-events-none">
                <span className={cx('h-4 w-4 inline-flex', selectedClassName)}>{icon}</span>
            </Button>
            {showCheckbox
                ? <Checkbox label={itemLabel}
                    value={id}
                    checked={isSelected}
                    onChange={onChange}
                    className={cx(selectedClassName)}
                    labelClassName={cx(selectedClassName)}
                    position="right"/>
                : <Typography as="span"
                    weight={typographyWeight}
                    className={twMergeCustom(cx('text-gray-700', selectedClassName))}>
                    {itemLabel}
                </Typography>}
            {!showCheckbox && <div className="ml-auto flex gap-2 items-center">
                {showActions && <Popover
                    stopPropagation
                    className="w-48 ml-auto"
                    button={<Button variant="ICON" size="ICON_MEDIUM" className="pr-6" stopPropagation={false}><MoreIcon className="w-4 h-4"/></Button>}
                    items={[{
                        stopPropagation: true,
                        label: tCommon('rename'),
                        onClick: (): void => {
                            setModalVisibility('addEditOrderGuide', true);
                            setOrderGuideIDSelected(id);
                        }
                    }, {
                        label: <span className="inline-flex text-red-300">{tCommon('Buttons.delete')}</span>,
                        onClick: ():void => {
                            setModalVisibility('deleteOrderGuide', true);
                            setOrderGuideIDSelected(id);
                        }
                    }
                    ]}/>}
                <span className="h-4 w-4 inline-flex  text-gray-500"><IconRightArrow/></span></div>}
        </div>

    </div>;
};

export const OrderGuideListItemPlaceholder = <div className="px-4 lg:px-10 flex w-full cursor-pointer">
    <Skeleton size={'full'} className="h-10"/>
</div>;

import React, { useContext } from 'react';
import { Typography, Button, Separator } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import ProductVariant from '@v2/components/Market/ProductPackage/ProductPackage';
import { IPackage } from '@v2/network/types';
import useGetOrderBySupplierCatalogId from '@v2/hooks/useGetOrderBySupplier.hook';
import { useDispatch } from 'react-redux';
import { calculatePackagePrice, getPackageFromOrder, mapPackageToOrderProduct } from '@v2/utils/ShopUtils';
import { ProductCardContext } from '@v2/components/Market/ProductCard/ProductCardContext';
import { EMarkAsSavedToggleButtonVariant,
    MarkAsSavedToggleButton } from '@v2/components/Market/MarkAsSavedToggleButton/MarkAsSavedToggleButton';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import Utils from '@/utils';
import { changeQuantityWithID, changeQuantityWithoutID, saveNoteCart } from '@/actions/orderActions';

export type IAddToCartCardProps = {
    isLoading?: boolean,
    onCancelClick?: () => void,
    setIsOpen?: (isOpen: boolean) => void,
    name?: string,
    supplierCatalogID?: string,
    isFavorite?: boolean,
    supplierName?: string,
    packages?: [],
};

export const AddToCartCard: React.FC<IAddToCartCardProps> = ({
    isLoading,
    setIsOpen,
}) => {
    const dispatch = useDispatch();
    const { productsInOrder = [], product } = useContext(ProductCardContext);
    const productPackagesInOrder = product.packages?.map((pp) => getPackageFromOrder(productsInOrder, pp?.id)).filter((pp) => !!pp) ?? [];
    const noteInProductPackages = productPackagesInOrder.find((op) => op?.notes !== '')?.notes || '';
    const { supplierName, isSaved, packages, supplierCatalogID, name } = product;
    const isMobileWidth = useIsMobileWidth();
    const buttonSize = isMobileWidth ? 'LARGE' : 'MEDIUM';
    const [updatedPackages, setUpdatedPackages] = React.useState<IPackage[]>([]);
    const [notes, setNotes] = React.useState<string>(noteInProductPackages);
    const order = useGetOrderBySupplierCatalogId(supplierCatalogID);
    const updatePackage = (updatedPackage: IPackage):void => {
        setUpdatedPackages((prevPackages) => {
            const newPackages = [...prevPackages];
            const index = newPackages.findIndex((item) => item.id === updatedPackage.id);
            if (index !== -1) {
                newPackages[index] = updatedPackage;
            } else {
                newPackages.push(updatedPackage);
            }
            return newPackages;
        });
    };
    const handleChangeQuantity = (count: number, updatedPackage: IPackage): void => {
        updatePackage(updatedPackage);
    };

    const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setNotes(e.target.value);
    };

    const handleAddToCartClick = (): void => {
        if (updatedPackages.length > 0) {
            updatedPackages.forEach((pack) => {
                const orderProduct = getPackageFromOrder(productsInOrder, pack.id);
                if (orderProduct && orderProduct.urlsafe) {
                    // TODO convert custom to variable
                    dispatch(changeQuantityWithID(order.urlsafe, orderProduct, pack.quantityInCart, Utils.QUANTITY_TYPES.CUSTOM, '', false, true, notes));
                } else {
                    const mappedProduct = mapPackageToOrderProduct({
                        product,
                        packageProduct: pack,
                        notes,
                    });
                    dispatch(changeQuantityWithoutID(order.urlsafe, orderProduct, pack.quantityInCart, Utils.QUANTITY_TYPES.CUSTOM, null, mappedProduct, false, true, notes));
                }
            });
            setIsOpen(false);
        } else {
            packages.forEach((pack) => {
                const orderProduct = getPackageFromOrder(productsInOrder, pack.id);
                if (orderProduct) {
                    dispatch(saveNoteCart(order.urlsafe, orderProduct, notes));
                }
            });
            setIsOpen(false);
        }
        sendTrackingEvent(TrackingEvents.addToCart);
    };
    return (
        <div className="h-full flex flex-col">
            <div className={'lg:px-5 px-4 lg:pt-0 pt-10 pb-6'}>
                <div className="flex">
                    <div className={'w-4/5'}>
                        <Typography
                            as="div"
                            variant="XL"
                            className="text-gray-700 mb-2"
                            weight="font-medium">{name}
                        </Typography>
                        <Typography
                            as="div"
                            className="text-gray-600">
                            {supplierName}
                        </Typography>
                    </div>

                </div>
            </div>
            <Separator/>
            <div className={'p-0 max-h-80 overflow-auto'}>
                {
                    packages.map((pack) => <ProductVariant key={pack.id}
                        onChange={handleChangeQuantity}
                        price={Utils.formatAsCurrency(calculatePackagePrice(pack))}
                        productPackage={pack}/>)
                }

            </div>
            <Separator variant="large" />
            <div className={'px-4 pt-5 pb-0 mt-auto'}>
                <div className="flex items-center justify-between flex-col gap-3">
                    <textarea
                        onInput={(e): void => {
                            e.currentTarget.style.height = '5px';
                            e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
                        }}
                        onChange={handleNoteChange}
                        value={notes}
                        className="resize-none min-h-0 w-full rounded-[10px] overflow-hidden font-base font-regular text-gray-700 placeholder-gray-600 appearance-none h-10 box-border border border-gray-400 px-4 outline-plum-500"
                        placeholder="Add a note..."/>

                    <div className="flex gap-2 w-full">
                        <Button variant="SECONDARY"
                            size={buttonSize}
                            className={'w-full flex-1'}
                            onClick={handleAddToCartClick}
                            loading={isLoading}
                            disabled={updatedPackages.every((pack) => pack.quantityInCart === 0)}>
                            <Typography as="span" weight="font-medium">
                                {tCommon('Buttons.addToCart')}
                            </Typography>
                        </Button>
                        <div>
                            <MarkAsSavedToggleButton popOverPlacement="top" variant={EMarkAsSavedToggleButtonVariant.Gray} isSaved={isSaved} product={product}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddToCartCard;

import React, { DragEvent, useRef } from 'react';
import { Banner, Button, DragAndDropUpload, EBannerType, Popover, Typography } from '@notch-ordering/ui-components';
import FileAttachmentIcon from '@icons/file-attachment-icon.svg';
import UploadIcon from '@icons/upload-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import InfoOutlineIcon from '@icons/info-outline-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import FileWarningIcon from '@icons/file-error-icon.svg';

export interface BulkUploadFileStepProps {
    setFile: (file?: File) => void,
    file?: File,
    errors?: string[],
    setErrors: (errors: string[]) => void,
}

export const BulkUploadFileStep:React.FC<BulkUploadFileStepProps> = ({ setFile, file = null, errors, setErrors }) => {
    const inputRef = useRef(null);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'BulkUpload' });
    const hasFileSelected = !!file;
    const STORAGE_BUCKET_URL = process.env.GOOGLE_STORAGE_BUCKET_URL ?? '';

    const hasErrors = errors?.length > 0;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
            return;
        }
        setFile(fileObj);
    };

    const onDragFile = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
    };

    const onDropFile = (event: DragEvent<HTMLDivElement>): void => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            // validate if file is csv
            const fileObj = event.dataTransfer.files[0];
            if (fileObj?.type !== 'text/csv') {
                return;
            }
            setFile(fileObj);
        }
    };

    const onClickUploadFile = (): void => {
        inputRef.current.click();
    };

    const redirectToSampleFile = (url:string = ''): void => {
        window.open(url, '_blank');
    };

    return <div className="mx-4 my-6 lg:mx-10">
        <div className="mb-6">
            <Banner className="w-full relative"
                alertType={EBannerType.SUBDUED}
                title={<div className="flex flex-col">
                    <div className="w-full flex items-center">
                        <Typography as="div" weight="font-medium">{t('useCsvTemplate')}</Typography>
                        <div className="ml-auto"><Popover
                            button={
                                <Button variant="LINK"
                                    size="NO_BACKGROUND"
                                    stopPropagation={false}
                                    className="mr-auto justify-self-end right-4 top-2.5 flex items-center relative">
                                    {tCommon('Buttons.download')}
                                    <ChevronDownIcon className="w-4 h-4 ml-2"/>
                                </Button>
                            }
                            items={[
                                {
                                    label: t('excelFormat'),
                                    onClick: (): void => redirectToSampleFile(`${STORAGE_BUCKET_URL}templates/bulk-upload/Notch Upload Template.xlsx`),
                                },
                                {
                                    label: t('appleNumbersFormat'),
                                    onClick: (): void => redirectToSampleFile(`${STORAGE_BUCKET_URL}templates/bulk-upload/Notch Upload Template.numbers`),
                                }, {
                                    label: t('googleSheetsFormat'),
                                    onClick: (): void => redirectToSampleFile('https://docs.google.com/spreadsheets/d/1Dm3qTJJ4bxMlLmR22-F2wtNU-Fu48CdLsNdwyFf3RX4')
                                },
                            ]}/></div>
                    </div>
                    <div>
                        <Typography as="div" className="text-gray-600">{t('needHelp')} <a rel="noopener noreferrer" target="_blank" href="https://intercom.help/notchordering/en/collections/3750018-bulk-upload-catalog-instructions">{t('viewTheGuide')}</a></Typography>
                    </div>
                </div>}
                icon={<InfoOutlineIcon className="w-4 h-4 text-gray-700"/>}/></div>
        <div/>

        {!hasFileSelected && <DragAndDropUpload
            className = "hover:bg-white hover:border-dashed"
            icon={<UploadIcon className="w-5 h-5 text-gray-600"/>}
            onInputChange={handleFileChange}
            onClick={onClickUploadFile}
            onDrop={onDropFile}
            onDragOver={onDragFile}
            fileTypes={'.csv'}
            uploadDesc={t('clickToUpload')}
            typeDesc={t('onlyCsvFiles')}
            inputRef={inputRef}/>}

        { hasFileSelected
            && <div className="p-7 border border-dashed rounded-xl">
                <Typography weight="font-medium" className="mb-4">{t('uploadedFile')}</Typography>
                <div className="flex gap-2 items-center">
                    <span className="w-4 inline-flex">
                        {hasErrors ? <FileWarningIcon/> : <FileAttachmentIcon/>}
                    </span>
                    <Typography weight="font-medium" className="m-0 p-0">{file.name}</Typography>
                    <span className="w-4 inline-flex cursor-pointer ml-auto"
                        onClick={():void => {
                            setFile(null);
                            setErrors([]);
                        }}><TrashIcon/></span>
                </div>
                {hasErrors && <div className="mt-1 flex gap-2 items-center">
                    <span className="w-4 inline-flex" />
                    <Typography as="div" className="flex flex-col gap-1 text-red-300">
                        {errors.map((message, index) => <div key={index}>{message}</div>)}
                    </Typography>

                </div>}
            </div>}
    </div>;
};

import React from 'react';
import PropTypes from 'prop-types';

const ChannelHeader = ({ title, time, unread }) => {
  return (
    <div className="list-header">
      <div className="vendor-name">{title}</div>
      <div className="info">
        <div
          className={`message-time ${
            time === 'Today' || time === 'Yesterday' ? 'highlight' : ''
          }`.trim()}
        >
          {time}
        </div>
        <i className="fa fa-angle-right" aria-hidden="true" />
        {!!unread && <div className="oval">{unread}</div>}
      </div>
    </div>
  );
};

export default ChannelHeader;

ChannelHeader.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string.isRequired,
  unread: PropTypes.number.isRequired,
};

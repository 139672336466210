import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

import LocationsBox from './LocationsBox.jsx';
import LocationsForm from './forms/LocationsForm.jsx';
import ModalBox from '../../general/ModalBox.jsx';
import LoadingData from '../../LoadingData.jsx';
import SettingsBackButton from '../header/SettingsBackButton.jsx';
import { basicCustomStyles } from '../../../constants/CustomStyles';

class LocationsMain extends Component {
  getModalView = () => {
    const {
      showLocationsModal,
      loadingSettingsModal,
      toggleLocationsModal,
      addShippingAddress,
    } = this.props;

    return (
      <div>
        <Modal
          isOpen={showLocationsModal}
          style={basicCustomStyles}
          ariaHideApp={false}
        >
          <ModalBox
            title={'Edit Shipping Location'}
            activateSave={true}
            loading={false}
            showDelete={false}
            onClickCancel={toggleLocationsModal}
            onClickClose={toggleLocationsModal}
            customStyles={{ height: '100%', marginTop: '8%' }}
            showHeader={true}
            showFooter={false}
            outsideClickIgnoreClass={'pac-container'}
          >
            {loadingSettingsModal ? (
              <LoadingData display={loadingSettingsModal} text="Saving" />
            ) : (
              <LocationsForm
                {...this.props}
                onClickCancel={toggleLocationsModal}
                onSubmit={addShippingAddress}
              />
            )}
          </ModalBox>
        </Modal>
      </div>
    );
  };

  onClickChange = (index) => {
    const { toggleLocationsModal, updateLocationsModalIndex } = this.props;

    toggleLocationsModal();
    updateLocationsModalIndex(index);
  };

  render() {
    const { loadingSpecificSettings, shippingAddresses, router } = this.props;

    return (
      <>
        <SettingsBackButton router={router} />
        {loadingSpecificSettings ? (
          <LoadingData
            display={loadingSpecificSettings}
            text="Loading Shipping Locations"
          />
        ) : (
          <div>
            {shippingAddresses.map((location, index) => (
              <LocationsBox
                {...this.props}
                location={location}
                key={index}
                onClickEdit={this.onClickChange.bind(null, index)}
              />
            ))}
          </div>
        )}
        {this.getModalView()}
      </>
    );
  }
}

LocationsMain.propTypes = {
  shippingAddresses: PropTypes.arrayOf(PropTypes.any).isRequired,
  showLocationsModal: PropTypes.bool.isRequired,
  loadingSpecificSettings: PropTypes.bool.isRequired,
  loadingSettingsModal: PropTypes.bool.isRequired,

  toggleLocationsModal: PropTypes.func.isRequired,
  addShippingAddress: PropTypes.func.isRequired,
  updateLocationsModalIndex: PropTypes.func.isRequired,
  router: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default connect((dispatch) => {
  return {
    // This will be passed as a property to the presentational component
    changeFieldValue: (field, value) => {
      dispatch(change('LocationsForm', field, value));
    },
  };
})(LocationsMain);

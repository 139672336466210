import React, { useEffect, useState } from 'react';
import { Separator, Typography } from '@notch-ordering/ui-components';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { connectToQBO,
    GetClassesResponse,
    GetOAuthConnectURLResponse,
    getSupplierMappings,
    GetTaxCodesResponse,
    SupplierMapping,
    SupplierMappings,
    TaxCodeSchema,
    updateSupplierMappings,
    VendorDetails,
    getAccountingTaxCodes,
    getAccountingVendors,
    getRutterAccessToken,
    getAccountingClasses,
    ApClassSchema,
    TaxCalculationSettings,
    CompanySchema,
    getAccountingCompany,
    getInitialSyncComplete,
    getSubsidiaries,
    RutterConfig,
    RutterSubsidiary } from '@v2/network/BushwhackAPI';
import useAccountHook from '@v2/hooks/useAccount.hook';
import AccountingConnectedModal from '@v2/Pages/Integrations/AccountingConnectedModal';
import { logError } from '@v2/utils/logError';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { EOnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import AddCreditCardModal from '@v2/components/Shared/AddCreditCard/AddCreditCardModal';
import { useLinkedSupplierCatalogs } from '@v2/hooks/useLinkedSupplierCatalogs';
import { EApPlatform, AccountingConfiguration, EErpPlatform, EConfigurationType, getAccountingConfiguration } from '@notch-ordering/shared-logic';
import { useRutterLink } from 'react-rutter-link';
import { FETCH_ACCOUNTING_INTEGRATIONS } from '@v2/components/Bills/PayBillModal';
import { useQuery } from '@tanstack/react-query';
import { tNamespace, tNavigation } from '@v2/i18n';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import OCIcon from '@images/integrations/oc-icon.svg';
import MarketmanIcon from '@images/integrations/marketman-icon.svg';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { getOCConfiguration, OCConfiguration } from '@v2/network/IntegrationAPI';
import { ComboOption } from '../Home/components/Combobox';
import { IntegrationsActive } from './IntegrationsActive';
import { IntegrationsExplore } from './IntegrationsExplore';
import { ERPIntegrations, IntegrationDisplayData, activeIconHeight, activeIconWidth, exploreIconHeight, exploreIconWidth, formatDate } from './IntegrationsUtils';
import IntegrationsMapSuppliersModal from './IntegrationsMapSuppliersModal';
import IntegrationsTaxSettingsModal from './IntegrationsTaxSettingsModal';
import { ALL_ACCOUNTING_INTEGRATIONS, integrationIcons, integrationNameKeys, updateIntegrationConfiguration } from './IntegrationPlatformUtils';
import IntegrationsImportClassesModal from './IntegrationsImportClassesModal';
import { IntegrationsCompleteModal } from './IntegrationsCompleteModal';

const FETCH_ACCOUNTING_INTEGRATION_INITIAL_SYNC = 'FETCH_ACCOUNTING_INTEGRATION_INITIAL_SYNC';

enum Tabs {
    ACTIVE,
    EXPLORE
}

let accountingPlatform: EApPlatform = null;
let configID: string = null;
let accessToken: string = null;
let accountingConfig: AccountingConfiguration = null;

export const Integrations: React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    useTitle(tNavigation('Integrations'));
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.ACTIVE);
    // use two Separators on top of each other to get a thicker line
    const selectedTabIndicator = <div className="mt-2.5">
        <Separator className="bg-gray-700" />
        <Separator className="bg-gray-700" />
    </div>;
    const { fetchQuery: supplierCatalogsQuery, supplierCatalogs } = useLinkedSupplierCatalogs(false);
    const { isLoading, isError } = supplierCatalogsQuery({ retry: 2, retryDelay: 10000 }); // TODO: error handling?
    const { buyer } = useBuyerHook();
    const [isCreditCardModalOpen, setIsCreditCardModalOpen] = useState(false);
    const buyerHasPaymentMethodConfigured = !!buyer.stripeCustomerID;
    const account = useAccountHook();
    const { completeTask, isTaskComplete } = useOnboardingStore();
    const [accountingConfigID, setAccountingConfigID] = useState<string>(null);
    const [accountingVendors, setAccountingVendors] = useState<Array<VendorDetails>>([]);
    const [subsidiaries, setSubsidiaries] = useState<Array<RutterSubsidiary>>([]);
    const [accountingClasses, setAccountingClasses] = useState<ApClassSchema[]>([]);
    const [taxCodes, setTaxCodes] = useState<TaxCodeSchema[]>([]);
    const [companyName, setCompanyName] = useState<string>('');
    const [supplierMappingDefaults, setSupplierMappingDefaults] = useState<SupplierMappings>({});
    const [showConnectedModal, setShowConnectedModal] = useState(false);
    const [shownConnectedModal, setShownConnectedModal] = useState(false);
    const [showMapSuppliersModal, setShowMapSuppliersModal] = useState(false);
    const [showTaxSettingsModal, setShowTaxSettingsModal] = useState(false);
    const [showImportClassesModal, setShowImportClassesModal] = useState(false);
    const [showIntegrationCompleteModal, setShowIntegrationCompleteModal] = useState(false);
    const accountingIntegration = useQuery([FETCH_ACCOUNTING_INTEGRATIONS, buyer.urlsafe], () => getAccountingConfiguration(buyer.urlsafe), { enabled: !!buyer.urlsafe, cacheTime: 0, retry: 1 });
    const isPlatformEnabled = !!buyer.urlsafe && !!accessToken && !!accountingPlatform;
    const isInitialIntegrationSyncComplete = useQuery([FETCH_ACCOUNTING_INTEGRATION_INITIAL_SYNC, buyer.urlsafe], () => getInitialSyncComplete(buyer.urlsafe, accountingPlatform, accessToken), { enabled: isPlatformEnabled, cacheTime: 0, retry: 1 });
    const [selectedAccountingClass, setSelectedAccountingClass] = useState<ApClassSchema>(accountingIntegration?.data?.data?.classData ?? { name: '', id: '' });
    const [selectedTaxCalculationSettings, setSelectedTaxCalculationSettings] = useState<TaxCalculationSettings>(accountingIntegration?.data?.data?.taxCalculationSettings ?? TaxCalculationSettings.ACCOUNTING);
    const [selectedTaxCode, setSelectedTaxCode] = useState<ApClassSchema>(accountingIntegration?.data?.data?.taxCode ?? { name: '', id: '' });
    const [selectedTaxCodeNonTaxable, setSelectedTaxCodeNonTaxable] = useState<ApClassSchema>(accountingIntegration?.data?.data?.taxCodeNonTaxable ?? { name: '', id: '' });
    const [activeAccountingIntegration, setActiveAccountingIntegration] = useState<IntegrationDisplayData>(null);
    const [activeERPIntegration, setActiveERPIntegration] = useState<IntegrationDisplayData>(null);

    useEffect(() => {
        async function checkERPIntegration(): Promise<void> {
            // For now, since the only ERP integration we have is OC, just look for the OC config
            const config = await getOCConfiguration(buyer.urlsafe).catch(() => {});
            const ocConfig = config as OCConfiguration;
            if (ocConfig) {
                setActiveERPIntegration({
                    name: t(integrationNameKeys(EErpPlatform.OPTIMUMCONTROL)),
                    date: formatDate(new Date(ocConfig.created_at)),
                    activeIcon: integrationIcons(EErpPlatform.OPTIMUMCONTROL, activeIconHeight, activeIconWidth),
                    platform: EErpPlatform.OPTIMUMCONTROL
                });
            }
        }
        checkERPIntegration();
    }, [buyer.urlsafe]);

    const completeConnection = (): void => {
        if (!accountingIntegration.data) {
            return;
        }

        const accountingConfigType = getAccountingConfigurationType(accountingIntegration.data);

        const fetchAccountingDetails = async (): Promise<void> => {
            const company: CompanySchema | void = await getAccountingCompany(accountingConfigType, buyer.urlsafe, accessToken).catch((e) => {
                logError('Unable to get the Accounting company', e, false);
            });
            if (company) {
                setCompanyName(company.companyName);
            }

            const vendorsResponse = await getAccountingVendors(accountingPlatform, buyer.urlsafe, accessToken).catch((e) => {
                logError('Unable to get Accounting vendors', e, false);
            });

            const vendors: Array<VendorDetails> = vendorsResponse ? vendorsResponse as Array<VendorDetails> : [];
            setAccountingVendors(vendors);

            // Netsuite uses subsidiaries for tax purposes and for creating new Vendors
            if (accountingPlatform === EApPlatform.NETSUITE) {
                const subsidiariesResponse = await getSubsidiaries(accountingPlatform, buyer.urlsafe, accessToken).catch((e) => {
                    logError('Unable to get Accounting subsidiaries', e, false);
                });

                const subsidiariesList: Array<RutterSubsidiary> = subsidiariesResponse ? subsidiariesResponse as Array<RutterSubsidiary> : [];
                setSubsidiaries(subsidiariesList);
            }

            // skip getting classes for MD365 as it's not supported
            if (accountingPlatform !== EApPlatform.MD365) {
                const classesResponse = await getAccountingClasses(accountingPlatform, buyer.urlsafe, accessToken).catch((e) => {
                    logError('Unable to get Accounting classes', e, false);
                });

                const classes: Array<ApClassSchema> = classesResponse ? (classesResponse as GetClassesResponse).classes : [];
                setAccountingClasses(classes);
            }

            const taxesResponse = await getAccountingTaxCodes(accountingPlatform, buyer.urlsafe, accessToken).catch((e) => {
                logError('Unable to get Accounting tax codes', e, false);
            });

            const result: Array<ApClassSchema> = taxesResponse ? (taxesResponse as GetTaxCodesResponse).taxCodes : [];

            setTaxCodes(result);
        };

        if (isInitialIntegrationSyncComplete.data) {
            fetchAccountingDetails();
        }

        if (!shownConnectedModal && isInitialIntegrationSyncComplete.data) {
            const accountingConnected: boolean = !accountingIntegration.data.data?.taxCode?.id;
            setShowConnectedModal(accountingConnected);
            setShownConnectedModal(true);
        }
    };

    const checkConnected = (accountingConfiguration: AccountingConfiguration): void => {
        accountingConfig = accountingConfiguration;
        const accountingConfigType = getAccountingConfigurationType(accountingConfiguration);
        const accountingData = accountingConfiguration.data;

        const getAccountingSupplierMappings = async (): Promise<void> => {
            const mappings: SupplierMappings | void = await getSupplierMappings(accountingPlatform, buyer.urlsafe, accessToken).catch((e) => {
                logError('Unable to get supplier mappings', e, false);
            });

            if (mappings) {
                setSupplierMappingDefaults(mappings);
            }
        };

        if (accountingConfiguration?.id && accountingConfiguration?.isEnabled) {
            switch (accountingConfiguration.type) {
                case EConfigurationType.ApRutter:
                case EConfigurationType.QBO:
                    configID = accountingConfiguration.id;
                    accountingPlatform = getAccountingConfigurationType(accountingConfiguration);
                    accessToken = accountingData.accessToken;
                    break;
                default:
                    break;
            }
        }

        if (accountingPlatform !== null) {
            setAccountingConfigID(configID);
            getAccountingSupplierMappings();

            setSelectedAccountingClass(accountingData.classData ?? { name: '', id: '' });
            setSelectedTaxCode(accountingData.taxCode ?? { name: '', id: '' });
            setSelectedTaxCodeNonTaxable(accountingData.taxCodeNonTaxable ?? { name: '', id: '' });
            setSelectedTaxCalculationSettings(accountingIntegration?.data?.data?.taxCalculationSettings ?? TaxCalculationSettings.ACCOUNTING);

            if (isInitialIntegrationSyncComplete.isFetched) {
                completeConnection();
            }

            if (!isTaskComplete(buyer.urlsafe, EOnboardingTasks.SETUP_INTEGRATION)) {
                completeTask(buyer.urlsafe, EOnboardingTasks.SETUP_INTEGRATION);
            }
        }

        switch (accountingConfigType) {
            case EApPlatform.QBO:
            case EApPlatform.NETSUITE:
            case EApPlatform.XERO:
            case EApPlatform.BIGCHIP:
            case EApPlatform.MD365:
            case EApPlatform.QBD:
                setActiveAccountingIntegration({
                    name: t(integrationNameKeys(accountingConfigType)),
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    date: formatDate(new Date(accountingConfiguration.createdAt ?? (accountingConfiguration.data as any).createdAt)), // QBO createdAt is inside the data object
                    activeIcon: integrationIcons(accountingConfigType, activeIconHeight, activeIconWidth)
                });
                break;
            default:
                setActiveAccountingIntegration(null);
                break;
        }
    };

    const exchangeForRutterAccessToken = async (buyerUrlsafeKey: string, publicToken: string): Promise<void> => {
        const response = await getRutterAccessToken(buyerUrlsafeKey, publicToken, accountingPlatform).catch((e) => {
            logError('Unable to exchange publicToken for accessToken', e, false);
        });

        if (response) {
            accountingConfig = response;
            accessToken = response.data.accessToken;

            checkConnected(response);
        }
    };

    function onRutterSuccess(publicToken: string): void {
        // Exchange public token for accessToken
        exchangeForRutterAccessToken(buyer.urlsafe, publicToken);
    }

    const rutterConfig = {
        publicKey: process.env.RUTTER_PUBLIC_KEY,
        onSuccess: onRutterSuccess,
        debug: process.env.RUTTER_DEBUG
    };

    const { open } = useRutterLink(rutterConfig); // TODO: implement error, ready, exit.

    const getClassFromCombo = (accountingClassCombo: ComboOption): ApClassSchema => {
        const accountingClass: ApClassSchema = { name: accountingClassCombo.label, id: accountingClassCombo.value, };
        return accountingClass;
    };
    const getTaxCodeFromCombo = (taxCodeCombo: ComboOption): TaxCodeSchema => {
        const taxCode: TaxCodeSchema = { name: taxCodeCombo.label, id: taxCodeCombo.value, taxable: true, description: '' };
        return taxCode;
    };

    const onConfigUpdate = (config: AccountingConfiguration): void => {
        accountingConfig = config;
    };

    const onEditTaxSettingsSave = (taxCalculationSettings: TaxCalculationSettings, taxCodeSelected: ComboOption, taxCodeNonTaxableSelected: ComboOption, subsidiarySelected?: ComboOption): void => {
        // Save tax code choices to backend
        const taxCode: TaxCodeSchema = getTaxCodeFromCombo(taxCodeSelected);
        const taxCodeNonTaxable: TaxCodeSchema = getTaxCodeFromCombo(taxCodeNonTaxableSelected);
        const subsidiary: string | undefined = subsidiarySelected?.value;
        const configData: RutterConfig = {
            taxCode,
            taxCodeNonTaxable,
            taxCalculationSettings,
            subsidiary
        } as RutterConfig;
        updateIntegrationConfiguration(accountingPlatform, configID, accountingConfig, configData, true, onConfigUpdate);
        setSelectedTaxCalculationSettings(taxCalculationSettings);
    };

    const onEditEnableClassesSave = (accountingClassCombo: ComboOption): void => {
        // Save enabled classes to backend
        const classData: ApClassSchema = getClassFromCombo(accountingClassCombo);
        setSelectedAccountingClass(classData);
        updateIntegrationConfiguration(accountingPlatform, configID, accountingConfig, { classData }, true, onConfigUpdate);
    };

    const onEditSuppliersSave = (supplierMappings: SupplierMappings): void => {
        // Save to backend the Supplier mapping choices
        const saveSupplierMappings = async (): Promise<void> => {
            const mappings: SupplierMapping[] = [];

            if (supplierMappings) {
                Object.keys(supplierMappings).map((supplierUrlsafe) => {
                    const accountingVendorID: string = supplierMappings[supplierUrlsafe];
                    mappings.push({
                        apIntegrationID: accountingConfigID,
                        supplierUrlsafeKey: supplierUrlsafe,
                        apVendorID: accountingVendorID
                    });

                    return null;
                });
            }

            if (mappings.length > 0) {
                await updateSupplierMappings(accountingPlatform, buyer.urlsafe, mappings, accessToken);
            }

            setSupplierMappingDefaults({ ...supplierMappingDefaults, ...supplierMappings });
        };
        saveSupplierMappings();
    };

    const onConnectedClose = (): void => {
        setShowConnectedModal(false);
        setShowMapSuppliersModal(true);
    };

    const onSupplierMapClose = (): void => {
        setShowMapSuppliersModal(false);
        setShowTaxSettingsModal(true);
    };

    const onTaxSettingsClose = (): void => {
        setShowTaxSettingsModal(false);

        if (accountingClasses.length > 0) {
            setShowImportClassesModal(true);
        } else {
            setShowIntegrationCompleteModal(true);
        }
    };

    const onImportClassesClose = (): void => {
        setShowImportClassesModal(false);
        setShowIntegrationCompleteModal(true);
    };

    useEffect(() => {
        if (accountingIntegration.data) {
            checkConnected(accountingIntegration.data);
        }
    }, [accountingIntegration.isFetched]);

    useEffect(() => {
        if (activeAccountingIntegration !== null) {
            completeConnection();
        }
    }, [accountingIntegration.isFetched && isInitialIntegrationSyncComplete.isFetched]);

    const onQBOConnect: () => void = () => {
        accountingPlatform = EApPlatform.QBO;

        connectToQBO(buyer.urlsafe, account.email, document.location.href)
            .then((data: GetOAuthConnectURLResponse) => {
                sendTrackingEvent(TrackingEvents.integrationsClicked); // TODO: add to Rutter
                // Redirect to Intuit website for login
                const redirectURL: string = data.qb_url;

                document.location.href = redirectURL;
            });
    };

    const onRutterConnect: () => void = () => {
        // Rutter platforms require specific casing:
        // https://docs.rutter.com/supported-platforms
        let platform: string;
        switch (accountingPlatform) {
            case EApPlatform.NETSUITE:
                platform = 'NETSUITE';
                break;
            case EApPlatform.XERO:
                platform = 'XERO';
                break;
            case EApPlatform.MD365:
                platform = 'DYNAMICS365';
                break;
            case EApPlatform.QBD:
                platform = 'QUICKBOOKS_DESKTOP';
                break;
            default:
        }

        open({ platform });
    };

    function onClickAccountingConnect(plat: EApPlatform): void {
        accountingPlatform = plat;
        configID = null;
        accessToken = null;
        accountingConfig = null;

        switch (accountingPlatform) {
            case EApPlatform.QBO:
                if (buyerHasPaymentMethodConfigured) {
                    onQBOConnect();
                } else {
                    setIsCreditCardModalOpen(true);
                }
                break;
            case EApPlatform.NETSUITE:
            case EApPlatform.XERO:
            case EApPlatform.MD365:
            case EApPlatform.QBD:
                if (buyerHasPaymentMethodConfigured) {
                    onRutterConnect();
                } else {
                    setIsCreditCardModalOpen(true);
                }
                break;
            default:
                // Do nothing
                break;
        }
    }

    const allERPIntegrations: Array<IntegrationDisplayData> = Object.values(ERPIntegrations).map((integration) => {
        switch (integration) {
            case ERPIntegrations.OC:
                return {
                    name: t('ocName'),
                    description: t('ocDescription'),
                    exploreIcon: <OCIcon className={`${exploreIconWidth} ${exploreIconHeight}`} />
                };
            case ERPIntegrations.MARKETMAN:
                return {
                    name: t('marketmanName'),
                    description: t('marketmanDescription'),
                    exploreIcon: <MarketmanIcon className={`${exploreIconWidth} ${exploreIconHeight}`} />
                };
            default:
                return null;
        }
    });

    const accountingConfigType = getAccountingConfigurationType(accountingIntegration?.data);

    return (
        <>
            {/* Page title */}
            <Typography
                as="div"
                weight="font-medium"
                variant="4XL"
                className="p-10 text-gray-700 flex content-start">
                {tNavigation('Integrations')}
            </Typography>
            {/* Tabs */}
            <div className="px-10 flex flex-row gap-6">
                {/* "Active" */}
                <div className="cursor-pointer" onClick={(): void => { setSelectedTab(Tabs.ACTIVE); }}>
                    <Typography
                        as="div"
                        className={cx({ 'font-regular': selectedTab !== Tabs.ACTIVE, 'font-medium': selectedTab === Tabs.ACTIVE })}>
                        {t('active')}
                    </Typography>
                    {selectedTab === Tabs.ACTIVE && selectedTabIndicator}
                </div>
                {/* "Explore" */}
                <div className="cursor-pointer" onClick={(): void => { setSelectedTab(Tabs.EXPLORE); }}>
                    <Typography
                        as="div"
                        className={cx({ 'font-regular': selectedTab !== Tabs.EXPLORE, 'font-medium': selectedTab === Tabs.EXPLORE })}>
                        {t('explore')}
                    </Typography>
                    {selectedTab === Tabs.EXPLORE && selectedTabIndicator}
                </div>
            </div>
            {/* Separator */}
            <Separator />
            {/* Tab contents */}
            {selectedTab === Tabs.ACTIVE && <IntegrationsActive
                onClickBrowse={(): void => { setSelectedTab(Tabs.EXPLORE); }}
                activeAccountingIntegration={accountingIntegration?.data ? activeAccountingIntegration : null}
                activeERPIntegration={activeERPIntegration}
                accountingIntegrationConfig={accountingIntegration?.data}
                integrationPlatform={accountingConfigType}
                notchSuppliers={supplierCatalogs}
                accountingVendors={accountingVendors}
                subsidiaries={subsidiaries}
                supplierMappingDefaults={supplierMappingDefaults}
                companyName={companyName}
                taxCodes={taxCodes}
                accountingClasses={accountingClasses}
                refetch={accountingIntegration.refetch}
                onSave={onEditSuppliersSave}
                onConfigUpdate={onConfigUpdate}
                isInitialIntegrationSyncComplete={isInitialIntegrationSyncComplete.isFetched ? isInitialIntegrationSyncComplete.data ?? false : false}
                isWaitingOnSyncInfo={!isInitialIntegrationSyncComplete.isFetched} />
            }
            {selectedTab === Tabs.EXPLORE && <IntegrationsExplore
                onClickConnect={onClickAccountingConnect}
                activeIntegrations={[accountingIntegration?.data ? activeAccountingIntegration?.name : null, activeERPIntegration?.name]}

                accountingIntegrations={Object.values(ALL_ACCOUNTING_INTEGRATIONS).map((integration) => ({
                    name: t(integration.nameKey),
                    description: t(integration.descriptionKey),
                    platform: integration.accountingPlatform,
                    exploreIcon: <integration.exploreIcon className={`${exploreIconWidth} ${exploreIconHeight}`} />
                }))}
                erpIntegrations={allERPIntegrations}
                hasAccountingIntegration={!!accountingIntegration?.data}
                hasERPIntegration={activeERPIntegration !== null} />}

            <AddCreditCardModal setIsOpen={setIsCreditCardModalOpen}
                isOpen={isCreditCardModalOpen}
                onSuccess={onQBOConnect} />

            {/* Integration Setup Modals */}
            {
                showConnectedModal && <AccountingConnectedModal
                    setIsOpen={setShowConnectedModal}
                    isOpen={showConnectedModal}
                    onClose={onConnectedClose}
                    integrationPlatform={accountingConfigType}/>
            }
            {
                showMapSuppliersModal && !(isLoading || isError) && <IntegrationsMapSuppliersModal
                    setIsOpen={setShowMapSuppliersModal}
                    isOpen={showMapSuppliersModal}
                    onSave={onEditSuppliersSave}
                    onClose={onSupplierMapClose}
                    notchSuppliers={supplierCatalogs}
                    accountingVendors={accountingVendors}
                    supplierMappingDefaults={supplierMappingDefaults}
                    integrationPlatform={accountingConfigType} />
            }
            {
                showTaxSettingsModal && !(isLoading || isError) && <IntegrationsTaxSettingsModal
                    setIsOpen={setShowTaxSettingsModal}
                    isOpen={showTaxSettingsModal}
                    onSave={onEditTaxSettingsSave}
                    onClose={onTaxSettingsClose}
                    integration={accountingIntegration?.data?.type}
                    taxCalculationSettingsDefault={selectedTaxCalculationSettings}
                    taxCodes={taxCodes}
                    taxCodeDefault={ selectedTaxCode?.id ? selectedTaxCode : (taxCodes?.[0] ?? selectedTaxCode)}
                    taxCodeNonTaxableDefault={ selectedTaxCodeNonTaxable?.id ? selectedTaxCodeNonTaxable : (taxCodes?.[0] ?? selectedTaxCodeNonTaxable)}
                    subsidiaries={subsidiaries}
                    integrationPlatform={accountingConfigType}/>
            }
            {
                showImportClassesModal && !(isLoading || isError) && <IntegrationsImportClassesModal
                    setIsOpen={setShowImportClassesModal}
                    isOpen={showImportClassesModal}
                    onSave={onEditEnableClassesSave}
                    onClose={onImportClassesClose}
                    accountingClasses={accountingClasses}
                    accountingClassDefault={selectedAccountingClass}
                    integrationPlatform={accountingConfigType} />
            }
            {
                showIntegrationCompleteModal && !(isLoading || isError) && <IntegrationsCompleteModal
                    close={(): void => {
                        setShowIntegrationCompleteModal(false);
                    }}
                    isOpen={showIntegrationCompleteModal}
                    refetch={accountingIntegration.refetch}/>
            }
        </>
    );
};

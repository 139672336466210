const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    if (!values.lastName) {
      errors.firstName = 'You must have either a first name or last name';
    }
  }

  if (!values.lastName) {
    if (!values.firstName) {
      errors.lastName = 'You must have either a first name or last name';
    }
  }

  if (!values.password) {
    if (values.password || values.password2) {
      errors.password = 'You must enter your old password';
    }
  }

  if (!values.password1 || !values.password2) {
    if (values.password) {
      errors.password1 = 'You must enter a new password';
    }
  }

  if (!values.password1) {
    if (values.password && values.password2) {
      errors.password1 = 'You must enter new password twice';
    }
  }

  if (!values.password2) {
    if (values.password && values.password1) {
      errors.password1 = 'You must re-enter password';
    }
  }

  if (values.password && values.password1 && values.password2) {
    if (values.password1 !== values.password2) {
      errors.password1 = "New passwords don't match";
    }
  }

  return errors;
};

export default validate;

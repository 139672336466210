import React, { useMemo } from 'react';
import { Button, Popover, PopoverItem } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { ScanTopNavigation } from '@v2/components/ScanReview/ScanTopNavigation/ScanTopNavigation';
import { getLineItems, LineItemCategory, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemFieldByIndex, onNavigate } from '@v2/components/ScanReview/ScanReviewUtils';
import { mergeClassNames } from '@v2/utils/Helpers';
import cx from 'classnames';
import { getLineItemCategory, getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
// import { AddLineItem } from './AddLineItem';

const lineItemsOptions = [
    { label: 'Add Product', value: 'product' },
    { label: 'Add Tax', value: 'tax' },
    { label: 'Add Fee', value: 'fee' },
] as const;

export const ScanLineItemsTopNavigation : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.LineItems' });

    const { addFormLineItem, validateForm, validateTopLevelData, draftInvoice } = useOcrInvoiceStore();
    const { setCurrentView, selectedTab, hasImsIntegration } = useOcrInvoiceContext();

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);

    // For this line item we ignore chart of accounts because they get calculated
    const lineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, true, hasImsIntegration);

    // For this line item we include chart of accounts if auto calculate is on
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);

    // const [tax, setTax] = React.useState<ScanLineItemsPopoverInputValue>(draftInvoice?.globalTaxRate);

    // const [discount, setDiscount] = React.useState<ScanLineItemsPopoverInputValue>(0);
    const onSubmit = async (): Promise<void> => {
        setCurrentView(ScanReviewViews.Overview);
    };
    // const handleTaxApply = (): void => {
    //     updateGlobalTaxRate(+tax);
    // };

    const lineItemsPopoverItems = useMemo<PopoverItem[]>(
        () => lineItemsOptions
            .map(({ label, value }) => ({
                label,
                onClick: (): void => {
                    addFormLineItem(value, lineItemCategory);
                    validateForm(lineItemCategoryToValidate);
                    validateTopLevelData();
                    setTimeout(() => {
                        const lineItems = getLineItems(draftInvoice, lineItemCategory, useSeparateIMSLineItems);
                        const lastElementIndex = lineItems?.length;
                        if (lastElementIndex) {
                            const inputElement = getLineItemFieldByIndex(lastElementIndex, 'name');
                            onNavigate(inputElement);
                        }
                    }, 100);
                },
            })),
        [],
    );

    return (
        <ScanTopNavigation title={t('lineItems')}
            rightContent={
                <>
                    {/* <Button variant="TERTIARY_FILLED"
                        size="SMALL"
                        onClick={(): void => {
                        // append new field functionality goes here
                            addFormLineItem();
                            validateForm();
                            setTimeout(() => {
                                const lastElementIndex = draftInvoice?.lineItems?.length;
                                if (lastElementIndex) {
                                    const inputElement = getLineItemFieldByIndex(lastElementIndex, 'name');
                                    onNavigate(inputElement);
                                }
                            }, 100);
                        }}>
                        {t('newItem')}
                    </Button> */}
                    <Popover
                        className={cx('w-48')}
                        buttonClassName="ml-auto"
                        closePanelOnClick
                        button={
                            <Button variant="TERTIARY_FILLED"
                                size="MEDIUM"
                                className={mergeClassNames('px-2.5 py-1.5 rounded-lg')}
                                stopPropagation={false}>
                        New line item
                            </Button>
                        }
                        items={lineItemsPopoverItems}/>
                    {/* <AddLineItem /> */}
                    {/*     This will be commented for in case we want to include global discount or global tax in the feature */}
                    {/* <ScanLineItemsPopover title={t('discountAmount')} */}
                    {/*     onApply={(value):void => console.log(value)} */}
                    {/*     inputPlaceholder="%" */}
                    {/*     value={discount} */}
                    {/*     setValue={(value): void => { */}
                    {/*         setDiscount(value); */}
                    {/*     } */}
                    {/*     }> */}
                    {/*     <Button variant="TERTIARY_FILLED" */}
                    {/*         className="flex gap-2.5 items-center" */}
                    {/*         stopPropagation={false} */}
                    {/*         as="span" */}
                    {/*         size="SMALL" */}
                    {/*         onClick={(): void => { */}
                    {/*         } */}
                    {/*         }> */}
                    {/*         {t('discounts')} */}
                    {/*         <ChevroDownIcon className="w-4 h-4 text-gray-700 ml-auto block "/> */}
                    {/*     </Button> */}
                    {/* </ScanLineItemsPopover> */}
                    {/* <ScanLineItemsPopover title={t('tax')} */}
                    {/*     onApply={handleTaxApply} */}
                    {/*     inputPlaceholder="%" */}
                    {/*     value={tax} */}
                    {/*     setValue={setTax}> */}
                    {/*     <Button variant="TERTIARY_FILLED" */}
                    {/*         className="flex gap-2.5 items-center" */}
                    {/*         as="span" */}
                    {/*         stopPropagation={false} */}
                    {/*         size="SMALL"> */}
                    {/*         {t('tax')} */}
                    {/*         <ChevroDownIcon className="w-4 h-4 text-gray-700 ml-auto block "/> */}
                    {/*     </Button> */}
                    {/* </ScanLineItemsPopover> */}
                    <Button variant="SECONDARY"
                        size="SMALL"
                        onClick={onSubmit}>
                        {tCommon('Buttons.done')}
                    </Button>
                </>
            }/>
    );
};

// Git revert last commit
// git reset --hard HEAD~1

import React from 'react';
import { Button, Modal, Typography } from '@notch-ordering/ui-components';
import WarningIcon from '@icons/warning-filled-icon.svg';
import DangerIcon from '@icons/danger-icon.svg';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';

export interface ScanOverviewDuplicatedInvoiceModalProps {
    isOpen: boolean,
    close: () => void,
    invoiceNumber: string,
    onConfirm: () => void,
}

export const ScanOverviewDuplicatedInvoiceModal : React.FC<ScanOverviewDuplicatedInvoiceModalProps> = ({
    close,
    isOpen,
    invoiceNumber,
    onConfirm,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.Errors' });
    return <Modal isOpen={isOpen}
        desktopModalWidth="lg:w-[450px]"
        close={close}
        headerPadding="pt-0 pb-4"
        topPadding="pt-5"
        leftPadding="pl-5"
        rightPadding="pr-5"
        title={<DangerIcon className="w-8 h-8"/>}
        icon={<WarningIcon className="h-8 w-8 text-orange-400"/>}>
        <div className="px-5 pb-5">
            <Typography className="mt-0 mb-2" weight="font-semibold" variant="LG-2">{t('qboDuplicatedBill')}</Typography>
            <Typography className="m-0 text-gray-600">{t('theDuplicatedBillIs')} #{invoiceNumber}</Typography>
        </div>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={close}>
                <Typography as="span" weight="font-medium">
                    {tCommon('Buttons.cancel')}
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                size="SMALL"
                onClick={onConfirm}>
                <Typography as="span" weight="font-medium">
                    {tCommon('Buttons.overwrite')}
                </Typography>
            </Button>
        </div>

    </Modal>;
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { showCart } from "../../actions/cartActions";
import { changeRoute } from "../../actions/routerActions";
import { getBuyer } from "../../selectors/buyerSelectors";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";
import { EPaths } from "@v2/constants/EPaths";

export default function CartEmpty() {
  const dispatch = useDispatch();
  const buyer = useSelector(getBuyer);

  const basketImage = `${BUCKET_ASSETS_URL}basket.svg`;

  const shopTheMarket = () => {
    // TODO: Use `useLocation` hook from `react-router` instead.
    const location = window.location;
    const currentPage = location.pathname;
    const marketPage = EPaths.SHOP;
    const isAlreadyOnMarketPage = currentPage.includes(marketPage);

    if (!isAlreadyOnMarketPage) {
      dispatch(changeRoute(marketPage));
    }

    dispatch(showCart(false));
  };

  return (
    <div className="multi-cart-empty">
      <img className="multi-cart-empty__basket-image" src={basketImage} />

      <h1>Your basket is empty</h1>

      <span className="multi-cart-empty__description">
        You have not added any items to your basket.'
      </span>

      <button
        className="btn btn-primary rounded-lg bg-gray-700 hover:bg-gray-675 active:bg-gray-650 multi-cart-empty__shop-mkt-btn"
        onClick={shopTheMarket}
      >
        Shop for products
      </button>
    </div>
  );
}

import { IPackage } from '@v2/network/types';
import ProductCardPlaceholder from '@v2/components/Market/ProductCard/ProductCardPlaceholder';
import React from 'react';
import { Container } from '@notch-ordering/ui-components';
import { FavoriteCheckBox } from '@v2/components/Shared/FavoriteCheckBox/FavoriteCheckBox';

import { generatePlaceholdersByCount } from '@v2/utils/Loader';
import { TFetchProductsType } from '@v2/network/SecretShopAPI';
import { calculatePackagePrice } from '@v2/utils/ShopUtils';
import Utils from '@/utils';
// eslint-disable-next-line import/no-named-as-default
import MarketEmptyState from '../../components/Market/EmptyFavorites/MarketEmptyState';

export const getProductPricesRange = (packages: IPackage[]): string => {
    if (!packages || packages.length === 0) {
        return '';
    }
    const prices = packages.map((p) => calculatePackagePrice(p));
    const priceRanges = {
        min: Math.min(...prices),
        max: Math.max(...prices),
    };
    if (priceRanges.min === priceRanges.max) {
        return Utils.formatAsCurrency(priceRanges.min);
    }
    return `${Utils.formatAsCurrency(priceRanges.min)} - ${Utils.formatAsCurrency(priceRanges.max)}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getElementsCount = (count: number): number[] => Array.from({ length: count }, (_v, k) => k);

export const getProductCardsPlaceholder = (count: number): React.ReactNode => generatePlaceholdersByCount(<Container as={'article'} className={'py-5'}><ProductCardPlaceholder/></Container>, count);

export const EMPTY_STATE_VALUES = {
    [TFetchProductsType.SAVED]: {
        title: 'No products saved',
        description: <>See all products in “View full catalog” and tap the <FavoriteCheckBox
            className={'w-4 relative top-2'}/> on a product to add to your order guide </>,
    },
    [TFetchProductsType.ORDER_GUIDE]: {
        title: 'You haven\'t saved any items in this order guide',
        description: <>Find new items to add in the explore tab and press the <FavoriteCheckBox
            className={'w-4 relative top-2'}/> icon on an item to add it to your order guides</>
    },
    [TFetchProductsType.SEARCH]: {
        title: 'No products available',
        description: 'Make sure you added the suppliers you order from and they have items in their catalogue'
    }
};

export const getMarketEmptyState = (fetchProductsType: TFetchProductsType): React.ReactNode => {
    const emptyKey = fetchProductsType;
    return <MarketEmptyState title={EMPTY_STATE_VALUES[emptyKey].title}
        description={EMPTY_STATE_VALUES[emptyKey].description}/>;
};

export interface IActiveFilters {
    categories?: string[],
    supplierCatalogs?: string[],
    inStockOnly?: boolean,
    favorite?: boolean,
    limit?: number,
    cursor?: string,
    name?: string,
    orderGuideID?: string,
    sort?: string,
    direction?: string,
}

export const DEBOUNCE_SEARCH_QUERY_DELAY = 1000;

export const DEFAULT_PRODUCTS_PER_PAGE = 20;

export const FILTERS_INITIAL_STATE: IActiveFilters = {
    categories: [],
    supplierCatalogs: [],
    inStockOnly: true,
    favorite: false,
    name: '',
    limit: DEFAULT_PRODUCTS_PER_PAGE,
};

import React from 'react';
import { Button } from '@notch-ordering/ui-components';
import MinusIcon from '@icons/minus-icon.svg';
import AddIcon from '@icons/add-icon.svg';
import { EKeyboardConstants } from '@v2/constants/KeyboardConstants';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

export interface Props {
    allowZero?: boolean,
    onChange: (value: number) => void,
    value: number,
}

export const Counter: React.FC<Props> = ({ allowZero, onChange, value }) => {
    const MIN_NUMBER = allowZero ? 0 : 1;
    const [count, setCount] = React.useState(value || MIN_NUMBER);
    const changeCount = (val: number): void => {
        if (val < MIN_NUMBER) {
            return;
        }
        setCount(val);
        onChange(val);
    };

    const handleMinus = (): void => {
        if (count > MIN_NUMBER) {
            changeCount(count - 1);
            sendTrackingEvent(TrackingEvents.productQuantityDecreased);
        }
    };

    const handleAdd = (): void => {
        changeCount(count + 1);
        sendTrackingEvent(TrackingEvents.productQuantityIncreased);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { value: currentVal, validity: { valid } } = event.target;
        const isValidInput = valid && currentVal;
        if (isValidInput) {
            changeCount(parseInt(currentVal, 10));
        } else {
            changeCount(MIN_NUMBER);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const { key } = event;
        if (key === EKeyboardConstants.ENTER) {
            changeCount(count);
        }
        if (key === EKeyboardConstants.ARROW_UP) {
            handleAdd();
        }
        if (key === EKeyboardConstants.ARROW_DOWN) {
            handleMinus();
        }
    };

    return (
        <div className="flex">
            <Button
                variant="TERTIARY_FILLED"
                size="ICON_MEDIUM"
                className={
                    'flex items-center rounded-r-none z-0'
                }
                onClick={handleMinus}>
                <MinusIcon className={'w-4 h-4'} />
            </Button>
            <div
                className={
                    'w-10 h-10 bg-gray-200 focus:ring-plum-500 focus:ring-1 text-center'
                }>
                <input
                    value={count}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    pattern="[0-9]*"
                    className={
                        'box-border appearance-none w-full h-full bg-gray-200 outline-plum-500 outline-1 focus:ring-plum-500 focus:ring-1 text-center p-0'
                    }/>
            </div>
            <Button
                onClick={handleAdd}
                variant="TERTIARY_FILLED"
                size="ICON_MEDIUM"
                className={
                    'flex items-center rounded-l-none'
                }>
                <AddIcon className={'w-4 h-4'}/>
            </Button>
        </div>
    );
};

export default Counter;

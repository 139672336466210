import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link } from 'react-router';

export default class NavLink extends Component {
  render() {
    const { to, isActive } = this.props;
    const selectedClassName = isActive ? 'active-tab' : '';

    return (
      <Link to={to}>
        <div className={`side-nav-bar-tab ${selectedClassName}`}>
          {this.props.children}
        </div>
      </Link>
    );
  }
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

import { Button, Loading } from '@notch-ordering/ui-components';
import { useQuery } from '@tanstack/react-query';
import { Order, getOrder } from '@v2/network/LegacyAPI';
import React from 'react';
import { browserHistory } from 'react-router';
import cx from 'classnames';

type Props = {
    orderUrlsafeKey: string,
    billNumber: string,
};

const FETCH_BILL_ORDER_NUMBER = 'FETCH_BILL_ORDER_NUMBER';

export const BillsOrderNumberColumn : React.FC<Props> = ({ orderUrlsafeKey, billNumber }) => {
    const { data: orderResponse, isLoading } = useQuery<{ data: Order }>(
        [FETCH_BILL_ORDER_NUMBER, orderUrlsafeKey],
        () => getOrder(orderUrlsafeKey),
        {
            enabled: !!orderUrlsafeKey,
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

    return <Button variant="TEXT"
        size="NO_BACKGROUND"
        className={cx('p-0', { 'cursor-default': !orderUrlsafeKey })}
        onClick={async (): Promise<void> => {
            if (orderUrlsafeKey) {
                browserHistory.push(`/order_detail/${orderUrlsafeKey}`);
            } else {
                browserHistory.push(`/bill/${billNumber}`);
            }
        }}>
        { isLoading && !!orderUrlsafeKey ? <Loading isDark />
            : <>
                { orderResponse ? orderResponse?.data?.id.toString(10) : '--' }
            </>}
    </Button>;
};

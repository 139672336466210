import React from 'react';

import OrderItem from '../order-item';

const OrderItems = ({ items }) => (
  <div className="OrderItems">
    {items.map((item) => (
      <OrderItem key={`${item.name}`} {...item} />
    ))}
  </div>
);

export default OrderItems;

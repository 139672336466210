import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import { t } from '@v2/i18n';
import { UploadAsset } from '@v2/components/Uploads/UploadAsset/UploadAsset';
import { COLUMN_NO_VALUE } from '@v2/Pages/Uploads/UploadsConstants';
import { UploadOcrStateBadge } from '@v2/components/Uploads/UploadOcrStateBadge/UploadOcrStateBadge';
import { UploadOcrLabelBadge } from '@v2/components/Uploads/UploadOcrLabelBadge/UploadOcrLabelBadge';
import { OcrInvoice } from '@v2/network/FalsePromiseApi';
import { OcrLabel } from '@v2/types/UploadsTypes';
import Utils from '@/utils';

export interface UploadsMobileTableCellProps {
    ocrInvoice: OcrInvoice,
}

export const UploadsMobileTableCell : React.FC<UploadsMobileTableCellProps> = ({ ocrInvoice }) => {
    const rejectReason = ocrInvoice.metadata?.rejectReason as OcrLabel;

    const badge = rejectReason
        ? <UploadOcrLabelBadge ocrLabel={rejectReason}/>
        : <UploadOcrStateBadge ocrState={ocrInvoice?.ocrState}/>;

    return <UploadAsset className="w-full"
        urls={ocrInvoice?.cdnUrls ?? []}
        supplierName={ocrInvoice?.supplierName ?? ''}
        invoiceNumber={ocrInvoice.externalReferenceID ?? ''} >
        <div className="flex min-w-0 px-3 flex-col gap-2 ">
            <div className="flex gap-3 justify-between items-center ">
                <div className="space-y-2 w-9/12">
                    <Typography className="text-gray-600 m-0"
                        variant="BASE">{t('Uploads.uploadedOn')} {Utils.formatDate(ocrInvoice.createdAt, 'MMM DD, YYYY')}</Typography>
                    <Typography className="m-0">{ocrInvoice.externalReferenceID ?? COLUMN_NO_VALUE}</Typography>
                    <Typography className="m-0 truncate text-gray-600">{ocrInvoice.supplierName ?? COLUMN_NO_VALUE}</Typography>
                    {badge}
                </div>
                <div className="flex-shrink-0 flex gap-2 ml-auto items-center">
                    <UploadAsset className="w-10 h-10 object-cover rounded-lg"
                        urls={ocrInvoice?.cdnUrls ?? []}
                        supplierName={ocrInvoice?.supplierName ?? ''}
                        invoiceNumber={ocrInvoice.externalReferenceID ?? ''}/>
                </div>
                <div className="flex" />
            </div>

        </div>
    </UploadAsset>;
};

import { DeliveryDay, Region } from '@v2/network/CoreAPI';
import { toDate, zonedTimeToUtc } from 'date-fns-tz';

export type LocalDeliveryDate = { deliveryDate: Date, cutoffDate: Date, date?: string };

/**
 * Delivery dates and cutoff time are confusing to display on the frontend because
 * of inconsistencies in how they are stored in the backend.
 *
 * Dates (Day-Month-Year) are given in local time and should be displayed as is.
 * Time (HH:MM:SS) however, is in UTC and should be converted.
 *
 * Simply putting the date and time together and converting from UTC to local
 * can cause the wrong date to be displayed.
 * E.g. Sept 27, 3am UTC => Sept 26, 11pm EST, but the expected displayed date is Sept 27, 11pm.
 *
 * This converts the date and time strings as received from the backend into the expected Date in
 * local time as objects
 *
 * @param supplierRegion - Supplier's region data containing the available delivery days and cutoff time
 * @returns An array of the supplier's delivery days with their dates and cutoff dates as JS Date objects in local time
 */
export function getDeliveryDatesInLocalTime(supplierRegion: Region): LocalDeliveryDate[] {
    const localDeliveryDays: LocalDeliveryDate[] = [];
    for (let i = 0; i < supplierRegion?.nextAvailableDeliveryDays?.length; i++) {
        const deliveryDate: DeliveryDay = supplierRegion.nextAvailableDeliveryDays[i];

        // The delivery date should be taken as is with no time zone conversion
        const localDate: Date = toDate(deliveryDate.date);

        // Combine the cutoff date and time as a UTC timestamp and get the date back in local time
        // The date needs to be included here so the time is converted accounting for Daylight Savings Time properly
        const localCutoffDate: Date = zonedTimeToUtc(toDate(`${deliveryDate.cutoffDate}T${supplierRegion.cutOffTime}`), 'UTC');
        // HOWEVER, the date should stay as is while we take the converted time
        // This is due to poor backend design. The cutoffTime is in UTC, but the date should remain as is
        const givenCutoffDate: Date = toDate(deliveryDate.cutoffDate);
        // Now overwrite the date in the converted time to the cutoffDate as given
        localCutoffDate.setDate(givenCutoffDate.getDate());
        localCutoffDate.setMonth(givenCutoffDate.getMonth());
        localCutoffDate.setFullYear(givenCutoffDate.getFullYear());

        localDeliveryDays.push({
            deliveryDate: localDate,
            cutoffDate: localCutoffDate
        });
    }

    return localDeliveryDays;
}

import React from 'react';
import classNames from 'classnames';

const renderFieldDropDown = ({
  input,
  options,
  selected,
  label,
  placeholder,
  className,
  meta: { active, touched, error },
}) => {
  let labelClassName = '';
  if (!input.value || (input.value && active)) {
    labelClassName = 'move-label';
  }

  return (
    <div className="input-group">
      <select
        className={classNames(className, { 'form-error': error && touched })}
        {...input}
      >
        <option value="">{placeholder}</option>
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
      <label className={`field-label ${labelClassName}`}>{label}</label>
      <div className="redux-form-error">
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

export default renderFieldDropDown;

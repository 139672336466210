import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import useIsMobileWidth from '../../../domains/shared/hooks/useIsMobileWidth';

import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

const MembersTable = (props) => {
  const isMobileWidth = useIsMobileWidth();
  const membersTable = useRef(null);
  const [showActions, updateShowAction] = useState(false);

  const onClickEdit = (memberUrlsafe, event) => {
    // stop the row from being selected (event bubble)
    event.stopPropagation();

    createOrUpdateMember(memberUrlsafe);
  };

  const onClickAddNew = (memberUrlsafe) => {
    createOrUpdateMember(memberUrlsafe);
    sendTrackingEvent(TrackingEvents.newStaffClicked);
  };

  const createOrUpdateMember = (memberUrlsafe) => {
    const { toggleMembersModal, updateMembersModalIndex } = props;

    // Open members modal
    toggleMembersModal();

    // Update the index to the correct member object
    updateMembersModalIndex(memberUrlsafe);
  };

  const onClickDelete = () => {
    props.toggleDeleteMembersModal();
  };

  const onClickCancel = () => {
    // hide action buttons
    updateShowAction(false);

    unSelectAllMembers();
  };

  const unSelectAllMembers = () => {
    // Unselect all members
    membersTable.current.cleanSelected();
  };

  // Format of the member name cells
  const memberFormat = (cell, row) => {
    const { account } = props;
    const isNotManagerDeletingOwner = account.roles
      ? account.roles[0].name !== 'Manager' || row.roles[0].name !== 'Owner'
      : false;

    return (
      <span className="full-name">
        <span
          onClick={
            isNotManagerDeletingOwner && onClickEdit.bind(null, row.urlsafe)
          }
        >
          {row.account.firstName} {row.account.lastName}
        </span>
        <div className="role">{row.roles[0].name}</div>

        {account.urlsafe === row.account.urlsafe ? (
          <div className="current-account"> Your Account</div>
        ) : (
          ''
        )}
      </span>
    );
  };

  // Format of the member email cells
  const emailFormat = (cell, row) => {
    return <span className="email">{row.account.email}</span>;
  };

  // Format of the member notification cells
  const notificationFormat = (cell, row) => {
    return (
      <span className="receive-notification">
        {row.receiveNotification ? 'Yes' : 'No'}
      </span>
    );
  };

  // Format of the member edit button cell
  const editbuttonFormat = (cell, row) => {
    const { account } = props;
    const isNotManagerDeletingOwner = account.roles
      ? account.roles[0].name !== 'Manager' || row.roles[0].name !== 'Owner'
      : false;

    return (
      <div>
        {isNotManagerDeletingOwner && (
          <button
            className="btn btn-xs btn-edit"
            onClick={onClickEdit.bind(null, row.urlsafe)}
          >
            Edit
          </button>
        )}
      </div>
    );
  };

  // Control showing of actions based on selecting one member
  const onRowSelect = (row, isSelected) => {
    if (isSelected) {
      updateShowAction(true);
    } else {
      // Array of selected memberIDs.
      // The length is 1 because the size of the selectRowKeys is delayed
      // until after the click
      if (membersTable.current.state.selectedRowKeys.length > 1) {
        updateShowAction(true);
      } else {
        updateShowAction(false);
      }
    }
  };

  // Control showing of actions based on selecting all members
  const onSelectAll = (isSelected) => {
    if (isSelected) {
      updateShowAction(true);
    } else {
      updateShowAction(false);
    }
  };

  const { members } = props;

  const options = {
    sortOrder: 'asc',
    defaultSortName: 'firstName',
  };

  const selectRowProp = {
    mode: 'checkbox',
    clickToSelect: true,
    onSelect: onRowSelect,
    onSelectAll: onSelectAll,
  };

  return (
    <div>
      <div className="settings-add-new-btn">
        {showActions ? (
          <div className="action-btns pull-left">
            <button className="btn btn-link btn-delete" onClick={onClickDelete}>
              Delete
            </button>
            <button className="btn btn-link btn-cancel" onClick={onClickCancel}>
              Cancel
            </button>
          </div>
        ) : (
          ''
        )}
        <button
          className="btn btn-link pull-right"
          onClick={() => onClickAddNew(0)}
        >
          + Add Another Member
        </button>
      </div>
      <BootstrapTable
        ref={membersTable}
        data={members}
        striped={true}
        hover={true}
        selectRow={selectRowProp}
        options={options}
        trClassName="members-table-tr"
      >
        <TableHeaderColumn
          dataField="firstName"
          dataFormat={memberFormat}
          dataSort={true}
        >
          Member
        </TableHeaderColumn>

        {!isMobileWidth && (
          <TableHeaderColumn
            dataField="email"
            dataFormat={emailFormat}
            dataSort={true}
          >
            Email
          </TableHeaderColumn>
        )}
        {!isMobileWidth && (
          <TableHeaderColumn
            dataField="receiveNotification"
            dataFormat={notificationFormat}
          >
            Receives Notifications
          </TableHeaderColumn>
        )}

        <TableHeaderColumn
          dataField="urlsafe"
          isKey={true}
          dataFormat={editbuttonFormat}
          width="85px"
        />
      </BootstrapTable>
    </div>
  );
};

MembersTable.propTypes = {
  members: PropTypes.array.isRequired,
  account: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.array.isRequired,

  toggleMembersModal: PropTypes.func.isRequired,
  toggleDeleteMembersModal: PropTypes.func.isRequired,
  updateMembersModalIndex: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
};

export default MembersTable;

import React from "react";
import { IndexRedirect, IndexRoute, Redirect, Route } from "react-router";

// Wrapped Routes
import CheckoutWrapper from "./containers/app/CheckoutWrapper.jsx";
import IndexWrapper from "./containers/app/IndexWrapper.jsx";
import AppWrapper from "./containers/app/AppWrapper.jsx";
import LoggedInWrapper from "./containers/app/LoggedInWrapper.jsx";

// Main Routes
import SettingsContainer from './containers/settings/SettingsContainer.jsx';
import SignInContainer from './containers/signin/SignInContainer.jsx';
import ForgotPasswordContainer from './domains/ForgotPassword/ForgotPasswordContainer.tsx';
import ResetPasswordContainer from './domains/ResetPassword/ResetPasswordContainer.jsx';
import { OrderDetails } from "@v2/Pages/Orders/OrderDetails.tsx"
import ChatContainer from './domains/Chat/ChatContainer.jsx';
import OrderConfirmationPage from './components/checkout/order-comfirmation';
import ReceivingTool from './domains/ReceivingTool/ReceivingToolContainer.jsx';

// Settings Routes
import Settings from "./components/settings/Settings.jsx";
import AccountContainer from "./containers/settings/AccountContainer.jsx";
import BuyerContainer from "./containers/settings/BuyerContainer.jsx";
import LocationsContainer from "./containers/settings/LocationsContainer.jsx";
import MembersContainer from "./containers/settings/MembersContainer.jsx";
import { Integrations } from "@v2/Pages/Integrations/Integrations";

// V2 routes
import { Home } from "@v2/Pages/Home/HomePage";
import { Setup } from "@v2/components/Setup/Setup";

import { SetupCatalog } from "@v2/Pages/Setup/SetupPage";
import { AddItems } from "@v2/Pages/Setup/Catalog/AddItems";
import { AddItemsCreate } from "@v2/Pages/Setup/Catalog/AddItems/Create";
import { AddItemsUpload } from "@v2/Pages/Setup/Catalog/AddItems/Upload";
import { SetupComplete } from "@v2/Pages/Setup/Catalog/SetupComplete";

import Utils from "./utils";
import * as orderActions from "./actions/orderActions";
import "./stylesheets/main.scss";
import { EPaths, ESettingsPaths, ESetupPaths } from "@v2/constants/EPaths";
import NotFound from "@v2/Pages/NotFound/NotFound";
import InvalidVersion from "@v2/Pages/InvalidVersion/InvalidVersion";
import MarketPage from "@v2/Pages/Market/MarketPage";
import { Maintenance } from '@v2/Pages/Maintenance/Maintenance';
import { SetupSuppliers } from "@v2/Pages/Setup/Suppliers/SetupSuppliers";
import {CatalogImport} from "@v2/Pages/Setup/Catalog/Import/CatalogImport";
import {CatalogManagement} from "@v2/Pages/Catalog/CatalogManagement";
import {Catalog} from "@v2/Pages/Catalog/Catalog";
import {Uploads} from "@v2/Pages/Uploads/Uploads";
import {Upload} from "@v2/Pages/Upload/Upload";
import {Orders} from "@v2/Pages/Orders/Orders.tsx";
import {ScanReviewPage} from "@v2/Pages/ScanReview/ScanReviewPage";
import { InvoicesPage } from "@v2/Pages/Invoices/InvoicesPage";
import { BillDetails } from "@v2/Pages/Invoices/BillDetails";

const createRoutes = (store) => {
  const handleEnterUnauthed = (nextState, replaceState) => {
    if (Utils.isAuthed()) {
      // pipe the search params (when available) to the route
      const searchParams =
        nextState && nextState.location ? nextState.location.search || "" : "";
      replaceState(`${EPaths.HOME}/${searchParams}`);
    }
  };

  const handleEnterAuthed = (nextState, replaceState) => {
    if (!Utils.isAuthed()) {
      // pipe the search params (when available) to the route
      const searchParams =
        nextState && nextState.location ? nextState.location.search || "" : "";
      replaceState(`/account/signin/${searchParams}`);
    }
  };

  const loadCartOrders = () => {
    // If we login through impersonation link or direct link login, then we don't have the access token
    // Therefore, we must avoid calling this method and let the postSignin method call it
    if (!window.__accessToken__) return;

    // Load the cart on route changes to refresh order information
    store.dispatch(orderActions.loadCartOrders());
  };

  return (
    <Route path="/" component={AppWrapper}>
      <Route path={EPaths.INVALID_VERSION} component={InvalidVersion} />
      <Route
        path={EPaths.MAINTENANCE}
        component={Maintenance}
      />
      <Route onEnter={handleEnterUnauthed}>
        <Route path={EPaths.SIGN_IN} component={SignInContainer} />
        <Route
          path={EPaths.FORGOT_PASSWORD}
          component={ForgotPasswordContainer}
        />
        <Route
          path={EPaths.RESET_PASSWORD}
          component={ResetPasswordContainer}
        />
      </Route>
      <Route onEnter={handleEnterAuthed} component={LoggedInWrapper}>
        <Route path="/checkout/:order_urlsafe" component={CheckoutWrapper} />
        <Route path="/checkout/pending/" component={CheckoutWrapper} />
        <Route
          path="/checkout/pending/:order_urlsafe/"
          component={CheckoutWrapper}
        />
        <Route path={EPaths.RECEIVING_TOOL} component={ReceivingTool} />
        <Route path={EPaths.SCANS_REVIEW} components={ScanReviewPage} />
        <Route path={EPaths.SETUP} component={Setup}>
          <Route path={ESetupPaths.SUPPLIERS} component={SetupSuppliers} />
          <Route path={ESetupPaths.CATALOG} component={SetupCatalog} />
          <Route path={ESetupPaths.CATALOG_ADD_ITEMS} component={AddItems} />
          <Route
            path={ESetupPaths.CATALOG_ADD_ITEMS_CREATE}
            component={AddItemsCreate}
          />
          <Route
            path={ESetupPaths.CATALOG_ADD_ITEMS_UPLOAD}
            component={AddItemsUpload}
          />
          <Route path={ESetupPaths.CATALOG_COMPLETE} component={SetupComplete} />
        </Route>
        <Route component={IndexWrapper}>
          <Route path={EPaths.HOME} components={{ main: Home }} />
          <Route path={EPaths.SHOP} components={{ main: MarketPage }} />
          <Route path={EPaths.UPLOAD} components={{ main: Upload }} />
          <Route path={EPaths.CATALOG} components={{ main: Catalog }} />
          <Route path={EPaths.CATALOG_MANAGEMENT} components={{ main: CatalogManagement }} />
          <Route path={ESettingsPaths.ACCOUNT} components={{ main: AccountContainer }} />
          <Route path={EPaths.SUPPLIER_CATALOG_IMPORT} components={{ main: CatalogImport }}/>
          <Route
            path={EPaths.ORDER_HISTORY}
            components={{ main: Orders }}
          />
          <Route
            path={EPaths.ORDER_DETAILS}
            components={{ main: OrderDetails }}
          />
          <Route
            path={EPaths.DIRECT_CHAT}
            components={{ main: ChatContainer }}
          />
          {/* Invoices Page v2*/}
          <Route path={EPaths.INVOICES} components={{ main: InvoicesPage }} />
          <Route path={EPaths.UPLOADS} components={{ main: Uploads }} />
          <Route path={EPaths.BILL_DETAILS} components={{ main : BillDetails }} />
          {/* Settings */}
          <Route
            path={EPaths.SETTINGS}
            components={{ main: SettingsContainer }}
          >
            <IndexRoute component={Settings} />
            <Route path={ESettingsPaths.BUYER} component={BuyerContainer} />
            <Route
              path={ESettingsPaths.LOCATIONS}
              component={LocationsContainer}
            />
            <Route path={ESettingsPaths.MEMBERS} component={MembersContainer} />

            {/* Redirects for old routes */}
            <Redirect
              from="order-history/order/:order_urlsafe/*"
              to="/settings/order/:order_urlsafe/*"
            />
          </Route>
          <Route path={ESettingsPaths.INTEGRATIONS} component={{main: Integrations}} />

          <Route
            path="/checkout/:orderUrlSafe/confirmation"
            components={{ main: OrderConfirmationPage }}
          />
          <Route path="*" components={{ main: NotFound }} />
        </Route>

      </Route>
      <IndexRedirect to={EPaths.HOME} />
    </Route>
  );
};

export default createRoutes;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MaskedInput from 'react-maskedinput';
import { Typography } from '@notch-ordering/ui-components';

export default class PhoneNumberForm extends Component {
  onChangePhoneNumber = (event) => {
    this.props.changePhoneNumber(event.target.value);
  };

  onSavePhoneNumberOnBlur = () => {
    this.props.savePhoneNumber();
  };

  render() {
    const { accountPhoneNumber } = this.props;
    return (
      <div className="checkout-box checkout-box-buyer-phone">
        <div className="input-group">
          <label className="full-address">
            <Typography as='span'>Send Order Confirmation to your phone:</Typography>
          </label>
          <MaskedInput
            type="text"
            value={accountPhoneNumber}
            onChange={this.onChangePhoneNumber}
            onBlur={this.onSavePhoneNumberOnBlur}
            className="form-control buyer-phone-input"
            mask="111-111-1111"
          />
        </div>
      </div>
    );
  }
}

PhoneNumberForm.propTypes = {
  accountPhoneNumber: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';

export default PropTypes.shape({
  packagingPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  packagingQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  salesQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  salesUnit: PropTypes.string,
  unitPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unitQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  originalQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sourceUrlsafe: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
});

import PropTypes from 'prop-types';
import React from 'react';

import OrderCheckout from './order/OrderCheckout.jsx';
import OrderCheckoutFooterPending from './order/footer/OrderCheckoutFooterPending.jsx';
import { Typography } from '@notch-ordering/ui-components';

export default function OrdersList(props) {
  const {
    orders,
    checkoutView,
    showOrdersTotal,
    vendorsByUrlsafe,
    ordersGrandTotal,
    isEditingSingleOrder,
    currentlyEditingOrderUrlsafe,
  } = props;

  // Only allow non-empty orders && have a vendor && order isCheckout && if editing a single order, only show that one
  const ordersList = orders.filter((order) => {
    return (
      !_.isEmpty(order) &&
      !_.isEmpty(vendorsByUrlsafe[order.vendorUrlsafe]) &&
      order.isCheckout &&
      !(isEditingSingleOrder && order.urlsafe !== currentlyEditingOrderUrlsafe)
    );
  });

  return (
    <div style={{ marginBottom: '40px' }}>
      {checkoutView === 'pendingView' && (
        <div className="order-checkout-header pending-header">
          <div className="order-checkout-header-table-desc">
            <Typography as='div' variant='BASE' weight='font-semibold' className="vendor-col">Supplier</Typography>
            <div className="delivery-col">
              <img
                className="delivery-image"
                src="/static/website/images/Delivery.png"
              />
              <Typography as='span' variant='BASE' weight='font-semibold'>Delivery Day</Typography>
            </div>
            <Typography as='div' variant='BASE' weight='font-semibold' className="total-col">Order Total</Typography>
          </div>
        </div>
      )}
      {ordersList.map((order) => {
        return (
          <OrderCheckout
            key={order.urlsafe}
            order={order}
            ordersGrandTotal={ordersGrandTotal}
            vendor={vendorsByUrlsafe[order.vendorUrlsafe]}
            {...props}
          />
        );
      })}
      {checkoutView === 'pendingView' && showOrdersTotal && (
        <OrderCheckoutFooterPending ordersGrandTotal={ordersGrandTotal} />
      )}
    </div>
  );
}

OrdersList.propTypes = {
  orders: PropTypes.array.isRequired,
  vendorsByUrlsafe: PropTypes.object.isRequired,
  ordersGrandTotal: PropTypes.number.isRequired,
  showOrdersTotal: PropTypes.bool.isRequired,
  checkoutView: PropTypes.string.isRequired,
  isEditingSingleOrder: PropTypes.bool.isRequired,
  currentlyEditingOrderUrlsafe: PropTypes.string,
};

import { useSelector } from 'react-redux';
import { RootState } from '..';

export const useLegacySuppliers = ():RootState => {
    const { vendors: legacySuppliers } = useSelector((store: RootState) => store.vendorsReducer);

    return {
        legacySuppliers
    };
};

/**
 * This component will render a navigation link
 */
import React from 'react';
import { Link } from 'react-router';
import { Typography, twMergeCustom } from '@notch-ordering/ui-components';
import cx from 'classnames';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';

export interface Props {
    to: string,
    className?: string,
    children: React.ReactNode,
    isActive?: boolean,
    target?: string,
    rel?: string,
    onClick?: () => void,
}

export const NavLink: React.FC<Props> = ({
    to,
    target,
    rel,
    className,
    children,
    isActive,
    onClick,
}) => {
    const isMobileWidth = useIsMobileWidth();
    return <Link
        to={to}
        target={target}
        rel={rel}
        onClick={onClick}
        className={twMergeCustom(
            cx([
                'flex lg:rounded-xl pt-3.5 pb-2.5 lg:py-1.5 lg:pl-4 items-center hover:text-gray-650 lg:active:bg-gray-200 lg:hover:bg-gray-100 text-gray-650',
                {
                    'text-gray-700 hover:text-gray-700': isMobileWidth,
                    'text-teal-500 lg:bg-gray-100 hover:text-teal-500 lg:hover:bg-gray-100': isActive
                },
                className,
            ])
        )}>
        <Typography
            as="div"
            weight={isActive ? 'font-medium' : 'font-regular'}
            className="w-full">
            {children}
        </Typography>
    </Link>;
};

export default NavLink;

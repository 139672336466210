import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Map from 'google-map-react';

export default class GoogleMap extends Component {
  state = {
    marker: {},
    position: {},
  };

  componentDidMount = () => {
    const { geocode } = this.props;

    this.setState({ position: geocode });
  };

  renderMarkers = (map, maps) => {
    const { geocode } = this.props;

    const marker = new maps.Marker({
      position: geocode,
      map,
      animation: google.maps.Animation.DROP,
    });

    this.setState({ marker });
  };

  changeMarkerPosition = (marker) => {
    const { geocode } = this.props;
    const existingMarker = this.state.marker;

    // Existing marker is empty to start.
    if (!_.isEmpty(existingMarker)) {
      // Only re-render marker if we change Locations
      if (!_.isEqual(geocode, this.state.position)) {
        existingMarker.setPosition(geocode);
        // This is done for efficiency of re-rendering,
        // but should be looked at with caution with general use.
        this.setState({ marker: existingMarker });
      }
    }
  };

  render() {
    const { geocode, width, height, zoom } = this.props;
    const style = { width: width, height: height };

    return (
      <div style={style}>
        {geocode ? (
          <Map
            bootstrapURLKeys={{
              key: process.env.GOOGLE_MAP_PARAMS.key,
              libraries: ['places'],
            }}
            center={geocode}
            zoom={zoom}
            options={{ fullscreenControl: false }}
            onChange={this.changeMarkerPosition}
            onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps)}
            yesIWantToUseGoogleMapApiInternals
          ></Map>
        ) : (
          <div className="map-placeholder">
            <img src="/static/website/images/empty-state-pin.png" />
            <p>Location not found.</p>
          </div>
        )}
      </div>
    );
  }
}

GoogleMap.propTypes = {
  geocode: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';

import renderField from '../../../../redux-form/renderField';
import renderFieldCheckbox from '../../../../redux-form/renderFieldCheckbox';
import renderFieldDropDown from '../../../../redux-form/renderFieldDropDown';
import validate from '../../../../redux-form/MemberForm/validate';
import asyncValidate from '../../../../redux-form/MemberForm/asyncValidate';
import ErrorMessage from '../../../ErrorMessage.jsx';
import InlineConfirm from '../../../InlineConfirm.jsx';

class MemberForm extends Component {
  componentDidMount() {
    this.props.analyticsViewPage('EditMember');
  }

  onClickDelete = () => {
    const { initialValues, toggleMembersModal, onClickDelete } = this.props;

    onClickDelete([initialValues.memberUrlsafe]);

    // Close modal
    toggleMembersModal();
  };

  render() {
    const {
      initialValues,
      account,
      pristine,
      submitting,
      handleSubmit,
      onClickCancel,
      onSubmit,
      errorAsync,
      errorMessage,
    } = this.props;

    // Set list of role options based on current account buyer role
    const accountRole = account.roles[0].name;
    let roleOptions = [];

    if (accountRole === 'Owner') {
      roleOptions = ['Owner', 'Manager', 'Staff', 'Bookkeeper'];
    } else if (accountRole === 'Manager') {
      roleOptions = ['Manager', 'Staff', 'Bookkeeper'];
    }

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-body">
          <h3 style={{ marginBottom: 24 }}> Team member information </h3>

          <Field
            name="firstName"
            className="form-control input-lg"
            component={renderField}
            label="First name"
            type="text"
          />
          <Field
            name="lastName"
            className="form-control input-lg"
            component={renderField}
            label="Last name"
            type="text"
          />
          <Field
            name="email"
            className="form-control input-lg"
            component={renderField}
            disabled={initialValues.email ? true : false}
            label="Email"
            type="text"
          />
          <Field
            name="mobile"
            className="form-control input-lg"
            component={renderField}
            label="Mobile"
            type="text"
          />
          <Field
            name="role"
            className="form-control input-lg dropdown"
            component={renderFieldDropDown}
            placeholder="-- Please Assign a Role to this Member --"
            options={roleOptions}
            defaultValue={initialValues.role ? initialValues.role : ''}
            type="dropdown"
          />
          <Field
            name="receiveNotification"
            className="form-control input-lg"
            component={renderFieldCheckbox}
            label="Receive notification"
            type="checkbox"
          />
          {/* Only show Remove Team Member button for the following conditions:
                      - if editing a member
                      - if member is not the accountholder
                  */}
          {initialValues.memberIndex > -1 &&
            initialValues.accountUrlsafe !== account.urlsafe && (
              <InlineConfirm confirmedYes={this.onClickDelete}>
                <button className="btn btn-link delete-btn">
                  Remove Team Member
                </button>
              </InlineConfirm>
            )}
        </div>
        <div className="footer-accounts">
          <ErrorMessage error={errorAsync} errorMessage={errorMessage} />
          <button
            type="button"
            className="btn btn-white btn-lg pull-left"
            onClick={onClickCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary btn-lg pull-right"
            disabled={pristine || submitting}
          >
            Save
          </button>
        </div>
      </form>
    );
  }
}

MemberForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  errorAsync: PropTypes.bool.isRequired,
  errorMessage: PropTypes.array.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  analyticsViewPage: PropTypes.func.isRequired,
  toggleMembersModal: PropTypes.func.isRequired,
};

MemberForm = reduxForm({
  form: 'MemberForm',
  validate,
  asyncValidate,
  asyncBlurFields: ['email'],
})(MemberForm);

MemberForm = connect((state) => {
  const { MemberForm } = state.form;
  const { buyerMembers } = state.buyerReducer;
  const { membersModalIndex } = state.settingsReducer;

  const member = buyerMembers[membersModalIndex];
  let initialValues = { receiveNotification: true, memberIndex: -1 }; // initial value if we are creating a new member

  if (member && Object.keys(member).length > 0) {
    initialValues = {
      firstName: member.account.firstName,
      lastName: member.account.lastName,
      email: member.account.email,
      mobile: member.account.mobile,
      role: member.roles[0].name,
      receiveNotification: member.receiveNotification,
      memberIndex: membersModalIndex,
      memberID: member.id,
      memberUrlsafe: member.urlsafe,
      accountUrlsafe: member.account.urlsafe,
    };
  }

  return {
    initialValues,
  };
})(MemberForm);

export default MemberForm;

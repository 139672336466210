import React from 'react';
import useAccount from '@v2/hooks/useAccount.hook';
import { Typography, Container } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';

const HomeHeaderEmpty: React.FC = () => {
    const { firstName = '' }: { firstName: string } = useAccount();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Home.Header' });

    const getDayGreetings = (): string => {
        const hours: number = new Date().getHours();
        if (hours >= 6 && hours < 12) {
            return t('goodMorning');
        } if (hours >= 12 && hours <= 17) {
            return t('goodAfternoon');
        }
        return t('goodEvening');
    };

    return (
        <Container fluid className={'bg-white'}>
            <Container className="lg:px-10 lg:py-16 py-10">
                <Typography
                    as="h1"
                    variant="6XL"
                    size="text-7"
                    className="text-gray-700 mb-4 lg:mb-2 mt-0">
                    {getDayGreetings()}, {firstName}
                </Typography>
            </Container>
        </Container>
    );
};

export default HomeHeaderEmpty;

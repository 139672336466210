import { SupplierData } from '@v2/network/CoreAPI';
import create from 'zustand';
import { persist } from 'zustand/middleware';

type SetupActions = {
    didUploadFiles?: boolean,
    didRequestPriceList?: boolean,
    didCreateItems?: boolean,
};

/**
 * State used by the self onboarding setup flow
 */
type SetupState = {
    catalogID: string,
    setCatalogID: (ID: string) => void,
    supplier: SupplierData,
    setSupplier: (supplier: SupplierData) => void,
    setupActions: SetupActions,
    setSetupActions: (actions: SetupActions) => void,
    clearSetupActions: () => void,
    productsCreated: number,
    setProductsCreated: (count: number) => void,
    filesUploaded: number,
    setFilesUploaded: (count: number) => void,
    initialDrawerHeight: number,
    setInitialDrawerHeight: (value: number) => void,
};

export const useSetupStore = create<SetupState>()(
    persist(
        (set) => ({
            catalogID: '',
            setCatalogID: (ID: string): void => set(() => ({ catalogID: ID })),
            supplier: {},
            setSupplier: (supplier: SupplierData): void => set(() => ({ supplier })),
            setupActions: {},
            setSetupActions: (actions: SetupActions): void => set((state) => ({ setupActions: { ...state.setupActions, ...actions } })),
            clearSetupActions: (): void => set(() => ({ setupActions: {} })),
            productsCreated: 0,
            setProductsCreated: (count: number): void => set(() => ({ productsCreated: count })),
            filesUploaded: 0,
            setFilesUploaded: (count: number): void => set(() => ({ filesUploaded: count })),
            initialDrawerHeight: 0,
            setInitialDrawerHeight: (value: number): void => set(() => ({ initialDrawerHeight: value }))
        }),
        { name: 'setup' }
    )
);

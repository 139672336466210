import { TBillsFilters, TBillsPagination } from '@v2/Pages/Invoices/BillsQueries.hook';
import { DEFAULT_BILL_FILTERS } from '@v2/components/Bills/BillsFilters/BillsFiltersConstants';
import { EStripeStatus } from '@v2/constants/EInvoiceStatus';
import { addYears, format } from 'date-fns';
import create from 'zustand';
import { UNIX_EPOCH_START_DATE } from '@v2/utils/BillsUtils';

/**
 * State used by the bill store
 *
 * @param stripeStatusMap - A mapping of invoice numbers to stripe statuses. These must be cached locally as they are fetched from the Stripe API
 */
type BillsStore = {
    filters: TBillsFilters,
    setFilters: (filters: TBillsFilters) => void,
    updateFilters: (filters: Partial<TBillsFilters>, shouldResetPagination?: boolean) => void,
    resetFilterByType: (key: keyof TBillsFilters) => void,
    pagination: TBillsPagination,
    setPagination: (pagination: TBillsPagination) => void,
    updatePagination: (pagination: Partial<TBillsPagination>) => void,
    resetPagination: () => void,
    stripeStatusMap: { [key: string]: EStripeStatus },
    setStripeStatus: (orderID: string, status: EStripeStatus) => void,
    getStripeStatus: (orderID: string) => EStripeStatus,
};

export const BILLS_PAGINATION_LIMIT = 20;
export const BILLS_PAGINATION_INITIAL_PAGE = 0;

export const BILLS_FILTERS_INITIAL_STATE: TBillsFilters = {
    dueDate: {
        startDate: UNIX_EPOCH_START_DATE,
        endDate: format(addYears(Date.now(), 10), 'yyyy-MM-dd'),
        type: DEFAULT_BILL_FILTERS.DATE.ALL,
    },
    issuedDate: {
        startDate: UNIX_EPOCH_START_DATE,
        endDate: format(addYears(Date.now(), 10), 'yyyy-MM-dd'),
        type: DEFAULT_BILL_FILTERS.DATE.ALL,
    },
    paymentStatus: undefined,
    search: undefined,
    sortBy: undefined,
    sortOrder: undefined,
};

export const BILLS_PAGINATION_DEFAULT_STATE: TBillsPagination = {
    page: BILLS_PAGINATION_INITIAL_PAGE,
    limit: BILLS_PAGINATION_LIMIT,
    nextCursor: undefined,
};

export const useBillsStore = create<BillsStore>((set, get) => ({
    filters: BILLS_FILTERS_INITIAL_STATE,
    setFilters: (filters): void => set(() => ({ filters })),
    updateFilters: (filters, shouldResetPagination = true): void => set((state) => {
        if (shouldResetPagination) {
            get().resetPagination();
        }
        return (
            {
                filters: {
                    ...state.filters,
                    ...filters
                }
            });
    }),
    resetFilterByType: (type: keyof TBillsFilters): void => set((state) => (
        { filters: { ...state.filters,
            [type]: BILLS_FILTERS_INITIAL_STATE[type] } })),
    pagination: BILLS_PAGINATION_DEFAULT_STATE,
    setPagination: (pagination): void => set(() => ({ pagination })),
    updatePagination: (pagination): void => set((state) => ({ pagination: { ...state.pagination, ...pagination } })),
    resetPagination: (): void => set(() => ({ pagination: BILLS_PAGINATION_DEFAULT_STATE })),
    stripeStatusMap: {},
    setStripeStatus: (orderID: string, status: EStripeStatus): void => set((state) => ({ stripeStatusMap: { ...state.stripeStatusMap, [orderID]: status } })),
    getStripeStatus: (orderID: string): EStripeStatus => get().stripeStatusMap[orderID]
}),);

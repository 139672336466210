import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ErrorMessage extends Component {
  render() {
    const { error, errorMessage } = this.props;
    return (
      <div className="error-message">
        {error ? (
          <span>
            {errorMessage.map((stringOrHTML, index) => (
              <span key={index}>{stringOrHTML}</span>
            ))}
          </span>
        ) : (
          ''
        )}
      </div>
    );
  }
}

ErrorMessage.propTypes = {
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.array.isRequired, // because we pass html and JS strings
};

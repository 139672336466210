import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class EditBox extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      text: props.text ? props.text : '',
      height: props.height || { height: '100px' },
    };
  }

  static defaultProps = {
    editMode: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ text: nextProps.text });
  }

  handleTextChange = (event) => {
    this.setState({ text: event.target.value });
  };

  onSaveText = () => {
    this.props.onClickSave(this.state.text);
  };

  onCancel = () => {
    this.setState({ text: this.props.text });
    this.props.onClickEdit();
  };

  onDoubleClick = () => {
    this.props.onClickEdit();
  };

  render() {
    const { className, text, editMode, onClickSave } = this.props;

    const customStyle = this.props.height;

    const NoteView = () => {
      if (editMode) {
        return (
          <span className={className}>
            <textarea
              name="note"
              onChange={this.handleTextChange}
              value={this.state.text}
              className="edit-box-textarea"
              style={customStyle}
            />
            <button
              onClick={this.onCancel}
              className="btn btn-default btn-sm edit-box-btn left-edit-btn"
            >
              Cancel
            </button>
            <button
              onClick={this.onSaveText}
              className="btn btn-primary btn-sm edit-box-btn right-edit-btn pull-right"
            >
              Save
            </button>
          </span>
        );
      } else {
        return (
          <span onDoubleClick={this.onDoubleClick} className="note-text">
            {this.state.text}
          </span>
        );
      }
    };

    return <span>{NoteView()}</span>;
  }
}

EditBox.propTypes = {
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  editMode: PropTypes.bool.isRequired,

  onClickSave: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

import { InfiniteData } from '@tanstack/query-core/src/types';
import { useInfiniteQuery, UseInfiniteQueryResult, useQuery, UseQueryResult } from '@tanstack/react-query';
import { CategoriesData,
    fetchProducts,
    getCategories,
    getLinkedSupplierCatalogs,
    getOrderGuide,
    getOrderGuides, getUnits,
    LinkedSupplierCatalogsData,
    TFetchProductsType,
    TGetOrderGuideParams, UnitsData } from '@v2/network/SecretShopAPI';
import { GetOrderGuidesResponse, IProductResponse, OrderGuide } from '@v2/network/types';
import { IActiveFilters } from '@v2/Pages/Market/MarketConstants';
import { UseInfiniteQueryOptions } from '@tanstack/react-query/src/types';

export const FETCH_MARKET_PRODUCTS_QUERY = 'FETCH_MARKET_PRODUCTS_QUERY';
export const GET_ORDER_GUIDES = 'GET_ORDER_GUIDES';
export const GET_ORDER_GUIDE = 'GET_ORDER_GUIDE';
export const GET_SUPPLIERS_PER_BUYER = 'GET_SUPPLIERS_PER_BUYER';
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_UNITS = 'GET_PRODUCT_UNITS';

export type FetchMarketProductsType = { buyerUrlsafeKey: string, params: IActiveFilters, type: TFetchProductsType,
    queryOptions?: UseInfiniteQueryOptions,

};

export const useFetchMarketProductsQuery = ({
    buyerUrlsafeKey,
    params,
    type = TFetchProductsType.SAVED,
    queryOptions = {}
}: FetchMarketProductsType): UseInfiniteQueryResult<IProductResponse> => useInfiniteQuery<IProductResponse>([FETCH_MARKET_PRODUCTS_QUERY, params, type], ({ pageParam = null }) => fetchProducts({ buyerUrlsafeKey, params: { ...params, ...(pageParam && { cursor: pageParam }) }, type }), {
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    ...queryOptions
});

const switchProductFavoriteState = (page, productID):InfiniteData<IProductResponse> => page.results.map((product) => {
    if (product.id === productID) {
        return {
            ...product,
            isSaved: !product.isSaved
        };
    }
    return product;
});

const filterFavoriteProducts = (page, productID):InfiniteData<IProductResponse> => page.results.filter((product) => product.id !== productID);

/**
 *
 * This function generates the mutation options to use optimistic update when saving/removing a product
 *
 * @param queryClient
 * @param filters
 * @param type
 * @param removeOptimistically - this option if marked as true will remove the product from the list instead of toggling the favorite state
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const favoriteMutationOptions = (queryClient, filters, type, removeOptimistically = false) => {
    const QUERY_KEY = [FETCH_MARKET_PRODUCTS_QUERY, filters, type];
    return ({
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onMutate: async ({ productID }) => {
            // Cancel any outgoing re-fetches (so they don't overwrite the optimistic update)
            await queryClient.cancelQueries(QUERY_KEY);
            // Snapshot the previous value
            const previousData = queryClient.getQueryData(QUERY_KEY);
            // Optimistically update to the new value
            queryClient.setQueryData(QUERY_KEY, (old: InfiniteData<IProductResponse>) => ({
                ...old,
                pages: old?.pages.map((page) => ({
                    ...page,
                    results: removeOptimistically ? filterFavoriteProducts(page, productID) : switchProductFavoriteState(page, productID)
                }))
            }));
            // Return a context object with the snapshotted value
            return { previousData };
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onError: (_err, _updatedProduct, context) => {
            queryClient.setQueryData(QUERY_KEY, context.previousData);
        },
        // Always refetch after error or success:
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        onSettled: () => {
            queryClient.invalidateQueries(QUERY_KEY);
        },
    });
};

export const useGetOrderGuides = (buyerUrlsafeKey): UseQueryResult<GetOrderGuidesResponse> => useQuery([GET_ORDER_GUIDES, buyerUrlsafeKey], () => getOrderGuides(buyerUrlsafeKey));

export const useGetOrderGuide = ({ buyerId, orderGuideID }: TGetOrderGuideParams): UseQueryResult<OrderGuide> => useQuery([GET_ORDER_GUIDE, orderGuideID], () => getOrderGuide({ buyerId, orderGuideID }), { enabled: !!orderGuideID });

export const useGetSuppliersPerBuyer = ({ buyerId, hideOffline = true }: { buyerId: string, hideOffline?: boolean }): UseQueryResult<LinkedSupplierCatalogsData> => useQuery([GET_SUPPLIERS_PER_BUYER, buyerId], () => getLinkedSupplierCatalogs(buyerId, hideOffline), {
    enabled: !!buyerId,
    staleTime: 60000,
    retry: 3,
    retryDelay: 10000
});

export const useGetProductCategories = (): UseQueryResult<CategoriesData> => useQuery([GET_PRODUCT_CATEGORIES], getCategories);
export const useGetProductUnits = (): UseQueryResult<UnitsData> => useQuery([GET_PRODUCT_UNITS], getUnits);

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const renderFieldTextArea = ({
  id,
  input,
  label,
  className,
  disabled,
  placeholder,
  hintText,
  meta: { active, touched, error },
}) => {
  let labelClassName = '';
  if ((input.value === '' && active) || input.value != '') {
    labelClassName = 'move-label';
  }

  return (
    <div className="input-group">
      <div>
        <textarea
          id={id}
          title={hintText}
          className={classNames(className, { 'form-error': error && touched })}
          {...input}
          value={input.value}
          placeholder={placeholder}
          rows={3}
        />
        <div className="redux-form-error">
          {touched && error && <span>{error}</span>}
        </div>
        <label className={`field-label ${labelClassName}`}>{label}</label>
      </div>
    </div>
  );
};

renderFieldTextArea.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default renderFieldTextArea;

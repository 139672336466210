import React, { FC, useEffect, useState } from 'react';
import { Typography, Button } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import MaintenanceImg from '@images/maintenance.svg';
import { isOutdatedVersion } from '@v2/utils/CheckVersion';
import { isPlatform } from '@ionic/react';
import Router from 'react-router';
import * as Intercom from '../../../third-party/Intercom';
import packageJson from '../../../../package.json';
import { getMinVersion } from '@/api/DataAPI';
import { EIonicPlatforms } from '@/constants/Mobile';

type MaintenanceProps = {
    router: Router,
};
export const Maintenance: FC<MaintenanceProps> = ({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Maintenance' });
    const [isBelowMinVersion, setIsBelowMinVersion] = useState(false);
    const supportEmail = 'support@notchordering.com';
    const supportPhoneNumber = '+1-647-930-0210';

    useEffect(() => {
        Intercom.initLoggedOutUser();

        // Compare versions on web browsers and hard refresh to attempt to get the new version
        if (!isPlatform(EIonicPlatforms.CAPACITOR)) {
            getMinVersion().then(({ data: response }) => {
                if (isOutdatedVersion(packageJson.version, response.minimumVersion)) {
                    setIsBelowMinVersion(true);
                }
            });
        }
    }, []);

    function hardRefresh(): void {
        caches.keys()
            .then((keyList) => Promise.all(
                keyList.map((key) => caches.delete(key))
            ))
            .then(() => {
                setTimeout(() => router.push('/'), 1000);
            });
    }

    return (
        <div className="flex justify-center items-center flex-col h-full w-full gap-9 bg-white px-8 sm:px-36 text-[16px]">
            <div className="w-[274px] h-[210px]">
                <MaintenanceImg />
            </div>
            <Typography
                variant="3XL"
                as="h3"
                className="text-center text-gray-700 m-0 p-0">
                {t('beBackSoon')}
            </Typography>

            <Typography
                as="div"
                className="sm:text-[18px] text-left text-gray-700">
                {t('upgradingOurPlatform')}<br/><br/>
                {t('forUrgentRequests')} <a href={`mailto:${supportEmail}`}>{supportEmail}</a> {t('orCallUs')} <a href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</a>
            </Typography>

            {isBelowMinVersion && <Button
                className="mt-10 text-center w-3/5"
                variant="SECONDARY"
                size="LARGE"
                onClick={hardRefresh}>
                {tCommon('Buttons.tryAgain')}
            </Button>}
        </div>
    );
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';

import * as CheckoutActions from '../../../../actions/checkoutActions';
import * as checkoutSelectors from '../../../../selectors/checkoutSelectors';

import OrderCheckoutHeader from './header/OrderCheckoutHeader.jsx';
import OrderCheckoutBody from './body/OrderCheckoutBody.jsx';

class OrderCheckout extends Component {
  render() {
    const { order, checkoutView } = this.props;

    const checkoutBoxStyle =
      checkoutView === 'pendingView' ? { marginBottom: '2px' } : {};

    return (
      <div className="checkout-box order-box" style={checkoutBoxStyle}>
        <OrderCheckoutHeader {...this.props} />

        {/* Do not show the body in 2 cases
         *** 1) The order is collapsed and we are in the reviewView
         *** 2) We are in the pendingView
         ***/}
        {(order.collapse && checkoutView === 'reviewView') ||
        checkoutView === 'pendingView' ? (
          ''
        ) : (
          <OrderCheckoutBody {...this.props} />
        )}
      </div>
    );
  }
}

OrderCheckout.propTypes = {
  order: PropTypes.objectOf(PropTypes.object).isRequired,
  belowMinimumOrder: PropTypes.bool.isRequired,
  isPendingOrders: PropTypes.bool.isRequired,
  checkoutView: PropTypes.string.isRequired,
  customDeliveryFee: PropTypes.number.isRequired,
  toggleCollapse: PropTypes.func.isRequired,
  checkoutActions: PropTypes.objectOf(PropTypes.any).isRequired,
};

function mapStatetoProps(state, props) {
  return {
    afterCutOffTime: checkoutSelectors.IsAfterCutOffTime(props),
    belowMinimumOrder: checkoutSelectors.checkIfBelowMinimumOrder(props),
    customDeliveryFee: checkoutSelectors.getSupplierCustomDeliveryFee(props),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkoutActions: bindActionCreators(CheckoutActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(OrderCheckout);

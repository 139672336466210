import React, { useContext } from 'react';
import { Button, Popover, toast } from '@notch-ordering/ui-components';
import { FavoriteCheckBox } from '@v2/components/Shared/FavoriteCheckBox/FavoriteCheckBox';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import { IProduct, OrderGuide } from '@v2/network/types';
import { removeProductFromOrderGuide, TFetchProductsType } from '@v2/network/SecretShopAPI';
import AddIcon from '@icons/add-icon.svg';
import UnsaveIcon from '@icons/unsave-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { favoriteMutationOptions, GET_ORDER_GUIDE } from '@v2/Pages/Market/MarketQueries.hook';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { Placement } from '@popperjs/core/lib/enums';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';

export enum EMarkAsSavedToggleButtonVariant {
    Transparent = 'bg-transparent',
    Gray = 'bg-gray-100',
}

export interface MarkAsSavedToggleButtonProps {
    isSaved: boolean,
    product: IProduct,
    popOverPlacement?: Placement,
    variant?: EMarkAsSavedToggleButtonVariant,
}

export const MarkAsSavedToggleButton : React.FC<MarkAsSavedToggleButtonProps> = ({
    isSaved, product, popOverPlacement,
    variant = EMarkAsSavedToggleButtonVariant.Transparent
}) => {
    const { setActiveProduct, fetchTypeSelected, setSavedProduct, filters, setModalVisibility } = useContext(MarketContext);
    const { buyer } = useBuyerHook();
    const isMobileWidth = useIsMobileWidth();
    const queryClient = useQueryClient();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Shop' });
    const isOrderGuide = fetchTypeSelected === TFetchProductsType.ORDER_GUIDE;
    const buttonSize = isMobileWidth ? 'ICON_LARGE' : 'ICON_MEDIUM';

    const commonButtonProps = {
        className: `flex items-center justify-center border-0 shadow-transparent w-auto ${variant}`,
        children: <FavoriteCheckBox checked={isSaved}/>
    };
    const removeProductFromOrderGuideMutation = useMutation(removeProductFromOrderGuide, { ...favoriteMutationOptions(queryClient, filters, fetchTypeSelected, true),
        onSuccess: () => {
            const currentOrderGuide = queryClient.getQueryData<OrderGuide>([GET_ORDER_GUIDE, filters.orderGuideID]);
            toast.show({
                message: t('productRemovedFromOrderGuide', { orderGuideName: currentOrderGuide?.name || '' }),
                showClose: false,
            });
        } });

    const handleRemoveFromOrderGuide = ():void => {
        removeProductFromOrderGuideMutation.mutate({
            orderGuideID: filters.orderGuideID,
            productID: product.id,
            buyerUrlsafeKey: buyer.urlsafe
        });
    };

    return (!isSaved ? <Button {...commonButtonProps}
        variant="TERTIARY_OUTLINED"
        size={buttonSize}
        onClick={():Promise<void> => {
            sendTrackingEvent(TrackingEvents.addToSavedProducts);
            return setSavedProduct(product);
        }}>{commonButtonProps.children}</Button>
        : <Popover
            placement={popOverPlacement}
            popOverClassName="absolute"
            className="w-48"
            button={<Button stopPropagation={false} {...commonButtonProps} variant="TERTIARY_OUTLINED" size={buttonSize} >{commonButtonProps.children}</Button>}
            items={[{

                label: <div className="flex gap-3 items-center"><AddIcon className="h-4 w-4"/> <span>Add to guide</span></div>,
                onClick: (): void => {
                    setActiveProduct(product);
                    setModalVisibility('addProductToOrderGuide', true);
                }
            },
            {
                label: <div className="flex gap-3 items-center text-red-300"><TrashIcon className="h-4 w-4"/> <span>Remove from guide</span></div>,
                onClick: () => handleRemoveFromOrderGuide(),
                hidden: !isOrderGuide
            },

            {
                label: <div className="flex gap-3 items-center text-red-300"><UnsaveIcon className="h-4 w-4"/> <span>Unsave product</span></div>,
                onClick: ():void => {
                    if (product.isSaved) {
                        setActiveProduct(product);
                        setModalVisibility('unsaveProduct', true);
                        sendTrackingEvent(TrackingEvents.removeFromSavedProducts);
                    } else {
                        setSavedProduct(product);
                    }
                }
            }
            ]}/>);
};

import Utils from '../../utils';

const validate = (values) => {
  const errors = {};

  if (!values.shippingName) {
    errors.shippingName = 'Required';
  }

  if (!values.shippingAddress) {
    errors.shippingAddress = 'Required';
  }

  if (!values.shippingPostalCode) {
    errors.shippingPostalCode = 'Required';
  } else if (
    values.shippingCountry &&
    !Utils.validatePostalCodeOrZip(
      values.shippingCountry,
      values.shippingPostalCode
    )
  ) {
    if (values.shippingCountry === 'Canada') {
      errors.shippingPostalCode = "Expected format: 'A1A1A1' or 'A1A 1A1'";
    } else if (values.shippingCountry === 'United States') {
      errors.shippingPostalCode = "Expected format: '12345' or '12345-6789'";
    }
  }

  if (!values.shippingCity) {
    errors.shippingCity = 'Required';
  }

  if (!values.shippingState) {
    errors.shippingState = 'Required';
  }

  if (!values.shippingCountry) {
    errors.shippingCountry = 'Required';
  }

  return errors;
};

export default validate;

import React, { useState } from 'react';
import { Button, Popover, Separator, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import MoreIcon from '@icons/more-icon.svg';
import PlusIcon from '@icons/add-icon.svg';
import { EPaths } from '@v2/constants/EPaths';
import Route, { browserHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Order, OrderItem } from '@v2/network/LegacyAPI';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import useRolesHook from '@v2/hooks/useRolesHook';
import { ERoleName } from '@v2/types/OrgData';
import Utils from '@/utils';
import { reorder } from '../../../actions/orderActions';
import ImageUpload from '@/domains/OrderDetails/components/ImageUpload';

type OrderDetailProps = {
    router: Route,
    orderData: Order,
    generatedInvoice: InvoiceData,
    enableEditButton?: boolean,
    openAddBillsModal?: () => void,
    openGenerateBillsModal?: () => void,
    onClickChat?: () => void,
};

export interface IOrderInfoColumn {
    title: string,
    orderInfo: React.ReactNode,
}

export const OrderDetailsMobile: React.FC<OrderDetailProps> = ({
    orderData,
    generatedInvoice,
    enableEditButton,
    openAddBillsModal,
    openGenerateBillsModal,
    onClickChat,
}) => {
    const { hasRole } = useRolesHook();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const dispatch = useDispatch();
    const hasImages = orderData?.images?.length > 0;

    const orderInfoColumn:IOrderInfoColumn[] = [
        {
            title: 'Order #',
            orderInfo: (orderData?.id)
        },
        {
            title: tCommon('Labels.orderDate'),
            orderInfo: Utils.formatDate(orderData?.placedAt, 'MMM DD, YYYY'),
        },
        {
            title: tCommon('Labels.deliveryAddress'),
            orderInfo: (orderData?.shippingAddress?.formatted),
        },
        {
            title: tCommon('Labels.deliveryInstructions'),
            orderInfo: orderData.shippingAddress?.driveInstructions ? orderData.shippingAddress?.driveInstructions : t('noDeliveryInstructions'),
        },
    ];

    return (
        <div className="w-full flex flex-col">
            <div className="w-full flex flex-col gap-y-1.5 pt-10 px-4">
                <div className="flex flex-row justify-between items-end">
                    <Typography variant="BASE" className="m-0 text-gray-600">
                        {orderData.vendorName}
                    </Typography>
                    <Popover
                        className="w-48"
                        button={ <Button variant="ICON"
                            size="NO_BACKGROUND"
                            stopPropagation={false}>
                            <div className={'w-4 h-4'}>
                                <MoreIcon title="..." />
                            </div>
                        </Button>}
                        items={[
                            {
                                label: t('messageSupplier'),
                                onClick: (): void => {
                                    onClickChat();
                                },
                            },
                        ]}/>
                </div>
                <Typography className="m-0" weight="font-medium" size="text-7" variant="4XL">
                    {`${Utils.formatAsCurrency(orderData.total)}`}
                </Typography>
                <Typography variant="BASE" className="m-0 text-gray-600">
                    {`Being delivered on ${Utils.formatDate(orderData.deliveryDay, 'MMM DD, YYYY')}`}
                </Typography>
            </div>
            <Separator variant="large" className="my-6" />
            <div className="flex flex-col">
                <Typography className="m-0 mb-6 ml-4" variant="LG-2" weight="font-semibold">
                    {tCommon('Labels.items')}
                </Typography>
                {orderData?.items?.map((product: OrderItem, i: number) => (
                    <div key={i} className="flex flex-col">
                        <div className="flex flex-row justify-between items-center px-4">
                            <div className="flex flex-row justify-start items-center">
                                <div className="w-8 h-8 flex-shrink-0 bg-gray-200 rounded-lg mr-5">
                                    <Typography variant="BASE" weight="font-medium" className="m-0 text-center mt-1.5">{product.packaging_quantity}</Typography>
                                </div>
                                <div className="flex flex-col">
                                    <Typography variant="BASE" weight="font-medium" className="m-0">{product.name}</Typography>
                                    <Typography variant="BASE" className="m-0 text-gray-600">{`${product.unitName} ${product.unitDescription ? `- ${product.unitDescription}` : ''}`}</Typography>
                                </div>
                            </div>
                            <Typography variant="BASE" className="m-0 ml-4">{Utils.formatAsCurrency(product.total)}</Typography>
                        </div>
                        <Separator className="my-4" />
                    </div>

                ))}
            </div>
            <div className="px-4 mb-4">
                <Button
                    variant="TERTIARY_FILLED"
                    size="MEDIUM"
                    className="w-full"
                    loading={isLoading}
                    onClick={(): void => {
                        setIsLoading(true);
                        dispatch(reorder(orderData));
                        setIsLoading(false);
                    }}>{t('reorder')}</Button>
                <Button
                    variant="SECONDARY"
                    size="MEDIUM"
                    className="w-full mt-2"
                    hidden={!enableEditButton}
                    onClick={(): void => {
                        if (hasRole([ERoleName.OWNER, ERoleName.MANAGER])) {
                            browserHistory.push(`/checkout/pending/${orderData.urlsafe}/`);
                        }
                    }}>{tCommon('Buttons.editOrder')}</Button>
            </div>
            <Separator variant="large"className="mb-6"/>
            <div className="px-4">
                <Typography className="mb-6" variant="LG-2" weight="font-semibold">{tCommon('Labels.basicDetails')}</Typography>
                {orderInfoColumn.map(({ title, orderInfo }, i) => (
                    <div key={i}>
                        <Typography variant="BASE" weight="font-medium" className="mb-1 text-gray-600">{title}</Typography>
                        <Typography variant="BASE" className="mb-6" >{orderInfo}</Typography>
                    </div>))}
            </div>
            <Separator variant="large" className="mb-6" />
            <div className="px-4">
                <div className="flex flex-row justify-between">
                    <Typography variant="LG-2" weight="font-semibold">{tCommon('Labels.linkedBills')}</Typography>
                    {(generatedInvoice || hasImages) && <Popover
                        className="w-48 m-0 p-0"
                        popOverClassName="m-0 p-0"
                        button={ <Button variant="TERTIARY_FILLED"
                            size="ICON_SMALL"
                            defaultColor="bg-transparent"
                            stopPropagation={false}>
                            <div className={'w-4 h-4'}>
                                <PlusIcon className="w-4 h-4" />
                            </div>
                        </Button>}
                        items={[
                            {
                                label: t('attachBill'),
                                onClick: (): void => {
                                    openAddBillsModal();
                                },
                            },
                        ]}/>}
                </div>
                {(generatedInvoice || hasImages) && <Typography
                    variant="BASE"
                    weight="font-medium"
                    className="mb-1 mt-3.5 text-gray-600">
                    {tCommon('Labels.billNumber')}
                </Typography>}
                {generatedInvoice && <Button variant="LINK"
                    size="NO_BACKGROUND"
                    className="p-0 mb-6 flex justify-left"
                    onClick={async (): Promise<void> => {
                        browserHistory.push(EPaths.INVOICES);
                    }}>
                    {generatedInvoice?.invoiceNumber}
                </Button>}
                {(!generatedInvoice && hasImages) && <Typography
                    variant="BASE"
                    className="mb-6">
                    {t('noBills')}
                </Typography>}
                <div className="flex flex-col gap-x-2">
                    {(generatedInvoice || hasImages) && <div>
                        <Typography variant="BASE"
                            weight="font-medium"
                            className="mb-0 text-gray-600">
                            {tCommon('Labels.attachments')}
                        </Typography>
                        <div className="mb-32">
                            <ImageUpload
                                attachments={orderData?.images}
                                generatedInvoice={generatedInvoice}/>
                        </div>
                    </div>}
                </div>
                {(!generatedInvoice && !hasImages) && <div>
                    <Typography className="mb-4 mt-2 text-gray-600">{t('noBillLinked')}</Typography>
                    <Button
                        variant="SECONDARY"
                        size="MEDIUM"
                        className="w-full"
                        loading={isLoading}
                        onClick={(): void => {
                            openAddBillsModal();
                        }}>{t('attachBill')}</Button>
                    <Button
                        variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        className="w-full mt-2 mb-32"
                        loading={isLoading}
                        onClick={(): void => {
                            openGenerateBillsModal();
                        }}>{t('generateBill')}</Button>
                </div>}
            </div>
        </div>
    );
};

import { Typography } from '@notch-ordering/ui-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const DisplayUnit = (props) => {
  const {
    unitName,
    unitDescription,
    style,
    pricePerStandardUnit,
    standardUnit,
  } = props;
  let displayUnit = '';

  if (unitName) {
    displayUnit += `${unitName}`;
  }
  if (unitDescription) {
    displayUnit += ' -' + unitDescription;
  }

  return (
    <span style={style}>
      <Typography as='span' className='text-gray-600'>
        {displayUnit}
        {pricePerStandardUnit > 0 &&
          standardUnit &&
          ` (${parseFloat(pricePerStandardUnit).toFixed(
            2
          )}/${standardUnit.toLowerCase()})`}
      </Typography>
    </span>
  );
};

DisplayUnit.propTypes = {
  unitName: PropTypes.string,
  unitDescription: PropTypes.string.isRequired,
  pricePerStandardUnit: PropTypes.number,
  standardUnit: PropTypes.string,
  style: PropTypes.object,
};

DisplayUnit.defaultProps = {
  unitName: '',
  pricePerStandardUnit: 0,
  standardUnit: '',
  style: {},
};

export default DisplayUnit;

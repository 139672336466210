import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import CheckoutContainer from '../checkout/CheckoutContainer.jsx';
import LoadingData from '../../components/LoadingData.jsx';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { tNavigation } from '@v2/i18n';

const CheckoutWrapper = ({ routes, params }) => {
  useTitle(tNavigation('Checkout'));

  const isLoadingAppInit = useSelector(
    (state) => state.accountReducer.isLoadingAppInit
  );

  // Determine if we are in a pending state or not
  const isPendingOrdersURLParamProp =
    routes[routes.length - 1].path.includes('/checkout/pending/');

  return (
    <>
      {isLoadingAppInit ? (
        <LoadingData display={isLoadingAppInit} text="Loading" />
      ) : (
        <CheckoutContainer
          isPendingOrdersURLParamProp={isPendingOrdersURLParamProp}
          currentlyEditingOrderUrlsafe={params['order_urlsafe']}
          isEditingSingleOrder={!!params['order_urlsafe']}
        />
      )}
    </>
  );
};

CheckoutWrapper.defaultProps = {
  params: {},
};

CheckoutWrapper.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.any),
};

export default CheckoutWrapper;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ChatChannelsListContext } from './';

const ChatChannelsListProvider = ({ children }) => {
  const { channels } = useSelector((store) => store.chat);

  return (
    <ChatChannelsListContext.Provider value={channels}>
      {children}
    </ChatChannelsListContext.Provider>
  );
};

export default ChatChannelsListProvider;

ChatChannelsListProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { Link } from "react-router";

import * as Intercom from "../../third-party/Intercom";

import { resetPassword } from "./actions/resetPasswordActions";
import * as changeRoute from "../../actions/routerActions";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";

export default function ResetPasswordContainer(props) {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    Intercom.initLoggedOutUser();
  }, []);

  const submitResetPassword = async () => {
    setLoading(true);

    const result = await dispatch(
      resetPassword({
        email: props.location.query.email || "",
        password1,
        password2,
        passwordResetCode: props.location.query.password_reset_code || "",
      })
    );

    if (result) {
      dispatch(changeRoute.changeRoute("/account/signin/"));
    }

    setLoading(false);
  };

  return (
    <div className="reset-password-container">
      <img
        className="company-logo"
        style={{ maxWidth: "150px", maxHeight: "32px" }}
        src={`${BUCKET_ASSETS_URL}notch-primary-logo.svg`}
      />
      <div className="reset-password-card">
        <div className="title">Reset your password</div>
        <div className="input-group">
          <input
            value={password1}
            onChange={(event) => setPassword1(event.target.value)}
            className="form-control password-input input-lg"
            type="password"
          />
          <label className="field-label">New password</label>
        </div>
        <div className="input-group">
          <input
            value={password2}
            onChange={(event) => setPassword2(event.target.value)}
            className="form-control password-input input-lg"
            type="password"
          />
          <label className="field-label">Re-enter password</label>
        </div>
        <div className="footer">
          <button
            type="submit"
            className="btn btn-primary reset-button"
            disabled={!password1 || !password2}
            onClick={submitResetPassword}
          >
            {isLoading ? (
              <i
                style={{ fontSize: "16px" }}
                className="spin-spinner fa fa-circle-o-notch"
              ></i>
            ) : (
              "Reset password"
            )}
          </button>
          <div className="log-in-button">
            <Link to="/account/signin/">Log In</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

ResetPasswordContainer.propTypes = {
  location: PropType.objectOf(PropType.any).isRequired,
};

import React, { useState } from 'react';
import { Button, Popover, toast, Tooltip, twMergeCustom, Typography } from '@notch-ordering/ui-components';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import { browserHistory } from 'react-router';
import { deleteOcrInvoice, OcrInvoice } from '@v2/network/FalsePromiseApi';
import { EPaths } from '@v2/constants/EPaths';
import { t } from '@v2/i18n';
import cx from 'classnames';

import { FETCH_OCR_INVOICES_QUERY_KEY } from '@v2/Pages/Uploads/UploadsQueries.hook';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CheckIcon from '@icons/check-icon.svg';
import { ConfirmDeletionModal } from '@v2/components/Shared/ConfirmDeletionModal/ConfirmDeletionModal';
import TrashIcon from '@icons/trash-icon.svg';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';

export interface UploadActionButtonsProps {
    ocrInvoice: OcrInvoice,
    isReviewAllowed?: boolean,
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const UploadActionButtons : React.FC<UploadActionButtonsProps> = ({ ocrInvoice, isReviewAllowed }) => {
    const [moreToolTip, setMoreToolTip] = useState<boolean>(false);
    const isReadyForReview = isReviewAllowed && (ocrInvoice.ocrState === 'reviewed' || ocrInvoice.ocrState === 'verified');
    const [messageToolTip, setMessageToolTip] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const queryClient = useQueryClient();

    const deleteOcrInvoiceMutation = useMutation(deleteOcrInvoice, {
        onSuccess: async (): Promise<void> => {
            await queryClient.invalidateQueries([FETCH_OCR_INVOICES_QUERY_KEY]);
            toast.show({
                icon: <CheckIcon />,
                message: t('Uploads.deleteSuccess'),
            });
        },
        onError: (): void => {
            toast.show({
                message: t('Uploads.deleteFailure'),
            });
        }
    });
    return <div className="flex gap-1  " onClick={(e): void => e.stopPropagation()}>
        <Tooltip
            show={messageToolTip && isReadyForReview}
            className="flex"
            tooltipClassName="py-1.5 px-2 rounded-md"
            showArrow={false}
            onShow ={(): void => { setMessageToolTip(true); }}
            onHide ={(): void => { setMessageToolTip(false); }}
            placement="top"
            trigger={<Button className={twMergeCustom(cx('bg-white justify-center items-center p-0 min-w-[32px] w-8 h-8 flex lg:invisible', {
                'lg:group-hover:visible': isReadyForReview,
                invisible: !isReadyForReview,
            }))}
            variant="TERTIARY_OUTLINED"
            size="ICON_SMALL"
            onClick={(): void => {
                sendTrackingEvent({ ...TrackingEvents.reviewBillClicked, label: TrackingLabel.UPLOADS });
                browserHistory.push(EPaths.SCANS_REVIEW.replace(':invoiceKey', ocrInvoice.id));
            }}><ArrowRightIcon className="w-4 h-4" /></Button>}>
            <Typography as="span" variant="BASE">{t('Uploads.review')}</Typography>

        </Tooltip>
        <div>
            <Tooltip
                show={moreToolTip}
                className="flex"
                tooltipClassName="py-1.5 px-2 rounded-md"
                showArrow={false}
                onShow={(): void => {
                    setMoreToolTip(true);
                }}
                onHide={(): void => {
                    setMoreToolTip(false);
                }}
                placement="top"
                trigger={<Popover
                    className="w-44"
                    button=
                        {<Button
                            variant="TERTIARY_TABLE"
                            className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                            size="SMALL"
                            stopPropagation={false}>
                            <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                <EllipsisIcon className="h-4 w-4"/>
                            </div>
                        </Button>}
                    items={[
                        {
                            label: <Typography className="flex gap-3 items-center m-0"> <TrashIcon
                                className="w-4 h-4"/> {t('Uploads.deleteUpload')}</Typography>,
                            className: 'text-red-300 z-50',
                            onClick: (): void => {
                                setShowDeleteModal(true);
                            },
                        },
                    ]}/>}>
                <Typography as="span" variant="BASE">{t('Uploads.more')}</Typography>
            </Tooltip>
        </div>
        <ConfirmDeletionModal isOpen={showDeleteModal}
            title={t('Uploads.deleteAnUpload')}
            prompt={t('Uploads.areYouSureYouWantToDelete')}
            onClose={():void => {
                setShowDeleteModal(false);
            }}
            onConfirm={():void => {
                deleteOcrInvoiceMutation.mutate({
                    invoiceKey: ocrInvoice.id,
                });
                setShowDeleteModal(false);
            }}/>
    </div>;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as Intercom from '../../third-party/Intercom';

import * as CartActions from '../../actions/cartActions';
import * as RouterActions from '../../actions/routerActions';
import { EPaths } from '@v2/constants/EPaths';

class CartManagers extends Component {
  onClose = () => {
    this.props.onClickCancel;
    
    // pipe the search params (when available) to the route
    const searchParams = window.location ? window.location.search || '' : '';
    const route = `${EPaths.HOME}/${searchParams}`;
    this.props.cartActions.setCartManagersModalVisibility(false);
    this.props.routerActions.changeRoute(route);
  };

  onClickHelp = () => {
    Intercom.open();
  };

  render() {
    const { buyer, buyerMembers } = this.props;

    // Get all Managers and Owners for given buyer
    const managers = buyerMembers.filter(
      (member) =>
        member.roles[0].name === 'Owner' || member.roles[0].name === 'Manager'
    );

    return (
      <div className="cart-managers-modal">
        <div className="title">
          <p>Is your cart ready to be checked out?</p>
          <p>Contact a Manager so they can complete the order.</p>
        </div>
        <div className="manager-list">
          <span className="small-text">MANAGERS AT {buyer.name}</span>
          <ul>
            {managers.map((manager) => (
              <li key={manager.memberID}>
                <p
                  className="avatar"
                  data-letters={manager.account.firstName.charAt(0)}
                ></p>
                <div className="member">
                  <p>
                    {manager.account.firstName} {manager.account.lastName}
                  </p>
                  <p className="role small-text">{manager.roles[0].name}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button className="btn btn-primary btn-lg" onClick={this.onClose}>
          Back to Order Desk
        </button>
        <div className="help-text">
          Need more assistance?{' '}
          <span className="help-chat" onClick={this.onClickHelp}>
            Let us help
          </span>
        </div>
      </div>
    );
  }
}

CartManagers.propTypes = {
  buyer: PropTypes.object.isRequired,
  onClickCancel: PropTypes.func.isRequired,
};

function mapStatetoProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    cartActions: bindActionCreators(CartActions, dispatch),
    routerActions: bindActionCreators(RouterActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(CartManagers);
import React from 'react';
import PropTypes from 'prop-types';

export default function ChatSystemMessage({ message }) {
  return (
    <div className="chat-system-message-container">
      <div className="chat-system-message">{message}</div>
    </div>
  );
}

ChatSystemMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

import React from 'react';
import { BillsFilterBadge } from '@v2/components/Bills/BillsFilters/BillsFilterBadge';
import { DEFAULT_BILL_FILTERS,
    FILTER_TYPE, formatDateRange,
    getFilterLabel, tFilters } from '@v2/components/Bills/BillsFilters/BillsFiltersConstants';
import { useBillsStore } from '@v2/stores/BillsStore';
import { useLegacySuppliers } from '@v2/hooks/useLegacySuppliers.hook';

export const BillsFilterBadges = ():JSX.Element => {
    const { filters, resetFilterByType } = useBillsStore();
    const { legacySuppliers = [] } = useLegacySuppliers();
    const { DUE_DATE, ISSUED_DATE, PAYMENT_STATUS } = FILTER_TYPE;
    const { DATE: { CUSTOM } } = DEFAULT_BILL_FILTERS;
    return <div className="w-full flex gap-2 flex-wrap">
        {filters.supplierUrlsafeKey && <BillsFilterBadge onClickClear={resetFilterByType} filterType={'supplierUrlsafeKey'} label={ tFilters('Badges.supplier', { count: 1 })}>
            {legacySuppliers.find((supplier) => supplier.urlsafe === filters.supplierUrlsafeKey)?.name}
        </BillsFilterBadge>}
        {filters.paymentStatus && <BillsFilterBadge onClickClear={resetFilterByType} filterType={'paymentStatus'} label={tFilters('Badges.statusIs')}>
            {getFilterLabel(PAYMENT_STATUS, filters.paymentStatus)}
        </BillsFilterBadge>}
        {filters.dueDate?.type !== DEFAULT_BILL_FILTERS.DATE.ALL && filters.dueDate?.startDate && <BillsFilterBadge onClickClear={resetFilterByType} filterType={'dueDate'} label={tFilters('Badges.dueDateIs')} >
            {filters.dueDate.type !== CUSTOM ? getFilterLabel(DUE_DATE, filters.dueDate.type) : formatDateRange(filters.dueDate)}
        </BillsFilterBadge>}
        {filters.issuedDate?.type !== DEFAULT_BILL_FILTERS.DATE.ALL && filters.issuedDate?.startDate && <BillsFilterBadge onClickClear={resetFilterByType} filterType={'issuedDate'} label={tFilters('Badges.issuedDateIs')} >
            {filters.issuedDate.type !== CUSTOM ? getFilterLabel(ISSUED_DATE, filters.issuedDate.type) : formatDateRange(filters.issuedDate)}
        </BillsFilterBadge>}
    </div>;
};

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as checkoutActions from '../../actions/checkoutActions';
import * as orderActions from '../../actions/orderActions';
import * as checkoutSelectors from '../../selectors/checkoutSelectors';
import Utils from '../../utils';
import { ADDITIONAL_FEE_TYPES } from '../../constants/AdditionalFeeTypes';
import { Separator, Typography } from '@notch-ordering/ui-components';

const Summary = (props) => {
  const {
    additionalOrderTotals,
    order,
    vendor,
    customDeliveryFee,
    itemsTotal,
    belowMinimumOrder,
    totalWithCredit,
  } = props;

  const [serviceFee, setServiceFee] = useState(0);

  useEffect(() => {
    if (order.chefheroServicesFee) {
      setServiceFee((itemsTotal * order.chefheroServicesFee).toFixed(2));
    }
  }, [order]);

  let originalServiceFee = {};
  if (order.additionalFees) {
    originalServiceFee = order.additionalFees.find(
      (fee) => fee.feeType === ADDITIONAL_FEE_TYPES.CHEHFERO_SERVICES_FEE
    );
  }

  const adjustServiceFeeTotal =
    order.addOnOrders && order.items && originalServiceFee
      ? Number(serviceFee) - Number(originalServiceFee.amount)
      : 0;
  const adjustServiceFeeTotalTax =
    order.addOnOrders && order.items && originalServiceFee
      ? Number(serviceFee * originalServiceFee.taxPercentage) -
      Number(originalServiceFee.tax)
      : 0;

  const displaySubtotal =
    additionalOrderTotals.ordersTotalMinusTaxes + adjustServiceFeeTotal;
  const displayTax =
    additionalOrderTotals.ordersTotalTaxes + adjustServiceFeeTotalTax;
  const displayTotal =
    totalWithCredit + adjustServiceFeeTotal + adjustServiceFeeTotalTax;

  const deliveryFee =
    customDeliveryFee || Utils.getDeliveryMinusTax(order, vendor);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="checkout-summary pb-6 lg:p-10 text-gray-600 lg:border lg:border-gray-300 lg:rounded-xl">
      <Typography as='div' variant='LG-2' weight='font-semibold' className='pb-6 text-gray-700'>Summary</Typography>
      <div>
        <div className="line">
          <Typography as='div'>Items</Typography>
          <div>
            <Typography as='span' weight='font-medium'>{Utils.formatAsCurrency(itemsTotal)}</Typography>
          </div>
        </div>

        {serviceFee > 0 ? (
          <>
            <div className="line">
              <Typography as='div'>Delivery fee</Typography>
              <Typography as='div'>
                {belowMinimumOrder
                  ? Utils.formatAsCurrency(deliveryFee)
                  : Utils.formatAsCurrency(0)}
              </Typography>
            </div>
            <div className="line">
              <Typography as='div'>Service fee</Typography>
              <Typography as='div'>{Utils.formatAsCurrency(serviceFee)}</Typography>
            </div>
          </>
        ) : (
          <>
            <div className="line">
              <Typography as='div'>Delivery fee</Typography>
              <Typography as='div'>
                {belowMinimumOrder
                  ? Utils.formatAsCurrency(deliveryFee)
                  : Utils.formatAsCurrency(0)}
              </Typography>
            </div>
          </>
        )}
        <Separator variant='small' className='mt-3 mb-5' />
        <div className="line">
          <Typography as='div'>Subtotal</Typography>
          <Typography as='div'>{Utils.formatAsCurrency(displaySubtotal)}</Typography>
        </div>

        <div className="line">
          <Typography as='div'>Taxes</Typography>
          <Typography as='div'>{Utils.formatAsCurrency(displayTax)}</Typography>
        </div>


        { // Note: Credit handling disabled since payments now go through
          // core-api accounts payable (stripe).
        /*
        props.credit.isLoading ||
          (props.credit.amount > 0 && (
            <div className="line credit-line">
              <Typography as='div'>
                Credit{' '}
                {`(${Utils.formatAsCurrency(props.credit.amount)} available)`}
              </Typography>
              <Typography as='div'>
                {props.credit.isLoading
                  ? '-'
                  : `(${Utils.formatAsCurrency(props.creditToUse)})`}
              </Typography>
            </div>
          ))
        */
        }


        <Separator variant='small' className='mt-3 mb-5' />
        <div className="flex justify-between mb-4">
          <Typography as='div' className='text-gray-700'>Estimated total</Typography>
          <Typography as='div' className='text-gray-700'>{Utils.formatAsCurrency(displayTotal)}</Typography>
        </div>

        <Typography as='div'>
          The total price of this order is only an estimate based on the information that you have currently provided. Pricing is subject to change by {order.vendorName} upon receipt and any payments required will be handled by you and {order.vendorName} directly without the involvement of notch.
        </Typography>

        <div className="summary-footer">
          <Modal className="StaticPagesModal" isOpen={openModal}>
            <div className="StaticPagesModal__header">
              <span
                className="StaticPagesModal__close-button"
                onClick={() => setOpenModal(!openModal)}
              />
            </div>
            <iframe
              className="StaticPagesModal__iframe"
              title="Checkout page static content viewer"
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state, props) => ({
    customDeliveryFee: checkoutSelectors.getSupplierCustomDeliveryFee(props),
    itemsTotal: checkoutSelectors.getItemsTotal(props),
    belowMinimumOrder: checkoutSelectors.checkIfBelowMinimumOrder(props),
    singleOrderBeforeChange: state.ordersReducer.singleOrder,
    credit: state.checkoutReducer.credit,
    creditToUse: checkoutSelectors.getCreditToUse(state, props),
    totalWithCredit: checkoutSelectors.getTotalsWithCredit(state, props),
    buyer: state.buyerReducer.buyer,
    isEditingOrderDeliveryDay: state.ordersReducer.isEditingOrderDeliveryDay,
  }),
  (dispatch) => {
    return {
      checkoutActions: bindActionCreators(checkoutActions, dispatch),
      orderActions: bindActionCreators(orderActions, dispatch),
    };
  }
)(Summary);

import React, { useContext } from 'react';
import { Container, Typography, Button, Popover, Skeleton, Tooltip } from '@notch-ordering/ui-components';
import { ESortBy,
    ESortDirection,
    getHeadlineAndSubHeadline } from '@v2/components/Market/MarketHeader/MarketHeaderConstants';
import { SearchPanel } from '@v2/components/Shared/Search/Search';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import { useShopStore } from '@v2/stores/ShopStore';
import SwitchVerticalArrows from '@icons/switch-vertical-arrows-icon.svg';
import { useGetOrderGuide } from '@v2/Pages/Market/MarketQueries.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import CartButton from '@v2/components/Shared/CartButton/CartButton';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@v2/index';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { t } from '@v2/i18n';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import * as cartActions from '@/actions/cartActions';

export interface Props {
    headline?: React.ReactNode,
    subHeadline?: React.ReactNode,
    productsDisplayed?: number,
    onClickFiltersBtn: () => void,
    onSearch?: (search: string) => void,
    isLoadingProducts?: boolean,
}

export const MarketHeader: React.FC<Props> = ({
    productsDisplayed = 0,
    onClickFiltersBtn,
    onSearch,
    isLoadingProducts

}) => {
    const dispatch = useDispatch();
    const isMobileWidth = useIsMobileWidth();
    const { isCartOpen } = useSelector((store:RootState) => store.cartReducer);

    const toggleCart = (): void => {
        dispatch(cartActions.showCart(!isCartOpen));
    };

    const { modals, setModalVisibility, fetchTypeSelected, filters, setFilters } = useContext(MarketContext);
    const { hasClickedOrderGuides, setHasClickedOrderGuides } = useShopStore();
    const { buyer } = useBuyerHook();
    const { data, isLoading } = useGetOrderGuide({ buyerId: buyer.urlsafe, orderGuideID: filters.orderGuideID });

    const toggleOrderGuidesModal = (): void => {
        setModalVisibility('switchOrderGuide', !modals.switchOrderGuide);
        setHasClickedOrderGuides(true);
    };

    const { headline, subHeadline } = getHeadlineAndSubHeadline(fetchTypeSelected, data?.name);

    const handleSortChange = (sort: ESortBy, direction: ESortDirection = null): void => {
        setFilters({
            ...filters,
            sort,
            direction,
        });
    };
    const hasProducts = productsDisplayed > 0;

    const showLoadingTitle = isLoading && !headline;

    return (
        <Container fluid className="bg-white lg:pt-10 pt-4">

            <Container>
                <div className="flex flex-col lg:gap-2 gap-1 lg:mb-0">
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-col cursor-pointer" onClick={toggleOrderGuidesModal}>
                            <Typography className="text-gray-600 mb-0 pt-4">
                                {subHeadline}
                            </Typography>
                            <div className="flex flex-row items-center gap-2">
                                <Typography as="h1"
                                    className="m-0 p-0"
                                    variant="5XL"
                                    size="text-7"
                                    weight="font-medium">
                                    {showLoadingTitle ? <Skeleton className="h-20" size="medium"/> : headline}
                                </Typography>
                                <Tooltip show={!hasClickedOrderGuides}
                                    showArrow
                                    placement="bottom"
                                    trigger={<ChevronDownIcon className="w-11 h-11 p-3 mt-3 rounded-xl text-gray-700 hover:bg-gray-200 "/>}>
                            View your other collections and order guides
                                </Tooltip>
                            </div>
                        </div>
                        {!isMobileWidth && <CartButton
                            className={' mt-4 h-10 py-2 px-4'}
                            onClick={toggleCart}/>}
                    </div>
                    <div className="mb-0 lg:mt-0 mt-6">
                        <SearchPanel
                            onFocus={():void => {
                                sendTrackingEvent(TrackingEvents.enterProductSearchBar);
                            }}
                            onChange={(query): void => onSearch(query)}
                            onClickFiltersBtn={onClickFiltersBtn}
                            className="lg:w-full lg:mt-6"/>
                    </div>

                </div>
            </Container>
            <Container className={'mt-12 mb-4'}>
                <section className="flex justify-between">
                    {hasProducts && <Typography
                        as="p"
                        className="mb-0 p-0 text-gray-600">
                        {productsDisplayed} product{productsDisplayed > 1 ? 's' : ''}
                    </Typography>}
                    {hasProducts
                    && <Popover
                        className="lg:w-48 w-52"
                        buttonClassName="ml-auto"
                        button={ <Button variant="TEXT"
                            size="NO_BACKGROUND"
                            stopPropagation={false}>
                            <div className="flex gap-2"><div>Sort:</div><SwitchVerticalArrows className="inline-flex mt-0.5 w-4 h-4"/></div>
                        </Button>}
                        items={[
                            {
                                label: <div className="flex flex-row justify-between items-center">
                                    <span>{t('Shop.nameAZ')}</span>
                                    {(filters.sort === ESortBy.NAME && filters.direction === ESortDirection.ASC) && <CheckIcon className="h-4 w-4"/>}
                                </div>,
                                onClick: (): void => handleSortChange(ESortBy.NAME, ESortDirection.ASC),
                                active: filters.sort === ESortBy.NAME && filters.direction === ESortDirection.ASC,
                            },
                            {
                                label: <div className="flex flex-row justify-between items-center">
                                    <span>{t('Shop.nameZA')}</span>
                                    {(filters.sort === ESortBy.NAME && filters.direction === ESortDirection.DESC) && <CheckIcon className="h-4 w-4"/>}
                                </div>,
                                onClick: (): void => handleSortChange(ESortBy.NAME, ESortDirection.DESC),
                                active: filters.sort === ESortBy.NAME && filters.direction === ESortDirection.DESC,
                            },
                            {
                                label: <div className="flex flex-row justify-between items-center">
                                    <span>{t('Shop.priceLowHigh')}</span>
                                    {(filters.sort === ESortBy.PRICE && filters.direction === ESortDirection.ASC) && <CheckIcon className="h-4 w-4"/>}
                                </div>,
                                onClick: (): void => handleSortChange(ESortBy.PRICE, ESortDirection.ASC),
                                active: filters.sort === ESortBy.PRICE && filters.direction === ESortDirection.ASC,
                            },
                            {
                                label: <div className="flex flex-row justify-between items-center">
                                    <span>{t('Shop.priceHighLow')}</span>
                                    {(filters.sort === ESortBy.PRICE && filters.direction === ESortDirection.DESC) && <CheckIcon className="h-4 w-4"/>}
                                </div>,
                                onClick: (): void => handleSortChange(ESortBy.PRICE, ESortDirection.DESC),
                                active: filters.sort === ESortBy.PRICE && filters.direction === ESortDirection.DESC,
                            },
                            {
                                label: <div className="flex flex-row justify-between items-center">
                                    <span>{t('Shop.frequentlyOrdered')}</span>
                                    {(filters.sort === ESortBy.FREQUENTLY_ORDERED && filters.direction === ESortDirection.DESC) && <CheckIcon className="h-4 w-4"/>}
                                </div>,
                                onClick: (): void => handleSortChange(ESortBy.FREQUENTLY_ORDERED, ESortDirection.DESC),
                                active: filters.sort === ESortBy.FREQUENTLY_ORDERED && filters.direction === ESortDirection.DESC,
                            },
                            {
                                label: <div className="flex flex-row justify-between items-center">
                                    <span>{t('Shop.recentlyOrdered')}</span>
                                    {(filters.sort === ESortBy.RECENTLY_ORDERED && filters.direction === ESortDirection.DESC) && <CheckIcon className="h-4 w-4"/>}
                                </div>,
                                onClick: (): void => handleSortChange(ESortBy.RECENTLY_ORDERED, ESortDirection.DESC),
                                active: filters.sort === ESortBy.RECENTLY_ORDERED && filters.direction === ESortDirection.DESC,
                            },

                        ]}/>}
                    {isLoadingProducts && <><Skeleton className="h-5 w-48" size="medium"/><Skeleton className="h-5 w-14" size="medium"/> </>}

                </section>
            </Container>
        </Container>
    );
};

export default MarketHeader;

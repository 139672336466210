import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import Utils from '@/utils';

type Props = {
    label: string,
    value: number,
    isTotal?: boolean,
};

export const ScanChartOfAccountTotalsItem : React.FC<Props> = ({ label, value, isTotal }) => {
    const labelColour = isTotal ? 'text-gray-700' : 'text-gray-600';
    const valueWeight = isTotal ? 'font-semibold' : 'font-medium';
    return <div className="flex flex-row gap-1">
        <Typography as="span" className={`${labelColour} font-medium`}>{`${label}:`}</Typography>
        <Typography as="span" className={`text-gray-700 ${valueWeight}`}>{`${Utils.formatAsCurrency(value)}`}</Typography>
    </div>;
};

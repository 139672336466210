import PropTypes from 'prop-types';
import React, { Component } from 'react';

import VariantsList from './body/VariantsList.jsx';

export default class ChangeUnitBody extends Component {
  render() {
    const { openedBy } = this.props;

    return (
      <div className="change-unit-container">
        <div className="change-unit-subtitle">
          {openedBy === 'ORDERGUIDE'
            ? 'Select units you want to add to your order guide...'
            : 'Enter the quantity for the units you need...'}
        </div>
        <VariantsList {...this.props} />
      </div>
    );
  }
}

ChangeUnitBody.propTypes = {
  openedBy: PropTypes.string.isRequired,
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { DEFAULT_PAGE_TITLE, useTitle } from '@v2/hooks/useTitle.hook';

/**
 *
 * @param Component - The component to wrap
 * @description HOC that wraps the component and adds useTitle hook
 */
// eslint-disable-next-line react/display-name
export const withUseTitle = (Component: any, title = '') => (props: any) => {
    useTitle(title ?? DEFAULT_PAGE_TITLE);
    return <Component {...props} />;
};

import React from 'react';
import { Separator, twMergeCustom, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { ScanLineItemsBadge,
    ScanLineItemsBadgeType } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsHeader/ScanLineItemsBadge';

import { ScanLineItemsTopNavigation } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsHeader/ScanLineItemsTopNavigation';
import { Transition } from '@headlessui/react';
import { LineItemCategory, LineItemCategoryEnum, LineItemFocusType, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { onNavigate } from '@v2/components/ScanReview/ScanReviewUtils';
import cx from 'classnames';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';

/**
 * A component that displays the header of the line items section of the Scan Review page, including the title and badges for missing and low confidence fields.
 *
 * @returns JSX.Element
 */

export const ScanLineItemsHeader : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.LineItems' });
    const { draftInvoice, setShowFocusPanel, setFocusType, showFocusPanel, formErrors } = useOcrInvoiceStore();
    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);
    const hasLowConfidenceFields = formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount > 0;
    const hasRequiredFields = formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount > 0;
    const hasErrors = lineItemCategoryToValidate !== LineItemCategoryEnum.ChartOfAccountDetails && (hasLowConfidenceFields || hasRequiredFields);

    return <>
        <ScanLineItemsTopNavigation />
        <Transition
            show={!showFocusPanel}
            enter="transition ease-in duration-100"
            enterFrom="opacity-0  transform -translate-y-10"
            enterTo="opacity-100 transform translate-y-0"
            leave="transition ease-out duration-100"
            leaveFrom="opacity-100 transform translate-y-0"
            leaveTo="opacity-0 transform -translate-y-10">
            <div className={twMergeCustom(cx('px-8 pt-4 pb-4', {
                'pb-8': !hasErrors,
            }),)}>
                <Typography className="lg:text-7 m-0" as="h1" variant="3XL" weight="font-bold">{t('lineItems')}</Typography>
            </div>
            {hasErrors && <div className="flex space-x-2 px-8 mt-2 mb-10">
                {hasRequiredFields && <ScanLineItemsBadge type={ScanLineItemsBadgeType.MissingFields}
                    title={t('countRequiredField', { count: formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount ?? 0 })}
                    onClick={():void => {
                        setShowFocusPanel(true);
                        setFocusType(LineItemFocusType.Required, lineItemCategoryToValidate);

                        if (formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs?.length > 0) {
                            onNavigate(formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs[0]);
                        }
                    }} />}
                {hasLowConfidenceFields && <ScanLineItemsBadge type={ScanLineItemsBadgeType.LowConfidence}
                    title={t('lowConfidenceFieldsCount', { count: formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount ?? 0 })}
                    onClick={():void => {
                        setShowFocusPanel(true);
                        setFocusType(LineItemFocusType.LowConfidence, lineItemCategoryToValidate);
                        if (formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.errorLineItemRefs?.length > 0) {
                            onNavigate(formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.errorLineItemRefs[0]);
                        }
                    }
                    } />}
            </div>}
            <Separator variant="large"/>
        </Transition>
    </>;
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DisplayUnit from '../../DisplayUnit.jsx';
import Utils from '../../../utils';

import { ProductQuantitySelect } from '../../shared';

export default class CartOrderItem extends Component {
  state = {
    quantity: this.props.orderItem.quantity,
    temporaryQuantity: '',
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ quantity: nextProps.orderItem.quantity });
  }

  changeQuantity = (type, quantity = this.state.quantity) => {
    const { order, orderItem, changeQuantity } = this.props;

    changeQuantity(order.urlsafe, orderItem, quantity, type);
  };

  removeItem = (event) => {
    event.preventDefault();
    this.changeQuantity(Utils.QUANTITY_TYPES.DECREASE, 0);
  };

  addNote = (event) => {
    event.preventDefault();
    const { order, orderItem, onClickEdit } = this.props;

    onClickEdit(order.urlsafe, orderItem);
  };

  render() {
    const { orderItem } = this.props;

    const total = orderItem.price * orderItem.quantity;

    const options = Utils.getInputQuantityOptions();

    return (
      <div className="cart-order-item">
        <ProductQuantitySelect
          className="cart-order-item__quantity-select"
          options={options}
          onChange={({ value }) => {
            this.changeQuantity(Utils.QUANTITY_TYPES.CUSTOM, value);
          }}
          value={{
            value: this.state.quantity,
            label: this.state.quantity,
          }}
          onInputChange={(value, { action }) => {
            if (action === 'input-change' && !isNaN(value)) {
              this.setState({
                temporaryQuantity: value,
              });
            } else if (
              action === 'input-blur' &&
              this.state.temporaryQuantity
            ) {
              this.changeQuantity(Utils.QUANTITY_TYPES.CUSTOM, this.state.temporaryQuantity);
              this.setState({
                temporaryQuantity: '',
              });
            }
          }}
          clearable={false}
        />

        <div
          className="cart-order-item__name-and-unit"
          onClick={this.toggleProductDetailModal}
        >
          <div>
            <p className="cart-order-item__name">{orderItem.name}</p>
          </div>

          <div className="cart-order-item__unit">
            {orderItem.isBYOS ? (
              <DisplayUnit unitDescription={orderItem.unitDescription} />
            ) : (
              <DisplayUnit
                unitName={orderItem.unitName}
                unitDescription={orderItem.unitDescription}
              />
            )}
          </div>
        </div>

        <div className="cart-order-item__price">
          <span>{total > 0 && Utils.formatAsCurrency(total)}</span>

          <div className="cart-order-item-remove" onClick={this.removeItem}>
            <span className="fa fa-trash fa-1" />
          </div>
        </div>
      </div>
    );
  }
}

CartOrderItem.propTypes = {
  order: PropTypes.object.isRequired,
  orderItem: PropTypes.object.isRequired,

  changeQuantity: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

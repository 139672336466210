import React from 'react';
import { Button, Typography } from '@notch-ordering/ui-components';
import AddIcon from '@icons/add-icon.svg';

export interface OrderGuideListCreateItemProps {
    onClick: () => void,
}

export const OrderGuideListCreateItem: React.FC<OrderGuideListCreateItemProps> = ({ onClick }) => <div
    onClick={onClick}
    className="px-4 lg:px-5 flex w-full cursor-pointer">
    <div className="gap-4 flex items-center w-full">
        <Button as="div"
            variant="TERTIARY_FILLED"
            size="ICON_MEDIUM"
            defaultColor="bg-teal-100"
            textColor="text-teal-300"
            className="flex items-center justify-center">
            <span className="h-4 w-4 inline-flex"><AddIcon/>
            </span>
        </Button>
        <Typography as="span"
            className="text-teal-300">
                    Create new guide
        </Typography>
    </div>

</div>;

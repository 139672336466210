import React from 'react';
import { Modal, onMobileSetLarge, Typography } from '@notch-ordering/ui-components';
import MarketFilters from '@v2/components/Market/MarketFilters/MarketFilters';
import { TFetchProductsType } from '@v2/network/SecretShopAPI';

export interface Props {
    isOpen: boolean,
    onClose?: () => void,
    setOpen: (isOpen: boolean) => void,
    fetchType: TFetchProductsType,
}

export const MarketFiltersModal : React.FC<Props> = ({ isOpen, setOpen, fetchType }) => <Modal
    titleSeparatorDesktop
    titleSeparatorMobile
    overflowAuto
    modalSize={onMobileSetLarge('MEDIUM', true)}
    title={<Typography
        as="h2"
        weight="font-semibold"
        className="my-0 mx-auto p-0 text-center"
        desktopSize="lg:text-4"
        variant="XL">
            Filters
    </Typography>}
    isOpen={isOpen}
    close={(): void => {
        setOpen(false);
    }}>
    <MarketFilters
        fetchType={fetchType}
        onClickShowResults = {(): void => {
            setOpen(false);
        }}/>
</Modal>;

export default MarketFiltersModal;

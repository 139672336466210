import React, { ReactNode } from 'react';

import CloseIcon from '@icons/close-icon.svg';
import { Button, Tooltip, TooltipProps, Typography } from '@notch-ordering/ui-components';
import { DoubleSyncOnboardingSteps, useDoubleSyncOnboardingStore } from '@v2/stores/DoubleSyncOnboardingStore';
import useAccount from '@v2/hooks/useAccount.hook';
import { t } from '@v2/i18n';

type ScanDoubleSyncOnboardingTooltipProps = Partial<TooltipProps> & {
    step:DoubleSyncOnboardingSteps,
};

const getTooltipContent = (step:DoubleSyncOnboardingSteps):{ title: string, description: string } => {
    switch (step) {
        case DoubleSyncOnboardingSteps.ChartOfAccounts:
            return {
                title: t('ScanReview.dualSyncOnboarding.chartOfAccounts'),
                description: t('ScanReview.dualSyncOnboarding.youCanNowConfigure')
            };
        case DoubleSyncOnboardingSteps.AccountingTabs:
            return {
                title: t('ScanReview.dualSyncOnboarding.integrationTabs'),
                description: t('ScanReview.dualSyncOnboarding.seeWhatDataWillSync')
            };
        default:
            return {
                title: '',
                description: ''
            };
    }
};

const getButtonText = (step:DoubleSyncOnboardingSteps):ReactNode => {
    switch (step) {
        case DoubleSyncOnboardingSteps.ChartOfAccounts:
            return t('Common.Buttons.next');
        case DoubleSyncOnboardingSteps.AccountingTabs:
            return t('Common.Buttons.finish');
        default:
            return null;
    }
};

export const ScanDoubleSyncOnboardingTooltip: React.FC<ScanDoubleSyncOnboardingTooltipProps> = ({ step, trigger, ...props }) => {
    const { currentStep, hasOnboardingBeenSeenByAccount, setAccountSeen, setCurrentStep } = useDoubleSyncOnboardingStore();
    const { urlsafe } = useAccount();
    const { title, description } = getTooltipContent(step);

    const handleClose = ():void => {
        setAccountSeen(urlsafe);
        setCurrentStep(DoubleSyncOnboardingSteps.Modal);
    };

    const handleNextStep = ():void => {
        if (step === DoubleSyncOnboardingSteps.ChartOfAccounts) {
            setCurrentStep(DoubleSyncOnboardingSteps.AccountingTabs);
        } else {
            handleClose();
        }
    };
    const showTooltip = currentStep === step && !hasOnboardingBeenSeenByAccount(urlsafe);

    if (!showTooltip) return (<>{trigger}</>);

    return <Tooltip show={showTooltip}
        tooltipClassName="z-50 w-[244px] "
        showArrow={true}
        trigger={trigger}
        {...props}>
        <div>
            <div className="flex justify-between items-center gap-2 mb-2 ">
                <Typography className="m-0 text-gray-500" variant="BASE">{title}</Typography>
                <CloseIcon className="w-4 h-4 cursor-pointer text-gray-600 hover:text-gray-500 pointer-events-auto"
                    onClick={(e: React.MouseEvent): void => {
                        e.stopPropagation();
                        handleClose();
                    }}/>
            </div>
            <Typography className="mb-3 font-medium">{description}</Typography>
            <Button stopPropagation={true}
                onClick={handleNextStep}
                className="w-full pointer-events-auto"
                variant="TERTIARY_DARK"
                size="SMALL">{getButtonText(step)}</Button>

        </div>
    </Tooltip>;
};

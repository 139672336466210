import { Button, Loading, Modal, Separator, Typography } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import Protected from '@images/protected.svg';
import { browserHistory } from 'react-router';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { EPaths } from '@v2/constants/EPaths';
import { getInvoiceByID, getInvoicePDF } from '@v2/network/GreevilsGreedApi';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { useQuery } from '@tanstack/react-query';

type BillGeneratedProps = {
    orderID: number,
    invoiceID: string,
    isOpen: boolean,
    close: () => void,
};

export const BillGeneratedModal: React.FC<BillGeneratedProps> = ({
    isOpen = false,
    close,
    orderID,
    invoiceID
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const { buyer } = useBuyerHook();
    const [isDownloading, setIsDownloading] = useState(false);
    const isMobileWidth = useIsMobileWidth();
    // Look up the bill data
    const { data: billResponse } = useQuery(
        ['FETCH_BILL_DATA', invoiceID],
        () => getInvoiceByID(buyer.urlsafe, invoiceID),
        {
            enabled: !!invoiceID,
        }
    );
    const bill = billResponse?.length > 0 ? billResponse[0] : null;
    const billNumber = bill?.invoiceNumber;
    return (
        <Modal
            hideHeader
            modalSize={'SMALL'}
            desktopModalWidth="lg:w-[450px]"
            isOpen={isOpen}
            close={close}>
            <div hidden={!isDownloading} className=" h-48 flex flex-col justify-center">
                <Loading isDark/>
            </div>
            <div hidden={isDownloading} className="flex flex-col px-5">
                <div className="flex flex-col justify-start items-start">
                    <Protected className="flex mt-5 w-[151px] h-[92px]"/>
                    <Typography variant="LG-1" desktopSize="lg:text-3" weight="font-semibold" className="mt-7 mb-0">{t('successfulBillGeneration', { orderID })}</Typography>
                    <div className="flex flex-row">
                        <Typography className="text-gray-600 pt-2.5" desktopSize="lg:text-2">{t('downloadPDF')}</Typography>
                        <Button variant="LINK"
                            size="NO_BACKGROUND"
                            className="px-1"
                            stopPropagation={false}
                            onClick={(): void => {
                                setIsDownloading(true);
                                getInvoicePDF(invoiceID, buyer.urlsafe)
                                    .finally(() => setIsDownloading(false));
                            }}>
                            {tCommon('Buttons.downloadPdf')}
                        </Button>
                    </div>
                </div>
            </div>
            <Separator className="mt-2.5" />
            <div className="px-5 pt-5 flex flex-row justify-end items-end gap-x-3">
                {!isMobileWidth && <Button
                    size={'SMALL'}
                    variant={'SECONDARY'}
                    disabled={isDownloading}
                    onClick={():void => {
                        if (billNumber) {
                            browserHistory.push(`/bill/${billNumber}`);
                        } else {
                            browserHistory.push(EPaths.INVOICES);
                        }
                    }}>{tCommon('Buttons.viewBill')}</Button>}
                <Button
                    size={'SMALL'}
                    variant={'TERTIARY_FILLED'}
                    disabled={isDownloading}
                    onClick={close}>{tCommon('Buttons.close')}</Button>
            </div>

        </Modal>);
};

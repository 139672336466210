import { Typography } from '@notch-ordering/ui-components';
import React from 'react';

type Props = {
    icon: JSX.Element,
    titleText: string,
    bodyText: string,
};

/**
 * This is a card that recaps an action the user took during self-onboarding and describes what to expect next.
 */
export const SetupCompleteCard: React.FC<Props> = ({
    icon,
    titleText,
    bodyText
}) => (
    <div className="
        w-full md:max-w-xs
        rounded-xl
        bg-gray-50
        p-5 lg:p-6
     ">

        {icon}

        <div className="pt-4">
            <Typography weight="font-medium" as="div" className="text-gray-700">
                {titleText}
            </Typography>
        </div>

        <div className="pt-1">
            <Typography as="div" className="text-gray-600">
                {bodyText}
            </Typography>
        </div>
    </div>
);

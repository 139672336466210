import { Button, Modal, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';

export interface Props {
    isOpen: boolean,
    productLabel: string,
    deleteFunction: () => void,
    closeFunction: () => void,
    count?: number,
}

export const ConfirmDeletionModal: React.FC<Props> = ({ isOpen, productLabel, deleteFunction, closeFunction, count = 1 }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'BulkUpload' });
    return <Modal isOpen={isOpen}
        title={`${t('areYouSure')} ${productLabel}`}
        close={closeFunction}>
        <div className="px-8 pb-5">
            <Typography as="div" className="text-gray-600">
                {t('onceDeleted', { count })}
            </Typography>
        </div>
        <div className="pt-5 px-5 flex justify-end gap-3">
            <Button variant="TERTIARY_FILLED"
                size="MEDIUM"
                onClick={closeFunction}>
                <Typography as="span" weight="font-medium">
                    {tCommon('Buttons.cancel')}
                </Typography>
            </Button>
            <Button variant="DESTRUCTIVE"
                size="MEDIUM"
                onClick={deleteFunction}>
                <Typography as="span" weight="font-medium">
                    {tCommon('Buttons.delete')}
                </Typography>
            </Button>
        </div>
    </Modal>;
};

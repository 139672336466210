import { Typography } from '@notch-ordering/ui-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ShowDeliveryRequestsView extends Component {
  editDeliveryRequests = () => {
    this.props.changeOrderDeliveryRequestsView('EditDeliveryRequestsView');
  };

  getOrderSpecialDeliveryRequests = (orders) => {
    // We can just refer to the first (of multiple) orders at checkout because the special requests will apply to all of them
    const order = orders[0];
    const orderSpecialRequests = [null, null, null];
    let specialRequestsIndex = 0;
    order.deliveryRequests.split('; ').forEach((orderDeliveryRequest) => {
      // order.deliveryRequests are always in the form
      // deliveryRequests: ...; deliveryContactName: ...; deliveryContactNumber: ...
      // To make sure colons that appear within the delivery requests don't cause an issue, we will split by regex
      // TODO: Look into doing the same for semicolons above
      const labelAndRequest = orderDeliveryRequest.split(/delivery.*?: /);
      orderSpecialRequests[specialRequestsIndex] = labelAndRequest[1];
      specialRequestsIndex++;
    });
    return {
      orderDeliveryRequests: orderSpecialRequests[0],
      orderDeliveryContactName: orderSpecialRequests[1],
      orderDeliveryContactNumber: orderSpecialRequests[2],
    };
  };

  render() {
    const {
      chosenLocation,
      deliveryInstructions,
      deliveryContactName,
      deliveryContactNumber,
      isPendingOrders,
      orders,
    } = this.props;
    const {
      orderDeliveryRequests,
      orderDeliveryContactName,
      orderDeliveryContactNumber,
    } = this.getOrderSpecialDeliveryRequests(orders);
    return (
      <div className="row">
        <div className="col-xs-10">
          <div className="full-address">
            <Typography as='span' className="title">Delivers to: </Typography>
            <Typography as='span'>{chosenLocation}</Typography>
          </div>
          <div className="delivery-instructions">
          <Typography as='span' className="title">Delivery Instructions: </Typography>
            <Typography as='span' className="text">
              {orderDeliveryRequests
                ? orderDeliveryRequests
                : deliveryInstructions}
            </Typography>
          </div>
          <div className="contact-info">
          <Typography as='span' className="title">Contact Information: </Typography>
            {((deliveryContactName && deliveryContactNumber) ||
              (orderDeliveryContactName && orderDeliveryContactNumber)) && (
              <Typography as='span' className="text">
                {orderDeliveryContactName
                  ? orderDeliveryContactName
                  : deliveryContactName}
                , Phone #{' '}
                {orderDeliveryContactNumber
                  ? orderDeliveryContactNumber
                  : deliveryContactNumber}
              </Typography>
            )}
          </div>
        </div>
        <div className="col-xs-2">
          {/* Don't allow the user to change their shipping address in pending order state*/}
          {!isPendingOrders && (
            <button
              onClick={this.editDeliveryRequests}
              className="btn btn-ch-primary-link-on-white pull-right"
            >
              <Typography as='span'>Edit</Typography>
            </button>
          )}
        </div>
      </div>
    );
  }
}

ShowDeliveryRequestsView.propTypes = {
  chosenLocation: PropTypes.string.isRequired,
  deliveryInstructions: PropTypes.string.isRequired,
  deliveryContactName: PropTypes.string.isRequired,
  deliveryContactNumber: PropTypes.string.isRequired,
  changeOrderDeliveryRequestsView: PropTypes.func.isRequired,

  orders: PropTypes.shape().isRequired,
};

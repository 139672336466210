import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DisplayUnit from '../../DisplayUnit.jsx';
import * as changeUnitBGSelectors from '../../../selectors/changeUnitSelectors';

import Utils from '../../../utils';

class VariantRowOrderProduct extends Component {
  state = {
    quantity: this.props.quantity,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ quantity: nextProps.quantity });
  }

  onChangeQuantityByTyping = (event) => {
    const oldQuantity = event.target.value;

    // First let the helper determine the future value of the quantity
    const quantity = Utils.changeQuantityHelper(oldQuantity, Utils.QUANTITY_TYPES.CUSTOM);
    this.setState({ quantity: quantity });
  };

  onBlurQuantity = () => {
    if (this.state.quantity === '') {
      // Keet the original quantity if there is no value in the input box
      this.setState({ quantity: this.props.quantity });
      return;
    }

    this.updateVariantQuantity(Utils.QUANTITY_TYPES.CUSTOM);
  };

  onFocusQuantity = () => {
    // Remove any old quantity value
    this.setState({ quantity: '' });
  };

  updateVariantQuantity = (type) => {
    const { addRemoveVariantCheckout, variant } = this.props;

    addRemoveVariantCheckout(variant, this.state.quantity, type);
  };

  render() {
    const { variant } = this.props;

    let inputGroupClass = '';

    if (variant.inStock) {
      inputGroupClass =
        this.state.quantity > 0 ? 'input-group-above-0' : 'input-group-0';
    } else {
      inputGroupClass = 'input-group-disabled';
    }

    return (
      <div className="variant-row-checkout">
        <div className="display-unit-col">
          <DisplayUnit
            unitName={variant.unit}
            unitDescription={variant.unitDescription}
          />
        </div>

        {/* Only show this div if variant has a price per unit that is > 0 and a standard unit (i.e. variant.pricePerStandardUnit > 0 && variant.standardUnit) */}
        {variant.pricePerStandardUnit > 0 && variant.standardUnit && (
          <div className="price-per-unit-col">
            <span
              className={
                variant.percentOff
                  ? 'discounted-price-per-unit-label'
                  : 'price-per-unit-label'
              }
            >
              ({Utils.formatAsCurrency(variant.pricePerStandardUnit)}/
              {variant.standardUnit})
            </span>
          </div>
        )}

        {/* Always show this div (for alignment purposes) */}
        <div className="out-of-stock-col">
          {/* Only show this span if variant is out of stock (i.e. !variant.inStock) */}
          {!variant.inStock && (
            <span className="out-of-stock-label">OUT OF STOCK</span>
          )}
        </div>

        <div className="variant-price-col">${variant.price}</div>

        <div className="qty-editor-col">
          <div className={inputGroupClass}>
            <span className="input-group-btn">
              <button
                onClick={() => this.updateVariantQuantity(Utils.QUANTITY_TYPES.DECREASE)}
                type="button"
                className="btn btn-number minus"
              >
                <span className="glyphicon glyphicon-minus"></span>
              </button>
            </span>
            <input
              type="text"
              className="input form-control pull-right input-quantity"
              onChange={this.onChangeQuantityByTyping}
              onBlur={this.onBlurQuantity}
              onFocus={this.onFocusQuantity}
              value={this.state.quantity}
              disabled={!variant.inStock ? true : false}
            />
            <span className="input-group-btn">
              <button
                onClick={() => this.updateVariantQuantity(Utils.QUANTITY_TYPES.INCREASE)}
                type="button"
                className="btn btn-number plus"
              >
                <span className="glyphicon glyphicon-plus"></span>
              </button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

VariantRowOrderProduct.propTypes = {
  variant: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
};

function mapStatetoProps(state, props) {
  return {
    quantity: changeUnitBGSelectors.getVariantQuantity(state, props),
  };
}

export default connect(mapStatetoProps, undefined)(VariantRowOrderProduct);

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Utils from '../../../utils';

const DeliveryFeeLineItem = ({ order, orderQualifiesForFreeDel, vendor }) => {
  const deliveryFee = Utils.getDeliveryMinusTax(order, vendor);

  return (
    <div className="cart-order-item del-fee-line-item">
      <div className="cart-order-item__name-and-unit">
        <p className="cart-order-item__name">Delivery fee</p>
        <p className="cart-order-item__unit">For all orders below supplier minimum</p>
      </div>

      <div>
        <span className={cx(
          'cart-order-item__price',
          { 'cart-order-item__price--free-del': orderQualifiesForFreeDel }
        )}
        >
          {Utils.formatAsCurrency(deliveryFee)}
        </span>

        {orderQualifiesForFreeDel && (<span
          className="del-fee-line-item__free-text-indicator"
        >
          {' '}FREE
        </span>)}
      </div>
    </div>
  );
};

DeliveryFeeLineItem.propTypes = {
  order: PropTypes.object.isRequired,
  vendor: PropTypes.object.isRequired,
  orderQualifiesForFreeDel: PropTypes.bool.isRequired,
};

export default DeliveryFeeLineItem;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import useStateWithSessionStorage from '../hooks/useStateWithSessionStorage.jsx';
import { ChatSelectedContext } from '.';
import { setChatSelected } from '../actions/chatSelectedActions';
import { sortChannelListHandler } from '../helpers/chatClientHelper';
import { CHAT_CHANNEL_SELECTED_MESSAGE_LOADING_IN_MSECONDS } from '../../../constants/GlobalConstants';
import useIsMobileWidth from '../../shared/hooks/useIsMobileWidth';

const ChatSelectedProvider = ({ children }) => {
  const [showChatDetails, setShowChatDetails] = useStateWithSessionStorage(
    'showingChatDetails',
    false
  );
  const timeoutRef = useRef(0);
  const isMobileWidth = useIsMobileWidth();
  const {
    chat: {
      chatSelected: { sid },
      messages,
      channels: { list },
    },
    orderHistoryReducer: { myOrders = [] },
  } = useSelector((store) => store);
  const dispatch = useDispatch();

  let order = null;
  if (messages[sid]) {
    order = myOrders.find(
      (o) =>
        o.id?.toString() ===
        messages[sid].messageAttributes.orderNumber?.toString()
    );
  }

  // On initial load set top Chat channel messages to show as selected
  useEffect(() => {
    if (timeoutRef.current === 'NO NEED') return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    // using timeout as channels aren't coming together from twilio, they are being added one by one; as recieved from "channelAdded" twilio event
    timeoutRef.current = setTimeout(() => {
      if (!messages[sid] && Object.keys(list).length > 0) {
        const sortedChannels = Object.entries(list).sort(
          sortChannelListHandler
        );

        if (sortedChannels && sortedChannels.length > 0 && !isMobileWidth) {
          dispatch(setChatSelected(sortedChannels[0][0]));
        }
        timeoutRef.current = 'NO NEED'; // No more needed (because, we have already set the top channel and, moving forward it is not reqiured)
      }
    }, CHAT_CHANNEL_SELECTED_MESSAGE_LOADING_IN_MSECONDS);
  }, [Object.keys(list).length]);

  return (
    <ChatSelectedContext.Provider
      value={{
        sid,
        ...messages[sid],
        order,
        showChatDetails,
        setShowChatDetails,
      }}
    >
      {children}
    </ChatSelectedContext.Provider>
  );
};

export default ChatSelectedProvider;

ChatSelectedProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

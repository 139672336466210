import CheckIcon from '@icons/check-icon.svg';
import { Modal, Typography } from '@notch-ordering/ui-components';
import useOrgHook from '@v2/hooks/useOrg.hook';
import useSwitchBuyerHook from '@v2/hooks/useSwitchBuyer.hook';
import React, { useMemo } from 'react';

type Props = {
    currentBuyerName: string,
    isOpen: boolean,
    close: () => void,
};

export const BuyerSwitcherMobile: React.FC<Props> = ({
    currentBuyerName,
    isOpen,
    close
}) => {
    const { org } = useOrgHook();
    const { switchBuyer } = useSwitchBuyerHook();

    const buyerOrganizations = useMemo(
        () => (org && org.length > 0 ? org.filter((o) => o.buyerKey) : []),
        [org]
    );

    buyerOrganizations.sort((a, b) => a.buyerName.localeCompare(b.buyerName));

    return (
        <Modal isOpen={isOpen} close={close} hideHeader={true}>
            <div className="pt-[60px] px-4 pb-9 flex flex-col gap-6">
                {buyerOrganizations.map((buyer) => {
                    const { buyerKey, buyerName } = buyer;
                    return <div key={buyerKey} className="flex flex-row justify-between" onClick={(): void => { switchBuyer(buyer, true); }}>
                        <Typography as="span" className="truncate text-gray-700">
                            {buyerName}
                        </Typography>
                        {buyerName === currentBuyerName && <CheckIcon className="w-4 h-4 ml-4 flex-none" />}
                    </div>;
                })}
            </div>
        </Modal>
    );
};

import React from 'react';
import { tCommon, tNamespace } from '@v2/i18n';
import { Button, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import ReceiptIcon from '@icons/receipt-icon.svg';
import AddIcon from '@icons/add-icon.svg';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';

export interface UploadsHeaderProps {
    onClickAddBill: () => void,
    onClickBillsButton: () => void,
}

export const UploadsHeader : React.FC<UploadsHeaderProps> = ({ onClickAddBill, onClickBillsButton }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Uploads' });
    const isMobileWidth = useIsMobileWidth();

    return !isMobileWidth ? <div className="flex justify-between items-center lg:pt-10 lg:pb-6">
        <Typography
            as="h1"
            variant="4XL"
            desktopSize="text-8"
            weight="font-medium"
            className="text-gray-700 mb-0 mt-0">
            {t('uploads')}
        </Typography>
        <div className="flex justify-end lg:flex-row flex-col gap-2">
            <Button variant="SECONDARY"
                size="LARGE"
                className="h-10 flex items-center justify-center"
                onClick={onClickAddBill}>{tCommon('Buttons.uploadBill')}</Button>
        </div>
    </div> : <div className="flex flex-col gap-5 pt-8 px-4"><div className="flex justify-between items-center">
        <div className="flex justify-end lg:flex-row flex-col gap-2">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                className="flex items-center justify-center gap-2"
                onClick={onClickBillsButton}><ReceiptIcon className="w-4 h-4" />Bills</Button>
        </div> <div className="flex justify-end lg:flex-row flex-col gap-2">
            <Button variant="TERTIARY_FILLED"
                size="ICON_SMALL"
                className=" flex items-center justify-center"
                onClick={onClickAddBill}><AddIcon className="w-4 h-4 text-gray-700"/></Button>
        </div>
    </div>
    <Typography
        as="h1"
        variant="4XL"
        weight="font-medium"
        className="text-gray-700 mb-0 mt-0 text-6">
        {t('uploads')}
    </Typography>
    </div>;
};

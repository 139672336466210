import useGetSupplier from '@v2/hooks/useGetSupplier.hook';
import React, { useEffect, useState } from 'react';

type Props = {
    name: string,
    supplierUrlsafeKey: string,
};

export const BillsSupplierNameColumn : React.FC<Props> = ({ name, supplierUrlsafeKey }) => {
    if (name) {
        return <>{name}</>;
    }

    const { getSupplierByUrlsafeKey } = useGetSupplier();
    const [supplierName, setSupplierName] = useState('...');

    useEffect(() => {
        async function getSupplier(): Promise<void> {
            const supplier = await getSupplierByUrlsafeKey(supplierUrlsafeKey);
            setSupplierName(supplier?.name);
        }
        getSupplier();
    }, [supplierUrlsafeKey]);

    return <>{supplierName}</>;
};

import React from 'react';
import { Button, Popover, Typography, PopoverProps } from '@notch-ordering/ui-components';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { PopoverItem } from '@notch-ordering/ui-components/src/components/Popover/Popover';
import cx from 'classnames';
import { BUTTON_SIZES } from '@notch-ordering/ui-components/dist/components/Button/ButtonConstants';

export interface BillsFilterButtonProps {
    items: PopoverItem[],
    label: string,
    size?: keyof typeof BUTTON_SIZES,
    className?: string,
    closePanelOnClick?: boolean,
    placement?: PopoverProps['placement'],
}

export const FilterButton : React.FC<BillsFilterButtonProps> = ({ items, label = '', className, closePanelOnClick = true, placement, size = 'MEDIUM' }) => <Popover
    className={cx('w-48', className)}
    buttonClassName="ml-auto"
    closePanelOnClick={closePanelOnClick}
    placement={placement}
    button={ <Button variant="TERTIARY_FILLED"
        size={size}
        className="px-4"
        stopPropagation={false}>
        <div className="flex gap-3 items-center">
            <Typography as="div" className="text-gray-700 mb-0" weight="font-medium">{label}</Typography>
            <ChevronDownIcon className="w-4 h-4 text-gray-600"/>
        </div>
    </Button>}
    items={items}/>;

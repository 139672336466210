import React from 'react';
import { useHomeStore } from '@v2/stores/HomeStore';
import { Button } from '@notch-ordering/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { tCommon } from '@v2/i18n';
import { signout } from '@/actions/signInActions';

export const LogoutButton: React.FC = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    return (
        <Button
            variant="TERTIARY_FILLED"
            size="MEDIUM"
            className="mt-5"
            onClick = {():void => {
                queryClient.invalidateQueries();
                useHomeStore.persist.clearStorage();
                dispatch(signout());
            }} >{tCommon('Buttons.logout')}
        </Button>);
};

/* eslint-disable no-console */
import { isPlatform } from '@ionic/react';
import { EPaths } from '@v2/constants/EPaths';
import { EIonicPlatforms } from '@/constants/Mobile';
import { logException } from '@/domains/shared/logger';
import packageJson from '../../../package.json';
import { getMinVersion } from '@/api/DataAPI';

export function isOutdatedVersion(
    currentVersion = '',
    minVersion = ''
): boolean {
    const currentNumbers: string[] = currentVersion.split('.');
    const minNumbers: string[] = minVersion.split('.');
    if (currentNumbers.length !== 3 || minNumbers.length !== 3) {
        console.error(`Incorrect version number format, cannot check for minimum version. currentVersion = ${currentVersion}, minVersion = ${minVersion}`);
        return false;
    }
    let isOutdated = true;

    // checking if current version is lower than min version
    if (parseInt(currentNumbers[0]) > parseInt(minNumbers[0])) {
        isOutdated = false;
    } else if (parseInt(currentNumbers[0]) === parseInt(minNumbers[0])) {
        if (parseInt(currentNumbers[1]) > parseInt(minNumbers[1])) {
            isOutdated = false;
        } else if (parseInt(currentNumbers[1]) === parseInt(minNumbers[1])) {
            if (parseInt(currentNumbers[2]) >= parseInt(minNumbers[2])) {
                isOutdated = false;
            }
        }
    }
    return isOutdated;
}

export const checkVersion = async (): Promise<void> => {
    try {
        const { data: response } = await getMinVersion();
        if (isOutdatedVersion(packageJson.version, response.minimumVersion)) {
            // Apps go to invalid version, web goes to maintenance
            if (isPlatform(EIonicPlatforms.CAPACITOR)) {
                window.location.href = EPaths.INVALID_VERSION;
            } else {
                window.location.href = EPaths.MAINTENANCE;
            }
        }
    } catch (error) {
        console.error('An Error occurred checking for minimum version.', error);
        logException(error);
    }
};

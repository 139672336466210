import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import * as SettingsActions from '../../actions/settingsActions';
import * as BuyerActions from '../../actions/buyerActions';
import * as ErrorsActions from '../../actions/errorsActions';
import * as AnalyticsActions from '../../actions/analyticsActions';

import MembersMain from '../../components/settings/members/MembersMain.jsx';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

class MembersContainer extends Component {
  componentDidMount() {
    const { settingsActions, analyticsActions } = this.props;
    analyticsActions.viewPage('Members');
    settingsActions.loadBuyerMembers();
  }

  toggleMembersModal = () => {
    const { settingsActions, showMembersModal } = this.props;

    settingsActions.toggleMembersModal(showMembersModal);
  };

  toggleDeleteMembersModal = () => {
    const { settingsActions, showDeleteMembersModal } = this.props;

    settingsActions.toggleDeleteMembersModal(showDeleteMembersModal);
  };

  updateMembersModalIndex = (memberUrlsafe) => {
    const { settingsActions, members } = this.props;

    const index = members.findIndex(
      (member) => member.urlsafe === memberUrlsafe
    );

    settingsActions.updateMembersModalIndex(index);
  };

  saveMember = () => {
    const { membersModalIndex, settingsActions, MemberForm, members, buyer } =
      this.props;

    // We have to wrap this in a try block to show any swalloed errors from redux-form handleSubmit
    try {
      if (MemberForm.values) {
        const data = {
          firstName: MemberForm.values.firstName,
          lastName: MemberForm.values.lastName,
          mobile: MemberForm.values.mobile,
          roleName: MemberForm.values.role,
          receiveNotification: MemberForm.values.receiveNotification,
        };

        if (membersModalIndex > -1) {
          const memberUrlsafe = members[membersModalIndex].urlsafe;

          if (memberUrlsafe) {
            settingsActions.updateMemberSettings(memberUrlsafe, data);
          }
        } else {
          data.email = MemberForm.values.email;
          settingsActions.createMemberSettings(data);
          useOnboardingStore.getState().completeTask(buyer.urlsafe, EOnboardingTasks.ADD_STAFF);
          sendTrackingEvent(TrackingEvents.createStaffClicked);
        }
      } else {
        console.error(`We don't have values in the form and we can still save`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  deleteMember = (selectedMemberUrlsafes) => {
    const { settingsActions } = this.props;

    if (selectedMemberUrlsafes.length > 0) {
      settingsActions.deleteMemberSettings(selectedMemberUrlsafes);
    }
  };

  showError = (errorMessage) => {
    this.props.errorsActions.showError(errorMessage);
  };

  analyticsViewPage = (screenName) => {
    this.props.analyticsActions.viewPage(screenName);
  };

  render() {
    return (
      <MembersMain
        {...this.props}
        toggleMembersModal={this.toggleMembersModal}
        toggleDeleteMembersModal={this.toggleDeleteMembersModal}
        updateMembersModalIndex={this.updateMembersModalIndex}
        saveMember={this.saveMember}
        deleteMember={this.deleteMember}
        showError={this.showError}
        analyticsViewPage={this.analyticsViewPage}
      />
    );
  }
}

MembersContainer.propTypes = {
  membersModalIndex: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(PropTypes.any).isRequired,
  MemberForm: PropTypes.objectOf(PropTypes.any),
  showMembersModal: PropTypes.bool.isRequired,
  showDeleteMembersModal: PropTypes.bool.isRequired,
  settingsActions: PropTypes.objectOf(PropTypes.func).isRequired,
  buyerActions: PropTypes.objectOf(PropTypes.func).isRequired,
  errorsActions: PropTypes.objectOf(PropTypes.func).isRequired,
  analyticsActions: PropTypes.objectOf(PropTypes.func).isRequired,
};

MembersContainer.defaultProps = {
  MemberForm: {},
};

function mapStatetoProps(state) {
  return {
    ...state.settingsReducer,
    ...state.accountReducer,
    ...state.errorsReducer,
    ...state.buyerReducer,

    members: state.buyerReducer.buyerMembers,
    MemberForm: state.form.MemberForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(SettingsActions, dispatch),
    buyerActions: bindActionCreators(BuyerActions, dispatch),
    errorsActions: bindActionCreators(ErrorsActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
  };
}

export default connect(mapStatetoProps, mapDispatchToProps)(MembersContainer);

import React from 'react';
import { Separator, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { useOcrInvoiceStore, updateTotals, getTotals } from '@notch-ordering/shared-logic';
import { mergeClassNames } from '@v2/utils/Helpers';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategory } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import Utils from '@/utils';

/**
 * A component that displays the totals of a draft invoice, including the subtotal, tax, and total.
 *
 * @returns JSX.Element
 */
export const ScanOverviewTotals : React.FC = () => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview' });
    const { draftInvoice, invoiceTopLevelDataErrors } = useOcrInvoiceStore();
    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, false, hasImsIntegration);
    const useChartOfAccountTotals = lineItemCategory === 'chartOfAccountDetails';
    const totals = getTotals(draftInvoice, lineItemCategory, useSeparateIMSLineItems);

    updateTotals(draftInvoice, lineItemCategory, useSeparateIMSLineItems);

    const subtotal = Utils.formatAsCurrency(totals?.subtotal ?? '--');
    const tax = Utils.formatAsCurrency(totals?.tax ?? '--');
    const total = Utils.formatAsCurrency(totals?.total ?? '--');
    const hasInvoiceTotalError = Boolean(invoiceTopLevelDataErrors?.errorFields?.total);

    return <>
        <div className="p-8">
            <Typography variant="LG-2" weight="font-semibold" className="mb-2">{t('totals.totalsTitle')}</Typography>
            {useChartOfAccountTotals
                ? <Typography className="text-gray-600 mb-8">{t('totals.totalsBasedOnChartOfAccounts')}</Typography>
                : <Typography className="text-gray-600 mb-8">{t('totals.totalsBasedOnLineItems')}</Typography>}
            <div className="space-y-2">
                <div className="flex gap-2 justify-between w-full">
                    <Typography as="div" className="text-gray-600">{t('totals.subtotal')}</Typography>
                    <Typography as="div" className="text-gray-700">{subtotal}</Typography>
                </div>
                <Separator className="my-3" />
                <div className="flex gap-2 justify-between w-full">
                    <Typography as="div" className="text-gray-600">{t('totals.tax')}</Typography>
                    <Typography as="div" className="text-gray-700">{tax}</Typography>
                </div>
                <Separator className="my-3" />
                <div className="flex gap-2 justify-between w-full">
                    <Typography as="div"
                        weight="font-semibold"
                        className={mergeClassNames({
                            'text-gray-700': !hasInvoiceTotalError,
                            'text-red-500': hasInvoiceTotalError,
                        })}>
                        {t('totals.total')}
                        {hasInvoiceTotalError && <Typography as="div"
                            className="text-red-500">
                            {t('Errors.invoiceTotalNegative')}
                        </Typography>}
                    </Typography>
                    <Typography as="div"
                        weight="font-semibold"
                        className={mergeClassNames({
                            'text-gray-700': !hasInvoiceTotalError,
                            'text-red-500': hasInvoiceTotalError,
                        })}>{total}</Typography>
                </div>

            </div>
        </div>
    </>;
};

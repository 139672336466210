import { useSelector } from 'react-redux';
import { RootState } from '@/v2';

const useTotalOfProductsInCart = (): number => {
    const orders = useSelector((store: RootState) => store.ordersReducer.orders);
    return orders
        .filter((order) => order.isInShoppingCart)
        .reduce((prevValue, order) => prevValue + order.items.length, 0);
};

export default useTotalOfProductsInCart;

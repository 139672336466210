import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { CheckoutFlowWebViewEvents } from "../../../constants";
import useOrder from "../hooks/useOrder";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";

const ReceivingToolHeader = ({ router, loading, isIonicMobile }) => {
  const {
    order: { data },
  } = useOrder();

  const closeWebView = () => {
    if (isIonicMobile) {
      router.goBack();
    } else {
      router.push({
        pathname: location.pathname,
        query: {
          event: CheckoutFlowWebViewEvents.CANCEL,
        },
      });
    }
  };

  return (
    <div className="od-header-container">
      <div className="od-header">
        <button onClick={closeWebView} disabled={loading}>
          <img
            src={`${BUCKET_ASSETS_URL}caret-left-icon.svg`}
            alt="Back Icon"
          />
        </button>
        <div className="title">
          Report Issue <span>| {data.vendorName}</span>
          <div>
            <span>Order #{data.id}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

ReceivingToolHeader.propTypes = {
  router: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  isIonicMobile: PropTypes.bool.isRequired,
};

export default withRouter(ReceivingToolHeader);

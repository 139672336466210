import create from 'zustand';
import { persist } from 'zustand/middleware';

// Define the state interface
type DoubleSyncOnboardingState = {
    currentStep: number,
    // variable of the buyers who has shown the onboarding steps
    seenAccounts: Record<string, boolean>,

};

export const enum DoubleSyncOnboardingSteps {
    Modal = 0,
    ChartOfAccounts = 1,
    AccountingTabs = 2,
}

// Define the actions interface
type DoubleSyncOnboardingActions = {
    setCurrentStep: (step: number) => void,
    setAccountSeen: (accountUrlsafeKey: string) => void,
    hasOnboardingBeenSeenByAccount: (buyerId: string) => boolean,
};

// Define the combined store interface
type DoubleSyncOnboardingStore = DoubleSyncOnboardingState & DoubleSyncOnboardingActions;

const doubleSyncOnboardingStoreName = 'double-sync-onboarding';
export const useDoubleSyncOnboardingStore = create<DoubleSyncOnboardingStore>()(
    persist(
        (set, getState): DoubleSyncOnboardingStore => ({
            currentStep: DoubleSyncOnboardingSteps.Modal,
            seenAccounts: {},
            setCurrentStep: (currentStep): void => set(() => ({ currentStep })),
            setAccountSeen: (accountUrlsafeKey): void => {
                const { seenAccounts } = getState();
                seenAccounts[accountUrlsafeKey] = true;
                set(() => ({ seenAccounts }));
            },
            hasOnboardingBeenSeenByAccount: (accountUrlsafeKey): boolean => {
                const { seenAccounts } = getState();
                return !!seenAccounts[accountUrlsafeKey];
            }
        }),
        { name: doubleSyncOnboardingStoreName }
    )
);

import create from 'zustand';
import { IColumnMap } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStepConstants';
import { IBulkImportRequestPayload, SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { ISupplierCatalog } from '@v2/network/types';

export enum EProductAvailability {
    IN_STOCK = 'IN_STOCK',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
    DISABLED = 'DISABLED',
}

export type TProductImport = {
    productName: string,
    productDescription: string,
    productCategory: string,
    productTaxCode: string,
    packageDescription: string,
    packageAvailability: EProductAvailability,
    packageSku: string,
    packageUnit: string,
    packageQuantity: number,
    defaultPrice: number,
    priceListPrices: never,
    errors?: Array<InvalidImportProductError>,
};

export type InvalidImportProductError = { field: string, message: string };
export type TInvalidImport = { product: TProductImport, errors: Array<InvalidImportProductError> };

export type TValidateImportInfo = {
    addedProducts: Partial<TProductImport>[],
    updatedProducts: Partial<TProductImport[]>,
    invalidProducts: Partial<TInvalidImport[]>,
};

type TBulkUploadStoreState = {
    validateImportInfo:TValidateImportInfo,
    setValidateImportInfo: (validateImportInfo: TValidateImportInfo) => void,
    csvColumns: string[],
    setCsvColumns: (columns: string[]) => void,
    columnMapping: IColumnMap,
    setColumnMapping: (columnMap: IColumnMap) => void,
    bulkImportRequestPayload: IBulkImportRequestPayload,
    setBulkImportRequestPayload: (payload: IBulkImportRequestPayload) => void,
    cleanBulkUploadStore?: () => void,
    currentSupplier: SupplierCatalogData,
    setCurrentSupplier: (supplier: SupplierCatalogData) => void,
    isModalBulkUploadOpen: boolean,
    setModalBulkUploadOpen: (open: boolean) => void,
};

export const useBulkUploadStore = create<TBulkUploadStoreState>((set) => ({
    validateImportInfo: {
        addedProducts: [],
        updatedProducts: [],
        invalidProducts: []
    },
    setValidateImportInfo: (validateImportInfo: TValidateImportInfo): void => set(() => ({ validateImportInfo })),
    csvColumns: [],
    setCsvColumns: (csvColumns: string[]): void => set(() => ({ csvColumns })),
    columnMapping: {},
    setColumnMapping: (columnMapping: IColumnMap): void => set(() => ({ columnMapping })),
    bulkImportRequestPayload: {
        data: null,
        supplierCatalogID: null,
    },
    setBulkImportRequestPayload: (bulkImportRequestPayload: IBulkImportRequestPayload): void => set(() => ({ bulkImportRequestPayload })),
    currentSupplier: null,
    setCurrentSupplier: (currentSupplier: ISupplierCatalog): void => set(() => ({ currentSupplier })),
    isModalBulkUploadOpen: false,
    setModalBulkUploadOpen: (modalOpen: boolean): void => set(() => ({ isModalBulkUploadOpen: modalOpen })),
    cleanBulkUploadStore: (): void => set(() => ({
        validateImportInfo: {
            addedProducts: [],
            updatedProducts: [],
            invalidProducts: []
        },
        csvColumns: [],
        columnMapping: {},
        bulkImportRequestPayload: {
            data: null,
            supplierCatalogID: null,
        },
        isModalBulkUploadOpen: false,
    })),

}),);

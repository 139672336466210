import { Button, Modal, onMobileSetLarge, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import Protected from '@images/protected.svg';
import { browserHistory } from 'react-router';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { ESettingsPaths } from '@v2/constants/EPaths';

type IntegrationCompleteProps = {
    isOpen: boolean,
    close: () => void,
    refetch: () => void,
};

export const IntegrationsCompleteModal: React.FC<IntegrationCompleteProps> = ({
    isOpen = false,
    close,
    refetch,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });

    function goToIntegrations(): void {
        browserHistory.push(ESettingsPaths.INTEGRATIONS);
    }

    return (
        <Modal
            hideHeader
            modalSize={onMobileSetLarge('MEDIUM')}
            hideCloseButton
            desktopModalWidth="lg:w-[750px]"
            isOpen={isOpen}
            close={close}>
            <div className="flex flex-col justify-center items-center px-4">
                <div className="flex flex-col mt-[67px] justify-center items-center max-w-[448px]">
                    <Protected className="mb-4 flex w-[151px] h-[92px]"/>
                    <Typography variant="3XL" className="mt-4 mb-2 text-center">{t('integrationFinished')}</Typography>
                    <Typography
                        className="m-0 mb-10 text-center text-gray-600">
                        {t('completeSetupDescription')}
                    </Typography>
                </div>
                <Button
                    className="mb-[43px]"
                    size={'MEDIUM'}
                    variant={'SECONDARY'}
                    onClick={():void => {
                        close();
                        refetch();
                        goToIntegrations();
                    }}>{tCommon('Buttons.close')}</Button>
            </div>
        </Modal>);
};

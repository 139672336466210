import React from 'react';
import AttachmentIcon from '@icons/file-attachment-icon.svg';
import { Badge, Typography } from '@notch-ordering/ui-components';

export interface UploadAssetProps {
    url: string,
    className?: string,
    imageSize?: number,
    pdfHeight?: string,
    pdfWidth?: string,
    smallThumbnail?: boolean,
}

const handleOnError = ({ currentTarget }):void => {
    currentTarget.onerror = null;
    // hiding the image if for some reason it fails to load
    currentTarget.style.display = 'none';
};

export const AttachmentThumbnail : React.FC<UploadAssetProps> = ({ url, pdfHeight, pdfWidth, imageSize, smallThumbnail }) => {
    const isPdf = url?.toLowerCase().includes('.pdf');

    if (isPdf) {
        return <div className={`${pdfHeight} ${pdfWidth} border border-gray-300 flex justify-center items-center rounded-lg`}>
            {!smallThumbnail ? <div className="flex flex-col gap-y-1.5 items-center">
                <AttachmentIcon className="w-4 h-4 text-gray-700" />
                <Badge size="SMALL" widthPadding="px-1" backgroundColor="bg-red-200">PDF</Badge>
            </div>
                : <div className="flex flex-col py-1 items-center pointer-events-none">
                    <AttachmentIcon className="relative top-1.5 w-4 h-4 text-gray-700" />
                    <Badge size="SMALL" className="z-10 mb-1 rounded" widthPadding="px-1" backgroundColor="bg-red-200">
                        <Typography className="p-0 m-0" variant="SM" desktopSize="text-1">
                            PDF
                        </Typography>
                    </Badge>
                </div>}
        </div>;
    }
    return <div>
        {(url?.includes('lh3.googleusercontent.com/') ? <img onError={handleOnError} className="rounded-lg" src={`${url}=s${imageSize}`} key={url}/> : <img onError={handleOnError} src={`${url}`} key={url}/>)}
    </div>;
};

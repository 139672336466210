import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChatChannelsListContainer from './containers/ChatChannelsListContainer.jsx';
import ChatSelectedContainer from './containers/ChatSelectedContainer.jsx';
import ChatSelectedDetailsContainer from './containers/ChatSelectedDetailsContainer.jsx';
import ChatChannelsListProvider from './context/ChatChannelsListProvider.jsx';
import ChatSelectedProvider from './context/ChatSelectedProvider.jsx';
import LoadingData from '../../components/LoadingData.jsx';
import EmptyChat from './components/ChatEmpty.jsx';
import { inboxScreenLoaded } from '../../actions/analyticsActions.js';
import { loadCartOrders } from '../../actions/orderActions';
import { setChatSelected } from './actions/chatSelectedActions';
import useIsMobileWidth from '../shared/hooks/useIsMobileWidth';
import { sortChannelListHandler } from './helpers/chatClientHelper';
import { tNavigation} from "@v2/i18n";
import {useTitle} from "@v2/hooks/useTitle.hook";

export default () => {
  const dispatch = useDispatch();
  const chat = useSelector((store) => store.chat);
  const isChatReady = useSelector((state) => state.chat.auth.isChatReady);
  const isMobileWidth = useIsMobileWidth();
  const {
    chatSelected: { sid },
  } = useSelector((store) => store.chat);

  useTitle(tNavigation('Messages'));
  useEffect(() => {
    dispatch(loadCartOrders());
    return () => {
      dispatch(setChatSelected(null, true));
    };
  }, []);

  useEffect(() => {
    if (isChatReady) {
      const numOfChatChannels = Object.keys(chat.channels.list).length;

      dispatch(inboxScreenLoaded(numOfChatChannels, chat.auth.openingSource));
    }
  }, [isChatReady]);

  useEffect(() => {
    // this handles if page is resized from mobile to desktop and no channel is selected by default - edge case
    if (
      isChatReady &&
      !isMobileWidth &&
      !sid &&
      Object.entries(chat?.channels?.list).length > 0
    ) {
      const sortedChannels = Object.entries(chat.channels.list).sort(
        sortChannelListHandler
      );
      dispatch(setChatSelected(sortedChannels[0][0]));
    }
  }, [isMobileWidth, isChatReady]);

  return !chat.auth.isChatReady || chat.auth.isCreatingChannel ? (
    <LoadingData display={true} text="Loading" />
  ) : (
    <>
      {Object.keys(chat.channels.list).length === 0 ? (
        <EmptyChat />
      ) : (
        <div className="chat-container">
          <ChatChannelsListProvider>
            <ChatChannelsListContainer />
          </ChatChannelsListProvider>

          <ChatSelectedProvider>
            <>
              <ChatSelectedContainer />
              <ChatSelectedDetailsContainer />
            </>
          </ChatSelectedProvider>
        </div>
      )}
    </>
  );
};

import React from 'react';
import { useDispatch } from 'react-redux';

import { goToMyOrders } from '../actions/chatEmptyActions';

export default () => {
  const dispatch = useDispatch();
  const gotoMyOrders = () => dispatch(goToMyOrders());

  return (
    <div className="chat-empty-container">
      <div className="main-text">Inbox is currently empty.</div>
      <div className="info-text">
        Go to any order to initiate a chat with your suppliers.
      </div>
      <button className="go-to-button" onClick={gotoMyOrders} type="button">
        Go to My Orders
      </button>
    </div>
  );
};

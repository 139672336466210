import React from 'react';
import { Badge, Button } from '@notch-ordering/ui-components';
import CloseIcon from '@icons/close-icon.svg';

export interface FiltersBadgeProps {
    children: React.ReactNode,
    label?: string,
    clearFilter: () => void,
}

export const FiltersBadge : React.FC<FiltersBadgeProps> = (
    {
        children,
        label,
        clearFilter,
    }
) => <Badge variant={'GRAY'}
    size={'MEDIUM'}
    iconRight={<Button variant="ICON"
        size="ICON_SMALL"
        heightPadding="py-0"
        widthPadding="px-0"
        onClick={clearFilter}><CloseIcon className="w-4 h-4 text-gray-600"/></Button>}
    className="items-center justify-center gap-1 inline-block">
    <div>
        <span className="text-gray-600">{label}</span>{' '}
        {children}
    </div>
</Badge>;

import React from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@icons/close-icon.svg';
import PropTypes from 'prop-types';

import { showCart } from '../../actions/cartActions';

const CartHeader = ({ cartsCount }) => {
  const dispatch = useDispatch();
  const cartIsEmpty = cartsCount === 0;

  return (
    <div className='cart-header'>
      {!cartIsEmpty && (<h3
        className='cart-header__text'
      >
        My cart{cartsCount > 1 ? 's' : null}
      </h3>)}

      <button
        className='cart-header__close-button'
        onClick={() => dispatch(showCart(false))}
      >
        <CloseIcon className="w-4 h-4 text-gray-700" />
      </button>
    </div>
  );
};

CartHeader.propTypes = {
  cartsCount: PropTypes.number.isRequired
};

export default CartHeader;

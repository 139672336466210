import React from 'react';
import Router from 'react-router';
import { Button, Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import DeliveryImage from '@images/delivery.svg';
import { EPaths } from '@v2/constants/EPaths';

type Props = {
    router: Router,
};

export const OrdersEmpty : React.FC<Props> = ({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    return <div className="flex flex-col justify-center items-center h-full p-4 lg:p-0">
        <DeliveryImage className="w-[306px] h-[155px] mb-[70px]" />
        <Typography as="div"
            className="text-center"
            variant="XL"
            desktopSize="text-5"
            weight="font-semibold">{t('placeOrder')}</Typography>
        <Typography as="div" className="text-gray-600 text-center mt-2 mb-16 max-w-[423px]">{t('afterOrderPlaced')}</Typography>
        <Button variant="TERTIARY_FILLED"
            onClick={(): void => {
                router.push(`${EPaths.SHOP}`);
            }}>
            {tCommon('Buttons.goToShop')}
        </Button>
    </div>;
};

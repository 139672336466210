import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Checkbox } from 'react-icheck';

class renderFieldCheckbox extends Component {
  render() {
    const { input, label, name, meta } = this.props;
    return (
      <div className="input-group check-box">
        <Checkbox
          name={name}
          checkboxClass="icheckbox_square-purple"
          increaseArea="20%"
          checked={input.checked}
          onChange={() => input.onChange(!input.checked)}
        />
        <label
          htmlFor={name}
          style={{
            marginLeft: '20px',
            fontWeight: 700,
            position: 'relative',
            top: 2,
          }}
        >
          {' '}
          {label}
        </label>
      </div>
    );
  }
}

export default renderFieldCheckbox;

import { IColumnMap } from '@v2/components/BulkUpload/BulkMapFieldsStep/BulkMapFieldsStepConstants';

/**
 * Generate form data for bulk import products mutation
 *
 * @param file
 * @param columnMap
 */
export const generateFormData = (file: File, columnMap: IColumnMap): FormData => {
    const formDataObj = new FormData();
    formDataObj.append('products', file, file.name);
    // eslint-disable-next-line no-restricted-syntax
    for (const key in columnMap) {
        if (columnMap[key]) {
            formDataObj.append(key, columnMap[key]);
        }
    }
    return formDataObj;
};

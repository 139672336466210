import React, { ElementType } from 'react';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import AddIcon from '@icons/add-icon.svg';
import { Button, twMergeCustom } from '@notch-ordering/ui-components';
import cx from 'classnames';

export interface Props {
    numberOfItemsInCart: number,
    onClick?: () => void,
    className?: string,
    rotate?: boolean,
    as?: ElementType,
}

const AddToCartButton: React.FC<Props> = ({
    numberOfItemsInCart = 0,
    onClick,
    className,
    rotate = false,
    as = 'button',
}) => {
    const hasItemsInCart = numberOfItemsInCart > 0;
    const buttonVariant = hasItemsInCart ? 'SECONDARY' : 'TERTIARY_FILLED';
    return (
        <Button
            as={as}
            stopPropagation={false}
            variant={buttonVariant}
            size="ICON_MEDIUM"
            className={twMergeCustom(
                cx('flex items-center justify-center h-10', className)
            )}
            onClick={onClick}>
            {hasItemsInCart ? (
                <div className={'flex pl-1 gap-1 items-center'}>
                    {numberOfItemsInCart}
                    <span className="w-5 h-5  text-white"><ChevronDownIcon className="h-4 w-4"/></span>
                </div>
            ) : (
                <AddIcon className={cx('w-4 h-4 max-w-none transition-transform', {
                    'rotate-45': rotate,
                })}/>

            )}
        </Button>
    );
};

export default AddToCartButton;

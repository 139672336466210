import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import ChevronRightIcon from '@icons/chevron-right-icon.svg';
import cx from 'classnames';
import LowConfidenceScanIcon from '@icons/low-confidence-scan-icon.svg';
import WarningIcon from '@icons/warning-icon.svg';

export const enum ScanLineItemsBadgeType {
    MissingFields = 'missingFields',
    LowConfidence = 'lowConfidence',
}
export interface ScanLineItemsBadgeProps {
    title?: React.ReactNode,
    onClick?: (e?: React.MouseEvent) => void,
    type?: ScanLineItemsBadgeType,
}

const getBadgeStylesByType = (type: ScanLineItemsBadgeType): { icon?: React.ReactNode, background: string } => {
    switch (type) {
        case ScanLineItemsBadgeType.MissingFields:
            return { icon: <WarningIcon className="h-3 w-3 text-orange-400" />, background: 'bg-orange-100' };
        case ScanLineItemsBadgeType.LowConfidence:
            return { icon: <LowConfidenceScanIcon className="h-3 w-3 text-orange-400" />, background: 'bg-[#EFF6F9]' };
        default:
            return { background: 'bg-orange-100' };
    }
};
export const ScanLineItemsBadge : React.FC<ScanLineItemsBadgeProps> = ({ title = '2 required fields', onClick, type = ScanLineItemsBadgeType.MissingFields }) => {
    const { icon: badgeIcon, background } = getBadgeStylesByType(type);
    return <div className={cx('bg-orange-100 rounded-lg px-2.5 py-1 flex items-center cursor-pointer h-6', background)}
        role="button"
        tabIndex={0}
        onClick={onClick}>
        {badgeIcon}
        <Typography as="span" weight="font-medium" className="ml-2">
            {title}
        </Typography>
        <ChevronRightIcon className="w-3 h-3 ml-0.5 "/>

    </div>;
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DragAndDrop extends Component {
  state = {
    dragging: false,
  };

  dropRef = React.createRef();

  dragCounter = 0;

  handleDragIn = (e) => {
    e.preventDefault();
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      e.dataTransfer.dropEffect = 'copy';
      this.setState({ dragging: true });
    } else {
      e.dataTransfer.dropEffect = 'none';
    }
  };

  handleDragOut = (e) => {
    e.preventDefault();
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ dragging: false });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({ dragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  handleDragFunction = (e) => {
    if ($.inArray('Files', e.dataTransfer.types) > -1) {
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      e.preventDefault();

      e.dataTransfer.dropEffect = 'copy';

      if (e.type === 'dragover') {
        e.preventDefault();
        if (e.stopPropagation) {
          e.stopPropagation();
        }
      } else if (e.type === 'dragleave') {
        e.preventDefault();
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        this.dragCounter--;
        if (this.dragCounter === 0) {
          this.setState({ dragging: false });
        }
      } else if (e.type === 'dragenter') {
        e.preventDefault();
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        this.dragCounter++;
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
          this.setState({ dragging: true });
        }
      } else if (e.type === 'drop') {
        e.preventDefault();
        if (e.stopPropagation) {
          e.stopPropagation();
        }
        this.setState({ dragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          this.props.handleDrop(e.dataTransfer.files); // This is like the callback function
          e.dataTransfer.clearData();
          this.dragCounter = 0;
        }
      }
    }
  };

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragFunction, false);
    div.addEventListener('dragleave', this.handleDragFunction, false);
    div.addEventListener('dragover', this.handleDragFunction, false);
    div.addEventListener('drop', this.handleDragFunction, false);
  }

  componentWillUnmount() {
    const div = this.dropRef.current;
    div.removeEventListener('dragenter', this.handleDragFunction, false);
    div.removeEventListener('dragleave', this.handleDragFunction, false);
    div.removeEventListener('dragover', this.handleDragFunction, false);
    div.removeEventListener('drop', this.handleDragFunction, false);
  }

  render() {
    return (
      <div className="drag-drop-main-container" ref={this.dropRef}>
        {this.state.dragging && (
          <div className="drag-drop-container">
            <div className="drag-drop-area">
              <div className="drag-drop-dropzone" />{' '}
              {/* This can be used to customize the dropzone UI */}
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

export default DragAndDrop;

DragAndDrop.propTypes = {
  children: PropTypes.any.isRequired,
};

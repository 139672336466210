import { Badge, Button, Separator, twMergeCustom } from '@notch-ordering/ui-components';
import LogoIcon from '@icons/logo-icon.svg';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router';
import ShoppingBagIcon from '@icons/shopping-bag-icon.svg';
import ShoppingBagActiveIcon from '@icons/shopping-bag-active-icon.svg';
import SettingIcon from '@icons/setting-icon.svg';
import SettingActiveIcon from '@icons/setting-active-icon.svg';
import MessagesUnreadIcon from '@icons/message-unread-icon.svg';
import MessagesUnreadActiveIcon from '@icons/message-unread-active-icon.svg';
import MessagesActiveIcon from '@icons/message-active-icon.svg';
import HouseIcon from '@icons/house-icon.svg';
import HouseActiveIcon from '@icons/house-active-icon.svg';
import AccountIcon from '@icons/account-icon.svg';
import AccountActiveIcon from '@icons/account-active-icon.svg';
import MessagesIcon from '@icons/message-icon.svg';
import TicketIcon from '@icons/ticket-icon.svg';
import TicketActiveIcon from '@icons/ticket-active-icon.svg';
import HelpIcon from '@icons/help-icon.svg';
import ReceiptIcon from '@icons/receipt-icon.svg';
import ReceiptActiveIcon from '@icons/receipt-active-icon.svg';
import NavLink from '@v2/components/Shared/NavLink/NavLink';
import BuyerSwitcher from '@v2/components/Shared/BuyerSwitcher/BuyerSwitcher';
import useTotalOfProductsInCart from '@v2/hooks/useTotalOfProductsInCart.hook';
import { EPaths, ESettingsPaths } from '@v2/constants/EPaths';
import cx from 'classnames';
import { t } from '@v2/i18n';
import { HELP_DESK } from '@v2/constants/ExternalUrls';
import { useSelector } from 'react-redux';
import { RootState } from '@v2/index';
import { ERoleName } from '@v2/types/OrgData';
import useRolesHook from '@v2/hooks/useRolesHook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { MAX_QUANTITY } from '../CartButton/CartButtonConstants';

export interface NavProps {
    locationName?: string,
    total?: string,
    location?: Location,
    toggleCart?: () => void,
}

export interface CartProps {
    showCounter?: boolean,
    onClick?: () => void,
    className?: string,
}

export interface INavItems {
    icon?: React.ReactNode,
    name: string,
    path: string,
}

export const Cart: React.FC<CartProps> = ({ onClick }) => {
    const productsInCart = useTotalOfProductsInCart();
    const value = productsInCart > MAX_QUANTITY ? `${MAX_QUANTITY}+` : productsInCart;

    return (
        <div className="flex flex-row py-1.5 rounded-xl text-gray-600 hover:bg-gray-100 active:bg-gray-200">
            <Button
                variant="SUBDUED_ICON"
                size="NO_BACKGROUND"
                heightPadding="py-0"
                className="w-full flex flex-row justify-between ml-9"
                onClick={onClick}>
                <div className="w-full flex flex-row justify-between">
                    <span>{t('Navigation.Cart')}</span>
                    <Badge variant={'GRAY'} size={'SMALL'} className="lg:text-1 lg:text-gray-650 lg:rounded-md lg:py-0.5 lg:px-1">
                        {value}
                    </Badge>
                </div>
            </Button>
        </div>
    );
};

export const SideNavigation: React.FC<NavProps> = ({
    locationName = '',
    location,
    toggleCart,

}) => {
    const productsInCart = useTotalOfProductsInCart();
    const value = productsInCart > MAX_QUANTITY ? `${MAX_QUANTITY}+` : productsInCart;
    const [shopActive, setShopActive] = useState<boolean>(false);
    const [shopTabsActive, setShopTabsActive] = useState<boolean>(false);
    const [catalogActive, setCatalogActive] = useState<boolean>(false);
    const [settingsEnabled, setSettingsEnabled] = useState<boolean>(false);
    const [settingsTabsActive, setSettingsTabsActive] = useState<boolean>(false);
    const [integrationsActive, setIntegrationsActive] = useState<boolean>(false);
    const { roles, hasRole } = useRolesHook();
    const { channels: { unreadMessagesCount }, } = useSelector((store: RootState) => store.chat);
    const isInvoiceSubmenu = location.pathname.includes(EPaths.INVOICES) || location.pathname.includes(EPaths.UPLOADS);
    const isInvoiceNavActive = location.pathname.includes(EPaths.INVOICES) || location.pathname.includes(EPaths.UPLOADS);
    const accountingIntegrationsEnabled = useBuyerHasFeatureFlagEnabled(EFirebaseParams.ACCOUNTING_BUYERS);

    function getMessagesIcon(isMessagesActive = false): JSX.Element {
        if (isMessagesActive) {
            return unreadMessagesCount > 0 ? <MessagesUnreadActiveIcon /> : <MessagesActiveIcon />;
        }
        return unreadMessagesCount > 0 ? <MessagesUnreadIcon /> : <MessagesIcon />;
    }

    useEffect(() => {
        if ((EPaths.CATALOG.includes(location.pathname))) {
            setShopActive(false);
            setShopTabsActive(true);
            setCatalogActive(true);
            setSettingsTabsActive(false);
            setIntegrationsActive(false);
        } else if ((EPaths.SHOP.includes(location.pathname))) {
            setShopActive(true);
            setShopTabsActive(true);
            setCatalogActive(false);
            setSettingsTabsActive(false);
            setIntegrationsActive(false);
        } else if (ESettingsPaths.INTEGRATIONS.includes(location.pathname)) {
            setShopActive(false);
            setShopTabsActive(false);
            setCatalogActive(false);
            setSettingsTabsActive(true);
            setIntegrationsActive(true);
        } else if (EPaths.SETTINGS.includes(location.pathname)) {
            setShopActive(false);
            setShopTabsActive(false);
            setCatalogActive(false);
            setSettingsTabsActive(true);
            setIntegrationsActive(false);
        } else {
            setShopActive(false);
            setShopTabsActive(false);
            setCatalogActive(false);
            setSettingsTabsActive(false);
            setIntegrationsActive(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        setSettingsEnabled(hasRole([ERoleName.MANAGER, ERoleName.OWNER], false));
    }, [roles]);

    return (
        <nav className="absolute z-50 flex flex-col items-left justify-between ml-2.5 h-full bg-white">
            <div>
                <Link to="/" aria-label="Home">
                    <LogoIcon className="h-5 mt-6 ml-4 text-gray-700" />
                </Link>
                <div className="mt-7 mb-4">
                    <BuyerSwitcher
                        name={locationName}
                        onClick={(): void => {
                            sendTrackingEvent(TrackingEvents.venueSwitcherClicked);
                        }}/>
                </div>
                <Separator/>
                <div className="flex flex-col mt-4 gap-y-px">
                    <NavLink
                        to={EPaths.HOME}
                        key={t('Navigation.Home')}
                        onClick={(): void => {
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Home') });
                        }}
                        isActive={EPaths.HOME.includes(location.pathname)}>
                        <div className="flex gap-3 items-center">
                            <div className="flex w-4 h-4">
                                {EPaths.HOME.includes(location.pathname) ? <HouseActiveIcon /> : <HouseIcon />}
                            </div>
                            {t('Navigation.Home')}
                        </div>
                    </NavLink>
                    <NavLink
                        isActive={location.pathname.includes(EPaths.INVOICES)}
                        to={EPaths.INVOICES}
                        key={t('Navigation.Bills')}
                        onClick={(): void => {
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Bills') });
                        }}>
                        <div className="flex gap-3 items-center">
                            <div className="flex w-4 h-4">
                                {isInvoiceSubmenu ? <ReceiptActiveIcon className="text-teal-500" /> : <ReceiptIcon />}
                            </div>
                            {t('Navigation.Bills')}
                        </div>
                    </NavLink>
                    {isInvoiceNavActive && <NavLink
                        to={EPaths.UPLOADS}
                        className="lg:pl-11"
                        key={t('Navigation.Uploads')}
                        onClick={(): void => {
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Uploads') });
                        }}
                        isActive={location.pathname.includes(EPaths.UPLOADS)}>
                        <div className="flex gap-3 items-center">
                            {t('Navigation.Uploads')}
                        </div>
                    </NavLink>}
                    <NavLink
                        to={EPaths.SHOP}
                        key={t('Navigation.Shop')}
                        onClick={(): void => {
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Shop') });
                        }}
                        isActive={EPaths.SHOP.includes(location.pathname)}>
                        <div className={twMergeCustom(
                            cx([
                                'flex flex-row justify-between rounded-xl items-center',
                                {
                                    'text-teal-500 bg-gray-100 hover:text-teal-500 hover:bg-gray-100 active:bg-gray-200': shopActive,
                                    'hover:text-gray-650 hover:bg-gray-100 text-gray-650 active:bg-gray-200': !shopActive
                                }
                            ])
                        )}>
                            <div className="w-full flex flex-row justify-between pr-2">
                                <div className="flex gap-3 items-center">
                                    {!catalogActive ? <div className="flex w-4 h-4">
                                        {EPaths.SHOP.includes(location.pathname) ? <ShoppingBagActiveIcon/> : <ShoppingBagIcon/>}
                                    </div>
                                        : <div className="flex w-4 h-4">
                                            {EPaths.CATALOG.includes(location.pathname) && <ShoppingBagActiveIcon className="text-gray-700"/>}
                                        </div>}
                                    {t('Navigation.Shop')}
                                </div>
                                {!shopTabsActive && <Badge className="lg:text-1 lg:text-gray-650 lg:rounded-md lg:py-0.5 lg:px-1">
                                    {value}
                                </Badge>}
                            </div>
                        </div>
                    </NavLink>
                    {shopTabsActive
                    && <><Cart
                        onClick={(): void => {
                            toggleCart();
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Cart') });
                        }}/>
                    <NavLink to={EPaths.CATALOG}
                        key={t('Navigation.Catalog')}
                        onClick={(): void => {
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Catalog') });
                        }}
                        isActive={location.pathname.includes(EPaths.CATALOG)}>
                        <div className="pl-7">
                            {t('Navigation.Catalog')}</div>
                    </NavLink></>}

                    <NavLink
                        to={EPaths.ORDER_HISTORY}
                        key={t('Navigation.Orders')}
                        onClick={(): void => {
                            sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Shop') });
                        }}
                        isActive={EPaths.ORDER_HISTORY.includes(location.pathname)}>
                        <div className="flex gap-3 items-center">
                            <div className="flex w-4 h-4">
                                {EPaths.ORDER_HISTORY.includes(location.pathname) ? <TicketActiveIcon /> : <TicketIcon />}
                            </div>
                            {t('Navigation.Orders')}
                        </div>
                    </NavLink>
                </div>
            </div>
            <div className="flex flex-col mb-5 justify-end">
                <NavLink
                    to={EPaths.DIRECT_CHAT}
                    key={t('Navigation.Messages')}
                    onClick={(): void => {
                        sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Messages') });
                    }}
                    isActive={EPaths.DIRECT_CHAT.includes(location.pathname)}>
                    <div className="flex gap-3 items-center">
                        <div className="w-4 h-4 flex">
                            {EPaths.DIRECT_CHAT.includes(location.pathname) ? getMessagesIcon(true) : getMessagesIcon()}
                        </div>
                        {t('Navigation.Messages')}
                    </div>
                </NavLink>
                <NavLink
                    to={HELP_DESK}
                    key={t('Navigation.Help')}
                    className="text-gray-650"
                    isActive={false}
                    target="_blank"
                    onClick={(): void => {
                        sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Help') });
                    }}
                    rel="noreferrer noopener">
                    <div className="flex gap-3 items-center text-gray-650">
                        <div className="w-4 h-4 flex"><HelpIcon className="w-4 h-4 text-gray-650"/></div>
                        {t('Navigation.Help')}
                    </div>
                </NavLink>
                {settingsEnabled && <NavLink
                    to={EPaths.SETTINGS}
                    key={t('Navigation.Settings')}
                    onClick={(): void => {
                        sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Settings') });
                    }}
                    isActive={EPaths.SETTINGS.includes(location.pathname)}>
                    <div className="flex gap-3 items-center">
                        {!integrationsActive ? <div className="flex w-4 h-4">
                            {EPaths.SETTINGS.includes(location.pathname) ? <SettingActiveIcon /> : <SettingIcon />}
                        </div> : <div className="flex w-4 h-4">
                            {ESettingsPaths.INTEGRATIONS.includes(location.pathname) && <SettingActiveIcon className="text-gray-700"/>}
                        </div>}
                        {t('Navigation.Settings')}
                    </div>
                </NavLink>}
                {settingsEnabled && accountingIntegrationsEnabled && settingsTabsActive && <NavLink
                    to={ESettingsPaths.INTEGRATIONS}
                    key={t('Navigation.Integrations')}
                    onClick={(): void => {
                        sendTrackingEvent({ ...TrackingEvents.integrationsClicked, label: t('Navigation.Integrations') });
                    }}
                    isActive={location.pathname.includes(ESettingsPaths.INTEGRATIONS)}>
                    <div className="pl-7">
                        {t('Navigation.Integrations')}</div>
                </NavLink>}
                <NavLink
                    to={ESettingsPaths.ACCOUNT}
                    key={t('Navigation.Account')}
                    onClick={(): void => {
                        sendTrackingEvent({ ...TrackingEvents.navigationMenuClicked, label: t('Navigation.Account') });
                    }}
                    isActive={ESettingsPaths.ACCOUNT.includes(location.pathname)}>
                    <div className="flex gap-3 items-center">
                        <div className="flex w-4 h-4">
                            {ESettingsPaths.ACCOUNT.includes(location.pathname) ? <AccountActiveIcon /> : <AccountIcon />}
                        </div>
                        {t('Navigation.Account')}
                    </div>
                </NavLink>
            </div>
        </nav>
    );
};

export default SideNavigation;

import create from 'zustand';
import { TBillsFilters } from '@v2/Pages/Invoices/BillsQueries.hook';
import { InvoiceQueryParams, InvoiceSearchParamsType, InvoicesSearchParams } from '@v2/network/FalsePromiseApi';
import { isEqual } from 'lodash';
import { removeEmptyValuesFromParamsObject } from '@v2/utils/NetworkUtils';

/**
 * State used by the scan page
 *
 * @param stripeStatusMap - A mapping of invoice numbers to stripe statuses. These must be cached locally as they are fetched from the Stripe API
 */

export interface UploadPDFModal {
    pdfData: string,
    invoiceNumber: string,
    supplierName: string,
    isPdfModalOpen: boolean,
}
export const UPLOAD_PDF_MODAL_INITIAL_STATE:UploadPDFModal = {
    invoiceNumber: '',
    isPdfModalOpen: false,
    supplierName: '',
    pdfData: ''

};

type UploadModals = {
    pdfModal: UploadPDFModal,
};

type UploadsPagination = {
    page: number,
    mapPageToNextCursor: Record<number, string>,
};

 type UploadsStore = {
     queryParams: InvoiceQueryParams,
     setQueryParams: (params: InvoiceQueryParams) => void,
     updateQueryParams: (params: Partial<InvoiceQueryParams>) => void,
     resetQueryParams: () => void,
     resetQueryParamByKey: (key: keyof TBillsFilters) => void,
     searchParams: Partial<InvoicesSearchParams>,
     setSearchParams: (params: InvoicesSearchParams) => void,
     resetSearchParamsByKey: (key: InvoiceSearchParamsType) => void,
     updateSearchParams: (params: Partial<InvoicesSearchParams>) => void,
     resetSearchParams: () => void,
     modals: UploadModals,
     updateModals: (params: Partial<UploadModals>) => void,
     hasSearchParamsSelected: () => boolean,
     pagination: UploadsPagination,
     setPagination: (params: UploadsPagination) => void,
     resetPagination: () => void,
     updatePagination: (params: Partial<UploadsPagination>) => void,

 };
export const UPLOADS_INITIAL_PAGE = 0;
export const MAP_PAGE_TO_NEXT_CURSOR = {};
export const UPLOADS_DEFAULT_PAGINATION: UploadsPagination = {
    page: UPLOADS_INITIAL_PAGE,
    mapPageToNextCursor: MAP_PAGE_TO_NEXT_CURSOR,
};

export const INVOICE_LIMIT = 20;

export const INVOICE_QUERY_PARAMS_INITIAL_STATE: InvoiceQueryParams = {
    limit: INVOICE_LIMIT,
    direction: 'desc',
    sort: 'createdAt',
    cursor: undefined,
};

export const INVOICE_SEARCH_PARAMS_INITIAL_STATE: Partial<InvoicesSearchParams> = {
    ocrState: ['parsed', 'in_progress', 'reviewed', 'verified']
};

const UPLOAD_MODALS_INITIAL_STATE: UploadModals = {
    pdfModal: UPLOAD_PDF_MODAL_INITIAL_STATE,
};

export const useUploadsStore = create<UploadsStore>((set, get) => ({
    queryParams: INVOICE_QUERY_PARAMS_INITIAL_STATE,
    setQueryParams: (params): void => set(() => ({ queryParams: params })),
    updateQueryParams: (params): void => set((state) => ({ queryParams: { ...state.queryParams, ...params } })),
    resetQueryParamByKey: (key: keyof TBillsFilters): void => set((state) => ({ queryParams: { ...state.queryParams, [key]: INVOICE_QUERY_PARAMS_INITIAL_STATE[key] } })),
    resetQueryParams: (): void => set(() => ({ queryParams: INVOICE_QUERY_PARAMS_INITIAL_STATE })),
    searchParams: INVOICE_SEARCH_PARAMS_INITIAL_STATE,
    setSearchParams: (params): void => set(() => ({ searchParams: params })),
    updateSearchParams: (params): void => set((state) => ({ searchParams: { ...state.searchParams, ...params } })),
    resetSearchParamsByKey: (key: InvoiceSearchParamsType): void => set((state) => ({ searchParams: { ...state.searchParams, [key]: INVOICE_SEARCH_PARAMS_INITIAL_STATE[key] } })),
    resetSearchParams: (): void => set(() => ({ searchParams: INVOICE_SEARCH_PARAMS_INITIAL_STATE })),
    modals: UPLOAD_MODALS_INITIAL_STATE,
    updateModals: (params): void => set((state) => ({ modals: { ...state.modals, ...params } })),
    hasSearchParamsSelected: (): boolean => {
        const { searchParams } = get();
        // removing ocr state by default since this will be always selected
        const { ocrState, ...restSearchParams } = searchParams;
        const { ocrState: initialOcrState, ...restInitialSearchParams } = INVOICE_SEARCH_PARAMS_INITIAL_STATE;
        return !isEqual(removeEmptyValuesFromParamsObject(restSearchParams), restInitialSearchParams);
    },
    pagination: UPLOADS_DEFAULT_PAGINATION,
    setPagination: (params): void => set(() => ({ pagination: params })),
    updatePagination: (params): void => set((state) => ({ pagination: { ...state.pagination, ...params } })),
    resetPagination: (): void => set(() => ({ pagination: UPLOADS_DEFAULT_PAGINATION })),

}),);

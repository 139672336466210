import ShoppingCartIcon from '@icons/shopping-cart-icon.svg';
import { Button, twMergeCustom } from '@notch-ordering/ui-components';
import { MAX_QUANTITY } from '@v2/components/Shared/CartButton/CartButtonConstants';
import useTotalOfProductsInCart from '@v2/hooks/useTotalOfProductsInCart.hook';
import cx from 'classnames';
import React from 'react';

export interface Props {
    className?: string,
    showCounter?: boolean,
    isClear?: boolean,
    onClick?: () => void,
}

export const CartButton: React.FC<Props> = ({
    className,
    showCounter = true,
    isClear,
    onClick,
}) => {
    const productsInCart = useTotalOfProductsInCart();
    const hasProductsInCart = productsInCart > 0;
    const value = productsInCart > MAX_QUANTITY ? `${MAX_QUANTITY}+` : productsInCart;
    const clsClear = isClear
        ? 'shadow-transparent border-none bg-transparent'
        : '';
    const buttonVariant = hasProductsInCart ? 'SECONDARY' : 'TERTIARY_FILLED';
    return (
        <Button
            variant={buttonVariant}
            size="ICON_MEDIUM"
            className={twMergeCustom(clsClear, 'w-auto border-none focus:ring-0', className)}
            onClick={onClick}>
            <div className={'flex gap-2 justify-center items-center'}>
                <div className={cx('w-4 h-4')}>
                    <ShoppingCartIcon />
                </div>
                {showCounter && value}
            </div>
        </Button>
    );
};

export default CartButton;

import React, { useState } from 'react';
import { EProductAvailability, InvalidImportProductError, TProductImport } from '@v2/stores/BulkUploadStore';
import { Badge, Button, Separator, Tooltip, Typography } from '@notch-ordering/ui-components';
import { BADGE_VARIANTS } from '@notch-ordering/ui-components/dist/components/Badge/BadgeConstants';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import ReactPaginate from 'react-paginate';
import BackIcon from '@icons/back-icon.svg';
import InfoIcon from '@icons/info-icon.svg';
import Utils from '@/utils';

export interface SupplierCatalogImportProductTableProps {
    products?: Partial<TProductImport>[],
}

type ProductErrorIconProps = {
    productErrors: InvalidImportProductError[],
    fields: string[],
};
const ProductErrorIcon = ({ productErrors = [], fields = [] }: ProductErrorIconProps):JSX.Element => {
    const [showTooltip, setShowTooltip] = useState(false);
    if (productErrors.length === 0 && fields.length === 0) {
        return null;
    }
    const error = productErrors.filter((e) => fields.includes(e.field));
    if (error.length === 0) {
        return null;
    }
    // group error messages by field for better display
    const groupedErrors = error.reduce((acc, e) => {
        if (!acc[e.field]) {
            acc[e.field] = [];
        }
        acc[e.field].push(<Typography as="div">{e.message}</Typography>);
        return acc;
    }, {} as Record<string, React.ReactNode[]>);
    return <Tooltip
        show={showTooltip}
        showArrow={true}
        tooltipClassName="w-64"
        placement="top"
        trigger={<div className="mt-1" onMouseEnter={():void => setShowTooltip(true)} onMouseLeave={():void => setShowTooltip(false)}><InfoIcon className="w-4 h-4 text-red-300"/></div>}>
        {Object.keys(groupedErrors).map((field, idx) => <Typography key={`${field}-${idx}`} as="div" className="inline-block">
            <Typography as="div" weight="font-semibold">{field}:</Typography><Typography as="div">{groupedErrors[field]}</Typography>
        </Typography>)}
    </Tooltip>;
};

export const CatalogImportProductTable : React.FC<SupplierCatalogImportProductTableProps> = ({ products }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'SupplierCatalogImport' });
    const [currentPage, setCurrentPage] = useState(0);
    const PRODUCTS_PER_PAGE = 10;
    const offset = currentPage * PRODUCTS_PER_PAGE;
    const currentPageData = products
        .slice(offset, offset + PRODUCTS_PER_PAGE);
    const pageCount = Math.ceil(products.length / PRODUCTS_PER_PAGE);
    const handlePageClick = ({ selected: selectedPage }):void => {
        setCurrentPage(selectedPage);
    };

    const getAvailabilityBadge = (availability: EProductAvailability):React.ReactNode => {
        let badgeVariant: keyof typeof BADGE_VARIANTS;
        let badgeText: string;
        switch (availability) {
            case EProductAvailability.IN_STOCK:
                badgeVariant = 'GREEN';
                badgeText = t('inStock');
                break;
            case EProductAvailability.OUT_OF_STOCK:
                badgeVariant = 'RED';
                badgeText = t('outOfStock');
                break;
            case EProductAvailability.DISABLED:
                badgeVariant = 'ORANGE';
                badgeText = t('disabled');
                break;
            default:
                badgeVariant = 'TEAL';
                badgeText = availability;
        }

        return <Badge variant={badgeVariant} size={'MEDIUM'}><div className="flex gap-2 items-center"><span>{badgeText}</span></div></Badge>;
    };
    return <>
        <div className="grid grid-cols-6 gap-4">
            <Typography className="m-0 py-5 text-gray-600" weight="font-medium">{t('product')}</Typography>
            <Typography className="m-0 py-5 text-gray-600" weight="font-medium">{t('size')}</Typography>
            <Typography className="m-0 py-5 text-gray-600" weight="font-medium">{t('sku')}</Typography>
            <Typography className="m-0 py-5 text-gray-600" weight="font-medium">{t('price')}</Typography>
            <Typography className="m-0 py-5 text-gray-600" weight="font-medium">{t('category')}</Typography>
            <Typography className="m-0 py-5 text-gray-600" weight="font-medium">{t('status')}</Typography>
        </div>
        <Separator/>
        {currentPageData.map((product, idx) => {
            const productHasErrors = product.errors?.length > 0;
            return <React.Fragment key={`${product.packageSku}-${idx}`}>
                <div className="grid grid-cols-6 gap-4">
                    <div className="m-0 py-5 flex flex-col justify-center ">
                        <Typography className="text-gray-700 mb-1">{product.productName} </Typography>
                        <Typography className="m-0 text-gray-600">
                            {product.productDescription}
                        </Typography>
                    </div>
                    <Typography as="div" className="m-0 py-5 text-gray-700 flex items-center gap-2">
                        <div>{product.packageQuantity ?? ''}&nbsp;{product.packageUnit ?? ''} {product.packageDescription ? `- ${product.packageDescription}` : ''}</div>
                        {productHasErrors && <ProductErrorIcon productErrors={product.errors}
                            fields={['packageQuantity', 'packageUnit', 'packageDescription']}/>}
                    </Typography>
                    <Typography as="div" className="m-0 py-5 text-gray-700 flex items-center gap-2">
                        <div>{product.packageSku}</div>
                        {productHasErrors && <ProductErrorIcon productErrors={product.errors} fields={['packageSku']}/>}
                    </Typography>
                    <Typography as="div" className="m-0 py-5 text-gray-700 flex items-center gap-2">
                        <div>{Utils.formatAsCurrency(product.defaultPrice)}</div>
                        {productHasErrors && <ProductErrorIcon productErrors={product.errors} fields={['defaultPrice']}/>}
                    </Typography>
                    <Typography as="div" className="m-0 py-5 text-gray-700 flex items-center gap-2">
                        <div>{product.productCategory}</div>
                        {productHasErrors && <ProductErrorIcon productErrors={product.errors} fields={['productCategory']}/>}
                    </Typography>
                    <Typography className="m-0 py-5 text-gray-700 flex items-center gap-2" as="div">
                        {getAvailabilityBadge(product.packageAvailability)}
                        {productHasErrors && <ProductErrorIcon productErrors={product.errors} fields={['packageAvailability']}/>}
                    </Typography>
                </div>
                <Separator/>

            </React.Fragment>;
        })}
        {pageCount > 1 && <div className={'mt-10'}><ReactPaginate
            previousLabel={ <Button
                variant="TERTIARY_FILLED"
                size="ICON_MEDIUM"
                className={'flex items-center justify-center'}
                stopPropagation={false}>
                <BackIcon className="w-4 h-4 text-gray-600"/>
            </Button>}
            nextLabel={<Button
                variant="TERTIARY_FILLED"
                size="ICON_MEDIUM"
                className={'flex items-center justify-center'}
                stopPropagation={false} >
                <BackIcon className="w-4 h-4 text-gray-600 rotate-180"/>
            </Button>}
            pageLinkClassName={'text-gray-700 hover:text-gray-700 cursor-default'}
            pageLabelBuilder={(page):React.ReactNode | null => (page === pageCount - 1 ? <div onClick={(e):void => e.stopPropagation()}>
                <Typography as="div" className="m-0 p-0">
                    {t('showingOfProducts', { start: offset + 1, end: offset + currentPageData.length, total: products.length })}
                </Typography>
            </div> : null)}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={'flex justify-center items-center gap-3 list-none '}
            disabledClassName={'opacity-50 pointer-events-none cursor-default'}/></div>}

    </>;
};

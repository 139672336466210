import { Typography } from '@notch-ordering/ui-components';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import MarketTag from '../../../../general/MarketTag.jsx';

export default class OrderCheckoutHeaderDelivery extends Component {
  toggleCancelOrderModal = event => {
    const { toggleCancelOrderModal, order } = this.props;

    event.stopPropagation();
    toggleCancelOrderModal(order.urlsafe);
  };

  render() {
    const {
      vendorName,
      order_urlsafe,
      isPendingOrders,
      toggleCollapse,
      order,
      vendor,
    } = this.props;

    return (
      <div className="order-checkout-header-delivery">
        <div className="order-checkout-header delivery-date">
          <div className="vendor-row">
            <Typography className="vendor-name"> {vendorName} </Typography>
            {!vendor.customer.isBYOS && !vendor.isPayFacLite && <MarketTag />}
          </div>
          <div className="order-actions">
            {isPendingOrders && (
              <span>
                {!order.isEditingAddOnOrder && (
                  <button
                    onClick={this.toggleCancelOrderModal}
                    className="btn btn-ch-warning-link-on-white pull-right actions"
                  >
                    Cancel Order
                  </button>
                )}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

OrderCheckoutHeaderDelivery.propTypes = {
  vendorName: PropTypes.string.isRequired,
  order_urlsafe: PropTypes.string.isRequired,
  isPendingOrders: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,

  toggleCollapse: PropTypes.func.isRequired,
  toggleCancelOrderModal: PropTypes.func.isRequired,
};

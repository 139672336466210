import React, { useEffect, useState } from 'react';
import { Separator, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import { AccountingConfiguration, EApPlatform, EErpPlatform, getAccountingConfiguration } from '@notch-ordering/shared-logic';
import cx from 'classnames';
import { InvoiceData } from '@v2/network/GreevilsGreedApi';
import { integrationIcons, integrationNameKeys } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { OCConfiguration, getOCConfiguration } from '@v2/network/IntegrationAPI';
import { IntegrationDisplayData, billDetailsIconHeight, billDetailsIconWidth } from '@v2/Pages/Integrations/IntegrationsUtils';
import { useTranslation } from 'react-i18next';
import { isEditedExternally, isPaidExternally } from '@v2/utils/AccountingUtils';
import { BillLabelBadge } from './BillLabelBadge';

type SyncStatusBillDetailsProps = {
    invoice: InvoiceData,
};

/**
 * This is the sync status and label section of the bill details page
 */
export const SyncStatusBillDetails: React.FC<SyncStatusBillDetailsProps> = ({ invoice }) => {
    const { buyer } = useBuyerHook();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const [activeERPIntegration, setActiveERPIntegration] = useState<IntegrationDisplayData>(null);
    const [activeAccountingIntegration, setActiveAccountingIntegration] = useState<IntegrationDisplayData>(null);
    const hasLabels = isEditedExternally(invoice) || isPaidExternally(invoice);

    async function checkAccountingIntegration(): Promise<void> {
        const config = await getAccountingConfiguration(buyer.urlsafe).catch(() => {});
        const accConfig = config as AccountingConfiguration;
        if (accConfig) {
            setActiveAccountingIntegration({
                name: t(integrationNameKeys(accConfig?.data?.platform)),
                activeIcon: integrationIcons(accConfig?.data?.platform, billDetailsIconHeight, billDetailsIconWidth),
                platform: accConfig?.data?.platform
            });
        }
    }

    async function checkERPIntegration(): Promise<void> {
        // For now, since the only ERP integration we have is OC, just look for the OC config
        const config = await getOCConfiguration(buyer.urlsafe).catch(() => {});
        const ocConfig = config as OCConfiguration;
        if (ocConfig) {
            setActiveERPIntegration({
                name: t(integrationNameKeys(EErpPlatform.OPTIMUMCONTROL)),
                activeIcon: integrationIcons(EErpPlatform.OPTIMUMCONTROL, billDetailsIconHeight, billDetailsIconWidth),
                platform: EErpPlatform.OPTIMUMCONTROL
            });
        }
    }

    const bigChipSynced = invoice?.isApprovedForBigChip;

    useEffect(() => {
        checkERPIntegration();
        checkAccountingIntegration();
    }, [buyer.urlsafe]);

    return (
        <div className="flex flex-col">
            {/* Sync Status Section */}
            {(activeAccountingIntegration?.platform || activeERPIntegration?.platform) && <>
                <Separator className="mb-8"/>
                <Typography className="pb-6 m-0" variant="LG-2" weight="font-semibold">{t('syncStatusFull')}</Typography>
                {activeAccountingIntegration?.platform && <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                        <div className="flex flex-row gap-x-2">
                            {activeAccountingIntegration?.activeIcon}
                            <Typography>{activeAccountingIntegration?.name}</Typography>
                        </div>
                        <Typography className="text-gray-600 p-0 m-0 pt-1.5">
                            {(invoice?.lastSyncedToAccounting || bigChipSynced) ? t('billSynced') : t('billNotSynced')}
                        </Typography>
                    </div>
                    <div className="flex flex-row gap-x-0.5">
                        <Typography className={cx({
                            'text-gray-500': !invoice?.lastSyncedToAccounting || !bigChipSynced,
                            'text-green-300': invoice?.lastSyncedToAccounting || bigChipSynced,
                        })}> {'•'}</Typography>
                        <Typography>{(invoice?.lastSyncedToAccounting || bigChipSynced) ? t('synced') : t('notSynced')}</Typography>
                    </div>
                </div>}
                {(activeAccountingIntegration?.platform && activeERPIntegration?.platform) && <Separator className="my-6"/>}
                {activeERPIntegration?.platform && <div className="flex flex-row justify-between">
                    <div className="flex flex-col">
                        <div className="flex flex-row gap-x-2">
                            {activeERPIntegration?.activeIcon}
                            <Typography>{activeERPIntegration?.name}</Typography>
                        </div>
                        <Typography className="text-gray-600 p-0 m-0 pt-1.5">
                            {invoice?.lastSyncedToIMS ? t('billSynced') : t('billNotSynced')}
                        </Typography>
                    </div>
                    <div className="flex flex-row gap-x-0.5">
                        <Typography className={cx({
                            'text-gray-500': !invoice?.lastSyncedToIMS,
                            'text-green-300': invoice?.lastSyncedToIMS,
                        })}> {'•'}</Typography>
                        <Typography >{invoice?.lastSyncedToIMS ? t('synced') : t('notSynced')}</Typography>
                    </div>
                </div>}
            </>}
            {/* Labels Section */}
            {activeAccountingIntegration?.platform && hasLabels && <>
                <Separator className="my-8" />
                <Typography className="pb-6 m-0" variant="LG-2" weight="font-semibold">{tCommon('Labels.labels')}</Typography>
                <div className="w-40">
                    <BillLabelBadge
                        invoice={invoice}
                        platform={activeAccountingIntegration?.platform as EApPlatform} />
                </div>
            </>}
        </div>
    );
};

import PropTypes from 'prop-types';
import React from 'react';

const LoadingData = ({ display }) => {
  const style = { display: display ? 'block' : 'none' };

  return (
    <div id="loading-data" className="wrapper " style={style}>
      <div
        className="row text-center"
        style={{ paddingTop: '50px', paddingBottom: '50px' }}
      >
        <div className="col-xs-2"></div>
        <div className="col-xs-8">
          <div className="spiner-example">
              <i
                style={{ fontSize: '26px' }}
                className="spin-spinner fa fa-circle-o-notch"
              />
          </div>
        </div>
        <div className="col-xs-2"></div>
      </div>
    </div>
  );
};

LoadingData.propTypes = {
  display: PropTypes.bool.isRequired,
};

export default LoadingData;

import React, { useState } from 'react';
import { Button, Loading, Popover, Separator, Tooltip, Typography } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import BackIcon from '@icons/back-icon.svg';
import PaperClipIcon from '@icons/paperclip-icon.svg';
import PlusIcon from '@icons/add-icon.svg';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import Route, { browserHistory } from 'react-router';
import useRolesHook from '@v2/hooks/useRolesHook';
import { ERoleName } from '@v2/types/OrgData';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AddBillModal } from '@v2/components/Bills/AddBillModal/AddBillModal';
import { Order, OrderItem, getOrder } from '@v2/network/LegacyAPI';
import { useQuery } from '@tanstack/react-query';
import { listInvoices } from '@v2/network/GreevilsGreedApi';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import Utils from '@/utils';
import ImageUpload from '@/domains/OrderDetails/components/ImageUpload';
import { reorder } from '../../../actions/orderActions';
import { createOrSelectGeneralChatChannelInStore } from '@/domains/Chat/actions/chatSelectedActions';
import { changeRoute } from '@/actions/routerActions';
import { GenerateBillModal } from './GenerateBillModal';
import { BillGeneratedModal } from './BillGeneratedModal';
import { OrderDetailsMobile } from './OrderDetailsMobile';

type OrderDetailProps = {
    router: Route,
    params: {
        order_urlsafe: string,
    },
};

export interface IOrderInfoColumn {
    title: string,
    orderInfo: React.ReactNode,
}

export const OrderDetails: React.FC<OrderDetailProps> = ({ params, router }) => {
    const isMobileWidth = useIsMobileWidth();
    const { hasRole } = useRolesHook();
    const [showAddBillModal, setShowAddBillModal] = useState<boolean>(false);
    const [showGenerateBillModal, setShowGenerateBillModal] = useState<boolean>(false);
    const [showBillGeneratedModal, setShowBillGeneratedModal] = useState<boolean>(false);
    const [attachToolTip, setAttachToolTip] = useState<boolean>(false);
    const [invoiceID, setInvoiceID] = useState<string>('');
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const dispatch = useDispatch();
    const { buyer } = useBuyerHook();

    const { data: orderResponse, isLoading, refetch: refetchOrder } = useQuery(
        ['ORDER_DATA', params.order_urlsafe],
        () => getOrder(params.order_urlsafe),
        {
            enabled: !!params.order_urlsafe,
        }
    );

    const orderData: Order = orderResponse?.data;

    const { data: orderInvoices, refetch: refetchInvoice } = useQuery(
        ['ORDER_INVOICES', orderData?.urlsafe],
        () => listInvoices(20, buyer.urlsafe, { search: [orderData?.id.toString(10)] }),
        {
            enabled: !!orderData,
        }
    );

    const invoice = orderInvoices?.results[0];
    const hasImages = orderData?.images?.length > 0;

    if (isLoading) {
        return (<div className="flex mt-40 justify-center items-center"><Loading isDark/></div>);
    }

    const isPackageCheck = (orderItem): boolean => (
        orderItem?.unitQuantity == null
          || orderItem?.salesUnit == null
          || orderItem?.salesUnit === ''
    );

    const onClickChat = (): void => {
        dispatch(
            createOrSelectGeneralChatChannelInStore({
                customerUrlsafe: orderData.customerUrlsafe,
                vendorUrlsafe: orderData.vendorUrlsafe,
                vendorName: orderData.vendorName,
            })
        );
        dispatch(changeRoute('/inbox/'));
    };

    const enableEditButton = !Utils.isAfterDeliveryDayCutOffTime({ name: orderData.vendorName }, orderData) // For some reason all it wants is a non-empty object for vendor
                    && !orderData.isAddOnOrder
                    && !(orderData.isReplacementOrder || orderData.isShortedOrder)
                    && !orderData.deliveryConfirmedBySupplierAt
                    && !orderData.isVendorEmailSent
                    && !['paid', 'invoiced', 'delivered'].includes(orderData?.orderBuyerStatus?.toLowerCase());

    const orderInfoColumn:IOrderInfoColumn[] = [
        {
            title: tCommon('Labels.deliveryDate'),
            orderInfo: Utils.formatDate(orderData?.deliveryDay, 'MMM DD, YYYY')
        },
        {
            title: tCommon('Labels.supplier'),
            orderInfo: (orderData.vendorName),
        },
        {
            title: tCommon('Labels.orderDate'),
            orderInfo: Utils.formatDate(orderData?.placedAt, 'MMM DD, YYYY'),
        },
        {
            title: tCommon('Labels.deliveryAddress'),
            orderInfo: (orderData?.shippingAddress?.formatted),
        },
        {
            title: tCommon('Labels.deliveryInstructions'),
            orderInfo: orderData.shippingAddress?.driveInstructions ? orderData.shippingAddress?.driveInstructions : t('noDeliveryInstructions'),
        },
    ];

    return (
        <> {!isMobileWidth ? <div className="w-full h-full flex flex-col">
            <div className="flex flex-row justify-between">
                <Button variant="ICON"
                    size="ICON_SMALL"
                    className="h-6 p-0 ml-10 mt-[38px]"
                    onClick={(): void => browserHistory.goBack() }>
                    <BackIcon className="w-4 h-4"/>
                </Button>
                <div className="flex pr-10 py-8 gap-x-2">
                    <Button
                        variant="TERTIARY_FILLED"
                        size="SMALL"
                        hidden={!enableEditButton}
                        onClick={(): void => {
                            if (hasRole([ERoleName.OWNER, ERoleName.MANAGER])) {
                                browserHistory.push(`/checkout/pending/${orderData.urlsafe}/`);
                            }
                        }}>{tCommon('Buttons.editOrder')}</Button>
                    <Button
                        variant="TERTIARY_FILLED"
                        size="SMALL"
                        onClick={(): void => {
                            dispatch(reorder(orderData));
                        }}>{t('reorder')}</Button>
                    <Popover
                        className="w-48"
                        buttonClassName="ml-auto"
                        button={ <Button variant="TERTIARY_FILLED"
                            size="SMALL"
                            className="px-4"
                            stopPropagation={false}>
                            <div className="flex gap-3 items-center">
                                <Typography className="text-gray-700 mb-0" weight="font-medium">{tCommon('Buttons.more')}</Typography>
                                <ChevronDownIcon className="w-4 h-4 text-gray-600"/>
                            </div>
                        </Button>}
                        items={[
                            {
                                label: t('attachBill'),
                                onClick: (): void => {
                                    setShowAddBillModal(true);
                                }
                            },
                            {
                                label: t('generateBill'),
                                separatorEnabled: true,
                                hidden: !!invoice || hasImages,
                                onClick: (): void => {
                                    setShowGenerateBillModal(true);
                                }
                            },
                            {
                                label: t('messageSupplier'),
                                onClick: (): void => {
                                    onClickChat();
                                },
                            },
                        ]}/>
                </div>
            </div>
            <Typography className="pl-10 m-0 mb-2 text-gray-600">
                {`Order #${orderData.id}`}
            </Typography>
            <Typography className="pl-10 pb-7 m-0" variant="4XL">
                {`${Utils.formatAsCurrency(orderData.total)}`}
            </Typography>
            <Separator />
            <div className="flex flex-row pl-10">
                <div className="flex flex-col w-3/4 pb-16">
                    <div className={'flex gap-4 mr-8'}>
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        className="min-w-[72px] py-5 pr-2 text-left text-2 font-medium text-gray-600">
                                        {tCommon('Labels.quantity')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="min-w-[120px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                        {tCommon('Labels.productName')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="min-w-[90px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                        {tCommon('Labels.description')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="min-w-[90px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                        {tCommon('Labels.unitPrice')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="min-w-[60px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                        {tCommon('Labels.sku')}
                                    </th>
                                    <th
                                        scope="col"
                                        className="min-w-[90px] py-5 pl-2 text-right text-2 font-medium text-gray-600">
                                        {tCommon('Labels.estTotal')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData?.items?.map((product: OrderItem, i: number) => {
                                    const isPackage = isPackageCheck(product);
                                    return (
                                        <tr key={i}
                                            className="border-b border-gray-200">
                                            <td className="py-5 pr-2 text-left text-2 font-regular">{product.packaging_quantity}</td>
                                            <td className="py-5 px-2 text-left text-2 font-regular">{product.name}</td>
                                            <td className="py-5 px-2 text-left text-2 font-regular">{`${product.unitName} ${product.unitDescription ? `- ${product.unitDescription}` : ''}`}</td>
                                            <td className="py-5 px-2 text-left text-2 font-regular">{isPackage ? Utils.formatAsCurrency(product.packaging_price) : Utils.formatAsCurrency(product.unit_price)}</td>
                                            <td className="py-5 px-2 text-left text-2 font-regular">{product.externalId}</td>
                                            <td className="py-5 pl-2 text-right text-2 font-regular">{Utils.formatAsCurrency(product.total)}</td>
                                        </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-row justify-end items-end mt-5 mr-8">
                        <div className="flex flex-col justify-end items-end mr-20">
                            <Typography className="mb-2 text-gray-600">{tCommon('Labels.subTotal')}</Typography>
                            <Typography className="mb-2 text-gray-600">{tCommon('Labels.tax')}</Typography>
                            <Typography className="mb-2 font-medium text-gray-700">{tCommon('Labels.estimatedTotal')}</Typography>
                        </div>
                        <div className="flex flex-col justify-end items-end">
                            <Typography className="mb-2" >{Utils.formatAsCurrency(orderData.subtotal)}</Typography>
                            <Typography className="mb-2">{Utils.formatAsCurrency(orderData.tax)}</Typography>
                            <Typography className="mb-2 font-semibold">{Utils.formatAsCurrency(orderData.total)}</Typography>
                        </div>
                    </div>
                </div>
                <Separator className="h-full w-[1px]" />
                <div className="flex flex-col mt-8 ml-8 mr-10 pb-16">
                    <Typography className="mb-6" variant="LG-2" weight="font-medium">{tCommon('Labels.basicDetails')}</Typography>
                    {orderInfoColumn.map(({ title, orderInfo }, i) => (
                        <div key={i}>
                            <Typography weight="font-medium" className="mb-2 text-gray-600">{title}</Typography>
                            <Typography className="mb-8" >{orderInfo}</Typography>
                        </div>))}
                    <Separator className="mb-8"/>
                    <div className="flex flex-row mb-6 justify-between">
                        <Typography className="m-0 p-0" variant="LG-2" weight="font-medium">{tCommon('Labels.linkedBills')}</Typography>
                        {(invoice || hasImages) && <Tooltip
                            show={attachToolTip}
                            className="flex"
                            tooltipClassName={'py-1 px-2 rounded-lg'}
                            showArrow={false}
                            onShow ={(): void => { setAttachToolTip(true); }}
                            onHide ={(): void => { setAttachToolTip(false); }}
                            placement="top"
                            trigger={<Button variant="ICON"
                                size="NO_BACKGROUND"
                                className="p-0 m-0"
                                onClick={():void => {
                                    setShowAddBillModal(true);
                                }}>
                                <div className="rounded-md h-6 w-6 bg-transparent hover:bg-gray-250">
                                    <PlusIcon className="text-gray-600 hover:text-gray-700 mt-1 w-4 h-4" />
                                </div>
                            </Button>}>
                            {t('addBill')}
                        </Tooltip>}
                    </div>
                    <Typography weight="font-medium" className="mb-2 text-gray-600">{tCommon('Labels.billNumber')}</Typography>
                    {invoice ? <Button variant="LINK"
                        size="NO_BACKGROUND"
                        className="p-0 mb-8 flex justify-left"
                        onClick={async (): Promise<void> => {
                            if (invoice?.invoiceNumber) {
                                browserHistory.push(`/bill/${invoice?.invoiceNumber}`);
                            }
                        }}>
                        {invoice?.invoiceNumber}
                    </Button>
                        : <Typography className="mb-8 text-gray-600">{t('noBills')}</Typography>}
                    <Typography weight="font-medium" className="mb-0 text-gray-600">{tCommon('Labels.attachments')}</Typography>
                    <ImageUpload
                        attachments={orderData?.images}
                        generatedInvoice={invoice}/>
                    <Button variant="TERTIARY_FILLED"
                        className="w-fit py-1 mt-3"
                        hidden={hasImages || !!invoice}
                        size={'SMALL'}
                        onClick={():void => {
                            setShowAddBillModal(true);
                        }}>
                        <div className="flex gap-x-3 justify-center items-center">
                            <PaperClipIcon className="w-4 h-4" />
                            <Typography className="text-gray-700 mb-0">{tCommon('Buttons.upload')}</Typography>
                        </div>
                    </Button>
                </div>
            </div>
        </div> : <OrderDetailsMobile router={router}
            orderData={orderData}
            generatedInvoice={invoice}
            enableEditButton={enableEditButton}
            openAddBillsModal={(): void => {
                setShowAddBillModal(true);
            }}
            openGenerateBillsModal={(): void => {
                setShowGenerateBillModal(true);
            }}
            onClickChat={(): void => {
                onClickChat();
            }}/>}
        <GenerateBillModal isOpen={showGenerateBillModal}
            close={(): void => {
                setShowGenerateBillModal(false);
            }}
            onSuccess={(generatedInvoiceID): void => {
                setInvoiceID(generatedInvoiceID);
                setShowBillGeneratedModal(true);
                refetchInvoice();
            }}
            row={orderData}/>
        <BillGeneratedModal
            isOpen={showBillGeneratedModal}
            close={(): void => { setShowBillGeneratedModal(false); }}
            orderID={orderData?.id}
            invoiceID={invoiceID} />
        <AddBillModal
            closeModal={(): void => {
                setShowAddBillModal(false);
            }}
            isOpen={showAddBillModal}
            orderUrlsafeKey={orderData?.urlsafe}
            onBillAdded={(): void => {
                refetchOrder();
            }}/>
        </>
    );
};

import React, { useEffect } from 'react';
import { tNavigation, tNamespace } from '@v2/i18n';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { Typography } from '@notch-ordering/ui-components';
import { InjectedRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { EPaths } from '@v2/constants/EPaths';
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import { AddBillForm } from '../../components/Bills/AddBillForm/AddBillForm';

export interface UploadProps {router: InjectedRouter}

export const Upload : React.FC<UploadProps> = ({ router }) => {
    useTitle(tNavigation('Upload'));
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Upload' });
    const isMobileWidth = useIsMobileWidth();

    const onComplete = (): void => {
        router.goBack();
    };

    useEffect(() => {
        if (!isMobileWidth) {
            router.replace(EPaths.UPLOADS);
        }
    }, [isMobileWidth]);

    if (!isMobileWidth) {
        return null;
    }

    return <>
        <div className="h-full">
            <div className="flex flex-col lg:justify-center inset-0 pt-safe px-4 py-6">
                <Typography
                    as="h1"
                    variant="4XL"
                    weight="font-medium"
                    className="text-gray-700 text-6">
                    {t('uploadABillTitle')}
                </Typography>
                <Typography as={'div'}
                    variant={'BASE'}
                    className="text-left text-gray-600 pt-2">
                    {t('uploadABillDescription')}
                </Typography>

                <div className="w-full bg-white mb-[62px] pb-safe">
                    <AddBillForm onComplete={onComplete} />
                </div>
            </div>
        </div>
    </>;
};

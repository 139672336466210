import UploadIcon from '@icons/upload-icon.svg';
import { Loading, Button, Typography, DragAndDropUpload } from '@notch-ordering/ui-components';
import { ActionsPanel, SetupInfo } from '@v2/components/Setup/Setup';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { tCommon, tNamespace } from '@v2/i18n';
import { AttachmentData, deleteSupplierAttachment, getSupplierAttachments, uploadSupplierAttachments } from '@v2/network/CoreAPI';
import { useSetupStore } from '@v2/stores/SetupStore';
import cx from 'classnames';
import React, { DragEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Router from 'react-router';
import { UploadedItem } from './components/UploadedItem';
import { getExtensionFromFileName, getFileNameFromURL } from './supplierAttachmentUtils';

type Props = {
    router: Router,
};

const ONE_MB_BYTES = 1048576;
const MAX_FILE_SIZE_BYTES = ONE_MB_BYTES * 100;

const VALID_FILE_TYPES = [
    'xlsx',
    'xls',
    'csv',
    'png',
    'jpg',
    'jpeg',
    'pdf',
    'heic',
    'heif'
];

/* This is the page the user sees when they choose to upload files while initializing a supplier catalog */
export const AddItemsUpload: React.FC<Props> = ({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Catalog.AddItems.Upload' });
    const { supplier, setSetupActions, setFilesUploaded } = useSetupStore();
    const [uploadedFiles, setUploadedFiles] = useState<AttachmentData[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef(null);
    const [error, setError] = useState<string>();
    const { buyer } = useBuyerHook();
    const isMobileWidth = useIsMobileWidth();
    const buttonVariant = uploadedFiles.length > 0 ? 'TERTIARY_OUTLINED' : 'SECONDARY';
    const [isActionsExpanded, setIsActionsExpanded] = useState(false);

    const fileTypes = useMemo(() => `.${VALID_FILE_TYPES.reduce((prev, curr) => `${prev},.${curr}`)}`, []);

    useEffect(() => {
        setSetupActions({ didUploadFiles: uploadedFiles.length > 0 });
        setFilesUploaded(uploadedFiles.length);
    }, [uploadedFiles]);

    function getAttachmentList(): void {
        setIsLoading(true);
        getSupplierAttachments(supplier.urlsafe).then((attachments) => {
            setUploadedFiles(attachments);
            setIsLoading(false);
        }).catch(() => {
            setError(`${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getAttachmentList();
    }, []);

    function exit(): void {
        router.push(EPaths.HOME);
    }

    function back(): void {
        router.goBack();
    }

    function addFile(fileObj: File): void {
        const extension = getExtensionFromFileName(fileObj.name);

        if (!fileObj) {
            setError(`${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`);
        } else if (uploadedFiles.findIndex((file) => getFileNameFromURL(file.attachmentURL) === fileObj.name) >= 0) {
            setError(`${t('errorAlreadyExists', { fileName: fileObj.name })}`);
        } else if (!VALID_FILE_TYPES.includes(extension)) {
            setError(t('errorInvalidType', { fileName: fileObj.name }));
        } else if (fileObj.size > MAX_FILE_SIZE_BYTES) {
            const fileSize = `${(fileObj.size / ONE_MB_BYTES).toFixed(2)}MB`;
            setError(t('errorTooLarge', { fileName: fileObj.name, fileSize }));
        } else {
            setIsLoading(true);
            uploadSupplierAttachments(buyer.urlsafe, supplier.urlsafe, fileObj).then((attachment) => {
                const newFiles = [...uploadedFiles, attachment];
                setUploadedFiles(newFiles);
                setIsLoading(false);
            }).catch((e) => {
                setIsLoading(false);

                // eslint-disable-next-line no-nested-ternary
                setError(e ? (typeof e === 'string' ? e : `${e}`) : `${tCommon('somethingWentWrong')} ${tCommon('tryAgain')}`);
            });
        }
    }

    function removeFile(fileName: string, attachmentID: string): void {
        setIsLoading(true);
        setUploadedFiles(uploadedFiles.filter((currentFile) => currentFile.id !== attachmentID));
        deleteSupplierAttachment(supplier.urlsafe, fileName).then(() => {
            setIsLoading(false);
        }).catch(() => {
            getAttachmentList(); // refresh list of uploads
        });
    }

    function onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const fileObj = event.target?.files?.[0];
        addFile(fileObj);
    }

    function onClickUploadFile(): void {
        setError(null);
        inputRef.current.click();
    }

    function onDragFile(event: DragEvent<HTMLDivElement>): void {
        setError(null);
        event.preventDefault();
        event.stopPropagation();
    }

    function onDropFile(event: DragEvent<HTMLDivElement>): void {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files) {
            addFile(event.dataTransfer.files?.[0]);
        }
    }

    /* Page JSX */
    return (
        <>
            {/* Right side of page */}
            <SetupInfo heading={supplier.name}
                title={t('uploadFiles')}
                shortTitle={t('uploadFiles')}
                description={t('uploadAnyAccepted')}
                info={t('weRecommend')}
                close={exit}
                back={back}
                showShortTitle={isActionsExpanded}/>
            {/* Left side of page */}
            <ActionsPanel
                back={back}
                nextButtonDisabled={uploadedFiles.length === 0}
                isBusy={isLoading}
                next={(): void => { router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS}`); } }
                nextButtonLabel={tCommon('Buttons.done')}
                totalSteps={1}
                currentStep={0}
                onExpand={(isExpanded: boolean): void => {
                    setIsActionsExpanded(isExpanded);
                }}>
                <div className={cx('py-4 w-full', { hidden: isMobileWidth })}>
                    <DragAndDropUpload
                        onClick={onClickUploadFile}
                        onDragOver={onDragFile}
                        onDrop={onDropFile}
                        fileTypes={fileTypes}
                        onInputChange={onInputChange}
                        inputRef={inputRef}
                        uploadDesc={t('clickToUpload')}
                        typeDesc={t('acceptedFileTypes')}
                        isInvalid={!!error}
                        invalidMessage={error}
                        disabled={isLoading} />
                </div>
                {/* List of uploads */}
                {uploadedFiles.length > 0
                && <div className="pr-0 lg:pr-4 w-full h-full mb-7 lg:mb-0 lg:h-auto lg:overflow-y-visible lg:pb-12">
                    {uploadedFiles.map((file, index, array) => (
                        <UploadedItem key={file.id}
                            attachmentURL={file.attachmentURL}
                            id={file.id}
                            removeFile={removeFile}
                            showDivider={index < array.length - 1} />
                    ))}
                </div>}
                {isLoading && <Loading className="w-full text-center py-3" isDark/>}
                { isMobileWidth
                    && <>
                        <Button
                            variant={buttonVariant}
                            size="MEDIUM"
                            className="text-white w-full py-3"
                            onClick={onClickUploadFile}
                            disabled={isLoading}>
                            <div className={cx(
                                'flex justify-center items-center gap-2 text-3',
                                {
                                    'text-white': uploadedFiles.length === 0,
                                    'text-gray-700': uploadedFiles.length > 0
                                }
                            )}>
                                <UploadIcon title="upload icon" className="w-6 h-6" />
                                {tCommon('Buttons.upload')}
                            </div>
                        </Button>
                        <Typography variant="BASE" className="text-gray-600 text-center mt-3 mb-6">{t('acceptedFileTypes')}</Typography>
                        {!!error && <Typography as="span" className="pt-2 text-red-300">
                            {error}
                        </Typography>}
                    </>
                }
            </ActionsPanel>
        </>
    );
};

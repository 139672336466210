import React from 'react';

import { tCommon, tNamespace } from '@v2/i18n';
import { Typography, Container, Button } from '@notch-ordering/ui-components';
import CloseIcon from '@icons/close-icon.svg';
import { useTranslation } from 'react-i18next';
import { useBulkUploadStore } from '@v2/stores/BulkUploadStore';
import { EPaths } from '@v2/constants/EPaths';
import { QUERY_PARAM_UPLOAD } from '@v2/Pages/Setup/Catalog/Import/CatalogImportConstants';
import Router, { withRouter } from 'react-router';

export type CatalogImportHeaderProps = {
    router?: Router,
};
export const CatalogImportHeader : React.FC<CatalogImportHeaderProps> = withRouter(({ router }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'SupplierCatalogImport' });
    const { cleanBulkUploadStore, currentSupplier } = useBulkUploadStore();
    const handleReUpload = ():void => {
        cleanBulkUploadStore();
        router.push(`${EPaths.CATALOG_MANAGEMENT.replace(':supplierUrlsafeKey', currentSupplier?.supplierUrlsafeKey)}?${QUERY_PARAM_UPLOAD}=true`);
    };
    return <Container>
        <div className="flex items-center gap-2 cursor-pointer mt-8"
            onClick={():void => {
                cleanBulkUploadStore();
                window.history.back();
            }}>
            <CloseIcon className="w-4 h-4 text-gray-400"/>
            <Typography className="m-0 p-0">
                {tCommon('Buttons.exit')}
            </Typography>
        </div>
        <div className="my-10 flex justify-between">
            <Typography as="h1"
                className="m-0 p-0"
                weight="font-medium"
                variant="5XL">{t('reviewUploadedItems')}</Typography>
            <Button variant="TERTIARY_FILLED" size="MEDIUM" onClick={handleReUpload} className="self-start">{t('reUploadCsv')}</Button>
        </div>
    </Container>;
});

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Modal from 'react-modal';

import MemberForm from './forms/MemberForm.jsx';
import MembersTable from './MembersTable.jsx';
import DeleteMember from './DeleteMember.jsx';
import ModalBox from '../../general/ModalBox.jsx';
import LoadingData from '../../LoadingData.jsx';
import SettingsBackButton from '../header/SettingsBackButton.jsx';
import { basicCustomStyles } from '../../../constants/CustomStyles';

export default class MembersMain extends Component {
  getModalView = () => {
    const {
      showMembersModal,
      membersModalIndex,
      toggleMembersModal,
      toggleDeleteMembersModal,
      showDeleteMembersModal,
      loadingSettingsModal,
      saveMember,
      deleteMember,
      error,
      buyer,
    } = this.props;

    return (
      <div>
        {/* Editing or creating a member modal */}
        <Modal
          isOpen={showMembersModal}
          style={basicCustomStyles}
          ariaHideApp={false}
        >
          <ModalBox
            title={`${
              membersModalIndex > -1 ? 'Edit' : 'Add'
            } a team member to ${buyer.name}`}
            activateSave={true}
            loading={false}
            showDelete={false}
            onClickCancel={toggleMembersModal}
            onClickClose={toggleMembersModal}
            customStyles={{ height: '100%', marginTop: '8%' }}
            showHeader={true}
            showFooter={false}
          >
            {loadingSettingsModal ? (
              <LoadingData display={loadingSettingsModal} text="Saving" />
            ) : (
              <MemberForm
                {...this.props}
                errorAsync={error}
                onClickCancel={toggleMembersModal}
                onSubmit={saveMember}
                onClickDelete={deleteMember}
              />
            )}
          </ModalBox>
        </Modal>

        {/* Deleting a member modal */}
        <Modal
          isOpen={showDeleteMembersModal}
          style={basicCustomStyles}
          ariaHideApp={false}
        >
          <ModalBox
            activateSave={true}
            loading={false}
            showDelete={false}
            onClickCancel={toggleDeleteMembersModal}
            onClickClose={toggleDeleteMembersModal}
            customStyles={{
              height: '245px',
              marginTop: '8%',
              textAlign: 'center',
            }}
            showHeader={true}
            showFooter={false}
          >
            <DeleteMember
              {...this.props}
              onClickCancel={toggleDeleteMembersModal}
              onClickDelete={this.onConfirmDelete}
            />
          </ModalBox>
        </Modal>
      </div>
    );
  };

  onConfirmDelete = () => {
    // Access the method of child component and active its
    // onConfirmDelete method
    this._child.onConfirmDelete();
  };

  render() {
    return (
      <div className="members-main">
        <SettingsBackButton router={this.props.router} />
        <MembersTable {...this.props} ref={(child) => (this._child = child)} />
        {this.getModalView()}
      </div>
    );
  }
}

MembersMain.propTypes = {
  showMembersModal: PropTypes.bool.isRequired,
  showDeleteMembersModal: PropTypes.bool.isRequired,
  loadingSettingsModal: PropTypes.bool.isRequired,
  membersModalIndex: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
  buyer: PropTypes.object.isRequired,

  toggleMembersModal: PropTypes.func.isRequired,
  toggleDeleteMembersModal: PropTypes.func.isRequired,
  saveMember: PropTypes.func.isRequired,
  deleteMember: PropTypes.func.isRequired,
  router: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CheckPermission from '../general/CheckPermission.jsx';
import {sendTrackingEvent} from "@v2/utils/Tracking";
import {TrackingEvents} from "@v2/constants/Tracking";

export default class CartCheckoutButton extends Component {
  state = {
    isLoadingSpinner: false
  };

  proceedToCheckout = () => {
    this.setState({ isLoadingSpinner: true });
    sendTrackingEvent(TrackingEvents.checkoutStarted);
    setTimeout(() => {
      this.setState({ isLoadingSpinner: false });
      this.props.proceedToCheckout(this.props.order.urlsafe);
    }, 2000);
  };

  render() {
    const { toggleCartManagersModal } = this.props;

    return (
      <>
        <CheckPermission roles={['Owner', 'Manager']}>
          <button
            onClick={this.proceedToCheckout}
            className='btn btn-primary bg-gray-700 hover:bg-gray-675 active:bg-gray-650 rounded-lg btn-lg cart-order-footer__checkout-button'
          >
            {this.state.isLoadingSpinner ? (
              <i className='spin-spinner fa fa-circle-o-notch' />
            ) : (
              `Checkout with ${this.props.order.vendorName}`
            )}
          </button>
        </CheckPermission>
        <CheckPermission roles={['Staff']}>
          <button
            className='btn btn-primary bg-gray-700 hover:bg-gray-675 active:bg-gray-650 rounded-lg btn-lg cart-order-footer__checkout-button'
            onClick={toggleCartManagersModal}
          >
            Continue
          </button>
        </CheckPermission>
      </>
    );
  }
}

CartCheckoutButton.propTypes = {
  toggleCartManagersModal: PropTypes.func.isRequired,
  proceedToCheckout: PropTypes.func.isRequired
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as ChangeUnitActions from '../../actions/changeUnitActions';
import * as changeUnitSelectors from '../../selectors/changeUnitSelectors';

import ChangeUnitMain from '../../components/change_unit/ChangeUnitMain.jsx';

class ChangeUnitContainer extends Component {
  componentDidMount() {
    const {
      ChangeUnitActions,
      originalVariantFromProps,
      openedByFromProps,
      orderguideFromProps,
    } = this.props;

    // Load all current variants for this product
    ChangeUnitActions.initChangeUnit({
      orderguideFromProps,
      originalVariantFromProps,
      openedByFromProps,
    });
  }

  componentWillUnmount() {
    // Destory all data when the modal is closed
    const { ChangeUnitActions } = this.props;

    ChangeUnitActions.clearData();
  }

  closeModal = () => {
    this.props.onCloseModal();
  };

  checkIfModalCanClose = () => {
    const { ChangeUnitActions, hasVariantsListChanged } = this.props;

    if (hasVariantsListChanged) {
      ChangeUnitActions.showModalConfirmPrompt(true);
      return;
    }

    this.closeModal();
  };

  confirmCloseModal = (isCloseModalConfirmed) => {
    const { ChangeUnitActions } = this.props;

    // If user confirmed closing the modal, close the modal right away.
    if (isCloseModalConfirmed) {
      this.closeModal();
      return;
    }
    // Else, return them back to the main view
    else {
      ChangeUnitActions.showModalConfirmPrompt(false);
    }
  };

  addRemoveVariantCheckout = (variant, quantity, type) => {
    const { ChangeUnitActions, variantsCheckout } = this.props;

    if (variant.inStock) {
      ChangeUnitActions.addRemoveVariantCheckout(
        variant,
        variantsCheckout,
        quantity,
        type
      );
    }
  };

  changeVariants = () => {
    const { ChangeUnitActions } = this.props;
    ChangeUnitActions.changeVariantsOrder();
  };

  render() {
    return (
      <ChangeUnitMain
        {...this.props}
        checkIfModalCanClose={this.checkIfModalCanClose}
        confirmCloseModal={this.confirmCloseModal}
        addRemoveVariantCheckout={this.addRemoveVariantCheckout}
        changeVariants={this.changeVariants}
      />
    );
  }
}

ChangeUnitContainer.propTypes = {
  originalVariantFromProps: PropTypes.object.isRequired,
  openedByFromProps: PropTypes.string.isRequired,
  orderguideFromProps: PropTypes.object,

  onCloseModal: PropTypes.func.isRequired,
};

function mapStatetoProps(state, props) {
  return {
    ...state.changeUnitReducer,

    hasVariantsListChanged: changeUnitSelectors.checkIfVariantsListChanged(
      state,
      props
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ChangeUnitActions: bindActionCreators(ChangeUnitActions, dispatch),
  };
}

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ChangeUnitContainer);

import React, { useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import {
  changeQuantityWithID,
  changeQuantityWithoutID,
  saveNoteCart,
} from '../../../../actions/orderActions';
import { ProductQuantitySelect, TextField } from '../../../shared';
import Utils from '../../../../utils';
import { AnalyticsEventSources } from '../../../../constants/AnalyticsEventSources';
import { Typography } from '@notch-ordering/ui-components';

const CartItem = ({
  order,
  item,
  isEditing,
  onEdit,
  isEditingAddon,
  originalQuantity,
}) => {
  const singleOrderBeforeChange = useSelector(
    (state) => state.ordersReducer.singleOrder
  );

  const itemInOriginalOrder = singleOrderBeforeChange.items.find(
    (orderItem) => orderItem.sourceUrlsafe === item.sourceUrlsafe
  );
  const dispatch = useDispatch();
  const [temporaryQuantity, setTemporaryQuantity] = useState('');
  const noteRef = createRef();
  useEffect(() => {
    if (isEditing && noteRef.current) {
      noteRef.current.focus();
    }
  }, [isEditing]);
  const options = new Array(100).fill(0).map((val, idx) => {
    return {
      value: (idx + 1).toString(),
      label: (idx + 1).toString(),
      isDisabled:
        isEditingAddon && originalQuantity && originalQuantity > idx + 1,
    };
  });

  const onQuantityChange = (orderItem, quantity) => {
    if (orderItem && orderItem.urlsafe) {
      dispatch(
        changeQuantityWithID(
          order.urlsafe,
          orderItem,
          quantity,
          Utils.QUANTITY_TYPES.CUSTOM,
          AnalyticsEventSources.CHECKOUT
        )
      );
    } else {
      dispatch(
        changeQuantityWithoutID(
          order.urlsafe,
          orderItem,
          quantity,
          Utils.QUANTITY_TYPES.CUSTOM,
          AnalyticsEventSources.CHECKOUT
        )
      );
    }
  };

  const onDelete = (orderItem) => {
    if (orderItem && orderItem.urlsafe) {
      dispatch(
        changeQuantityWithID(
          order.urlsafe,
          orderItem,
          0,
          Utils.QUANTITY_TYPES.CUSTOM,
          AnalyticsEventSources.CHECKOUT
        )
      );
    } else {
      dispatch(
        changeQuantityWithoutID(
          order.urlsafe,
          orderItem,
          0,
          Utils.QUANTITY_TYPES.CUSTOM,
          AnalyticsEventSources.CHECKOUT
        )
      );
    }
  };

  const quantityChangerStyle = {
    indicatorsContainer: () => ({
      display: 'none',
    }),
  };
  const total = item.price * item.quantity;
  return (
    <div className="item-wrapper">
      <div className="item" key={item.urlsafe}>
        <div className="left">
          <ProductQuantitySelect
            className="checkout-cart-quantity"
            options={options}
            value={{ label: item.quantity, value: item.quantity }}
            onChange={({ value }) => onQuantityChange(item, value)}
            onInputChange={(value, { action }) => {
              if (action === 'input-change' && !isNaN(value)) {
                setTemporaryQuantity(value);
              } else if (action === 'input-blur' && temporaryQuantity) {
                onQuantityChange(item, temporaryQuantity);
                setTemporaryQuantity(0);
              }
            }}
            styles={quantityChangerStyle}
          />
          <div className="description">
            <Typography as='div' weight='font-semibold'>{item.name}</Typography>
            <Typography as='div' className='text-gray-600'>{`${item.unitName} - ${item.unitDescription}`}</Typography>
            {!isEditing && !item.notes && (
              <button className="add-note" onClick={() => onEdit(item.urlsafe)}>
                <Typography as='div'>Add note</Typography>
              </button>
            )}
          </div>
        </div>
        <div className="right">
          {total > 0 && (
            <div className="price" style={{'fontFamily': 'Inter'}}>{Utils.formatAsCurrency(total)}</div>
          )}

          <button
            className={cx('trash', {
              'add-on': isEditingAddon && itemInOriginalOrder,
            })}
            onClick={() => onDelete(item)}
          >
            <span className="fa fa-trash fa-1" />
          </button>
        </div>
      </div>
      {(isEditing || item.notes) && (
        <div className="item-note">
          <TextField
            icon={
              <button
                className="remove-note"
                onClick={() => {
                  onEdit('');
                  dispatch(saveNoteCart(order.urlsafe, item, ''));
                }}
              >
                <img src="https://storage.googleapis.com/chefhero-storage-release/static/fe-supplier/images/icon-fee-trash.svg" />
              </button>
            }
            ref={noteRef}
            setValueOnEnter
            onBlur={(value) => {
              if (value === item.notes) {
                onEdit('');
                return;
              }

              dispatch(saveNoteCart(order.urlsafe, item, value));
            }}
            defaultValue={item.notes}
          />
        </div>
      )}
    </div>
  );
};

export default CartItem;

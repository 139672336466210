import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Modal, Separator, Typography, onMobileSetLarge, Combobox, ComboOption } from '@notch-ordering/ui-components';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import { SupplierMappings, VendorDetails } from '@v2/network/BushwhackAPI';
import cx from 'classnames';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { EApPlatform } from '@notch-ordering/shared-logic';
import { integrationIcons, integrationNameKeys } from './IntegrationPlatformUtils';

export interface MapSuppliersProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onClose?: () => void,
    onSave?: (supplierMappings: SupplierMappings) => void,
    notchSuppliers: SupplierCatalogData[],
    accountingVendors: VendorDetails[],
    supplierMappingDefaults: SupplierMappings,
    integrationPlatform: EApPlatform,
}

/**
 * Modal to allow the mapping of Notch suppliers to Integration suppliers - Step 1/3
 */
const IntegrationsMapSuppliersModal : React.FC<MapSuppliersProps> = ({
    isOpen,
    setIsOpen,
    onClose,
    onSave,
    notchSuppliers,
    accountingVendors,
    supplierMappingDefaults,
    integrationPlatform,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const [supplierMappings, setSupplierMappings] = useState<SupplierMappings>();
    const [accountingVendorOptions, setAccountingVendorOptions] = useState<ComboOption[]>([]);

    const DEFAULT_LABEL: string = t('selectSupplier');
    const SUPPLIER_AMOUNT_REQUIRING_PADDING = 5;
    const integrationType = integrationPlatform ? t(integrationNameKeys(integrationPlatform)) : '';
    const hasManySuppliers: boolean = notchSuppliers.length > SUPPLIER_AMOUNT_REQUIRING_PADDING;

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const vendorOptions: ComboOption[] = accountingVendors.map((vendor) => ({ label: vendor.displayName, value: vendor.id }));
        vendorOptions.push({ label: DEFAULT_LABEL, value: null });
        setAccountingVendorOptions(vendorOptions);
    }, [isOpen]);

    function onSupplierMappingUpdate(supplierID: string, option: ComboOption): void {
        const updatedMappings: SupplierMappings = { ...supplierMappings, [supplierID]: option.value };
        setSupplierMappings(updatedMappings);
    }

    function onCloseModal(): void {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }

    function onSaveModal(): void {
        if (onSave) {
            onSave(supplierMappings);
        }

        onCloseModal();
    }

    function getSupplierDefaultMappingValue(supplierID: string): string {
        const value: string = supplierMappingDefaults[supplierID] ?? null;
        return value;
    }

    return <Modal
        isOpen={isOpen}
        title={<div className="flex flex-row items-center ">
            <div className="flex items-center mr-4">{integrationIcons(integrationPlatform, 'w-6', 'h-6')}</div>
            <Typography weight="font-medium" className="m-0 mr-1">
                {t('stepOneOfThree')}
            </Typography>
            <Typography weight="font-medium" className="m-0 text-gray-600">
                {t('accountSetup', { integrationType })}
            </Typography>
        </div>}
        hideCloseButton={true}
        close={onCloseModal}
        overflowAuto
        modalSize={onMobileSetLarge('MEDIUM')}
        desktopModalWidth="lg:w-[650px]"
        topPadding="pt-8"
        leftPadding="pl-10"
        rightPadding="pr-10" >
        <div className={cx('flex flex-col justify-start px-10 overflow-auto h-full', {
            'pb-28': hasManySuppliers,
            'pb-[33px]': !hasManySuppliers,
        })}>
            <Button variant="ICON"
                size="NO_BACKGROUND"
                focus=""
                hoverColor=""
                activeColor=""
                className="text-left p-0 cursor-text"><Typography className="mb-2" variant="3XL" weight="font-bold">
                    {t('verifySuppliers', { integrationType })}
                </Typography></Button>
            <Typography className="text-gray-600 mb-12">
                {t('selectSuppliers')}
            </Typography>
            <div className="flex flex-row justify-between mb-2">
                <Typography className="m-0 w-1/2" weight="font-medium">{t('notchName')}</Typography>
                <Typography className="m-0 w-1/2 ml-12" weight="font-medium">{t('integrationsName', { integrationType })}</Typography>
            </div>
            {
                notchSuppliers.map((supplier: SupplierCatalogData, index: number) => {
                    const isLastTwo = index + 1 === notchSuppliers.length || index + 1 === notchSuppliers.length - 1;
                    const isFirst = index === 0;
                    return <div key={index} className="flex flex-col">
                        {!isFirst && <Separator />}
                        <div className="my-3 flex flex-row items-center justify-between gap-x-6">
                            <div className="w-1/2 flex flex-row justify-between gap-x-3 flex-shrink-0 flex-grow-0" >
                                <Typography className="truncate m-0">
                                    {supplier.supplierName}
                                </Typography>
                                <ArrowRightIcon className="text-gray-400 w-4 h-4" />
                            </div>
                            <div className="w-1/2">
                                <div className="flex flex-row justify-center items-center gap-x-6">
                                    {!isLastTwo && <Combobox
                                        onChange={(option: ComboOption): void => {
                                            onSupplierMappingUpdate(supplier.supplierUrlsafeKey, option);
                                        }}
                                        options={accountingVendorOptions}
                                        placeholder={t('selectSupplier')}
                                        value={getSupplierDefaultMappingValue(supplier.supplierUrlsafeKey)}/>}
                                    {isLastTwo && <Combobox
                                        onChange={(option: ComboOption): void => {
                                            onSupplierMappingUpdate(supplier.supplierUrlsafeKey, option);
                                        }}
                                        options={accountingVendorOptions}
                                        optionsClassName="bottom-full"
                                        placeholder={t('selectSupplier')}
                                        value={getSupplierDefaultMappingValue(supplier.supplierUrlsafeKey)}/>}
                                </div>
                            </div>
                        </div>
                    </div>;
                })
            }
        </div>
        <div className="sticky bottom-0 bg-white">
            <Separator />
            <div className="flex justify-end pt-0 mx-10">
                <Button className="mt-6"
                    variant="SECONDARY"
                    size="MEDIUM"
                    onClick={onSaveModal}>
                    {tCommon('Buttons.next')}
                </Button>
            </div>
        </div>
    </Modal>;
};

export default withRouter(IntegrationsMapSuppliersModal);

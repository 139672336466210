import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useFeatures from '../../shared/hooks/useFeatures';
import { getOrderDetails, editOrder } from '../actions/orderActions';
import { reorder } from '../../../actions/orderActions';

const useOrder = (orderUrlSafe = '') => {
  const {
    receiving: { order },
    vendorsReducer: { vendors },
  } = useSelector((store) => store);
  const { feature } = useFeatures();
  const dispatch = useDispatch();
  const getOrder = useCallback(
    (orderUrlSafe) => {
      dispatch(getOrderDetails(orderUrlSafe));
    },
    [dispatch]
  );

  const reOrder = useCallback(
    (order, event) => {
      dispatch(reorder(order, event));
    },
    [dispatch]
  );

  const editOrderData = useCallback(
    async ({ orderUrlSafe, data, isWeb, vendorName, callback }) => {
      await dispatch(
        editOrder({ orderUrlSafe, data, isWeb, vendorName, callback })
      );
    },
    [dispatch]
  );

  const vendor = useMemo(() => {
    if (order.data.vendorUrlsafe && vendors.length) {
      return vendors.find(
        (vendor) => vendor.urlsafe === order.data.vendorUrlsafe
      );
    }
  }, [order.data.vendorUrlsafe, vendors]);

  useEffect(() => {
    if (orderUrlSafe && feature.isReceivingTool) {
      getOrder(orderUrlSafe);
    }
  }, [orderUrlSafe, feature.isReceivingTool]);

  return {
    order,
    feature,
    vendor,
    editOrderData,
    reOrder,
  };
};

export default useOrder;

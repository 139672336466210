import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class ChangeUnitFooter extends Component {
  onClickDone = () => {
    this.props.changeVariants();
  };

  render() {
    const { hasVariantsListChanged } = this.props;

    return (
      <div className="change-unit-footer col-md-12">
        <button
          type="button"
          className="done-button btn btn-primary"
          onClick={this.onClickDone}
          disabled={hasVariantsListChanged ? '' : 'disable'}
        >
          Done
        </button>
      </div>
    );
  }
}

ChangeUnitFooter.propTypes = {
  hasVariantsListChanged: PropTypes.bool.isRequired,
  changeVariants: PropTypes.func.isRequired,
};

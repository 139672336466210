import { ScanLineItemsFocusNavigation } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsFocusNavigation/ScanLineItemsFocusNavigation';
import { ScanLineItemsForm } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsForm/ScanLineItemsForm';
import { ScanLineItemsHeader } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsHeader/ScanLineItemsHeader';
import React from 'react';
import { ScanReviewViewProps } from '@v2/Pages/ScanReview/ScanReviewView';

export const LINE_ITEMS_ID_FORM_PREFIX = 'lineItems';
/**
 *
 * ScanLineItems this is the main component that renders the line items view form and the header
 *
 * @class
 */
export const ScanLineItems : React.FC<ScanReviewViewProps> = ({ className }) => <div className={className}>
    <ScanLineItemsHeader />
    <ScanLineItemsFocusNavigation/>
    <ScanLineItemsForm />

</div>;

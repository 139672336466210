import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class BuyerBox extends Component {
  openLiveChat = () => {
    window.Intercom('show');
  };

  render() {
    const { fields, showUpdate = true } = this.props;

    return (
      <div className="buyer-box">
        {Object.keys(fields).map((key) => (
          <div className="buyer-box-field" key={key}>
            <div className="buyer-box-label">{key}</div>
            <div className="buyer-box-value">
              {(() => {
                // We can't display null, undefined or Object directly in the
                // DOM, so we have to make sure we ignore these values
                if (fields[key] && fields[key].constructor !== Object) {
                  return <span>{fields[key]}</span>;
                }
              })()}
            </div>
          </div>
        ))}
        {
            showUpdate && <div className="buyer-info" onClick={this.openLiveChat}>
                To update your Billing Address, please contact us through live chat
            </div>
        }
      </div>
    );
  }
}

BuyerBox.propTypes = {
  fields: PropTypes.object.isRequired,
  showUpdate: PropTypes.bool,
};

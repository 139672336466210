import React from 'react';
import { Loading, Separator, Typography } from '@notch-ordering/ui-components';
import { t, tCommon } from '@v2/i18n';
import ArrowsBidirectionalIcon from '@icons/arrows-bidirectional-icon.svg';
import { EConfigurationType, EErpPlatform, getChartOfAccountsName, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { InvoiceData, InvoiceLineItem } from '@v2/network/GreevilsGreedApi';
import { integrationNameKeys } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import { Tabs, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import Utils from '@/utils';

type SyncSummaryPageProps = {
    invoice: InvoiceData,
    isFetchingInvoice: boolean,
};

/**
 * This is a bill summary page displayed in the ocr review when either accounting or IMS has been synced for a dual sync user.
 */

export const SyncSummaryPage: React.FC<SyncSummaryPageProps> = ({ invoice, isFetchingInvoice }) => {
    const { draftInvoice } = useOcrInvoiceStore();
    const { selectedTab } = useOcrInvoiceContext();

    const accountingPlatform = integrationNameKeys(draftInvoice?.accountingConfiguration?.data?.platform);
    const imsPlatform = integrationNameKeys(EErpPlatform.OPTIMUMCONTROL);

    function getIntegrationType(imsSyncAt: string, accountingSyncAt: string, platform: string): string {
        if (!imsSyncAt && accountingSyncAt) {
            return t(`Integrations.${platform}`);
        }
        if (imsSyncAt && !accountingSyncAt) {
            return t(`Integrations.${imsPlatform}`);
        }
        return '';
    }

    const invoiceSubTotal = invoice?.lineItems.reduce((subTotal, { subtotal }) => subtotal + subTotal, 0);
    const subTotalConversion = invoiceSubTotal / 100;
    const invoiceTaxTotal = invoice?.lineItems.reduce((taxTotal, { tax }) => tax + taxTotal, 0);
    const taxTotalConversion = invoiceTaxTotal / 100;
    const invoiceTotal = invoice?.invoiceTotal;
    const totalConversion = invoiceTotal / 100;

    const syncAtType = invoice?.lastSyncedToAccounting ? invoice?.lastSyncedToAccounting : invoice?.lastSyncedToIMS;
    const dataSyncedDay = `${Utils.formatDate(syncAtType, 'MMM Do')}`;
    const dataSyncedTime = `${Utils.formatDate(syncAtType, 'h:mm A')}`;

    const syncName = invoice?.accountingSyncName ? invoice?.accountingSyncName : invoice?.imsSyncName;
    const chartOfAccountsName = getChartOfAccountsName(draftInvoice.taxCodes, invoice?.lineItems[0]?.taxCodeID, draftInvoice.glCodes, invoice?.lineItems[0]?.glCodeID, draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip);
    const lineItemCategory = invoice?.lineItems[0]?.name === chartOfAccountsName ? tCommon('Labels.chartOfAccountDetails') : tCommon('Labels.items');
    const isBigChip = draftInvoice?.accountingConfiguration?.type === EConfigurationType.BigChip && selectedTab !== Tabs.IMS;

    return (
        <>
            {isFetchingInvoice ? <div className="mt-10"><Loading isDark /></div>
                : <div className="w-full flex flex-col">
                    {!isBigChip && <div className="my-5 ml-8 flex flex-row items-center gap-x-4">
                        <div className="flex flex-row gap-x-4 justify-center items-center">
                            <div className="flex justify-center items-center w-7 h-7 rounded-lg bg-green-100">
                                <ArrowsBidirectionalIcon className="w-4 h-4 text-green-500 m-0 p-0" />
                            </div>
                            <div className="flex flex-row gap-x-1">
                                <Typography className="m-0 p-0 text-gray-600">{t('ScanReview.billSyncedTo')}</Typography>
                                <Typography className="m-0 p-0" weight="font-medium">{getIntegrationType(invoice?.lastSyncedToIMS, invoice?.lastSyncedToAccounting, accountingPlatform)}</Typography>
                                <Typography className="m-0 p-0 text-gray-600">{'on'}</Typography>
                                <Typography className="m-0 p-0" weight="font-medium">{dataSyncedDay}</Typography>
                                <Typography className="m-0 p-0 text-gray-600">{'at'}</Typography>
                                <Typography className="m-0 p-0" weight="font-medium">{dataSyncedTime}</Typography>
                                <Typography className="m-0 p-0 text-gray-600">{'by'}</Typography>
                                <Typography className="m-0 p-0" weight="font-medium">{syncName}</Typography>
                            </div>
                        </div>
                    </div>}
                    <Separator className="mb-8" />
                    <Typography className="pl-8 mb-8" variant="LG-2" weight="font-semibold">{t('ScanReview.summary')}</Typography>
                    <div className="flex flex-row pl-8 gap-x-10 mb-8">
                        <div className="flex flex-col">
                            <Typography className="text-gray-600 mb-1" weight="font-medium">{tCommon('Labels.supplier')}</Typography>
                            <Typography className="m-0">{`${draftInvoice?.supplierName}`}</Typography>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="text-gray-600 mb-1" weight="font-medium">{t('ScanReview.issueDate')}</Typography>
                            <Typography className="m-0">{`${Utils.formatDate(invoice?.issuedDate, 'MMM Do, YYYY')}`}</Typography>
                        </div>
                        <div className="flex flex-col">
                            <Typography className="text-gray-600 mb-1" weight="font-medium">{t('ScanReview.dueDate')}</Typography>
                            <Typography className="m-0">{`${Utils.formatDate(invoice?.dueDate, 'MMM Do, YYYY')}`}</Typography>
                        </div>
                    </div>
                    <Separator className="mb-8"/>
                    <Typography className="pl-8 mb-2.5" variant="LG-2" weight="font-semibold">{lineItemCategory}</Typography>
                    <div className="flex flex-row pl-8 w-full">
                        <div className="flex flex-col w-full pb-16">
                            <div className={'flex gap-4 mr-8'}>
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead>
                                        <tr>
                                            <th
                                                scope="col"
                                                className="min-w-[72px] py-5 pr-2 text-left text-2 font-medium text-gray-600">
                                                {tCommon('Labels.qty')}
                                            </th>
                                            <th
                                                scope="col"
                                                className="min-w-[120px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                                {tCommon('Labels.productName')}
                                            </th>
                                            <th
                                                scope="col"
                                                className="min-w-[90px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                                {tCommon('Labels.description')}
                                            </th>
                                            <th
                                                scope="col"
                                                className="min-w-[90px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                                {tCommon('Labels.unitPrice')}
                                            </th>
                                            <th
                                                scope="col"
                                                className="min-w-[60px] py-5 px-2 text-left text-2 font-medium text-gray-600">
                                                {tCommon('Labels.sku')}
                                            </th>
                                            <th
                                                scope="col"
                                                className="min-w-[90px] py-5 pl-2 text-right text-2 font-medium text-gray-600">
                                                {tCommon('Labels.estTotal')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice?.lineItems?.map((lineItem: InvoiceLineItem, i: number) => {
                                            const pricePerUnitConversion = lineItem.pricePerUnit / 100;
                                            const pricePerUnit = Utils.formatAsCurrency(pricePerUnitConversion);
                                            const itemTotalConversion = lineItem.total / 100;
                                            const itemTotal = Utils.formatAsCurrency(itemTotalConversion);

                                            return (
                                                <tr key={i}
                                                    className="border-b border-gray-200">
                                                    <td className="py-5 pr-2 text-left text-2 font-regular">{Math.round(lineItem.quantity)}</td>
                                                    <td className="py-5 px-2 text-left text-2 font-regular">{lineItem.name}</td>
                                                    <td className="py-5 px-2 text-left text-2 font-regular">{`${lineItem.unit} ${lineItem.description ? `- ${lineItem.description}` : ''}`}</td>
                                                    <td className="py-5 px-2 text-left text-2 font-regular">{pricePerUnit}</td>
                                                    <td className="py-5 px-2 text-left text-2 font-regular">{lineItem.sku}</td>
                                                    <td className="py-5 pl-2 text-right text-2 font-regular">{itemTotal}</td>
                                                </tr>);
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="flex flex-row justify-end items-end mt-5 mr-8">
                                <div className="flex flex-col justify-end items-end mr-20">
                                    <Typography className="mb-2 text-gray-600">{tCommon('Labels.subTotal')}</Typography>
                                    <Typography className="mb-2 text-gray-600">{tCommon('Labels.tax')}</Typography>
                                    <Typography className="mb-2 font-medium text-gray-700">{tCommon('Labels.estimatedTotal')}</Typography>
                                </div>
                                <div className="flex flex-col justify-end items-end">
                                    <Typography className="mb-2" >{Utils.formatAsCurrency(subTotalConversion)}</Typography>
                                    <Typography className="mb-2">{Utils.formatAsCurrency(taxTotalConversion)}</Typography>
                                    <Typography className="mb-2 font-semibold">{Utils.formatAsCurrency(totalConversion)}</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
        </>
    );
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as SettingsActions from '../../actions/settingsActions';
import * as BuyerActions from '../../actions/buyerActions';
import * as AnalyticsActions from '../../actions/analyticsActions';

import BuyerMain from '../../components/settings/buyer/BuyerMain.jsx';

class BuyerContainer extends Component {
  componentDidMount() {
    this.props.analyticsActions.viewPage('BuyerSettings');
  }

  toggleEditBuyerModal = () => {
    const { settingsActions, analyticsActions, showEditBuyerModal } =
      this.props;

    settingsActions.toggleEditBuyerModal(showEditBuyerModal);
  };

  saveEditBuyerForm = () => {
    const { BuyerForm, settingsActions } = this.props;
    const { name, phone, fax, email, accountingEmails } = BuyerForm.values;

    if (BuyerForm.values) {
      const data = { name, phone, fax, email, accountingEmails };
      settingsActions.updateBuyerSettings(data);
    }
  };

  analyticsViewPage = (screenName) => {
    this.props.analyticsActions.viewPage(screenName);
  };

  render() {
    return (
      <BuyerMain
        {...this.props}
        toggleEditBuyerModal={this.toggleEditBuyerModal}
        saveEditBuyerForm={this.saveEditBuyerForm}
        analyticsViewPage={this.analyticsViewPage}
      />
    );
  }
}

BuyerContainer.propTypes = {};

function mapStateToProps(state) {
  return {
    ...state.buyerReducer,
    ...state.settingsReducer,
    ...state.form,
    ...state.errorsReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    settingsActions: bindActionCreators(SettingsActions, dispatch),
    buyerActions: bindActionCreators(BuyerActions, dispatch),
    analyticsActions: bindActionCreators(AnalyticsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerContainer);

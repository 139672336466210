import { LINE_ITEMS_ID_FORM_PREFIX } from '@v2/components/ScanReview/ScanLineItems/ScanLineItems';

export const getLineItemFieldByIndex = (index: number, fieldName: string): string => `${LINE_ITEMS_ID_FORM_PREFIX}.${index}.${fieldName}`;

export const onNavigate = (id): void => {
    try {
        const isComboboxComponent = id.endsWith('unit') || id.endsWith('glCodeID') || id.endsWith('taxCodeID');
        const element = document.getElementById(id);
        if (isComboboxComponent) {
            element?.querySelector('input').click();
            element?.querySelector('input').scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        element?.focus();
        element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // scroll to the element
    } catch (error) {
        console.error(error);
    }
};

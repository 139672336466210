import React from 'react';
import SuccessIcon from '@icons/success-icon.svg';
import ErrorIcon from '@icons/info-icon.svg';
import { Container, Separator, Typography } from '@notch-ordering/ui-components';
import { CatalogImportProductTable } from '@v2/components/CatalogImport/CatalogImportProductTable/CatalogImportProductTable';
import { TProductImport } from '@v2/stores/BulkUploadStore';

export enum ESupplierCatalogImportProductSectionType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export interface SupplierCatalogImportProductSectionProps {
    type: ESupplierCatalogImportProductSectionType,
    title: React.ReactNode,
    subtitle: React.ReactNode,
    products?: Partial<TProductImport>[],
    hideSeparator?: boolean,
}

export const CatalogImportProductSection : React.FC<SupplierCatalogImportProductSectionProps> = ({ title, subtitle, type, products, hideSeparator }) => <>
    <Container className="my-16">
        <div className="mb-6">
            {type === ESupplierCatalogImportProductSectionType.SUCCESS && <SuccessIcon className="w-5 h-5 text-green-500 mb-4"/>}
            {type === ESupplierCatalogImportProductSectionType.ERROR && <ErrorIcon className="w-5 h-5 text-red-500 mb-4"/>}
            <div className="flex gap-2 flex-col">
                <Typography as="div" variant="LG-2" weight="font-semibold" className="m-0 p-0">
                    {title}
                </Typography>
                <Typography as="div" className="text-gray-600 m-0 p-0">
                    {subtitle}
                </Typography>
            </div>
        </div>
        <CatalogImportProductTable products={products}/>
    </Container>
    {!hideSeparator && <Separator className="mb-16"/>}
</>;

import React from 'react';
import { Combobox } from '@notch-ordering/ui-components';
import { TaxCalculationSettings } from '@v2/network/BushwhackAPI';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { EConfigurationType } from '@notch-ordering/shared-logic';

interface SelectTaxCalculationSettingsProps {
    value: TaxCalculationSettings,
    integrationType: EConfigurationType,
    onChange: (value: TaxCalculationSettings) => void,
}

/**
 * Field to select Accounting Tax Calculation Settings
 */
const SelectTaxCalculationSettings : React.FC<SelectTaxCalculationSettingsProps> = ({
    value,
    integrationType,
    onChange,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Accounting.taxCalculationSettings' });

    const TAX_CALCULATION_SETTINGS_OPTIONS = [{
        value: TaxCalculationSettings.ACCOUNTING,
        label: t('accountingLabel'),
        helperText: t('accountingHelperText')
    },
    {
        value: TaxCalculationSettings.CUSTOM,
        label: t('customLabel'),
        helperText: t('customHelperText')
    }];

    const TAX_CALCULATION_SETTINGS_OPTIONS_RUTTER = [{
        value: TaxCalculationSettings.ACCOUNTING,
        label: t('accountingLabel'),
    }];
    return (
        <Combobox
            onChange={(v): void => onChange(TaxCalculationSettings[v.value])}
            options={integrationType === EConfigurationType.ApRutter ? TAX_CALCULATION_SETTINGS_OPTIONS_RUTTER : TAX_CALCULATION_SETTINGS_OPTIONS}
            value={value}
            disabled={integrationType === EConfigurationType.BigChip}
            label={ t('title')}
            variant="ADVANCED_ALTERNATIVE" />
    );
};

export default SelectTaxCalculationSettings;

import React, { useContext } from 'react';

import Header from '../components/ChatSelectedDetailsHeader.jsx';
import Body from '../components/ChatSelectedDetailsBody.jsx';
import { ChatSelectedContext } from '../context';

const ChatSelectedDetailsContainer = () => {
  const context = useContext(ChatSelectedContext);

  return (
    <div
      className="chat-selected-details-container"
      style={
        context.showChatDetails ? { width: 550 } : { width: 0, opacity: 0 }
      }
    >
      <Header />
      <Body />
    </div>
  );
};

export default ChatSelectedDetailsContainer;

import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { EApPlatform, LineItem, OcrMatchedLineItem, useOcrInvoiceStore, getLineItemFieldByIndex, LineItemCategory, valueToFixedNumber, ELineItemPrecision } from '@notch-ordering/shared-logic';
import { Typography, Popover, Button, Input, Combobox, Separator } from '@notch-ordering/ui-components';
import LowConfidenceScanIcon from '@icons/low-confidence-scan-icon.svg';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { tNamespace } from '@v2/i18n';
import { mergeClassNames } from '@v2/utils/Helpers';
import { TaxCalculationSettings } from '@notch-ordering/shared-logic/dist/src/ocr/network/OcrLoadNetwork';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategory, getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import React from 'react';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { CurrencyInput } from './InputCurrency';
import { InputTax } from './InputTax';

const lowConfidenceScanIcon = <LowConfidenceScanIcon className={cx('w-4 h-4')}/>;

interface Props {
    lineItemPrimaryData: LineItem,
    lineItems: OcrMatchedLineItem[],
    ocrMatchedLineItem: OcrMatchedLineItem,
    unitOptions: { label: string, value: string }[],
    taxCodeOptions: { label: string, value: string }[],
    reactKey: string,
    onBlur: () => void,
    hasErrorFieldIndex: (index: number, field: string) => boolean,
    hasLowConfidenceFieldIndex: (index: number, field: string) => boolean,
    handleOnFocusField: (e: React.FocusEvent<HTMLInputElement>) => void,
    glCodeOptions: { label: string, value: string }[],
    hasGLCodeOptions: boolean,
    hasTaxCodeOptions: boolean,
    updateFormData: <T extends keyof LineItem>(reactKey: string, lineItemKey: T, value: LineItem[T]) => void,
    index: number,
    hasLoadedUnits: boolean,
    isIMSLineItem: boolean,
}

/**
 React component for displaying and editing product line item details.
 *
 @returns JSX.Element
 */
export const ProductLineItem = (
    {
        lineItemPrimaryData,
        lineItems,
        ocrMatchedLineItem,
        reactKey,
        unitOptions,
        onBlur,
        hasErrorFieldIndex,
        hasLowConfidenceFieldIndex,
        handleOnFocusField,
        hasGLCodeOptions,
        hasTaxCodeOptions,
        updateFormData,
        glCodeOptions,
        taxCodeOptions,
        index,
        hasLoadedUnits,
        isIMSLineItem,
    }: Props
): JSX.Element => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.LineItems' });
    const {
        validateForm,
        validateTopLevelData,
        removeLineItem,
        draftInvoice,
        getLineItemSubtotal,
    } = useOcrInvoiceStore();

    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();

    let showTaxCode = draftInvoice?.isQBOEnabled && draftInvoice?.topLevelData?.taxCalculationSettings !== TaxCalculationSettings.CUSTOM && !isIMSLineItem;
    if (getAccountingConfigurationType(draftInvoice.accountingConfiguration) === EApPlatform.BIGCHIP) {
        showTaxCode = false;
    }

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategory: LineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, true, hasImsIntegration);
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);

    return (
        <div className="font-body">
            {/* <code className="mx-8">{lineItem?.packageID}</code> */}
            {/* <code className="mx-8" /> */}
            <div className="px-8 flex items-center justify-between pt-10 pb-2">
                <Typography weight="font-bold" className="m-0">{index + 1}</Typography>
                {lineItems.length > -1 && <Popover
                    className="w-44"
                    button=
                        {<Button
                            variant="TERTIARY_FILLED"
                            className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                            size="SMALL"
                            stopPropagation={false}>
                            <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                <EllipsisIcon className="h-4 w-4"/>
                            </div>
                        </Button>}
                    items={[
                        {
                            label: <Typography className="flex gap-3 items-center m-0"> <TrashIcon
                                className="w-4 h-4"/> {t('deleteLineItem')}</Typography>,
                            className: 'text-red-300',
                            onClick: (): void => {
                                removeLineItem(reactKey, lineItemCategory);
                                validateForm(lineItemCategoryToValidate);
                                validateTopLevelData();
                            },
                        },
                    ]}/>}
            </div>
            <div className="px-8 pt-1 pb-10 flex flex-col gap-2">
                <div className="flex gap-2">
                    <Input variant="ADVANCED_ALTERNATIVE"
                        id={getLineItemFieldByIndex(index, 'name')}
                        onChange={(e): void => updateFormData(reactKey, 'name', e.target.value)}
                        value={lineItemPrimaryData?.name}
                        onFocus={handleOnFocusField}
                        isWarning={hasErrorFieldIndex(index, 'name')}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'name') ? lowConfidenceScanIcon : undefined}
                        onBlur={onBlur}
                        label={t('ProductFields.name')}/>

                    <Input variant="ADVANCED_ALTERNATIVE"
                        id={getLineItemFieldByIndex(index, 'sku')}
                        isWarning={hasErrorFieldIndex(index, 'sku')}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'sku') ? lowConfidenceScanIcon : undefined}
                        onChange={(e): void => updateFormData(reactKey, 'sku', e.target.value)}
                        onFocus={handleOnFocusField}
                        value={lineItemPrimaryData?.sku}
                        onBlur={onBlur}
                        label={t('ProductFields.sku')}/>

                </div>
                <div className={mergeClassNames('grid lg:grid-cols-12  gap-2', {
                    '2xl:grid-cols-[repeat(20,_minmax(0,_1fr))]': draftInvoice.isQBOEnabled
                })}>
                    {hasLoadedUnits && <Combobox
                        className={mergeClassNames('lg:col-span-3', {
                            '2xl:col-span-4': draftInvoice.isQBOEnabled
                        })}
                        id={getLineItemFieldByIndex(index, 'unit')}
                        onBlur={onBlur}
                        onChange={(value): void => {
                            updateFormData(reactKey, 'unit', value.value);
                        }}
                        value={lineItemPrimaryData?.unit}
                        isWarning={hasErrorFieldIndex(index, 'unit')}
                        onFocus={():void => {
                            handleOnFocusField({ target: { id: getLineItemFieldByIndex(index, 'unit') } } as React.FocusEvent<HTMLInputElement>);
                        }}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'unit') ? lowConfidenceScanIcon : undefined}
                        inputIconClassName="right-8"
                        options={unitOptions}
                        label={t('ProductFields.unitOfMeasure')}
                        variant="ADVANCED_ALTERNATIVE"/>}
                    <Input variant="ADVANCED_ALTERNATIVE"
                        className={mergeClassNames('lg:col-span-3', {
                            '2xl:col-span-4': draftInvoice.isQBOEnabled
                        })}
                        id={getLineItemFieldByIndex(index, 'weight')}
                        onChange={(e): void => updateFormData(reactKey, 'weight', e.target.value)}
                        value={lineItemPrimaryData?.weight}
                        type="TEXT"
                        isWarning={hasErrorFieldIndex(index, 'weight')}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'weight') ? lowConfidenceScanIcon : undefined}
                        onFocus={handleOnFocusField}
                        onBlur={(e): void => {
                            updateFormData(reactKey, 'weight', valueToFixedNumber(e.target.value, ELineItemPrecision.WEIGHT) || 0);
                            onBlur();
                        }}
                        label={t('ProductFields.packSize')}/>

                    <Input variant="ADVANCED_ALTERNATIVE"
                        className={mergeClassNames('lg:col-span-3', {
                            '2xl:col-span-4': draftInvoice.isQBOEnabled
                        })}
                        id={getLineItemFieldByIndex(index, 'quantity')}
                        onChange={(e): void => updateFormData(reactKey, 'quantity', e.target.value)}
                        value={lineItemPrimaryData?.quantity}
                        onBlur={(e): void => {
                            updateFormData(reactKey, 'quantity', valueToFixedNumber(e.target.value, ELineItemPrecision.QUANTITY) || 0);
                            onBlur();
                        }}
                        type="TEXT"
                        isWarning={hasErrorFieldIndex(index, 'quantity')}
                        onFocus={handleOnFocusField}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'quantity') ? lowConfidenceScanIcon : undefined}
                        label={t('ProductFields.quantity')}/>

                    <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                        className={mergeClassNames('lg:col-span-3', {
                            '2xl:col-span-4': draftInvoice.isQBOEnabled
                        })}
                        decimalScale={5}
                        fixedDecimalScale={false}
                        id={getLineItemFieldByIndex(index, 'unitPrice')}
                        onChange={(unitPrice): void => {
                            updateFormData(reactKey, 'unitPrice', valueToFixedNumber(unitPrice.floatValue ?? 0, ELineItemPrecision.UNIT_PRICE));
                        }}
                        value={lineItemPrimaryData?.unitPrice}
                        onBlur={onBlur}
                        isWarning={hasErrorFieldIndex(index, 'unitPrice')}
                        onFocus={handleOnFocusField}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'unitPrice') ? lowConfidenceScanIcon : undefined}
                        label={t('ProductFields.unitPrice')}/>

                    <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                        className={'lg:col-span-3 2xl:col-span-3'}
                        id={getLineItemFieldByIndex(index, 'unitDeposit')}
                        onChange={(unitDeposit): void => {
                            updateFormData(reactKey, 'unitDeposit', valueToFixedNumber(unitDeposit.floatValue ?? 0, ELineItemPrecision.UNIT_PRICE));
                        }}
                        value={lineItemPrimaryData?.unitDeposit}
                        onBlur={((): void => {
                            if (!lineItemPrimaryData.unitDeposit) {
                                updateFormData(reactKey, 'unitDeposit', 0);
                            }
                            onBlur();
                        })}
                        onFocus={handleOnFocusField}
                        label={`${t('ProductFields.unitDeposit')}`}/>

                    <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                        className={'lg:col-span-4 2xl:col-span-4'}
                        id={getLineItemFieldByIndex(index, 'discount')}
                        onChange={(discount): void => {
                            updateFormData(reactKey, 'discount', valueToFixedNumber(discount.floatValue, ELineItemPrecision.DISCOUNT));
                        }}
                        value={lineItemPrimaryData?.discount}
                        onBlur={((): void => {
                            if (!lineItemPrimaryData.discount) {
                                updateFormData(reactKey, 'discount', 0);
                            }
                            onBlur();
                        })}
                        onFocus={handleOnFocusField}
                        label={`${t('ProductFields.discount')}`}/>

                    {showTaxCode
                    && <Combobox
                        id={getLineItemFieldByIndex(index, 'taxCodeID')}
                        onChange={(value): void => updateFormData(reactKey, 'taxCodeID', value.value)}
                        className="lg:col-span-4"
                        onFocus={():void => {
                            handleOnFocusField({ target: { id: getLineItemFieldByIndex(index, 'taxCodeID') } } as React.FocusEvent<HTMLInputElement>);
                        }}
                        options={taxCodeOptions}
                        isWarning={hasErrorFieldIndex(index, 'taxCodeID')}
                        onBlur={onBlur}
                        value={lineItemPrimaryData?.taxCodeID}
                        label={t('ProductFields.taxCode')}
                        variant="ADVANCED_ALTERNATIVE"/>}
                    <InputTax
                        label={t('ProductFields.tax')}
                        id={getLineItemFieldByIndex(index, 'tax')}
                        value={lineItemPrimaryData?.tax}
                        itemSubtotal={getLineItemSubtotal(ocrMatchedLineItem)}
                        onFocus={handleOnFocusField}
                        className="lg:col-span-4"
                        onChange={(tax): void => {
                            updateFormData(reactKey, 'tax', {
                                dollarAmount: valueToFixedNumber(tax.dollarAmount, ELineItemPrecision.TAX_AMOUNT),
                                percent: Number.isNaN(tax.percent) ? 0 : valueToFixedNumber(tax.percent, ELineItemPrecision.TAX_PERCENT)
                            });
                            onBlur();
                        }}/>
                    {(draftInvoice?.isQBOEnabled && !isIMSLineItem) && <Combobox
                        className="lg:col-span-6"
                        id={getLineItemFieldByIndex(index, 'glCodeID')}
                        onChange={(value): void => updateFormData(reactKey, 'glCodeID', value.value)}
                        onFocus={():void => {
                            handleOnFocusField({ target: { id: getLineItemFieldByIndex(index, 'glCodeID') } } as React.FocusEvent<HTMLInputElement>);
                        }}
                        options={glCodeOptions}
                        isWarning={hasErrorFieldIndex(index, 'glCodeID')}
                        onBlur={onBlur}
                        value={lineItemPrimaryData?.glCodeID}
                        label={t('ProductFields.glCode')}
                        variant="ADVANCED_ALTERNATIVE"/>
                    }
                    <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                        disabled
                        className={mergeClassNames('lg:col-span-6', {
                            'lg:col-span-4': !hasGLCodeOptions || !hasTaxCodeOptions
                        })}
                        id={getLineItemFieldByIndex(index, 'total')}
                        onChange={(total): void => updateFormData(reactKey, 'total', total.floatValue)}
                        value={lineItemPrimaryData?.total}
                        onBlur={onBlur}
                        allowNegative
                        label={t('ProductFields.totalPrice')}/>
                </div>
            </div>
            {index !== lineItems.length - 1 && <Separator/>}
        </div>
    );
};

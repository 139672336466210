import Chat from 'twilio-chat';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getChatToken,
  refreshChatToken,
  UPDATE_CHAT_STATUS,
  SHOW_CHAT_UI,
} from '../actions/chatAuthActions';
import {
  addChatChannelWithMessages,
  removeChatChannelWithMessages,
  updateChatChannelAndUnreadCount,
  downloadMPChatAccessList,
} from '../helpers/chatClientHelper';
import { CHAT_MAIN_LOADING_DELAY_IN_MSECONDS } from '../../../constants/GlobalConstants';
import { logException, logMessage } from '../../../domains/shared/logger';
import useFeatures from '../../shared/hooks/useFeatures';
import useIsMobileWidth from '../../shared/hooks/useIsMobileWidth';

export default () => {
  // This holds the instance of Twilio Chat
  const [chatClient, setChatClient] = useState(null);
  const {
    feature: { isChatEnabled, chatAllowAllMarketVendors },
  } = useFeatures();

  const {
    chat: { auth },
    accountReducer: { account },
  } = useSelector((store) => store);
  const dispatch = useDispatch();
  // enable chat if desktop or mobile receiving tool

  const isMobileWidth = useIsMobileWidth();
  const enableChat = !isMobileWidth || window.location.href.includes('/order/edit/');

  useEffect(() => {
    if (
      isChatEnabled !== undefined &&
      chatAllowAllMarketVendors !== undefined &&
      enableChat
    ) {
      fetchMPChatAccessList();
    }
  }, [isChatEnabled, chatAllowAllMarketVendors, enableChat]);
  useEffect(() => {
    if (account.email && !auth.accessTokenChat) {
      dispatch(
        getChatToken(
          account.email.toLowerCase().trim(),
          account.firstName,
          account.lastName
        )
      );
    }
  }, [account.email]);
  useEffect(() => {
    if (auth.accessTokenChat) {
      createOrUpdateChatClient();
    }
  }, [auth.accessTokenChat]);
  useEffect(() => {
    return () => {
      if (chatClient) chatClient.shutdown();
    };
  }, []);

  /**
   * Creates or Updates the chat client object with access token
   */
  const createOrUpdateChatClient = async () => {
    try {
      if (!chatClient) {
        const client = await Chat.create(auth.accessTokenChat);
        client.on('connectionStateChanged', updateChatConnectionStatus);
        client.on('tokenAboutToExpire', refreshUserChatToken);
        client.on('tokenExpired', refreshUserChatToken);
        client.on('channelAdded', addChatChannel);
        client.on('channelRemoved', removeChatChannel);
        client.on('channelUpdated', updateChannel);
        setChatClient(client);

        // Queue it in event loop.
        // This helps to avoid the flicker of empty chat UI and chat channels UI, when chat channels are present.
        setTimeout(() => {
          dispatch({ type: SHOW_CHAT_UI });
        }, CHAT_MAIN_LOADING_DELAY_IN_MSECONDS);
      } else {
        await chatClient.updateToken(auth.accessTokenChat);
      }
    } catch (error) {
      logMessage(
        `Error occured for user: ${account.email} while create/updating chat client: ${chatClient}`
      );
      logException(error);
    }
  };

  const fetchMPChatAccessList = () =>
    downloadMPChatAccessList(
      dispatch,
      isChatEnabled && chatAllowAllMarketVendors
    );
  const updateChatConnectionStatus = (chatStatus) =>
    dispatch({ type: UPDATE_CHAT_STATUS, chatStatus });
  const refreshUserChatToken = () => dispatch(refreshChatToken());
  const addChatChannel = (channel) =>
    addChatChannelWithMessages(auth.buyerKey, channel, dispatch);
  const removeChatChannel = (channel) =>
    removeChatChannelWithMessages(auth.buyerKey, channel, dispatch);
  const updateChannel = (channel) =>
    updateChatChannelAndUnreadCount(auth.buyerKey, channel, dispatch);
};

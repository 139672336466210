import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Button from '@/deprecated/fe-shared-components/button/Button';

import './Modal.scss';

const Modal = ({
  children,
  isOpen,
  onCancel,
  onAccept,
  onClose,
  cancelButtonText,
  acceptButtonText,
  title,
  desc,
  isWarning,
  isLoading,
  className = '',
}) => {
  const showActionsSection = [onCancel, onAccept].some(Boolean);

  return (
    <ReactModal
      className={`CustomModal__content ${className}`}
      overlayClassName="CustomModal__overlay"
      isOpen={isOpen}
    >
      <div className="CustomModal__header">
        {title && <span className="CustomModal__title">{title}</span>}

        {onClose && (
          <span
            className="CustomModal__close-button close-icon"
            role={'button'}
            tabIndex="0"
            onClick={onClose}
          />
        )}
      </div>

      {desc && <p className="CustomModal__desc">{desc}</p>}

      <div className="Modal__body">{children}</div>

      {showActionsSection && (
        <section className="CustomModal__actions">
          {onCancel && (
            <Button
              color={isWarning ? 'dismiss-white-bg' : 'warning-invert'}
              className="CustomModal__action-button"
              onClick={onCancel}
              disabled={isLoading}
            >
              {cancelButtonText}
            </Button>
          )}

          {onAccept && (
            <Button
              color={isWarning ? 'warning-invert' : 'primary'}
              className="CustomModal__action-button"
              onClick={onAccept}
              disabled={isLoading}
            >
              {isLoading ? (
                <i className="spin-spinner fa fa-circle-o-notch" />
              ) : (
                acceptButtonText
              )}
            </Button>
          )}
        </section>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  onCancel: PropTypes.func,
  onAccept: PropTypes.func,
  onClose: PropTypes.func,
  cancelButtonText: PropTypes.string,
  acceptButtonText: PropTypes.string,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
};

Modal.defaultProps = {
  cancelButtonText: 'Cancel',
  acceptButtonText: 'OK',
  className: '',
};

export default Modal;

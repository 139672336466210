import create from 'zustand';
import { persist } from 'zustand/middleware';

export const STORE_SHOP_NAME = 'shop';
/**
 * State used by the shop store
 */
type ShopStore = {
    hasClickedOrderGuides: boolean,
    setHasClickedOrderGuides: (hasClicked: boolean) => void,
};

export const useShopStore = create<ShopStore>()(
    persist(
        (set): ShopStore => ({
            hasClickedOrderGuides: false,
            setHasClickedOrderGuides: (hasClickedOrderGuides: boolean): void => set(() => ({ hasClickedOrderGuides })),
        }),
        { name: STORE_SHOP_NAME }
    )
);

export type LineItemTax = {
    percent: number,
    dollarAmount: number,
};
export type MatchResults = 'new' | 'equal' | 'different' | 'onlyOcr' | 'onlyOrder' | 'userChange';
export type OcrState = 'uploaded' | 'parsed' | 'verified' | 'in_progress' | 'reviewed';

export enum OcrLabel {
    BLURRY = 'Blurry',
    DOES_NOT_MATCH_NOTCH_ORDER = 'Does not match Notch order',
    INCORRECT_SUPPLIER = 'Incorrect supplier',
    MISSING_PAGES = 'Missing pages',
    CREDIT_INV_PACKING_SLIP = 'Credit inv/packing slip',
    DUPLICATE_BILL = 'Duplicate bill'
}

export type LineItem = {
    name?: string,
    sourceUUID?: string, // packageID
    categoryID?: string,
    sku?: string,
    unit?: string,
    quantity?: number | string,
    unitPrice?: number | string,
    weight?: number | string,
    taxCode?: string,
    tax?: LineItemTax,
    taxable?: boolean,
    total?: number,
    key?: string,
    inventoryItemKey?: string,
    packageID?: string,
    updateCatalog?: boolean,
    productKey?: string,
    originalWeight?: number | string,
    hasFinalSalesQuantitySet?: boolean,
    removedFromOcrInvoice?: boolean,
    taxIsSet?: boolean,
    glCodeID?: string,
    discount?: number | string,
};

export type Fee = {
    displayName?: string,
    feeType?: string,
    amount?: number,
    tax?: number,
    urlsafe?: string,
    glCodeID?: string,
};

export type SearchPackageResult = {
    id: string,
    supplierCatalogID: string,
    catalog: string,
    categoryID: string,
};

export type SkuSearchPackageResult = {
    id: string,
    description: string,
    availability: string,
    sku: string,
    quantity: number,
    unit: string,
    pricePerUnit: number,
    taxCode: string,
};

export type OcrMatchedLineItem = {
    primaryItemData?: LineItem,
    secondaryItemData?: LineItem,
    matchResult: MatchResults,
    isRemoved?: boolean,
    reactKey?: string,
    linkedCatalogData?: SkuSearchPackageResult,
    defaultLineItem?: LineItem,
    defaultMatchResult?: MatchResults,
};

export type OcrMatchedFee = {
    reactKey?: string,
    primaryFee?: Fee,
    secondaryFee?: Fee,
};

export type BuyerIntegrationConfiguration = {
    id: string,
    buyerId: string,
    integrationConfiguration: {
        id: string,
        ownerEmail: string,
        type: string,
        configurationData: Record<string, unknown>,
        isEnabled: boolean,
    },
    isEnabled: boolean,
    configurationData?: {
        classData?: {
            id: string,
        },
    },
};

export type GLCode = {
    id: string,
    name: string,
    code: string,
};

export type GlMappingsSKU = Record<string, string>;

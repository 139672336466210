import FileAttachmentIcon from '@icons/file-attachment-icon.svg';
import MoreIcon from '@icons/more-icon.svg';
import { Popover, Typography } from '@notch-ordering/ui-components';
import { tCommon } from '@v2/i18n';
import React, { useMemo } from 'react';
import { getFileNameFromURL } from '../supplierAttachmentUtils';

type Props = {
    attachmentURL: string,
    id: string,
    removeFile: (attachmentURL: string, id: string) => void,
    showDivider?: boolean,
};

/* This component represents a single file that was uploaded to the catalog during supplier onboarding on the uploads page (AddItemsUpload.jsx) */
export const UploadedItem: React.FC<Props> = ({ attachmentURL, id, removeFile, showDivider = true }) => {
    const fileName = useMemo(() => getFileNameFromURL(attachmentURL), [attachmentURL]);
    return (
        <>
            <div className="flex items-center w-full">
                <div className="w-4 h-4 flex-shrink-0"><FileAttachmentIcon title="attachment icon" /></div>
                <Typography as="div" weight="font-medium" className="text-gray-700 pl-4 flex-auto truncate">
                    {fileName}
                </Typography>
                <div className="flex-grow-0 flex-shrink-0">
                    <Popover
                        button={
                            <div className="bg-white flex items-center justify-center h-10 w-10 hover:bg-white lg:hover:bg-gray-100 rounded-xl">
                                <div className="text-gray-700 w-4 h-4"><MoreIcon title="more options" /></div>
                            </div>
                        }
                        items={[
                            {
                                label: tCommon('Buttons.delete'),
                                onClick: (): void => removeFile(fileName, id),
                                className: 'text-red-300'
                            }
                        ]}/>
                </div>
            </div>
            {showDivider && <div className="w-full h-px bg-gray-300 my-2" />}
        </>);
};

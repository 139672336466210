import React from 'react';
import { withRouter } from 'react-router';
import { Button, Modal, Typography, onMobileSetLarge } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { EApPlatform } from '@notch-ordering/shared-logic';
import { integrationIcons, integrationNameKeys } from './IntegrationPlatformUtils';

export interface AccountingConnectedProps {
    isOpen: boolean,
    integrationPlatform: EApPlatform,
    setIsOpen: (isOpen: boolean) => void,
    onClose?: () => void,
}

/**
 * Modal to tell user they have successfully connected to Accounting integration.
 */
const AccountingConnectedModal : React.FC<AccountingConnectedProps> = ({
    isOpen,
    integrationPlatform,
    setIsOpen,
    onClose
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });

    const integrationType = integrationPlatform ? t(integrationNameKeys(integrationPlatform)) : '';

    function onCloseModal(): void {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }

    return <Modal
        isOpen={isOpen}
        desktopModalWidth="lg:w-[482px]"
        hideCloseButton={true}
        modalSize={onMobileSetLarge('SMALL')}
        close={onCloseModal} >
        <div className="flex flex-col justify-center items-center">
            <div className="w-12 h-12">
                {integrationIcons(integrationPlatform, 'w-12', 'h-12')}
            </div>
            <Typography className="text-center mt-10 mb-2 px-10"
                variant="3XL"
                weight="font-bold">
                {t('continueSetup', { integrationType })}
            </Typography>
            <Typography
                className="m-0 text-center text-gray-600 px-[71px]">
                {t('continueSetupDescription')}
            </Typography>
            <Button className="mt-10 mb-11"
                variant="SECONDARY"
                size="MEDIUM"
                onClick={onClose}>
                {tCommon('Buttons.continue')}
            </Button>
        </div>
    </Modal>;
};

export default withRouter(AccountingConnectedModal);

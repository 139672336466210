import React from 'react';
import cx from 'classnames';

import Utils from '../../../../../utils';
import { Typography } from '@notch-ordering/ui-components';

const LineItem = ({ name, className, ...props }) => {
  const price = Utils.formatAsCurrency(props.price);

  return (
    <div className={cx('LineItem', className)}>
      <Typography as='span' weight='font-medium' className="LineItem__name">{name}</Typography>

      <Typography as='span' weight='font-medium'>{price}</Typography>
    </div>
  );
};

export default LineItem;

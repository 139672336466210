import React, { useState, FunctionComponent } from 'react';
import 'react-dates/initialize';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import './index.scss';
import {Button, Modal, Separator} from "@notch-ordering/ui-components";

export interface DateFilterProps {
  onHide: () => void;
  updateDates: (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => void;
  headerText: string;
  isOpen: boolean;
}

const DateFilter: FunctionComponent<DateFilterProps> = ({
  onHide,
  updateDates,
  headerText,
  isOpen,
}: DateFilterProps): JSX.Element => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    'startDate'
  );

  const [startDate, setStartDate] = useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = useState<moment.Moment | null>(null);

  const handleClose = () => {
    onHide();
    setStartDate(null);
    setEndDate(null);
  }
  return (
      <Modal close={handleClose}
             title={headerText}
             titleSeparatorDesktop
             desktopModalWidth="lg:w-[700px]"
             isOpen={isOpen}
             >
        <div className="date-range-filter px-4 lg:px-8 overflow-y-auto flex justify-center ">
          <DateRangePicker
            startDate={startDate}
            startDateId="start"
            endDate={endDate}
            endDateId="end"
            onDatesChange={(newDates) => {
              setStartDate(newDates.startDate);
              setEndDate(newDates.endDate);
            }}
            focusedInput={focusedInput}
            onFocusChange={(e) => setFocusedInput(e || 'startDate')}
            isOutsideRange={() => false}
            numberOfMonths={window.innerWidth > 1080 ? 2 : 1}
            hideKeyboardShortcutsPanel
            navNext={
              <div className="month-arrow right">
                <svg
                  width="8px"
                  height="12px"
                  viewBox="0 0 8 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="MARKET-Order"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                  >
                    <g
                      id="Calendar"
                      transform="translate(-291.000000, -38.000000)"
                      stroke="#6E6E6F"
                      strokeWidth="2"
                    >
                      <g
                        id="Group-44"
                        transform="translate(16.000000, 13.000000)"
                      >
                        <polyline
                          id="Path-6"
                          transform="translate(278.500000, 31.000000) scale(-1, 1) translate(-278.500000, -31.000000) "
                          points="281 26 276 31 281 36"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            }
            navPrev={
              <div className="month-arrow left">
                <svg
                  width="8px"
                  height="12px"
                  viewBox="0 0 8 12"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="MARKET-Order"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                  >
                    <g
                      id="Calendar"
                      transform="translate(-44.000000, -38.000000)"
                      stroke="#ACADAD"
                      strokeWidth="2"
                    >
                      <g
                        id="Group-44"
                        transform="translate(16.000000, 13.000000)"
                      >
                        <polyline id="Path-6" points="35 26 30 31 35 36" />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            }
          />
        </div>
        <Separator className="my-4"/>
        <div className="px-4 lg:px-8 sticky bottom-0">

          <footer className="flex items-center gap-3 sticky bottom-0 ">
            <Button variant="TERTIARY_FILLED"
                    className="ml-auto"
                    onClick={handleClose}
            >Cancel</Button>
            <Button variant="SECONDARY"
                    onClick={() => {
                      updateDates(startDate, endDate);
                      handleClose();

                    }}
                    disabled={!startDate || !endDate}
            >Apply</Button>


          </footer>
        </div>

      </Modal>

  );
};
export default DateFilter;

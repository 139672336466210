import PropTypes from 'prop-types';
import React from 'react';

import CartEmpty from '../CartEmpty.jsx';
import CartOrder from './CartOrder.jsx';
import Utils from '../../../utils';

export default function CartOrders(props) {
  const { orders, vendors } = props;
  const filteredOrders = orders.filter((order) => order.isInShoppingCart);
  const isEmptyCart = filteredOrders.length === 0;

  return (
    <div className="cart-orders text-2">
      {isEmptyCart ? (
        <CartEmpty />
      ) : (
        filteredOrders.map((order) => {
          const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);
          return (
            <CartOrder
              {...props}
              key={order.urlsafe}
              order={order}
              vendor={vendor}
              minimumOrderAmount={Utils.getMinimumOrderAmount(order, vendor)}
              isOnlyOrder={orders.length === 1}
            />
          );
        })
      )}
    </div>
  );
}

CartOrders.propTypes = {
  orders: PropTypes.array.isRequired,
  vendors: PropTypes.array.isRequired,
};

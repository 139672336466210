import React from 'react';
import { Button, Typography } from '@notch-ordering/ui-components';
import PlusIcon from '@icons/add-icon.svg';
import { Order } from '@v2/network/LegacyAPI';
import Utils from '@/utils';
import { OrderRowMoreButton } from './OrdersRowMoreButton';
import { AttachmentThumbnail } from './AttachmentThumbnail';

export interface OrdersMobileTableCellProps {
    ordersData: Order,
    onClick?: () => void,
    openAddBillsModal?: () => void,
    openGenerateBillsModal?: () => void,
    orderUrlsafeKey?: () => void,
    row?: () => void,
}

const SIZE = 40;

export const OrdersMobileTableCell : React.FC<OrdersMobileTableCellProps> = ({
    ordersData,
    onClick = (): void => {},
    openAddBillsModal,
    openGenerateBillsModal,
    orderUrlsafeKey,
    row,
}) => <div className="w-full px-4">
    <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-y-2 truncate">
            <div className="flex flex-row w-64">
                <Typography className="my-0 text-gray-600 truncate">{`${ordersData.vendorName}`}</Typography>
                <Typography className="ml-1 my-0"> {`• ${Utils.formatAsCurrency(ordersData.total)}`}</Typography>
            </div>
            <Typography className="my-0 truncate">{`#${ordersData.id}`}</Typography>
            <Typography className="my-0 text-gray-600 truncate">{`Delivery on ${Utils.formatDate(ordersData.deliveryDay, 'MMM DD, YYYY')}`}</Typography>
        </div>
        <div className="flex flex-col justify-end items-end gap-y-2" onClick={(e): void => e.stopPropagation()}>
            <OrderRowMoreButton row={ordersData}
                openAddBillsModal={(): void => {
                    orderUrlsafeKey();
                    openAddBillsModal();
                }}
                openGenerateBillsModal={(): void => {
                    row();
                    openGenerateBillsModal();
                }} />
            {ordersData.images?.length > 0
                ? <div className="flex justify-center items-center w-10 h-10 bg-transparent">
                    <AttachmentThumbnail
                        pdfHeight={'h-10'}
                        pdfWidth={'w-10'}
                        imageSize={SIZE}
                        smallThumbnail={true}
                        url={ordersData.images[0].image_serving_url} /></div>
                : <Button
                    variant="TERTIARY_FILLED"
                    className="flex justify-center items-center"
                    size="ICON_MEDIUM"
                    onClick={():void => {
                        onClick();
                    }}>
                    <div className={'w-4 h-4'}>
                        <PlusIcon className="h-4 w-4" />
                    </div>
                </Button>}
        </div>
    </div>
</div>;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactModal from 'react-modal';
import { LeftArrow, RightArrow } from '../Icons/Icons.jsx';
import CloseIcon from '@icons/close-icon.svg';
import BackIcon from '@icons/back-icon.svg';

const ImagesModal = ({
  open,
  title,
  onClose,
  images,
  imageIndex,
  slideShowView,
}) => {
  const [index, setIndex] = useState(
    imageIndex < 0 || imageIndex >= images.length ? 0 : imageIndex
  );

  // When images or imageIndex changes reset the index in state
  useEffect(() => {
    setIndex(imageIndex < 0 || imageIndex >= images.length ? 0 : imageIndex);
  }, [images, imageIndex]);

  return (
    <ReactModal
      isOpen={open}
      className="images-modal"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          transition: 'none',
        },
        content: {
          transform: 'translate(0%, 0%)',
          transition: 'none',

        }
      }}
      aria={{
        labelledby: 'images-modal-title',
      }}
    >
      <div className="images-modal-header pt-safe">
        <div className="flex items-center justify-center w-full py-3">
          <div className="images-modal-header-left w-10/12  ">
            <button
                tabIndex={0}
                className="btn images-modal-back"
                onClick={onClose}
            >
              <BackIcon className="w-4 h-4 text-white"/>

              <span className="visually-hidden">Go Back</span>
            </button>
            <h3 id="images-modal-title" className="images-modal-title">
              {title}
            </h3>
        </div>
          <button className="btn images-modal-close" onClick={onClose}>
            <CloseIcon className="w-4 h-4 text-white"/>
            <span className="visually-hidden">Close Modal</span>
          </button>
        </div>
      </div>

      <div
        className={classnames(
          'images-modal-container mt-safe',
          slideShowView && 'slide-show'
        )}
      >
        {images.map((image, idx) => (
          <div
            key={image.key}
            className={classnames(
              'images-modal-image-wrapper mt-20 lg:mt-16 ',
              slideShowView && idx === index && 'active'
            )}
            style={{
              transform: slideShowView ? `translateX(-${index * 100}%)` : '',
            }}
          >
            <img
              className="images-modal-image max-w-full w-full lg:max-w-[70%] "
              src={image.url}
              alt={image.alt}
            />
          </div>
        ))}
        {slideShowView && index !== 0 && (
          <button
            className="images-modal-container-button-left"
            onClick={() => setIndex((prevIndex) => prevIndex - 1)}
          >
            <LeftArrow />
            <span className="visually-hidden">Previous Image</span>
          </button>
        )}
        {slideShowView && index + 1 !== images.length && (
          <button
            className="images-modal-container-button-right"
            onClick={() => setIndex((prevIndex) => prevIndex + 1)}
          >
            <RightArrow />
            <span className="visually-hidden">Next Image</span>
          </button>
        )}
      </div>
    </ReactModal>
  );
};

ImagesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.oneOf([React.Fragment]),
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      key: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  imageIndex: PropTypes.number,
  slideShowView: PropTypes.bool,
};

ImagesModal.defaultProps = {
  images: [],
  imageIndex: 0,
  slideShowView: false,
};

export default ImagesModal;

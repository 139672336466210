import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, ComboOption, Combobox, Modal, Separator, Typography, onMobileSetLarge } from '@notch-ordering/ui-components';
import { t, tCommon } from '@v2/i18n';
import { ApClassSchema, EApPlatform } from '@notch-ordering/shared-logic';
import { integrationIcons, integrationNameKeys } from './IntegrationPlatformUtils';

export interface ClassSettingProps {
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void,
    onClose?: () => void,
    onSave?: (accountingClassOption: ComboOption) => void,
    accountingClasses: ApClassSchema[],
    accountingClassDefault: ApClassSchema,
    integrationPlatform: EApPlatform,
}

/**
 * Modal to allow for toggle import classes during integration setup - Step 3/3
 */
const IntegrationsImportClassesModal : React.FC<ClassSettingProps> = ({
    isOpen,
    setIsOpen,
    onClose,
    onSave,
    accountingClasses,
    accountingClassDefault,
    integrationPlatform,
}) => {
    const [accountingClassOptions, setAccountingClassOptions] = useState<ComboOption[]>([]);
    const [accountingClassOption, setAccountingClassOption] = useState<ComboOption>();
    const integrationType = t(`Integrations.${integrationNameKeys(integrationPlatform)}`);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const classOptions: ComboOption[] = accountingClasses.map((accountingClass) => ({ label: accountingClass.name, value: accountingClass.id }));
        setAccountingClassOptions(classOptions);
    }, [isOpen]);

    useEffect(() => {
        setAccountingClassOption({ label: accountingClassDefault.name, value: accountingClassDefault.id });
    }, [accountingClassDefault]);

    function onAccountingClassUpdate(option: ComboOption): void {
        setAccountingClassOption(option);
    }

    function onCloseModal(): void {
        setIsOpen(false);
        if (onClose) {
            onClose();
        }
    }

    function onSaveModal(): void {
        if (onSave) {
            onSave(accountingClassOption);
        }

        onCloseModal();
    }

    return <Modal
        isOpen={isOpen}
        title={<div className="flex flex-row items-center ">
            <div className="flex items-center mr-4">{integrationIcons(integrationPlatform, 'w-6', 'h-6')}</div>
            <Typography weight="font-medium" className="m-0 mr-1">
                {t('Integrations.stepThreeOfThree')}
            </Typography>
            <Typography weight="font-medium" className="m-0 text-gray-600">
                {t('Integrations.accountSetup', { integrationType })}
            </Typography>
        </div>}
        hideCloseButton={true}
        close={onCloseModal}
        modalSize={onMobileSetLarge('MEDIUM')}
        desktopModalWidth="lg:w-[650px]"
        topPadding="pt-8"
        leftPadding="pl-10"
        rightPadding="pr-10" >
        <div className="flex flex-col justify-start pt-2.5 pb-6 px-10">
            <Button variant="ICON"
                size="NO_BACKGROUND"
                focus=""
                hoverColor=""
                activeColor=""
                className="text-left p-0 cursor-text"><Typography className="mb-2" variant="3XL" weight="font-bold">
                    {t('Integrations.selectClass')}
                </Typography></Button>
            <Typography className="text-gray-600 mb-10">
                {t('Integrations.changeSelectionLater')}
            </Typography>
            <div className={'lg:w-[300px] flex flex-row justify-between items-center mb-6'}>
                <Combobox
                    value={accountingClassOption?.value}
                    options={accountingClassOptions}
                    optionsClassName="h-[104px]"
                    placeholder="Select class"
                    width={'lg:w-[300px] w-full'}
                    onChange={(option: ComboOption): void => { onAccountingClassUpdate(option); }} />
            </div>
        </div>
        <Separator />
        <div className="flex justify-end pt-0 mx-10">
            <Button className="mt-6"
                variant="SECONDARY"
                size="MEDIUM"
                onClick={onSaveModal}>
                {tCommon('Buttons.next')}
            </Button>
        </div>
    </Modal>;
};

export default withRouter(IntegrationsImportClassesModal);

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@notch-ordering/ui-components';

export default function OrdersPlacedConfirmation(props) {
  const { placedOrders, exitCheckout } = props;

  const numberOfOrdersPlaced = placedOrders.length || 0;
  const message =
    numberOfOrdersPlaced > 1
      ? `Your ${numberOfOrdersPlaced} orders have been sent`
      : 'Your order has been sent';

  return (
    <div
      className="col-md-8 col-sm-offset-2 checkout-no-orders"
      style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
    >
      <Typography as='div' weight='font-medium' variant='2XL'>{message}</Typography>
      <div className="orders-placed-checkmark-image"></div>
      <div
        className="back-to-order-desk-button-container"
        onClick={exitCheckout}
      >
        <Typography as='div' weight='font-medium' variant='2XL' className="back-to-order-desk-button-text">Back to order desk</Typography>
      </div>
    </div>
  );
}

OrdersPlacedConfirmation.propTypes = {
  placedOrders: PropTypes.array.isRequired,
  exitCheckout: PropTypes.func.isRequired,
};

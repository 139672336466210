import { Button, Modal, Typography } from '@notch-ordering/ui-components';
import React from 'react';
import { tCommon } from '@v2/i18n';

export interface ConfirmDeletionModalProps {
    isOpen: boolean,
    onConfirm: () => void,
    onClose: () => void,
    title?: React.ReactNode,
    prompt?: React.ReactNode,
}

export const ConfirmDeletionModal: React.FC<ConfirmDeletionModalProps> = ({ isOpen, onConfirm, onClose, prompt, title }) => <Modal isOpen={isOpen}
    title={title}
    close={onClose}>
    <div className="px-8 pb-5">
        <Typography as="div" className="text-gray-600">
            {prompt}
        </Typography>
    </div>
    <div className="pt-5 px-5 flex justify-end gap-3">
        <Button variant="TERTIARY_FILLED"
            size="MEDIUM"
            onClick={onClose}>
            <Typography as="span" weight="font-medium">
                {tCommon('Buttons.cancel')}
            </Typography>
        </Button>
        <Button variant="DESTRUCTIVE"
            size="MEDIUM"
            onClick={onConfirm}>
            <Typography as="span" weight="font-medium">
                {tCommon('Buttons.delete')}
            </Typography>
        </Button>
    </div>
</Modal>;

import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const ChatSelectedFooter = ({ showFileUpload, sendHandler, children }) => {
  const [chatMessage, setChatMessage] = useState('');
  const textInput = createRef();
  const {
    auth: { isSendingMessage },
  } = useSelector((state) => state.chat);

  const sendChatMessage = () => {
    sendHandler(chatMessage);
    setChatMessage('');
  };

  const onKeyPress = ({ key }) => {
    // send message if user presses enter.
    if (key === 'Enter') {
      sendChatMessage();
    }
  };

  const handleFocus = () => {
    if (textInput && textInput.current) {
      textInput.current.focus();
    }
  };

  return (
    <>
      <div className="footer" onClick={handleFocus}>
        <div className="chat-compose">
          <input
            ref={textInput}
            type="text"
            placeholder="Write a message..."
            value={chatMessage}
            onChange={(e) => setChatMessage(e.target.value)}
            onKeyPress={onKeyPress}
          />
        </div>
        {
          <button className="send-button" onClick={sendChatMessage}>
            {isSendingMessage ? (
              <i
                style={{ fontSize: '16px' }}
                className="spin-spinner fa fa-circle-o-notch"
              ></i>
            ) : (
              'Send'
            )}
          </button>
        }
        <button className="attachment-button" onClick={showFileUpload}>
          <span
            className="btn-lg glyphicon glyphicon-paperclip"
            style={{ cursor: 'pointer' }}
          />
        </button>
      </div>

      {children}
    </>
  );
};

export default ChatSelectedFooter;

ChatSelectedFooter.propTypes = {
  showFileUpload: PropTypes.func.isRequired,
  sendHandler: PropTypes.func.isRequired,

  children: PropTypes.element.isRequired,
};

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Separator } from '@notch-ordering/ui-components';
import CartContainer from "../cart/CartContainer.jsx";
import LoadingData from "../../components/LoadingData.jsx";
import { showCart } from "../../actions/cartActions";
import { IonContent } from "@ionic/react";
import useIsMobileWidth from '@/domains/shared/hooks/useIsMobileWidth';
import SideNavigation from "@v2/components/Shared/SideNavigation/SideNavigation";
import useAccount from "@v2/hooks/useAccount.hook";
import useBuyer from "@v2/hooks/useBuyer.hook";
import useOrderTotalsHook from "@v2/hooks/useOrderTotals.hook";
import Utils from "@/utils";
import * as cartActions from "@/actions/cartActions";
import { Loading } from '@notch-ordering/ui-components';
import { BottomNavigation } from "@v2/components/Shared/BottomNavigation/BottomNavigation";

const IndexWrapper = ({ main, location }) => {
  const dispatch = useDispatch();
  const isLoadingAppInit = useSelector(
    (state) => state.accountReducer.isLoadingAppInit
  );
  const { ordersTotalMinusTaxesAndFees = 0 } = useOrderTotalsHook();
  const { isCartOpen } = useSelector((store) => store.cartReducer);
  let wrapperStyle = {};
  const cartStyle = { display: isCartOpen ? "block" : "none" };
  const isMobileWidth = useIsMobileWidth();
  const { org } = useAccount();
  const {
    defaultBuyer: { buyerName = "" },
  } = useBuyer();

  const toggleCart = () => {
    dispatch(cartActions.showCart(!isCartOpen));
  };

  return (
    <div id="wrapper" style={wrapperStyle}>
      <div style={cartStyle}>
        <div
          id="multi-cart"
          className="multi-cart"
          onClick={(e) => {
            if (e.target.id === "multi-cart") {
              dispatch(showCart(false));
            }
          }}
        >
          <CartContainer />
        </div>
      </div>

      {isMobileWidth &&
      <IonContent scrollEvents id="page-wrapper__">
      <div className="fixed w-full pt-safe bg-white z-50"/>
      {isLoadingAppInit ? (
        <div className="flex justify-center w-full mt-8 p-safe">
          <Loading isDark/>
        </div>
      ) : (
        <div className="w-full h-full p-safe"><div className="h-full pb-[62px]">{main}</div></div>
      )}
      <BottomNavigation locationName={buyerName} location={location} />
      </IonContent>}


      {!isMobileWidth && <IonContent scrollEvents id="page-wrapper__">
        <div className="flex flex-row">

            <section className="sticky top-0 min-w-[260px] h-screen z-50 bg-white">
              <Separator className="absolute mt-[129px]"/>
              <SideNavigation
              locationName={buyerName}
              total={Utils.formatAsCurrency(ordersTotalMinusTaxesAndFees)}
              toggleCart={toggleCart}
              location={location}
              />
            </section>

          <Separator className="min-h-screen sticky top-0 flex-shrink-0 w-px bg-gray-300"/>
          {isLoadingAppInit ? (
            <div className="flex justify-center w-full mt-8 pt-safe">
              <Loading isDark/>
            </div>
          ) : (
            <div className="w-full">{main}</div>
          )}

        </div>
      </IonContent>}
    </div>
  );
};

IndexWrapper.propTypes = {
  main: PropTypes.node.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default IndexWrapper;

import PropTypes from "prop-types";
import React from "react";

import Utils from "../../../utils";
import { BUCKET_ASSETS_STATIC_URL } from "@v2/constants/Assets";

const CustomDatePickerText = (props) => {
  const { item, itemSelected, orderDeliveryDay, onClick } = props;
  return (
    <div onClick={onClick}>
      {_.isEqual(item, itemSelected) ? (
        <div>
          <p className="day-of-week">
            {Utils.formatDate(orderDeliveryDay, "dddd")}
          </p>
          <p className="date">{Utils.formatDate(orderDeliveryDay, "MMMM D")}</p>
          <img src={`${BUCKET_ASSETS_STATIC_URL}images/check%402x.png`} />
        </div>
      ) : (
        <div style={{ position: "relative" }} className="custom-date-btn">
          <i className="fa fa-calendar" aria-hidden="true"></i>
          <span>All Delivery Days</span>
        </div>
      )}
    </div>
  );
};

CustomDatePickerText.defaultProps = {
  orderDeliveryDay: "",
  itemSelected: {},
};

CustomDatePickerText.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  itemSelected: PropTypes.objectOf(PropTypes.any),
  orderDeliveryDay: PropTypes.string,

  onClick: PropTypes.func.isRequired,
};

export default CustomDatePickerText;

import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import AddToCartButton from '@v2/components/Market/AddToCartButton/AddToCartButton';
import AddToCartModal from '@v2/components/Market/AddToCartModal/AddToCartModal';
import { IProduct } from '@v2/network/types';
import useGetOrderBySupplierCatalogId from '@v2/hooks/useGetOrderBySupplier.hook';
import { ProductCardProvider } from '@v2/components/Market/ProductCard/ProductCardContext';
import { getProductPricesRange } from '@v2/Pages/Market/MarketConstants';
import { MarkAsSavedToggleButton } from '@v2/components/Market/MarkAsSavedToggleButton/MarkAsSavedToggleButton';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

export interface Props {
    product?: IProduct,
}

export const ProductCard: React.FC<Props> = ({ product, }) => {
    const { name, supplierName, isSaved, supplierCatalogID, packages } = product;
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const order = useGetOrderBySupplierCatalogId(supplierCatalogID);
    const packagesIDs = packages.map((item) => item.id);
    const orderHasPackages = order?.items?.filter((item) => packagesIDs.includes(item.sourceUrlsafe)) ?? [];
    const numberOfProductsInOrder = orderHasPackages.reduce((acc, item) => acc + item.quantity, 0);
    const price = getProductPricesRange(packages);
    const isMobileWidth: boolean = useIsMobileWidth();
    const shouldShowBookmarkButton = !isMobileWidth;
    return (
        <ProductCardProvider order={order} numberOfProductsInOrder={numberOfProductsInOrder} product={product}>
            <div className="w-full flex gap-3">
                <div className="lg:w-3/4  flex flex-col lg:flex-row">
                    <div className="lg:w-3/4  w-full flex flex-col gap-2">
                        <Typography
                            as="div"
                            className="text-gray-700"
                            weight="font-medium">
                            {name}
                        </Typography>
                        <Typography as="div" className="text-gray-600">
                            {supplierName}
                        </Typography>
                    </div>
                    <div
                        className="lg:w-1/4 w-full flex items-center gap-2 lg:items-start lg:flex-col">
                        <Typography as="div" className="text-gray-700">
                            {price}
                        </Typography>
                        <span className="text-[8px] text-gray-600 lg:hidden">•</span>
                        <Typography as="div" className="text-gray-600">
                            {packages.length} size{packages.length > 1 ? 's' : ''}
                        </Typography>
                    </div>
                </div>
                <div className="lg:w-1/4 gap-2 flex items-center ml-auto justify-end">
                    <AddToCartButton
                        numberOfItemsInCart={numberOfProductsInOrder}
                        onClick={(): void => {
                            setIsModalOpen(true);
                            sendTrackingEvent(TrackingEvents.addButtonClicked);
                        }}/>
                    <AddToCartModal setIsOpen={setIsModalOpen}
                        isOpen={isModalOpen}/>
                    {shouldShowBookmarkButton && <MarkAsSavedToggleButton product={product} isSaved={isSaved} />}
                </div>
            </div>
        </ProductCardProvider>
    );
};

export default ProductCard;

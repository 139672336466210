import { useEffect, useState } from 'react';
import { EApPlatform, getAccountingConfiguration } from '@notch-ordering/shared-logic';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import useBuyerHook from './useBuyer.hook';

export const useApPlatform = () : EApPlatform => {
    const { buyer } = useBuyerHook();

    const buyerUrlsafeKey = buyer.urlsafe;

    const [apPlatform, setApPlatform] = useState<EApPlatform>();

    useEffect(() => {
        if (buyerUrlsafeKey) {
            const fetchAccountingPlatform = async (): Promise<void> => {
                const accountingConfig = await getAccountingConfiguration(buyerUrlsafeKey);
                setApPlatform(getAccountingConfigurationType(accountingConfig) as EApPlatform);
            };

            fetchAccountingPlatform();
        }
    }, [buyerUrlsafeKey, setApPlatform, apPlatform]);

    return apPlatform;
};

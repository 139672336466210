import PropTypes from "prop-types";
import React, { Component } from "react";
import AsyncSelect from "react-select/async";

import { fetchSearchProductResults } from "@/api/DataAPI";
import { BUCKET_ASSETS_STATIC_URL } from "@v2/constants/Assets";
import Utils from "../../../utils";
import { SupplierStatus } from "@/constants/SupplierStatus";

import OrderItemRow from "./OrderItemRow.jsx";
import SearchSuggestion from "./SearchSuggestion.jsx";
import { Typography } from '@notch-ordering/ui-components';;

export default class OrderItemsList extends Component {
  changeCheckoutView = (event) => {
    event.stopPropagation();

    this.props.changeCheckoutView("deliveryView");
  };

  selectNewItem = (selectedItem) => {
    const { order, orderItem, changeQuantity } = this.props;
    const quantity = 1;
    changeQuantity(order.urlsafe, orderItem, quantity, "CUSTOM", selectedItem);
  };

  getOptions = async (query, callback) => {
    const { buyer, vendor } = this.props;
    try {
      // Don't return anything if we have no query
      if (!query) {
        return;
      }

      const data = {
        buyerID: buyer.id,
        searchTerm: query,
        vendorID: vendor.id,
        page: 0,
        isOutOfStockIncluded: false,
      };

      const response = await fetchSearchProductResults(data);
      const options = response ? response.data.items.map((item) => item) : [];
      callback(options);
    } catch (error) {
      console.error(error);
      toastr.error("A problem occurred retrieving search results");
    }
  };

  render() {
    const { orderItems, order, vendor } = this.props;

    const filterOptions = ({ data }) => {
      const orderItemIDs = order.items.map((item) => item.sourceUrlsafe);

      // Filter items already added to the order
      return !orderItemIDs.includes(data.urlsafe);
    };

    return (
      <div>
        <div className="order-checkout-review-subheader">
          {order.deliveryDay ? (
            <div className="delivery-day-label">
              <Typography as='div' variant='BASE' className="delivery-day-title">DELIVERY DAY:</Typography>
              <div className="date-text">
                <img
                  src={`${BUCKET_ASSETS_STATIC_URL}images/calendar%402x.png`}
                />
                <Typography as='span' weight='font-medium' variant='2XL'>{Utils.formatDate(order.deliveryDay, "dddd, MMMM D, YYYY")}</Typography>
                {!order.isEditingAddOnOrder && (
                  <button
                    onClick={this.changeCheckoutView}
                    className="btn btn-ch-primary-link-on-gray actions"
                  >
                    Change
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="date-text">
              Please Choose an Expected Delivery Date Below
              <button
                onClick={this.changeCheckoutView}
                className="btn btn-ch-primary-link-on-gray actions"
              >
                Change
              </button>
            </div>
          )}
          {vendor.customer.vendorStatus === SupplierStatus.PENDING && (
            <div className="pending-vendor-label pending-vendor-chip pull-right">
              1st ORDER - 2 Day Lead Time
              <span className="info-image tip-bottom">
                <span className="tip-text">
                  Your first order with a new supplier will require 2 business
                  days of lead time to make sure your business is set up just
                  right for a sublime delivery experience. Subsequent orders
                  will not require this lead time.
                </span>
              </span>
            </div>
          )}
        </div>
        {!order.isBYOS && (
          <div className="add-new-item">
            <Typography className="header">ADD NEW ITEM</Typography>
            {/* Add cache={false} to load results again, cache is causing issue */}
            <AsyncSelect
              name="search"
              cache={false}
              loadOptions={this.getOptions}
              placeholder={
                <div>
                  <span className="fa fa-search search-icon"></span> Type to
                  search...
                </div>
              }
              searchPromptText={""}
              onChange={this.selectNewItem}
              components={{
                Option: SearchSuggestion,
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
                NoOptionsMessage: () => null,
              }}
              className="Select-control"
              noOptionsMessage={() => null}
              filterOption={filterOptions}
              styles={{
                control: () => ({
                  backgroundColor: "#f5f5f5",
                  display: "flex",
                  borderRadius: "4px",
                  height: "36px",
                }),
                placeholder: () => ({
                  ".search-icon": { color: "#000" },
                  div: {
                    color: "#aaa",
                  },
                }),
                valueContainer: (props) => ({
                  ...props,
                  position: "relative",
                }),
                input: (props) => ({
                  ...props,
                  position: "absolute",
                  left: "22px",
                }),
                loadingMessage: (props, style) => {
                  return {
                    ...props,
                    display: "flex",
                    justifyContent: "flex-start",
                    textAlign: "left",
                  };
                },
              }}
            />
          </div>
        )}
        <table className="products-table">
          <colgroup>
            <col width="10%" />
            <col width="40%" />
            <col width="20%" />
            <col width="20%" />
            <col width="10%" />
          </colgroup>
          <tbody>
            {!order.isBYOS ? (
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>ITEM DESCRIPTION</td>
                <td></td>
                <td>UNIT SIZE</td>
                <td style={{ padding: "7px 10px" }}>QUANTITY</td>
                <td>PRICE</td>
              </tr>
            ) : (
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>ITEM DESCRIPTION</td>
                <td style={{ padding: "7px 10px" }}>QUANTITY</td>
                <td>PRICE</td>
              </tr>
            )}
          </tbody>
          <tbody>
            {orderItems
              .filter((orderItem) => !_.isEmpty(orderItem))
              .map((orderItem) => (
                <OrderItemRow
                  key={orderItem.urlsafe}
                  orderItem={orderItem}
                  {...this.props}
                />
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}

OrderItemsList.propTypes = {
  order: PropTypes.object.isRequired,
  orderItems: PropTypes.array.isRequired,
  vendor: PropTypes.object.isRequired,
  buyer: PropTypes.object.isRequired,

  changeCheckoutView: PropTypes.func.isRequired,
  changeQuantity: PropTypes.func.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames';

import './styles.scss';

const ProductQuantitySelect = ({ className, isDisabled, ...props }) => {
  return (
    <Select
      className={cx('product-quantity-select', className)}
      classNamePrefix="product-quantity-select"
      clearable={false}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

ProductQuantitySelect.propTypes = {
  className: PropTypes.string,
};

export default ProductQuantitySelect;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import OrderItems from '../order-items';
import LineItem from '../line-item';
import { Typography } from '@notch-ordering/ui-components';

const OrderSummary = ({
  items,
  customItems = [],
  itemsTotal,
  subtotal,
  tax,
  total,
  vendorDeliveryFee,
  creditApplied,
  chefheroServicesFee,
  isBYOS,
}) => {
  const customItemsFormatted = useMemo(
    () =>
      customItems.map((item) => {
        return {
          name: item.name,
          unitName: item.packaging,
          unitDescription: item.externalRef,
          price: item.price,
          quantity: item.quantity,
        };
      }),
    [customItems]
  );
  const showCustomItemsSection = customItemsFormatted.length > 0;
  return (
    <div className="OrderSummary">
      <Typography variant='3XL' size='text-6' font='font-title' weight='font-medium' className="OrderSummary__title">Summary</Typography>

      <LineItem name="Items" price={itemsTotal} />

      <OrderItems items={items} />

      {chefheroServicesFee > 0 ? (
        <>
          <LineItem name="Delivery fee" price={vendorDeliveryFee} />
          <LineItem
            className="LineItem--last-fee"
            name="Service fee"
            price={Number((itemsTotal * chefheroServicesFee).toFixed(2))}
          />
        </>
      ) : (
        <>
          <LineItem
            className="LineItem--last-fee"
            name="Delivery fee"
            price={vendorDeliveryFee}
          />
        </>
      )}

      <LineItem name="Subtotal" price={subtotal} />

      {!isBYOS && <LineItem name="Taxes" price={tax} />}

      {creditApplied > 0 ? (
        <>
          <LineItem
            className="LineItem--credit"
            name="Credit"
            price={creditApplied}
            isCredit
          />
          <LineItem
            className="LineItem--total"
            name="Estimated Total"
            price={total - creditApplied}
          />
        </>
      ) : (
        <LineItem
          className="LineItem--total"
          name="Estimated Total"
          price={total}
        />
      )}
      {showCustomItemsSection && (
        <>
          <LineItem name="Added by you for this order" />
          <OrderItems items={customItemsFormatted} />
        </>
      )}
    </div>
  );
};

OrderSummary.propTypes = {
  subtotal: PropTypes.number.isRequired,
  tax: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  vendorDeliveryFee: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  customItems: PropTypes.arrayOf(PropTypes.object),
  creditTotalAmount: PropTypes.number.isRequired,
  itemsTotal: PropTypes.number.isRequired,
  serviceFee: PropTypes.number,
  isBYOS: PropTypes.bool.isRequired,
};

export default OrderSummary;

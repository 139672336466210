import React, { useEffect } from 'react';
import Route from 'react-router';

type Props = {
    children: React.ReactNode,
    router: Route,
};

export const Setup: React.FC<Props> = ({ children }: Props) => {
    function moveIntercomButton(): void {
        setTimeout(() => {
            if (window.Intercom.booted) {
                window.Intercom('update', {
                    alignment: 'left',
                });
            } else {
                moveIntercomButton();
            }
        }, 5000);
    }

    useEffect(() => {
        if (window.Intercom.booted) {
            window.Intercom('update', {
                alignment: 'left',
            });
        } else {
            // This is a bad hack to move the intercom button from overlapping on the next button
            // The delay is only needed if the users lands in /setup first
            moveIntercomButton();
        }

        return (() => {
            window.Intercom('update', {
                alignment: 'right',
            });
        });
    }, [children]);

    return (
        <div className="h-full w-full flex flex-col lg:flex-row">
            {children}
        </div>
    );
};

export default Setup;

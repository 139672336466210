import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { StripeProvider } from 'react-stripe-elements';
import cx from 'classnames';
import { bindActionCreators } from 'redux';

import ChangeUnitContainer from '../../containers/change_unit/ChangeUnitContainer.jsx';
import { Modal as CustomModal } from '../shared';
import Header from './header';
import CheckoutBody from './CheckoutBody.jsx';
import Summary from './Summary.jsx';
import OrdersPlacedConfirmation from './CheckoutConfirm.jsx';
import NoOrdersCheckout from './NoOrdersCheckout.jsx';
import BuyerSuspendedModal from '../modals/BuyerSuspendedModal.jsx';
import ProgressBar from '../general/ProgressBar.jsx';
import LoadingData from '../LoadingData.jsx';
import EditingAddOnOrderNotice from './EditingAddOnOrderNotice';
import * as OrderActions from '../../actions/orderActions';
import * as CheckoutActions from '../../actions/checkoutActions';
import { basicCustomStyles } from '../../constants/CustomStyles';
import { CHECKOUT_STEPS } from '../../constants/Checkout';
import { Button, Separator, Typography } from '@notch-ordering/ui-components';
import BackIcon from '@icons/back-icon.svg';
import { EPaths } from '@v2/constants/EPaths';
import { ContinueButton } from '@v2/components/Checkout/ContinueButton';
import withUseIsMobileWidth from '@/domains/shared/hooks/withUseIsMobileWidth';

class CheckoutMain extends Component {
  state = {
    currentStep: CHECKOUT_STEPS.CART,
  };

  steps = [CHECKOUT_STEPS.CART, CHECKOUT_STEPS.INFO, CHECKOUT_STEPS.PAYMENT];

  getModalView = () => {
    const {
      showChangeUnitModal,
      showCancelOrderModal,
      changeUnitProduct,
      buyer,
      stripePubKey,
      cancelOrderVendor,
      isBuyerSuspendedModalOpen,
      toggleChangeUnitModalCheckout,
      toggleCancelOrderModal,
      toggleBuyerSuspendedModal,
      cancelOrder,
      reverseOrder,
    } = this.props;

    const customStyles = {
      ...basicCustomStyles,
      content: {
        ...basicCustomStyles.content,
        padding: '20px',
      },
    };

    if (showChangeUnitModal || showCancelOrderModal) {
      // Disable Scroll
      $('body').css('overflow', 'hidden');
    } else {
      // Enable Scroll
      $('body').css('overflow', 'auto');
    }

    const closeCancelOrderModal = () => toggleCancelOrderModal('');

    return (
      <div>
        <Modal
          isOpen={showChangeUnitModal}
          style={customStyles}
          ariaHideApp={false}
        >
          <ChangeUnitContainer
            buyer={buyer}
            onCloseModal={toggleChangeUnitModalCheckout}
            originalVariantFromProps={changeUnitProduct}
            openedByFromProps={'CHECKOUT'}
          />
        </Modal>

        <CustomModal
          title={`Cancel order from ${cancelOrderVendor}`}
          desc="You can save this order and checkout at a later time. Delete
              this order if you would like to remove it completely from your cart."
          onClose={closeCancelOrderModal}
          onAccept={reverseOrder}
          onCancel={cancelOrder}
          acceptButtonText="Save for later"
          cancelButtonText="Delete order"
          isOpen={showCancelOrderModal}
        />

        <Modal
          isOpen={isBuyerSuspendedModalOpen}
          style={customStyles}
          ariaHideApp={false}
        >
          <BuyerSuspendedModal
            onCloseModal={() => toggleBuyerSuspendedModal()}
          />
        </Modal>
      </div>
    );
  };

  nextStep = () => {
    const nextStepIdx = this.steps.indexOf(this.state.currentStep) + 1;
    if (nextStepIdx < this.steps.length) {
      this.setState({
        currentStep: this.steps[nextStepIdx],
      });
    }
  };

  goBack = (step) => {
    this.setState({ currentStep: step });
  };

  render() {
    const {
      checkoutView,
      loadingCheckout,
      loadingOrders,
      orders,
      isEditingSingleOrder,
      currentlyEditingOrderUrlsafe,
      placedOrders,
      toggleCancelOrderModal,
    } = this.props;

    if (!this.props.orderToPlaceUrlsafe) {
      return null;
    }

    const order = this.props.orders.find(
      (o) => o.urlsafe === this.props.orderToPlaceUrlsafe
    );

    if (
      order &&
      this.props.isEditingSingleOrder &&
      (!this.props.singleOrderBeforeChange ||
        this.props.singleOrderBeforeChange.urlsafe !== order.urlsafe)
    ) {
      this.props.orderActions.receiveSingleOrders(order);
    }

    let currentStep = 'step1';

    // This is for the ProgressBar component to know which steps it's in
    // step1, step2 or step3 depending on checkoutView value
    switch (checkoutView) {
      case 'deliveryView':
        currentStep = 'step1';
        break;
      case 'reviewView':
        currentStep = 'step2';
        break;
      case 'pendingView':
        if (orders.length > 0) {
          currentStep = 'step3';
        } else {
          currentStep = 'step0';
        }
        break;
      default:
        currentStep = 'step1';
    }

    // We override all the switch statement values with 'step0'
    // If we have no orders
    if (orders.length === 0) {
      currentStep = 'step0';
    }
    const hasBYOSOrders = orders.some((o) => o.isCheckout && o.isBYOS);
    const hasMarketOrders = orders.some((o) => o.isCheckout && !o.isBYOS);

    const currentlyEditingOrder = orders.find(
      (o) => o.urlsafe === currentlyEditingOrderUrlsafe
    );

    const { isEditingAddOnOrder, isInShoppingCart, urlsafe } =
      currentlyEditingOrder || {};

    const openCancelOrderModal = () => toggleCancelOrderModal(urlsafe);
    const continueButtonStyle = 'pt-2.5 px-[30px]';
    const currentVendor = order?.vendorUrlsafe && this.props.vendors.length ? this.props.vendors.find(
        (v) => v.urlsafe === order.vendorUrlsafe
    ): null;

    return (
      <div className="container-checkout text-2 h-full overflow-y-auto overflow-x-hidden">
        {loadingCheckout || loadingOrders ? (
          <div className="col-md-12">
            {/* show which ever one is true, laodingCheckout or loadingOrders */}
            <LoadingData
              display={loadingCheckout ? loadingCheckout : loadingOrders}
              text="Loading"
            />
          </div>
        ) : (
          <div>
            {/* IIFE in JSX */}
            {(() => {
              if (placedOrders.length > 0) {
                return <OrdersPlacedConfirmation {...this.props} />;
              } else if (isEditingSingleOrder) {
                if (currentlyEditingOrder) {
                  return (
                    <div className={cx({ 'checkout-content': true })}>
                      <div
                        className={cx({
                          'col-md-8': false,
                          'left-element': true,
                        })}
                      >
                        <div>
                          {isEditingAddOnOrder && <EditingAddOnOrderNotice />}
                        </div>
                        <div
                          className={cx({
                            'checkout-header': true,
                          })}
                        >
                          <>
                            <Header order={order} />
                            <Button variant='TEXT' size='NO_BACKGROUND' className="pl-0" onClick={() => { this.props.routerActions.changeRoute(EPaths.HOME); }}>
                              <div className='flex flex-row'>
                                <div className='text-gray-600 pr-3 flex-shrink-0'><BackIcon className='w-5 h-5' /></div>
                                <Typography as='span'>Back to Order Desk</Typography>
                              </div>
                            </Button>
                            <div className="pt-3 pb-10">
                              <Typography as='span' variant='5XL' size='text-7' weight='font-medium'>
                                Checkout with {order.vendorName}
                              </Typography>
                            </div>
                            <ProgressBar
                              currentStep={this.state.currentStep}
                              backToCart={this.goBack}
                              order={order}
                              isEditingOrder={order.isEditingAddOnOrder}
                            />
                            <Separator variant='small' />
                          </>
                        </div>
                        <CheckoutBody
                          currentStep={this.state.currentStep}
                          goBack={() => this.goBack(CHECKOUT_STEPS.INFO)}
                          isEditingAddon={order.isEditingAddOnOrder}
                          {...this.props}
                        />
                        {!this.props.isMobileWidth &&
                          <div className={continueButtonStyle}>
                            <ContinueButton
                                vendor={currentVendor}
                              order={order}
                              nextStep={this.nextStep}
                              currentStep={this.state.currentStep}
                              isEditingAddonOrder={order.isEditingAddOnOrder}
                              isEditingOrderDeliveryDay={this.props.isEditingOrderDeliveryDay}
                              singleOrderBeforeChange={this.props.singleOrderBeforeChange}
                              checkoutActions={this.props.checkoutActions}
                              orderActions={this.props.orderActions}
                            />
                          </div>}
                      </div>
                      <div
                        className={cx('right-element')}
                      >
                        <Summary
                          additionalOrderTotals={
                            this.props.additionalOrderTotals
                          }
                          order={order}
                          vendor={currentVendor}
                          nextStep={this.nextStep}
                          currentStep={this.state.currentStep}
                          isEditingAddonOrder={order.isEditingAddOnOrder}
                        />
                        {!isInShoppingCart && !isEditingAddOnOrder && (
                          <div className='w-full my-5 flex'>
                            <Button
                              variant="TERTIARY_FILLED"
                              size="MEDIUM"
                              className='flex-grow mx-[30px] lg:mx-[72px]'
                              onClick={openCancelOrderModal}
                            >
                              <Typography as='span' variant='LG-2'>Cancel this order</Typography>
                            </Button>
                          </div>
                        )}
                        {this.props.isMobileWidth &&
                          <div className={continueButtonStyle}>
                            <ContinueButton
                              order={order}
                              vendor={currentVendor}
                              nextStep={this.nextStep}
                              currentStep={this.state.currentStep}
                              isEditingAddonOrder={order.isEditingAddOnOrder}
                              isEditingOrderDeliveryDay={this.props.isEditingOrderDeliveryDay}
                              singleOrderBeforeChange={this.props.singleOrderBeforeChange}
                              checkoutActions={this.props.checkoutActions}
                              orderActions={this.props.orderActions}
                            />
                          </div>}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <NoOrdersCheckout
                      {...this.props}
                      displayText="This order is no longer editable"
                    />
                  );
                }
              } else {
                if (orders.length > 0) {
                  return (
                    <div>
                      <div className="col-md-8">
                        <CheckoutBody {...this.props} />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <NoOrdersCheckout
                      {...this.props}
                      displayText="There are no pending orders at this time"
                    />
                  );
                }
              }
            })()}
            {this.getModalView()}
          </div>
        )}
      </div>
    );
  }
}

CheckoutMain.propTypes = {
  showChangeUnitModal: PropTypes.bool.isRequired,
  showCancelOrderModal: PropTypes.bool.isRequired,
  cancelOrderVendor: PropTypes.string.isRequired,
  changeUnitProduct: PropTypes.object.isRequired,
  buyer: PropTypes.object.isRequired,
  loadingCheckout: PropTypes.bool.isRequired,
  loadingOrders: PropTypes.bool.isRequired,
  updatingSidebar: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  errorType: PropTypes.string.isRequired,
  errorMessage: PropTypes.array.isRequired,
  cancelOrder: PropTypes.func.isRequired,
  reverseOrder: PropTypes.func.isRequired,
  isBuyerSuspendedModalOpen: PropTypes.bool.isRequired,
  isEditingSingleOrder: PropTypes.bool.isRequired,
  currentlyEditingOrderUrlsafe: PropTypes.string,

  toggleChangeUnitModalCheckout: PropTypes.func.isRequired,
  toggleCancelOrderModal: PropTypes.func.isRequired,
  toggleBuyerSuspendedModal: PropTypes.func.isRequired,
};

export default connect(
  (state, props) => ({
    singleOrderBeforeChange: state.ordersReducer.singleOrder,
    isEditingOrderDeliveryDay: state.ordersReducer.isEditingOrderDeliveryDay
  }),
  (dispatch) => ({
    orderActions: bindActionCreators(OrderActions, dispatch),
    checkoutActions: bindActionCreators(CheckoutActions, dispatch),
  })
)(withUseIsMobileWidth(CheckoutMain));

import React from 'react';
import { Typography } from '@notch-ordering/ui-components';
import { useTranslation } from 'react-i18next';
import { tNamespace } from '@v2/i18n';
import { UPLOAD_TABS } from '@v2/components/Uploads/UploadsTabs/UploadsTabs';
import BidirectionalArrowIcon from '@icons/arrows-bidirectional-icon.svg';
import CheckIcon from '@icons/check-icon.svg';

export interface UploadsNoResultsProps {
    type?: string,
    hasFiltersSelected?: boolean,
}

type EmptyLabelAndIcon = {
    message: React.ReactNode,
    icon: React.ReactNode,
};
const getEmptyStateMessage = (type: string, hasFiltersSelected: boolean, t):EmptyLabelAndIcon => {
    if (type === UPLOAD_TABS.IN_REVIEW && !hasFiltersSelected) {
        return {
            message: <Typography desktopSize="lg:text-2" variant="BASE" weight="font-regular" className="text-gray-600 mb-16 whitespace-pre-line">
                {t('niceYouHaveNoUploads')}
            </Typography>,
            icon: <CheckIcon className="h-3.5 w-3.5"/>
        };
    }
    if (type === UPLOAD_TABS.APPROVED && !hasFiltersSelected) {
        return { message: <Typography desktopSize="lg:text-2" variant="BASE" weight="font-regular" className="text-gray-600 mb-16 whitespace-pre-line">
            {t('onceYouApproveBills')}
        </Typography>,
        icon: <BidirectionalArrowIcon className="h-3.5 w-3.5"/> };
    }
    if (hasFiltersSelected) {
        return {
            message: <div className="">
                <Typography variant="LG-2" weight="font-semibold" className="text-gray-700 mb-2">{t('noResultsFound')}</Typography>
                <Typography variant="LG-1" weight="font-regular" className="text-gray-600 mb-16 lg:w-80 lg:mx-auto mb-0">
                    {t('weCouldntFindAnyUploads')}
                </Typography>
            </div>,
            icon: null,
        };
    }
    return { message: '', icon: null };
};

export const UploadsNoResults : React.FC<UploadsNoResultsProps> = ({ type = UPLOAD_TABS.IN_REVIEW, hasFiltersSelected }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Uploads.NoResults' });
    const { message, icon } = getEmptyStateMessage(type, hasFiltersSelected, t);
    return <div className="lg:mt-40 mt-32 flex flex-col justify-center items-center h-full px-4 lg:px-0">
        <div className="lg:w-96 w-full text-center flex gap-4 flex-col">
            {!!icon && <div className="mx-auto bg-gray-100 w-8 h-8 flex justify-center items-center rounded-xl">
                {icon}
            </div>}
            {message}

        </div>
    </div>;
};

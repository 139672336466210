import { toastr } from 'react-redux-toastr';
import camelCaseKeys from 'camelcase-keys';
import { logException } from '../../../domains/shared/logger';
import {
  SEARCH_START,
  SEARCH_SUCCESS,
  SEARCH_FAILED,
  SEARCH_CLEAR,
} from '../types';
import {searchBuyerCatalog} from "@v2/network/SecretShopAPI";

/**
 * Clear Search Product Catalog
 *
 * @param {string} searchTerm Search keyword
 * @param {string} buyerUrlsafeKey Unique buyer key
 * @param {string} supplierUrlsafeKey Order's supplier key
 */
export const searchProductCatalog =
  ({ searchTerm, buyerUrlsafeKey, supplierUrlsafeKey }) =>
  async (dispatch) => {
    try {
      dispatch({ type: SEARCH_START });
      const data = { supplierUrlsafeKeys: [supplierUrlsafeKey], name: searchTerm};
      const responseData = await searchBuyerCatalog(buyerUrlsafeKey, data);
      const results = responseData.results;

      dispatch({
        type: SEARCH_SUCCESS,
        payload: camelCaseKeys(results || [], {
          deep: true,
        }),
      });
    } catch (error) {
      dispatch({ type: SEARCH_FAILED });
      const { response = null } = error;
      let message = '';

      if (!response) {
        message = 'Detected a connection problem, please refresh this page';
      } else {
        const {
          data: { errorMessage = 'Please try again' } = {
            errorMessage: 'Please try again',
          },
          status = 0,
        } = response;

        if (status === 403) {
          message = 'Unauthorized error!';
        } else {
          message = errorMessage;
        }
      }

      toastr.error(`Error: ${message}`);
      logException(error);
    }
  };

/* Clear Search Product Catalog */
export const clearSearchResults = () => (dispatch) => {
  dispatch({ type: SEARCH_CLEAR });
};

import { ActionsPanel, SetupInfo } from '@v2/components/Setup/Setup';
import { EPaths, ESetupPaths } from '@v2/constants/EPaths';
import React, { useState } from 'react';

import { Button, Typography } from '@notch-ordering/ui-components';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import { tCommon, tNamespace } from '@v2/i18n';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Route from 'react-router';
import { useSetupStore } from '@v2/stores/SetupStore';

type Props = {
    router: Route,
};

type Option = {
    label?: string,
    text?: string,
    path?: string,
    badgeText?: string,
};

/**
 * View that list the ways to uploads the catalog
 *
 * @param props - component properties
 * @param props.router - for navigation
 */
export const AddItems: React.FC<Props> = ({ router }) => {
    const { supplier, setupActions, productsCreated, filesUploaded } = useSetupStore();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Catalog.AddItems' });
    const [isActionsExpanded, setIsActionsExpanded] = useState(false);

    const close = (): void => {
        router.push(EPaths.HOME);
    };

    const back = (): void => {
        router.goBack();
    };

    const addItemOptions: Option[] = [
        {
            badgeText: tCommon('recommended'),
            label: t('uploadFiles'),
            text: t('filesUploaded', { count: filesUploaded ?? 0 }),
            path: `${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS_UPLOAD}`
        },
        {
            label: t('createItems'),
            text: t('itemsCreated', { count: productsCreated ?? 0 }),
            path: `${EPaths.SETUP}/${ESetupPaths.CATALOG_ADD_ITEMS_CREATE}`
        }
    ];

    return (
        <>
            <SetupInfo
                heading={supplier.name}
                title={t('startAdding')}
                description={t('addAllItems')}
                shortTitle={t('addItems')}
                close={close}
                back={back}
                gradient
                showShortTitle={isActionsExpanded}>
                <ActionsPanel
                    back={back}
                    nextButtonLabel={tCommon('Buttons.finish')}
                    nextButtonDisabled={!setupActions.didCreateItems && !setupActions.didUploadFiles}
                    next={(): void => {
                        router.push(`${EPaths.SETUP}/${ESetupPaths.CATALOG_COMPLETE}`);
                    }}
                    totalSteps={3}
                    currentStep={2}
                    onExpand={(isExpanded: boolean): void => {
                        setIsActionsExpanded(isExpanded);
                    }}>
                    {addItemOptions.map((option: Option) => (
                        <Button
                            variant="TERTIARY_OUTLINED"
                            className={cx('p-6 mb-4 focus:ring-offset-0 focus:ring-1 flex items-center justify-between w-full')}
                            key={option.label}
                            onClick={(): void => {
                                router.push(option.path);
                            }}>
                            <div>
                                {option.badgeText && (
                                    <Typography
                                        as="div"
                                        desktopSize="lg:text-3"
                                        weight="font-semibold"
                                        className="text-left text-teal-300 bg-teal-100 py-0.5 px-2 rounded-3xl mb-4 w-fit">
                                        {option.badgeText}
                                    </Typography>
                                )}
                                <Typography
                                    as="div"
                                    variant="LG-2"
                                    desktopSize="lg:text-4"
                                    weight="font-medium"
                                    className="text-left text-gray-700 mb-2">
                                    {option.label}
                                </Typography>

                                <Typography
                                    as="div"
                                    variant="LG-2"
                                    desktopSize="lg:text-4"
                                    className="text-left text-gray-600">
                                    {option.text}
                                </Typography>
                            </div>
                            <div>
                                <div className="h-3 w-2 text-gray-600">
                                    <ArrowRightIcon title="arrow-right" />
                                </div>
                            </div>
                        </Button>
                    ))}
                </ActionsPanel>
            </SetupInfo>
        </>
    );
};

import React from 'react';
import cx from 'classnames';

export const RadioButton = ({
  id,
  name,
  label,
  isChecked,
  onChecked,
  isDisabled = false,
}) => (
  <label
    htmlFor={id}
    className={cx({
      disabled: isDisabled,
    })}
  >
    <input
      type="radio"
      name={name}
      id={id}
      disabled={isDisabled}
      checked={isChecked}
      onChange={(e) => {
        if (e.target.checked) {
          onChecked(e);
        }
      }}
    />
    {label}
  </label>
);

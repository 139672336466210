import React from 'react';

import { SupplierStatus } from '../../../constants/SupplierStatus';

import CartCheckoutButton from '../CartCheckoutButton.jsx';

const CartOrderFooter = ({ vendor, ...props }) => (
  <section className='cart-order-footer'>
    <CartCheckoutButton {...props} />

    {vendor && vendor.customer.vendorStatus === SupplierStatus.PENDING && (
      <div className='cart-order-footer__lead-time-notice'>
        Please allow 2-day lead time for your first order.

        <span className='info-image tip-top'>
          <span className='tip-text'>
            First orders require two business days lead time for administrative setup.
            Setup will not be required for subsequent orders.
          </span>
        </span>
      </div>
    )}
  </section>
);

export default CartOrderFooter;

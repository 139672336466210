import { EApPlatform, EErpPlatform, ESyncDataType, LineItemCategory } from '@notch-ordering/shared-logic';
import { Tabs } from '../ScanReview/ScanReviewContext';

export enum ERPIntegrations {
    OC = 'OC',
    MARKETMAN = 'Marketman'
}

export const billDetailsIconWidth = 'w-5';
export const billDetailsIconHeight = 'h-5';
export const activeIconWidth = 'w-6';
export const activeIconHeight = 'h-6';
export const exploreIconWidth = 'w-8';
export const exploreIconHeight = 'h-8';

export type IntegrationDisplayData = {
    name: string,
    description?: string,
    date?: string,
    activeIcon?: React.ReactNode,
    exploreIcon?: React.ReactNode,
    platform?: EApPlatform | EErpPlatform,
};

/**
 * Given a numeric day of the month, returns the corresponding day
 * as a string with ordinal suffix (e.g. 1st, 10th)
 */
export function nthDay(d: number): string {
    if (d > 3 && d < 21) return `${d}th`;
    switch (d % 10) {
        case 1: return `${d}st`;
        case 2: return `${d}nd`;
        case 3: return `${d}rd`;
        default: return `${d}th`;
    }
}

/**
 * Given a Date object, returns a string representation of that date, formatted
 * according to the design specifications for the Integrations page
 */
export function formatDate(date: Date): string {
    if (date === null || date === undefined) {
        return '';
    }
    const month = date.toLocaleString('default', { month: 'long' });
    const day = nthDay(date.getDate());
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
}

const getAccountingLineItemCategoryToValidate = (syncDataType: ESyncDataType, isAutoCalculateOn: boolean): LineItemCategory => ((syncDataType === ESyncDataType.CHART_OF_ACCOUNT_DETAILS && !isAutoCalculateOn)
    ? 'chartOfAccountDetails'
    : 'lineItems');

export function getLineItemCategoryToValidate(selectedTab: Tabs, syncDataType: ESyncDataType, isAutoCalculateOn: boolean, useSeparateIMSLineItems: boolean, hasImsIntegration: boolean): LineItemCategory {
    let lineItemCategory: LineItemCategory;
    switch (selectedTab) {
        case Tabs.IMS:
            lineItemCategory = 'imsLineItems';
            break;
        case Tabs.ACCOUNTING:
            lineItemCategory = getAccountingLineItemCategoryToValidate(syncDataType, isAutoCalculateOn);
            break;
        case Tabs.NO_TABS:
        default:
            lineItemCategory = hasImsIntegration
                ? 'imsLineItems'
                : getAccountingLineItemCategoryToValidate(syncDataType, isAutoCalculateOn);
            break;
    }

    return lineItemCategory;
}

const geAccountingLineItemCategory = (syncDataType: ESyncDataType, ignoreChartOfAccounts: boolean): LineItemCategory => ((!ignoreChartOfAccounts && syncDataType === ESyncDataType.CHART_OF_ACCOUNT_DETAILS) ? 'chartOfAccountDetails' : 'lineItems');

export function getLineItemCategory(selectedTab: Tabs, syncDataType: ESyncDataType, useSeparateIMSLineItems: boolean, ignoreChartOfAccounts: boolean, hasImsIntegration: boolean): LineItemCategory {
    let lineItemCategory: LineItemCategory;
    switch (selectedTab) {
        case Tabs.IMS:
            lineItemCategory = useSeparateIMSLineItems ? 'imsLineItems' : 'lineItems';
            break;
        case Tabs.ACCOUNTING:
            lineItemCategory = geAccountingLineItemCategory(syncDataType, ignoreChartOfAccounts);
            break;
        case Tabs.NO_TABS:
        default:
            // eslint-disable-next-line no-nested-ternary
            lineItemCategory = hasImsIntegration
                ? (useSeparateIMSLineItems ? 'imsLineItems' : 'lineItems')
                : geAccountingLineItemCategory(syncDataType, ignoreChartOfAccounts);
            break;
    }

    return lineItemCategory;
}

import React, { useEffect } from 'react';
import CloseIcon from '@icons/close-icon.svg';
import { Button, Separator, Tooltip, Typography } from '@notch-ordering/ui-components';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import { useTranslation } from 'react-i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { EPaths } from '@v2/constants/EPaths';
import { useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { isEqual, omit, pick } from 'lodash';
import { ScanUnsavedChangesModal } from './ScanUnsavedChangesModal';
/**
 * Props for the ScanOverviewTopNavigation component
 *
 * @interface ScanOverviewTopNavigationProps
 * @property {boolean} [hideCloseButton=false] - Whether or not to hide the close button
 */
export interface ScanOverviewTopNavigationProps {
    hideCloseButton?: boolean,
}

/**
 * A React functional component that renders the top navigation bar for the OCR invoice scanning review page
 *
 * @function ScanOverviewTopNavigation
 * @param {ScanOverviewTopNavigationProps} props - The props for the component
 * @returns {JSX.Element} - The rendered component
 */

export const ScanOverviewTopNavigation : React.FC<ScanOverviewTopNavigationProps> = ({ hideCloseButton = false, }) => {
    // Get router and invoice data from context and store
    const { router, initialInvoice } = useOcrInvoiceContext();
    const { invoices, draftInvoice, autoCalculateChartOfAccountDetails } = useOcrInvoiceStore();

    useEffect(() => {
        if (!isEqual(draftInvoice.lineItems, initialInvoice.lineItems)) {
            autoCalculateChartOfAccountDetails();
        }
    }, []);

    // Get the index of the current invoice being reviewed and the total number of invoices
    const invoiceIndex = invoices?.results.findIndex((inv) => inv.id === draftInvoice.invoiceID);
    const currentPosition = invoiceIndex + 1;
    const totalInvoices = invoices?.total ?? 0;

    // Function to navigate to the next or previous invoice
    const navigateScan = (direction: 'next' | 'previous'): void => {
        const offset = direction === 'next' ? 1 : -1;
        const targetInvoice = invoices?.results[invoiceIndex + offset];
        if (targetInvoice) {
            router.push(EPaths.SCANS_REVIEW.replace(':invoiceKey', targetInvoice.id));
        }
    };

    // Get the translation function from the useTranslation hook
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview' });

    // State variables for showing/hiding tooltips on the previous/next buttons
    const [showCloseTooltip, setShowCloseTooltip] = React.useState(false);
    const [showPrevScanTooltip, setShowPrevScanTooltip] = React.useState(false);
    const [showNextScanTooltip, setShowNextScanTooltip] = React.useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = React.useState(false);
    const [closeButton, setCloseButton] = React.useState(false);
    const [previousButton, setPreviousButton] = React.useState(false);
    const [nextButton, setNextButton] = React.useState(false);

    function onSaveOrDiscard(): void {
        if (closeButton) {
            router.push(EPaths.UPLOADS);
        } else if (previousButton) {
            navigateScan('previous');
        } else if (nextButton) {
            navigateScan('next');
        }
    }

    function areChartOfAccountDetailsEqual(): boolean {
        const initialChartOfAccountDetails = initialInvoice.chartOfAccountDetails;
        const currentChartOfAccountDetails = draftInvoice.chartOfAccountDetails;
        const dataThatCouldHaveChanged = ['glCodeID', 'tax', 'taxCodeID', 'total'];

        if (currentChartOfAccountDetails.length !== initialChartOfAccountDetails.length) {
            return false;
        }

        for (let i = 0; i < initialChartOfAccountDetails.length; i++) {
            const initialMatchResult = initialChartOfAccountDetails[i].matchResult;
            const currentMatchResult = currentChartOfAccountDetails[i].matchResult;

            if (initialMatchResult !== currentMatchResult) {
                return false;
            }

            const initialLine = pick(initialChartOfAccountDetails[i].primaryItemData, dataThatCouldHaveChanged);
            const currentLine = pick(currentChartOfAccountDetails[i].primaryItemData, dataThatCouldHaveChanged);
            if (!isEqual(initialLine, currentLine)) {
                return false;
            }
        }

        return true;
    }

    const hasInvoiceFieldsChanged = ():boolean => {
        const omittedKeysTopLevelData = ['totalDiscount', 'subtotal', 'tax', 'total'];
        const isTopLevelDataEqual = isEqual(omit(draftInvoice.topLevelData, omittedKeysTopLevelData), omit(initialInvoice.topLevelData, omittedKeysTopLevelData));
        const areLineItemsEqual = isEqual(draftInvoice.lineItems, initialInvoice.lineItems);
        const isAutoCalculateSettingEqual = draftInvoice.isAutoCalculateOn === initialInvoice.isAutoCalculateOn;
        return !isTopLevelDataEqual || !areLineItemsEqual || !isAutoCalculateSettingEqual || !areChartOfAccountDetailsEqual();
    };

    const handleUnsavedChanges = (action: ()=>void):void => {
        const hasModifiedFields = hasInvoiceFieldsChanged();
        if (hasModifiedFields) {
            setShowUnsavedChangesModal(true);
        } else {
            action();
        }
    };

    return <>
        <div className="px-6 py-4 w-full flex justify-between items-center ">
            {/* Close button */}
            {!hideCloseButton
                && <Tooltip show={showCloseTooltip}
                    showArrow={false}
                    placement="bottom"
                    tooltipClassName="py-1.5 px-2 rounded-md"
                    trigger={<div onMouseLeave={(): void => setShowCloseTooltip(false)}
                        onMouseEnter={(): void => setShowCloseTooltip(true)}>
                        <Button onClick={():void => {
                            setCloseButton(true);
                            handleUnsavedChanges(() => {
                                router.push(EPaths.UPLOADS);
                            });
                        }}
                        variant="SUBDUED_ICON"
                        size="NO_BACKGROUND"
                        className="w-7 h-7 min-w-0  p-0 flex items-center justify-center"><CloseIcon
                                className="w-4 h-4 "/></Button></div>}>
                    <Typography as="span" variant="BASE">{tCommon('Buttons.close')}</Typography>
                </Tooltip>}
            {/* Title */}
            <Typography as="div"
                className="text-gray-600 mx-auto"
                weight="font-medium">
                {t('topNavigation.countOfScans', { currentScanIndex: currentPosition, totalScans: totalInvoices })}
            </Typography>
            {/* Next/previous buttons */}
            <div className="flex justify-end">
                {/* Previous button */}
                <Tooltip show={showPrevScanTooltip}
                    showArrow={false}
                    placement="bottom"
                    tooltipClassName="py-1.5 px-2 rounded-md"
                    trigger={<div onMouseLeave={(): void => setShowPrevScanTooltip(false)}
                        onMouseEnter={(): void => setShowPrevScanTooltip(true)}><Button
                            onClick={():void => {
                                setPreviousButton(true);
                                handleUnsavedChanges(() => {
                                    navigateScan('previous');
                                });
                            }}
                            variant="SUBDUED_ICON"
                            disabled={currentPosition === 1}
                            size="NO_BACKGROUND"
                            className="w-7 h-7 min-w-0  p-0 flex items-center justify-center">
                            <ChevronDownIcon className="w-4 h-4 rotate-180"/>
                        </Button></div>}>
                    <Typography as="span" variant="BASE">{t('topNavigation.previousBill')}</Typography>
                </Tooltip>
                {/* Next button */}
                <Tooltip show={showNextScanTooltip}
                    showArrow={false}
                    tooltipClassName="py-1.5 px-2 rounded-md"
                    placement="bottom"
                    trigger={<div onMouseLeave={(): void => setShowNextScanTooltip(false)}
                        onMouseEnter={(): void => setShowNextScanTooltip(true)}>
                        <Button onClick={():void => {
                            setNextButton(true);
                            handleUnsavedChanges(() => {
                                navigateScan('next');
                            });
                        }}
                        variant="SUBDUED_ICON"
                        disabled={currentPosition === totalInvoices}
                        size="NO_BACKGROUND"
                        className="w-7 h-7 border min-w-0  p-0 flex items-center justify-center">
                            <ChevronDownIcon className="w-4 h-4"/>
                        </Button></div>}>
                    <Typography as="span" variant="BASE">{t('topNavigation.nextBill')}</Typography>
                </Tooltip>
            </div>
        </div>
        {/* Separator */}
        <Separator/>
        <ScanUnsavedChangesModal
            isOpen={showUnsavedChangesModal}
            close={(): void => setShowUnsavedChangesModal(false)}
            onSaveOrDiscard={onSaveOrDiscard}/>
    </>;
};

import React from 'react';
import { Button, twMergeCustom, Typography } from '@notch-ordering/ui-components';
import cx from 'classnames';
import { Transition } from '@headlessui/react';
import BackIcon from '@icons/back-icon.svg';
import { useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { ScanReviewViews, useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';

export interface ScanTopNavigationProps {
    rightContent?: React.ReactNode,
    title?: string,
}

export const ScanTopNavigation : React.FC<ScanTopNavigationProps> = ({ rightContent, title = '' }) => {
    const { showFocusPanel } = useOcrInvoiceStore();
    const { setCurrentView } = useOcrInvoiceContext();
    return <div
        className={twMergeCustom(cx('top-0 sticky pt-6 pb-0 px-8 bg-white z-10', { 'border-b border-gray-200 pb-6': showFocusPanel }))}>
        <div className="flex">
            <div className="flex gap-3 items-center">
                <Button variant="SUBDUED_ICON"
                    size="NO_BACKGROUND"
                    className="p-0"
                    onClick={(): void => setCurrentView(ScanReviewViews.Overview)}>
                    <BackIcon className="w-4 h-4 ml-auto block "/>
                </Button>
                {!!title && <Transition
                    show={showFocusPanel}
                    enter="transition ease-out duration-100"
                    enterFrom="opacity-0 transform translate-y-10"
                    enterTo="opacity-1 transform translate-y-0"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100  transform translate-y-0"
                    leaveTo="opacity-0 transform translate-y-10">
                    <Typography weight="font-medium" className="m-0">{title}</Typography>
                </Transition>}
            </div>
            {!!rightContent && <div className="ml-auto flex space-x-2">
                {rightContent}
            </div>}
        </div>
    </div>;
};

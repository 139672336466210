import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useOrder from './hooks/useOrder';
import ReceivingTool from './components/ReceivingTool.jsx';
import ReceivingToolWeb from './components/ReceivingToolWeb.jsx';

const ReceivingToolContainer = ({
  params,
  isWeb,
  closeWebView,
  location: { query: { event = '' } = { event: '' } } = {
    query: { event: '' },
  },
}) => {
  const {
    order: { loading, data: { items = [] } = { items: [] } },
    feature,
  } = useOrder(params.order_urlsafe);

  if (loading || !feature.isReceivingTool || !items.length) {
    return (
      <div
        className={cx({
          'od-container': !isWeb,
          'od-container-web': isWeb,
        })}
      >
        <div className="od-loading">
          <div className="root-app-loader" />
        </div>
      </div>
    );
  }
  if (isWeb) {
    return <ReceivingToolWeb closeWebView={closeWebView} />;
  }
  return <ReceivingTool isIonicMobile={event === 'redirect'} />;
};

ReceivingToolContainer.defaultProps = {
  isWeb: false,
  closeWebView: null,
};

ReceivingToolContainer.propTypes = {
  params: PropTypes.shape({
    order_urlsafe: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    query: PropTypes.shape({
      event: PropTypes.string,
    }),
  }).isRequired,
  isWeb: PropTypes.bool,
  closeWebView: PropTypes.func,
};

export default ReceivingToolContainer;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import InlineConfirm from '../InlineConfirm.jsx';

class ModalBox extends Component {
  handleClickOutside = (evt) => {
    const { isClickOutSideDisabled, onClickClose } = this.props;

    if (!this.node.contains(evt.target) && !isClickOutSideDisabled) {
      onClickClose();
    }
  };

  render() {
    const {
      id,
      title,
      activateSave,
      loading,
      showDelete,
      customStyles,
      showHeader,
      showFooter,
      onClickSave,
      onClickDelete,
      onClickClose,
      onClickCancel,
    } = this.props;

    const saveBtnClassName = activateSave
      ? 'btn btn-primary btn-lg save-btn pull-right'
      : 'btn btn-default btn-lg save-btn pull-right';

    return (
      // ref attribute takes a callback which will be executed immediately after the
      // component is mounted.  The callback received the mounted instance of the
      // component as an argument (node, in this case). We are then using the ref
      // callback to store the reference of the DOM node as this.node.
      <div
        className="modal-box"
        id={id}
        ref={(node) => {
          this.node = node;
        }}
      >
        <div
          className="modal-box-container"
          style={customStyles}
          ref="modalContainer"
        >
          {/* Modal header */}
          {showHeader && (
            <div className="modal-box-header">
              <div className="modal-box-header-text">{title}</div>
              <button
                onClick={onClickClose}
                className="btn pull-right btn-link"
              >
                <i className="fa fa-times"></i>
              </button>
            </div>
          )}

          {/* Modal body */}
          <div className="modal-box-body">
            {this.props.children}

            {/* Footer Containing Remove Vendor and Save Buttons */}
            {showFooter && (
              <div className="modal-box-footer">
                {showDelete ? (
                  <InlineConfirm confirmedYes={onClickDelete}>
                    <span>
                      <a href="#" className="remove-vendor pull-left">
                        <i className="fa fa-trash-o" aria-hidden="true"></i>{' '}
                        Remove Vendor
                      </a>
                    </span>
                  </InlineConfirm>
                ) : (
                  <button
                    onClick={onClickCancel}
                    className="btn btn-white btn-lg pull-left"
                  >
                    <span className="btn-text">Cancel</span>
                  </button>
                )}

                <button onClick={onClickSave} className={saveBtnClassName}>
                  {loading ? (
                    <img
                      src="/static/website/images/loading-spinning-bubbles.svg"
                      alt="Loading icon"
                      width="20"
                      height="20"
                    />
                  ) : (
                    <span className="btn-text">Save</span>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ModalBox.defaultProps = {
  id: '',
  title: '',
  onClickSave: null,
  onClickDelete: null,
  onClickCancel: null,
  children: null,
  isClickOutSideDisabled: false,
};

ModalBox.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.object,
  showDelete: PropTypes.bool,
  isClickOutSideDisabled: PropTypes.bool,
  activateSave: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  customStyles: PropTypes.object.isRequired,
  showHeader: PropTypes.bool.isRequired,
  showFooter: PropTypes.bool.isRequired,

  onClickSave: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickCancel: PropTypes.func,
  onClickClose: PropTypes.func.isRequired,
};

ModalBox.defaultProps = {
  showDelete: false,
};

export default onClickOutside(ModalBox);

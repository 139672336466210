import React from 'react';
import { FiltersBadge } from '@v2/components/Shared/FiltersBadge/FiltersBadge';

export interface BillsFilterBadgeProps {
    children: React.ReactNode,
    filterType: string,
    label?: string,
    onClickClear: (key: string) => void,
}

export const BillsFilterBadge : React.FC<BillsFilterBadgeProps> = (
    {
        children,
        filterType,
        label, onClickClear,
    }
) => {
    const clearFilter = ():void => {
        onClickClear(filterType);
    };

    return <FiltersBadge clearFilter={clearFilter} label={label}>{children}</FiltersBadge>;
};

import React from 'react';
import { Input, InputProps } from '@notch-ordering/ui-components';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

export type CurrencyInputProps = Partial<Omit<InputProps, 'type' | 'onChange'>> & {
    prefix?: string,
    suffix?: string,
    onChange?: (value: NumberFormatValues) => void,
    defaultValue?: number,
    decimalScale?: number,
    decimalSeparator?: string,
    allowNegative?: boolean,
    thousandSeparator?: boolean | string,
    fixedDecimalScale?: boolean,
};

export const CURRENCY_INPUT_DEFAULT_PROPS: CurrencyInputProps = {
    prefix: '$',
    suffix: '',
    onChange: ():void => {},
    onFocus: ():void => {},
    defaultValue: 0.00,
    decimalScale: 2,
    decimalSeparator: '.',
    allowNegative: false,
    thousandSeparator: ',',
    fixedDecimalScale: true,

};
export const CurrencyInput: React.FC<CurrencyInputProps> = ({
    value,
    prefix = CURRENCY_INPUT_DEFAULT_PROPS.prefix,
    suffix = CURRENCY_INPUT_DEFAULT_PROPS.suffix,
    allowNegative = CURRENCY_INPUT_DEFAULT_PROPS.allowNegative,
    defaultValue = CURRENCY_INPUT_DEFAULT_PROPS.defaultValue,
    decimalSeparator = CURRENCY_INPUT_DEFAULT_PROPS.decimalSeparator,
    decimalScale = CURRENCY_INPUT_DEFAULT_PROPS.decimalScale,
    fixedDecimalScale = CURRENCY_INPUT_DEFAULT_PROPS.fixedDecimalScale,
    thousandSeparator = CURRENCY_INPUT_DEFAULT_PROPS.thousandSeparator,
    onChange, onFocus, ...props
}) => <NumericFormat customInput={Input}
    {...props}
    thousandSeparator={thousandSeparator}
    fixedDecimalScale={fixedDecimalScale}
    onValueChange={onChange}
    onFocus={onFocus}
    prefix={prefix}
    allowNegative={allowNegative}
    suffix={suffix}
    defaultValue={defaultValue}
    decimalSeparator={decimalSeparator}
    decimalScale={decimalScale}
    value={value}/>;

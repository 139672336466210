import moment from 'moment/moment';
import { LegacyInvoiceData } from '@v2/network/CoreAPI';
import { OFFLINE_PAYMENT_METHODS } from '@/constants/OfflinePaymentMethodTypes';
import { ADDITIONAL_FEE_TYPES } from '@/constants/AdditionalFeeTypes';
import { EAcctingPymntMethod, EAcctingPymntStatus } from '../network/CoreAPI';

/**
 * Used for accounting syncs such as for QBO.
 */
export const isAccountingPaymentMethodValid = (type: string): boolean => {
    switch (type) {
        case EAcctingPymntMethod.CARD:
        case EAcctingPymntMethod.CHECK:
            return true;
        default:
            return false;
    }
};

// this function was abstracted from invoices reducer
export const getInvoiceFormattedToSync = (order, details, currentInvoices: LegacyInvoiceData[] = [], invoiceItems: InvoiceItemSync[] = []):unknown => {
    const isDraft = !order.invoiceSentAt;
    const invoices = isDraft ? [order] : order?.invoices;
    const isUsingNotchPay = order.isConnect ?? (order.isPayFacLite && !OFFLINE_PAYMENT_METHODS.includes(order.paymentMethodType));
    const orderDeliveryFees = order.additionalFees.find((fee) => fee.feeType === ADDITIONAL_FEE_TYPES.DELIVERY) ?? { amount: 0, tax: 0 };
    const supplierReferenceId = currentInvoices.find((orderInvoice) => orderInvoice.order_id === details.orderId)?.supplier_reference_id ?? '';
    const orderItemsTotals = order.items.reduce((tempOrderItemsTotals, orderItem) => {
        tempOrderItemsTotals.subtotal += orderItem.priceTotal;
        tempOrderItemsTotals.tax += orderItem.taxAmount;
        return tempOrderItemsTotals;
    }, { subtotal: 0, tax: 0 });

    const formattedInvoices = invoices.map((invoice) => ({
        invoiceNumber: String(invoice.invoiceNumber ?? ''),
        supplierReferenceId,
        issueDate: !isDraft ? invoice.issueDate : null,
        buyer: {
            id: order.buyer.urlsafe,
            name: order.buyer.name
        },
        supplier: {
            id: order.vendorUrlsafe,
        },
        isUsingNotchPay,
        url: invoice.url ? invoice.url : null,
        paymentTerms: order.paymentsTerms,
        isDraft,
        isPrimaryInvoice: isDraft ?? invoice.isPrimaryInvoice,
        itemsTotal: {
            subTotal: isDraft ? orderItemsTotals.subtotal : invoice.itemsSubtotal,
            taxes: isDraft ? orderItemsTotals.tax : invoice.itemsTax
        },
        serviceFee: {
            subTotal: isDraft ? invoice.chefheroServicesFee : invoice.serviceFeeSubtotal,
            taxes: isDraft ? 0 : invoice.serviceFeeTax
        },
        deliveryFee: {
            subTotal: isDraft ? orderDeliveryFees.amount : invoice.deliveryFeeSubtotal,
            taxes: isDraft ? orderDeliveryFees.tax : invoice.deliveryFeeTax
        },
        invoiceTotal: {
            subTotal: invoice.subtotal,
            taxes: invoice.tax
        },
        items: invoiceItems,
        isTwoWayMatched: true,
    }));

    // Add payment to the primary invoice if it exists
    if (details.payments.length > 0) {
        const { id, status, paymentMethod, paymentMethodBrand, chargeDate, amount } = details.payments[0];
        const primaryInvoiceNumber = String(order.invoices.find((inv) => inv.isPrimaryInvoice)?.invoiceNumber ?? '');
        if (primaryInvoiceNumber) {
            const invoice = formattedInvoices.find((inv) => inv.invoiceNumber === primaryInvoiceNumber);
            if ([EAcctingPymntStatus.PAID, EAcctingPymntStatus.CHARGED, EAcctingPymntStatus.REFUNDED].includes(status) && isAccountingPaymentMethodValid(paymentMethod)) {
                invoice.payments = [
                    {
                        id,
                        invoiceNumber: primaryInvoiceNumber,
                        buyerId: order.buyer.urlsafe,
                        supplierId: order.vendorUrlsafe,
                        status: status === EAcctingPymntStatus.REFUNDED ? EAcctingPymntStatus.REFUNDED : EAcctingPymntStatus.CHARGED,
                        amount: amount || order.paidAmount, // note: AP endpoint sets amount, otherwise use legacy order data
                        paymentMethod,
                        paymentMethodBrand,
                        chargeDate: chargeDate || moment(order.paidAt).utc().format() // note: AP endpoint sets amount, otherwise use legacy order data
                    }
                ];
            }
        }
    }

    return formattedInvoices;
};
export type InvoiceItemSync = {
    name: string,
    amount: {
        subTotal: number,
        taxes: number,
    },
    external_id: string,
    glCodeID: string,
};

export const getSyncInvoiceItems = (invoice: LegacyInvoiceData):Partial<InvoiceItemSync[]> => invoice.orderSyncDetails.order.items.map((item) => ({
    name: item.name,
    amount: {
        subTotal: item.price,
        taxes: item.taxAmount,
    },
    external_id: item.external_id,
    glCodeID: item.glCodeID
}));

export const UNIX_EPOCH_START_DATE = '1970-01-01';

import { useSelector } from 'react-redux';
import { OrderData } from '@v2/types/OrderData';
import { RootState } from '..';

/**
 *
 *
 * @description Get order by supplier catalog id
 * @param supplierCatalogID Supplier catalog id coming from secret shop api response
 */
const useGetOrderBySupplierCatalogId = (supplierCatalogID: string): OrderData => {
    const { orders } = useSelector((store: RootState) => store.ordersReducer);
    const { suppliers } = useSelector((store: RootState) => store.suppliersReducer);
    const supplierUrlSafe = suppliers.find((supplier) => supplier.id === supplierCatalogID)?.supplierUrlsafeKey;
    return orders.find((o) => o.vendorUrlsafe === supplierUrlSafe);
};

export default useGetOrderBySupplierCatalogId;

import { Typography } from '@notch-ordering/ui-components';
import React from 'react';

const EditingAddOnOrderNotice = () => (
  <Typography as='div' weight='font-medium' className="checkout-editing-message">
    This order has already been sent to your vendor. You can now only add new
    items. If you wish to remove anything, please{' '}
    <a onClick={() => window.Intercom('show')} href="#">
      contact support
    </a>
    .
  </Typography>
);

export default EditingAddOnOrderNotice;

import { Typography } from '@notch-ordering/ui-components';
import React from 'react';

const OrderInfoSection = ({ heading, children }) => (
  <section className="OrderInfoSection">
    <Typography weight='font-semibold' className="OrderInfoSection__heading">{heading}</Typography>

    <div>{children}</div>
  </section>
);

OrderInfoSection.propTypes = {};

export default OrderInfoSection;

import React from 'react';
import ChevronDownIcon from '@icons/chevron-down-icon.svg';
import CloseIcon from '@icons/close-icon.svg';
import { Button, Tooltip, Typography } from '@notch-ordering/ui-components';
import cx from 'classnames';
import { LineItemCategory, LineItemFocusType, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { tCommon } from '@v2/i18n';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategory } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';

export interface ScanLineItemsFocusControlsProps {
    hasNext?: boolean,
    hasPrevious?: boolean,
    onPrevious?: () => void,
    onNext?: () => void,
}

/**
 * A set of controls used to navigate between line items while scanning an invoice.
 *
 * @param {object} props - The component props.
 * @param {boolean} [props.hasNext=true] - Whether there is a next line item to scan.
 * @param {boolean} [props.hasPrevious=false] - Whether there is a previous line item to scan.
 * @param {Function} [props.onNext] - A function to call when the "Next" button is clicked.
 * @param {Function} [props.onPrevious] - A function to call when the "Previous" button is clicked.
 * @returns {JSX.Element} - The component's rendered elements.
 */

export const ScanLineItemsFocusControls : React.FC<ScanLineItemsFocusControlsProps> = ({ hasPrevious, hasNext = true, onNext, onPrevious }) => {
    const { draftInvoice, setFocusType, setShowFocusPanel } = useOcrInvoiceStore();
    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategory: LineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, false, hasImsIntegration);

    const [prevButtonTooltip, setPrevButtonTooltip] = React.useState(false);
    const [closeButtonTooltip, setCloseButtonTooltip] = React.useState(false);
    const [nextButtonTooltip, setNextButtonTooltip] = React.useState(false);
    return <div className="flex ml-auto items-center gap-2">
        <div className="flex gap-2 items-center">
            <Tooltip show={prevButtonTooltip}
                showArrow={false}
                tooltipClassName="py-1.5 px-2 rounded-md"
                placement="bottom"
                trigger={
                    <div onMouseEnter={(): void => setPrevButtonTooltip(true)} onMouseLeave={(): void => setPrevButtonTooltip(false)}>
                        <Button
                            disabled={!hasPrevious}
                            onClick={onPrevious}
                            variant="SUBDUED_ICON"
                            size="NO_BACKGROUND"
                            className="w-7 h-7 p-0 flex items-center justify-center">
                            <ChevronDownIcon className="h-4 w-4 rotate-180"/>
                        </Button>
                    </div>
                }>
                <div className="flex items-center gap-2 justify-center">
                    <Typography as="span" variant="BASE">Previous</Typography>
                    <div className="h-4 w-4 bg-gray-650 rounded flex items-center justify-center">
                        <ChevronDownIcon className="w-3 h-3 text-gray-400 rotate-180"/></div></div>
            </Tooltip>

            <Tooltip show={nextButtonTooltip}
                showArrow={false}
                placement="bottom"
                tooltipClassName="py-1.5 px-2 rounded-md"
                trigger={
                    <div onMouseEnter={(): void => setNextButtonTooltip(true)} onMouseLeave={(): void => setNextButtonTooltip(false)}>
                        <Button disabled={!hasNext}
                            variant="SUBDUED_ICON"
                            size="NO_BACKGROUND"
                            onClick={onNext}
                            className={cx('w-7 h-7 p-0 flex items-center justify-center')}>
                            <ChevronDownIcon className="h-4 w-4 "/>
                        </Button>
                    </div>
                }>
                <div className="flex items-center gap-2 justify-center">
                    <Typography as="span" variant="BASE">Next</Typography>
                    <div className="h-4 w-4 bg-gray-650 rounded flex items-center justify-center">
                        <ChevronDownIcon className="w-3 h-3 text-gray-400"/></div></div>
            </Tooltip>

        </div>
        <div className=" w-px h-3 bg-gray-200"/>
        <Tooltip show={closeButtonTooltip}
            showArrow={false}
            tooltipClassName="py-1.5 px-2 rounded-md"
            placement="bottom"
            trigger={
                <div onMouseEnter={(): void => setCloseButtonTooltip(true)} onMouseLeave={(): void => setCloseButtonTooltip(false)}>
                    <Button variant="SUBDUED_ICON"
                        size="NO_BACKGROUND"
                        className="w-7 h-7 p-0 flex items-center justify-center"
                        onClick={(): void => {
                            setFocusType(LineItemFocusType.Required, lineItemCategory);
                            setShowFocusPanel(false);
                        }}>
                        <CloseIcon className="h-4 w-4"/>
                    </Button>
                </div>
            }>
            <div className="flex items-center gap-2 justify-center">
                <Typography as="span" variant="BASE">{tCommon('Buttons.close')}</Typography>
            </div>
        </Tooltip>

    </div>;
};

import { Modal, onMobileSetLarge, Combobox, Typography, Separator, Button, toast, ComboOption } from '@notch-ordering/ui-components';
import { t, tCommon } from '@v2/i18n';
import React, { useEffect, useState } from 'react';
import CheckIcon from '@icons/check-icon.svg';
import cx from 'classnames';
import ArrowRightIcon from '@icons/arrow-right-icon.svg';
import { ApClassSchema,
    ESyncDataType,
    SupplierMappings,
    TaxCalculationSettings,
    TaxCodeSchema,
    VendorDetails,
    updateAccountingConfiguration,
    updateQBOBuyerConfiguration,
    RutterSubsidiary } from '@v2/network/BushwhackAPI';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { AccountingConfiguration, EApPlatform, EConfigurationType } from '@notch-ordering/shared-logic';
import { getAccountingConfigurationType } from '@v2/utils/GetAccountingConfigurationType';
import { DisconnectIntegrationModal } from './DisconnectIntegrationModal';
import { logException } from '@/domains/shared/logger';

enum Tab {
    BASIC,
    MAPPING
}

type ManageIntegrationProps = {
    isOpen: boolean,
    close: () => void,
    refetch: () => void,
    onSave?: (supplierMappings: SupplierMappings) => void,
    onConfigUpdate: (config: AccountingConfiguration) => void,
    integration: AccountingConfiguration,
    integrationType: string,
    notchSuppliers: SupplierCatalogData[],
    accountingVendors: VendorDetails[],
    subsidiaries?: RutterSubsidiary[],
    supplierMappingDefaults: SupplierMappings,
    taxCodes: TaxCodeSchema[],
    accountingClasses: ApClassSchema[],
    companyName: string,
    isInitialSyncComplete: boolean,
};

export type FormData = {
    classData?: ApClassSchema,
    taxCalculationSettings: TaxCalculationSettings,
    taxCode: TaxCodeSchema,
    taxCodeNonTaxable: TaxCodeSchema,
    syncDataType: ESyncDataType,
    subsidiaryID?: string,
};

/**
 * Modal that appears if you choose Manage on Active Integrations Tab.
 */
export const ManageIntegrationModal: React.FC<ManageIntegrationProps> = ({
    isOpen = false,
    close,
    refetch,
    onSave,
    onConfigUpdate,
    integration,
    integrationType,
    notchSuppliers,
    accountingVendors,
    subsidiaries,
    supplierMappingDefaults,
    taxCodes,
    accountingClasses,
    companyName,
    isInitialSyncComplete
}) => {
    const [showDisconnectModal, setShowDisconnectModal] = useState<boolean>(false);
    const [supplierMappings, setSupplierMappings] = useState<SupplierMappings>();
    const [taxCodeOptions, setTaxCodeOptions] = useState<ComboOption[]>([]);
    const [taxCalculationSettingsOptions, setTaxCalculationSettingsOptions] = useState<ComboOption[]>([]);
    const [subsidiaryOptions, setSubsidiaryOptions] = useState<ComboOption[]>([]);
    const [syncDataTypes, setSyncDataTypes] = useState<ComboOption[]>([]);
    const [accountingVendorOptions, setAccountingVendorOptions] = useState<ComboOption[]>([]);
    const [classOptions, setClassOptions] = useState<ComboOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tab, setTab] = useState<Tab>(Tab.BASIC);

    const [formData, setFormData] = useState<FormData>({
        taxCode: {
            name: '',
            id: '',
        },
        taxCodeNonTaxable: {
            name: '',
            id: '',
        },
        taxCalculationSettings: null,
        syncDataType: null,
        classData: undefined,
        subsidiaryID: undefined,
    });

    const TAX_CALCULATION_SETTINGS_OPTIONS = [{
        value: TaxCalculationSettings.ACCOUNTING,
        label: t('Accounting.taxCalculationSettings.accountingLabel'),
        helperText: t('Accounting.taxCalculationSettings.accountingHelperText')
    },
    {
        value: TaxCalculationSettings.CUSTOM,
        label: t('Accounting.taxCalculationSettings.customLabel'),
        helperText: t('Accounting.taxCalculationSettings.customHelperText')
    }];

    const TAX_CALCULATION_SETTINGS_OPTIONS_RUTTER = [{
        value: TaxCalculationSettings.ACCOUNTING,
        label: t('Accounting.taxCalculationSettings.accountingLabel')
    }];

    const TAX_CALCULATION_SETTINGS_OPTIONS_BIG_CHIP = [{
        value: TaxCalculationSettings.CUSTOM,
        label: t('Accounting.taxCalculationSettings.customLabel'),
        helperText: t('Accounting.taxCalculationSettings.customHelperText')
    }];

    const SYNC_DATA_TYPE = [{
        value: ESyncDataType.CHART_OF_ACCOUNT_DETAILS,
        label: t('Integrations.chartOfAccountDetails'),
    },
    {
        value: ESyncDataType.LINE_ITEMS,
        label: t('Integrations.lineItems'),
    }];

    const DEFAULT_LABEL: string = t('Integrations.selectSupplier');
    const SUPPLIER_AMOUNT_REQUIRING_PADDING = 7;
    const hasManySuppliers: boolean = notchSuppliers.length > SUPPLIER_AMOUNT_REQUIRING_PADDING;
    const hideClass = [EApPlatform.MD365].includes(integration?.data?.platform) || classOptions.length === 0;

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        // Set supplier options
        const vendorOptions: ComboOption[] = accountingVendors.map((vendor) => ({ label: vendor.displayName, value: vendor.id }));
        vendorOptions.push({ label: DEFAULT_LABEL, value: null });
        setAccountingVendorOptions(vendorOptions);

        // Set information to sync
        const syncOptions: ComboOption[] = SYNC_DATA_TYPE.map((sdt) => ({ label: sdt.label, value: sdt.value }));
        setSyncDataTypes(syncOptions);

        // Set tax options
        const tcOptions: ComboOption[] = taxCodes.map((tc) => ({ label: tc.name, value: tc.id }));
        setTaxCodeOptions(tcOptions);

        if (integration?.type === EConfigurationType.ApRutter) {
            setTaxCalculationSettingsOptions(TAX_CALCULATION_SETTINGS_OPTIONS_RUTTER);
        } else if (integration?.type === EConfigurationType.BigChip) {
            setTaxCalculationSettingsOptions(TAX_CALCULATION_SETTINGS_OPTIONS_BIG_CHIP);
        } else {
            const tcsOptions: ComboOption[] = TAX_CALCULATION_SETTINGS_OPTIONS.map((tcs) => ({ label: tcs.label, value: tcs.value }));
            setTaxCalculationSettingsOptions(tcsOptions);
        }

        if (subsidiaries?.length > 0) {
            const sOptions: ComboOption[] = subsidiaries.map((sub) => ({ label: sub.name, value: sub.id }));
            setSubsidiaryOptions(sOptions);
        } else {
            setSubsidiaryOptions([]);
        }

        // Set class options
        const accountClassOptions: ComboOption[] = accountingClasses.map((accountingClass) => ({ label: accountingClass.name, value: accountingClass.id }));
        setClassOptions(accountClassOptions);

        // Set form data
        setFormData({ taxCode: {
            name: integration?.data?.taxCode?.name,
            id: integration?.data?.taxCode?.id,
        },
        taxCodeNonTaxable: {
            name: integration?.data?.taxCodeNonTaxable?.name,
            id: integration?.data?.taxCodeNonTaxable?.id,
        },
        taxCalculationSettings: integration?.data?.taxCalculationSettings ?? TaxCalculationSettings.ACCOUNTING,
        syncDataType: integration?.data?.syncDataType ?? ESyncDataType.CHART_OF_ACCOUNT_DETAILS,
        subsidiaryID: integration?.data?.subsidiaryID,
        classData: integration?.data?.classData ? {
            name: integration.data.classData.name,
            id: integration.data.classData.id,
        } : undefined, });
    }, [isOpen, integration, taxCodes]);

    const accessToken = integration?.data?.accessToken;
    const connectionID = integration?.data.connectionID;

    function onSupplierMappingUpdate(supplierID: string, option: ComboOption): void {
        const updatedMappings: SupplierMappings = { ...supplierMappings, [supplierID]: option.value };
        setSupplierMappings(updatedMappings);
    }

    function getSupplierDefaultMappingValue(supplierID: string): string {
        const value: string = supplierMappingDefaults[supplierID] ?? null;
        return value;
    }

    function onSaveModal(onUpdate: (config: AccountingConfiguration) => void): void {
        setIsLoading(true);
        if (integration?.type === EConfigurationType.QBO) {
            updateQBOBuyerConfiguration(
                integration?.id,
                { taxCode: formData.taxCode,
                    taxCodeNonTaxable: formData.taxCodeNonTaxable,
                    taxCalculationSettings: formData.taxCalculationSettings,
                    syncDataType: formData.syncDataType,
                    classData: formData.classData }
            )
                .then(() => {
                    setIsLoading(false);
                    close();
                    toast.show({
                        message: <span className="font-semibold">{t('Integrations.savedSuccessfully')}</span>,
                        icon: <CheckIcon />,
                        showClose: false
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    logException(error);
                });
        } else if ([EApPlatform.NETSUITE, EApPlatform.XERO, EApPlatform.QBD].includes(integration?.data?.platform)) {
            updateAccountingConfiguration(
                integration?.id,
                { data: { ...integration.data,
                    accessToken,
                    connectionID,
                    taxCode: {
                        ...formData.taxCode,
                        taxable: true,
                        description: formData.taxCode.name
                    },
                    taxCodeNonTaxable: {
                        ...formData.taxCodeNonTaxable,
                        taxable: false,
                        description: formData.taxCodeNonTaxable.name,
                    },
                    taxCalculationSettings: formData.taxCalculationSettings,
                    syncDataType: formData.syncDataType,
                    ...(formData.subsidiaryID && { subsidiaryID: formData.subsidiaryID }),
                    classData: formData.classData } },
                integration.data.platform,
                integration.data.accessToken,
                false,
                onUpdate
            )
                .then(() => {
                    setIsLoading(false);
                    close();
                    toast.show({
                        message: <span className="font-semibold">{t('Integrations.savedSuccessfully')}</span>,
                        icon: <CheckIcon />,
                        showClose: false
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    logException(error);
                });
        } else if ([EApPlatform.MD365].includes(integration?.data?.platform)) {
            updateAccountingConfiguration(
                integration?.id,
                { data: { ...integration.data,
                    accessToken,
                    connectionID,
                    taxCode: {
                        ...formData.taxCode,
                        taxable: true,
                        description: formData.taxCode.name
                    },
                    taxCodeNonTaxable: {
                        ...formData.taxCodeNonTaxable,
                        taxable: false,
                        description: formData.taxCodeNonTaxable.name,
                    },
                    taxCalculationSettings: formData.taxCalculationSettings,
                    syncDataType: formData.syncDataType } },
                integration.data.platform,
                integration.data.accessToken,
                false,
                onUpdate
            )
                .then(() => {
                    setIsLoading(false);
                    close();
                    toast.show({
                        message: <span className="font-semibold">{t('Integrations.savedSuccessfully')}</span>,
                        icon: <CheckIcon />,
                        showClose: false
                    });
                })
                .catch((error) => {
                    setIsLoading(false);
                    logException(error);
                });
        }
        if (onSave) {
            onSave(supplierMappings);
        }
    }

    return <>
        <Modal
            title={t('Integrations.manageIntegration', { integrationType })}
            description={companyName}
            descriptionClassName="pt-2"
            overflowAuto
            modalSize={onMobileSetLarge('MEDIUM')}
            desktopModalWidth="w-[536px]"
            isOpen={isOpen}
            close={close}>
            {isInitialSyncComplete
                ? <>
                    <div className="flex items-center p-0 flex-row justify-start lg:ml-8 ml-6 w-auto gap-x-6">
                        <Button
                            variant="TEXT"
                            size="NO_BACKGROUND"
                            heightPadding="pb-3"
                            widthPadding="lg:px-0 px-2"
                            className="w-fit m-0"
                            onClick={(): void => {
                                setTab(Tab.BASIC);
                            }}>
                            <Typography as="span" weight={tab === Tab.BASIC ? 'font-semibold' : 'font-medium'}>
                                {tCommon('Buttons.basic')}
                            </Typography>
                        </Button>
                        <Button
                            variant="TEXT"
                            size="NO_BACKGROUND"
                            heightPadding="pb-3"
                            widthPadding="px-0"
                            className="w-fit m-0"
                            onClick={(): void => {
                                setTab(Tab.MAPPING);
                            }}>
                            <Typography as="span" weight={tab === Tab.MAPPING ? 'font-semibold' : 'font-medium'}>
                                {t('Integrations.mapping')}
                            </Typography>
                        </Button>
                    </div>
                    {tab === Tab.BASIC ? <div className="ml-8 w-[37px] h-px bg-gray-700" /> : <div className="lg:ml-[92px] ml-[106px] w-[59px] h-px bg-gray-700" />}
                    <Separator />
                    <div className="overflow-auto h-full">
                        {tab === Tab.BASIC
                            ? <>
                                <div className="flex flex-col w-full pb-8">
                                    <div className="flex flex-col w-full mt-8 px-8">
                                        <Typography className="mb-1" weight="font-medium">{tCommon('Labels.infoToSync')}</Typography>
                                        {syncDataTypes.length > 0 && <Combobox
                                            variant="SMALL"
                                            onChange={(v): void => setFormData({ ...formData, syncDataType: v.value as ESyncDataType })}
                                            width={'w-full'}
                                            options={syncDataTypes}
                                            value={formData?.syncDataType}/>}
                                        <Typography className="text-gray-600 mt-1 mb-6">{t('Integrations.infoToSyncDescription')}</Typography>
                                    </div>
                                    {!hideClass && <div className="px-8">
                                        <Typography className="mb-1" weight="font-medium">{tCommon('Labels.class')}</Typography>
                                        <div className={'w-full flex flex-row justify-between items-center mb-8'}>
                                            <Combobox
                                                variant="SMALL"
                                                value={formData.classData?.id ?? ''}
                                                options={classOptions}
                                                width={'w-full'}
                                                placeholder="Select"
                                                onChange={(value: ComboOption): void => setFormData({ ...formData, classData: { name: value.label, id: value.value } })} />
                                        </div>
                                    </div>}
                                    <Separator />
                                    <Typography className="mt-8 mb-6 px-8" weight="font-semibold">{t('Integrations.taxConfiguration')}</Typography>
                                    <div className="flex flex-row gap-x-3 px-8">
                                        <div className="flex flex-col w-full">
                                            <Typography className="mb-1" weight="font-medium">{tCommon('Labels.taxCode')}</Typography>
                                            {taxCodeOptions.length > 0 && <Combobox
                                                variant="SMALL"
                                                value={formData?.taxCode?.id}
                                                width={'w-full'}
                                                optionsClassName="bottom-full"
                                                options={taxCodeOptions}
                                                onChange={(value: ComboOption): void => setFormData({ ...formData, taxCode: { name: value.label, id: value.value } })} />}
                                        </div>
                                        <div className="flex flex-col w-full">
                                            <Typography className="mb-1" weight="font-medium">{t('Integrations.nonTaxable')}</Typography>
                                            {taxCodeOptions.length > 0 && <Combobox
                                                variant="SMALL"
                                                value={formData?.taxCodeNonTaxable?.id}
                                                width={'w-full'}
                                                optionsClassName="bottom-full"
                                                options={taxCodeOptions}
                                                onChange={(value: ComboOption): void => setFormData({ ...formData, taxCodeNonTaxable: { name: value.label, id: value.value } })} />}
                                        </div>
                                    </div>
                                    {subsidiaryOptions.length > 0 && <Typography className="mt-6 mb-1 px-8" weight="font-medium">{'Subsidiary'}</Typography>}
                                    {subsidiaryOptions.length > 0 && <div className="px-8">
                                        <Combobox
                                            variant="SMALL"
                                            onChange={(v): void => setFormData({ ...formData, subsidiaryID: v.value })}
                                            width={'w-full'}
                                            options={subsidiaryOptions}
                                            value={formData?.subsidiaryID}/>
                                    </div>}
                                    <Typography className="mt-6 mb-1 px-8" weight="font-medium">{t('Integrations.defaultTaxRate')}</Typography>
                                    {taxCalculationSettingsOptions.length > 0 && <div className="px-8">
                                        <Combobox
                                            variant="SMALL"
                                            onChange={(v): void => setFormData({ ...formData, taxCalculationSettings: TaxCalculationSettings[v.value] })}
                                            width={'w-full'}
                                            options={taxCalculationSettingsOptions}
                                            value={formData?.taxCalculationSettings}/>
                                    </div>}
                                    <Typography className="text-gray-600 mt-1 mb-0 px-8">{t('Integrations.chooseTaxConfiguration')}</Typography>
                                </div>
                            </>
                            : <div className={cx('min-h-[400px] px-8', {
                                'pb-28': hasManySuppliers,
                                'pb-6': !hasManySuppliers,
                            })}>
                                <div className="flex flex-row justify-between mt-8">
                                    <Typography className="m-0 w-1/2" weight="font-medium">{t('Integrations.notchName')}</Typography>
                                    <Typography className="m-0 w-1/2 ml-12" weight="font-medium">{t('Integrations.integrationsName', { integrationType })}</Typography>
                                </div>
                                {
                                    notchSuppliers.map((supplier: SupplierCatalogData, index: number) => {
                                        const isLastTwo = notchSuppliers.length > 6 && (index + 1 === notchSuppliers.length || index + 1 === notchSuppliers.length - 1);
                                        const isFirst = index === 0;
                                        return <div key={index} className="flex flex-col">
                                            {!isFirst && <Separator />}
                                            <div className="my-4 flex flex-row items-center justify-between gap-x-6">
                                                <div className="w-1/2 flex flex-row justify-between gap-x-3 flex-shrink-0 flex-grow-0" >
                                                    <Typography className="truncate m-0">
                                                        {supplier.supplierName}
                                                    </Typography>
                                                    <ArrowRightIcon className="text-gray-400 w-5 h-5" />
                                                </div>
                                                <div className="w-1/2">
                                                    <div className="flex flex-row justify-center items-center gap-x-6">
                                                        {!isLastTwo && <Combobox
                                                            variant="SMALL"
                                                            onChange={(option: ComboOption): void => {
                                                                onSupplierMappingUpdate(supplier.supplierUrlsafeKey, option);
                                                            }}
                                                            options={accountingVendorOptions}
                                                            placeholder={t('Integrations.selectSupplier')}
                                                            value={getSupplierDefaultMappingValue(supplier.supplierUrlsafeKey)}/>}
                                                        {isLastTwo && <Combobox
                                                            variant="SMALL"
                                                            onChange={(option: ComboOption): void => {
                                                                onSupplierMappingUpdate(supplier.supplierUrlsafeKey, option);
                                                            }}
                                                            options={accountingVendorOptions}
                                                            optionsClassName="bottom-full"
                                                            placeholder={t('Integrations.selectSupplier')}
                                                            value={getSupplierDefaultMappingValue(supplier.supplierUrlsafeKey)}/>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>;
                                    })
                                }
                            </div>}
                    </div>
                    <div className="sticky bottom-0 bg-white">
                        <Separator />
                        <div className="flex flex-row justify-between px-8 mt-6">
                            <Button
                                variant="DESTRUCTIVE"
                                size="SMALL"
                                onClick={():void => {
                                    setShowDisconnectModal(true);
                                }}>
                                {tCommon('Buttons.disconnect')}
                            </Button>
                            <div className="flex flex-row gap-x-3">
                                <Button
                                    variant="TERTIARY_FILLED"
                                    size="SMALL"
                                    onClick={close}>
                                    {tCommon('Buttons.cancel')}
                                </Button>
                                <Button
                                    variant="SECONDARY"
                                    loading={isLoading}
                                    size="SMALL"
                                    onClick={(): void => { onSaveModal(onConfigUpdate); }}>
                                    {tCommon('Buttons.save')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
                : <div>
                    <div className="flex flex-col items-center w-full">
                        <Typography className="m-0 w-2/3 pb-8" weight="font-medium">{t('Integrations.initialSyncDesc', { integrationType })}</Typography>
                    </div>
                </div>
            }
        </Modal>
        <DisconnectIntegrationModal
            isOpen={showDisconnectModal}
            close={(): void => { setShowDisconnectModal(false); }}
            refetch={refetch}
            disconnectSuccess={(): void => { close(); }}
            accessToken={accessToken}
            platform={getAccountingConfigurationType(integration)}
            integrationType={integrationType} />
    </>;
};

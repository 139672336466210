import React from 'react';
import AddCustomItem from './AddCustomItem.jsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  showCart,
  toggleAddCustomItemModal,
} from '../../../actions/cartActions';
import { createCustomItem } from '../../../actions/orderActions';

export const initialState = {
  name: '',
  description: '',
  price: '',
  quantity: 1,
};

const AddCustomItemContainer = () => {
  const {
    isOpen = false,
    supplier = '',
    urlsafe,
  } = useSelector((state) => state.cartReducer.addCustomItemModal);
  const { isLoading = false } = useSelector(
    (state) => state.ordersReducer.customItems
  );
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(toggleAddCustomItemModal(false, ''));
  };

  const onAccept = async (data) => {
    try {
      closeModal();
      dispatch(showCart(true));
      await dispatch(createCustomItem(data, urlsafe));
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <AddCustomItem
      isOpen={isOpen}
      supplierName={supplier}
      onClose={closeModal}
      onAccept={onAccept}
      isLoading={isLoading}
    />
  );
};

AddCustomItemContainer.propTypes = {};

export default AddCustomItemContainer;

import PropTypes from "prop-types";
import React, { Component } from "react";

import DisplayUnit from "../../DisplayUnit.jsx";
import Utils from "../../../utils";
import { BUCKET_ASSETS_STATIC_URL } from "@v2/constants/Assets";

export default class PreOrderItem extends Component {
  onImageError = (event) => {
    event.target.src = `${BUCKET_ASSETS_STATIC_URL}images/your-item-market-placeholder.png`;
  };

  render() {
    const { orderItem } = this.props;

    return (
      <tr className="pre-order-item">
        <td>
          <span className="pre-order-item-quantity">
            {orderItem.quantity} x &nbsp;
          </span>
          <strong>
            <DisplayUnit
              unitName={orderItem.unitName}
              unitDescription={orderItem.unitDescription}
            />
          </strong>
        </td>
        <td>
          <img
            src={
              orderItem.genericItem
                ? orderItem.genericItem.imageURL
                : Utils.getBackupImage(orderItem.productCode)
            }
            onError={this.onImageError}
            height="50px"
            style={{ maxWidth: "60px", marginRight: "5px" }}
          />
          <span className="pre-order-item-name">{orderItem.name}</span>
        </td>
        <td>
          <div className="pre-order-label">48HR notice</div>
        </td>
      </tr>
    );
  }
}

PreOrderItem.propTypes = {
  orderItem: PropTypes.object.isRequired,
};

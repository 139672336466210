import PropTypes from "prop-types";
import React, { Component } from "react";

import GoogleMap from "../../general/GoogleMap.jsx";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";

export default class LocationsBox extends Component {
  onClickEdit = () => {
    this.props.onClickEdit();
  };

  render() {
    const { location, onClickEdit } = this.props;

    return (
      <div className="locations-box">
        <div className="locations-box-map">
          <GoogleMap
            geocode={location.geocode}
            width={"100%"}
            height={"100%"}
            zoom={13}
          />
        </div>
        <div className="locations-box-address">
          <div className="title">SHIPPING LOCATION</div>
          <p>{location.name}</p>
          {location.line2 ? (
            <p>
              {location.line1}, Unit {location.line2}
            </p>
          ) : (
            <p>{location.line1}</p>
          )}
          <p>
            {location.city}, {location.state}, {location.zip}
          </p>
          <p>{location.country}</p>
          <button
            className="btn btn-link change-btn"
            onClick={this.onClickEdit}
          >
            <img src={`${BUCKET_ASSETS_URL}location-icon.svg`} alt="location" />
            Edit Location
          </button>
        </div>
        <div className="locations-box-instructions">
          <div>
            <div className="title">DELIVERY INSTRUCTIONS</div>
            {location.driveInstructions ? (
              <p>{location.driveInstructions}</p>
            ) : (
              <p>None</p>
            )}
            <button
              className="btn btn-link change-btn"
              onClick={this.onClickEdit}
            >
              <img
                src={`${BUCKET_ASSETS_URL}edit-icon.svg`}
                alt="edit delivery notes"
              />
              Edit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

LocationsBox.propTypes = {
  location: PropTypes.object.isRequired,
  onClickEdit: PropTypes.func,
};

import React, { useEffect, useState } from 'react';
import SearchIcon from '@icons/search-icon.svg';
import SearchFilterButton from '@v2/components/Shared/Search/SearchFilterButton';
import CancelButton from '@icons/close-times-icon.svg';
import CartButton from '@v2/components/Shared/CartButton/CartButton';
import { tCommon } from '@v2/i18n';
import { twMergeCustom } from '@notch-ordering/ui-components';
import cx from 'classnames';

export interface Props {
    onChange: (query: string) => void,
    showCartIcon?: boolean,
    showFilterBtn?: boolean,
    itemsInCart?: number,
    className?: string,
    onClickFiltersBtn?: () => void,
    placeholder?: string,
    onFocus?: () => void,
}

export const SearchPanel: React.FC<Props> = ({
    onChange,
    showCartIcon,
    showFilterBtn = true,
    itemsInCart,
    className,
    onClickFiltersBtn,
    placeholder = tCommon('search'),
    onFocus,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const hasItemsInCart = itemsInCart > 0;
    const clearQuery = (): void => {
        setSearchQuery('');
        setIsFocused(false);
    };
    const displayCartIcon = !isFocused && showCartIcon;
    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Escape') {
            clearQuery();
        }
    };
    useEffect(() => {
        setIsFocused(searchQuery !== '');
        onChange(searchQuery);
    }, [searchQuery]);
    return (
        <div className={twMergeCustom('flex w-100 gap-2', className)}>
            <div className="flex flex-col grow">
                <div className={twMergeCustom(cx('relative rounded-lg lg:w-full h-10 border border-gray-400', { 'border-teal-250 border shadow-z6 outline-none': isFocused }))}>
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon className="inline-flex w-4 h-4"/>
                    </div>

                    <input
                        onFocus={(): void => {
                            setIsFocused(true);
                            if (onFocus) {
                                onFocus();
                            }
                        }}
                        type="text"
                        onChange={(e): void => {
                            setSearchQuery(e.target.value);
                        }}
                        value={searchQuery}
                        className={'h-full  block w-full pl-10 focus:border-teal-250  outline-none pr-10 lg:text-2 text-3 font-regular font-body rounded-lg'}
                        placeholder={placeholder}
                        onKeyDown={onKeyDown}
                        onBlur={(): void => {
                            if (searchQuery === '') {
                                setTimeout(() => setIsFocused(false));
                            }
                        }}/>
                    {isFocused && searchQuery.length > 0 && (
                        <div
                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer "
                            onClick={(): void => clearQuery()}
                            role={'button'}>
                            <CancelButton className="inline-flex w-4 h-4"/>
                        </div>
                    )}
                </div>
            </div>
            <div className="flex ">
                <div className="flex  gap-2">

                    {displayCartIcon && (
                        <CartButton
                            showCounter={hasItemsInCart}
                            isClear={!hasItemsInCart}/>
                    )}
                    {showFilterBtn
                    && <SearchFilterButton
                        hideFilterText={showCartIcon}
                        hideBorderButton={showCartIcon}
                        onClick={onClickFiltersBtn}/>}
                </div>
            </div>
        </div>
    );
};

export default SearchPanel;

import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { getOcrInvoices, InvoiceQueryParams, InvoicesResults, InvoicesSearchParams } from '@v2/network/FalsePromiseApi';
import { fetchSingleOrder } from '@/api/DataAPI';

export const FETCH_OCR_INVOICES_QUERY_KEY = 'FETCH_OCR_INVOICES_QUERY_KEY';
export type TUseGetOcrInvoicesQuery = {
    searchParams: Partial<InvoicesSearchParams>,
    queryParams: Partial<InvoiceQueryParams>,
    queryOptions?: UseQueryOptions<InvoicesResults>,
};
export const useGetOcrInvoices = ({ searchParams, queryParams, queryOptions }: TUseGetOcrInvoicesQuery): UseQueryResult<InvoicesResults> => useQuery([FETCH_OCR_INVOICES_QUERY_KEY, queryParams, searchParams], async () => {
    const ocrInvoices = await getOcrInvoices(searchParams, queryParams);
    if (ocrInvoices.results.length > 0) {
        await Promise.all(ocrInvoices.results.map(async (invoice) => {
            try {
                if (invoice.orderUrlsafeKey) {
                    const order = await fetchSingleOrder(invoice.orderUrlsafeKey);
                    invoice.orderNumber = order.data?.id;
                }
            } catch (e) {
                invoice.orderNumber = null;
            }
        }));
    }
    return ocrInvoices;
}, { enabled: !!searchParams.buyerUrlsafeKey, ...queryOptions });

import { changeRoute } from '../../../actions/routerActions';
import { toastr } from 'react-redux-toastr';
import { batch } from 'react-redux';
import { logException } from '../../../domains/shared/logger';

// #region Action Types
export const GO_TO_MY_ORDERS = 'GO TO MY ORDERS';
export const GO_TO_MY_ORDERS_SUCCESS = 'GO TO MY ORDERS SUCCESS';
export const GO_TO_MY_ORDERS_FAILED = 'GO TO MY ORDERS FAILED';
// #endregion Action Types

// #region Actions
/**
 * Handles going to My Orders page
 */
export const goToMyOrders = () => {
  return (dispatch) => {
    try {
      batch(() => {
        // Executes both of the dispatch's together and avoids extra render of app
        dispatch(changeRoute('/orders/'));
        dispatch({ type: GO_TO_MY_ORDERS_SUCCESS });
      });
    } catch (error) {
      handleCatch(GO_TO_MY_ORDERS_FAILED, 'goToMyOrders', error, dispatch);
    }
  };
};
// #endregion Actions

// #region Helper methods
/**
 * Handle Catch method in case Action is failed to execute.
 *
 * @param {string} type Action Type
 * @param {string} methodname Error occured in method name
 * @param {object} error Error object
 * @param {Function} dispatch Redux dispatch function
 */
const handleCatch = (type, methodname, error, dispatch) => {
  dispatch({ type });

  let errorMessage = '';
  if (!error.response)
    errorMessage = 'Detected a connection problem, please refresh this page';
  else
    errorMessage =
      (((error || {}).response || {}).data || {}).message || 'Please try again';

  toastr.error(`Error: ${errorMessage}`);
  // eslint-disable-next-line no-console
  console.error(`An Error occured with ${methodname} ${error}`);
  logException(error);
};
// #endregion Helper methods

import PropTypes from 'prop-types';
import React from 'react';
import Utils from '../../../utils';

import DeliveryFeeLineItem from '../cart_orders/DeliveryFeeLineItem.jsx';

import CartOrderItem from './CartOrderItem.jsx';

const CartOrderItems = ({
  orderItems,
  orderQualifiesForFreeDel,
  vendor,
  ...props
}) => {
  const shouldShowDelFee =
    Utils.hasVendorMinimumOrderAmountAndDeliveryFee(vendor);

  return (
    <div className="cart-order-items">
      {orderItems.map((orderItem, index) => {
        return (
          <CartOrderItem
            key={orderItem.sourceUrlsafe}
            orderItemIndex={index}
            orderItem={orderItem}
            {...props}
          />
        );
      })}

      {shouldShowDelFee && (
        <DeliveryFeeLineItem
          orderQualifiesForFreeDel={orderQualifiesForFreeDel}
          vendor={vendor}
          {...props}
        />
      )}
    </div>
  );
};

CartOrderItems.propTypes = {
  orderItems: PropTypes.array.isRequired,
  orderQualifiesForFreeDel: PropTypes.bool.isRequired,
};

export default CartOrderItems;

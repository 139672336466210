import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { LineItem, OcrMatchedLineItem, useOcrInvoiceStore, getLineItemFieldByIndex, LineItemCategory, ELineItemPrecision, valueToFixedNumber } from '@notch-ordering/shared-logic';
import { Typography, Popover, Button, Input, Separator, Combobox } from '@notch-ordering/ui-components';
import LowConfidenceScanIcon from '@icons/low-confidence-scan-icon.svg';
import EllipsisIcon from '@icons/ellipsis-icon.svg';
import TrashIcon from '@icons/trash-icon.svg';
import { tNamespace } from '@v2/i18n';
import { mergeClassNames } from '@v2/utils/Helpers';
import { TaxCalculationSettings } from '@notch-ordering/shared-logic/dist/src/ocr/network/OcrLoadNetwork';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategory, getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { CurrencyInput } from './InputCurrency';

const lowConfidenceScanIcon = <LowConfidenceScanIcon className={cx('w-4 h-4')}/>;

interface Props {
    lineItemPrimaryData: LineItem,
    lineItems: OcrMatchedLineItem[],
    reactKey: string,
    onBlur: () => void,
    hasErrorFieldIndex: (index: number, field: string) => boolean,
    hasLowConfidenceFieldIndex: (index: number, field: string) => boolean,
    handleOnFocusField: (e: React.FocusEvent<HTMLInputElement>) => void,
    updateFormData: <T extends keyof LineItem>(reactKey: string, lineItemKey: T, value: LineItem[T]) => void,
    glCodeOptions: { label: string, value: string }[],
    index: number,
}

/**
 React component for displaying and editing fee line item details.
 *
 @returns JSX.Element
 */
export const TaxLineItem = (
    {
        lineItemPrimaryData,
        lineItems,
        reactKey,
        onBlur,
        hasErrorFieldIndex,
        hasLowConfidenceFieldIndex,
        handleOnFocusField,
        updateFormData,
        glCodeOptions,
        index,
    }: Props
): JSX.Element => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview.LineItems' });
    const {
        validateForm,
        removeLineItem,
        draftInvoice,
    } = useOcrInvoiceStore();
    const isGlCodeEnabled = draftInvoice?.isQBOEnabled
        && draftInvoice?.topLevelData?.taxCalculationSettings === TaxCalculationSettings.CUSTOM;

    const { selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);

    // For this line item we ignore chart of accounts because they get calculated
    const lineItemCategory: LineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, true, hasImsIntegration);

    // For this line item we include chart of accounts
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);

    return (
        <div className="font-body">
            <div className="px-8 flex items-center justify-between pt-10 pb-2">
                <Typography weight="font-bold" className="m-0">{index + 1}</Typography>
                {lineItems.length > -1 && <Popover
                    className="w-44"
                    button=
                        {<Button
                            variant="TERTIARY_FILLED"
                            className="bg-white flex justify-center items-center p-0 min-w-[32px] w-8 h-8"
                            size="SMALL"
                            stopPropagation={false}>
                            <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                                <EllipsisIcon className="h-4 w-4"/>
                            </div>
                        </Button>}
                    items={[
                        {
                            label: <Typography className="flex gap-3 items-center m-0"> <TrashIcon
                                className="w-4 h-4"/> {t('deleteLineItem')}</Typography>,
                            className: 'text-red-300',
                            onClick: (): void => {
                                removeLineItem(reactKey, lineItemCategory);
                                validateForm(lineItemCategoryToValidate);
                            },
                        },
                    ]}/>}
            </div>
            <div className={mergeClassNames('px-8 pt-1 pb-10 flex flex-col gap-2', {
                'lg:flex-row': !isGlCodeEnabled,
            })}>
                <div className={mergeClassNames('flex gap-2', {
                    'lg:w-2/3 lg:flex-grow': !isGlCodeEnabled,
                })}>
                    <Input variant="ADVANCED_ALTERNATIVE"
                        id={getLineItemFieldByIndex(index, 'name')}
                        onChange={(e): void => updateFormData(reactKey, 'name', e.target.value)}
                        value={lineItemPrimaryData?.name}
                        onFocus={handleOnFocusField}
                        isWarning={hasErrorFieldIndex(index, 'name')}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'name') ? lowConfidenceScanIcon : undefined}
                        onBlur={onBlur}
                        label={t('ProductFields.name')}/>

                    <Input variant="ADVANCED_ALTERNATIVE"
                        id={getLineItemFieldByIndex(index, 'sku')}
                        isWarning={hasErrorFieldIndex(index, 'sku')}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'sku') ? lowConfidenceScanIcon : undefined}
                        onChange={(e): void => updateFormData(reactKey, 'sku', e.target.value)}
                        onFocus={handleOnFocusField}
                        value={lineItemPrimaryData?.sku}
                        onBlur={onBlur}
                        label={t('ProductFields.sku')}/>
                </div>
                <div className={mergeClassNames('flex gap-2', {
                    'lg:w-1/3 lg:flex-grow': !isGlCodeEnabled
                })}>
                    <CurrencyInput variant="ADVANCED_ALTERNATIVE"
                        id={getLineItemFieldByIndex(index, 'unitPrice')}
                        decimalScale={5}
                        fixedDecimalScale={false}
                        onChange={(values): void => {
                            updateFormData(reactKey, 'unitPrice', valueToFixedNumber(values.floatValue ?? 0, ELineItemPrecision.UNIT_PRICE));
                        }}
                        value={lineItemPrimaryData?.unitPrice}
                        onBlur={onBlur}
                        isWarning={hasErrorFieldIndex(index, 'unitPrice')}
                        onFocus={handleOnFocusField}
                        inputIcon={hasLowConfidenceFieldIndex(index, 'unitPrice') ? lowConfidenceScanIcon : undefined}
                        label={'Amount'}/>
                    {isGlCodeEnabled && <Combobox
                        id={getLineItemFieldByIndex(index, 'glCodeID')}
                        onChange={(value): void => updateFormData(reactKey, 'glCodeID', value.value)}
                        onFocus={():void => {
                            handleOnFocusField({ target: { id: getLineItemFieldByIndex(index, 'glCodeID') } } as React.FocusEvent<HTMLInputElement>);
                        }}
                        options={glCodeOptions}
                        isWarning={hasErrorFieldIndex(index, 'glCodeID')}
                        onBlur={onBlur}
                        value={lineItemPrimaryData?.glCodeID}
                        label={t('ProductFields.glCode')}
                        variant="ADVANCED_ALTERNATIVE"/>
                    }
                </div>
            </div>
            {index !== lineItems.length - 1 && <Separator/>}
        </div>
    );
};

import React from 'react';

import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { ScanReviewImageControls } from '@v2/components/ScanReview/ScanReviewImage/ScanReviewImageControls';
import cx from 'classnames';

export interface ScanReviewImageProps {
    url: string,
    pageNumber: number,
    totalPages: number,
}

const allowedRotations = [0, 90, 180, 270];
const rotationTwClasses: Record<typeof allowedRotations[number], string> = {
    0: 'rotate-0',
    90: '-rotate-90',
    180: '-rotate-180',
    270: '-rotate-[270deg]',
};

export const ScanReviewImage: React.FC<ScanReviewImageProps> = ({ url, pageNumber, totalPages }) => {
    const [rotate, setRotate] = React.useState(0);

    const rotateImage = (): void => {
        const newRotate = rotate + 90;
        if (allowedRotations.includes(newRotate)) {
            setRotate(newRotate);
        } else {
            setRotate(allowedRotations[0]);
        }
    };

    return <div data-testid="scan-review-image"
        className="max-h-full max-w-full bg-gray-600 relative flex flex-col m-8 aspect-square rounded-lg">
        <TransformWrapper centerOnInit={true} centerZoomedOut={true} wheel={{ disabled: true }}>
            {({
                zoomIn,
                zoomOut,
            }):React.ReactNode => (
                <>
                    <TransformComponent wrapperClass="w-full h-full" contentClass="w-full h-full">
                        <img className={cx('w-full h-full object-scale-down', rotationTwClasses[rotate])}
                            src={url}
                            alt="Scan review"/>
                    </TransformComponent>
                    <ScanReviewImageControls rotate={rotateImage}
                        zoomIn={zoomIn}
                        zoomOut={zoomOut}
                        url={url}
                        pageNumber={pageNumber}
                        totalPages={totalPages}/>
                </>
            )}
        </TransformWrapper>

    </div>;
};

import RefreshIcon from '@icons/refresh-icon.svg';
import React from 'react';
import { Button, Separator, Typography } from '@notch-ordering/ui-components';
import { AccountingConfiguration, ApClassSchema, EApPlatform, EErpPlatform, TaxCodeSchema } from '@notch-ordering/shared-logic';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import { RutterSubsidiary, SupplierMappings, VendorDetails } from '@v2/network/BushwhackAPI';
import cx from 'classnames';
import { t } from '@v2/i18n';
import { IntegrationDisplayData } from './IntegrationsUtils';
import { IntegrationsMoreButton } from './IntegrationsMoreButton';

type Props = {
    integration?: AccountingConfiguration,
    refetch?: () => void,
    onSave?: (supplierMappings: SupplierMappings) => void,
    onConfigUpdate?: (config: AccountingConfiguration) => void,
    data: IntegrationDisplayData,
    integrationPlatform?: EApPlatform | EErpPlatform,
    notchSuppliers?: SupplierCatalogData[],
    accountingVendors?: VendorDetails[],
    subsidiaries?: RutterSubsidiary[],
    supplierMappingDefaults?: SupplierMappings,
    taxCodes?: TaxCodeSchema[],
    accountingClasses?: ApClassSchema[],
    companyName?: string,
    isInitialSyncComplete?: boolean,
    isWaitingOnSyncInfo?: boolean,
};

export const IntegrationsActiveCard: React.FC<Props> = ({
    data,
    integration,
    integrationPlatform,
    refetch,
    onSave,
    onConfigUpdate,
    notchSuppliers,
    accountingVendors,
    subsidiaries,
    supplierMappingDefaults,
    taxCodes,
    accountingClasses,
    companyName,
    isInitialSyncComplete,
    isWaitingOnSyncInfo
}) => (
    <div className="pt-5">
        <Separator />
        <div className="grid grid-cols-4">
            <div className="pt-5 flex flex-row items-center">
                {data.activeIcon}
                <Typography as="span" weight="font-medium" className="pl-5">{data.name}</Typography>
            </div>
            <Typography as="span" className="pt-5 text-gray-600">{data.date}</Typography>
            {isInitialSyncComplete || isWaitingOnSyncInfo
                ? <span className="pt-5">
                    <Typography as="span" className="">
                        {isWaitingOnSyncInfo ? '' : t('Integrations.initialSyncStatusComplete')}
                    </Typography>
                </span>
                : <div className="flex flex-row pt-5">
                    <Typography as="span">
                        {t('Integrations.initialSyncStatusPending')}
                    </Typography>
                    <Button
                        variant="ICON"
                        size="NO_BACKGROUND"
                        className="justify-center items-center"
                        onClick={(): void => { window.location.reload(); }}>
                        <RefreshIcon className={cx('w-4 h-4', { 'animate-spin': false })}/>
                    </Button>
                </div>
            }
            {integrationPlatform !== EApPlatform.BIGCHIP && integrationPlatform !== EErpPlatform.OPTIMUMCONTROL && <div className="text-right pt-4"><IntegrationsMoreButton refetch={refetch}
                integration={integration}
                integrationPlatform={integrationPlatform}
                notchSuppliers={notchSuppliers}
                accountingVendors={accountingVendors}
                subsidiaries={subsidiaries}
                supplierMappingDefaults={supplierMappingDefaults}
                taxCodes={taxCodes}
                accountingClasses={accountingClasses}
                companyName={companyName}
                onSave={onSave}
                onConfigUpdate={onConfigUpdate}
                isInitialSyncComplete={isInitialSyncComplete} />
            </div>}
        </div>
    </div>
);

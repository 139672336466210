import PropTypes from 'prop-types';
import React, { Component } from 'react';

import CartOrderItems from '../cart_order_items/CartOrderItems.jsx';
import DeliveryFeeStatus from './delivery-fee-status/DeliveryFeeStatus.jsx';
import OrderItemsToggle from './OrderItemsToggle.jsx';
import CartOrderFooter from './CartOrderFooter.jsx';

import Utils from '../../../utils';
import AddCustomItemMessage from '../../general/AddCustomItemMessage/AddCustomItemMessage.jsx';
import CartOrderCustomItems from '../cart_order_custom_items/CartOrderCustomItems.jsx';
import {EPaths} from "@v2/constants/EPaths";
import {getSupplierByVendorUrlSafe} from "@v2/utils/ShopUtils";

export default class CartOrder extends Component {
  state = {
    showOrders: this.props.isOnlyOrder,
  };

  componentDidUpdate(prevProps) {
    const { isOnlyOrder } = this.props;

    if (isOnlyOrder !== prevProps.isOnlyOrder) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState((state) => ({
        ...state,
        showOrders: isOnlyOrder,
      }));
    }
  }

  toggleShowOrder = () => {
    this.setState(({ showOrders }) => ({
      showOrders: !showOrders,
    }));
  };

  toggleIsCheckoutOrder = (event) => {
    event.stopPropagation();

    const { order, toggleIsCheckoutOrder } = this.props;

    toggleIsCheckoutOrder(order.urlsafe, order.isCheckout);
  };

  render() {
    const { order, vendors, minimumOrderAmount, customItems } = this.props;
    const { showOrders: areOrderItemsVisible } = this.state;

    const onClickStore = (vendor, event) => {
      changeView(vendor, 'storeView');

      // This needs to be added to prevent the parent div click handler
      // (onClickVendor()) from firing after we click here. This is b/c the parent
      // is technically clicked as well since this click handler is in a child div.
      event.stopPropagation();
    };

    const changeView = (vendor, view) => {
      if (!_.isEmpty(vendor)) {
        const { buyer, changeRoute, changeVendorFilters } = this.props;

        if (view === 'orderGuideView') {
          if (orderGuideByVendor[vendor.id]) {
            // Save selected vendor
            changeVendorFilters(vendor.name, vendor.id);
          }

          // Route to the orderGuide
          changeRoute(`/store/${vendor.id}/order-guide/`);
        } else if (view === 'storeView') {
          const catalogSupplier = getSupplierByVendorUrlSafe(this.props.suppliers, vendor.urlsafe);
          // Change the route: to the market
          changeRoute( `${EPaths.SHOP}?supplierCatalogs=${catalogSupplier.id}`);
        }
      } else {
        throw Error("Error, we don't have a vendorID or view assigned");
      }
    };

    let isEveryItemInOrderHaveAPrice = true;
    const orderTotalMinusTaxandFees = order.items.reduce((acc, item) => {
      if (item.price <= 0) {
        isEveryItemInOrderHaveAPrice = false;
        return acc;
      }

      return acc + item.price * item.quantity;
    }, 0);

    // Get vendor data from buyer for given order
    const vendor = vendors.find((v) => v.urlsafe === order.vendorUrlsafe);

    const orderQualifiesForFreeDel =
      Utils.hasVendorMinimumOrderAmount(vendor) &&
      orderTotalMinusTaxandFees >= minimumOrderAmount;
    const cItems = customItems.orders[order.urlsafe] ?? [];
    // close cart modal - shows add custom product modal
    const onClickAddCustomItem = () => {
      this.props.cartActions.showCart(false);
      this.props.cartActions?.toggleAddCustomItemModal(
        true,
        order.vendorName,
        order.urlsafe
      );
    };
    const showAddCustomItemMessage = !order.isBYOS;
    return (
      <div className="cart-order">
        <div className="cart-order-vendor">
          <div className="order-row">
            <div className="vendor-name-container text-2">
              <span
                className="vendor-name"
                onClick={(event) => onClickStore(vendor, event)}
              >
                {order.vendorName}
              </span>
            </div>

            {isEveryItemInOrderHaveAPrice && (
              <div className="order-total">
                <div className="order-total-text">ESTIMATED SUBTOTAL</div>
                <div className="order-total-amount">
                  {orderQualifiesForFreeDel
                    ? Utils.formatAsCurrency(orderTotalMinusTaxandFees)
                    : Utils.formatAsCurrency(
                        orderTotalMinusTaxandFees + vendor?.region?.deliveryFee ?? 0
                      )}
                </div>
              </div>
            )}
          </div>

          {/* If all items in the order have a price
           and if order's vendor has a minimum order amount
           and if order's vendor has a delivery fee
           then display the delivery status progress bar */}
          {isEveryItemInOrderHaveAPrice && (
            <DeliveryFeeStatus
              orderTotalMinusTaxandFees={orderTotalMinusTaxandFees}
              orderQualifiesForFreeDel={orderQualifiesForFreeDel}
              vendor={vendor}
              {...this.props}
            />
          )}
        </div>
        <OrderItemsToggle
          itemsCount={order.items.length}
          toggleItems={this.toggleShowOrder}
          isOpen={areOrderItemsVisible}
        />
        {areOrderItemsVisible && (
          <CartOrderItems
            vendor={vendor}
            orderItems={order.items}
            orderTotalMinusTaxandFees={orderTotalMinusTaxandFees}
            orderQualifiesForFreeDel={orderQualifiesForFreeDel}
            {...this.props}
          />
        )}

        {showAddCustomItemMessage && (
          <AddCustomItemMessage
            background
            itemsLength={cItems.length}
            onClick={onClickAddCustomItem}
          />
        )}
        <CartOrderCustomItems orderItems={cItems} urlsafe={order.urlsafe} />
        <CartOrderFooter {...this.props} />
      </div>
    );
  }
}

CartOrder.propTypes = {
  isOnlyOrder: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  vendors: PropTypes.array.isRequired,
  toggleIsCheckoutOrder: PropTypes.func.isRequired,
};

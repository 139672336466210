import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CustomDatePickerText from './CustomDatePickerText.jsx';

const ListItemCheckoutDatePicker = (props) => {
  const {
    item,
    itemSelected,
    itemSelectedStyle,
    className,
    orderDeliveryDay,
    minDate,
    daysToFilterOut,
    selectCustomDate,
    selectCustomOption,
  } = props;

  const changeDate = (date) => {
    // This changes the deliveryDayIndex
    selectCustomOption();

    // This actually changes order.deliveryDay to the custom datepicker date
    selectCustomDate(date);
  };

  const filterOutCertainDays = (date) => {
    // We filter days out that we don't need from the datepicker.
    return daysToFilterOut.includes(date.getDay()) ? false : true;
  };

  // If this is the current selected item, then highlight it.
  // Otherwise show default state
  const style = _.isEqual(item, itemSelected) ? itemSelectedStyle : {};

  return (
    <div className="delivery-day-item-wrapper">
      <div className={className}>
        <div className="checkout-list-item-date-picker" style={style}>
          <DatePicker
            className="react-datepicker-custom"
            selected={
              orderDeliveryDay ? moment(orderDeliveryDay).toDate() : undefined
            }
            minDate={minDate.toDate()}
            onChange={changeDate}
            filterDate={filterOutCertainDays}
            style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
            customInput={
              <CustomDatePickerText
                orderDeliveryDay={orderDeliveryDay}
                item={item}
                itemSelected={itemSelected}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

ListItemCheckoutDatePicker.defaultProps = {
  orderDeliveryDay: '',
  itemSelected: {},
};

ListItemCheckoutDatePicker.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
  itemSelected: PropTypes.objectOf(PropTypes.any),
  itemSelectedStyle: PropTypes.objectOf(PropTypes.any).isRequired,
  className: PropTypes.string.isRequired,
  daysToFilterOut: PropTypes.arrayOf(PropTypes.number).isRequired,
  orderDeliveryDay: PropTypes.string,
  minDate: PropTypes.instanceOf(Date).isRequired,

  selectCustomOption: PropTypes.func.isRequired,
  selectCustomDate: PropTypes.func.isRequired,
};

export default ListItemCheckoutDatePicker;

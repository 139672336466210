import axios, { AxiosResponse } from 'axios';
import { throwAxiosError } from '@v2/utils/AxiosUtils';

const { integrationApi } = process.env.BASE_URLS || {};
const BASE_ROUTE = `${integrationApi}/api/v3`;

export type OCConfiguration = {
    error: string,
    buyer_id?: string,
    created_at?: string,
    updated_at?: string,
};

type OCConfigurationRequest = {
    integrationType: string,
    operation: string,
    config: {
        type: string,
        buyerId: string,
    },
};

/**
 * Fetch the buyer's Optimum Control config
 */
export async function getOCConfiguration(buyerUrlsafeKey: string): Promise<OCConfiguration> {
    const url: string = `${BASE_ROUTE}/config`;
    const body: OCConfigurationRequest = {
        integrationType: 'OptimumControl',
        operation: 'get',
        config: {
            type: 'ftp',
            buyerId: buyerUrlsafeKey
        }
    };
    const response: AxiosResponse<OCConfiguration> = await axios.post(url, body)
        .catch((e) => throwAxiosError(e, 'Error retrieving OC config', false));
    return response.data;
}

import { useEffect, useState } from 'react';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import useFirebaseRemoteConfig from '@v2/hooks/useFirebaseRemoteConfig.hook';
import useBuyerHook from '@v2/hooks/useBuyer.hook';

export const useBuyerHasFeatureFlagEnabled = (featureFlag: EFirebaseParams): boolean => {
    const { isEnabledForBuyer } = useFirebaseRemoteConfig();
    const { buyer } = useBuyerHook();
    const [buyerHasFeatureFlagEnabled, setBuyerHasFeatureFlagEnabled] = useState<boolean>(undefined);
    useEffect(() => {
        const getHasFeatureFlag = async (): Promise<void> => {
            isEnabledForBuyer(featureFlag).then((res) => setBuyerHasFeatureFlagEnabled(res)).catch(() => setBuyerHasFeatureFlagEnabled(false));
        };
        getHasFeatureFlag();
    }, [buyer.urlsafe]);

    return buyerHasFeatureFlagEnabled;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError } from 'axios';
import { toast } from '@notch-ordering/ui-components';
import { getAccessToken, getAPIConfig } from '@/api/APIClient';

let isAuthSetup: boolean = false;
const MAX_ERROR_LENGTH: number = 200;
type ErrorData = { error?: string, message?: string };

/**
 * https://axios-http.com/docs/handling_errors
 */
export function parseAxiosError(error: AxiosError, message: string): string {
    let errorMessage: string | undefined;

    if (error.response?.data && (error.response.data as ErrorData).error) {
        // Response from server contains an error response
        errorMessage = (error.response.data as ErrorData).error;
    } else if (error.response?.data && (error.response.data as ErrorData).message) {
        // Response from server contains an error message
        errorMessage = (error.response.data as ErrorData).message;
    } else if (error.message) {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
    } else if (error.request) {
        // The request was made but no response was received
        errorMessage = 'No response';
    }

    if (!errorMessage) {
        errorMessage = 'Unknown Error';
    }

    if (errorMessage.length > MAX_ERROR_LENGTH) {
        errorMessage = errorMessage.substring(0, MAX_ERROR_LENGTH);
    }

    errorMessage = `${message}: ${errorMessage}`;

    // eslint-disable-next-line no-console
    console.log(`${errorMessage}`);

    return errorMessage;
}

export function throwAxiosError(error: AxiosError, message: string = 'Error', showToast: boolean = true): any {
    const errorMessage: string = parseAxiosError(error, message);

    if (showToast) {
        toast.show({
            message: `Network Error: ${errorMessage}`,
            showClose: true
        });
    }

    throw new Error(errorMessage);
}

export async function getBearer(): Promise<string> {
    const apiConfig: unknown = await getAPIConfig();
    const accessToken: string = await getAccessToken(apiConfig);
    const bearer: string = `Bearer ${accessToken}`;

    return bearer;
}

export function setupAxiosAuth(): void {
    if (isAuthSetup) {
        // Nothing to do
        return;
    }

    // Add a request interceptor
    axios.interceptors.request.use(async (config) => {
        if (process.env.NODE_ENV === 'test') {
            config.headers.Authorization = 'Bearer asfasfaTestBearerTokenAdfjdsflsdsklsfga';
        } else if (config.url.indexOf(process.env.NOTCH_DOMAIN) > -1 || config.url.indexOf('localhost') > -1 || config.url.indexOf('127.0.0.1') > -1) {
            config.headers.Authorization = await getBearer();
        }

        return config;
    });

    isAuthSetup = true;
}

import { Badge, Button, Separator, Tooltip, Typography } from '@notch-ordering/ui-components';
import { tNamespace } from '@v2/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@icons/check-icon.svg';
import cx from 'classnames';

const mailto = 'mailto:support@notch.financial?subject=Request%20to%20set%20up%20IMS%20integration';

type Props = {
    icon: React.ReactNode,
    name: string,
    description: string,
    disabled: boolean,
    isConnected: boolean,
    onClickConnect?: () => void,
    contactUs?: boolean,
    enableTooltip?: boolean,
    comingSoon?: boolean,
};

export const IntegrationsExploreCard: React.FC<Props> = ({ icon, name, description, disabled, isConnected, onClickConnect, contactUs, enableTooltip, comingSoon }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const [shouldShowTooltip, setShouldShowTooltip] = React.useState<boolean>(false);

    return (
        <div className="w-80 h-56">
            {icon}
            <Typography
                as="div"
                variant="LG-1"
                weight="font-semibold"
                className="pt-3 text-gray-700">
                {name}
            </Typography>
            <div className="flex flex-col h-40 justify-between">
                <Typography
                    as="div"
                    variant="LG-1"
                    className="pt-1 text-gray-600">
                    {description}
                </Typography>
                <div>
                    <Button
                        hidden={isConnected}
                        onClick={disabled ? (): void => {} : onClickConnect}
                        className={cx(
                            'mt-7 h-8 flex items-center',
                            { 'bg-gray-100 text-gray-500 hover:bg-gray-100 hover:text-gray-500 active:bg-gray-100 cursor-default': disabled }
                        )}>
                        {<Tooltip show={shouldShowTooltip}
                            placement="top"
                            tooltipClassName="z-50"
                            showArrow={false}
                            trigger={<div
                                onMouseLeave={():void => setShouldShowTooltip(false)}
                                onMouseEnter={():void => {
                                    if (disabled && enableTooltip) {
                                        setShouldShowTooltip(true);
                                    }
                                }}>
                                {!contactUs && (comingSoon ? t('comingSoon') : t('connect'))}
                                {contactUs && <a href={mailto}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-gray-700 hover:text-gray-700 active:text-gray-700 focus:text-gray-700">
                                    {t('contactUs')}</a>}
                            </div>}>
                            {t('onlyOneAccounting')}
                            <br />
                            {t('canBeConnected')}
                        </Tooltip>}
                    </Button>
                    <div hidden={!isConnected}>
                        <Badge variant="GREEN"
                            iconLeft={<CheckIcon />}
                            fontWeight="font-medium"
                            className="mt-7 w-32 h-8 flex justify-center">
                            {t('connected')}
                        </Badge>
                    </div>
                    <Separator className="mt-7" />
                </div>
            </div>
        </div>
    );
};

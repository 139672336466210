import { Button, Modal, Separator, Typography, onMobileSetLarge, toast } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { format, formatInTimeZone, toDate } from 'date-fns-tz';
import { addDays } from 'date-fns';
import CheckIcon from '@icons/check-icon.svg';
import AlertIcon from '@icons/alert-icon.svg';
import { Order, OrderItem } from '@v2/network/LegacyAPI';
import { GenerateInvoiceParams, InvoiceLineItem, generateInvoice } from '@v2/network/GreevilsGreedApi';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { dollarsToCents } from '@v2/utils/CurrencyUtils';
import { useQuery } from '@tanstack/react-query';
import { getSupplierPayToAddress } from '@v2/network/CoreAPI';

export interface GenerateBillProps {
    isOpen: boolean,
    row: Order,
    close: () => void,
    onSuccess?: (invoiceID: string) => void,
}

export const GenerateBillModal: React.FC<GenerateBillProps> = ({ isOpen, close, onSuccess, row }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { buyer } = useBuyerHook();
    const orderID = row.id;
    const supplierName = row.vendorName;
    const supplierUrlsafeKey = row.vendorUrlsafe;
    const { data: supplierPayToAddress } = useQuery(['getSupplierDataGenerateBill', supplierUrlsafeKey], () => getSupplierPayToAddress(supplierUrlsafeKey), {
        enabled: !!supplierUrlsafeKey && isOpen,
    });

    function onClickGenerateBill(): void {
        let dueDate = row.dueAt;
        if (!dueDate) {
            // Calculate based on terms and delivery date
            const deliveryDate = toDate(row.deliveryDay, { timeZone: 'UTC' });
            if (row.paymentTerms) {
                dueDate = formatInTimeZone(addDays(deliveryDate, row.paymentTerms), 'yyyy-MM-dd', 'UTC');
            }
        }
        const data: GenerateInvoiceParams = {
            buyerUrlsafeKey: buyer.urlsafe,
            supplierUrlsafeKey: row.vendorUrlsafe,
            orderUrlsafeKey: row.urlsafe,
            deliveryDate: row.deliveryDay,
            issuedDate: format(toDate(row.deliveryDay), 'yyyy-MM-dd'),
            dueDate: row.dueAt || row.deliveryDay,
            invoiceNumber: row.id.toString(10),
            isApprovedForBigChip: false,
            lineItems: row.items.map((orderItem: OrderItem): InvoiceLineItem => ({
                name: orderItem.name,
                quantity: orderItem.quantity,
                pricePerUnit: dollarsToCents(orderItem.packaging_price, true),
                packageSize: orderItem.salesQuantity,
                sku: orderItem.externalId,
                description: orderItem.unitDescription,
                unit: orderItem.unitName,
                subtotal: dollarsToCents(orderItem.priceTotal, true),
                tax: dollarsToCents(orderItem.taxAmount, true),
                taxRate: orderItem.taxPercentage,
                total: dollarsToCents(orderItem.total, true),
            })),
            shipToAddress: {
                name: row.shippingAddress.name,
                address: `${row.shippingAddress.line1} ${row.shippingAddress.line2}`,
                state: row.shippingAddress.state,
                city: row.shippingAddress.city,
                email: buyer.email,
            },
            billToAddress: {
                name: row.billingAddress.name,
                address: `${row.billingAddress.line1} ${row.billingAddress.line2}`,
                state: row.billingAddress.state,
                city: row.billingAddress.city,
                email: buyer.email,
            },
            payToAddress: {
                name: row.vendorName,
                address: supplierPayToAddress ? `${supplierPayToAddress.address?.line1} ${supplierPayToAddress.address?.line2}` : '',
                state: supplierPayToAddress?.address?.state ?? '',
                city: supplierPayToAddress?.address?.city ?? '',
                email: supplierPayToAddress?.email ?? '',
            },
        };
        setIsLoading(true);
        generateInvoice(data).then((invoice) => {
            setIsLoading(false);
            toast.show({
                icon: <CheckIcon />,
                message: t('billGenerated')
            });
            onSuccess(invoice.id);
            close();
        }).catch(() => {
            toast.show({
                icon: <AlertIcon />,
                message: t('billNotGenerated')
            });
            setIsLoading(false);
        });
    }

    return <Modal isOpen={isOpen}
        modalSize={onMobileSetLarge('SMALL')}
        desktopModalWidth="lg:w-[450px]"
        headerPadding="pb-2 lg:pt-0 pt-10"
        title= {t('confirmGenerateBill')}
        close={close}>
        <div className="px-5 pb-6">
            <Typography as="div" className="text-gray-600" desktopSize="lg:text-2">
                {t('orderBillWillGenerate', { orderID })}
            </Typography>
        </div>
        <div className="px-5 pb-5">
            <Typography as="div" className="text-gray-600" desktopSize="lg:text-2">
                {t('reminder', { supplierName })}
            </Typography>
        </div>
        <Separator />
        <div className="px-5 pt-5 flex justify-between">
            <Button variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={close}>
                <Typography as="span" weight="font-medium">
                    {tCommon('Buttons.cancel')}
                </Typography>
            </Button>
            <Button variant="SECONDARY"
                size="SMALL"
                loading={isLoading}
                onClick={onClickGenerateBill}>
                <Typography as="span" weight="font-medium">
                    {t('generateBill')}
                </Typography>
            </Button>
        </div>
    </Modal>;
};

import React, { useContext } from 'react';
import { Modal, Button, Typography, Separator, onMobileSetLarge } from '@notch-ordering/ui-components';
import { OrderGuide } from '@v2/network/types';
import { tCommon, tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';

export interface OrderGuideAddModalProps {
    onClose?: () => void,
    orderGuide?: OrderGuide,
}

export const UnsaveProductModal : React.FC<OrderGuideAddModalProps> = ({ onClose = (): void => null }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Shop' });
    const { modals, setModalVisibility, activeProduct, setSavedProduct, setActiveProduct } = useContext(MarketContext);

    const closeModal = ():void => {
        setModalVisibility('unsaveProduct', false);
        onClose();
    };
    const handleUnsaveProduct = ():void => {
        setSavedProduct(activeProduct);
        setActiveProduct(undefined);
        closeModal();
    };

    return (
        <Modal isOpen={modals.unsaveProduct}
            close={closeModal}
            onClose={closeModal}
            modalSize={onMobileSetLarge('MEDIUM', true)}
            title={t('unsaveProductModalTitle')}>

            <div className="w-full h-full flex flex-col gap-3 overflow-auto w-full px-4 lg:px-8">
                <Typography className="m-0">
                    {t('unsaveProductModalMessage')}
                </Typography>
            </div>
            <Separator className="mt-4" />
            <div className={'px-4 lg:px-8 pt-5 mt-auto sticky bottom-0'}>
                <div className="flex items-center justify-between  gap-3">
                    <Button variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        className={'w-full'}
                        onClick={closeModal}>
                        {tCommon('Buttons.cancel')}
                    </Button>
                    <Button variant="DESTRUCTIVE"
                        size="MEDIUM"
                        className="w-full"
                        onClick={handleUnsaveProduct}>
                        {tCommon('Buttons.unsave')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

import React, { useContext } from 'react';
import { Typography, Separator } from '@notch-ordering/ui-components';
import Counter from '@v2/components/Market/Counter/Counter';
import { IPackage } from '@v2/network/types';
import { ProductCardContext } from '@v2/components/Market/ProductCard/ProductCardContext';
import { getPackageFromOrder } from '@v2/utils/ShopUtils';

export interface Props {
    onChange: (count: number, updatedPackage?: IPackage) => void,
    price: React.ReactNode,
    productPackage?: IPackage,
    hideSeparator?: boolean,
}

export const ProductPackage: React.FC<Props> = ({
    onChange,
    price,
    productPackage,
    hideSeparator,
}) => {
    let productUnit = productPackage?.unit;
    if (!productUnit) {
        productUnit = 'each';
    }

    const productDescription = productPackage?.description ?? `${productPackage?.quantity ?? ''} ${productUnit}`;
    const { productsInOrder = [] } = useContext(ProductCardContext);
    const orderProduct = getPackageFromOrder(productsInOrder, productPackage?.id) ?? {};
    const quantity = orderProduct?.quantity ?? 0;
    return (
        <>
            <div className="flex py-5 px-4 items-center">
                <div className={'w-3/5'}>
                    <div className="flex items-center gap-2">
                        <Typography as="div" className="text-gray-700" weight="font-medium">
                            {price}
                        </Typography>
                        {!!productPackage.id && (
                            <>
                                <span className={'text-[8px]'}>•</span>
                                <Typography as="div" className="text-gray-600">
                                    {productDescription}
                                </Typography>
                            </>
                        )}
                    </div>
                </div>
                <div className="flex gap-2 ml-auto w-2/5 justify-end">
                    <Counter value={quantity}
                        allowZero
                        onChange={
                            (count): void => {
                                onChange(count, {
                                    ...productPackage,
                                    quantityInCart: count
                                });
                            }
                        }/>
                </div>
            </div>
            {!hideSeparator && <Separator/>}
        </>
    );
};

export default ProductPackage;

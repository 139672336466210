import SetupCompleteImg from '@images/setup-complete.svg';
import FileIcon from '@icons/file-icon.svg';
import CurrencyIcon from '@icons/currency-icon.svg';
import PencilIcon from '@icons/pencil-icon.svg';
import { Button, Typography } from '@notch-ordering/ui-components';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { tCommon, tNamespace } from '@v2/i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Router } from 'react-router';
import { EPaths } from '@v2/constants/EPaths';
import { SetupCompleteCard } from '@v2/components/Setup';
import { useSetupStore } from '@v2/stores/SetupStore';

type Props = {
    router: Router,
};

/**
 * This is the page the user will see once the catalog setup is complete.
 */
export const SetupComplete: React.FC<Props> = ({ router }) => {
    const { setupActions } = useSetupStore();
    const { didUploadFiles, didRequestPriceList, didCreateItems } = setupActions;
    const isMobileWidth: boolean = useIsMobileWidth();
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Setup.Catalog.Complete' });
    const [shouldShowTitleBar, setShouldShowTitleBar] = useState(false);
    const cardIconStyle = 'w-5 h-5 text-gray-700';
    const bottomDrawerHeight = 100;
    const standardPadding = 16;
    const bottomPadding = bottomDrawerHeight + standardPadding;

    function scrollHandler(event: React.UIEvent<HTMLDivElement>): void {
        const titleBarDisplayThreshold = 206;
        setShouldShowTitleBar(event.currentTarget.scrollTop > titleBarDisplayThreshold);
    }

    return (
        <div className="
            h-full w-full
            flex flex-col
            justify-start lg:justify-center mt-safe">

            {/* Title bar (mobile only) */}
            {shouldShowTitleBar && <div className="
                lg:invisible lg:absolute
                sticky top-0 z-10
                flex justify-center
                bg-white
                p-4 pt-16">
                <Typography variant="LG-2" weight="font-semibold" as="div" className="text-gray-700">
                    {t('you\'reDone')}
                </Typography>
            </div>}

            {/* Header: image, page title, description text */}
            <div className="
                flex flex-col
                items-start lg:items-center
                overflow-scroll lg:overflow-auto
                bg-white
                px-4 lg:px-0"
            onScroll={scrollHandler}>

                <div className="
                    pt-10 lg:pt-4
                    w-[149px] lg:w-[274px]">
                    <SetupCompleteImg title="setup-complete" />
                </div>

                <Typography
                    variant="5XL"
                    weight={isMobileWidth ? 'font-regular' : 'font-medium'}
                    as="div"
                    className="pt-6 lg:pt-10 text-gray-700">
                    {t('niceJob')}
                </Typography>

                <Typography as="div" className="pt-4 text-gray-700 opacity-70">
                    {t('whatToExpect')}
                </Typography>

                {/* Dynamic cards */}
                <div className="
                    pt-6 lg:pt-10
                    flex flex-wrap lg:flex-row gap-4"
                style={{ paddingBottom: isMobileWidth ? `${bottomPadding}px` : '0px' }}>

                    {didUploadFiles && <SetupCompleteCard
                        icon={<div className={cardIconStyle}>
                            <FileIcon title="file icon" />
                        </div>}
                        titleText={t('uploadedFiles')}
                        bodyText={t('willBeProcessed')} />}

                    {didRequestPriceList && <SetupCompleteCard
                        icon={<div className={cardIconStyle}>
                            <CurrencyIcon title="currency icon" />
                        </div>}
                        titleText={t('priceList')}
                        bodyText={t('willBeProcessed')} />}

                    {didCreateItems && <SetupCompleteCard
                        icon={<div className={cardIconStyle}>
                            <PencilIcon title="pencil icon" />
                        </div>}
                        titleText={t('createdItems')}
                        bodyText={t('areReady')} />}
                </div>

                {/* Footer (drawer on mobile) with button */}
                <div className="
                    fixed bottom-0 lg:static
                    -ml-4 lg:ml-0
                    w-full
                    lg:flex lg:justify-center
                    rounded-t-2xl lg:rounded-none
                    shadow-z4 lg:shadow
                    bg-white
                    pt-5 lg:pt-20 px-4 pb-8 lg:pb-4">

                    <Button
                        variant="SECONDARY"
                        size="LARGE"
                        className="
                            min-w-fit w-full lg:w-44
                            py-3 px-6"
                        onClick={(): void => { router.push(EPaths.HOME); }}>
                        <span className="text-3">
                            {tCommon('Buttons.goToOrderDesk')}
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

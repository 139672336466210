import PropTypes from 'prop-types';
import React, { Component } from 'react';

import OrderCheckoutBodyDelivery from './OrderCheckoutBodyDelivery.jsx';
import OrderCheckoutBodyReview from './OrderCheckoutBodyReview.jsx';

export default class OrderCheckoutBody extends Component {
  toggleCancelOrderModal = () => {
    const { toggleCancelOrderModal, order } = this.props;

    toggleCancelOrderModal(order.urlsafe);
  };

  render() {
    const { order, checkoutView, isPendingOrders } = this.props;

    const showCheckoutView = () => {
      switch (checkoutView) {
        case 'deliveryView':
          return <OrderCheckoutBodyDelivery {...this.props} />;
        case 'reviewView':
          return (
            <OrderCheckoutBodyReview
              orderProducts={order.items}
              order_urlSafe={order.urlsafe}
              {...this.props}
            />
          );
        case 'pendingView':
          return '';
        default:
          return <OrderCheckoutBodyDelivery {...this.props} />;
      }
    };

    return <div className="order-checkout-body">{showCheckoutView()}</div>;
  }
}

OrderCheckoutBody.propTypes = {
  order: PropTypes.object.isRequired,
  checkoutView: PropTypes.string.isRequired,
  isPendingOrders: PropTypes.bool.isRequired,

  toggleCancelOrderModal: PropTypes.func.isRequired,
};

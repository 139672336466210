import PropTypes from 'prop-types';
import React from 'react';
import useIsMobileWidth from '../../../domains/shared/hooks/useIsMobileWidth';

const AccountBox = ({ toggleAccountSettingsModal, fields }) => {
  const isMobileWidth = useIsMobileWidth();

  const handleChangePassword = (e) => {
    e.preventDefault();
    toggleAccountSettingsModal();
  };

  const changePassword = (
    <div className="change-password" onClick={handleChangePassword}>
      Change Password
    </div>
  );

  return (
    <div className="account-box">
      {Object.keys(fields).map((key) => {
        const isDefined = fields[key] && fields[key].constructor !== Object;
        if (isDefined) {
          return (
            <>
              <div className="account-box-field" key={key}>
                <div className="account-box-label">{key}</div>
                <div className="account-box-value">
                  {key === 'Password' ? (
                    <>
                      <input
                        type="password"
                        disabled={true}
                        value={fields[key]}
                        style={{ border: 'none' }}
                      />
                      {!isMobileWidth && changePassword}
                    </>
                  ) : (
                    <span>{fields[key]}</span>
                  )}
                </div>
              </div>
              {isMobileWidth && key === 'Password' && (
                <div className="account-box-field">{changePassword}</div>
              )}
            </>
          );
        }
        return null;
      })}
    </div>
  );
};

AccountBox.propTypes = {
  fields: PropTypes.shape({
    'First Name': PropTypes.string,
    'Last Name': PropTypes.string,
    Email: PropTypes.string,
    Password: PropTypes.string,
  }).isRequired,
  toggleAccountSettingsModal: PropTypes.func.isRequired,
};

export default AccountBox;

import create from 'zustand';

/**
 * State used by the orders store
 *
 */
type OrdersStore = {
    filters: TOrdersFilters,
    setFilters: (filters: TOrdersFilters) => void,
    updateFilters: (filters: Partial<TOrdersFilters>, shouldResetPagination?: boolean) => void,
    resetFilterByType: (key: keyof TOrdersFilters) => void,
};

export type TOrdersFilters = {
    deliveryDayStart?: string,
    deliveryDayEnd?: string,
    vendorUrlsafe?: string,
    sortBy?: {
        value: string,
        label: string,
    },
    id?: string, // Order number
    vendorID?: string,
    dateFilter?: string,
};

export const ORDERS_FILTERS_INITIAL_STATE: TOrdersFilters = {
    deliveryDayStart: undefined,
    deliveryDayEnd: undefined,
    vendorUrlsafe: undefined,
    sortBy: undefined,
    id: undefined,
    vendorID: undefined,
    dateFilter: undefined
};

export const useOrdersStore = create<OrdersStore>((set) => ({
    filters: ORDERS_FILTERS_INITIAL_STATE,
    setFilters: (filters): void => set(() => ({ filters })),
    updateFilters: (filters): void => set((state) => ({ filters: { ...state.filters, ...filters } })),
    resetFilterByType: (type: keyof TOrdersFilters): void => set((state) => (
        { filters: { ...state.filters,
            [type]: ORDERS_FILTERS_INITIAL_STATE[type] } })),
}),);

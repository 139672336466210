import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import orderItemType from '../../types/orderItemType';

const ItemPriceEdit = ({
  orderItem,
  handlePrice,
  handlePriceForPackaging,
  editPrice,
  toggleEditPrice,
  isPackage,
}) => {
  const editPriceRef = useRef(null);
  useEffect(() => {
    if (editPrice && editPriceRef.current) {
      editPriceRef.current.focus();
    }
  }, [editPrice]);

  return (
    <div className="od-row col-50">
      <div className="od-data">
        <span className="od-edit-label">Price</span>
        <div className="od-data-row">
          {isPackage ? (
            <>
              {editPrice && (
                <span className="od-product gray od-padding-right-0">$</span>
              )}
              <input
                type={editPrice ? 'number' : 'text'}
                className={cx('od-product', {
                  'od-product-100': !editPrice,
                  'od-padding-0': editPrice,
                })}
                value={
                  editPrice
                    ? orderItem.packagingPrice
                    : `$${orderItem.packagingPrice}`
                }
                onFocus={() => toggleEditPrice(true)}
                onBlur={() => toggleEditPrice(false)}
                ref={editPriceRef}
                onChange={handlePriceForPackaging}
              />
            </>
          ) : (
            <>
              {editPrice && (
                <span className="od-product gray od-padding-right-0">$</span>
              )}
              <input
                type={editPrice ? 'number' : 'text'}
                className={cx('od-product', {
                  'od-product-100': !editPrice,
                  'od-padding-0': editPrice,
                })}
                value={
                  editPrice
                    ? orderItem.unitPrice
                    : `$${orderItem.unitPrice}/${orderItem.salesUnit}`
                }
                onFocus={() => toggleEditPrice(true)}
                onBlur={() => toggleEditPrice(false)}
                ref={editPriceRef}
                onChange={handlePrice}
              />
              {editPrice && (
                <span className="od-product gray">/{orderItem.salesUnit}</span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemPriceEdit;

ItemPriceEdit.propTypes = {
  handlePrice: PropTypes.func.isRequired,
  handlePriceForPackaging: PropTypes.func.isRequired,
  editPrice: PropTypes.bool.isRequired,
  toggleEditPrice: PropTypes.func.isRequired,
  orderItem: orderItemType.isRequired,
  isPackage: PropTypes.bool.isRequired,
};

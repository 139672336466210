import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Checkbox } from '@notch-ordering/ui-components';
import { EApPlatform, EErpPlatform } from '@notch-ordering/shared-logic';
import { tNamespace } from '@v2/i18n';
import { FilterButton } from '@v2/components/Shared/FiltersButton/FilterButton';
import { useUploadsStore } from '@v2/stores/UploadsStore';
import { integrationNameKeys } from '@v2/Pages/Integrations/IntegrationPlatformUtils';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents, TrackingLabel } from '@v2/constants/Tracking';

/**
 * Determines the sync filter based on the selected options.
 *
 * @param {boolean} isSyncedSelected - Whether the "Synced" option is selected.
 * @param {boolean} isNotSyncedSelected - Whether the "Not Synced" option is selected.
 * @returns {boolean | null} - `true` if "Synced" is selected and "Not Synced" is not selected. The server will query for invoices that are synced to the selected platform;
 * - `false` if "Not Synced" is selected and "Synced" is not selected. The server will query for invoices that are not synced to the selected platform;
 * - `null` if both or neither are selected. This is used to indicate that the filter should not be applied.
 */
export const determineSyncFilter = (isSyncedSelected: boolean, isNotSyncedSelected: boolean): boolean | null => {
    if (isSyncedSelected === isNotSyncedSelected) return null;
    if (isSyncedSelected) return true;
    return false;
};

enum FilterOptionsEnum {
    IMS_SYNCED = 'imsSynced',
    IMS_NOT_SYNCED = 'imsNotSynced',
    ACCOUNTING_SYNCED = 'accountingSynced',
    ACCOUNTING_NOT_SYNCED = 'accountingNotSynced'
}

type FilterOptions = {
    imsSynced: boolean,
    imsNotSynced: boolean,
    accountingSynced: boolean,
    accountingNotSynced: boolean,
};

export type SyncStatusFilterProps = {
    onChangeFilters?: () => void,
    erpPlatform?: EErpPlatform,
    accountingPlatform?: EApPlatform,
    shouldShowSyncStatusFilter: boolean,
};

export const SyncStatusFilter: React.FC<SyncStatusFilterProps> = ({ onChangeFilters, erpPlatform, accountingPlatform, shouldShowSyncStatusFilter }) => {
    const { updateSearchParams, searchParams, resetPagination } = useUploadsStore();
    const [filterOptions, setFilterOptions] = React.useState<FilterOptions>(() => {
        const { hasImsSynced, hasAccountingSynced } = searchParams;
        return {
            imsSynced: !!(hasImsSynced === true || hasImsSynced === undefined),
            imsNotSynced: !!(hasImsSynced === false || hasImsSynced === undefined),
            accountingSynced: !!(hasAccountingSynced === true || hasAccountingSynced === undefined),
            accountingNotSynced: !!(hasAccountingSynced === false || hasAccountingSynced === undefined),
        };
    });
    const { t: tIntergrations } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices.Filters' });

    React.useEffect(() => {
        if (!shouldShowSyncStatusFilter) return;

        const { imsSynced, imsNotSynced, accountingSynced, accountingNotSynced } = filterOptions;

        updateSearchParams({
            hasImsSynced: determineSyncFilter(imsSynced, imsNotSynced),
            hasAccountingSynced: determineSyncFilter(accountingSynced, accountingNotSynced)
        });
        resetPagination();

        if (onChangeFilters) {
            onChangeFilters();
        }
    }, [JSON.stringify(filterOptions)]);

    if (!shouldShowSyncStatusFilter) return null;

    const handleFilterOptionChange = (filterName: FilterOptionsEnum, value: boolean): void => {
        setFilterOptions((options: FilterOptions): FilterOptions => ({
            ...options,
            [filterName]: value
        }));
        sendTrackingEvent({ ...TrackingEvents.syncStatusFilterSelected, label: TrackingLabel.UPLOADS });
    };

    const preventDefaultEvent = (e): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const syncFilterOptions = [
        {
            label: <Typography className="mb-0 flex gap-2 relative items-center">
                <span className="pr-5">{tIntergrations(integrationNameKeys(accountingPlatform))}</span>
            </Typography>,
            onClick: preventDefaultEvent,
            disabled: true
        },
        accountingPlatform && {
            label: <Checkbox
                label={t('synced')}
                onChange={handleFilterOptionChange}
                value={FilterOptionsEnum.ACCOUNTING_SYNCED}
                checked={filterOptions[FilterOptionsEnum.ACCOUNTING_SYNCED]}/>,
            onClick: preventDefaultEvent
        },
        {
            label: <Checkbox
                label={t('notSynced')}
                onChange={handleFilterOptionChange}
                value={FilterOptionsEnum.ACCOUNTING_NOT_SYNCED}
                checked={filterOptions[FilterOptionsEnum.ACCOUNTING_NOT_SYNCED]}/>,
            onClick: preventDefaultEvent
        },
        {
            label: <Typography className="mb-0 -ml-4 -mr-4 border-t"/>,
            onClick: preventDefaultEvent,
            disabled: true
        },
        {
            label: <Typography className="mb-0 flex gap-2 relative items-center">
                <span className="pr-5">{tIntergrations(integrationNameKeys(erpPlatform))}</span>
            </Typography>,
            onClick: preventDefaultEvent,
            disabled: true
        },
        {
            label: <Checkbox
                label={t('synced')}
                onChange={handleFilterOptionChange}
                value={FilterOptionsEnum.IMS_SYNCED}
                checked={filterOptions[FilterOptionsEnum.IMS_SYNCED]}/>,
            onClick: preventDefaultEvent
        },
        {
            label: <Checkbox
                label={t('notSynced')}
                onChange={handleFilterOptionChange}
                value={FilterOptionsEnum.IMS_NOT_SYNCED}
                checked={filterOptions[FilterOptionsEnum.IMS_NOT_SYNCED]}/>,
            onClick: preventDefaultEvent
        }
    ];

    return <FilterButton
        label={t('syncStatus')}
        size={'SMALL'}
        items={syncFilterOptions}
        className="max-h-80 overflow-auto"
        placement="bottom-start"/>;
};

import CheckIcon from '@icons/check-icon.svg';
import MarkerActiveIcon from '@icons/marker-active-icon.svg';
import MarkerIcon from '@icons/marker-icon.svg';
import SelectIcon from '@icons/select-icon.svg';
import { Popover, Typography } from '@notch-ordering/ui-components';
import useOrgHook from '@v2/hooks/useOrg.hook';
import useSwitchBuyerHook from '@v2/hooks/useSwitchBuyer.hook';
import { BuyerData } from '@v2/types/BuyerData';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

export interface Props {
    name: string,
    onClick?: () => void,
}

export const BuyerSwitcher: React.FC<Props> = ({
    name,
    onClick,
}) => {
    const { org } = useOrgHook();
    const modal = useRef<HTMLIonModalElement>(null);
    const buttonRef = useRef(null);
    const [iconActive, setIconActive] = useState(false);
    const { switchBuyer } = useSwitchBuyerHook();

    const buyerOrganizations = useMemo(
        () => (org && org.length > 0 ? org.filter((o) => o.buyerKey) : []),
        [org]
    );
    buyerOrganizations.sort((a, b) => a.buyerName.localeCompare(b.buyerName));

    const onClickBuyer = (buyer: BuyerData): void => {
        modal.current?.dismiss();
        switchBuyer(buyer, true);
        sendTrackingEvent(TrackingEvents.venueSelected);
    };

    useEffect(() => {
        const changeIcon = (e):void => {
            if (buttonRef.current && !buttonRef.current.contains(e.target)) {
                setIconActive(false);
            } else {
                setIconActive(true);
            }
        };
        document.addEventListener('mousedown', changeIcon);
    }, [buttonRef, setIconActive]);

    return (
        <>
            <Popover
                className="w-[300px] max-h-[500px] overflow-auto py-2"
                button={<button
                    onClick={onClick}
                    ref={buttonRef}
                    className="text-left border-0 pl-4 py-2 text-gray-700 bg-white shadow-none hover:bg-gray-200 rounded-xl">
                    <div className="flex flex-row gap-3 justify-left items-center">
                        <div className={'w-4 h-4 text-gray-700'}>
                            {iconActive ? <MarkerActiveIcon /> : <MarkerIcon /> }
                        </div>
                        <Typography as="span" className="truncate lg:w-40">{name}</Typography>
                        <div className={'w-4 h-4 text-gray-700'}>
                            <SelectIcon />
                        </div>
                    </div>
                </button>}
                items={buyerOrganizations.map((buyer) => {
                    const { buyerKey, buyerName } = buyer;
                    return { label:
                            <div className="flex flex-row justify-between items-center">
                                <div key={buyerKey} className="flex flex-col m-0 p-0">
                                    <Typography as="span" className="w-60">{buyerName}</Typography>
                                </div>
                                {buyerName === name && <CheckIcon className="w-4 h-4"/>}
                            </div>,
                    onClick: (): void => {
                        onClickBuyer(buyer);
                    },
                    className: 'py-1.5' };
                })} />

        </>
    );
};

export default BuyerSwitcher;

import React, { FC, Fragment, useEffect, useState } from 'react';
import { ScanLineItemsFocusDropdown } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsFocusNavigation/ScanLineItemsFocusDropdown';
import { ScanLineItemsFocusControls } from '@v2/components/ScanReview/ScanLineItems/ScanLineItemsFocusNavigation/ScanLineItemsFocusControls';

import { Transition } from '@headlessui/react';
import { Tooltip, Typography } from '@notch-ordering/ui-components';
import { LineItemFocusType, useOcrInvoiceStore, LineItemCategory } from '@notch-ordering/shared-logic';
import { onNavigate } from '@v2/components/ScanReview/ScanReviewUtils';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { getLineItemCategoryToValidate } from '@v2/Pages/Integrations/IntegrationsUtils';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';

/**
 React component for navigating through line item errors on an OCR invoice form.
 *
 @returns JSX.Element or null
 */
export const ScanLineItemsFocusNavigation: FC = () => {
    const { showFocusPanel, setShowFocusPanel, focusType, formErrors, getErrorsCountByTypeSelected, draftInvoice } = useOcrInvoiceStore();
    const { setCurrentErrorField, currentErrorField, selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategoryToValidate: LineItemCategory = getLineItemCategoryToValidate(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, draftInvoice?.isAutoCalculateOn, useSeparateIMSLineItems, hasImsIntegration);
    const errorLineItemRefs = focusType === LineItemFocusType.Required ? formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs : formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.errorLineItemRefs;
    const [showFocusDropdownTooltip, setShowFocusDropdownTooltip] = useState(false);
    const errorsCount = getErrorsCountByTypeSelected(lineItemCategoryToValidate);
    const isFocusPanelVisible = errorsCount > 0 && showFocusPanel;

    const onNavigateNext = (): void => {
        if (currentErrorField < errorsCount - 1) {
            onNavigate(errorLineItemRefs[currentErrorField + 1]);
            setCurrentErrorField(currentErrorField + 1);
        }
    };
    const onNavigatePrevious = (): void => {
        if (currentErrorField > 0) {
            onNavigate(errorLineItemRefs[currentErrorField - 1]);
            setCurrentErrorField(currentErrorField - 1);
        }
    };
    const handleChangeFocusChange = (type) :void => {
        setCurrentErrorField(0);
        if (type === LineItemFocusType.Required) {
            if (formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs?.length > 0) {
                onNavigate(formErrors[lineItemCategoryToValidate]?.requiredFields?.errorLineItemRefs[0]);
            }
        } else if (formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.errorLineItemRefs?.length > 0) {
            onNavigate(formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.errorLineItemRefs[0]);
        }
    };

    useEffect(() => {
        if (isFocusPanelVisible && errorLineItemRefs?.length > 0) {
            onNavigate(errorLineItemRefs[currentErrorField]);
        }
    }, []);

    useEffect(() => {
        // close the focus panel if there are no errors
        if (focusType === LineItemFocusType.Required && formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount === 0) {
            setShowFocusPanel(false);
        }
        if (focusType === LineItemFocusType.LowConfidence && formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount === 0) {
            setShowFocusPanel(false);
        }
    }, [focusType, formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount, formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount]);

    const getHasNext = (): boolean => currentErrorField < errorsCount - 1;

    const getHasPrevious = (): boolean => currentErrorField > 0;
    if (!isFocusPanelVisible) return null;
    return <Transition as={Fragment}
        show={showFocusPanel}
        enter="transition ease-in duration-100"
        enterFrom="opacity-0  transform -translate-y-4"
        enterTo="opacity-100 transform translate-y-0"
        leave="transition ease-out duration-100"
        leaveFrom="opacity-100 transform translate-y-0"
        leaveTo="opacity-0 transform -translate-y-4">
        <div className="rounded-xl px-4 py-2.5 -mt-1.5 bg-white shadow-z3 fixed mx-8 z-10 flex items-center top-28 lg:w-[calc(50%-4rem)] w-[calc(100%-4rem)]">
            <Tooltip show={showFocusDropdownTooltip}
                showArrow={false}
                tooltipClassName="py-1.5 px-2 rounded-md"
                placement="bottom"
                trigger={
                    <div onMouseEnter={(): void => setShowFocusDropdownTooltip(true)} onMouseLeave={(): void => setShowFocusDropdownTooltip(false)}>
                        <ScanLineItemsFocusDropdown currentMissingRequiredField={currentErrorField + 1}
                            totalMissingRequiredFields={formErrors[lineItemCategoryToValidate]?.requiredFields?.totalCount ?? 0}
                            totalLowConfidenceFields={formErrors[lineItemCategoryToValidate]?.lowConfidenceFields?.totalCount ?? 0}
                            currentLowConfidenceField={currentErrorField + 1}
                            onChooseFocusType={handleChangeFocusChange}/>
                    </div>
                }>
                <div className="flex items-center gap-2 justify-center">
                    <Typography as="span" variant="BASE">Switch focus view</Typography>
                </div>
            </Tooltip>

            <ScanLineItemsFocusControls onNext={onNavigateNext}
                onPrevious={onNavigatePrevious}
                hasPrevious={getHasPrevious()}
                hasNext={getHasNext()}/>

        </div>
    </Transition>;
};

import React from 'react';
import PropTypes from 'prop-types';
import orderItemType from '../../types/orderItemType';
import utils from '../../../../utils';

const ItemOriginals = ({ isPackage, orderItemCopy, orderItem }) => {
  return (
    <div className="od-dropdown">
      <div className="col-50 original-label">
        {isPackage &&
          !!orderItem.expectedDeliveryPrice &&
          Number(orderItemCopy.packagingPrice) !==
            Number(orderItem.expectedDeliveryPrice) && (
          <span className="od-edit-label od-bold">
              Original: ${utils.roundOff2(orderItem.expectedDeliveryPrice)}
          </span>
        )}
        {!isPackage &&
          !!orderItem.expectedDeliveryPrice &&
          !!orderItem.expectedDeliverySalesQuantity &&
          Number(orderItemCopy.unitPrice) !==
            Number(
              orderItem.expectedDeliveryPrice /
                (orderItem.expectedDeliverySalesQuantity || 1)
            ) && (
          <span className="od-edit-label od-bold">
              Original: $
            {utils.roundOff2(
              orderItem.expectedDeliveryPrice /
                  (orderItem.expectedDeliverySalesQuantity || 1)
            )}
          </span>
        )}
      </div>
      {!isPackage && !!orderItem.expectedDeliveryUnitQuantity && (
        <div className="col-50 original-label">
          {orderItemCopy.unitQuantity !==
            orderItem.expectedDeliveryUnitQuantity && (
            <span className="od-edit-label od-bold">
              Original:{' '}
              {utils.roundOff2(orderItem.expectedDeliveryUnitQuantity)}/
              {orderItem.salesUnit}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemOriginals;

ItemOriginals.propTypes = {
  isPackage: PropTypes.bool.isRequired,
  orderItem: orderItemType.isRequired,
  orderItemCopy: orderItemType.isRequired,
};

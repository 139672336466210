import AddIcon from '@icons/add-icon.svg';
import InfoIcon from '@icons/info-icon.svg';
import { Button, Typography, Popover, PopoverItem, Skeleton, Banner, EBannerType } from '@notch-ordering/ui-components';
import BackIcon from '@icons/back-icon.svg';
import CloseIcon from '@icons/close-icon.svg';
import useIsMobileWidth from '@v2/hooks/useIsMobileWidth.hook';
import { useSetupStore } from '@v2/stores/SetupStore';
import cx from 'classnames';
import React, { useEffect, useRef } from 'react';

type Props = {
    children?: React.ReactNode,
    close: () => void,
    back: () => void,
    heading?: string,
    title: string,
    shortTitle: string,
    showShortTitle?: boolean,
    description?: string,
    info?: string,
    gradient?: boolean,
    hideCloseMobile?: boolean,
    mobileOptions?: PopoverItem[],
    isLoading?: boolean,
    drawerEnabled?: boolean,
};

export const SetupInfo: React.FC<Props> = ({
    children = null,
    close,
    back,
    hideCloseMobile = false,
    heading,
    shortTitle = '',
    showShortTitle = false,
    title = '',
    description = '',
    info,
    gradient = false,
    mobileOptions = [],
    isLoading = false,
    drawerEnabled = true
}: Props) => {
    const isMobileWidth: boolean = useIsMobileWidth();
    const descriptionRef = useRef<HTMLDivElement>(null);
    const { setInitialDrawerHeight } = useSetupStore();

    useEffect(() => {
        setInitialDrawerHeight(descriptionRef.current?.offsetTop);
    }, [descriptionRef]);

    if (isLoading && !isMobileWidth) {
        return (
            <div className="flex flex-row h-full w-full">
                <div className={cx(
                    'grow',
                    {
                        gradient,
                        'bg-gray-50': !gradient
                    }
                )} />
                <div className="flex flex-col grow gap-4 items-center justify-center">
                    <Skeleton size="large" className="h-24"/>
                    <Skeleton size="large" className="h-24"/>
                    <Skeleton size="large" className="h-24"/>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={cx(
                'lg:h-full w-full lg:w-6/12 py-4 px-4 lg:items-start lg:flex-none lg:py-16 lg:px-20 relative pt-safe',
                {
                    gradient,
                    'bg-gray-50': !gradient,
                    'h-full': drawerEnabled,
                    'h-32 flex items-center justify-between': !drawerEnabled
                }
            )}>
                <div className="flex items-center justify-between lg:justify-center w-full absolute left-0 lg:px-20 pl-4 pr-4 pb-1 mt-4 lg:mt-0">
                    {isMobileWidth ? (
                        <>
                            <Button
                                variant="ICON"
                                size="NO_BACKGROUND"
                                onClick={back}
                                className={cx('flex items-center justify-center')}>
                                <div className={cx('h-4 w-4', {
                                    'text-white': gradient,
                                    'text-gray-700': !gradient
                                })}>
                                    <BackIcon />
                                </div>
                            </Button>
                            <div>
                                {showShortTitle
                                    && <Typography as="div"
                                        variant="LG-2"
                                        weight="font-semibold"
                                        className={cx('m-0 text-center', { 'text-white': gradient, 'text-gray-700': !gradient })}>
                                        {shortTitle}
                                    </Typography>}
                                <Typography
                                    as="div"
                                    variant="BASE"
                                    className={cx('text-center', { 'text-gray-300': gradient, 'text-gray-600': !gradient })}>
                                    {heading}
                                </Typography>
                            </div>
                            {(isMobileWidth && mobileOptions?.length > 0)
                                && <div>
                                    <Popover
                                        className={'z-20 w-56'}
                                        button={
                                            <Button
                                                variant="ICON"
                                                size="NO_BACKGROUND"
                                                className={cx('flex items-center justify-center')}
                                                stopPropagation={false}>
                                                <div className={cx('h-4 w-4', {
                                                    'text-white': gradient,
                                                    'text-gray-700': !gradient
                                                })}>
                                                    <AddIcon />
                                                </div>

                                            </Button>
                                        }
                                        items={mobileOptions}/>
                                </div>}
                            <Button
                                onClick={close}
                                variant="ICON"
                                size="NO_BACKGROUND"
                                className={cx(
                                    'flex items-center justify-center flex-shrink-0',
                                    {
                                        hidden: hideCloseMobile
                                    }
                                )}>
                                <div className={cx('h-4 w-4', {
                                    'text-white': gradient,
                                    'text-gray-700': !gradient
                                })}>
                                    <CloseIcon />
                                </div>

                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={close}
                                variant="ICON"
                                size="NO_BACKGROUND"
                                className={cx('flex items-center justify-center flex-shrink-0',)}>
                                <div className={cx('h-4 w-4', {
                                    'text-white': gradient,
                                    'text-gray-700': !gradient
                                })}>
                                    <CloseIcon />
                                </div>
                            </Button>
                            <Typography
                                as="span"
                                className={cx('text-center w-full', { 'text-gray-300': gradient, 'text-gray-700': !gradient })}>
                                {heading}
                            </Typography>
                        </>
                    )}
                </div>
                <div className="flex flex-col h-full justify-start pt-24 lg:justify-center lg:pr-20 lg:pt-0"
                    hidden={showShortTitle && isMobileWidth}>
                    <Typography as="div"
                        variant="3XL"
                        weight="font-regular"
                        font="font-title"
                        desktopSize="lg:text-8"
                        className={cx('m-0', { 'text-white': gradient, 'text-gray-700': !gradient })}>
                        {title}
                    </Typography>
                    <Typography
                        as="div"
                        className={gradient ? 'text-white mt-4 lg:mt-6' : 'text-gray-600 mt-4 lg:mt-6'}>
                        {description}
                    </Typography>
                    {!!info && <Banner className="mt-10" alertType={EBannerType.INFO} title={info} icon={<InfoIcon className="w-4 h-4" />} />}
                    <div ref={descriptionRef} />
                </div>
            </div>
            {children}
        </>
    );
};

export default SetupInfo;

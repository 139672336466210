import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import useProduct from "../hooks/useProduct";
import useOrder from "../hooks/useOrder";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";

const CustomDropDown = ({ label, orderItem, handleVariant }) => {
  const { getProductVariants, clearProductVariants, variants, loading } =
    useProduct();
  const { vendor: { id = "" } = { id: "" } } = useOrder();
  const [options, updateOptions] = useState(false);
  const onClick = () => updateOptions((state) => !state);
  useEffect(() => {
    if (id && orderItem.productCode && !variants.length) {
      getProductVariants(id, orderItem.productCode);
    }
  }, [id, orderItem.productCode, variants]);

  useEffect(() => () => clearProductVariants(), []);

  const updateVariant = (variant) => {
    handleVariant(variant);
    updateOptions(false);
  };

  return (
    <div className="od-dropdown">
      <button
        className={cx("od-row", {
          "od-disabled": loading,
        })}
        onClick={onClick}
        disabled={loading}
      >
        <div className="od-data">
          <span className="od-edit-label">{label}</span>
          <div className="od-product">
            {orderItem.unitName} - {orderItem.unitDescription}
          </div>
        </div>
        <img
          src={`${BUCKET_ASSETS_URL}caret_down_new_icon.svg`}
          alt="carert"
          className="caret-down"
        />
      </button>
      {options && !loading && (
        <ul>
          {variants.map((variant) => {
            const onSelect = () => updateVariant(variant);
            return (
              <li key={variant.id} onClick={onSelect}>
                {variant.unit} - {variant.unitDescription}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

CustomDropDown.propTypes = {
  label: PropTypes.string.isRequired,
  orderItem: PropTypes.shape({
    productCode: PropTypes.string,
    unitName: PropTypes.string,
    unitDescription: PropTypes.string,
  }).isRequired,
  handleVariant: PropTypes.func.isRequired,
};

export default CustomDropDown;

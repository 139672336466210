export interface ILoginFormState {
    username: string,
    password: string,
    isLoading?: boolean,
}

export const LOGIN_FORM_INITIAL_STATE: ILoginFormState = {
    username: '',
    password: '',
    isLoading: false
};

export const LOGIN_FORM_LABELS = {
    username: {
        name: 'username',
        label: 'Email',
        error: 'You must enter your email address',
        placeholder: 'Enter your email address'
    },
    password: {
        name: 'password',
        label: 'Password',
        error: 'You must enter your password',
        placeholder: 'Enter your password'
    },
    login: 'Login',
    forgotPassword: 'Forgot Password'
};

import { Button, Container, Loading, Typography } from '@notch-ordering/ui-components';
import SupplierCard from '@v2/components/Shared/SupplierCard/SupplierCard';
import { Route } from 'react-router';
import { EPaths } from '@v2/constants/EPaths';
import { t, tCommon } from '@v2/i18n';
import { SupplierCatalogData } from '@v2/network/SecretShopAPI';
import NoCatalogImg from '@images/no-catalog.svg';
import React from 'react';
import { useLinkedSupplierCatalogs } from '@v2/hooks/useLinkedSupplierCatalogs';
import { sendTrackingEvent } from '@v2/utils/Tracking';
import { TrackingEvents } from '@v2/constants/Tracking';

type CatalogProps = {
    router: Route,
};

export const Catalog: React.FC<CatalogProps> = ({ router }) => {
    const { fetchQuery: supplierCatalogsQuery, supplierCatalogs } = useLinkedSupplierCatalogs();
    const { isLoading, isError } = supplierCatalogsQuery({ retry: 2, retryDelay: 10000 });

    const onClickManageCatalog = (supplierUrlsafeKey: string): void => {
        router.push(EPaths.CATALOG_MANAGEMENT.replace(':supplierUrlsafeKey', supplierUrlsafeKey));
        sendTrackingEvent({ ...TrackingEvents.supplierCardClicked, label: supplierUrlsafeKey });
    };

    const manageableCatalogs = supplierCatalogs.filter((catalog) => catalog.canControlSupplierCatalog && catalog.priceListCount < 1);

    return (
        <>
            <div className="flex justify-center w-full mt-8" hidden={!isLoading && !isError}><Loading isDark className="mt-safe" /></div>
            <Container hidden={(isLoading || isError)} className="h-min bg-white" fluid>
                <Container className="p-10">
                    <div className="flex flex-row items-start lg:items-center justify-between mb-5">
                        <Typography
                            as="h2"
                            variant="4XL"
                            size="text-6"
                            weight="font-medium"
                            className="text-gray-700 mt-0 mb-5">
                            {t('Navigation.Catalog')}
                        </Typography>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-4 gap-6 lg:pb-0">
                        {manageableCatalogs.map((catalog: SupplierCatalogData) => (
                            (catalog.canControlSupplierCatalog && catalog.priceListCount < 1)
                            && <SupplierCard key={catalog.id}
                                name={catalog.supplierName}
                                className="hover:shadow-z2-hover hover:border-gray-400 cursor-pointer"
                                onClick={(): void => { onClickManageCatalog(catalog.supplierUrlsafeKey); }}>
                                <div className="pt-1 ml-6 text-gray-600 font-body">{`${catalog.productCount} product${catalog.productCount === 1 ? '' : 's'}`}</div>
                            </SupplierCard>
                        ))}
                    </div>
                    {manageableCatalogs?.length === 0 && <div className="flex flex-col items-center justify-center mt-52">
                        <NoCatalogImg className="w-40 h-40" />
                        <Typography variant="LG-2" className="mt-6 mb-2" weight="font-semibold">
                            {t('BulkUpload.noSupplier')}
                        </Typography>
                        <Typography className="text-gray-600" weight="font-semibold">
                            {t('BulkUpload.addSuppliers')}
                        </Typography>
                        <Button variant="SECONDARY"
                            size="MEDIUM"
                            className="mt-10"
                            onClick={(): void => {
                                router.push('/');
                            }}>
                            {tCommon('Buttons.goToHome')}
                        </Button>
                    </div>
                    }
                </Container>
            </Container>
        </>
    );
};

import i18n from 'i18next';
import { tCommon, tNamespace } from '@v2/i18n';
import { BillsFilterDateRange, TBillsFilters } from '@v2/Pages/Invoices/BillsQueries.hook';
import moment from 'moment/moment';
import { EInvoiceStatus, EStripeStatus } from '@v2/constants/EInvoiceStatus';

export const tFilters = i18n.getFixedT(null, tNamespace, 'Invoices.Filters');
export type FilterSelectionItem = {
    label?: string,
    value?: string,
};

export const dateToString = (date: moment.Moment): string => date.utc().format('YYYY-MM-DD');
export const FILTERS = {
    DATE: {
        ALL: '',
        OVERDUE: 'overdue',
        THIS_WEEK: 'this_week',
        NEXT_WEEK: 'next_week',
        THIS_MONTH: 'this_month',
        NEXT_MONTH: 'next_month',
        CUSTOM: 'custom',
    },
    PAYMENT_STATUS: {
        ALL: '',
        UNPAID: 'unpaid',
        PAID: 'paid',
        REFUNDED: 'refunded',
        PROCESSING: 'processing',
    },
} as const;
export const FILTER_TYPE = {
    DUE_DATE: 'dueDate',
    ISSUED_DATE: 'issuedDate',
    CUSTOM: 'custom',
    VENDOR: 'vendor',
    PAYMENT_STATUS: 'paymentStatus',
    SEARCH_TERM: 'searchTerm',
} as const;

export type FilterType = keyof TBillsFilters;
export type FilterSelection = {
    label: string,
    type: FilterType,
    items: FilterSelectionItem[],
};

export const DEFAULT_BILL_FILTERS = {
    DATE: {
        ALL: '',
        OVERDUE: 'overdue',
        THIS_WEEK: 'this_week',
        NEXT_WEEK: 'next_week',
        THIS_MONTH: 'this_month',
        NEXT_MONTH: 'next_month',
        CUSTOM: 'custom',
    },
    PAYMENT_STATUS: {
        ALL: '',
        OPEN: EStripeStatus.OPEN,
        PROCESSING: EInvoiceStatus.PROCESSING,
        PAID: EStripeStatus.PAID,
        VOID: EStripeStatus.VOID,
        UNCOLLECTIBLE: EStripeStatus.UNCOLLECTIBLE,
        DELETED: EStripeStatus.DELETED,
        FAILED: EStripeStatus.FAILED,
        SYNCED: EStripeStatus.SYNCED
    },
} as const;

export type FilterDateTypeKey = keyof typeof DEFAULT_BILL_FILTERS['DATE'];
export type FilterDateTypeValue = typeof DEFAULT_BILL_FILTERS.DATE[FilterDateTypeKey];

export const BillsFiltersOptions: FilterSelection[] = [
    /*
    Temporarily disabling this for the release of PLAT-1522, it will be put back with PLAT-1562
    {
        type: FILTER_TYPE.PAYMENT_STATUS,
        label: tFilters('status'),
        items: [
            { label: tCommon('Statuses.open'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.OPEN }, // invoices with 'unpaid' notch status and no Stripe status
            { label: tCommon('Statuses.processing'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.PROCESSING }, // invoice with either 'open' or 'draft' Stripe status
            { label: tCommon('Statuses.paid'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.PAID },
            { label: tCommon('Statuses.void'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.VOID },
            { label: tCommon('Statuses.uncollectible'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.UNCOLLECTIBLE },
            { label: tCommon('Statuses.deleted'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.DELETED },
            { label: tCommon('Statuses.failed'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.FAILED },
            { label: tCommon('Statuses.synced'), value: DEFAULT_BILL_FILTERS.PAYMENT_STATUS.SYNCED },
        ],
    },
    */
    {
        type: FILTER_TYPE.DUE_DATE,
        label: tFilters('dueDate'),
        items: [
            { label: tCommon('Filters.DateRange.overdue'), value: DEFAULT_BILL_FILTERS.DATE.OVERDUE },
            { label: tCommon('Filters.DateRange.thisWeek'), value: DEFAULT_BILL_FILTERS.DATE.THIS_WEEK },
            { label: tCommon('Filters.DateRange.nextWeek'), value: DEFAULT_BILL_FILTERS.DATE.NEXT_WEEK },
            { label: tCommon('Filters.DateRange.thisMonth'), value: DEFAULT_BILL_FILTERS.DATE.THIS_MONTH },
            { label: tCommon('Filters.DateRange.nextMonth'), value: DEFAULT_BILL_FILTERS.DATE.NEXT_MONTH },
            { label: tCommon('Filters.DateRange.selectRange'), value: DEFAULT_BILL_FILTERS.DATE.CUSTOM },
        ],
    },
    {
        type: FILTER_TYPE.ISSUED_DATE,
        label: tFilters('issuedDate'),
        items: [
            { label: tCommon('Filters.DateRange.thisWeek'), value: DEFAULT_BILL_FILTERS.DATE.THIS_WEEK },
            { label: tCommon('Filters.DateRange.nextWeek'), value: DEFAULT_BILL_FILTERS.DATE.NEXT_WEEK },
            { label: tCommon('Filters.DateRange.thisMonth'), value: DEFAULT_BILL_FILTERS.DATE.THIS_MONTH },
            { label: tCommon('Filters.DateRange.nextMonth'), value: DEFAULT_BILL_FILTERS.DATE.NEXT_MONTH },
            { label: tCommon('Filters.DateRange.selectRange'), value: DEFAULT_BILL_FILTERS.DATE.CUSTOM },
        ],
    },
];

export const getStartEndDate = (dateFilter: FilterDateTypeValue):BillsFilterDateRange => {
    const dates: BillsFilterDateRange = {
        startDate: dateToString(moment()),
        endDate: dateToString(moment()),
        type: DEFAULT_BILL_FILTERS.DATE.ALL
    };

    const day = 'day';
    const month = 'month';
    const week = 'week';

    switch (dateFilter) {
        case DEFAULT_BILL_FILTERS.DATE.OVERDUE:
            dates.startDate = dateToString(moment('0001-01-01'));
            dates.endDate = dateToString(moment().endOf(day).subtract(1, day));
            dates.type = DEFAULT_BILL_FILTERS.DATE.OVERDUE;
            break;
        case DEFAULT_BILL_FILTERS.DATE.THIS_MONTH:
            dates.startDate = dateToString(moment().startOf(month));
            dates.endDate = dateToString(
                moment().add(1, month).startOf(month).subtract(1, day)
            );
            dates.type = DEFAULT_BILL_FILTERS.DATE.THIS_MONTH;
            break;
        case DEFAULT_BILL_FILTERS.DATE.NEXT_MONTH:
            dates.startDate = dateToString(moment().add(1, month).startOf(month));
            dates.endDate = dateToString(moment().add(1, month).endOf(month));
            dates.type = DEFAULT_BILL_FILTERS.DATE.NEXT_MONTH;
            break;
        case DEFAULT_BILL_FILTERS.DATE.THIS_WEEK:
            dates.startDate = dateToString(moment().startOf(week));
            dates.endDate = dateToString(
                moment().add(1, week).startOf(week).subtract(1, day)
            );
            dates.type = DEFAULT_BILL_FILTERS.DATE.THIS_WEEK;
            break;
        case DEFAULT_BILL_FILTERS.DATE.NEXT_WEEK:
            dates.startDate = dateToString(moment().add(1, week).startOf(week));
            dates.endDate = dateToString(moment().add(1, week).endOf(week));
            dates.type = DEFAULT_BILL_FILTERS.DATE.NEXT_WEEK;
            break;
        default:
            return { startDate: '', endDate: '', type: DEFAULT_BILL_FILTERS.DATE.ALL };
    }
    return dates;
};

export const getFilterLabel = (filterType: FilterType, value: string): string => {
    const filter = BillsFiltersOptions.find((f) => f.type === filterType);
    if (!filter) {
        return '';
    }
    const item = filter.items.find((i) => i.value === value);
    return item ? item.label : '';
};

export const formatDateRange = (dateRange:BillsFilterDateRange, format = 'MM/DD/YYYY'):string => `${moment(dateRange.startDate).format(format)} - ${moment(dateRange.endDate).format(format)}`;

import { Modal, onMobileSetLarge, Typography, Button, Separator, } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ForbiddenIcon from '@icons/forbidden-icon.svg';

type ManagePermissionRequiredModal = {
    isOpen: boolean,
    close: () => void,
};

/**
 * Modal that appears if a buyer does not have permissions to pay bills.
 * This permission is set in the Buyer Configuration in Jarvis.
 */
export const PermissionRequiredModal: React.FC<ManagePermissionRequiredModal> = ({
    isOpen = false,
    close,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });

    return <Modal
        modalSize={onMobileSetLarge('SMALL')}
        title={<div className="flex justify-center items-center w-8 h-8 bg-orange-100 rounded-lg">
            <ForbiddenIcon className="w-4 h-4 text-orange-400"/>
        </div>}
        headerPadding="pt-0 pb-2"
        desktopModalWidth="lg:w-[372px]"
        isOpen={isOpen}
        close={close}>
        <div className="w-full flex flex-col px-5">
            <Typography className="m-0 mb-2"
                variant="LG-2"
                weight="font-semibold">
                {t('permissionRequired')}
            </Typography>
            <Typography className="m-0 mb-5 text-gray-600">
                {t('permissionRequiredDescription')}
            </Typography>
        </div>
        <Separator />
        <div className="flex flex-row justify-end gap-x-3 px-5 mt-5">
            <Button
                variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={close}>
                {tCommon('Buttons.close')}
            </Button>
        </div>
    </Modal>;
};

import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

const ReceivingToolWebFooter = ({
  text,
  onClick,
  className,
  disabled,
  loading,
  clearClick,
}) => {
  return (
    <div
      className={cx('od-web-footer', {
        'od-web-footer-abs': !!clearClick,
      })}
    >
      {clearClick && (
        <button
          className="clear-changes"
          onClick={clearClick}
          disabled={disabled}
        >
          <span>Clear changes</span>
        </button>
      )}

      <button
        className={cx({
          [`${className}`]: true,
          'disable-button': disabled,
        })}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? (
          <i
            style={{ fontSize: '16px', color: 'white' }}
            className="spin-spinner fa fa-circle-o-notch"
          />
        ) : (
          <span>{text}</span>
        )}
      </button>
    </div>
  );
};

ReceivingToolWebFooter.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  clearClick: PropTypes.func,
};

ReceivingToolWebFooter.defaultProps = {
  loading: false,
  clearClick: null,
};

export default ReceivingToolWebFooter;

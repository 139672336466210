import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import cx from "classnames";
import useSearch from "../hooks/useSearch";
import useOrder from "../hooks/useOrder";
import utils from "../../../utils";
import { customStyles } from "@/constants/CustomStyles";
import { BUCKET_ASSETS_URL } from "@v2/constants/Assets";

const ReceivingToolSearch = ({ items, updateOrder, isEditable, isWeb }) => {
  const {
    search: { loading, results },
    searchProducts,
    clearSearch,
  } = useSearch();
  const {
    vendor: { urlsafe: supplierUrlsafeKey = "" } = { urlsafe: "" },
    order: {
      data: { buyer: { id: buyerId, urlsafe: buyerUrlsafeKey } } = {
        items: [],
      },
    },
  } = useOrder();

  const [showModal, updateShowModal] = useState(false);
  const openModal = () => updateShowModal(true);
  const closeModal = () => updateShowModal(false);

  const [searchTerm, updateSearchTerm] = useState("");
  const delayedFunction = useCallback(
    _.debounce((searchTerm) => {
      searchProducts({ searchTerm, buyerUrlsafeKey, supplierUrlsafeKey });
    }, 500),
    []
  );
  const onChange = ({ target: { value } }) => {
    updateSearchTerm(value);
    if (value) delayedFunction(value);
    else clearSearch();
  };

  const searchInputRef = useRef(null);
  const focusInput = useCallback(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef]);

  useEffect(() => {
    let t;
    if (showModal) {
      t = setTimeout(focusInput, 300);
    }
    return () => clearTimeout(t);
  }, [showModal]);

  useEffect(() => () => clearSearch(), []);

  const handleVariant = (variant, variantPackage) => {
    const newProduct = {
      imageUrl: variant.imageUrl,
      name: variant.name,
      productCode: variant.id,
      unitName: variant.packagingName,
      unitDescription: variant.description,
      packagingDescription: variantPackage?.description,
      salesUnit: variantPackage?.unit,
      externalId: variant.externalId,
      packagingQuantity: 1,
      packagingPrice: variantPackage?.pricePerUnit,
      unitQuantity: variantPackage?.quantity,
      originalUnitQuantity: null,
      unitPrice: variantPackage?.pricePerUnit,
      total: variantPackage?.pricePerUnit * variantPackage?.quantity,
      productId: variant.id,
      sourceId: null,
      isNew: true,
      receivalIssueChange: "Item substituted",
      receivalIssueDescription: "",
      unit: variantPackage?.unit,
    };

    updateOrder([...items, newProduct]);
  };

  const closeAndClear = () => {
    closeModal();
    clearSearch();
    updateSearchTerm("");
  };

  const onClick = async (product) => {
    handleVariant(product, product.packages ? product.packages[0] : null);
    closeAndClear();
  };

  if (!showModal) {
    return (
      <div
        className={cx("search-container search-mobile-container", {
          "item-disabled": isEditable,
        })}
      >
        <button onClick={openModal}>
          <img src={`${BUCKET_ASSETS_URL}search-icon.svg`} alt="Search Icon" />
          <span>Search an item to add</span>
        </button>
      </div>
    );
  }

  const productCodes = items.map(({ productCode }) => productCode);

  return (
    <Modal isOpen={showModal} style={customStyles} ariaHideApp={false}>
      <div
        className={cx("search-container", {
          "search-mobile-container": !isWeb,
          "search-web-container": isWeb,
        })}
      >
        <button onClick={focusInput}>
          <img src={`${BUCKET_ASSETS_URL}search-icon.svg`} alt="Search Icon" />
          <input
            value={searchTerm}
            onChange={onChange}
            placeholder="Search item to add"
            ref={searchInputRef}
          />
          <img
            src={`${BUCKET_ASSETS_URL}close-icon.svg`}
            alt="close icon"
            onClick={closeAndClear}
          />
        </button>
        {loading && (
          <div className="search-result-loading">
            <i
              style={{ fontSize: "26px" }}
              className="spin-spinner fa fa-circle-o-notch"
            />
          </div>
        )}
        {!loading && !!results.length && (
          <div className="search-result-list">
            {results.map((result) => {
              if (productCodes.includes(result.productId)) {
                return null;
              }

              return (
                <button
                  className="search-result"
                  onClick={() => onClick(result)}
                  key={result.id}
                >
                  <div className="product-img-holder">
                    <img src={result.imageUrl} alt={result.name} />
                  </div>
                  <div className="product-details">
                    <div className="header">{result.name}</div>
                    <div className="sub-header">{result.description}</div>
                    <div className="content">{result.externalId}</div>
                  </div>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ReceivingToolSearch;

ReceivingToolSearch.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      productCode: PropTypes.string,
    })
  ).isRequired,
  isEditable: PropTypes.bool.isRequired,
  updateOrder: PropTypes.func.isRequired,
  isWeb: PropTypes.bool.isRequired,
};

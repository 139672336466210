import { Button, Tooltip } from '@notch-ordering/ui-components';
import React, { useState } from 'react';
import { tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';
import PlusIcon from '@icons/add-icon.svg';
import { AttachmentThumbnail } from './AttachmentThumbnail';

export interface Props {
    orderImages?: { image_serving_url: string, image_key:string, image_url: string }[],
    onClick?: () => void,
}

export const AttachBillButton: React.FC<Props> = ({ orderImages, onClick = (): void => {}, }) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Orders' });
    const [attachToolTip, setAttachToolTip] = useState<boolean>(false);

    return (
        <>
            {orderImages?.length > 0
                ? <div className="flex justify-center items-center w-10 h-10 bg-transparent">
                    <AttachmentThumbnail pdfHeight={'h-10'}
                        pdfWidth={'w-10'}
                        imageSize={40}
                        smallThumbnail={true}
                        url={orderImages?.[0].image_serving_url} />
                </div>
                : <Tooltip
                    show={attachToolTip}
                    className="flex"
                    tooltipClassName={'py-1 px-2 rounded-lg'}
                    showArrow={false}
                    onShow ={(): void => { setAttachToolTip(true); }}
                    onHide ={(): void => { setAttachToolTip(false); }}
                    placement="top"
                    trigger={<Button
                        variant="TERTIARY_FILLED"
                        className="flex justify-center items-center"
                        size="ICON_MEDIUM"
                        onClick={():void => {
                            onClick();
                        }}>
                        <div className={'lg:w-4 lg:h-4 w-5 h-5'}>
                            <PlusIcon className="h-4 w-4" />
                        </div>
                    </Button>}>
                    {t('attach')}
                </Tooltip>}
        </>
    );
};

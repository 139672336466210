import { Modal, onMobileSetLarge, Typography } from '@notch-ordering/ui-components';
import CelebrateImg from '@images/celebrate.svg';
import QRCodeNotchApp from '@images/qr-code-notch-app.svg';
import React from 'react';
import { tNamespace } from '@v2/i18n';
import { useTranslation } from 'react-i18next';

type Props = {
    isOpen: boolean,
    close: () => void,
};

export const DownloadNotchAppModal: React.FC<Props> = ({
    isOpen = false,
    close
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Invoices' });

    return (
        <Modal
            hideHeader
            modalSize={onMobileSetLarge('MEDIUM')}
            desktopModalWidth="lg:w-[560px]"
            isOpen={isOpen}
            close={close}>
            <div className = "flex flex-col justify-center items-center">
                <CelebrateImg className="h-[120px] w-24 my-10" />
                <Typography className="pb-4" variant="3XL">{t('downloadNotch')}</Typography>
                <Typography className="text-gray-600 pb-[30px] px-14 text-center">{t('scanQRCode')}</Typography>
                <QRCodeNotchApp className="mb-5 w-[180px] h-[180px]" />
            </div>
        </Modal>);
};

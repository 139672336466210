import React, { useContext, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import Header from '../components/ChatChannelHeader.jsx';
import Body from '../components/ChatChannelBody.jsx';
import Footer from '../components/ChatChannelFooter.jsx';
import { ChatChannelsListContext } from '../context';
import { setChannelMessagesAsRead } from '../actions/chatChannelsListActions.js';
import { setChatSelected } from '../actions/chatSelectedActions';
import {
  formatDateTimePerChatChannelList,
  formatDateTimePerChatChannelOrderStatus,
  sortChannelListHandler,
} from '../helpers/chatClientHelper.js';
import { FIRST_MESSAGE } from '../../../constants/GlobalConstants';

export default () => {
  const context = useContext(ChatChannelsListContext);
  const [channelList, setChannelList] = useState([]);
  const {
    chatSelected: { sid: selectedChatSid },
    auth: { isSendingMessage },
  } = useSelector((store) => store.chat);

  const dispatch = useDispatch();
  const chatListClickHandler = (sid) => {
    batch(() => {
      dispatch(setChannelMessagesAsRead(sid));
      dispatch(setChatSelected(sid));
    });
  };

  const singleView = selectedChatSid;
  const refs = Object.entries(context.list).reduce((acc, [sid]) => {
    acc[sid] = React.createRef();
    return acc;
  }, {});

  useEffect(() => {
    // Below function is used to auto scroll to the selected chat channel
    setTimeout(() => {
      if (refs[selectedChatSid] && refs[selectedChatSid].current)
        refs[selectedChatSid].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    }, 0);
  }, []);

  useEffect(() => {
    // Sort and consolidate channel list into a single obj each instead of an array
    let channels = Object.entries(context.list)
      .sort(sortChannelListHandler)
      .map((c) => {
        return {
          sid: c[0],
          ...c[1].channelAttributes
        };
      });

    // Remove duplicates when network is slow. Always use the later instance of a vendor channel
    channels = channels.filter((channel, index) => {
      const nextIndex = channels.findIndex((c) =>
      channel !== c && c.vendorName === channel.vendorName);

      return nextIndex < 0 || index > nextIndex;
    });

    setChannelList(channels);
  }, [context.list])

  return (
    <div
      className={cx('chat-channels-list-container', 'chat-view', {
        'hidden-mobile': singleView,
      })}
    >
      
      {channelList
        .map((channelAttributes) => {
          const {
            vendorName,
            orderNumber,
            orderStatus,
            timestamp,
            lastMessage = '',
            lastMessageIndex,
            lastConsumedMessageIndex,
            orderDate,
            deliveryDate,
            sid
          } = channelAttributes;

          const text = lastMessage === FIRST_MESSAGE ? '' : lastMessage;
          const selected = sid === selectedChatSid;
          const unread =
            selected && isSendingMessage
              ? 0
              : lastMessageIndex - (lastConsumedMessageIndex || 0);

          return (
            vendorName && <div
              key={sid}
              ref={refs[sid]}
              className={cx('channel', {
                selected,
              })}
              onClick={() => chatListClickHandler(sid)}
            >
              <Header
                title={vendorName}
                time={formatDateTimePerChatChannelList(timestamp)}
                unread={unread}
              />

              <Body orderNumber={`${orderNumber}`} text={text} />

              {orderNumber && (
                <Footer
                  status={orderStatus}
                  orderDate={formatDateTimePerChatChannelOrderStatus(orderDate)}
                  deliveryDate={formatDateTimePerChatChannelOrderStatus(
                    deliveryDate
                  )}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

import { FileOpener } from '@capacitor-community/file-opener';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { isPlatform } from '@ionic/react';
import { TBillsFilters,
    TBillsPagination, } from '@v2/Pages/Invoices/BillsQueries.hook';
import { throwAxiosError } from '@v2/utils/AxiosUtils';
import { PDF_CONTENT_TYPE, blobToBase64 } from '@v2/utils/PDF';
import axios, { AxiosResponse } from 'axios';
import { PDFDocument } from 'pdf-lib';
import { EApPlatform } from '@notch-ordering/shared-logic';
import { EIonicPlatforms } from '@/constants/Mobile';
import { InvoiceOrderSyncDetails } from './CoreAPI';
import { IPagination } from './types';

const { greevilsGreedApi } = process.env.BASE_URLS;

type UploadOfflineInvoicesParams = {
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
    invoices: File[],
};

type UploadOfflineInvoicesResponse = {
    id: string,
    cdnUris: string[],
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
    ocrUploadID: string,
};

export async function uploadOfflineInvoices({
    buyerUrlsafeKey,
    supplierUrlsafeKey,
    invoices,
}: UploadOfflineInvoicesParams): Promise<UploadOfflineInvoicesResponse> {
    const url = `${greevilsGreedApi}/uploads/offlineInvoices`;
    const body = new FormData();
    invoices.forEach((invoice) => {
        body.append('invoices', invoice);
    });
    body.append('buyerUrlsafeKey', buyerUrlsafeKey);
    body.append('supplierUrlsafeKey', supplierUrlsafeKey);
    body.append('isDualSync', 'true');
    const response: AxiosResponse = await axios
        .post(url, body, {
            headers: {
                'content-type': 'application/pdf',
            },
        })
        .catch((e) => throwAxiosError(e));

    return response.data;
}
export type ResyncOfflineInvoicesParams = {
    buyerUrlsafeKey: string,
    invoiceID: string,
};
export async function reSyncOfflineInvoice({
    buyerUrlsafeKey,
    invoiceID,
}: ResyncOfflineInvoicesParams): Promise<void> {
    const url = `${greevilsGreedApi}/resyncInvoice`;
    return axios
        .post(url, {
            buyerUrlsafeKey,
            id: invoiceID,
        })
        .catch((e) => throwAxiosError(e, 'Could not search catalog'));
}

export type GenerateInvoiceParams = {
    id?: string,
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
    orderUrlsafeKey: string,
    deliveryDate: string, // YYYY-MM-DD
    issuedDate: string, // YYYY-MM-DD
    dueDate: string, // YYYY-MM-DD
    invoiceNumber: string,
    lineItems: InvoiceLineItem[],
    shipToAddress: InvoiceAddress,
    billToAddress: InvoiceAddress,
    payToAddress: InvoiceAddress,
    isApprovedForBigChip: boolean,
};

export type InvoiceLineItem = {
    name: string,
    quantity: number,
    pricePerUnit: number,
    packageSize: number,
    sku: string,
    description: string,
    unit: string,
    subtotal: number,
    tax: number,
    taxRate: number,
    total: number,
    glCodeID?: string,
    taxCodeID?: string,
};

export async function generateInvoice(
    invoice: GenerateInvoiceParams
): Promise<GenerateInvoiceParams> {
    const url = `${greevilsGreedApi}/invoices`;
    const response = await axios
        .post(url, invoice)
        .catch((e) => throwAxiosError(e, 'Could not generate invoice'));
    return response.data;
}

// Download and open PDF
export async function getInvoicePDF(
    invoiceID: string,
    buyerUrlsafeKey: string
): Promise<void> {
    const url = `${greevilsGreedApi}/invoices/${invoiceID}/pdf`;
    const response: AxiosResponse = await axios
        .get(url, { params: { buyerUrlsafeKey }, responseType: 'blob' })
        .catch((e) => throwAxiosError(e, 'Could not generate invoice'));
    const pdf = response.data;
    const fileName = `${invoiceID}.pdf`;

    if (isPlatform(EIonicPlatforms.CAPACITOR)) {
        const pdfFile = new File([response.data], fileName);
        const pdfDoc = await PDFDocument.load(await pdfFile.arrayBuffer());
        const pdfBytes = await pdfDoc.save();
        const data = (await blobToBase64(
            new Blob([pdfBytes], { type: PDF_CONTENT_TYPE })
        )) as string;

        const file = await Filesystem.writeFile({
            data,
            path: fileName,
            directory: Directory.Documents,
        });
        await FileOpener.open({
            filePath: file.uri,
            contentType: PDF_CONTENT_TYPE,
        });
        // Removes the file from storage once it was been closed
        await Filesystem.deleteFile({
            path: file.uri,
            directory: Directory.Documents,
        });
    } else {
        const blob = URL.createObjectURL(pdf);
        const a = document.createElement('a');
        a.href = blob;
        a.target = '_blank';
        a.download = fileName;
        a.style.display = 'none';
        document.body.append(a);
        a.click();
    }
}

export type InvoiceAddress = {
    id?: string,
    name: string,
    address: string,
    city: string,
    state: string,
    zip?: string,
    country?: string,
    email?: string,
    phoneNumber?: string,
};

export type InvoiceData = {
    id: string,
    createdAt: string,
    buyerUrlsafeKey: string,
    supplierUrlsafeKey: string,
    qboVendorID: string, // Stored on greevils-greed-api and be-core
    uploadID: string,
    deliveryDate: string,
    issuedDate: string,
    dueDate: string,
    invoiceNumber: string,
    classID: string,
    orderUrlsafeKey: string,
    isTwoWayAccounting: boolean,
    isApprovedForBigChip: boolean,
    lastEditedDateByAccounting: string,
    shipToAddress: InvoiceAddress,
    billToAddress: InvoiceAddress,
    payToAddress: InvoiceAddress,
    lineItems: InvoiceLineItem[],
    payments: InvoicePayment[],
    amountPaid: number,
    invoiceTotal: number,
    orderSyncDetails?: InvoiceOrderSyncDetails,
    lastSyncedToIMS?: string,
    lastSyncedToAccounting?: string,
    accountingSyncName?: string,
    imsSyncName?: string,
    approvedToPay: boolean,
};

export type InvoicePayment = {
    invoiceID: string,
    amount: number,
    source: 'stripe' | EApPlatform,
    description: string,
    externalReferenceID: string,
    chargeDate?: string,
    paymentMethod?: string,
    paymentMethodBrand?: string,
};

export type InvoicesResponse = IPagination & {
    results: InvoiceData[],
};

export async function listInvoices(
    limit: number,
    buyerUrlsafeKey: string,
    filters?: TBillsFilters,
    cursor?: string
): Promise<InvoicesResponse> {
    const url = `${greevilsGreedApi}/invoices`;
    const response = await axios
        .get(url, {
            params: {
                limit,
                cursor,
                buyerUrlsafeKey,
                invoiceNumber: filters?.search[0],
                supplierUrlsafeKey: filters?.supplierUrlsafeKey,
                dueDate: filters?.dueDate,
                issuedDate: filters?.issuedDate,
            },
        })
        .catch((e) => throwAxiosError(e, 'Error fetching invoices'));
    return response.data;
}

export async function changeApprovedToPay(buyerUrlsafeKey: string, invoiceIDs: string[], approvedToPay: boolean): Promise<InvoiceData[]> {
    const url = `${greevilsGreedApi}/invoices/approvedToPay/bulk`;
    const response: AxiosResponse<InvoiceData[]> = await axios.put(url, { buyerUrlsafeKey, invoiceIDs, approvedToPay })
        .catch((e) => throwAxiosError(e));
    return response.data;
}

export async function searchInvoices(
    buyerUrlsafeKey: string,
    filters?: TBillsFilters,
    pagination?: TBillsPagination
): Promise<InvoicesResponse> {
    const url = `${greevilsGreedApi}/invoices/search`;
    const { limit, nextCursor } = pagination;
    const response = await axios
        .post(
            url,
            {
                buyerUrlsafeKey,
                invoiceNumbers: filters?.search,
                supplierUrlsafeKey: filters?.supplierUrlsafeKey,
                orderUrlsafeKey: filters?.orderUrlsafeKey,
                dueDate: filters?.dueDate,
                issuedDate: filters?.issuedDate,
            },
            { params: { cursor: nextCursor, limit } }
        )
        .catch((e) => throwAxiosError(e, 'Error fetching invoices'));
    return response.data;
}

export async function getInvoiceByID(
    buyerUrlsafeKey: string,
    id: string,
): Promise<InvoiceData[]> {
    const url = `${greevilsGreedApi}/invoicesByIDs`;
    const response = await axios
        .get(url, {
            params: {
                buyerUrlsafeKey,
                ids: id,
            },
        })
        .catch((e) => throwAxiosError(e, 'Error fetching invoices'));
    return response.data;
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class InlineConfirm extends Component {
  constructor(props) {
    super(props);

    this.onChildClick = this.onChildClick.bind(this);
    this.sendConfirmation = this.sendConfirmation.bind(this);

    this.state = {
      showConfirmation: false,
    };
  }

  sendConfirmation(event) {
    event.preventDefault();
    const confirm = event.target.value;

    if (confirm === 'yes') {
      // We pressed yes, pass up to the parent component
      // this info to do whatever action it needs.
      this.props.confirmedYes();
    } else if (confirm === 'no' && this.props.confirmedNo) {
      this.props.confirmedNo();
    }

    this.setState({ showConfirmation: false });
  }

  onChildClick() {
    this.setState({ showConfirmation: true });
  }

  render() {
    const { confirmText } = this.props;

    return (
      <span>
        {this.state.showConfirmation ? (
          <span className="inline-confirm">
            <span className="inline-confirm-text">
              {confirmText || 'Are you sure?'}{' '}
            </span>
            <button
              onClick={this.sendConfirmation}
              className="btn btn-default btn-sm no"
              value="no"
            >
              No
            </button>
            <button
              onClick={this.sendConfirmation}
              className="btn btn-default btn-sm yes"
              value="yes"
            >
              Yes
            </button>
          </span>
        ) : (
          <span onClick={this.onChildClick}>{this.props.children}</span>
        )}
      </span>
    );
  }
}

InlineConfirm.propTypes = {
  confirmText: PropTypes.string,

  confirmedYes: PropTypes.func.isRequired,
  confirmedNo: PropTypes.func,
};

import { Modal, onMobileSetLarge, Typography, Button, } from '@notch-ordering/ui-components';
import { tNamespace } from '@v2/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertIcon from '@icons/alert-icon.svg';
import { useOcrInvoiceContext } from '@v2/Pages/ScanReview/ScanReviewContext';
import { LineItemCategory, useOcrInvoiceStore } from '@notch-ordering/shared-logic';
import { getLineItemCategory } from '@v2/Pages/Integrations/IntegrationsUtils';
import { useBuyerHasFeatureFlagEnabled } from '@v2/hooks/useBuyerHasFeatureFlagEnabled.hook';
import { EFirebaseParams } from '@v2/constants/EFirebaseParams';

type ManageIntegrationProps = {
    isOpen: boolean,
    close: () => void,
    onSaveOrDiscard: () => void,
};

/**
 * Modal that appears if you try to leave Scan Overview page without saving changes.
 */
export const ScanUnsavedChangesModal: React.FC<ManageIntegrationProps> = ({
    isOpen = false,
    close,
    onSaveOrDiscard,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'ScanReview' });
    const { onSaveOcrInvoice, selectedTab, hasImsIntegration } = useOcrInvoiceContext();
    const { draftInvoice } = useOcrInvoiceStore();

    const useSeparateIMSLineItems = useBuyerHasFeatureFlagEnabled(EFirebaseParams.OCR_IMS_SEPARATE_LINE_ITEMS);
    const lineItemCategory: LineItemCategory = getLineItemCategory(selectedTab, draftInvoice?.accountingConfiguration?.data?.syncDataType, useSeparateIMSLineItems, false, hasImsIntegration);

    return <Modal
        modalSize={onMobileSetLarge('SMALL')}
        title={<div className="flex justify-center items-center w-8 h-8 bg-red-100 rounded-lg">
            <AlertIcon className="w-4 h-4 text-red-300"/>
        </div>}
        desktopModalWidth="lg:w-[450px]"
        isOpen={isOpen}
        close={close}>
        <div className="w-full flex flex-col px-5">
            <Typography className="m-0 mb-2" variant="LG-2" weight="font-semibold">{t('unsavedChanges')}</Typography>
            <Typography className="m-0 text-gray-600">{t('unsavedDescription')}</Typography>
        </div>
        <div className="flex flex-row justify-end gap-x-3 px-5 mt-10">
            <Button
                variant="DESTRUCTIVE"
                size="SMALL"
                onClick={():void => {
                    close();
                    onSaveOrDiscard();
                }}>
                {t('discardChanges')}
            </Button>
            <Button
                variant="SECONDARY"
                size="SMALL"
                onClick={():void => {
                    onSaveOcrInvoice({ onSuccess: () => {
                        onSaveOrDiscard();
                    },
                    ocrState: draftInvoice?.invoiceData?.ocrState,
                    verifyOnSave: false,
                    syncAccountingOnSave: false,
                    syncIMSOnSave: false,
                    hasIMSIntegration: false,
                    hasAccountingIntegration: false,
                    accountingSyncName: null,
                    imsSyncName: null,
                    lineItemCategory,
                    triggeredBy: '[fe-buyer] ScanUnsavedChangesModal' });
                }}>
                {t('saveChanges')}
            </Button>
        </div>
    </Modal>;
};

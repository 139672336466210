import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router';
import { CheckoutFlowWebViewEvents } from '../../../constants';
import './styles.scss';
import {EPaths} from "@v2/constants/EPaths";

const CheckoutPageHeader = ({
  order: { isInShoppingCart },
  location,
  router,
}) => {

  const cancelOrCloseWebView = () => {
    router.push({
      pathname: location.pathname,
      query: {
        event: isInShoppingCart
          ? CheckoutFlowWebViewEvents.CANCEL
          : CheckoutFlowWebViewEvents.CLOSE,
        ...location.query,
      },
    });
  };

  const renderLogo = () => {
    const logo = (
      <div className="w-16">
        <img
          src="https://storage.googleapis.com/chefhero-storage/static/fe-buyer/images/notch-primary-logo.svg"
          alt="Notch Logo"
        />
      </div>
    );

    return <Link to={EPaths.HOME}>{logo}</Link>;
  };

  return (
    <div className="pt-10 pb-14">
      {renderLogo()}
    </div>
  );
};

CheckoutPageHeader.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.object,
  }).isRequired,
  router: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  order: PropTypes.shape({
    isInShoppingCart: PropTypes.bool,
  }).isRequired,
};

export default withRouter(CheckoutPageHeader);

import React, { useContext } from 'react';
import { Modal, Button, Typography, Separator, toast, onMobileSetLarge } from '@notch-ordering/ui-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteOrderGuide } from '@v2/network/SecretShopAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { OrderGuide } from '@v2/network/types';
import { MarketContext } from '@v2/Pages/Market/MarketPageContext';
import CheckIcon from '@icons/check-icon.svg';
import { GET_ORDER_GUIDES, useGetOrderGuide } from '@v2/Pages/Market/MarketQueries.hook';
import { tCommon } from '@v2/i18n';

export interface OrderGuideAddModalProps {
    onClose?: () => void,
    orderGuide?: OrderGuide,
}

export const DeleteOrderGuideModal : React.FC<OrderGuideAddModalProps> = ({ onClose = (): void => null, }) => {
    const { buyer } = useBuyerHook();
    const { modals, setModalVisibility, orderGuideIDSelected, setOrderGuideIDSelected } = useContext(MarketContext);
    const queryClient = useQueryClient();
    const { data: orderGuideSelected } = useGetOrderGuide({ buyerId: buyer.urlsafe, orderGuideID: orderGuideIDSelected });
    const closeModal = ():void => {
        setModalVisibility('deleteOrderGuide', false);
        setOrderGuideIDSelected(null);
        onClose();
    };

    const onSuccess = (): void => {
        // this will reload the order guides list
        queryClient.invalidateQueries([GET_ORDER_GUIDES, buyer.urlsafe]);

        closeModal();
        const message = 'Successfully Deleted';
        toast.show({
            icon: <CheckIcon />,
            message
        });
    };

    const { mutate: deleteOrderGuideMutation, isLoading } = useMutation(deleteOrderGuide, {
        onSuccess
    });

    const isButtonDisabled = isLoading;
    const handleDeleteOrderGuide = (): void => {
        deleteOrderGuideMutation({
            buyerUrlsafeKey: buyer.urlsafe,
            orderGuideID: orderGuideIDSelected
        });
    };

    return (
        <Modal isOpen={modals.deleteOrderGuide}
            close={closeModal}
            onClose={closeModal}
            modalSize={onMobileSetLarge('MEDIUM', true)}
            title={'Delete order guide'}>

            <div className="w-full h-full flex flex-col gap-3 overflow-auto w-full px-4 lg:px-8 ">
                <Typography className="m-0">
                    Are you sure you want to delete the “{orderGuideSelected?.name}”  order guide? Any products within it can be found in the “All saved products” collection.
                </Typography>
            </div>
            <Separator className="mt-4" />
            <div className={'px-4 lg:px-8 pt-5 mt-auto sticky bottom-0'}>
                <div className="flex items-center justify-between  gap-3">
                    <Button variant="TERTIARY_FILLED"
                        size="MEDIUM"
                        className={'w-full'}
                        onClick={closeModal}
                        disabled={isButtonDisabled}
                        loading={isLoading}>
                        {tCommon('Buttons.cancel')}
                    </Button>
                    <Button variant="DESTRUCTIVE"
                        size="MEDIUM"
                        className="w-full"
                        onClick={handleDeleteOrderGuide}
                        disabled={isButtonDisabled}
                        loading={isLoading}>
                        {tCommon('Buttons.delete')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

import { Modal, onMobileSetLarge, Typography, Button, toast, } from '@notch-ordering/ui-components';
import { tCommon, tNamespace } from '@v2/i18n';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EApPlatform } from '@notch-ordering/shared-logic';
import InfoIcon from '@icons/info-icon.svg';
import CheckIcon from '@icons/check-icon.svg';
import { revokeAccountingAccess } from '@v2/network/BushwhackAPI';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { logException } from '@/domains/shared/logger';

type ManageIntegrationProps = {
    isOpen: boolean,
    close: () => void,
    refetch: () => void,
    disconnectSuccess?: () => void,
    accessToken: string,
    platform: EApPlatform,
    integrationType: string,
};

/**
 * Modal that appears if you choose Disconnect from the Manage Integrations Modal.
 */
export const DisconnectIntegrationModal: React.FC<ManageIntegrationProps> = ({
    isOpen = false,
    close,
    refetch,
    disconnectSuccess,
    accessToken,
    platform,
    integrationType,
}) => {
    const { t } = useTranslation(tNamespace, { keyPrefix: 'Integrations' });
    const { buyer } = useBuyerHook();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function disconnectAccount(): void {
        setIsLoading(true);
        revokeAccountingAccess(platform, buyer.urlsafe, accessToken)
            .then(() => {
                setIsLoading(false);
                disconnectSuccess();
                refetch();
                close();
                toast.show({
                    message: <Typography className="p-0 m-0">{t('integrationDisconnected')}</Typography>,
                    icon: <CheckIcon />,
                    showClose: false
                });
            })
            .catch((error) => {
                setIsLoading(false);
                logException(error);
            });
    }

    return <Modal
        modalSize={onMobileSetLarge('SMALL')}
        title={<div className="flex justify-center items-center w-8 h-8 bg-red-100 rounded-lg">
            <InfoIcon className="w-4 h-4 text-red-300"/>
        </div>}
        desktopModalWidth="lg:w-[450px]"
        isOpen={isOpen}
        close={close}>
        <div className="w-full flex flex-col px-5">
            <Typography className="m-0 mb-2" variant="LG-2" weight="font-semibold">{t('disconnectIntegration', { integrationType })}</Typography>
            <Typography className="m-0 text-gray-600">{t('disconnectSync')}</Typography>
        </div>
        <div className="flex flex-row justify-end gap-x-3 px-5 mt-10">
            <Button
                variant="TERTIARY_FILLED"
                size="SMALL"
                onClick={close}>
                {tCommon('Buttons.cancel')}
            </Button>
            <Button
                variant="DESTRUCTIVE"
                size="SMALL"
                loading={isLoading}
                onClick={():void => {
                    disconnectAccount();
                }}>
                {tCommon('Buttons.disconnect')}
            </Button>
        </div>
    </Modal>;
};

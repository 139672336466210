/* eslint-disable @typescript-eslint/no-shadow */
import { useQueryClient } from '@tanstack/react-query';
import { Loading, Separator, toast } from '@notch-ordering/ui-components';
import { tCommon, tNavigation } from '@v2/i18n';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Router from 'react-router';
import { useTitle } from '@v2/hooks/useTitle.hook';
import { FETCH_SUPPLIER_CATALOGS_QUERY, useLinkedSupplierCatalogs } from '@v2/hooks/useLinkedSupplierCatalogs';
import useBuyerHook from '@v2/hooks/useBuyer.hook';
import { OnboardingTasks } from '@v2/components/Home/OnboardingTasks';
import { useOnboardingStore } from '@v2/stores/OnboardingStore';
import { loadCartOrders } from '@/actions/orderActions';
import HomeEmpty from './HomeEmpty';
import HomeHeader from './HomeHeader';
import HomeSuppliers from './HomeSuppliers';
import { getRemoteConfig } from '@/domains/shared/feature-flag';
import useFeatures from '@/domains/shared/hooks/useFeatures';

type Props = {
    router: Router,
};

/**
 * Loads the home page with list of suppliers and deliveries on that day
 * For a new buyer, it lists the default empty view
 *
 * @param props - component properties
 * @param props.router - for navigation
 */
export const Home: React.FC<Props> = ({ router }) => {
    const { getAppFeatures } = useFeatures();
    const { buyer } = useBuyerHook();
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const { switchedLocation } = router.location.query;
    const { fetchQuery: supplierCatalogsQuery, supplierCatalogs } = useLinkedSupplierCatalogs();
    useTitle(tNavigation('Home'));
    const { isLoading, isError } = supplierCatalogsQuery({ retry: 2, retryDelay: 10000 });
    const { areAllComplete } = useOnboardingStore();

    useEffect(() => {
        dispatch(loadCartOrders());

        const refreshRemoteConfig = async (): Promise<void> => {
            // Refresh feature-flags when visiting home
            await getRemoteConfig(true);
            getAppFeatures();
        };

        refreshRemoteConfig();
    }, []);

    useEffect(() => {
        if (switchedLocation) {
            toast.show({ message: tCommon('locationChanged'), showClose: false });
        }
    }, switchedLocation);

    const allTasksComplete = areAllComplete(buyer.urlsafe);

    return (
        <>
            <div className="flex justify-center w-full mt-8" hidden={!isLoading && !isError}><Loading isDark className="mt-safe" /></div>
            {!(isLoading || isError) && <div className="h-full">
                { supplierCatalogs?.length > 0 ? (
                    <>
                        <HomeHeader />
                        {!allTasksComplete && <><OnboardingTasks router={router} /><Separator /></>}
                        <HomeSuppliers supplierCatalogs={supplierCatalogs}
                            router={router}
                            onRemoveSupplier={(): Promise<void> => queryClient.invalidateQueries([FETCH_SUPPLIER_CATALOGS_QUERY, buyer.urlsafe])}/>
                    </>
                ) : (
                    <>
                        <HomeEmpty router={router}/>
                    </>
                )}
            </div>}
        </>
    );
};
